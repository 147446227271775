import React, { Component, Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb';
import Swal from "sweetalert2";
import { set_auth } from "../../reducers/Auth";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import i18n from "i18next";
import Switch from "react-switch";

export class PayrollSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            prosesSimpan: false,
            isLoading: true,
            payroll_note: '',
            set_cuti: '0',
            set_izin: '0',
            persen_cuti: '0',
            set_lembur: '1',
            perusahaan: {},
            set_showQty: '0'
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    showModal = () => {
        this.setState({
            modal: true,
        });
    };

    handleOk = e => {
        this.setState({
            modal: false,
        });
    };

    handleCancel = e => {
        this.setState({
            modal: false,
        });
    };

    simpanPayrollSetting = () => {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            payroll_note: this.state.payroll_note,
            set_cuti : this.state.set_cuti,
            set_izin : this.state.set_izin,
            persen_cuti : this.state.persen_cuti,
            set_lembur : this.state.set_lembur,
            set_showQty : this.state.set_showQty,
        }

        this.props.request("payroll/set-note", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t(response.message),
                        icon: 'success'
                    })
                    this.loadData()
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t(response.message),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    prosesSimpan: false,
                })
            });
    }


    loadData() {
        this.setState({
            loading: true
        })
        let form = {}
        this.props.request("get_company", form, 'POST')
            .then(response => {
                if (response) {
                    this.setState({
                        payroll_note: response.data.perusahaan_payroll_note,
                        perusahaan: response.data,
                    })
                }
            })
        this.setState({
            loading: false
        })
    }

    loadPayset() {
        let form = {}
        this.props.request("payroll/list-payset", form, 'POST')
            .then(response => {
                if (response && response.pay_set && response.pay_set.length > 0) {
                    const payset = response.pay_set[0];
                    this.setState({
                        set_cuti: payset.payset_count_cuti,
                        set_izin: payset.payset_count_izin,
                        persen_cuti: payset.payset_cuti_percentage,
                        set_lembur: payset.payset_count_lembur,
                        set_showQty: payset.payset_show_term_value,
                    })
                }
            })
    }

    componentDidMount() {
        this.loadData()
        this.loadPayset()
    }

    render() {
        if (!this.props.user.user_level.level_page_limited || this.props.user.user_level.level_page_limited.search("Pengaturan Payroll") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{i18n.t("pengaturanpayroll")} - iPresens</title>
                        <meta name="description" content="Detail Presensi" />
                    </Helmet>
                    <Breadcrumb title={i18n.t("pengaturanpayroll") +  " Presensi" }/>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card">
                                    <div className="card-body">
                                        <form className="needs-validation user-add" noValidate="">
                                            <h4>{i18n.t("pengaturanpayrollperusahaan")}</h4>
                                            <div className="form-group row">
                                                <label className="col-xl-3 col-md-4">{i18n.t("catatanpayroll")}</label>
                                                <input name="payroll_note" className="form-control col-xl-8 col-md-7"
                                                       placeholder={i18n.t("catatanpayroll") + "..."}
                                                       type="text" required="" value={this.state.payroll_note}
                                                       onChange={this.handleChange}/>
                                            </div>
                                            <div className="form-group row mt-4">
                                                <label className="col-xl-3 col-md-4">{i18n.t("perhitungancuti")}</label>
                                                <Switch
                                                    id={'set_cuti'}
                                                    name="set_cuti"
                                                    checked={
                                                        this.state.set_cuti === '1'
                                                    }
                                                    onChange={(val) => {
                                                        this.setState({
                                                            set_cuti: val ? "1" : "0",
                                                            set_izin: val ? this.state.set_izin : "0",
                                                            persen_cuti: val ? this.state.persen_cuti : "0"
                                                        })
                                                    }}
                                                    onColor="#8c2bee"
                                                    offColor="#eb4034"
                                                    handleDiameter={18}
                                                    uncheckedIcon={false}
                                                    checkedIcon={false}
                                                    boxShadow="0px 1px 1px rgba(0, 0, 0, 0.6)"
                                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                    height={24}
                                                    width={48}
                                                    className="mr-1 switch-settings-app my-2"
                                                />
                                            </div>
                                            {this.state.set_cuti === '1' &&
                                                <>
                                                    <div className="form-group row">
                                                        <div className="col-xl-3 col-md-4">
                                                            <label className="ml-3">{i18n.t("hitungsakit")}</label>
                                                        </div>
                                                        <div className="ml-3 ml-md-0">
                                                            <Switch
                                                                id={'set_izin'}
                                                                name="set_izin"
                                                                checked={
                                                                    this.state.set_izin === '1'
                                                                }
                                                                onChange={(val) => {
                                                                    this.setState({
                                                                        set_izin: val ? "1" : "0"
                                                                    })
                                                                }}
                                                                onColor="#8c2bee"
                                                                offColor="#eb4034"
                                                                handleDiameter={18}
                                                                uncheckedIcon={false}
                                                                checkedIcon={false}
                                                                boxShadow="0px 1px 1px rgba(0, 0, 0, 0.6)"
                                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                height={24}
                                                                width={48}
                                                                className="mr-1 switch-settings-app my-2"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <div className="col-xl-3 col-md-4">
                                                            <label className="ml-3">{i18n.t("persencuti")}</label>
                                                        </div>
                                                        <input name="persen_cuti"
                                                               className="form-control col-xl-8 col-md-7 ml-4 ml-md-0"
                                                               type="number" value={this.state.persen_cuti}
                                                               onChange={(event) => {
                                                                   const {name, value} = event.target;
                                                                   let persen_cuti = Math.max(0, Math.min(100, Number(value)));
                                                                   this.setState({[name]: persen_cuti});
                                                               }}/>
                                                    </div>
                                                </>
                                            }
                                            <div className="form-group row mt-4">
                                                <label className="col-xl-3 col-md-4">{i18n.t("perhitunganlembur")}</label>
                                                <Switch
                                                    id={'set_lembur'}
                                                    name="set_lembur"
                                                    checked={
                                                        this.state.set_lembur === '1'
                                                    }
                                                    onChange={(val) => {
                                                        this.setState({
                                                            set_lembur: val ? "1" : "0",
                                                        })
                                                    }}
                                                    onColor="#8c2bee"
                                                    offColor="#eb4034"
                                                    handleDiameter={18}
                                                    uncheckedIcon={false}
                                                    checkedIcon={false}
                                                    boxShadow="0px 1px 1px rgba(0, 0, 0, 0.6)"
                                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                    height={24}
                                                    width={48}
                                                    className="mr-1 switch-settings-app my-2"
                                                />
                                            </div>
                                            <div className="form-group row mt-4">
                                                <label className="col-xl-3 col-md-4">{i18n.t('tampilkanjumlahkompensasi/potongan')}</label>
                                                <Switch
                                                    id={'set_showQty'}
                                                    name="set_showQty"
                                                    checked={
                                                        this.state.set_showQty === '1'
                                                    }
                                                    onChange={(val) => {
                                                        this.setState({
                                                            set_showQty: val ? "1" : "0",
                                                        })
                                                    }}
                                                    onColor="#8c2bee"
                                                    offColor="#eb4034"
                                                    handleDiameter={18}
                                                    uncheckedIcon={false}
                                                    checkedIcon={false}
                                                    boxShadow="0px 1px 1px rgba(0, 0, 0, 0.6)"
                                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                    height={24}
                                                    width={48}
                                                    className="mr-1 switch-settings-app my-2"
                                                />
                                            </div>
                                        </form>
                                        <div className="pull-right">
                                            {this.state.prosesSimpan === false ?
                                                <button type="button" className="btn ipresens-btn-color"
                                                        onClick={this.simpanPayrollSetting}>{i18n.t("simpan")}</button>
                                                :
                                                <button type="button" className="btn ipresens-btn-color"><i
                                                    className="fa fa-refresh fa-spin"/> Loading</button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">{i18n.t("tidakadaakseshalaman")}</h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    auth: state.Auth.auth,
    user: state.Auth.user,
    api_token: state.Auth.api_token
})

const mapDispatchToProps = dispatch => ({
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(PayrollSetting)
