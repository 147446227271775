import React, {Component} from 'react'
import {Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import i18n from "i18next";
import SaveIcon from "../../assets/icons/save.svg";
import Select from "react-select";
import Swal from "sweetalert2";

class AddMataPelajaran extends Component {
    constructor(props) {
        super(props)
        this.state = {
            guru: [],
            guru_selected: [],
            guru_loading: false,
            guru_id: 0,
            mapel_nama: '',
            mapel_kkm: '',
            mapel_nilai_start: '',
            mapel_nilai_end: '',
            prosesSimpan: false
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    searchGuru() {
        let form = {}
        this.props.request('guru/search', form, 'POST')
            .then((response) => {
                if (response.success) {
                    this.setState({
                        guru: response.data,
                        guru_loading: false
                    })
                }
            })
    }

    handleAdd = () => {
        this.setState({ prosesSimpan: true})
        let form = {
            mapel_nama : this.state.mapel_nama,
            mapel_kkm : this.state.mapel_kkm,
            mapel_nilai_start: this.state.mapel_nilai_start,
            mapel_nilai_end : this.state.mapel_nilai_end,
            guru_id : this.state.guru_id,
        }
        this.props.request("mapel/add", form, 'POST')
            .then((response) => {
                if (response.success) {
                    this.props.changeState({
                        add_visible: false,
                        data: [],
                        loading: true,
                    }, () => this.props.loadData())
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('databerhasilditambahkan'),
                        icon:'success',
                        confirmButtonText: 'OK'
                    })
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: response.message,
                        icon: 'error',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({ guru_id: 0, guru_selected: [], mapel_nama: '', mapel_kkm: '', mapel_nilai_start: '', mapel_nilai_end: '', prosesSimpan: false })
            })
    }

    render() {
        return (
            <Modal isOpen={this.props.add_visible} toggle={() => this.props.changeState({ add_visible: false })} style={{ maxWidth: "1000px"}} zIndex={999}>
                <ModalHeader className="modal-header-ipresens" toggle={() => this.props.changeState({ add_visible: false })}>
                    <div className='modal-header-ipresens'>{i18n.t("tambahmapel")}</div>
                </ModalHeader>
                <ModalBody>
                    <div>
                        <div className="py-1">
                            <label className="mb-2 pengumuman-label-input" style={{ color: "#38115F" }}>{i18n.t("namamapel")}</label>
                            <input className="form-control pengumuman-input" type="text" name="mapel_nama"
                                   value={this.state.mapel_nama} placeholder={i18n.t('ketikkannamamapel')}
                                   onChange={this.handleChange}/>
                        </div>
                        <div className="py-1">
                            <label htmlFor="" className="mb-2 pengumuman-label-input" style={{ color: "#38115F" }}>{i18n.t('guru')}</label>
                            <Select
                                className="font-edit-value"
                                placeholder={i18n.t("namaguru")}
                                backspaceRemoves={true}
                                deleteRemoves={true}
                                components={{
                                    DropdownIndicator: () => null,
                                    IndicatorSeparator: () => null
                                }}
                                value={this.state.guru_selected}
                                isLoading={this.state.guru_loading}
                                onFocus={() => this.setState({guru_loading: true}, () => this.searchGuru())}
                                onBlur={() => this.setState({guru_loading: false})}
                                onInputChange={(val) => this.setState({guru_name: val}, () => this.setState({guru_loading: true}, () => this.searchGuru()))}
                                options={
                                    this.state.guru != null ? (
                                            this.state.guru.map((item, index) =>
                                                (
                                                    {
                                                        key: index,
                                                        value: item.guru_id,
                                                        label: item.guru_nama,
                                                    }
                                                )
                                            ))
                                        : ("")
                                }
                                onChange={
                                    (guru_selected) => {
                                        if (!guru_selected) {
                                            this.setState({
                                                guru_selected: '',
                                                guru_id: 0,
                                            })
                                        } else {
                                            this.setState({
                                                guru_selected,
                                                guru_id: guru_selected.value,
                                                guru: []
                                            })
                                        }
                                    }
                                }
                                isClearable={() => {
                                    this.setState({
                                        isClearable: true,
                                    })
                                }}
                            />
                        </div>
                        <div className="row" style={{ marginTop: 8 }}>
                            <div className="form-group col">
                                <label className="mb-2 pengumuman-label-input" style={{ color: "#38115F" }}>{i18n.t("mapelkkm")}</label>
                                <input className="form-control pengumuman-input" type="number"
                                       name="mapel_kkm"
                                       value={this.state.mapel_kkm} placeholder={i18n.t('masukkannilaikkm')}
                                       onChange={this.handleChange}/>
                            </div>
                            <div className="form-group col">
                                <label className="mb-2 pengumuman-label-input" style={{ color: "#38115F" }}>{i18n.t("nilaiawal")}</label>
                                <input className="form-control pengumuman-input" type="number"
                                       name="mapel_nilai_start"
                                       value={this.state.mapel_nilai_start}
                                       placeholder={i18n.t('masukkannilaiawal')}
                                       onChange={this.handleChange}/>
                            </div>
                            <div className="form-group col">
                                <label
                                    className="mb-2 pengumuman-label-input" style={{ color: "#38115F" }}>{i18n.t("nilaiakhir")}</label>
                                <input className="form-control pengumuman-input" type="number"
                                       name="mapel_nilai_end"
                                       value={this.state.mapel_nilai_end}
                                       placeholder={i18n.t('masukkannilaiakhir')}
                                       onChange={this.handleChange}/>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className="inventaris-modal-footer">
                    <button onClick={() => this.props.changeState({add_visible: false})}
                            className='ipresens-cancel-button'>{i18n.t("batal")}</button>
                    <button className='ipresens-save-button' disabled={this.state.prosesSimpan}
                            onClick={this.handleAdd}><img alt=''
                                                          src={SaveIcon}/> {this.state.prosesSimpan ? i18n.t("menyimpan") : i18n.t("simpan")}
                    </button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default AddMataPelajaran;