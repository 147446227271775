import React, { Component, Fragment } from 'react';
import { Checkbox, Empty, Spin, Tooltip } from 'antd';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import { Modal, Modal as ModalRs, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { Col, Input, InputGroup, InputGroupAddon, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WarningIcon from '../../assets/icons/warning-icon.svg';
import { faCalendarAlt, } from '@fortawesome/free-solid-svg-icons';
import { DateRange } from 'react-date-range';
import {BACKEND_API_HOST, DEFAULT_IMG, IMG_HOST} from "../../helper/host";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Swal from "sweetalert2";
import Paging from "../../components/paging";
import { Helmet } from "react-helmet";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import moment from "moment";
import { pageNumber } from "../../functions/functionGeneral";
import { clear_auth, set_auth } from "../../reducers/Auth";
import { connect } from "react-redux";
import ReactPaginate from 'react-paginate';
import "./style.css";
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import ArrowRight from "../../assets/icons/arrow-right.svg";
import SearchIcon from "../../assets/icons/search.svg";
import ArrowUpIcon from "../../assets/icons/arrow-up.svg";
import ArrowDownIcon from "../../assets/icons/arrow-down.svg";
import DownloadIcon from '../../assets/icons/download-white.svg';
import RefreshIcon from "../../assets/icons/refresh.svg";
import ElipsisIcon from "../../assets/icons/ellipsis-vertical.svg";
import CheckIcon from "../../assets/icons/Check Blue.svg";
import TrashIcon from "../../assets/icons/trash.svg";
import CrossMarkIcon from "../../assets/icons/cross-mark.svg";
import Breadcrumb from "../common/breadcrumb";
import FilterIcon from "../../assets/icons/filter.svg";
import i18n from "i18next";
//css ipresens
import "../iPresens-style.css"
import EmptyDataImage from "../../assets/images/data-kosong.png";
import ImportIcon from "../../assets/icons/import-icon.svg";
import MoreIcon from "../../assets/icons/more.svg";
import Restore from "../../assets/icons/update.svg";
import Trash from "../../assets/icons/trash.svg";

export class List_izin extends Component {
    constructor(props) {
        var namabulan = ("1 2 3 4 5 6 7 8 9 10 11 12");
        namabulan = namabulan.split(" ");
        var tgl = new Date();
        var bulan = tgl.getMonth();
        var tahun = tgl.getFullYear();
        super(props);
        this.state = {
            loading: true,
            karyawan: [],
            data: [],
            idDel: '',
            filter_nama: "",
            approvedCuti: '',
            rejectCuti: '',
            checkAll: false,
            checked: [],
            pageCount: 0,
            currentPage: 0,
            currentPageHistory:0,
            totalData: 0,
            perPage: 0,
            paging: 20,
            pagination: 2,
            guide: false,
            filter_status_ct: "",
            month: namabulan[bulan],
            year: tahun,
            monthNow: namabulan[bulan],
            yearNow: tahun,
            filter: false,
            confirmApprove: false,
            confirmReject: false,
            confirmDelete: false,
            confirmApproveOne: false,
            confirmRejectOne: false,
            exportStartDate: "",
            exportEndDate: "",
            reactDateRange: false,
            valueDate: "",
            modalexport: false,
            modalManagement: false,
            modalHistory: false,
            start_date: new Date(),
            end_date: new Date(),
            cutiStartDate: '',
            cutiEndDate: '',
            approvedData: "",
            rejectData : "",
            filterExport : "",
            buttonFilter : "",
            slice_string: true,
            modalEdit: false,
            editCutiType: "",
            editCutiStart: moment(new Date()).format("YYYY-MM-DD"),
            editCutiEnd: moment(new Date()).format("YYYY-MM-DD"),
            editCutiDesc: "",
            editCutiFile: "",
            editCutiStatus: "",
            cuti_id: 0,
            dataDetail: [],
            dataHistory: [],
            historyLast: [],
            editKaryawanNama: "",
            editKaryawanId: 0,
            loadingHistory: false,
            importFile: null,
            modalimport: false,
            id_cutiHistory: 0,
            id_history: 0,
            modalTrash: false,
            trashHistory: []
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleDate = this.handleDate.bind(this)
        this.handlePaging = this.handlePaging.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.handlePageHistory = this.handlePageHistory.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
        this.wantDelete = this.wantDelete.bind(this)
        this.exportIzin = this.exportIzin.bind(this)
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this)
        this.handleCheckBoxChangeTrash = this.handleCheckBoxChangeTrash.bind(this)
        this.handleCheckBoxAllChange = this.handleCheckBoxAllChange.bind(this)
        this.handleCheckBoxAllChangeTrash = this.handleCheckBoxAllChangeTrash.bind(this)
        this.bulkApprovedCuti = this.bulkApprovedCuti.bind(this)
        this.bulkRejectCuti = this.bulkRejectCuti.bind(this)
        this.importExcel = this.importExcel.bind(this)
    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, loading: true }, () => {
            this.loadData();
        });
    }

    handlePageHistory(dataHistory) {
        let selected = dataHistory.selected; // zero based index
        this.setState({ currentPageHistory: selected, dataHistory: [], loadingHistory: true }, () => {
            this.loadHistory();
        });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value,
            editCutiType: event.target.value
        })
    }

    handleFilter(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value,
            currentPage: 0,
            datas: [],
            loading: true
        }, () => this.loadData())
    }

    handleCheckBoxChange(id) {
        this.state.checked.includes(id) ?
            this.setState(prevState => ({
                checked: prevState.checked.filter(currId => currId !== id),
            }), () => {
                if (this.state.checked.length !== this.state.totalData) {
                    this.setState({
                        checkAll: false
                    })
                }
            })
            :
            this.setState(prevState => ({
                checked: [...prevState.checked, id],
            }), () => {
                if (this.state.checked.length === this.state.totalData) {
                    this.setState({
                        checkAll: true
                    })
                }
            })
    }

    handleCheckBoxChangeTrash(id) {
        this.state.checked.includes(id) ?
            this.setState(prevState => ({
                checked: prevState.checked.filter(currId => currId !== id),
            }), () => {
                if (this.state.checked.length !== this.state.totalDataHistory) {
                    this.setState({
                        checkAll: false
                    })
                }
            })
            :
            this.setState(prevState => ({
                checked: [...prevState.checked, id],
            }), () => {
                if (this.state.checked.length === this.state.totalDataHistory) {
                    this.setState({
                        checkAll: true
                    })
                }
            })
    }

    handleCheckBoxAllChange() {
        this.setState(prevState => ({
            checkAll: !prevState.checkAll
        }), () => {
            this.state.checkAll
                ? this.setState({
                    checked: this.state.data.map(item => item.cuti_id)
                })
                : this.setState({
                    checked: []
                })
        })
    }

    handleCheckBoxAllChangeTrash() {
        this.setState(prevState => ({
            checkAll: !prevState.checkAll
        }), () => {
            this.state.checkAll
                ? this.setState({
                    checked: this.state.trashHistory.map(item => item.cuti_id)
                })
                : this.setState({
                    checked: []
                })
        })
    }

    resetFilter(event) {
        this.setState({
            filter_nama: '',
            data: [],
            filter_status_ct: "",
            loading: true,
            month: this.state.monthNow,
            year: this.state.yearNow,
            cutiStartDate: '',
            cutiEndDate: '',
        }, () =>
            this.loadData())
    }

    handlePaging(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        }, () => {
            this.loadData()
        })
    }

    approveCuti = () => {
        this.setState({
            data: [],
            confirmApprove: false,
            confirmReject: false,
            confirmDelete: false,
            confirmApproveOne: false,
            confirmRejectOne: false,
            loading: true
        })
        let form = {
            id: this.state.approvedCuti
        }
        this.props.request("cuti/approved", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                    this.setState({
                        confirmApproveOne: false,
                        loading: false
                    })
                }
                this.loadData()
            });
    }

    rejectCuti = () => {
        this.setState({
            data: [],
            confirmApprove: false,
            confirmReject: false,
            confirmDelete: false,
            confirmApproveOne: false,
            confirmRejectOne: false,
            loading: true
        })
        let form = {
            id: this.state.rejectCuti
        }
        this.props.request("cuti/reject", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                    this.setState({
                        confirmRejectOne: false,
                        loading: false
                    })
                }
                this.loadData()
            });
    }

    wantDelete() {
        this.setState({
            data: [],
            loading: true
        })
        let form = {
            id: this.state.idDel
        }
        this.props.request("cuti/delete", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                    this.setState({
                        confirmDelete: false,
                        loading: false
                    })
                }
                this.loadData()
            });
    }

    loadHistory(id) {
        this.setState({
            dataHistory: [],
            loadingHistory: true
        },()=>{
            let form = {
                id: id ? id : this.state.cuti_id,
                paging : this.state.pagination,
                page: this.state.currentPageHistory + 1,
            };

            this.props.request("cuti/history", form, 'POST')
                .then(response => {
                    this.setState({
                        loadingHistory: false
                    })
                    if (response.succes) {
                        this.setState({
                            dataHistory: response.data.data,
                            historyLast: response.history,
                            pageCountHistory: response.data.last_page,
                            totalDataHistory: response.data.total,
                            totalPerpageHistory: response.data.to,
                            perPageHistory: response.data.per_page,
                        });
                    }
                    else {
                        this.setState({
                            modalHistory: false,
                            cuti_id: ''
                        });
                    }
                });
        })
    }

    handleSubmitRevert = () => {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('yakinmengembalikancuti'),
            icon: "warning",
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                    if (result.isConfirmed) {
                        let form = {
                            cuti_id: this.state.id_cutiHistory,
                            history_id: this.state.id_history,
                        }
                        this.props.request("cuti/revert", form, 'POST')
                            .then(response => {
                                if (response.success) {
                                    Swal.fire({
                                        title: i18n.t('berhasil'),
                                        text: response.message,
                                        icon: 'success'
                                    })
                                    this.setState({
                                        modalHistory: false
                                    })
                                    this.setState({
                                        modalHistory: false
                                    })
                                    this.props.history.push('/submission/permit')
                                    this.loadData()
                                } else {
                                    Swal.fire({
                                        title: i18n.t('gagal'),
                                        text: response.message,
                                        icon: 'warning',
                                        confirmButtonText: 'OK'
                                    })
                                }
                            })
                    }
                }
            )
    }

    loadData() {
        let form = {
            filter_status_ct: this.state.filter_status_ct,
            filter_nama: this.state.filter_nama,
            page: this.state.currentPage + 1,
            month: this.state.month,
            year: this.state.year,
            paginate: 10,
        }
        this.props.request("cuti/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        loading: false
                    })
                }
            });
    }

    loadTrash(id) {
        this.setState({
            trashHistory: [],
            loading_history: true,
        }, () => {
            let form = {
                perusahaan_id: this.state.perusahaan_id,
                page: this.state.currentPageHistory + 1,
                paginate: 4,
            };
            this.props.request('cuti/trash', form, 'POST')
                .then(response => {
                    if (response.success) {
                        this.setState({
                            trashHistory: response.data.data,
                            pageCountHistory: response.data.last_page,
                            totalDataHistory: response.data.total,
                            totalPerpageHistory: response.data.to,
                            perPageHistory: response.data.per_page,
                            loading_history: false
                        })
                    } else {
                        this.setState({
                            cuti_id: 0, perusahaan_id: 0,
                            modalTrash: false,
                        })
                    }
                })
        })
    }

    izinRestore = (id) => {
        let form = {
            cuti_id: this.state.checked,
        }
        this.props.request('cuti/restore', form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('databerhasildikembalikan'),
                        icon: "success",
                        showConfirmButton: false,
                        timer: 900,
                    })
                    this.loadData()
                    this.setState({
                        modalTrash: false,
                        checked: [],
                        restoreInventaris: false,
                        checkAll: false,
                    })
                }
            })
    }

    deletePermanent = (id) => {
        let form = {
            cuti_id: this.state.checked,
        }
        this.props.request('cuti/delete-permanent', form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('databerhasildihapus'),
                        icon: "success",
                        showConfirmButton: false,
                        timer: 900,
                    })
                    this.loadData()
                    this.setState({
                        modalTrash: false,
                        checked: [],
                        permanentDelete: false,
                        checkAll: false,
                    })
                }
            })
    }

    handleEdit = () => {
        this.setState({
            loading: true,
            data: []
        })
        let form = {
            id: this.state.cuti_id,
            cutiType: this.state.editCutiType,
            cutiDesc: this.state.editCutiDesc,
            cutiStart: this.state.editCutiStart,
            cutiEnd: this.state.editCutiEnd,
        }
        this.props.request("cuti/update", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.setState({
                                modalEdit: false,
                                loading: true
                            }, () => this.loadData())
                        } else {
                            this.setState({
                                modalEdit: false,
                                loading: true
                            }, () => this.loadData())
                        }
                    })
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            });
    }
    handleDate(event) {
        const start = event.selectStart;
        const end = event.selectEnd;

        this.setState({
            data: [],
            loading: true,
            modalexport: true,
            exportStartDate: start,
            exportEndDate: end,
            filterExport: '',
        }, () => {
            this.loadData()
        });
    }
    exportIzin() {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'cuti/export?token=' + AuthKey + '&cutiStartDate=' + this.state.exportStartDate + '&cutiEndDate=' + this.state.exportEndDate + "&filter=" + this.state.filterExport, "_blank")
    }

    handleCancel = e => {
        this.setState({
            modalexport: false,
            modalManagement: false,
            modalimport: false,
            valueDate: "",
            exportStartDate: "",
            exportEndDate: "",
            start_date: new Date(),
            end_date: new Date()
        });
    };


    bulkApprovedCuti = () => {
        this.setState({
            data: [],
            confirmApprove: false,
            confirmReject: false,
            confirmDelete: false,
            confirmApproveOne: false,
            confirmRejectOne: false,
            loading: true
        })
        let form = {
            cuti_ids: this.state.checked
        }
        this.props.request("cuti/bulk-approve", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                    this.setState({
                        checked: [],
                        checkAll: false,
                        confirmApprove: false
                    })
                }
                this.loadData()
            });
    }

    bulkRejectCuti = () => {
        this.setState({
            data: [],
            confirmApprove: false,
            confirmReject: false,
            confirmDelete: false,
            confirmApproveOne: false,
            confirmRejectOne: false,
            loading: true
        })
        let form = {
            cuti_ids: this.state.checked
        }
        this.props.request("cuti/bulk-reject", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                    this.setState({
                        checked: [],
                        checkAll: false,
                        confirmReject: false
                    })
                }
                this.loadData()
            });
    }

    componentDidMount() {
        this.loadData()
    }

    sortabledate = (val) => {

        const copyArray = this.state.data

        copyArray.sort((a, b) => {
            return val === 0 ? new Date(a.cuti_created_date) - new Date(b.cuti_created_date) : new Date(b.cuti_created_date) - new Date(a.cuti_created_date);
        });

        this.setState({
            data: copyArray
        })
    }

    compareBy(key) {
        return function (a, b) {
            if (a[key] < (b[key])) return -1;
            if (a[key] > (b[key])) return 1;
            return 0;
        };
    }

    sortablestatus = (val) => {

        const copyArray = this.state.data

        if (val === 0) {
            copyArray.sort(this.compareBy("cuti_status"));
        } else {
            copyArray.reverse();
        }

        this.setState({
            data: copyArray
        })
    }

    exportTemplateCuti = () => {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'cuti/export_template?token=' + AuthKey, "_blank")
    }

    importExcel() {
        this.setState({
            importLoading: true
        })

        var AuthKey = localStorage.getItem('Presensi-Auth-Key')

        let formData = new FormData()
        formData.append('file', this.state.importFile)
        formData.append('token', AuthKey)

        fetch(BACKEND_API_HOST + 'cuti/import', {
            method: 'POST',
            headers: {
                'Authorization': 'bearer ' + AuthKey
            },
            body: formData
        })
            .catch((error) => {
                console.error(error);
            })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                    window.location.reload();
                    this.setState({
                        modalimport: false,
                        loading: true,
                        importFile: null,
                        data: [],
                    }, () => {
                        this.loadData()
                    })
                }
                else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        showConfirmButton: false,
                        timer: 1200,
                    })
                    this.setState({
                        modalimport: false,
                    })
                }

                this.setState({ importLoading: false })
            })
    }

    handleOk = e => {
        this.setState({
            modalimport: false,
        });
    };

    render() {
        moment.locale(i18n.language);
        const selectionRange = {
            startDate: this.state.start_date,
            endDate: this.state.end_date,
            key: 'selection',
        }
        const style_add = {
            font_header: {
                "fontWeight": 700,
                "fontSize": "18px",
                "lineHeight": "27px",
                "color": "#143B5E"
            },
            font_label: {
                "fontWeight": 500,
                "fontSize": "16px",
                "lineHeight": "24px",
                "color": "#143B5E"
            },
            font_time: {
                "padding": "16px",
                "textAlign": "center",
                "fontWeight": 500,
                "fontSize": "16px",
                "lineHeight": "24px",
                "color": "#143B5E"
            },
            input: {
                "padding": "16px",
                "borderRadius": "8px",
                "fontWeight": 500,
                "fontSize": "14px",
                "lineHeight": "18px",
                "color": "#143B5E"
            }
        }
        let no = 0
        let borderBottom = "1px solid #EFEFEF"
        if (!this.props.user.user_level.level_page_limited || this.props.user.user_level.level_page_limited.search("Pengajuan Izin") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{i18n.t('titlehalamanizincuti')}</title>
                        <meta name="description" content="Detail Absensi" />
                    </Helmet>
                    <Modal centered isOpen={this.state.confirmReject} toggle={() => {
                        this.setState({
                            confirmReject: false
                        })
                    }}>
                        <ModalHeader className='pl-4 border-bottom-0' toggle={() => {
                            this.setState({
                                confirmReject: false
                            })
                        }}>
                            <div className='d-flex'>
                                <div className='p-3 mr-4' style={{
                                    backgroundColor: "#FFECE8",
                                    borderRadius: "8px"
                                }}>
                                    <img alt='' src={CrossMarkIcon} />
                                </div>
                                <div>
                                    <div style={{
                                        color: "#143B5E",
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        lineHeight: "27px"
                                    }}>{i18n.t('modalizincuti')}</div>
                                    <div style={{
                                        color: "#5D6F80",
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "24px"
                                    }}>{this.state.checked.length} {i18n.t('pengajuanterpilih')}</div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalFooter style={{
                            borderTop: 0
                        }}>
                            <div className='row mx-0' style={{ width: "100%" }}>
                                <div className='col-md-6 px-1'>
                                    <button className='btn ipresens-btn-cancel' style={{ width: "100%" }} onClick={() => {
                                        this.setState({
                                            confirmReject: false
                                        })
                                    }}>
                                        {i18n.t('batal')}
                                    </button>
                                </div>
                                <div className='col-md-6 px-1'>
                                    <button className='btn ipresens-btn-color' style={{
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        lineHeight: "24px",
                                        width: "100%"
                                    }} onClick={this.bulkRejectCuti}>
                                        {i18n.t('ya')}
                                    </button>
                                </div>
                            </div>
                        </ModalFooter>
                    </Modal>
                    <Modal size="lg" zIndex={999} isOpen={this.state.modalTrash} toggle={() => this.setState({
                        modalTrash: false,
                        checked: [],
                        trashHistory: [],
                        checkAll: false,
                    })} style={{maxWidth: "1000px"}}>
                        <ModalHeader className='pengumuman-modal-header'>
                            <div className='pengumuman-modal-header-font'>{i18n.t("trashcuti")}</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="row d-flex justify-content-end">
                                <div className="col-md-6"></div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <button className="btn ipresens-btn-color px-3 py-2 mx-1"
                                            disabled={this.state.checked.length === 0}
                                            onClick={() => {
                                                this.setState({restoreInventaris: true});
                                            }}>
                                        Restore
                                    </button>
                                    <button className="btn ipresens-btn-color px-3 py-2 mx-1"
                                            disabled={this.state.checked.length === 0} onClick={() => {
                                        this.setState({permanentDelete: true});
                                    }}>Delete Permanent
                                    </button>
                                </div>
                            </div>
                            <div className="list-group">
                                <div>
                                    {this.state.loading_history && (
                                        <div style={{textAlign: "center", margin: '10px auto'}}>
                                            <Spin size="large" tip={i18n.t('loading')}
                                                  style={{width: "100%", margin: "auto"}}/>
                                        </div>
                                    )}
                                    {this.state.trashHistory.length !== 0 && (
                                        <Table className="table table-striped mb-5">
                                            <Thead>
                                                <Tr>
                                                    <Th width="30"><Checkbox checked={this.state.checkAll}
                                                                             onChange={this.handleCheckBoxAllChangeTrash}></Checkbox></Th>
                                                    <Th className="izin-th-style" style={{ maxWidth: "165px" }}>{i18n.t("deskripsicuti")}</Th>
                                                    <Th className="izin-th-style">{i18n.t("tipeizin")}</Th>
                                                    <Th className="izin-th-style" style={{ maxWidth: "174px" }}>{i18n.t("submissiondate")}</Th>
                                                    <Th className="izin-th-style" style={{ maxWidth: "174px" }}>{i18n.t("mulaiberakhircuti")}</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {this.state.trashHistory.map((item, index) => {
                                                    let no = index + 1;
                                                    return (
                                                        <Tr>
                                                            <Td><Checkbox checked={this.state.checked.includes(item.cuti_id)} onChange={() => this.handleCheckBoxChangeTrash(item.cuti_id)}></Checkbox></Td>
                                                            <Td style={{border: "none"}} align='left'>
                                                                <div className="mt-0">
                                                                    <p style={{
                                                                        color: "#38115F",
                                                                        marginBottom: "0px"
                                                                    }}>{item.cuti_desc}</p>
                                                                </div>
                                                            </Td>
                                                            <Td style={{border: "none"}} align='left'>
                                                                <div className="mt-0">
                                                                    <p style={{
                                                                        color: "#38115F",
                                                                        marginBottom: "0px"
                                                                    }}>{item.cuti_type}</p>
                                                                </div>
                                                            </Td>
                                                            <Td style={{border: "none"}} align='left'>
                                                                <div className="mt-0">
                                                                    <p style={{
                                                                        color: "#38115F",
                                                                        marginBottom: "0px"
                                                                    }}>{item.cuti_start}</p>
                                                                </div>
                                                            </Td>
                                                            <Td style={{border: "none"}} align='left'>
                                                                <div className="mt-0">
                                                                    <p style={{
                                                                        color: "#38115F",
                                                                        marginBottom: "0px"
                                                                    }}>{item.cuti_end}</p>
                                                                </div>
                                                            </Td>
                                                        </Tr>
                                                    );
                                                })}
                                            </Tbody>
                                        </Table>
                                    )
                                    }
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{ backgroundColor: "#F5F5F5", borderBottomLeftRadius: "8px", borderBottomRightRadius: "8px" }}>
                            <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                                {this.state.trashHistory.length !== 0 &&
                                    <>
                                        <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>{i18n.t("menampilkan")} {this.state.trashHistory.length === 0 || this.state.trashHistory.length === null ? "0" : this.state.trashHistory.length} {i18n.t("dari")} {this.state.totalDataHistory} {i18n.t("data")}</div>
                                        <div className='col'>
                                            <ReactPaginate
                                                previousLabel={<img src={ArrowLeft} alt='' />}
                                                nextLabel={<img src={ArrowRight} alt='' />}
                                                pageCount={this.state.pageCountHistory}
                                                forcePage={this.state.currentPageHistory}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={this.handlePageTrashHistory}
                                                breakClassName={'page-item'}
                                                breakLinkClassName={'page-link custom-page-link'}
                                                containerClassName={'pagination my-2'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link custom-page-link'}
                                                previousClassName={'page-item'}
                                                previousLinkClassName={'page-link custom-prev-next-link'}
                                                nextClassName={'page-item'}
                                                nextLinkClassName={'page-link custom-prev-next-link'}
                                                activeClassName={'active custom-active'}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </ModalFooter>
                    </Modal>
                    <Modal centered isOpen={this.state.permanentDelete} toggle={() => {
                        this.setState({
                            permanentDelete: false
                        })
                    }}>
                        <ModalHeader className='pl-4 border-bottom-0' toggle={() => {
                            this.setState({
                                permanentDelete: false
                            })
                        }}>
                            <div className='d-flex'>
                                <div className='p-3 mr-4' style={{
                                    backgroundColor: "transparent",
                                    borderRadius: "8px"
                                }}>
                                    <img alt='' src={Trash}/>
                                </div>
                                <div>
                                    <div style={{
                                        color: "#143B5E",
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        lineHeight: "27px"
                                    }}>Pulihkan Data Cuti</div>
                                    <div style={{
                                        color: "#5D6F80",
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "24px"
                                    }}>{this.state.checked.length} Cuti Terpilih</div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalFooter style={{
                            borderTop: 0
                        }}>
                            <div className='row mx-0' style={{ width: "100%" }}>
                                <div className='col-md-6 px-1'>
                                    <button className='btn ipresens-btn-cancel' style={{ width: "100%" }} onClick={() => {
                                        this.setState({
                                            permanentDelete: false
                                        })
                                    }}>
                                        {i18n.t('batal')}
                                    </button>
                                </div>
                                <div className='col-md-6 px-1'>
                                    <button className='btn ipresens-btn-color' style={{
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        lineHeight: "24px",
                                        width: "100%"
                                    }} onClick={this.deletePermanent}>
                                        {i18n.t('ya')}
                                    </button>
                                </div>
                            </div>
                        </ModalFooter>
                    </Modal>
                    <Modal centered isOpen={this.state.restoreInventaris} toggle={() => {
                        this.setState({
                            restoreInventaris: false
                        })
                    }}>
                        <ModalHeader className='pl-4 border-bottom-0' toggle={() => {
                            this.setState({
                                restoreInventaris: false
                            })
                        }}>
                            <div className='d-flex'>
                                <div className='p-3 mr-4' style={{
                                    backgroundColor: "#8C2BEE",
                                    borderRadius: "8px"
                                }}>
                                    <img alt='' src={Restore} />
                                </div>
                                <div>
                                    <div style={{
                                        color: "#143B5E",
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        lineHeight: "27px"
                                    }}>Hapus Permanen Data Cuti?</div>
                                    <div style={{
                                        color: "#5D6F80",
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "24px"
                                    }}>{this.state.checked.length} Inventaris Terpilih</div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalFooter style={{
                            borderTop: 0
                        }}>
                            <div className='row mx-0' style={{ width: "100%" }}>
                                <div className='col-md-6 px-1'>
                                    <button className='btn ipresens-btn-cancel' style={{ width: "100%" }} onClick={() => {
                                        this.setState({
                                            restoreInventaris: false
                                        })
                                    }}>
                                        {i18n.t('batal')}
                                    </button>
                                </div>
                                <div className='col-md-6 px-1'>
                                    <button className='btn ipresens-btn-color' style={{
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        lineHeight: "24px",
                                        width: "100%"
                                    }} onClick={this.izinRestore}>
                                        {i18n.t('ya')}
                                    </button>
                                </div>
                            </div>
                        </ModalFooter>
                    </Modal>
                    <ModalRs size='lg' isOpen={this.state.modalexport} toggle={this.handleCancel}>
                                <ModalHeader style={{ backgroundColor: "#FAFDFF" }} toggle={this.handleCancel}>Export Excel</ModalHeader>
                                <ModalBody>
                                    <div className='row'>
                                        <div className='col'>
                                            <div className='d-flex flex-row warning-info px-2 mb-2'>
                                                <div className='px-0 mr-1'><img src={WarningIcon} alt="" /></div>
                                                <div className='px-0' style={{ fontSize: "14px" }}>
                                                    {i18n.t('filterberdasarkantanggal')}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mb-2' style={{ width:"780px" }}>
                                        <div className='col-md-6'>
                                            <InputGroup className="form-tgl-izin">
                                                <InputGroupAddon addonType="prepend">
                                                    <div className="input-group-text">
                                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                                    </div>
                                                </InputGroupAddon>

                                                <Input bsSize="sm" className='font-edit-value' style={{ paddingTop: "8px", paddingBottom: "8px" }} onFocus={() => this.setState({ reactDateRange: true })} placeholder={i18n.t('filtertanggal')} value={this.state.valueDate} />
                                            </InputGroup>
                                        </div>
                                    </div>
                                    {this.state.reactDateRange === true &&
                                        <div className='shadow d-flex flex-column' style={{ maxWidth: "388px" }}>
                                            <div>
                                                <DateRange
                                                    onBlur={() => this.setState({ reactDateRange: false })}
                                                    ranges={[selectionRange]}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            start_date: e.selection.startDate,
                                                            end_date: e.selection.endDate,
                                                            exportStartDate: moment(e.selection.startDate).format("YYYY-MM-DD"),
                                                            exportEndDate: moment(e.selection.endDate).format("YYYY-MM-DD")
                                                        })
                                                    }
                                                    }
                                                />
                                            </div>
                                            <div>
                                                <div className='d-flex justify-content-end pr-2'>
                                                    <button className='btn ipresens-btn-cancel text-capitalize mb-2' style={{ fontSize: "14px", fontWeight: 500 }} onClick={() => this.setState({ reactDateRange: false })}>{i18n.t('batal')}</button>
                                                    <button className='btn ipresens-btn-color mb-2 ml-2 text-capitalize' style={{ fontSize: "14px", fontWeight: 500, lineHeight: "24px" }} onClick={
                                                        () => this.setState({
                                                            valueDate: this.state.exportStartDate + " - " + this.state.exportEndDate,
                                                            reactDateRange: false
                                                        })
                                                    }>{i18n.t('terapkan')}</button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {this.state.valueDate !== "" &&
                                        <>
                                        <div>
                                            <div className='row' >
                                                <div className='col-auto d-flex justify-content-start mb-2'>
                                                    <div className='btn-group width-filter'>
                                                        <button
                                                            className={`btn text-capitalize ipresens-custom-btn-group filter-masuk${this.state.filterExport === "new" ? ' ipresens-active-custom-btn-group' : ''}`}
                                                            onClick={() => this.setState((prevState) => ({filterExport: prevState.filterExport === "new" ? '' : 'new'}))}>
                                                            {i18n.t('menunggu')}
                                                        </button>
                                                        <button
                                                            className={`btn text-capitalize ipresens-custom-btn-group filter-masuk${this.state.filterExport === "approved" ? ' ipresens-active-custom-btn-group' : ''}`}
                                                            onClick={() => this.setState((prevState) => ({filterExport: prevState.filterExport === "approved" ? '' : "approved"}))}>
                                                            {i18n.t('disetujui')}
                                                        </button>
                                                        <button
                                                            className={`btn text-capitalize ipresens-custom-btn-group filter-masuk${this.state.filterExport === "reject" ? ' ipresens-active-custom-btn-group' : ''}`}
                                                            onClick={() => this.setState((prevState) => ({filterExport: prevState.filterExport === "reject" ? '' : 'reject'}))}>
                                                            {i18n.t('ditolak')}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr style={{ marginBottom: "40px" }} />
                                            <div className='row px-2'>
                                                <div className='col-md-4 px-1'>
                                                    <button className={'btn btn-block ipresens-btn-color px-3 py-2 text-capitalize mb-2'} style={{ fontSize: "14px", borderRadius: "11px" }} onClick={this.exportIzin}>{i18n.t('buttonexportizincuti')}</button>
                                                </div>
                                            </div>
                                        </div>
                                        </>
                                    }
                                </ModalBody>
                                <ModalFooter style={{ backgroundColor: "#F5F5F5", borderTop: 0 }}>
                                    <button className="btn ipresens-btn-cancel text-capitalize" onClick={this.handleCancel}>
                                        {i18n.t('batalkan')}
                                    </button>
                                </ModalFooter>
                            </ModalRs>
                    <ModalRs size="lg" style={{ maxWidth: "1000px" }} isOpen={this.state.modalHistory} toggle={() => this.setState({ modalHistory: false })}>
                        <ModalHeader style={{ backgroundColor: "#FAFDFF" }} toggle={() => this.setState({ modalHistory: false })}>
                            <div className='modal-header-reactstrap'>{i18n.t('riwayatperubahan')}</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="list-group">
                                <div>
                                    {this.state.loadingHistory && (
                                        <div style={{ textAlign: 'center', margin: '10px auto' }}>
                                            <Spin
                                                size="large"
                                                tip="Loading..."
                                                style={{
                                                    width: '100%',
                                                    margin: 'auto',
                                                }}
                                            />
                                        </div>
                                    )}

                                    {this.state.dataHistory.length !== 0 && (
                                        <div>
                                            <Row>
                                                <Col xs={0} md={0} className="text-left" style={{ marginLeft: '20px' }}>
                                                    <h4>{i18n.t('user')}</h4>
                                                </Col>
                                                <Col xs={0} md={0} className="text-left"style={{ marginRight: '20px' }}>
                                                    <h4>{i18n.t('aktivitas')}</h4>
                                                </Col>
                                            </Row>

                                            {this.state.dataHistory.map((item, index) => {
                                                return (
                                                    <div key={index} style={{ marginBottom: '20px' }}>
                                                        <Row>
                                                            <Col xs={12} md={6} className="text-left">
                                                                <div style={{ display: 'flex', alignItems: 'center', paddingTop: '16px',paddingBottom:'16px', paddingRight:'12px', paddingLeft:'12px' }}>
                                                                    <div style={{ marginRight: '16px' }}>
                                                                        <img
                                                                            src={DEFAULT_IMG}
                                                                            alt=""
                                                                            style={{ width: '48px' , marginBottom:'25px', height:'48px'}}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <h6
                                                                            className="mb-0 font-13"
                                                                            style={{
                                                                                fontWeight: 500,
                                                                                fontSize: '16px',
                                                                                lineHeight: '24px',
                                                                            }}
                                                                        >
                                                                            {item.p_user_name} | {item.level_name}
                                                                        </h6>
                                                                        <p className="m-0 font-12">
                                                                                    <span className="col-green font-weight-bold">
                                                                                      {moment(item.created_at).format('lll')}
                                                                                    </span>
                                                                        </p>
                                                                        <div style={{ marginTop: 12 }}>
                                                                            {item.history_status === "created" ? (
                                                                                <div className="row px-3">
                                                                                    <div
                                                                                        className="mr-2"
                                                                                        style={{
                                                                                            border: '1px solid #000',
                                                                                            borderRadius: '25px',
                                                                                            width: '50%',
                                                                                            color: '#7805e4',
                                                                                            borderColor: '#7805e4',
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            className="d-flex justify-content-center"
                                                                                            style={{
                                                                                                paddingTop: '6px',
                                                                                                paddingBottom: '6px'
                                                                                            }}>
                                                                                            {i18n.t('dibuat')}
                                                                                        </div>
                                                                                    </div>
                                                                                    {this.state.historyLast.history_id === item.history_id ?
                                                                                        "" :
                                                                                        <button
                                                                                            className='col-md-5 d-flex justify-content-center ipresens-btn-revert'
                                                                                            onClick={() => {
                                                                                                this.setState({
                                                                                                    id_cutiHistory: item.cuti_id,
                                                                                                    id_history: item.history_id,
                                                                                                })
                                                                                                this.handleSubmitRevert()
                                                                                            }}>{i18n.t('revert')}
                                                                                        </button>
                                                                                    }
                                                                                </div>
                                                                            ) : item.history_status === "reverted" ?
                                                                                <div
                                                                                    className="mr-2 px-5"
                                                                                    style={{
                                                                                        border: '1px solid #000',
                                                                                        borderRadius: '25px',
                                                                                        width: '70%',
                                                                                        color: '#7805e4',
                                                                                        borderColor: '#7805e4',
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        className="d-flex justify-content-center"
                                                                                        style={{
                                                                                            paddingTop: '6px',
                                                                                            paddingBottom: '6px'
                                                                                        }}>
                                                                                        {i18n.t('dikembalikan')}
                                                                                    </div>
                                                                                </div>
                                                                                : (
                                                                                    <div className="row px-3">
                                                                                        <div
                                                                                            className="mr-2"
                                                                                            style={{
                                                                                                border: '1px solid #000',
                                                                                                borderRadius: '25px',
                                                                                                width: '50%',
                                                                                                color: '#E6462E',
                                                                                                borderColor: '#E6462E',
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                className="d-flex justify-content-center"
                                                                                                style={{
                                                                                                    paddingTop: '6px',
                                                                                                    paddingBottom: '6px'
                                                                                                }}>
                                                                                                {i18n.t('diubah')}
                                                                                            </div>
                                                                                        </div>
                                                                                        {this.state.historyLast.history_id === item.history_id ?
                                                                                            "" :
                                                                                            <button
                                                                                                className='col-md-5 d-flex justify-content-center ipresens-btn-revert'
                                                                                                onClick={() => {
                                                                                                    this.setState({
                                                                                                        id_cutiHistory: item.cuti_id,
                                                                                                        id_history: item.history_id,
                                                                                                    })
                                                                                                    this.handleSubmitRevert()
                                                                                                }}>{i18n.t('revert')}
                                                                                            </button>
                                                                                        }
                                                                                    </div>
                                                                                )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} md={6} className="text-left" style={{ paddingTop: '16px',paddingBottom:'16px', paddingRight:'12px', paddingLeft:'12px' }}>
                                                                {item.history_status !== 'created' && (
                                                                    <div>
                                                                        {item.parent_history ?
                                                                            item.parent_history.cuti_desc != item.cuti_desc && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left "
                                                                                        style={{
                                                                                            fontWeight: 100,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t('deskripsicutidari')}{' '}
                                                                                        {/*Deskripsi Cuti : dari{' '}*/}
                                                                                        {item.parent_history
                                                                                            ? item.parent_history.cuti_desc
                                                                                            : 'N/A'}{' '}
                                                                                        <img
                                                                                            src={ArrowRight}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '1px',
                                                                                                height: '18px'
                                                                                            }}
                                                                                        /> jadi : {item.cuti_desc ? item.cuti_desc : ''}
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.cuti_start != item.cuti_start && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 100,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t('mulaicutidari')}{' '}
                                                                                        {item.parent_history
                                                                                            ? item.parent_history.cuti_start
                                                                                            : 'N/A'}{' '}
                                                                                        <img
                                                                                            src={ArrowRight}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '1px',
                                                                                                height: '18px'
                                                                                            }}
                                                                                        /> {i18n.t('jadicuti')} : {item.cuti_start ? item.cuti_start : ''}
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.cuti_end != item.cuti_end && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 100,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t('berakhircutidari')}{' '}
                                                                                        {item.parent_history
                                                                                            ? item.parent_history.cuti_end
                                                                                            : 'N/A'}{' '}
                                                                                        <img
                                                                                            src={ArrowRight}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '1px',
                                                                                                height: '18px'
                                                                                            }}
                                                                                        /> {i18n.t('jadicuti')} : {item.cuti_end ? item.cuti_end : ''}
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.cuti_status != item.cuti_status && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 100,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t('statuscutidari')}{' '}
                                                                                        {item.parent_history
                                                                                            ? item.parent_history.cuti_status
                                                                                            : 'N/A'}{' '}
                                                                                        <img
                                                                                            src={ArrowRight}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '1px',
                                                                                                height: '18px'
                                                                                            }}
                                                                                        /> {i18n.t('jadicutistatus')} {item.cuti_status ? item.cuti_status : ''}
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                    </div>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{ backgroundColor: "#F5F5F5" }}>
                            <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                                {this.state.dataHistory.length !== 0 &&
                                    <>
                                        <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>menampilkan {this.state.dataHistory.length === 0 || this.state.dataHistory.length === null ? "0" : this.state.dataHistory.length} dari {this.state.totalDataHistory} data</div>
                                        <div className='col'>
                                            <ReactPaginate
                                                previousLabel={<img src={ArrowLeft} alt='' />}
                                                nextLabel={<img src={ArrowRight} alt='' />}
                                                pageCount={this.state.pageCountHistory}
                                                forcePage={this.state.currentPageHistory}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={this.handlePageHistory}
                                                breakClassName={'page-item'}
                                                breakLinkClassName={'page-link custom-page-link'}
                                                containerClassName={'pagination my-2'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link custom-page-link'}
                                                previousClassName={'page-item'}
                                                previousLinkClassName={'page-link custom-prev-next-link'}
                                                nextClassName={'page-item'}
                                                nextLinkClassName={'page-link custom-prev-next-link'}
                                                activeClassName={'active custom-active'}
                                            />
                                        </div>
                                    </>
                                }
                            </div>

                        </ModalFooter>
                    </ModalRs>
                    <Modal isOpen={this.state.modalimport}>
                        <ModalHeader onClick={this.handleCancel} toggle={() => this.setState({importFile: null})}>
                            <div>{i18n.t('importcuti')}</div>
                        </ModalHeader>
                        <ModalBody style={{ padding: "20px" }}>
                            <div className='row mb-2'>
                                <div className="col">
                                    <a style={{color: "#5D6F80", cursor: "default"}}>{i18n.t('importcutitext')}</a><br/>
                                    <a onClick={this.exportTemplateCuti} style={{color: "#057CE4"}}>{i18n.t('importcutitemplates')}</a>
                                    <div style={{
                                        position: 'relative',
                                        width: '100%',
                                        backgroundColor: '#F9F9F9',
                                        padding: 16,
                                        borderRadius: 6,
                                        marginTop: 8,
                                        border: "1px dashed #DDDDDD"
                                    }}>
                                        <input type='file'
                                               accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                               onChange={(event) => {
                                                   this.setState({
                                                       importFile: event.target.files[0],
                                                   })
                                               }}/><br/>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            {[
                                this.state.importLoading ?
                                    <button className={'btn ipresens-btn-color'} style={{textTransform: "none", width: "100%"}}
                                            disabled={true}><i className="fa fa-refresh fa-spin"></i>{i18n.t('sedangmenguploaddata')}</button>
                                    :
                                    <button className={this.state.importFile === null ? 'btn bg-secondary-custom' : 'btn ipresens-btn-color'} style={{textTransform: "none", width: "100%"}} disabled={this.state.importFile === null} onClick={this.importExcel}>Import Data</button>
                            ]}
                        </ModalFooter>
                    </Modal>
                    <Modal centered isOpen={this.state.confirmRejectOne} toggle={() => {
                        this.setState({
                            confirmRejectOne: false
                        })
                    }}>
                        <ModalHeader className='pl-4 border-bottom-0' toggle={() => {
                            this.setState({
                                confirmRejectOne: false
                            })
                        }}>
                            <div className='d-flex'>
                                <div className='p-3 mr-4' style={{
                                    backgroundColor: "#FFECE8",
                                    borderRadius: "8px"
                                }}>
                                    <img alt='' src={CrossMarkIcon} />
                                </div>
                                <div>
                                    <div style={{
                                        color: "#143B5E",
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        lineHeight: "27px"
                                    }}>{i18n.t('tolakpengajuanizincuti')}</div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalFooter style={{
                            borderTop: 0
                        }}>
                            <div className='row mx-0' style={{ width: "100%" }}>
                                <div className='col-md-6 px-1'>
                                    <button className='ipresens-btn-cancel' style={{ width: "100%" }} onClick={() => {
                                        this.setState({
                                            confirmRejectOne: false
                                        })
                                    }}>
                                        {i18n.t('batal')}
                                    </button>
                                </div>
                                <div className='col-md-6 px-1'>
                                    <button className='btn ipresens-btn-color text-capitalize' style={{
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        lineHeight: "24px",
                                        width: "100%"
                                    }} onClick={this.rejectCuti}>
                                        {i18n.t('ya')}
                                    </button>
                                </div>
                            </div>
                        </ModalFooter>
                    </Modal>
                    <Modal centered isOpen={this.state.confirmDelete} toggle={() => {
                        this.setState({
                            confirmDelete: false
                        })
                    }}>
                        <ModalHeader className='pl-4 border-bottom-0' toggle={() => {
                            this.setState({
                                confirmDelete: false
                            })
                        }}>
                            <div className='d-flex'>
                                <div className='p-3 mr-4' style={{
                                    backgroundColor: "#FFECE8",
                                    borderRadius: "8px"
                                }}>
                                    <img alt='' src={TrashIcon} />
                                </div>
                                <div>
                                    <div style={{
                                        color: "#143B5E",
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        lineHeight: "27px"
                                    }}>{i18n.t('hapuspengajuanizincuti')}</div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalFooter style={{
                            borderTop: 0
                        }}>
                            <div className='row mx-0' style={{ width: "100%" }}>
                                <div className='col-md-6 px-1'>
                                    <button className='ipresens-btn-cancel' style={{ width: "100%" }} onClick={() => {
                                        this.setState({
                                            confirmDelete: false
                                        })
                                    }}>
                                        {i18n.t('batal')}
                                    </button>
                                </div>
                                <div className='col-md-6 px-1'>
                                    <button className='btn ipresens-btn-color text-capitalize' style={{
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        lineHeight: "24px",
                                        width: "100%"
                                    }} onClick={this.wantDelete}>
                                        {i18n.t('ya')}
                                    </button>
                                </div>
                            </div>
                        </ModalFooter>
                    </Modal>
                    <Modal centered isOpen={this.state.confirmApprove} toggle={() => {
                        this.setState({
                            confirmApprove: false
                        })
                    }}>
                        <ModalHeader className='pl-4 border-bottom-0' toggle={() => {
                            this.setState({
                                confirmApprove: false
                            })
                        }}>
                            <div className='d-flex'>
                                <div className='p-3 mr-4' style={{
                                    backgroundColor: "#E8F4FF",
                                    borderRadius: "8px"
                                }}>
                                    <img alt='' src={CheckIcon} />
                                </div>
                                <div>
                                    <div style={{
                                        color: "#143B5E",
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        lineHeight: "27px"
                                    }}>{i18n.t('setujuipengajuanizincuti')}</div>
                                    <div style={{
                                        color: "#5D6F80",
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "24px"
                                    }}>{this.state.checked.length} {i18n.t('pengajuanterpilih')}</div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalFooter style={{
                            borderTop: 0
                        }}>
                            <button className='ipresens-btn-cancel' onClick={() => {
                                this.setState({
                                    confirmApprove: false
                                })
                            }}>
                                {i18n.t("tolak")}
                            </button>{' '}
                            <button className='btn ipresens-btn-color text-capitalize' style={{
                                fontSize: "14px",
                                fontWeight: 500,
                                lineHeight: "24px"
                            }} onClick={this.bulkApprovedCuti}>
                                {i18n.t("setujui")}
                            </button>
                        </ModalFooter>
                    </Modal>
                    <Modal centered isOpen={this.state.confirmApproveOne} toggle={() => {
                        this.setState({
                            confirmApproveOne: false
                        })
                    }}>
                        <ModalHeader className='pl-4 border-bottom-0' toggle={() => {
                            this.setState({
                                confirmApproveOne: false
                            })
                        }}>
                            <div className='d-flex'>
                                <div className='p-3 mr-4' style={{
                                    backgroundColor: "#E8F4FF",
                                    borderRadius: "8px"
                                }}>
                                    <img alt='' src={CheckIcon} />
                                </div>
                                <div>
                                    <div style={{
                                        color: "#143B5E",
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        lineHeight: "27px"
                                    }}>{i18n.t('setujuipengajuanizincuti')}</div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalFooter style={{
                            borderTop: 0
                        }}>
                            <button className='ipresens-btn-cancel' onClick={() => {
                                this.setState({
                                    confirmApproveOne: false
                                })
                            }}>
                                {i18n.t("batal")}
                            </button>{' '}
                            <button className='btn ipresens-btn-color text-capitalize' style={{
                                fontSize: "14px",
                                fontWeight: 500,
                                lineHeight: "24px"
                            }} onClick={this.approveCuti}>
                                {i18n.t("setujui")}
                            </button>
                        </ModalFooter>
                    </Modal>
                    {this.state.modalEdit &&
                        <Modal isOpen={this.state.modalEdit} toggle={() => this.setState({
                            modalEdit: false,
                            editCutiType:"",
                            editCutiStart: "",
                            editCutiEnd: "",
                            editCutiDesc: "",
                            editCutiFile: "",
                        })} style={{
                            marginRight: "10px",
                            maxWidth: "419px",
                        }}>
                            <ModalHeader className='pl-4 border-bottom-0' toggle={() => this.setState({
                                modalEdit: false,
                                editCutiType:"",
                                editCutiStart: "",
                                editCutiEnd: "",
                                editCutiDesc: "",
                                editCutiFile: "",
                            })}>
                                <div style={style_add.font_header}>{i18n.t('editizincuti')}</div>
                            </ModalHeader>
                            <ModalBody>
                                <div className="row mb-2">
                                    <div className="col-md-12">
                                        <label for="cuti_type" className="form-label" style={style_add.font_label}>{i18n.t('namakaryawan')}</label>
                                        <a style={{
                                            fontWeight: 700,
                                            fontSize: "16px",
                                            lineHeight: "24px"
                                        }}  href={"/karyawan/profil/detail/" + this.state.editKaryawanId} className='text-capitalize'>{this.state.editKaryawanNama}</a>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-md-12">
                                        <label for="cuti_type" className="form-label" style={style_add.font_label}>{i18n.t('tipeizin')}</label>
                                        <select name="cuti_type" id="cuti_type" className="form-control font-edit-value" value={this.state.editCutiType} onChange={this.handleChange}>
                                            <option value={this.state.editCutiType}>{i18n.t(this.state.editCutiType)}</option>
                                            {this.state.editCutiType !== "Izin" && <option value="Izin">{i18n.t('Izin')}</option>}
                                            {this.state.editCutiType !== "Cuti" && <option value="Cuti">{i18n.t('Cuti')}</option>}
                                            {this.state.editCutiType !== "Sakit" && <option value="Sakit">{i18n.t('Sakit')}</option>}
                                        </select>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                        <div className="col-md-6">
                                            <label for="cuti_start" style={style_add.font_label}>{i18n.t('tanggalmulai')}</label>
                                            <input type="date" onChange={this.handleChange} className="form-control" id="cuti_start" name='editCutiStart' style={style_add.input} value={moment(this.state.editCutiStart).format("YYYY-MM-DD")} placeholder={i18n.t('pilihtanggal')} />
                                        </div>
                                        <div className="col-md-6">
                                            <label for="cuti_end" style={style_add.font_label}>{i18n.t('tanggalselesai')}</label>
                                            <input type="date" onChange={this.handleChange} className="form-control" id="cuti_end" name='editCutiEnd' style={style_add.input} value={moment(this.state.editCutiEnd).format("YYYY-MM-DD")} placeholder={i18n.t('pilihtanggal')} />
                                        </div>
                                </div>
                                <div className='row' style={{ marginBottom: "20px"}}>
                                    <div className='col-md-12'>
                                        <label htmlFor="karyawan_nama" className="form-label" style={style_add.font_label}>{i18n.t('deskripsicuti')}</label>
                                        <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder={i18n.t('deskripsilemburplaceholder')} onChange={this.handleChange} name='editCutiDesc' value={this.state.editCutiDesc}></textarea>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <label htmlFor="cuti_file" className={"form-label"} style={style_add.font_label}>{i18n.t('lampiranizincuti')}</label>
                                        {this.state.editCutiFile !== null ? (
                                        <a href={"https://services.presensi.co.id/media/mitra/cuti/" + this.state.editCutiFile} target={"_blank"}>
                                            <img src={IMG_HOST + "mitra/cuti/" + this.state.editCutiFile} className={"dk-image-lampiran"} alt=""/>
                                        </a>) : (
                                            <i>{i18n.t('lampiranizincutikosong')}</i>
                                            )
                                        }
                                    </div>
                                </div>
                            </ModalBody>
                            <ModalFooter style={{
                                borderTop: 0,
                                backgroundColor: "#F5F5F5",
                                borderBottomLeftRadius: "8px",
                                borderBottomRightRadius: "8px",
                            }}>
                                <button className='ipresens-btn-cancel' onClick={() => {
                                    this.setState({
                                        modalEdit: false,
                                        editCutiType:"",
                                        editCutiStart: "",
                                        editCutiEnd: "",
                                        editCutiDesc: "",
                                        editCutiFile: "",
                                    })
                                }}>
                                    {i18n.t('batal')}
                                </button>{' '}
                                <button className='btn ipresens-btn-color text-capitalize' style={{
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    lineHeight: "24px"
                                }} onClick={() => {
                                    this.handleEdit()
                                }}>
                                    {i18n.t('simpan')}
                                </button>
                            </ModalFooter>
                        </Modal>
                    }
                    <Breadcrumb title={i18n.t("daftarpengajuanizin")} parent={i18n.t("pengajuan")} setGuide={() => { this.setState({ guide: true }) }} />
                    <div className="container-fluid">
                        <div className="card" style={{ border: "1px solid #DDDDDD" }}>
                            <div className="card-header pb-0 border-bottom-0">
                                <div className='row'>
                                    <div className='col-md-5'>
                                        <div className='d-flex flex-row'>
                                            <div className='flex-fill pr-2'>
                                                <div className='input-group btn-srch-izin'>
                                                    <span className="input-group-text izin-search-karyawan-img"
                                                          id="basic-addon1">
                                                        <img alt='' src={SearchIcon}/>
                                                    </span>
                                                    <input type="text" name='filter_nama' onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            this.setState({
                                                                loading: true,
                                                                data: []
                                                            }, () => this.loadData())
                                                        }
                                                    }} value={this.state.filter_nama} onChange={this.handleChange}
                                                           className="form-control izin-search-karyawan-form"
                                                           placeholder={i18n.t("carinamakaryawan") + "..."}
                                                           aria-label="Username" aria-describedby="basic-addon1"/>
                                                </div>
                                            </div>
                                            <div>
                                                <button className='btn ipresens-btn-filter'
                                                        onClick={() => this.setState({filter: !this.state.filter})}><img
                                                    src={FilterIcon}/> Filter
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-7 justify-content-end d-flex'>
                                        {!this.state.loading &&
                                            <>
                                            <button
                                                className='btn ipresens-btn-color text-capitalize ml-2 izin-btn-w100 mr-2 mr-2'
                                                style={{
                                                    height: "2.5rem",
                                                }}
                                                onClick={() => {
                                                    this.setState({
                                                        confirmApprove: true
                                                    })
                                                }}
                                                disabled={this.state.checked.length === 0}>{i18n.t("setujui")}</button>
                                            <button
                                                className='izin-tolak-button text-capitalize izin-btn-w100 mr-2'
                                                style={{
                                                    lineHeight: "20px",
                                                    padding: "8px 18px",
                                                    borderRadius: "6px",
                                                    height: "2.5rem",
                                                }}
                                                onClick={() => {
                                                    this.setState({
                                                        confirmReject: true
                                                    })
                                                }}
                                                disabled={this.state.checked.length === 0}>{i18n.t("tolak")}</button>
                                                <Tooltip rigger={'click'} placement="bottomLeft" color={'#FFF'}
                                                         title={
                                                             <div className={'p-2'}>
                                                                 <a onClick={() => {
                                                                     this.setState({modalexport: true})
                                                                 }} style={{marginRight: "50px"}}
                                                                    className={'text-dark'}>{i18n.t("eksporexcel")}</a><br/>
                                                                 <a onClick={() => {
                                                                     this.setState({modalimport: true})
                                                                 }} style={{marginRight: "50px"}}
                                                                    className={'text-dark'}>{i18n.t("import")}</a><br/>
                                                                 <a onClick={() => {
                                                                     this.setState({modalTrash: true});
                                                                     this.loadTrash();
                                                                 }} style={{marginRight: "50px"}}
                                                                    className={'text-dark'}>{i18n.t("trashcuti")}</a>
                                                             </div>
                                                         }>
                                                    <a><i
                                                        style={{
                                                            width: "15",
                                                            fontSize: 18,
                                                            color: 'rgb(51 51 51)',
                                                            alignItems: "center",
                                                            height: "2.5rem",
                                                        }}
                                                        className='btn border border-dark border-1 p-2'
                                                    ><img src={MoreIcon}/></i></a>
                                                </Tooltip>
                                                {this.state.checked.length !== 0 &&
                                                    <div className='float-right mr-2' style={{
                                                        height: "100%",
                                                        paddingTop: "8px"
                                                    }}>
                                                        <div style={{
                                                            fontSize: "14px",
                                                            fontWeight: 500,
                                                            lineHeight: "24px",
                                                            color: "#143B5E",
                                                        }}>
                                                            {this.state.checked.length} {i18n.t("pengajuantelahdipilih")}
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                                {this.state.filter &&
                                    <div className='row mx-0'>
                                        <div className='col-md-2 px-1 mb-2'>
                                            <label className='form-label'
                                                   style={{fontSize: "12px"}}>{i18n.t("bulan")}</label>
                                            <select name="month" className='form-control' value={this.state.month}
                                                    style={{fontSize: "12px"}} onChange={this.handleFilter}>
                                                <option value="">{i18n.t("pilihbulan")}</option>
                                                <option value="1">{i18n.t("januari")}</option>
                                                <option value="2">{i18n.t("februari")}</option>
                                                <option value="3">{i18n.t("maret")}</option>
                                                <option value="4">{i18n.t("april")}</option>
                                                <option value="5">{i18n.t("mei")}</option>
                                                <option value="6">{i18n.t("juni")}</option>
                                                <option value="7">{i18n.t("juli")}</option>
                                                <option value="8">{i18n.t("agustus")}</option>
                                                <option value="9">{i18n.t("september")}</option>
                                                <option value="10">{i18n.t("oktober")}</option>
                                                <option value="11">{i18n.t("november")}</option>
                                                <option value="12">{i18n.t("desember")}</option>
                                            </select>
                                        </div>
                                        <div className='col-md-2 px-1 mb-2'>
                                            <label className='form-label'
                                                   style={{fontSize: "12px"}}>{i18n.t("tahun")}</label>
                                            <select name="year" className='form-control' value={this.state.year} style={{ fontSize: "12px" }} onChange={this.handleFilter}>
                                            <option value="">{i18n.t("pilihtahun")}</option>
                                            <option value="2020">2020</option>
                                            <option value="2021">2021</option>
                                            <option value="2022">2022</option>
                                            <option value="2023">2023</option>
                                            <option value="2024">2024</option>
                                        </select>
                                    </div>
                                    <div className='col-auto px-1 d-flex align-items-end justify-content-end mb-2'>
                                        <div className='btn-group width-filter'>
                                            <button
                                                className={'btn text-capitalize ipresens-custom-btn-group filter-masuk' + (this.state.filter_status_ct === "new" ? ' ipresens-active-custom-btn-group' : '')}
                                                onClick={() => this.setState({ filter_status_ct: "New", loading: true }, () => this.loadData())}>
                                                {i18n.t("menunggu")}
                                            </button>
                                            <button
                                                className={'btn text-capitalize ipresens-custom-btn-group filter-masuk' + (this.state.filter_status_ct === "approved" ? ' ipresens-active-custom-btn-group' : '')}
                                                onClick={() => this.setState({ filter_status_ct: "Approved", loading: true }, () => this.loadData())}>
                                                {i18n.t("disetujui")}
                                            </button>
                                            <button
                                                className={'btn text-capitalize ipresens-custom-btn-group filter-masuk' + (this.state.filter_status_ct === "reject" ? ' ipresens-active-custom-btn-group' : '')}
                                                onClick={() => this.setState({ filter_status_ct: "Reject", loading: true }, () => this.loadData())}>
                                                {i18n.t("ditolak")}
                                            </button>
                                        </div>
                                    </div>
                                    <div className='col-auto px-1 d-flex align-items-end justify-content-end pb-2'>
                                        <button className='btn text-capitalize px-2 width-filter ipresens-btn-reset-filter pt-1' onClick={
                                            () => {
                                                this.setState({
                                                    loading: true,
                                                    filter_nama: "",
                                                    filter_month: ("1 2 3 4 5 6 7 8 9 10 11 12").split(" ")[new Date().getMonth()],
                                                    filter_year: new Date().getFullYear(),
                                                    filter_status_ct: "",
                                                }, () => this.loadData())
                                            }
                                        }><img src={RefreshIcon} alt="icon refresh" /> Reset Filter</button>
                                    </div>
                                </div>
                                }
                            </div>
                            <div className="card-body daftar-izin">
                                {!this.state.loading && this.state.data.length === 0 ?
                                    <Empty description={i18n.t("datakosong")}
                                           image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                    :
                                    <Fragment>
                                        <Table className="table table-striped table-borderless mb-5">
                                            <Thead>
                                                <Tr align="center">
                                                    <Th><Checkbox checked={this.state.checkAll} onChange={this.handleCheckBoxAllChange}></Checkbox></Th>
                                                    <Th className="izin-th-style">#</Th>
                                                    <Th className="izin-th-style">{i18n.t("karyawan")}</Th>
                                                    <Th className="izin-th-style">{i18n.t("tipeizin")}</Th>
                                                    <Th className="px-1">
                                                        <div className='d-flex'>
                                                            <div className='izin-th-style'>{i18n.t("submissiondate")} </div>
                                                            <div className='ml-1 d-flex flex-column pt-1'>
                                                                <img alt='' onClick={() => {
                                                                    this.sortabledate(1)
                                                                }} style={{ width: "8px", height: "8px" }} className='izin-btn' src={ArrowUpIcon} />
                                                                <img alt='' onClick={() => {
                                                                    this.sortabledate(0)
                                                                }} style={{ width: "8px", height: "8px" }} className='izin-btn' src={ArrowDownIcon} />
                                                            </div>
                                                        </div>
                                                    </Th>
                                                    <Th className="izin-th-style" style={{ maxWidth: "174px" }}>{i18n.t("mulaiberakhircuti")}</Th>
                                                    <Th className="izin-th-style" style={{ maxWidth: "165px" }}>{i18n.t("deskripsicuti")}</Th>
                                                    <Th className="izin-th-style">{i18n.t("lampiran")}</Th>
                                                    <Th className="px-1">
                                                        <div className='d-flex'>
                                                            <div className='izin-th-style'>Status </div>
                                                            <div className='ml-1 d-flex flex-column pt-1'>
                                                                <img alt='' style={{ width: "8px", height: "8px" }} className='izin-btn' src={ArrowUpIcon} onClick={() => {
                                                                    this.sortablestatus(1)
                                                                }} />
                                                                <img alt='' style={{ width: "8px", height: "8px" }} className='izin-btn' src={ArrowDownIcon} onClick={() => {
                                                                    this.sortablestatus(0)
                                                                }} />
                                                            </div>
                                                        </div>
                                                    </Th>
                                                    <Th className="izin-th-style"></Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {this.state.loading &&
                                                    <Tr>
                                                        <Td colSpan={9}>
                                                            <Spin size="large" tip="Loading..." style={{
                                                                width: '100%',
                                                                margin: 'auto',
                                                                marginBottom: '10px',
                                                                marginTop: '10px'
                                                            }} />
                                                        </Td>
                                                    </Tr>
                                                }
                                                {this.state.data.map((item, index) => {
                                                    no += 1
                                                    return (
                                                        <Tr align="center" style={{
                                                            borderBottom: no === this.state.data.length ? "" : borderBottom
                                                        }}>
                                                            <Td><Checkbox checked={this.state.checked.includes(item.cuti_id)} onChange={() => this.handleCheckBoxChange(item.cuti_id)}></Checkbox></Td>
                                                            <Td scope="row" className="izin-td-style">{pageNumber(no, this.state.perPage, this.state.currentPage)}</Td>
                                                            <Td className="izin-td-style">
                                                                <a href={"/karyawan/profil/detail/" + item.karyawan.karyawan_slug} style={{ color: "#8c2bee" }}>
                                                                    {item.karyawan.karyawan_nama}
                                                                </a>
                                                            </Td>
                                                            <Td className="text-capitalize izin-td-style">{i18n.t(item.cuti_type)}</Td>
                                                            <Td className="izin-td-style">{moment(item.cuti_created_date).format('ll')}</Td>
                                                            <Td className="izin-td-style" style={{ maxWidth: "174px" }} >{moment(item.cuti_start).format('ll HH:mm')} - {moment(item.cuti_end).format('ll HH:mm')}</Td>
                                                            <Td className="izin-td-style" style={{ maxWidth: "165px" }}>{this.state.slice_string ? item.cuti_desc.slice(0, 44) : item.cuti_desc}{item.cuti_desc.length >= 45 && this.state.slice_string === true ? <button className='izin-btn' style={{ color: "#6BA9FF", fontSize: "24px", fontWeight: 400, padding: "0px" }} onClick={() => {
                                                                this.setState({
                                                                    slice_string: false
                                                                })
                                                            }}>...</button> : ""}{item.cuti_desc.length >= 45 && this.state.slice_string === false ? <button className='izin-btn' style={{ color: "#6BA9FF", fontSize: "14px", fontWeight: 400, padding: "0px" }} onClick={() => {
                                                                this.setState({
                                                                    slice_string: true
                                                                })
                                                            }}>{i18n.t("lebihsedikit")}</button> : ""}</Td>
                                                            <Td className="izin-td-style">
                                                                {item.cuti_file !== null ?
                                                                    <a href={"https://services.presensi.co.id/media/mitra/cuti/" + item.cuti_file} target={"_blank"}><i
                                                                        className="fa fa-eye" style={{
                                                                            width: 35,
                                                                            fontSize: 20,
                                                                            padding: 11,
                                                                            color: '#8c2bee'
                                                                        }}></i>
                                                                    </a>
                                                                    :
                                                                    <div>-</div>
                                                                }
                                                            </Td>
                                                            <Td className="izin-td-style" style={{
                                                                color: item.cuti_status === "New" || item.cuti_status === "Draft" ? "#FF9C00" :
                                                                    item.cuti_status === "Reject" ? "#E03616" :
                                                                        item.cuti_status === "Approved" ? "#1E7E0E" : ""
                                                            }}>
                                                                {item.cuti_status === "New" || item.cuti_status === "Draft" ? i18n.t("menunggu") :
                                                                    item.cuti_status === "Reject" ? i18n.t("ditolak") :
                                                                        item.cuti_status === "Approved" ? i18n.t("disetujui") :
                                                                            ""}
                                                            </Td>
                                                            <Td className="izin-td-style">
                                                                {this.state.modalEdit === true ? ""
                                                                :
                                                                <Tooltip trigger={'click'} placement="bottom" color={'#fff'} title={
                                                                    <div className={'d-flex flex-column'}>
                                                                        <button style={{ width: "84px" }} className='izin-btn izin-tooltip-menu px-2 py-1 mb-1' onClick={() => {
                                                                            this.setState({
                                                                                modalHistory: true,
                                                                                cuti_id: item.cuti_id
                                                                            },()=>{
                                                                                this.loadHistory(item.cuti_id);
                                                                            })
                                                                        }}>{i18n.t('riwayat')}</button>
                                                                        {item.cuti_status !== "Approved" &&
                                                                            <button style={{ width: "84px" }} className='izin-btn izin-tooltip-menu px-2 py-1 mb-1' onClick={() => {
                                                                                this.setState({ approvedCuti: item.cuti_id, confirmApproveOne: true })
                                                                            }}>{i18n.t('setujui')}</button>
                                                                        }
                                                                        {item.cuti_status !== "Reject" &&
                                                                            <button style={{ width: "84px" }} className='izin-btn izin-tooltip-menu px-2 py-1 mb-1' onClick={() => {
                                                                                this.setState({ rejectCuti: item.cuti_id, confirmRejectOne: true })
                                                                            }}>{i18n.t('tolak')}</button>
                                                                        }
                                                                        <button style={{ width:"84px", color:"#1a37e1" }} className="izin-btn izin-tooltip-menu px-2 py-1 mb-1" onClick={() => {
                                                                            this.setState({
                                                                                modalEdit: true,
                                                                                cuti_id: item.cuti_id,
                                                                                editKaryawanId: item.karyawan.karyawan_id,
                                                                                editKaryawanNama: item.karyawan.karyawan_nama,
                                                                                editCutiType: item.cuti_type,
                                                                                editCutiStart: item.cuti_start,
                                                                                editCutiEnd: item.cuti_end,
                                                                                editCutiDesc: item.cuti_desc,
                                                                                editCutiFile: item.cuti_file,
                                                                                editCutiStatus: item.cuti_status
                                                                            })}
                                                                        }>Edit</button>
                                                                        <button style={{ width: "84px", color: "red" }} className='izin-btn izin-tooltip-menu px-2 py-1' onClick={() => {
                                                                            this.setState({
                                                                                idDel: item.cuti_id,
                                                                                confirmDelete: true
                                                                            })
                                                                        }}>{i18n.t('hapus')}</button>
                                                                    </div>
                                                                }>
                                                                    <button className='izin-btn'>
                                                                        <img alt='' src={ElipsisIcon} />
                                                                    </button>
                                                                </Tooltip>
                                                                }
                                                            </Td>
                                                        </Tr>
                                                    )
                                                })}
                                            </Tbody>
                                        </Table>
                                    </Fragment>
                                }
                            </div>
                            <div className='card-footer p-0'>
                                <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                                    {this.state.data.length !== 0 &&
                                        <>
                                            <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>{i18n.t("menampilkan")} {this.state.data.length === 0 || this.state.data.length === null ? "0" : this.state.data.length} {i18n.t("dari")} {this.state.totalData} data</div>
                                            <div className='col'>
                                                <ReactPaginate
                                                    previousLabel={<img src={ArrowLeft} alt='' />}
                                                    nextLabel={<img src={ArrowRight} alt='' />}
                                                    pageCount={this.state.pageCount}
                                                    forcePage={this.state.currentPage}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={this.handlePageClick}
                                                    breakClassName={'page-item'}
                                                    breakLinkClassName={'page-link custom-page-link'}
                                                    containerClassName={'pagination my-2'}
                                                    pageClassName={'page-item'}
                                                    pageLinkClassName={'page-link custom-page-link'}
                                                    previousClassName={'page-item'}
                                                    previousLinkClassName={'page-link custom-prev-next-link'}
                                                    nextClassName={'page-item'}
                                                    nextLinkClassName={'page-link custom-prev-next-link'}
                                                    activeClassName={'active custom-active'}
                                                />
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">{i18n.t("tidakadaakseshalaman")}</h5>
                </Fragment>
            )
        }

    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(List_izin);
