import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import i18n from 'i18next';
import {Helmet} from "react-helmet";
import {clear_auth, set_auth} from "../../reducers/Auth";
import {connect} from "react-redux";
import SearchIcon from "../../assets/icons/search.svg";
import RefreshIcon from "../../assets/icons/refresh.svg";
import {Empty, Spin} from "antd";
import EmptyDataImage from "../../assets/images/data-kosong.png";
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table";
import {pageNumber} from "../../functions/functionGeneral";
import {Modal, ModalHeader, ModalBody} from "reactstrap";
import {DEFAULT_IMG, IMG_HOST} from "../../helper/host";
import moment from "moment/moment";
import Paging from "../paging";

export class TaskActivity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data : [],
            tugas : [],
            currentPage: 0,
        }
        this.handleFilter = this.handleFilter.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
    }

    handleFilter(event, load = false) {
        const { name, value } = event.target;
        this.setState({
            [name]: value,
        }, () => {
            if (load) {
                this.loadData();
            }
        })
    }

    resetFilter() {
        this.setState({
            filter_nama: '',
            filter_tugas: '',
        }, () => this.loadData())
    }

    loadData() {
        this.setState({loading: true})
        let form = {
            filter_nama : this.state.filter_nama,
            filter_tugas : this.state.filter_tugas,
            paginate : 10,
            page : this.state.currentPage + 1,
        }
        this.props.request("task/list-ativity", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data : response.data.data,
                        tugas : response.tugas,
                        loading : false,
                        totalData : response.data.total,
                        pageCount : response.data.last_page,
                        perPage : response.data.per_page,
                    })
                }
            });
    }

    handlePageClick(data) {
        let selected = data.selected
        this.setState({ currentPage: selected }, () => {
            this.loadData()
        });
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        function truncateString(str, maxWords) {
            if (!str) return ''
            const words = str.split(' ');
            var truncated = words.slice(0, maxWords).join(' ');
            if(words.length > maxWords){
                truncated = truncated + " ..."
            }
            return truncated;
        }

        let no = 0
        moment.locale(i18n.language)
        if (this.props.user.user_level.level_page_limited == null || this.props.user.user_level.level_page_limited.search("Activity") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{i18n.t("aktivitastugas")} - iPresens</title>
                        <meta name="description" content={i18n.t("aktivitastugas")} />
                    </Helmet>
                    <Breadcrumb parent={i18n.t("tugas")} title={i18n.t("aktivitastugas")} setGuide={() => { this.setState({ guide: true }) }} />
                    <Modal style={{maxWidth: 1000}} zIndex={999} isOpen={this.state.modalfile} toggle={() => this.setState({modalfile : false})}>
                        <ModalHeader toggle={() => this.setState({modalfile : false})}>
                            <div className='pengumuman-modal-header-font'>{i18n.t("filetugas", {nama_karyawan : this.state.nama_karyawan})}</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className='d-flex justify-content-center align-items-center'>
                                <img alt='' src={this.state.file} style={{ maxWidth: "720px", maxHeight: "720px" }} />
                            </div>
                        </ModalBody>
                    </Modal>
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-4 pr-lg-1 pr-md-1">
                                        <div className='input-group btn-srch-kbeban'>
                                                <span
                                                    className='input-group-text izin-search-karyawan-img'>
                                                    <img alt='' src={SearchIcon}/>
                                                </span>
                                            <input type='text' onChange={(e) => this.handleFilter(e)}
                                                   name='filter_nama' value={this.state.filter_nama}
                                                   className='form-control izin-search-karyawan-form'
                                                   style={{padding: "12px 10px",}}
                                                   placeholder={i18n.t("namakaryawan") + "..."}
                                                   onKeyDown={(e) => {
                                                       if (e.key === "Enter") {
                                                           this.loadData()
                                                       }
                                                   }}>
                                            </input>
                                        </div>
                                    </div>
                                    <div className="col-md-3 px-lg-1 px-md-1 d-flex flex-row flex-nowrap mb-3">
                                        <select name="filter_tugas" value={this.state.filter_tugas}
                                                className="form-control karyawan-filter-jabatan mr-2 btn-slc-krywn"
                                                style={{borderRadius: "4px"}}
                                                onChange={(e) => this.handleFilter(e, true)}
                                        >
                                            <option value="">{i18n.t('judultugas')}</option>
                                            {this.state.tugas.map((item) => {
                                                return (
                                                    <option value={item.task_id}>{truncateString(item.task_title, 10)}</option>
                                                )
                                            })}
                                        </select>
                                        <div>
                                            <button className='btn ipresens-btn-reset border px-2'>
                                                <img alt="" src={RefreshIcon}
                                                     onClick={this.resetFilter}/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <div className="table-responsive">
                                            {!this.state.loading && this.state.data.length === 0 ?
                                                <Empty description="Data Kosong"
                                                       image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                                :
                                                <div>
                                                    <Table className="table mb-5 table-striped table-borderless">
                                                        <Thead>
                                                            <Tr>
                                                                <Th className='lembur-th-style text-center' width="50">#</Th>
                                                                <Th className='lembur-th-style'>{i18n.t("namakaryawan")}</Th>
                                                                <Th className='lembur-th-style'>{i18n.t("tugas")}</Th>
                                                                <Th className='lembur-th-style'>{i18n.t("aktivitas")}</Th>
                                                                <Th className='lembur-th-style'>{i18n.t("waktuaktivitas")}</Th>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            {this.state.loading ?
                                                                <Tr>
                                                                    <Td colSpan="9" style={{
                                                                        textAlign: "center",
                                                                        backgroundColor: "#FFFFFF"
                                                                    }}>
                                                                        <Spin size="large"
                                                                              tip={i18n.t("sedangmemuatdata") + ' ...'}
                                                                              style={{
                                                                                  width: '100%',
                                                                                  margin: 'auto',
                                                                                  marginBottom: '10px',
                                                                                  marginTop: '10px'
                                                                              }}/>
                                                                    </Td>
                                                                </Tr>
                                                                :
                                                                this.state.data.map((item, index) => {
                                                                    no += 1
                                                                    return (
                                                                        <Tr key={index}>
                                                                            <Td className="lembur-td-style text-center">{pageNumber(no, this.state.perPage, this.state.currentPage)}</Td>
                                                                            <Td className="lembur-td-style fw-900">{item.karyawan.karyawan_nama}</Td>
                                                                            <Td className="lembur-td-style">{item.tugas.task_title}</Td>
                                                                            <Td className="lembur-td-style">
                                                                                {item.activity_type === "comment" && (
                                                                                    <>{i18n.t("karyawanmengomentari")} "{item.activity_value}"</>
                                                                                )}
                                                                                {item.activity_type === "attachment" && (
                                                                                    <>{i18n.t("karyawanmelampirkan")}
                                                                                        <a onClick={() => this.setState({
                                                                                            nama_karyawan : item.karyawan.karyawan_nama,
                                                                                            file : item.activity_value !== null ? IMG_HOST + 'mitra/activity/' + item.activity_value : DEFAULT_IMG,
                                                                                            modalfile: true
                                                                                        })} target={"_blank"}>
                                                                                            <i className="fa fa-eye"
                                                                                               style={{
                                                                                                   width: 35,
                                                                                                   fontSize: 20,
                                                                                                   padding: 11,
                                                                                                   color: '#8c2bee'
                                                                                               }}/>
                                                                                        </a>
                                                                                    </>
                                                                                )}
                                                                                {item.activity_type === "finished" && (
                                                                                    <>{i18n.t("karyawanmenyelesaikan")}</>
                                                                                )}
                                                                            </Td>
                                                                            <Td className="lembur-td-style">{moment(item.created_at).format('lll')}</Td>
                                                                        </Tr>
                                                                    )
                                                                })}
                                                        </Tbody>
                                                    </Table>
                                                </div>
                                            }
                                        </div>
                                        <div className="row">
                                            <div className='col font-14' style={{
                                                padding: "auto",
                                                margin: "auto",
                                                fontWeight: 500,
                                                lineHeight: "24px"
                                            }}>
                                                {i18n.t("menampilkan")} {this.state.data.length === 0 || this.state.data.length === null ? "0" : this.state.data.length} {i18n.t("dari")} {this.state.totalData} {i18n.t("data")}.
                                            </div>
                                            <div className="col">
                                                <Paging
                                                    className="float-right"
                                                    handlePageClick={this.handlePageClick}
                                                    pageCount={this.state.pageCount}
                                                    currentPage={this.state.currentPage}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">{i18n.t("tidakadaakseshalaman")}</h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(TaskActivity);