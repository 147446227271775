import React, { Component, Fragment, useState } from 'react';
import {Checkbox, Empty, Modal, Spin, Tooltip} from 'antd';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Swal from "sweetalert2";
import Paging from "../../components/paging";
import {BACKEND_API_HOST, DEFAULT_IMG, IMG_HOST} from "../../helper/host";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { pageNumber } from "../../functions/functionGeneral";
import { toast } from "react-toastify";
import { clear_auth, set_auth } from "../../reducers/Auth";
import Dropdown from 'react-bootstrap/Dropdown';
import RefreshIcon from '../../assets/icons/refresh.svg';
import ModalDefault from '../menu-guide/modal-default';
import MoreIcon from '../../assets/icons/more.svg'
import IconPlusWhite from "../../assets/icons/IconPlusWhite.svg"
import './style.css'
import { addMonthsToDate, formatDateToYMD } from "../../helper/date";
import { isOdd } from "../../helper/function";
import EmptyDataImage from "../../assets/images/data-kosong.png";
import {Col, Modal as ModalRs, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import moment from "moment/moment";
import ArrowRight from "../../assets/icons/arrow-right.svg";
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import ReactPaginate from 'react-paginate';
import i18n from "i18next";
import Trash from "../../assets/icons/trash.svg";
import Restore from "../../assets/icons/update.svg";
import PlusIcon from "../../assets/icons/plus-white.svg";

export class List_karyawan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: [],
            idDel: '',
            filter_nama: "",
            filter_jabatan: "",
            importFile: null,
            modalimport: false,
            modalAction: false,
            dataAction: [],
            pageCount: 0,
            totalData: 0,
            perPage: 0,
            paging: 15,
            pagination: 3,
            currentPage: parseInt(props.match.params.page),
            guide: false,
            kunci_pengaturan: ['Tes'],
            icon_success: false,
            itemsPerPage: 10,
            shift_check: [],
            lokasi_check: [],
            divisi_check: [],
            karyawan_check: [],
            jam_kerja_check: [],
            rowsPerPage: 50,
            confirm_modal_default: false,
            modalHistory: false,
            historyKaryawan: [],
            karyawan_id: 0,
            historyLast: [],
            id_karyawanHistory: 0,
            modalTrash: false,
            restore: false,
            deletePermanent: false,
            checked: [],
            checkAll: false,
            trashHistory: [],
        }
        this.handleChange = this.handleChange.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
        this.importExcel = this.importExcel.bind(this)
        this.wantDelete = this.wantDelete.bind(this)
        this.handlePageHistory = this.handlePageHistory.bind(this)
        this.handleCheckBoxChangeTrash = this.handleCheckBoxChangeTrash.bind(this)
        this.handleCheckBoxAllChangeTrash = this.handleCheckBoxAllChangeTrash.bind(this)
    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected }, () => {
            this.loadData()
            this.props.history.push('/employee/employee-list/' + selected)
        });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    handleFilter(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            data: [],
            loading: true
        }, () => this.loadData())
    }

    resetFilter(event) {
        this.setState({
            filter_nama: '',
            filter_jabatan: '',
            data: [],
            loading: true
        }, () => this.loadData())
    }

    handleOk = e => {
        this.setState({
            modalimport: false,
            modalAction: false
        });
    };

    handleCancel = e => {
        this.setState({
            modalimport: false,
            modalAction: false
        });
    };

    exportKaryawan = () => {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'karyawan/export_template?token=' + AuthKey, "_blank")
    }

    exportExcelKaryawan = () => {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'karyawan/export_karyawan?token=' + AuthKey, "_blank")
    }

    wantDelete() {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('yakinmenghapuskaryawan'),
            icon: 'warning',
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        loading: true,
                        data: []
                    })
                    let form = {
                        karyawan_id: this.state.idDel
                    }
                    this.props.request("karyawan/delete_karyawan", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: i18n.t('berhasilhapuskaryawan'),
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.setState({
                                    loading: true,
                                    data: []
                                })
                                this.loadData()
                                this.loadCheck()
                            }
                        });
                }
            })
    }

    importExcel() {
        this.setState({
            importLoading: true
        })

        var AuthKey = localStorage.getItem('Presensi-Auth-Key')

        let formData = new FormData()
        formData.append('file', this.state.importFile)
        formData.append('token', AuthKey)

        fetch(BACKEND_API_HOST + 'karyawan/import', {
            method: 'POST',
            headers: {
                'Authorization': 'bearer ' + AuthKey
            },
            body: formData
        })
            .catch((error) => {
                console.error(error);
            })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t(response.message),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                    window.location.reload();
                    this.setState({
                        modalimport: false,
                        loading: true,
                        importFile: null,
                        data: [],
                    }, () => {
                        this.loadData()
                    })
                }
                else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: response.message,
                        icon: 'warning',
                        showConfirmButton: false,
                        timer: 1200,
                    })
                    this.setState({
                        modalimport: false,
                    })
                }

                this.setState({ importLoading: false })
            })
    }

    loadHistory(id) {
        this.setState({
            historyKaryawan: [],
            loadingHistory: true
        },()=>{
            let form = {
                id: id ? id : this.state.karyawan_id,
                paging : this.state.pagination,
                page: this.state.currentPageHistory + 1,
            };

            this.props.request("karyawan/history", form, 'POST')
                .then(response => {
                    this.setState({
                        loadingHistory: false
                    })
                    if (response.succes) {
                        this.setState({
                            historyKaryawan: response.data.data,
                            historyLast: response.history,
                            pageCountHistory: response.data.last_page,
                            totalDataHistory: response.data.total,
                            totalPerpageHistory: response.data.to,
                            perPageHistory: response.data.per_page,
                        });
                    }
                    else {
                        this.setState({
                            modalHistory: false,
                            karyawan_id: ''
                        });
                    }
                });
        })
    }

    handleSubmitRevert = () => {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('yakinmengembalikankaryawan'),
            icon: "warning",
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                    if (result.isConfirmed) {
                        let form = {
                            karyawan_id: this.state.id_karyawanHistory,
                            history_id: this.state.id_history,
                        }
                        this.props.request("karyawan/revert", form, 'POST')
                            .then(response => {
                                if (response.success) {
                                    Swal.fire({
                                        title: i18n.t('berhasil'),
                                        text: response.message,
                                        icon: 'success'
                                    })
                                    this.setState({
                                        modalHistory: false
                                    })
                                    this.props.history.push('/employee/employee-list/' + this.props.match.params.page)
                                    this.loadData()
                                } else {
                                    Swal.fire({
                                        title: i18n.t('gagal'),
                                        text: response.message,
                                        icon: 'warning',
                                        confirmButtonText: 'OK'
                                    })
                                }
                            })
                    }
                }
            )
    }

    handlePageHistory(historyKaryawan) {
        let selected = historyKaryawan.selected; // zero based index
        this.setState({ currentPageHistory: selected, historyKaryawan: [], loadingHistory: true }, () => {
            this.loadHistory();
        });
    }

    loadData() {
        let form = {
            paginate: this.state.paging,
            page: this.state.currentPage + 1,
            filter_nama: this.state.filter_nama,
            filter_jabatan: this.state.filter_jabatan,
            tipe_instansi : this.props.user.perusahaan.perusahaan_type
        }
        this.props.request("karyawan/get_karyawan_v2", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        loading: false
                    })

                    const kunci_pengaturan = response.data.data.map(item => {
                        let kunci = "Kunci ";
                        if (item.kunci_lokasi === '1') {
                            kunci += 'Lokasi';
                        }

                        if (item.kunci_foto === '1') {
                            kunci += 'Foto'
                        }

                        if (item.kunci_kunjungan === '1') {
                            kunci += 'Kunjungan'
                        }

                        return kunci;
                    });

                    this.setState({ kunci_pengaturan });
                }
            });
    }

    loadTrash(id) {
        this.setState({
            trashHistory: [],
            loading_history: true,
        }, () => {
            let form = {
                perusahaan_id: this.state.perusahaan_id,
                page: this.state.currentPageHistory + 1,
                paginate: 4,
            };
            this.props.request('karyawan/trash', form, 'POST')
                .then(response => {
                    if (response.success) {
                        this.setState({
                            trashHistory: response.data.data,
                            pageCountHistory: response.data.last_page,
                            totalDataHistory: response.data.total,
                            totalPerpageHistory: response.data.to,
                            perPageHistory: response.data.per_page,
                            loading_history: false
                        })
                    } else {
                        this.setState({
                            karyawan_id: 0, perusahaan_id: 0,
                            modalTrash: false,
                        })
                    }
                })
        })
    }

    restore = (id) => {
        let form = {
            karyawan_id: this.state.checked,
        }
        this.props.request('karyawan/restore', form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('databerhasildikembalikan'),
                        icon: "success",
                        showConfirmButton: false,
                        timer: 900,
                    })
                    this.loadData()
                    this.setState({
                        modalTrash: false,
                        checked: [],
                        restore: false,
                        checkAll: false,
                    })
                }
            })
    }

    deletePermanent = (id) => {
        let form = {
            karyawan_id: this.state.checked,
        }
        this.props.request('karyawan/delete-permanent', form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('databerhasildihapus'),
                        icon: "success",
                        showConfirmButton: false,
                        timer: 900,
                    })
                    this.loadData()
                    this.setState({
                        modalTrash: false,
                        checked: [],
                        permanentDelete: false,
                        checkAll: false,
                    })
                }
            })
    }

    handleCheckBoxChangeTrash(id) {
        this.state.checked.includes(id) ?
            this.setState(prevState => ({
                checked: prevState.checked.filter(currId => currId !== id),
            }), () => {
                if (this.state.checked.length !== this.state.totalDataHistory) {
                    this.setState({
                        checkAll: false
                    })
                }
            })
            :
            this.setState(prevState => ({
                checked: [...prevState.checked, id],
            }), () => {
                if (this.state.checked.length === this.state.totalDataHistory) {
                    this.setState({
                        checkAll: true
                    })
                }
            })
    }

    handleCheckBoxAllChangeTrash() {
        this.setState(prevState => ({
            checkAll: !prevState.checkAll
        }), () => {
            this.state.checkAll
                ? this.setState({
                    checked: this.state.trashHistory.map(item => item.karyawan_id)
                })
                : this.setState({
                    checked: []
                })
        })
    }

    cekMasterData() {
        const { perusahaan } = this.props.user;
        const isSekolah = perusahaan.perusahaan_type === "sekolah";

        const hasMissingData = isSekolah
            ? perusahaan.divisi === null || perusahaan.lokasi.length < 1
            : perusahaan.divisi === null || perusahaan.jabatan.length < 1 || perusahaan.lokasi.length < 1;

        if (hasMissingData) {
            Swal.fire({
                title: 'Peringatan!',
                text: 'Anda harus menambahkan Seluruh Master Data terlebih dahulu',
                icon: 'warning',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.props.history.push('/employee/shift');
                }
            });
        }
    }


    loadCheck() {
        let form = {
            perusahaan_id: this.props.user.perusahaan_id
        }
        this.props.request("check-guide-data", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        shift_check: response.shift,
                        lokasi_check: response.lokasi,
                        divisi_check: response.divisi,
                        karyawan_check: response.karyawan,
                        jam_kerja_check: response.jam_kerja ? response.jam_kerja : []
                    }, () => {
                        const isSekolah = this.props.user.perusahaan.perusahaan_type === "sekolah";
                        const isCheckEmpty = (checks) => checks.some(check => check.length === 0);

                        if (isSekolah) {
                            const checks = [this.state.lokasi_check, this.state.divisi_check, this.state.karyawan_check];
                            if (isCheckEmpty(checks)) {
                                this.setState({ confirm_modal_default: true });
                            }
                        } else {
                            const checks = [
                                this.state.shift_check,
                                this.state.lokasi_check,
                                this.state.divisi_check,
                                this.state.karyawan_check,
                                this.state.jam_kerja_check,
                            ];
                            if (isCheckEmpty(checks)) {
                                this.setState({ confirm_modal_default: true });
                            }
                        }

                        // if (this.props.user.perusahaan.perusahaan_type !== "sekolah") {
                        //     if (this.state.shift_check.length === 0 || this.state.lokasi_check.length === 0 || this.state.divisi_check.length === 0 || this.state.karyawan_check.length === 0 || this.state.jam_kerja_check.length === 0) {
                        //         this.setState({
                        //             confirm_modal_default: true
                        //         })
                        //     }
                        // } else {
                        //     if (this.state.lokasi_check.length === 0 || this.state.divisi_check.length === 0 || this.state.karyawan_check.length === 0) {
                        //         this.setState({
                        //             confirm_modal_default: true
                        //         })
                        //     }
                        // }

                    })
                }
            })
    }

    handleRowsPerPageChange = (newRowsPerPage) => {
        this.setState({ rowsPerPage: newRowsPerPage});
      };


    componentDidMount() {
        this.cekMasterData()
        this.loadData()
        this.loadCheck()
    }

    render() {
        const { rowsPerPage } = this.state;
        let no = 0;
        const stylefoto = {
            height: '50px',
            width: '50px',
            objectFit: 'cover'
        }
        const steps = [
            {
                selector: '.tambah-karyawan',
                content: 'Anda dapat menambah Karyawan disini',
            },
            {
                selector: '.daftar-karyawan',
                content: 'Daftar Karyawan anda dapat dilihat disini',
            },
            {
                selector: '.filter-karyawan',
                content: 'Anda dapat mem-Filter daftar Karyawan anda lewat sini',
            },
        ];
        const tipe = this.props.user.perusahaan.perusahaan_type === "sekolah";
        const levelPageLimited = this.props.user.user_level.level_page_limited;
        moment.locale(i18n.language);
        if (!levelPageLimited ||
            (tipe
                ? levelPageLimited.search("Siswa") >= 0
                : levelPageLimited.search("Daftar Karyawan") >= 0)) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{tipe ? i18n.t("daftarsiswa") : i18n.t("daftarkaryawan")} - iPresens</title>
                        <meta name="description" content="Detail Presensi" />
                    </Helmet>
                    <Tour steps={steps} isOpen={this.state.guide === true} onAfterOpen={this.disableBody} onBeforeClose={this.enableBody} onRequestClose={() => this.setState({ guide: false })} />
                    <ModalDefault
                        shift={this.state.shift_check}
                        lokasi={this.state.lokasi_check}
                        divisi={this.state.divisi_check}
                        karyawan={this.state.karyawan_check}
                        confirm_modal_default={this.state.confirm_modal_default}
                        setState={(name, val) => this.setState(name, val)}
                        history={this.props.history}
                        jam_kerja={this.state.jam_kerja_check}
                    />
                    <div className="container-fluid list-karyawan">
                        <div className='row pt-4'>
                            <div className='col-md-12'>
                                <div className='row'>
                                    <div className='col-md-12' style={{
                                        fontSize: "12px",
                                        fontWeight: 400,
                                        lineHeight: "16px",
                                        color: "#5D6F80"
                                    }}>iPresens Admin Panel</div>
                                </div>
                                <div className='row' style={{ marginBottom: "28px" }}>
                                    <div className='col-md-12' style={{
                                        fontSize: "24px",
                                        lineHeight: "36px",
                                        fontWeight: 700,
                                        color: "#143B5E"
                                    }}>{tipe ? i18n.t("daftarsiswa") : i18n.t("daftarkaryawan")}</div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header pt-4 pb-0 px-3" style={{borderBottom: "0px"}}>
                                <div className="row mx-0">
                                    <div className="col-md-4 px-1 karyawan-filter mb-2">
                                        <input name="filter_nama"
                                               className={this.state.filter_nama !== "" ? "form-control h-100" : "form-control bg-image-input karyawan-filter-nama"}
                                               placeholder={"                      " + tipe ? i18n.t("carinama") : i18n.t('carinama') + "..."}
                                               style={{paddingLeft: "40px"}}
                                               type="text"
                                               value={this.state.filter_nama}
                                               onChange={this.handleChange}
                                               onKeyUp={(event) => {
                                                   event.preventDefault()
                                                   if (event.which == 13) {

                                                       this.setState({
                                                           page: 0,
                                                           paging: this.state.paging,
                                                           loading: true
                                                       }, () => {
                                                           this.loadData()
                                                       });
                                                   }
                                               }}/>
                                    </div>
                                    <div className="col-md-3 px-1 d-flex flex-row flex-nowrap mb-2">
                                        <select name="filter_jabatan" value={this.state.filter_jabatan}
                                                className="form-control karyawan-filter-jabatan mr-1 btn-slc-krywn"
                                                onChange={this.handleFilter}>
                                            <option value="">{i18n.t('pilihjabatan')}</option>
                                            {this.props.jabatan.map((item, index) => {
                                                return (
                                                    <option value={item.jabatan_id}>{item.jabatan_nama}</option>
                                                )
                                            })}
                                        </select>
                                        <button className="btn ipresens-btn-reset border px-2"
                                                onClick={this.resetFilter}>
                                            <img src={RefreshIcon} alt=''/>
                                        </button>
                                    </div>
                                    <div className="col-md-5 d-flex justify-content-end flex-wrap px-1">
                                        {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("C") >= 0) && (
                                            <div className="flex-grow-1 mr-2">
                                                <button
                                                    className="btn ipresens-btn-color text-capitalize width-filter pull-right"
                                                    onClick={() => this.props.history.push("/karyawan/tambah-karyawan")}>
                                                    <img src={PlusIcon}
                                                         alt=""/> {tipe ? i18n.t("tambahsiswa") : i18n.t("tambahkaryawan")}
                                                </button>
                                            </div>
                                        )}
                                        <div className="flex-shrink-0">
                                            <Tooltip rigger={'click'} placement="bottomLeft" color={'#FFF'} title={
                                                <div className={'p-2'}>
                                                    <a onClick={() => {
                                                        this.setState({modalimport: true})
                                                    }} style={{marginRight: "50px"}} className={'text-dark'}>Import
                                                        Excel</a><br/>
                                                    <a onClick={this.exportExcelKaryawan}
                                                       style={{marginRight: "50px"}}
                                                       className={'text-dark'}
                                                    >Export Excel</a><br/>
                                                    <a onClick={() => {
                                                        this.setState({modalTrash: true});
                                                        this.loadTrash();
                                                    }}
                                                       style={{marginRight: "50px"}}
                                                       className={'text-dark'}
                                                    >{i18n.t("trashkaryawan")}</a><br/>
                                                </div>
                                            }>
                                                <a><i
                                                    style={{
                                                        width: "15",
                                                        fontSize: 18,
                                                        color: 'rgb(51 51 51)',
                                                        alignItems: "center",
                                                        paddingRight: "30px"
                                                    }}
                                                    className='btn border border-dark border-1 px-2'
                                                ><img src={MoreIcon}/></i></a>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body px-2 daftar-karyawan" style={{paddingTop: "40px"}}>
                                {!this.state.loading && this.state.data.length === 0 ?
                                    <Empty description={i18n.t("datakosong")}
                                           image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                    :
                                    <Table className="table table-striped mb-5">
                                        <Thead>
                                            <Tr>
                                                <Th style={{border: "none"}} width="30">#</Th>
                                                <Th style={{border: "none"}} className="text-left">{i18n.t('foto')}</Th>
                                                <Th style={{border: "none"}} className="text-left">{tipe ? i18n.t('namasiswa') : i18n.t('namakaryawan')}</Th>
                                                <Th style={{border: "none"}} className="text-left">{tipe ? i18n.t('kelas') : i18n.t('jabatandivisi')}</Th>
                                                <Th style={{border: "none"}} className="text-left">{i18n.t('lokasipresensi')}</Th>
                                                <Th style={{border: "none"}} className="text-left">{i18n.t('kuncipengaturan')}</Th>
                                                {!tipe && <Th style={{border: "none"}} className="text-left">{i18n.t('tipekontrak')}</Th>}
                                                <Th style={{border: "none"}} className="text-left"></Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {this.state.loading &&
                                                <Tr>
                                                    <Td colSpan={10}>
                                                        <Spin size="large" tip="Loading..." style={{
                                                            width: '100%',
                                                            margin: 'auto',
                                                            marginBottom: '10px',
                                                            marginTop: '10px'
                                                        }}/>
                                                    </Td>
                                                </Tr>
                                            }
                                            {this.state.data.map((item, index) => {
                                                no += 1
                                                return (
                                                    <Tr style={item.status_karyawan !== 'active' ? (isOdd(index) ? {backgroundColor: '#FFDED7'} : {backgroundColor: '#F9B5A6'}) : {}}>
                                                        <small>
                                                            <Th style={{border: "none"}}
                                                                scope="row">{pageNumber(no, this.state.perPage, this.state.currentPage)}</Th>
                                                        </small>
                                                        <Td style={{border: "none"}}
                                                            align="center">{item.karyawan_foto !== null ? (
                                                            <img style={stylefoto} alt=''
                                                                 src={IMG_HOST + 'mitra/frontapp/karyawan/' + item.karyawan_foto}/>
                                                        ) : (
                                                            <img style={stylefoto} alt=''
                                                                 src={IMG_HOST + "user-profile.png"}/>
                                                        )}</Td>
                                                        <Td style={{border: "none"}} align="left">
                                                            {item.status_karyawan !== 'active' && (
                                                                <div>
                                                                    <p className={'badges-danger'}>{tipe ? i18n.t("siswabelumaktif") : i18n.t("karyawanblmaktif")}</p>
                                                                </div>
                                                            )}
                                                            {item.karyawan_no_induk && (
                                                                <div>
                                                                    <p className={'ipresens-badges-induk'}>NI: {item.karyawan_no_induk}</p>
                                                                </div>
                                                            )}
                                                            <span style={{ fontWeight: 500, color: "#248CE7", fontSize: "14px" }}><a href={"/karyawan/profil/detail/" + item.karyawan_slug}>{item.karyawan_nama}</a></span>
                                                            <br />
                                                            <div>
                                                                <p className={'text-muted fs-5'} style={{ fontSize: "12px", lineHeight: "24px", fontWeight: 400 }}>{item.karyawan_email}</p>
                                                            </div>
                                                            {item.karyawan_payroll_amount === 0 ?
                                                                <a href={"/karyawan/profil/detail/" + item.karyawan_slug + '/4'} target="_blank">
                                                                    <div className={"badge badge-warning"}>{i18n.t('lengkapipayroll')}</div>
                                                                </a>
                                                                : ""
                                                            }
                                                        </Td>
                                                        <Td style={{ border: "none" }} align="left">
                                                            <span style={{ fontWeight: 400, fontSize: "14px", lineHeight: "24px", color: "#143B5E" }}>{item.jabatan && item.jabatan.jabatan_nama ? item.jabatan.jabatan_nama : '-'}</span><br /><div><p className={'text-muted fs-5'} style={{ fontSize: "14px", fontWeight: 400, lineHeight: "24px" }}>{item.divisi && item.divisi.divisi_name ? item.divisi.divisi_name : '-'}</p></div>
                                                        </Td>
                                                        <Td style={{ border: "none" }} align="left">{item.lokasi.length > 0 ? item.lokasi.map((item2, index2) => {
                                                            return (<span className="" style={{ marginRight: '2px', fontWeight: 400, fontSize: "14px", lineHeight: "24px", color: "#143B5E" }}>{item2.lokasi_nama}{item.lokasi.length > (index2 + 1) && ", "}</span>)
                                                        }) : (<div className="" style={{ marginRight: '2px', fontWeight: 400, fontSize: "14px", lineHeight: "24px", color: "#143B5E" }}>{i18n.t("semualokasi")}</div>)}</Td>
                                                        <Td style={{ border: "none" }} className="text-left">
                                                            <Dropdown className='px-1'>
                                                                <Dropdown.Toggle style={{ textTransform: "capitalize", fontSize: "12px", backgroundColor: "transparent", fontWeight: 500, lineHeight: "16px" }} className="border border-info-subtle px-1" variant="outline" id="dropdown-basic">
                                                                    {i18n.t("kuncipengaturan")}
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item style={{ fontSize: "12px", fontWeight: 400, lineHeight: "16px", color: "#143B5E" }} href="#/action-1" onClick={(val) => {
                                                                        var data = this.state.data

                                                                        data[index]['kunci_lokasi'] = (val) ? '1' : '0'

                                                                        this.setState({
                                                                            loading: true,
                                                                            data: []
                                                                        })

                                                                        let formData = {
                                                                            karyawan_id: item.karyawan_id,
                                                                        }

                                                                        this.props.request('karyawan/kunci-lokasi', formData, 'POST')
                                                                            .then((response) => {
                                                                                if (response.success) {
                                                                                    Swal.fire({
                                                                                        title: 'Berhasil!',
                                                                                        text: response.message,
                                                                                        icon: 'success',
                                                                                        showConfirmButton: false,
                                                                                        timer: 1500,
                                                                                    })
                                                                                } else {
                                                                                    if (Array.isArray(response.message)) {

                                                                                        response.message.map(item => {
                                                                                            toast['error'](item)
                                                                                            return true
                                                                                        })
                                                                                    } else {
                                                                                        toast['error'](response.message)
                                                                                    }
                                                                                }
                                                                                this.loadData()
                                                                                this.setState({loading: false})
                                                                            })

                                                                    }}>{i18n.t("kuncilokasi")} {item.kunci_lokasi === '1' ?
                                                                        <i className='fa fa-check pull-right'
                                                                           style={{color: "#248CE7"}}></i> : ''}</Dropdown.Item>
                                                                    <Dropdown.Item style={{
                                                                        fontSize: "12px",
                                                                        fontWeight: 400,
                                                                        lineHeight: "16px",
                                                                        color: "#143B5E"
                                                                    }} href="#/action-2" onClick={(val) => {
                                                                        var data = this.state.data


                                                                        data[index]['kunci_foto'] = (val) ? '1' : '0'

                                                                        this.setState({
                                                                            loading: true,
                                                                            data: []
                                                                        })

                                                                        let formData = {
                                                                            karyawan_id: item.karyawan_id,

                                                                        }

                                                                        this.props.request('karyawan/kunci-foto', formData, 'POST')
                                                                            .then((response) => {
                                                                                if (response.success) {
                                                                                    Swal.fire({
                                                                                        title: 'Berhasil!',
                                                                                        text: response.message,
                                                                                        icon: 'success',
                                                                                        showConfirmButton: false,
                                                                                        timer: 1500,
                                                                                    })
                                                                                } else {
                                                                                    if (Array.isArray(response.message)) {

                                                                                        response.message.map(item => {
                                                                                            toast['error'](item)
                                                                                            return true
                                                                                        })
                                                                                    } else {
                                                                                        toast['error'](response.message)
                                                                                    }
                                                                                }
                                                                                this.loadData()
                                                                                this.setState({loading: false})
                                                                            })

                                                                    }}>{i18n.t("kuncifoto")} {item.kunci_foto === '1' ?
                                                                        <i className='fa fa-check pull-right'
                                                                           style={{color: "#248CE7"}}></i> : ''}</Dropdown.Item>
                                                                    <Dropdown.Item style={{
                                                                        fontSize: "12px",
                                                                        fontWeight: 400,
                                                                        lineHeight: "16px",
                                                                        color: "#143B5E"
                                                                    }} href="#/action-3" onClick={(val) => {
                                                                        var data = this.state.data

                                                                        data[index]['kunci_kunjungan'] = (val) ? '1' : '0'

                                                                        this.setState({
                                                                            loading: true,
                                                                            data: []
                                                                        })

                                                                        let formData = {
                                                                            karyawan_id: item.karyawan_id,
                                                                        }

                                                                        this.props.request('karyawan/kunci-kunjungan', formData, 'POST')
                                                                            .then((response) => {
                                                                                if (response.success) {
                                                                                    Swal.fire({
                                                                                        title: 'Berhasil!',
                                                                                        text: response.message,
                                                                                        icon: 'success',
                                                                                        showConfirmButton: false,
                                                                                        timer: 1500,
                                                                                    })
                                                                                } else {
                                                                                    if (Array.isArray(response.message)) {

                                                                                        response.message.map(item => {
                                                                                            toast['error'](item)
                                                                                            return true
                                                                                        })
                                                                                    } else {
                                                                                        toast['error'](response.message)
                                                                                    }
                                                                                }
                                                                                this.loadData()
                                                                                this.setState({loading: false})
                                                                            })

                                                                    }}>{i18n.t("kuncikunjungan")} {item.kunci_kunjungan === '1' ?
                                                                        <i className='fa fa-check'
                                                                           style={{color: "#248CE7"}}></i> : ''}</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </Td>
                                                        <Td style={{border: "none"}} align="center">
                                                            {item.kontrak_aktif ? (
                                                                <div>
                                                                    <span
                                                                        className="badge badge-success">{item.kontrak_aktif.contract_type.toUpperCase()}</span><br/>
                                                                    {item.kontrak_aktif.contract_duration ? (
                                                                        <span
                                                                            className="fs-12">Exp: {formatDateToYMD(addMonthsToDate(new Date(item.kontrak_aktif.contract_start), item.kontrak_aktif.contract_duration))}</span>
                                                                    ) : null}
                                                                </div>
                                                            ) : '-'}
                                                        </Td>
                                                        <Td style={{border: "none"}} align="center">
                                                            <Tooltip trigger={'click'} placement="bottom" color={'#fff'}
                                                                     title={
                                                                         <div className={'p-2'}>
                                                                             <a href={"/karyawan/profil/detail/" + item.karyawan_slug}
                                                                                style={{marginRight: "50px"}}
                                                                                className={'text-dark'}>Detail</a><br/>
                                                                             <a href={"/karyawan/edit-karyawan/" + item.karyawan_slug + "/" + this.state.currentPage}
                                                                                className={'text-dark'}>Edit</a><br/>
                                                                             <a onClick={() => {
                                                                                 this.setState({
                                                                                     modalHistory: true,
                                                                                     karyawan_id: item.karyawan_id
                                                                                 }, () => {
                                                                                     this.loadHistory(item.karyawan_id);
                                                                                 })
                                                                             }} style={{marginRight: "50px"}}
                                                                                className={'text-dark'}>{i18n.t('riwayat')}</a><br/>

                                                                             <a onClick={() => {
                                                                                 this.setState({
                                                                                     idDel: item.karyawan_id
                                                                                 })
                                                                                 this.wantDelete()
                                                                             }}
                                                                                className={'text-danger'}>{i18n.t("hapus")}</a>
                                                                         </div>
                                                                     }>
                                                                <a><i
                                                                    className="fa fa-ellipsis-v" style={{
                                                                    width: 35,
                                                                    fontSize: 18,
                                                                    padding: 11,
                                                                    color: 'rgb(51 51 51)'
                                                                }}
                                                                ></i></a>
                                                            </Tooltip>
                                                        </Td>
                                                    </Tr>
                                                )
                                            })}
                                        </Tbody>
                                    </Table>
                                }
                                <Paging
                                    className="float-right"
                                    handlePageClick={this.handlePageClick}
                                    pageCount={this.state.pageCount}
                                    currentPage={this.state.currentPage}
                                />
                                <Modal
                                    title="Import Karyawan"
                                    visible={this.state.modalimport}
                                    onOk={this.handleOk}
                                    onCancel={this.handleCancel}
                                    footer={[
                                        this.state.importLoading ?
                                            <button className={'btn btn-primary'}
                                                    style={{textTransform: "none", width: "100%"}} disabled={true}><i
                                                className="fa fa-refresh fa-spin"></i>{i18n.t('prosesupload')}</button>
                                            :
                                            <button
                                                className={this.state.importFile === null ? 'btn bg-secondary-custom' : 'btn ipresens-btn-color'}
                                                style={{textTransform: "none", width: "100%"}}
                                                disabled={this.state.importFile === null}
                                                onClick={this.importExcel}>{i18n.t('importdata')}</button>
                                    ]}
                                >
                                    <div>
                                        <a style={{
                                            color: "#5D6F80",
                                            cursor: "default"
                                        }}>{i18n.t('andabisamengunduhtemplate')}</a><br/>
                                        <a onClick={this.exportKaryawan}
                                           style={{color: "#057CE4"}}>{i18n.t('unduhtemplatedisini')}</a>
                                        <div style={{
                                            position: 'relative',
                                            width: '100%',
                                            backgroundColor: '#F9F9F9',
                                            padding: 16,
                                            borderRadius: 6,
                                            marginTop: 8,
                                            border: "1px dashed #DDDDDD"
                                        }}>
                                            <input type='file'
                                                   accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                   onChange={(event) => {
                                                       this.setState({
                                                           importFile: event.target.files[0],
                                                       })
                                                   }}/><br/>
                                        </div>
                                    </div>
                                </Modal>
                                <Modal
                                    title="Aksi Karyawan"
                                    visible={this.state.modalAction}
                                    onOk={this.handleOk}
                                    onCancel={this.handleCancel}
                                    footer={[]}
                                >
                                    <a href={"/karyawan/profil/detail/" + this.state.dataAction.karyawan_slug}><i
                                        className="fa fa-eye" style={{
                                        width: 35,
                                        fontSize: 20,
                                        padding: 11,
                                        color: 'rgb(9 153 255)'
                                    }}
                                    ></i>Detail</a>
                                    <a href={"/karyawan/edit-karyawan/" + this.state.dataAction.karyawan_id}><i
                                        className="fa fa-pencil" style={{
                                        width: 35,
                                        fontSize: 20,
                                        padding: 11,
                                        color: 'rgb(255 172 21)'
                                    }}
                                    ></i>Edit</a>
                                    <a onClick={() => {
                                        this.setState({
                                            idDel: this.state.dataAction.karyawan_id
                                        })
                                        this.wantDelete()
                                    }}><i className="fa fa-trash" style={{
                                        width: 35,
                                        fontSize: 20,
                                        padding: 11,
                                        color: 'rgb(255 37 37)'
                                    }}
                                    ></i>{i18n.t('hapus')}
                                    </a>
                                </Modal>
                                <ModalRs size="lg" style={{maxWidth: "1000px"}} isOpen={this.state.modalHistory}
                                         toggle={() => this.setState({modalHistory: false})}>
                                    <ModalHeader style={{backgroundColor: "#FAFDFF"}}
                                                 toggle={() => this.setState({modalHistory: false})}>
                                        <div className='modal-header-reactstrap'>{i18n.t('riwayatperubahan')}</div>
                                    </ModalHeader>
                                    <ModalBody>
                                        <div className="list-group">
                                            <div>
                                                {this.state.loadingHistory && (
                                                    <div style={{textAlign: 'center', margin: '10px auto'}}>
                                                        <Spin
                                                            size="large"
                                                            tip="Loading..."
                                                            style={{
                                                                width: '100%',
                                                                margin: 'auto',
                                                            }}
                                                        />
                                                    </div>
                                                )}

                                                {this.state.historyKaryawan.length !== 0 && (
                                                    <div>
                                                        <Row>
                                                            <Col xs={0} md={0} className="text-left"
                                                                 style={{marginLeft: '20px'}}>
                                                                <h4>User</h4>
                                                            </Col>
                                                            <Col xs={0} md={0} className="text-left"
                                                                 style={{marginRight: '20px'}}>
                                                                <h4>{i18n.t('aktivitas')}</h4>
                                                            </Col>
                                                        </Row>

                                                        {this.state.historyKaryawan.map((item, index) => {
                                                            return (
                                                                <div key={index} style={{marginBottom: '20px'}}>
                                                                    <Row>
                                                                        <Col xs={12} md={6} className="text-left">
                                                                            <div style={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                paddingTop: '16px',
                                                                                paddingBottom: '16px',
                                                                                paddingRight: '12px',
                                                                                paddingLeft: '12px'
                                                                            }}>
                                                                                <div style={{marginRight: '16px'}}>
                                                                                    <img
                                                                                        src={DEFAULT_IMG}
                                                                                        alt=""
                                                                                        style={{
                                                                                            width: '48px',
                                                                                            marginBottom: '25px',
                                                                                            height: '48px'
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-0 font-13"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {item.p_user_name} | {item.level_name}
                                                                                    </h6>
                                                                                    <p className="m-0 font-12">
                                                                                    <span
                                                                                        className="col-green font-weight-bold">
                                                                                      {moment(item.created_at).format('lll')}
                                                                                    </span>
                                                                                    </p>
                                                                                    <div style={{marginTop: 12}}>
                                                                                        {item.history_status === "created" ? (
                                                                                            <div className="row px-3">
                                                                                                <div
                                                                                                    className="mr-2"
                                                                                                    style={{
                                                                                                        border: '1px solid #000',
                                                                                                        borderRadius: '25px',
                                                                                                        width: '50%',
                                                                                                        color: '#7805e4',
                                                                                                        borderColor: '#7805e4',
                                                                                                    }}
                                                                                                >
                                                                                                    <div
                                                                                                        className="d-flex justify-content-center"
                                                                                                        style={{
                                                                                                            paddingTop: '6px',
                                                                                                            paddingBottom: '6px'
                                                                                                        }}>
                                                                                                        {i18n.t('dibuat')}
                                                                                                    </div>
                                                                                                </div>
                                                                                                {this.state.historyLast.history_id === item.history_id ?
                                                                                                    "" :
                                                                                                    <button
                                                                                                        className='col-md-5 d-flex justify-content-center ipresens-btn-revert'
                                                                                                        onClick={() => {
                                                                                                            this.setState({
                                                                                                                id_karyawanHistory: item.karyawan_id,
                                                                                                                id_history: item.history_id,
                                                                                                            })
                                                                                                            this.handleSubmitRevert()
                                                                                                        }}>{i18n.t('revert')}
                                                                                                    </button>
                                                                                                }
                                                                                            </div>
                                                                                        ) : item.history_status === "reverted" ?
                                                                                            <div
                                                                                                className="mr-2 px-5"
                                                                                                style={{
                                                                                                    border: '1px solid #000',
                                                                                                    borderRadius: '25px',
                                                                                                    width: '70%',
                                                                                                    color: '#7805e4',
                                                                                                    borderColor: '#7805e4',
                                                                                                }}
                                                                                            >
                                                                                                <div
                                                                                                    className="d-flex justify-content-center"
                                                                                                    style={{
                                                                                                        paddingTop: '6px',
                                                                                                        paddingBottom: '6px'
                                                                                                    }}>
                                                                                                    {i18n.t('dikembalikan')}
                                                                                                </div>
                                                                                            </div>
                                                                                            : (
                                                                                                <div className="row px-3">
                                                                                                    <div
                                                                                                        className="mr-2"
                                                                                                        style={{
                                                                                                            border: '1px solid #000',
                                                                                                            borderRadius: '25px',
                                                                                                            width: '50%',
                                                                                                            color: '#E6462E',
                                                                                                            borderColor: '#E6462E',
                                                                                                        }}
                                                                                                    >
                                                                                                        <div
                                                                                                            className="d-flex justify-content-center"
                                                                                                            style={{
                                                                                                                paddingTop: '6px',
                                                                                                                paddingBottom: '6px'
                                                                                                            }}>
                                                                                                            {i18n.t('diubah')}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {this.state.historyLast.history_id === item.history_id ?
                                                                                                        "" :
                                                                                                        <button
                                                                                                            className='col-md-5 d-flex justify-content-center ipresens-btn-revert'
                                                                                                            onClick={() => {
                                                                                                                this.setState({
                                                                                                                    id_karyawanHistory: item.karyawan_id,
                                                                                                                    id_history: item.history_id,
                                                                                                                })
                                                                                                                this.handleSubmitRevert()
                                                                                                            }}>{i18n.t('revert')}
                                                                                                        </button>
                                                                                                    }
                                                                                                </div>
                                                                                            )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs={12} md={6} className="text-left"
                                                                             style={{
                                                                                 paddingTop: '16px',
                                                                                 paddingBottom: '16px',
                                                                                 paddingRight: '12px',
                                                                                 paddingLeft: '12px'
                                                                             }}>
                                                                            {item.history_status !== 'created' && (
                                                                                <div>
                                                                                    {item.parent_history ?
                                                                                        item.parent_history.karyawan_nama != item.karyawan_nama && (
                                                                                            <div>
                                                                                                <h6
                                                                                                    className="mb-3 font-13 text-left "
                                                                                                    style={{
                                                                                                        fontWeight: 100,
                                                                                                        fontSize: '16px',
                                                                                                        lineHeight: '24px',
                                                                                                    }}
                                                                                                >
                                                                                                    {i18n.t('karyawannamadari')}{' '}
                                                                                                    {item.parent_history
                                                                                                        ? item.parent_history.karyawan_nama
                                                                                                        : 'N/A'}{' '}
                                                                                                    <img
                                                                                                        src={ArrowRight}
                                                                                                        alt=""
                                                                                                        style={{
                                                                                                            width: '20x',
                                                                                                            marginBottom: '1px',
                                                                                                            height: '18px'
                                                                                                        }}
                                                                                                    /> {i18n.t('jadititikdua')} {item.karyawan_nama ? item.karyawan_nama : ''}
                                                                                                </h6>
                                                                                            </div>
                                                                                        ) : null
                                                                                    }
                                                                                    {item.parent_history ?
                                                                                        item.parent_history.no_hp != item.no_hp && (
                                                                                            <div>
                                                                                                <h6
                                                                                                    className="mb-3 font-13 text-left"
                                                                                                    style={{
                                                                                                        fontWeight: 100,
                                                                                                        fontSize: '16px',
                                                                                                        lineHeight: '24px',
                                                                                                    }}
                                                                                                >
                                                                                                    {i18n.t('nohpdari')}{' '}
                                                                                                    {item.parent_history
                                                                                                        ? item.parent_history.no_hp
                                                                                                        : 'N/A'}{' '}
                                                                                                    <img
                                                                                                        src={ArrowRight}
                                                                                                        alt=""
                                                                                                        style={{
                                                                                                            width: '20x',
                                                                                                            marginBottom: '1px',
                                                                                                            height: '18px'
                                                                                                        }}
                                                                                                    /> {i18n.t('jadititikdua')} {item.no_hp ? item.no_hp : ''}
                                                                                                </h6>
                                                                                            </div>
                                                                                        ) : null
                                                                                    }
                                                                                    {item.parent_history ?
                                                                                        item.parent_history.karyawan_alamat != item.karyawan_alamat && (
                                                                                            <div>
                                                                                                <h6
                                                                                                    className="mb-3 font-13 text-left"
                                                                                                    style={{
                                                                                                        fontWeight: 100,
                                                                                                        fontSize: '16px',
                                                                                                        lineHeight: '24px',
                                                                                                    }}
                                                                                                >
                                                                                                    {i18n.t('alamatkaryawandari')}{' '}
                                                                                                    {item.parent_history
                                                                                                        ? item.parent_history.karyawan_alamat
                                                                                                        : 'N/A'}{' '}
                                                                                                    <img
                                                                                                        src={ArrowRight}
                                                                                                        alt=""
                                                                                                        style={{
                                                                                                            width: '20x',
                                                                                                            marginBottom: '1px',
                                                                                                            height: '18px'
                                                                                                        }}
                                                                                                    /> {i18n.t('jadititikdua')} {item.karyawan_alamat ? item.karyawan_alamat : ''}
                                                                                                </h6>
                                                                                            </div>
                                                                                        ) : null
                                                                                    }
                                                                                    {item.parent_history ?
                                                                                        item.parent_history.status_karyawan != item.status_karyawan && (
                                                                                            <div>
                                                                                                <h6
                                                                                                    className="mb-3 font-13 text-left"
                                                                                                    style={{
                                                                                                        fontWeight: 100,
                                                                                                        fontSize: '16px',
                                                                                                        lineHeight: '24px',
                                                                                                    }}
                                                                                                >
                                                                                                    {i18n.t('statuskaryawandari')}{' '}
                                                                                                    {item.parent_history
                                                                                                        ? item.parent_history.status_karyawan
                                                                                                        : 'N/A'}{' '}
                                                                                                    <img
                                                                                                        src={ArrowRight}
                                                                                                        alt=""
                                                                                                        style={{
                                                                                                            width: '20x',
                                                                                                            marginBottom: '1px',
                                                                                                            height: '18px'
                                                                                                        }}
                                                                                                    /> {i18n.t('jadititikdua')} {item.status_karyawan ? item.status_karyawan : ''}
                                                                                                </h6>
                                                                                            </div>
                                                                                        ) : null
                                                                                    }
                                                                                </div>
                                                                            )}
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </ModalBody>
                                    <ModalFooter style={{ backgroundColor: "#F5F5F5" }}>
                                        <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                                            {this.state.historyKaryawan.length !== 0 &&
                                                <>
                                                    <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>{i18n.t('menampilkan')} {this.state.historyKaryawan.length === 0 || this.state.historyKaryawan.length === null ? "0" : this.state.historyKaryawan.length} {i18n.t('dari')} {this.state.totalDataHistory} {i18n.t("data")}</div>
                                                    <div className='col'>
                                                        <ReactPaginate
                                                            previousLabel={<img src={ArrowLeft} alt='' />}
                                                            nextLabel={<img src={ArrowRight} alt='' />}
                                                            pageCount={this.state.pageCountHistory}
                                                            forcePage={this.state.currentPageHistory}
                                                            marginPagesDisplayed={2}
                                                            pageRangeDisplayed={3}
                                                            onPageChange={this.handlePageHistory}
                                                            breakClassName={'page-item'}
                                                            breakLinkClassName={'page-link custom-page-link'}
                                                            containerClassName={'pagination my-2'}
                                                            pageClassName={'page-item'}
                                                            pageLinkClassName={'page-link custom-page-link'}
                                                            previousClassName={'page-item'}
                                                            previousLinkClassName={'page-link custom-prev-next-link'}
                                                            nextClassName={'page-item'}
                                                            nextLinkClassName={'page-link custom-prev-next-link'}
                                                            activeClassName={'active custom-active'}
                                                        />
                                                    </div>
                                                </>
                                            }
                                        </div>

                                    </ModalFooter>
                                </ModalRs>
                                <ModalRs size="lg" zIndex={999} isOpen={this.state.modalTrash} toggle={() => this.setState({
                                    modalTrash: false,
                                    checked: [],
                                    trashHistory: [],
                                    checkAll: false,
                                })} style={{maxWidth: "1000px"}}>
                                    <ModalHeader className='pengumuman-modal-header'>
                                        <div className='pengumuman-modal-header-font'>{i18n.t("trashkaryawan")}</div>
                                    </ModalHeader>
                                    <ModalBody>
                                        <div className="row d-flex justify-content-end">
                                            <div className="col-md-6"></div>
                                            <div className="col-md-6 d-flex justify-content-end">
                                                <button className="btn ipresens-btn-color px-3 py-2 mx-1"
                                                        disabled={this.state.checked.length === 0}
                                                        onClick={() => {
                                                            this.setState({restore: true});
                                                        }}>
                                                    Restore
                                                </button>
                                                <button className="btn ipresens-btn-color px-3 py-2 mx-1"
                                                        disabled={this.state.checked.length === 0} onClick={() => {
                                                    this.setState({permanentDelete: true});
                                                }}>Delete Permanent
                                                </button>
                                            </div>
                                        </div>
                                        <div className="list-group">
                                            <div>
                                                {this.state.loading_history && (
                                                    <div style={{textAlign: "center", margin: '10px auto'}}>
                                                        <Spin size="large" tip={i18n.t('loading')}
                                                              style={{width: "100%", margin: "auto"}}/>
                                                    </div>
                                                )}
                                                {this.state.trashHistory.length !== 0 && (
                                                    <Table className="table table-striped mb-5">
                                                        <Thead>
                                                            <Tr>
                                                                <Th width="30"><Checkbox checked={this.state.checkAll}
                                                                                         onChange={this.handleCheckBoxAllChangeTrash}></Checkbox></Th>
                                                                <Th style={{ border: "none" }} className="text-left">{i18n.t('foto')}</Th>
                                                                <Th style={{ border: "none" }} className="text-left">{tipe ? i18n.t('namasiswa') : i18n.t('namakaryawan')}</Th>
                                                                <Th style={{ border: "none" }} className="text-left">{i18n.t('tanggalbergabung')}</Th>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            {this.state.trashHistory.map((item) => {
                                                                return (
                                                                    <Tr>
                                                                        <Td><Checkbox
                                                                            checked={this.state.checked.includes(item.karyawan_id)}
                                                                            onChange={() => this.handleCheckBoxChangeTrash(item.karyawan_id)}></Checkbox></Td>
                                                                        <Td style={{border: "none"}}
                                                                            align="center">{item.karyawan_foto !== null ? (
                                                                            <img style={stylefoto} alt=''
                                                                                 src={IMG_HOST + 'mitra/frontapp/karyawan/' + item.karyawan_foto}/>
                                                                        ) : (
                                                                            <img style={stylefoto} alt=''
                                                                                 src={IMG_HOST + "user-profile.png"}/>
                                                                        )}</Td>
                                                                        <Td style={{border: "none"}} align="left">
                                                                            {item.status_karyawan !== 'active' && (
                                                                                <div>
                                                                                    <p className={'badges-danger'}>{tipe ? i18n.t("siswabelumaktif") : i18n.t("karyawanblmaktif")}</p>
                                                                                </div>
                                                                            )}
                                                                            {item.karyawan_no_induk && (
                                                                                <div>
                                                                                    <p className={'ipresens-badges-induk'}>NI: {item.karyawan_no_induk}</p>
                                                                                </div>
                                                                            )}
                                                                            <span style={{ fontWeight: 500, color: "#248CE7", fontSize: "14px" }}><a href={"/karyawan/profil/detail/" + item.karyawan_slug}>{item.karyawan_nama}</a></span>
                                                                            <br />
                                                                            <div>
                                                                                <p className={'text-muted fs-5'} style={{ fontSize: "12px", lineHeight: "24px", fontWeight: 400 }}>{item.karyawan_email}</p>
                                                                            </div>
                                                                            {item.karyawan_payroll_amount === 0 ?
                                                                                    <div className={"badge badge-warning"}>{i18n.t('lengkapipayroll')}</div>
                                                                                : ""
                                                                            }
                                                                        </Td>
                                                                        <Td>{item.karyawan_join_date}</Td>
                                                                    </Tr>
                                                                );
                                                            })}
                                                        </Tbody>
                                                    </Table>
                                                )
                                                }
                                            </div>
                                        </div>
                                    </ModalBody>
                                    <ModalFooter style={{
                                        backgroundColor: "#F5F5F5",
                                        borderBottomLeftRadius: "8px",
                                        borderBottomRightRadius: "8px"
                                    }}>
                                        <div className='row mx-0' style={{backgroundColor: "#F5F5F5", width: "100%"}}>
                                            {this.state.trashHistory.length !== 0 &&
                                                <>
                                                    <div className='col font-14' style={{
                                                        padding: "auto",
                                                        margin: "auto",
                                                        fontWeight: 500,
                                                        lineHeight: "24px"
                                                    }}>{i18n.t("menampilkan")} {this.state.trashHistory.length === 0 || this.state.trashHistory.length === null ? "0" : this.state.trashHistory.length} {i18n.t("dari")} {this.state.totalDataHistory} {i18n.t("data")}</div>
                                                    <div className='col'>
                                                        <ReactPaginate
                                                            previousLabel={<img src={ArrowLeft} alt=''/>}
                                                            nextLabel={<img src={ArrowRight} alt=''/>}
                                                            pageCount={this.state.pageCountHistory}
                                                            forcePage={this.state.currentPageHistory}
                                                            marginPagesDisplayed={2}
                                                            pageRangeDisplayed={3}
                                                            onPageChange={this.handlePageTrashHistory}
                                                            breakClassName={'page-item'}
                                                            breakLinkClassName={'page-link custom-page-link'}
                                                            containerClassName={'pagination my-2'}
                                                            pageClassName={'page-item'}
                                                            pageLinkClassName={'page-link custom-page-link'}
                                                            previousClassName={'page-item'}
                                                            previousLinkClassName={'page-link custom-prev-next-link'}
                                                            nextClassName={'page-item'}
                                                            nextLinkClassName={'page-link custom-prev-next-link'}
                                                            activeClassName={'active custom-active'}
                                                        />
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </ModalFooter>
                                </ModalRs>
                                <ModalRs centered isOpen={this.state.permanentDelete} toggle={() => {
                                    this.setState({
                                        permanentDelete: false
                                    })
                                }}>
                                    <ModalHeader className='pl-4 border-bottom-0' toggle={() => {
                                        this.setState({
                                            permanentDelete: false
                                        })
                                    }}>
                                        <div className='d-flex'>
                                            <div className='p-3 mr-4' style={{
                                                backgroundColor: "transparent",
                                                borderRadius: "8px"
                                            }}>
                                                <img alt='' src={Trash} />
                                            </div>
                                            <div>
                                                <div style={{
                                                    color: "#143B5E",
                                                    fontWeight: 700,
                                                    fontSize: "18px",
                                                    lineHeight: "27px"
                                                }}>Hapus Permanen Data Karyawan?</div>
                                                <div style={{
                                                    color: "#5D6F80",
                                                    fontWeight: 400,
                                                    fontSize: "14px",
                                                    lineHeight: "24px"
                                                }}>{this.state.checked.length} Karyawan Terpilih</div>
                                            </div>
                                        </div>
                                    </ModalHeader>
                                    <ModalFooter style={{
                                        borderTop: 0
                                    }}>
                                        <div className='row mx-0' style={{ width: "100%" }}>
                                            <div className='col-md-6 px-1'>
                                                <button className='btn ipresens-btn-cancel' style={{ width: "100%" }} onClick={() => {
                                                    this.setState({
                                                        permanentDelete: false
                                                    })
                                                }}>
                                                    {i18n.t('batal')}
                                                </button>
                                            </div>
                                            <div className='col-md-6 px-1'>
                                                <button className='btn ipresens-btn-color' style={{
                                                    fontSize: "14px",
                                                    fontWeight: 500,
                                                    lineHeight: "24px",
                                                    width: "100%"
                                                }} onClick={this.deletePermanent}>
                                                    {i18n.t('ya')}
                                                </button>
                                            </div>
                                        </div>
                                    </ModalFooter>
                                </ModalRs>
                                <ModalRs centered isOpen={this.state.restore} toggle={() => {
                                    this.setState({
                                        restore: false
                                    })
                                }}>
                                    <ModalHeader className='pl-4 border-bottom-0' toggle={() => {
                                        this.setState({
                                            restore: false
                                        })
                                    }}>
                                        <div className='d-flex'>
                                            <div className='p-3 mr-4' style={{
                                                backgroundColor: "#8C2BEE",
                                                borderRadius: "8px"
                                            }}>
                                                <img alt='' src={Restore} />
                                            </div>
                                            <div>
                                                <div style={{
                                                    color: "#143B5E",
                                                    fontWeight: 700,
                                                    fontSize: "18px",
                                                    lineHeight: "27px"
                                                }}>Pulihkan Data Karyawan</div>
                                                <div style={{
                                                    color: "#5D6F80",
                                                    fontWeight: 400,
                                                    fontSize: "14px",
                                                    lineHeight: "24px"
                                                }}>{this.state.checked.length} Karyawan Terpilih</div>
                                            </div>
                                        </div>
                                    </ModalHeader>
                                    <ModalFooter style={{
                                        borderTop: 0
                                    }}>
                                        <div className='row mx-0' style={{ width: "100%" }}>
                                            <div className='col-md-6 px-1'>
                                                <button className='btn ipresens-btn-cancel' style={{ width: "100%" }} onClick={() => {
                                                    this.setState({
                                                        restore: false
                                                    })
                                                }}>
                                                    {i18n.t('batal')}
                                                </button>
                                            </div>
                                            <div className='col-md-6 px-1'>
                                                <button className='btn ipresens-btn-color' style={{
                                                    fontSize: "14px",
                                                    fontWeight: 500,
                                                    lineHeight: "24px",
                                                    width: "100%"
                                                }} onClick={this.restore}>
                                                    {i18n.t('ya')}
                                                </button>
                                            </div>
                                        </div>
                                    </ModalFooter>
                                </ModalRs>
                                <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>
                                    {i18n.t("menampilkan")} {this.state.data.length === 0 || this.state.data.length === null ? "0" : this.state.data.length} {i18n.t("dari")} {this.state.totalData} {i18n.t("data")}.
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>

            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">{i18n.t("tidakadaakseshalaman")}</h5>
                </Fragment>
            )
        }

    }
}


const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token,
    jabatan: state.Filter._jabatan
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(List_karyawan);
