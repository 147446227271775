import React, {Component} from 'react'
import {Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import i18n from "i18next";
import SaveIcon from "../../../assets/icons/save.svg";
import Swal from "sweetalert2";

class AddIpWhitelist extends Component {
    constructor(props) {
        super(props)
        this.state = {
            rows:[{}],
        }
    }

    handleAdd = () => {
        this.setState({ prosesSimpan: true})
        let form = {
            rows : this.state.rows,
            ip_start : this.props.ip_start,
            ip_end: this.props.ip_end,
        }
        this.props.request("whitelist/add", form, 'POST')
            .then((response) => {
                if (response.success) {
                    this.props.changeState({
                        modal_add: false,
                    }, () => this.props.loadData())
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('databerhasilditambahkan'),
                        icon:'success',
                        confirmButtonText: 'OK'
                    })
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: response.message,
                        icon: 'error',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({ input: "", rows: [{}], ip_start: [], ip_end: '', prosesSimpan: false })
            })
    }

    handleRowChange = (index, field, value) => {
        value = value.replace(/[^0-9.x]/g, "")
        let parts = value.split(".")
        parts = parts.map((part) => part.slice(0, 3)).slice(0, 4)
        let formattedIp = parts.join(".")
        let newRows = [...this.state.rows]
        newRows[index][field] = formattedIp;
        this.setState({ rows: newRows })
    }

    addRow = () => {
        this.setState(prevState => ({
            rows: [...prevState.rows, { ip: '' }]
        }))
    }

    removeRow = () => {
        this.setState(prevState => ({
            rows: prevState.rows.slice(0, -1)
        }))
    }

    render() {
        return (
            <Modal isOpen={this.props.modal_add} toggle={() => this.props.changeState({ modal_add: false })} style={{ maxWidth: "1000px"}} zIndex={999}>
                <ModalHeader className="modal-header-ipresens" toggle={() => this.props.changeState({ modal_add: false })}>
                    <div className='modal-header-ipresens'>{i18n.t("tambahip")}</div>
                </ModalHeader>
                <ModalBody className="inventaris-modal-body">
                    <div className="row p-3">
                        <div className="col">
                            <div className="form-group">
                                <label className="pengumuman-label-input" htmlFor="exampleFormControlInput1">
                                    {i18n.t('pilihtipeinputip')}
                                </label>
                                <select className="form-control fs-14 py-2"
                                        name="input" value={this.state.input}
                                        onChange={(e) => {
                                            const { name, value } = e.target;
                                            this.setState({
                                                [name]: value,
                                            })

                                            if (value === "byone") {
                                                this.props.changeState({
                                                    ip_start    : "",
                                                    ip_end      : ""
                                                });
                                            } else if (value === "range") {
                                                this.setState({
                                                    rows        : [{}],
                                                });
                                            }
                                        }}
                                >
                                    <option value="">{i18n.t("pilihtipeinputip")}</option>
                                    <option value="byone">{i18n.t("byone")}</option>
                                    <option value="range">{i18n.t("range")}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row p-3">
                        <div className="col">
                            <div className="form-group">
                                {this.state.input === "byone" &&
                                    <>
                                        {this.state.rows.map((row, index) => (
                                            <div key={index}>
                                                <div className="row">
                                                    <div className="col 1 col-md-1">
                                                        <label className="mb-2 pengumuman-label-input">
                                                            IP {index + 1}
                                                        </label>
                                                    </div>
                                                    <div className="col-7 col-md-5">
                                                        <input
                                                            name={`ip_${index}`}
                                                            className="form-control mb-2 py-2"
                                                            style={{fontSize: "12px"}}
                                                            placeholder={i18n.t("masukkanip") + " (ex : 192.168.1.x)"}
                                                            type="text"
                                                            value={row.ip}
                                                            onChange={(e) => this.handleRowChange(index, 'ip', e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="col-3 col-md-1 p-0">
                                                        {this.state.rows.length !== 1 &&
                                                            <button
                                                                className="btn btn-danger mb-2"
                                                                style={{padding: "7px 24px"}}
                                                                onClick={this.removeRow}>
                                                                <i className="fa fa-trash"></i>
                                                            </button>
                                                        }
                                                        {this.state.rows.length - 1 === index &&
                                                            <button
                                                                className="btn ipresens-btn-color"
                                                                style={{padding: "7px 24px"}}
                                                                onClick={this.addRow}>
                                                                <i className="fa fa-plus"></i>
                                                            </button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                }
                                {this.state.input === "range" &&
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <label className="pengumuman-label-input">
                                                    {i18n.t("dari")}
                                                </label>
                                                <input type='text' onChange={this.props.handleInputIP}
                                                       name='ip_start' value={this.props.ip_start}
                                                       className='form-control font-12'
                                                       placeholder={i18n.t("masukkanip") + " (ex : 192.168.1.x)"}/>
                                            </div>
                                            <div className="col">
                                                <label className="pengumuman-label-input">
                                                    {i18n.t("sampai")}
                                                </label>
                                                <input type='text' onChange={this.props.handleInputIP}
                                                       name='ip_end' value={this.props.ip_end}
                                                       className='form-control font-12'
                                                       placeholder={i18n.t("masukkanip") + " (ex : 192.168.1.x)"}/>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className="inventaris-modal-footer">
                    <button onClick={() => this.props.changeState({modal_add: false})}
                            className='ipresens-cancel-button'>{i18n.t("batal")}</button>
                    <button className='ipresens-save-button' disabled={this.state.prosesSimpan}
                            onClick={this.handleAdd}><img alt='' src={SaveIcon}/>{this.state.prosesSimpan ? i18n.t("menyimpan") : i18n.t("simpan")}
                    </button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default AddIpWhitelist;