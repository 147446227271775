import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import App from "./components/app";
import Page404 from "./components/404/404-page";

const checkAllowed = (type, allowed) => {
    return Array.isArray(allowed) ? allowed.includes(type) : allowed === type;
};

const AdminRoute = ({ component: Component, auth, user, allowed = ['sekolah', 'perusahaan'], ...rest }) => {
    return (
        <App request={(action, data, method = 'GET') => rest.request(action, data, method)}>
            <Route
                {...rest}
                render={(props) => {
                    if (!auth) {
                        return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
                    }

                    if (user.perusahaan.status === 'pending' || user.perusahaan.status === 'expired') {
                        return <Redirect to={{ pathname: "/subscription", state: { from: props.location } }} />;
                    }

                    if (checkAllowed(user.perusahaan.perusahaan_type, allowed)) {
                        return <Component {...props} request={(action, data, method = 'POST') => rest.request(action, data, method)} />;
                    }

                    return <Page404 />;
                }}
            />
        </App>
    );
};

const mapStateToProps = state => ({
    auth: state.Auth.auth,
    user: state.Auth.user
});

export default connect(mapStateToProps)(AdminRoute);
