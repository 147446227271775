import React, {Component} from "react";
import {Modal as ModalRs, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import i18n from "i18next";
import Select from "react-select";
import TimeInput from "../../../helper/time_input";
import TrashIcon from "../../../assets/icons/trash.svg";
import Swal from "sweetalert2";
import moment from "moment";

export class EditModalAbsensi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jam_masuk: '',
            jam_keluar: '',
            jam_masuk_absen: '',
            jam_keluar_absen: '',
            tanggal: this.props.tanggal,
            karyawan_selected: null,
            karyawan_loading: false,
            karyawan_name: '',
            karyawan: [],
            karyawan_id: 0,
            pickKaryawan: false,
            shift: [],
            mapel: [],
            sift_selected: null,
            mapel_selected: null,
            sift_id: 0,
            pickSift: false,
            lokasi_out_loading: false,
            lokasi_out: [],
            lokasi_in: [],
            lokasi_in_loading: false,
            lokasi_id: 0,
            keterangan_masuk: '',
            loading_selected: false,
            status_masuk: '',
            status_keluar: '',
            keterangan_keluar: '',
            rows_length: 0,
            istirahat_start: [],
            istirahat_end: [],
            istirahat_times: [{ start: '', end: '' }],
            prosesSimpan: false,
            dataDetail: [],
            istirahat: [],
            times: [],
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    searchKaryawan() {
        let form = {
            karyawan_name: this.state.karyawan_name
        }
        this.props.request("karyawan/search-karyawan", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        karyawan: response.data,
                        karyawan_loading: false
                    })
                }
            })
    }

    searchLokasiIn() {
        let form = {
            lokasi_nama: this.state.lokasi_nama_masuk
        }
        this.props.request("search_lokasi_perusahaan", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        lokasi_in: response.data,
                        lokasi_in_loading: false
                    })
                }
            })
    }

    searchLokasiOut() {
        let form = {
            lokasi_nama: this.state.lokasi_nama_keluar
        }
        this.props.request("search_lokasi_perusahaan", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        lokasi_out: response.data,
                        lokasi_out_loading: false
                    })
                }
            })
    }

    // handleDeleteLastRow = () => {
    //     this.setState(prevState => {
    //         const updatedIstirahatTimes = [...prevState.istirahat_times];
    //         // Hapus elemen terakhir jika ada
    //         if (updatedIstirahatTimes.length > 0) {
    //             updatedIstirahatTimes.pop(); // Menghapus elemen terakhir
    //         }
    //         return {
    //             istirahat_times: updatedIstirahatTimes,
    //             rows_length: updatedIstirahatTimes.length // Perbarui rows_length
    //         };
    //     });
    // };

    handleDeleteLastRow = () => {
        if (this.state.rows_length > 0) {
            const newTimes = this.state.times.slice(0, -1);
            this.setState(prevState => ({
                rows_length: prevState.rows_length - 1,
                times: newTimes
            }));
        }
    };

    // handleTimeChange = (index, type, time) => {
    //     this.setState(prevState => {
    //         const updatedIstirahatTimes = [...prevState.istirahat_times];
    //
    //         updatedIstirahatTimes[index] = {
    //             ...updatedIstirahatTimes[index],
    //             [type]: time,  // 'start' atau 'end'
    //             rowIndex: index
    //         };
    //         console.log(updatedIstirahatTimes)
    //         return { istirahat_times: updatedIstirahatTimes };
    //
    //     });
    // };

    handleTimeChange = (rowIndex, type, event) => {
        // Perbarui waktu berdasarkan rowIndex dan jenis (start/end)
        const newTimes = [...this.state.times];
        if (!newTimes[rowIndex]) {
            newTimes[rowIndex] = {};
        }
        newTimes[rowIndex][type] = event;

        this.setState({ times: newTimes });
    };

    simpanAbsensi = () => {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            id: this.state.idDetail,
            sift: this.state.shift_nama,
            tanggal: this.state.tanggal,
            jam_in: this.state.jam_masuk,
            jam_out: this.state.jam_keluar,
            istirahat_start: this.state.istirahat_start,
            istirahat_end: this.state.istirahat_end,
            status_in: this.state.status_masuk,
            status_out: this.state.status_keluar,
            keterangan_in: this.state.keterangan_masuk,
            keterangan_out: this.state.keterangan_keluar,
            istirhat_time: this.state.times,
        }
        // this.props.request("presensi/update", form, 'POST')
        {this.props.is_sekolah ?
            this.props.request("presensi/update-v4", form, 'POST')
                .then(response => {
                    if (response.success) {
                        Swal.fire({
                            title: i18n.t('berhasil'),
                            text: i18n.t(response.message),
                            icon: 'success',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.props.changeState({
                                    editIsOpen: false,
                                    loading: true,
                                }, () => this.props.loadData())
                            } else {
                                this.props.changeState({
                                    editIsOpen: false,
                                    loading: true,
                                }, () => this.props.loadData())
                            }
                        })
                    } else {
                        Swal.fire({
                            title: 'Gagal!',
                            text: response.message,
                            icon: 'warning',
                            confirmButtonText: 'OK'
                        })
                    }
                    this.setState({
                        prosesSimpan: false,
                    })
                })
            :
            this.props.request("presensi/update-v2", form, 'POST')
                .then(response => {
                    if (response.success) {
                        Swal.fire({
                            title: i18n.t('berhasil'),
                            text: i18n.t(response.message),
                            icon: 'success',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.props.changeState({
                                    editIsOpen: false,
                                    loading: true,
                                }, () => this.props.loadData())
                            } else {
                                this.props.changeState({
                                    editIsOpen: false,
                                    loading: true,
                                }, () => this.props.loadData())
                            }
                        })
                    } else {
                        Swal.fire({
                            title: i18n.t('gagal'),
                            text: i18n.t(response.message),
                            icon: 'warning',
                            confirmButtonText: 'OK'
                        })
                    }
                    this.setState({
                        prosesSimpan: false,
                    })
                });
        }
    }

    loadDataAbsen() {
        let form = {
            id: this.state.idDetail,
        }
        this.props.request("presensi/profil", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        dataDetail: response.data,
                        detail_statusin: response.data.status_in,
                        detail_statusout: response.data.status_out,
                        dataLembur: response.lembur,
                        dataKaryawan: response.data.karyawan,
                        DataDivisi: response.data.divisi,
                        absenMasuk: {
                            lat: response.data.latitude_in,
                            lng: response.data.longitude_in,
                        },
                        absenPulang: {
                            lat: response.data.latitude_out,
                            lng: response.data.longitude_out,
                        },
                        tanggal: response.data.tanggal,
                        karyawan_id: response.data.karyawan_id,
                        shift_nama: response.data.sif_name,
                        jam_masuk: response.data.jam_masuk_absen,
                        status_masuk: response.data.status_in,
                        keterangan_masuk: response.data.keterangan_in,
                        jam_keluar: response.data.jam_keluar_absen,
                        status_keluar: response.data.status_out,
                        keterangan_keluar: response.data.keterangan_out,
                        istirahat_start: response.data.istirahat_mulai,
                        istirahat_end: response.data.istirahat_selesai,
                        karyawan_selected: {
                            value: response.data.karyawan_id,
                            label: response.data.karyawan.karyawan_nama
                        },
                        sift_selected: {
                            value: response.data.sift_id,
                            label: response.data.sift_name,
                        },
                        mapel_selected: {
                            value: response.data.mapel_id,
                            label: response.data.mapel_nama,
                        },
                        shift: response.data.karyawan.divisi_sift,
                        mapel: response.data.karyawan.divisi_sift_mapel,
                        istirahat: response.data.istirahat,
                        rows_length: response.data.istirahat.length,
                        times: response.data.istirahat.map(item => ({
                            start: item.istirahat_start,
                            end: item.istirahat_end
                        }))
                    })
                }
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.id !== prevProps.id) {
            this.setState({
                idDetail: this.props.id,
            }, () => {
                this.loadDataAbsen();
            });
        }
    }

    Capitalize(str){
        const Capitalize = str
            .toLowerCase()
            .split(' ')
            .map(word => {
                return word.charAt(0).toUpperCase() + word.slice(1);
            })
            .join(' ');

        return Capitalize;
    }

    render() {
        const isSekolah = this.props.is_sekolah;
        return (
            <ModalRs size='lg' style={{maxWidth: "1000px"}} isOpen={this.props.editIsOpen}
                     toggle={() => this.props.changeState({ editIsOpen: false, rows_length: 0 },
                         () => this.setState({
                             sift_selected: null,
                             sift_id: '',
                             tanggal: '',
                         }))}>
                <ModalHeader style={{backgroundColor: "#FAFDFF", padding: "12px 24px"}}
                             toggle={() => this.props.changeState({ editIsOpen: false, rows_length: 0 },
                                 () => this.setState({
                                     sift_selected: null,
                                     sift_id: '',
                                     tanggal: '',
                                 })
                             )}>
                    <div className='modal-header-reactstrap'>{i18n.t('editpresensi')}</div>
                </ModalHeader>
                <ModalBody>
                    <div className='row px-3 mb-3'>
                        <div className='col-md-3 px-2'>
                            <label for="exampleFormControlInput1" class="form-label font-edit-label">{i18n.t('tglpresensi')}</label>
                            <input style={{fontSize: "12px", paddingTop: "10px", paddingBottom: "10px"}}
                                   type="date" name="tanggal" className="form-control font-edit-value"
                                   defaultValue={this.props.tanggal} onChange={this.handleChange}/>
                        </div>
                        <div className='col-md-3 px-2'>
                            <label for="exampleFormControlInput1"
                                   class="form-label font-edit-label">{isSekolah ? i18n.t('namasiswa') : i18n.t('namakaryawan')}</label>
                            <div className='form-control p-0'>
                                <Select
                                    isDisabled={true}
                                    className="font-edit-value"
                                    placeholder={isSekolah ? i18n.t('namasiswa') : i18n.t('namakaryawan')}
                                    backspaceRemoves={true}
                                    deleteRemoves={true}
                                    components={{
                                        DropdownIndicator: () => null,
                                        IndicatorSeparator: () => null
                                    }}
                                    value={this.state.karyawan_selected}
                                    isLoading={this.state.karyawan_loading}
                                    onFocus={() => this.setState({ karyawan_loading: true }, () => this.searchKaryawan())}
                                    onBlur={() => this.setState({ karyawan_loading: false })}
                                    onInputChange={(val) => this.setState({ karyawan_name: val }, () => this.setState({ karyawan_loading: true }, () => this.searchKaryawan()))}
                                    options={
                                        this.state.karyawan != null ? (
                                                this.state.karyawan.map((item, index) =>
                                                    (
                                                        {
                                                            key: index,
                                                            value: item.karyawan_id,
                                                            label: item.karyawan_nama,
                                                            shift: item.divisi_sift,
                                                            mapel: item.divisi_sift_mapel
                                                        }
                                                    )
                                                ))
                                            : ("")
                                    }
                                    onChange={
                                        (karyawan_selected) => {
                                            if (!karyawan_selected) {
                                                this.setState({
                                                    karyawan_selected: '',
                                                    karyawan_id: 0,
                                                    pickKaryawan: false,
                                                    shift: [],
                                                    mapel: []
                                                })
                                            } else {
                                                this.setState({
                                                    karyawan_selected,
                                                    karyawan_id: karyawan_selected.value,
                                                    pickKaryawan: true,
                                                    shift: karyawan_selected.shift,
                                                    mapel: karyawan_selected.mapel,
                                                    karyawan: []
                                                }, () => this.searchLokasiIn(), this.searchLokasiOut())
                                            }
                                        }
                                    }
                                    isClearable={() => {
                                        this.setState({
                                            isClearable: true,
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        {isSekolah ? (
                                <div className='col-md-4 px-2'>
                                    <label htmlFor="exampleFormControlInput1"
                                           className="form-label font-edit-label">{i18n.t('jadwalmapel')}</label>
                                    <select name="shift_nama" className='form-control font-edit-value'
                                            onChange={this.handleChange}>
                                        <option>{i18n.t('pilihmapel')}</option>
                                        {this.state.mapel.length > 0 &&
                                            this.state.mapel.map((item, index) => {
                                                return (
                                                    <option
                                                        value={item.mapel.mapel_nama}
                                                        selected={
                                                            item.mapel.mapel_nama === this.state.dataDetail.sif_name &&
                                                            item.jadwal_hari === this.state.dataDetail.absen_hari.toLowerCase() &&
                                                            item.jadwal_jam_mulai === this.state.dataDetail.jam_masuk_kantor &&
                                                            item.jadwal_jam_selesai === this.state.dataDetail.jam_keluar_kantor
                                                        }
                                                    >
                                                        {item.mapel.mapel_nama} ( {this.Capitalize(i18n.t(item.jadwal_hari))} : {item.jadwal_jam_mulai} - {item.jadwal_jam_selesai} )
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            )
                            :
                            (
                                <div className='col-md-3 px-2'>
                                    <label htmlFor="exampleFormControlInput1"
                                           className="form-label font-edit-label">Shift</label>
                                    <select name="shift_nama" className='form-control font-edit-value'
                                            onChange={this.handleChange}>
                                        <option>Pilih Shift</option>
                                        {this.state.shift.length > 0 &&
                                            this.state.shift.map((item, index) => {
                                                return (
                                                    <option value={item.sif.sift_name}
                                                            selected={item.sif.sift_name === this.state.dataDetail.sif_name}>{item.sif.sift_name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            )
                        }
                        <div className='col-md-3 px-2'></div>
                    </div>
                    <hr className='mb-3' />
                    <div className='row px-3'>
                        <div className='col-md-4 px-2'>
                            <div className='card border'>
                                <div className='card-header modal-header-reactstrap px-3 border-bottom' style={{ paddingTop: "10px", paddingBottom: "10px", fontSize: "16px", lineHeight: "24px", backgroundColor: "#FAFDFF" }}>{i18n.t('masuk')}</div>
                                <div className='card-body p-3'>
                                    <div className='mb-1'>
                                        <label for="exampleFormControlInput1" class="form-label font-edit-label-2">{i18n.t('jam')}</label>
                                        <input
                                            name='jam_masuk'
                                            type="time"
                                            className="form-control mb-2 py-2"
                                            style={{fontSize: "12px"}}
                                            placeholder={moment().format('HH:mm')}
                                            value={this.state.jam_masuk ? this.state.jam_masuk.substr(0, 5) : ""}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className='mb-1'>
                                        <label for="exampleFormControlInput1" class="form-label font-edit-label-2">{i18n.t('status')}</label>
                                        <select style={{fontSize: "12px"}} name="status_masuk"
                                                value={this.state.status_masuk} className="form-control"
                                                onChange={this.handleChange}>
                                            <option value="">Pilih Status</option>
                                            <option value="Masuk Kerja">Masuk Kerja (Tepat Waktu)</option>
                                            <option value="Terlambat">Terlambat</option>
                                            <option value="Izin">Izin</option>
                                            <option value="Sakit">Sakit</option>
                                            <option value="Cuti">Cuti</option>
                                        </select>
                                    </div>
                                    <div className='mb-1'>
                                        <label for="exampleFormControlInput1" class="form-label font-edit-label-2">{i18n.t('keterangan')}</label>
                                        <textarea style={{ fontSize: "12px" }} value={this.state.keterangan_masuk} name="keterangan_masuk" className="form-control" placeholder="Keterangan Presensi..." onChange={this.handleChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 px-2'>
                            <div className='card border'>
                                <div className='card-header modal-header-reactstrap px-3 border-bottom' style={{ paddingTop: "10px", paddingBottom: "10px", fontSize: "16px", lineHeight: "24px", backgroundColor: "#FAFDFF" }}>{i18n.t('keluar')}</div>
                                <div className='card-body p-3'>
                                    <div className='mb-1'>
                                        <label for="exampleFormControlInput1" class="form-label font-edit-label-2">{i18n.t('jam')}</label>
                                        <input
                                            name='jam_keluar'
                                            type="time"
                                            className="form-control mb-2 py-2"
                                            style={{fontSize: "12px"}}
                                            placeholder={moment().format('HH:mm')}
                                            value={this.state.jam_keluar ? this.state.jam_keluar.substr(0, 5) : ""}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className='mb-1'>
                                        <label for="exampleFormControlInput1" class="form-label font-edit-label-2">{i18n.t('status')}</label>
                                        <select style={{ fontSize: "12px" }} name="status_keluar" value={this.state.status_keluar} className="form-control" onChange={this.handleChange}>
                                            <option value="">Pilih Status</option>
                                            <option value="Pulang Kerja">Pulang Kerja</option>
                                            <option value="Sakit">Sakit</option>
                                            <option value="Izin">Izin</option>
                                        </select>
                                    </div>
                                    <div className='mb-1'>
                                        <label for="exampleFormControlInput1" class="form-label font-edit-label-2">{i18n.t('keterangan')}</label>
                                        <textarea style={{ fontSize: "12px" }} value={this.state.keterangan_keluar} type="text" name="keterangan_keluar" className="form-control" placeholder="Keterangan Presensi..." onChange={this.handleChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='card border'>
                                <div
                                    className='card-header modal-header-reactstrap px-3 border-bottom'
                                    style={{
                                        paddingTop: "10px",
                                        paddingBottom: "10px",
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                        backgroundColor: "#FAFDFF"
                                    }}>{i18n.t("jamistirahat")} ({i18n.t("opsional")})
                                </div>

                                <div
                                    className="p-3 d-flex flex-row align-items-center justify-content-between">
                                    <button
                                        className="btn ipresens-btn-color mt-0 text-capitalize pull-left button-w-full"
                                        disabled={this.state.rows_length === 5}
                                        onClick={() => this.setState({rows_length: this.state.rows_length + 1})}>{i18n.t('tambah')}</button>

                                    {this.state.rows_length > 0 && (
                                        <button className="btn ipresens-delete-button"
                                                style={{paddingBottom: "2px"}}
                                                onClick={() => this.handleDeleteLastRow()}>
                                            <img src={TrashIcon} alt="" className="mr-2 mb-1"/>
                                        </button>
                                    )}
                                </div>
                                {Array.from({length: this.state.rows_length}, (item, rowIndex) => {
                                    const istirahat = this.state.times[rowIndex] || { start: '', end: '' };
                                    return (
                                        <div className='d-flex flex-row'>
                                            <div className='mb-2 px-3'>
                                                <label htmlFor="exampleFormControlInput1"
                                                       className="form-label font-edit-label-2">{i18n.t("jammulai")}</label>
                                                <TimeInput
                                                    placeholder={i18n.t("jammulai") + "..."}
                                                    style={{fontSize: "12px"}}
                                                    initTime={istirahat.start}
                                                    className='s-input -time form-control px-3 py-2'
                                                    onTimeChange={(event) => this.handleTimeChange(rowIndex, 'start', event)}
                                                />
                                            </div>
                                            <div className='mb-2 px-3'>
                                                <label htmlFor="exampleFormControlInput1"
                                                       className="form-label font-edit-label-2">{i18n.t("jamselesai")}</label>
                                                <TimeInput
                                                    placeholder={i18n.t("jamselesai") + "..."}
                                                    style={{fontSize: "12px"}}
                                                    initTime={istirahat.end}
                                                    className='s-input -time form-control px-3 py-2'
                                                    onTimeChange={(event) => this.handleTimeChange(rowIndex, 'end', event)}
                                                />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter style={{backgroundColor: "#F5F5F5", borderTop: 0}}>
                    <button className="btn ipresens-btn-cancel text-capitalize"
                            onClick={() => this.props.changeState({ editIsOpen: false, rows_length: 0},
                                () => this.setState({
                                    sift_selected: null,
                                    sift_id: '',
                                    tanggal: '',
                                }))}>
                        {i18n.t('batal')}
                    </button>
                    {' '}
                    <button className="btn ipresens-btn-color text-capitalize"
                            onClick={this.simpanAbsensi}>
                        {i18n.t('simpan')}
                    </button>
                </ModalFooter>
            </ModalRs>
        )
    }
}

export default EditModalAbsensi;