import React, {Component} from "react";
import {Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import Swal from "sweetalert2";
import i18n from "i18next";
import ArrowRight from "../../assets/icons/arrow-right.svg";
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import ReactPaginate from 'react-paginate';
import {Spin} from "antd";
import {DEFAULT_IMG} from "../../helper/host";
import moment from "moment";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg";

class HistoryMataPelajaran extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataHistory: [],
            historyLast: [],
            loadingHistory: false,
            currentPageHistory: 0,
            pageCountHistory: 0,
            totalDataHistory: 0,
            totalPerpageHistory: 0,
            perPageHistory: 0,
            mapel_id: 0,
            history_id: 0,
        };
        this.handlePageHistory = this.handlePageHistory.bind(this);
    }

    componentDidUpdate(prevProps) {
        const { history_visible, mapel_id } = this.props;

        if (history_visible && (!prevProps.history_visible || mapel_id !== prevProps.mapel_id)) {
            this.setState({ loading_detail: true, mapel_id: mapel_id, currentPageHistory: 0  }, () => this.loadHistory(mapel_id));
        }
    }

    handlePageHistory(dataHistory) {
        let selected = dataHistory.selected;
        this.setState({ currentPageHistory: selected, dataHistory: [], loadingHistory: true }, () => {
            this.loadHistory();
        });
    }

    loadHistory(id) {
        this.setState({
            dataHistory: [],
            loadingHistory: true
        },()=>{
            let form = {
                id: id ? id : this.state.mapel_id,
                paging : 2,
                page: this.state.currentPageHistory + 1,
            };

            this.props.request("mapel/history", form, 'POST')
                .then(response => {
                    this.setState({
                        loadingHistory: false
                    })
                    if (response.succes) {
                        this.setState({
                            dataHistory: response.data.data,
                            historyLast: response.history,
                            pageCountHistory: response.data.last_page,
                            totalDataHistory: response.data.total,
                            totalPerpageHistory: response.data.to,
                            perPageHistory: response.data.per_page,
                        });
                    }
                });
        })
    }

    revertMapel(history, mapel) {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('revertdata'),
            icon: 'warning',
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        id: mapel || this.state.mapel_id,
                        historyId: history || this.state.history_id
                    }
                    this.props.request("mapel/revert", form, 'POST')
                        .then(response => {
                            this.props.changeState({ history_visible: false, loading: true, data: [], }, () => this.props.loadData())
                            if (response.success) {
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                            } else {
                                Swal.fire({
                                    title: i18n.t('gagal'),
                                    text: i18n.t(response.message),
                                    icon: 'warning',
                                    confirmButtonText: 'OK'
                                })
                            }
                        });
                }
            })
    }

    render() {
        return (
            <Modal style={{ maxWidth: "1000px"}} isOpen={this.props.history_visible} toggle={() => this.props.changeState({ history_visible: false })}>
                <ModalHeader className="modal-header-ipresens" toggle={() => this.props.changeState({ history_visible: false })}>
                    <div className="modal-header-ipresens">{i18n.t('riwayatperubahan')}</div>
                </ModalHeader>
                <ModalBody>
                    <div className="list-group">
                        {this.state.loadingHistory && (
                            <div style={{textAlign: 'center', margin: '10px auto'}}>
                                <Spin size="large" tip="Loading..." style={{width: '100%', margin: 'auto'}}/>
                            </div>
                        )}
                        {this.state.dataHistory.length > 0 && (
                            <div>
                                <Row>
                                    <Col xs={0} md={0} className="text-left" style={{marginLeft: '20px'}}>
                                        <h4>{i18n.t('user')}</h4>
                                    </Col>
                                    <Col xs={0} md={0} className="text-left" style={{marginRight: '20px'}}>
                                        <h4>{i18n.t('aktivitas')}</h4>
                                    </Col>
                                </Row>
                                {this.state.dataHistory.map((item, index) => {

                                    const {p_user_name, level_name, created_at, history_status, parent_history, mapel_nama, mapel_kkm, mapel_nilai_start, mapel_nilai_end, guru} = item;
                                    const renderChange = (label, oldValue, newValue) => {
                                        if (oldValue === newValue) return null
                                        return (
                                            <h6 className="mb-3 font-16">
                                                {i18n.t(label)} : {i18n.t('dari')} {oldValue}{' '}
                                                <img src={ArrowRightIcon} alt="" style={{ width: '20px', marginBottom: '3px', height: '20px' }}
                                                />{' '}
                                                {i18n.t('jadi')} {newValue}
                                            </h6>
                                        )
                                    }

                                    return (
                                        <div key={index} style={{marginBottom: "20px"}}>
                                            <Row>
                                                <Col xs={12} md={6} className="text-left">
                                                    <div style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '16px 12px'
                                                    }}>
                                                        <div style={{marginRight: '16px'}}>
                                                            <img
                                                                src={DEFAULT_IMG}
                                                                alt=""
                                                                style={{
                                                                    width: '48px',
                                                                    marginBottom: '25px',
                                                                    height: '48px'
                                                                }}
                                                            />
                                                        </div>
                                                        <div>
                                                            <h6 className="mb-0 font-13" style={{
                                                                fontWeight: 500,
                                                                fontSize: '16px',
                                                                lineHeight: '24px'
                                                            }}>{item.p_user_name} | {item.level_name}</h6>
                                                            <p className="m-0 font-12">
                                                                            <span
                                                                                className="col-green font-weight-bold">
                                                                              {moment(item.created_at).format('lll')}
                                                                            </span>
                                                            </p>
                                                            <div style={{marginTop: 8, gap: 5}}
                                                                 className="d-flex align-items-center">
                                                                {item.history_status === 'created' ? (
                                                                    <div
                                                                        className="m-0"
                                                                        style={{
                                                                            border: '1px solid #000',
                                                                            borderRadius: '25px',
                                                                            width: '50%',
                                                                            color: '#7805e4',
                                                                            borderColor: '#7805e4',
                                                                        }}>
                                                                        <div className="d-flex justify-content-center"
                                                                             style={{
                                                                                 paddingTop: '6px',
                                                                                 paddingBottom: '6px'
                                                                             }}>
                                                                            {i18n.t('dibuat')}
                                                                        </div>
                                                                    </div>
                                                                ) : item.history_status === 'reverted' ? (
                                                                    <div className="m-0" style={{
                                                                        border: '1px solid #000',
                                                                        borderRadius: '25px',
                                                                        width: '75%',
                                                                        color: '#7805e4',
                                                                        borderColor: '#7805e4',
                                                                        fontSize: "14px"
                                                                    }}>
                                                                        <div
                                                                            className="d-flex justify-content-center"
                                                                            style={{
                                                                                paddingTop: '6px',
                                                                                paddingBottom: '6px'
                                                                            }}>
                                                                            {i18n.t('dikembalikan')}
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        className="m-0"
                                                                        style={{
                                                                            border: '1px solid #000',
                                                                            borderRadius: '25px',
                                                                            width: '50%',
                                                                            color: '#7805e4',
                                                                            borderColor: '#7805e4',
                                                                        }}>
                                                                        <div
                                                                            className="d-flex justify-content-center"
                                                                            style={{
                                                                                paddingTop: '6px',
                                                                                paddingBottom: '6px'
                                                                            }}>
                                                                            {i18n.t('diubah')}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {(this.state.historyLast.history_id !== item.history_id && item.history_status !== 'reverted') && (
                                                                    <button
                                                                        className="px-5 d-flex justify-content-center ipresens-btn-revert py-2"
                                                                        style={{borderRadius: "25px"}}
                                                                        onClick={() => this.setState({
                                                                            history_id: item.history_id,
                                                                            mapel_id: item.mapel_id
                                                                        }, () => this.revertMapel(item.history_id, item.mapel_id))}>
                                                                        {i18n.t('kembalikan')}
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <div className="col-md-6 p-3 mb-3">
                                                    {parent_history && (
                                                        <>
                                                            {renderChange('namamapel', parent_history.mapel_nama, mapel_nama)}
                                                            {renderChange('mapelkkm', parent_history.mapel_kkm, mapel_kkm)}
                                                            {renderChange('nilaiawal', parent_history.mapel_nilai_start, mapel_nilai_start)}
                                                            {renderChange('nilaiakhir', parent_history.mapel_nilai_end, mapel_nilai_end)}
                                                            {renderChange('guru', parent_history.guru?.guru_nama, guru?.guru_nama)}
                                                        </>
                                                    )}
                                                </div>
                                            </Row>
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                </ModalBody>
                <ModalFooter>
                    {this.state.dataHistory.length !== 0 &&
                        <>
                            <div className='col font-14' style={{
                                padding: "auto",
                                margin: "auto",
                                fontWeight: 500,
                                lineHeight: "24px"
                            }}>{i18n.t('menampilkan')} {this.state.dataHistory.length === 0 || this.state.dataHistory.length === null ? "0" : this.state.dataHistory.length} {i18n.t('dari')} {this.state.totalDataHistory} {i18n.t('data')}
                            </div>
                            <div className='col'>
                                <ReactPaginate
                                    previousLabel={<img src={ArrowLeft} alt=''/>}
                                    nextLabel={<img src={ArrowRight} alt=''/>}
                                    pageCount={this.state.pageCountHistory}
                                    forcePage={this.state.currentPageHistory}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={this.handlePageHistory}
                                    breakClassName={'page-item'}
                                    breakLinkClassName={'page-link custom-page-link'}
                                    containerClassName={'pagination my-2'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link custom-page-link'}
                                    previousClassName={'page-item'}
                                    previousLinkClassName={'page-link custom-prev-next-link'}
                                    nextClassName={'page-item'}
                                    nextLinkClassName={'page-link custom-prev-next-link'}
                                    activeClassName={'active custom-active'}
                                />
                            </div>
                        </>
                    }

                </ModalFooter>
            </Modal>
        )
    }
}
export default HistoryMataPelajaran;