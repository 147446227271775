import React, {Component} from "react";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import i18n from "i18next";
import EmptyDataImage from "../../../assets/images/data-kosong.png";
import {Empty, Spin} from "antd";
import ReactPaginate from "react-paginate";
import ArrowLeft from "../../../assets/icons/arrow-left.svg";
import ArrowRight from "../../../assets/icons/arrow-right.svg";
import Swal from "sweetalert2";


class TrashIstilahPembayaranSiswa extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataTrash: [],
            loadingTrash: false,
            checked_all: false,
            perPage: 0,
            currentPage: 0,
            totalData: 0,
            pageCount: 0,
            paginate: 5,
            checked: [],
        };
        this.handlePageClick = this.handlePageClick.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.trash_visible !== this.props.trash_visible && this.props.trash_visible) {
            this.setState({ loadingTrash: true, currentPage: 0 }, () => this.loadTrash())
        }
    }

    handlePageClick(dataTrash) {
        let selected = dataTrash.selected;
        this.setState({ currentPage: selected, dataTrash: [], loadingTrash: true }, () => {
            this.loadTrash();
        });
    }

    handleCheckAll(e) {
        const value = e.target.checked;
        if (value) {
            const allChecked = this.state.dataTrash.map(item => item.pb_term_id);
            this.setState({
                checked: allChecked,
                checked_all: true
            });
        } else {
            this.setState({
                checked: [],
                checked_all: false
            });
        }
    }

    handleCheck(id) {
        this.setState(prevState => {
            const isChecked = prevState.checked.includes(id);
            const newChecked = isChecked
                ? prevState.checked.filter(currId => currId !== id)
                : [...prevState.checked, id];

            return {
                checked: newChecked,
                checked_all: newChecked.length === prevState.totalData
            };
        });
    }

    loadTrash() {
        this.setState({ loadingTrash: true, dataTrash: [] })
        let form = {
            page: this.state.currentPage + 1,
            paginate: this.state.paginate
        }
        this.props.request('pembayaran/trash-pb-term', form, 'POST')
            .then((response) => {
                if (response.success) {
                    this.setState({
                        dataTrash: response.data.data,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        loadingTrash: false,
                    })
                }
            })
    }

    handleRestore() {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: `${this.state.checked.length} ${i18n.t('yakin-pulihkan-pembayaran-term')}`,
            icon: "warning",
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        term_ids: this.state.checked
                    }
                    this.props.request('pembayaran/restore-term', form, 'POST')
                        .then((response) => {
                            if (response.success) {
                                this.props.changeState({ trash_visible: false, loading: true }, () => this.props.loadData())
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: i18n.t('pembayaran-term-berhasil-dipulihkan'),
                                    icon: "success"
                                })
                            } else {
                                Swal.fire({
                                    title: i18n.t('gagal'),
                                    text: response.message,
                                    icon: "warning"
                                })
                            }
                            this.setState({ checked: [], checked_all: false })
                        })
                }
            })
    }
 
    handleDeletePermanent() {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: `${this.state.checked.length} ${i18n.t('yakin-hapus-permanent-pembayaran-term')}`,
            icon: "warning",
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        term_ids: this.state.checked
                    }
                    this.props.request('pembayaran/delete-permanent-term', form, 'POST')
                        .then((response) => {
                            if (response.success) {
                                this.props.changeState({ trash_visible: false, loading: true }, () => this.props.loadData())
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: i18n.t('pembayaran-term-berhasil-dihapus-permanent'),
                                    icon: "success"
                                })
                            } else {
                                Swal.fire({
                                    title: i18n.t('gagal'),
                                    text: response.message,
                                    icon: "warning"
                                })
                            }
                            this.setState({ checked: [], checked_all: false })
                        })
                }
            })
    }

    render() {
        return (
            <Modal isOpen={this.props.trash_visible} toggle={() => this.props.changeState({ trash_visible: false })} zIndex={999} size="lg" style={{ maxWidth: '1000px' }}>
                <ModalHeader toggle={() => this.props.changeState({ trash_visible: false }, () => this.setState({ checked: [], checked_all: false }))} className="modal-header-reactstrap">
                    <div className="modal-header-reactstrap">{i18n.t('sampahistilah-pembayaran')}</div>
                </ModalHeader>
                <ModalBody>
                    <div>
                        <div className="d-flex align-items-center justify-content-end" style={{ gap: 5 }}>
                            <button className="btn ipresens-btn-color" disabled={this.state.checked.length === 0} onClick={() => this.handleRestore()}>{i18n.t('pulihkan')}</button>
                            <button className="btn ipresens-btn-color" disabled={this.state.checked.length === 0} onClick={() => this.handleDeletePermanent()}>{i18n.t('hapuspermanent')}</button>
                        </div>
                        <div>
                            {!this.state.loadingTrash && this.state.dataTrash.length === 0 ? (
                                <Empty description={i18n.t("datakosong")}
                                       image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                            ): (
                                <table className="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th style={{width: '20px'}}>
                                                <input
                                                    type="checkbox"
                                                    className="form-control"
                                                    checked={this.state.checked_all}
                                                    onChange={(e) => this.handleCheckAll(e)}
                                                />
                                            </th>
                                            <th className="">{i18n.t('nama')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.loadingTrash && (
                                        <tr>
                                            <td colSpan={5}>
                                                <Spin size="large" tip="Loading..." style={{
                                                    width: '100%',
                                                    margin: 'auto',
                                                    marginBottom: '10px',
                                                    marginTop: '10px'
                                                }} />
                                            </td>
                                        </tr>
                                    )}
                                    {this.state.dataTrash.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td style={{width: '20px'}}>
                                                        <input
                                                            type="checkbox"
                                                            className="form-control"
                                                            checked={this.state.checked.includes(item.pb_term_id)}
                                                            onChange={() => this.handleCheck(item.pb_term_id)}
                                                        />
                                                    </td>
                                                    <td className="">{item.pb_term_name}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    {this.state.dataTrash.length !== 0 &&
                        <>
                            <div className='col font-14' style={{
                                padding: "auto",
                                margin: "auto",
                                fontWeight: 500,
                                lineHeight: "24px"
                            }}>{i18n.t('menampilkan')} {this.state.dataTrash.length === 0 || this.state.dataTrash.length === null ? "0" : this.state.dataTrash.length} {i18n.t('dari')} {this.state.totalData} {i18n.t('data')}
                            </div>
                            <div className='col'>
                                <ReactPaginate
                                    previousLabel={<img src={ArrowLeft} alt=''/>}
                                    nextLabel={<img src={ArrowRight} alt=''/>}
                                    pageCount={this.state.pageCount}
                                    forcePage={this.state.currentPage}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={this.handlePageClick}
                                    breakClassName={'page-item'}
                                    breakLinkClassName={'page-link custom-page-link'}
                                    containerClassName={'pagination my-2'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link custom-page-link'}
                                    previousClassName={'page-item'}
                                    previousLinkClassName={'page-link custom-prev-next-link'}
                                    nextClassName={'page-item'}
                                    nextLinkClassName={'page-link custom-prev-next-link'}
                                    activeClassName={'active custom-active'}
                                />
                            </div>
                        </>
                    }
                </ModalFooter>
            </Modal>
        )
    }
}

export default TrashIstilahPembayaranSiswa;