import React, {Component} from "react";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import i18n from "i18next";
import { Empty, Spin } from "antd";
import EmptyDataImage from "../../assets/images/data-kosong.png";
import ReactPaginate from "react-paginate";
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import ArrowRight from "../../assets/icons/arrow-right.svg";
import Swal from "sweetalert2";

class TrashTunjangan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: [],
            dataTrash: [],
            checked_all: false,
            loadingTrash: false,
            currentPage: 0,
            perPage: 0,
            totalData: 0,
            pageCount: 0,
            paginate: 5,
        };
        this.handlePageClick = this.handlePageClick.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.trash_visible !== this.props.trash_visible && this.props.trash_visible) {
                this.setState({ loadingTrash: true}, () => this.loadTrash())
        }
    }

    loadTrash() {
        this.setState({ loadingTrash: true, dataTrash: [] })
        let form = {
            paginate: this.state.paginate,
            page: this.state.currentPage + 1,
        }
        this.props.request('tunjangan/trash', form, 'POST')
        .then((response) => {
            if (response.success) {
                this.setState({
                    dataTrash: response.data.data,
                    totalData: response.data.total,
                    pageCount: response.data.last_page,
                    perPage: response.data.per_page,
                    loadingTrash: false,
                })
            }
        })
    }

    handleCheck(id) {
        this.state.checked.includes(id) ?
            this.setState(prevState => ({
                checked: prevState.checked.filter(currId => currId !== id),
            }), () => {
                if (this.state.checked.length === this.state.totalData) {
                    this.setState({
                        checked_all: true
                    })
                } else {
                    this.setState({
                        checked_all: false
                    })
                }
            })
            :
            this.setState(prevState => ({
                checked: [...prevState.checked, id],
            }), () => {
                if (this.state.checked.length === this.state.totalData) {
                    this.setState({
                        checked_all: true
                    })
                } else {
                    this.setState({
                        checked_all: false
                    })
                }
            })
    }

    handleCheckAll(e) {
        let value = e.target.checked
        if (value) {
            this.state.dataTrash.map((item) => {
                if (!this.state.checked.includes(item.tunjangan_id)) {
                    this.setState(prevState => {
                        return {
                            ...prevState,
                            checked: [...prevState.checked, item.tunjangan_id]
                        }
                    }, () => {
                        this.setState({
                            checked_all: true
                        })
                    })
                }
            })
        } else {
            this.setState({
                checked_all: false,
                checked: []
            })
        }
    }

    handlePageClick(dataTrash) {
        let selected = dataTrash.selected;
        this.setState({ currentPage: selected, dataTrash: [], loadingTrash: true }, () => this.loadTrash())
    }

    restoreData() {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: `${this.state.checked.length} ${i18n.t('datatunjangandipulihkan')}`,
            icon: "warning",
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
        .then((result) => {
            if (result.isConfirmed) {
                let form = {
                    tunjangan_ids: this.state.checked,
                }
                this.props.request('tunjangan/restore', form, 'POST')
                .then((response) => {
                    if (response.success) {
                        this.props.changeState({ trash_visible: false, loading: true }, () => this.props.loadData())
                        Swal.fire({
                            title: i18n.t('berhasil'),
                            text: i18n.t('tunjanganberhasildipulihkan'),
                            icon: 'success'
                        })
                    } else {
                        Swal.fire({
                            title: i18n.t('gagal'),
                            text: response.message,
                            icon: 'warning',
                            confirmButtonText: 'OK'
                        })
                    }
                    this.setState({ checked: [], checked_all: false })
                })
            }
        })
    }

    deletePermanentData() {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: `${this.state.checked.length} ${i18n.t('datatunjanganakandihapuspermanent')}`,
            icon: "warning",
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
        .then((result) => {
            if (result.isConfirmed) {
                let form = {
                    tunjangan_ids: this.state.checked,
                }
                this.props.request('tunjangan/delete-permanent', form, 'POST')
                .then((response) => {
                    if (response.success) {
                        this.props.changeState({ trash_visible: false, loading: true }, () => this.props.loadData())
                        Swal.fire({
                            title: i18n.t('berhasil'),
                            text: i18n.t('tunjanganberhasildihapuspermanent'),
                            icon: 'success'
                        })
                    } else {
                        Swal.fire({
                            title: i18n.t('gagal'),
                            text: response.message,
                            icon: 'warning',
                            confirmButtonText: 'OK'
                        })
                    }
                    this.setState({ checked: [], checked_all: false })
                })
            }
        })
    }

    render() {
        return (
            <Modal isOpen={this.props.trash_visible} toggle={() => this.props.changeState({ trash_visible: false })} size="lg" zIndex={999} style={{ maxWidth: '1000px' }}>
                <ModalHeader toggle={() => this.props.changeState({ trash_visible: false })} className="pengumuman-modal-header">
                    <div className="pengumuman-modal-header-font">{i18n.t('sampahtunjangan')}</div>
                </ModalHeader>
                <ModalBody>
                    <div>
                        <div className="d-flex align-items-center justify-content-end" style={{ gap: 5 }}>
                            <button className="btn ipresens-btn-color" disabled={this.state.checked.length === 0} onClick={() => this.restoreData()}>{i18n.t('pulihkan')}</button>
                            <button className="btn ipresens-btn-color" disabled={this.state.checked.length === 0} onClick={() => this.deletePermanentData()}>{i18n.t('hapuspermanent')}</button>
                        </div>
                        <div className="mt-3">
                            {!this.state.loadingTrash && this.state.dataTrash.length === 0 ? (
                                <Empty description={i18n.t("datakosong")}
                                image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                            ):(
                                <table className="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '20px' }}>
                                                <input
                                                    type="checkbox"
                                                    className="form-control"
                                                    checked={this.state.checked_all}
                                                    onChange={(e) => this.handleCheckAll(e)}
                                                />
                                            </th>
                                            <th>{i18n.t('namadivisi')}</th>
                                            <th>{i18n.t('namatunjangan')}</th>
                                            <th>{i18n.t('deskripsitunjangan')}</th>
                                        </tr>
                                    </thead>
                                    {this.state.loadingTrash && (
                                        <tr>
                                            <td colSpan={5}>
                                                <Spin size="large" tip="Loading..." style={{
                                                    width: '100%',
                                                    margin: 'auto',
                                                    marginBottom: '10px',
                                                    marginTop: '10px'
                                                }} />
                                            </td>
                                        </tr>
                                    )}
                                    {this.state.dataTrash.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td style={{  width: '2opx' }}>
                                                    <input
                                                        type="checkbox"
                                                        className="form-control"
                                                        checked={this.state.checked.includes(item.tunjangan_id)}
                                                        onChange={(e) => this.handleCheck(item.tunjangan_id)}
                                                    />
                                                </td>
                                                <td>{item.divisi.divisi_name}</td>
                                                <td>{item.tunjangan_name}</td>
                                                <td>{item.tunjangan_desc}</td>
                                            </tr>
                                        )
                                    })}
                                </table>
                            )}
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    {this.state.dataTrash.length !== 0 &&
                        <>
                            <div className='col font-14' style={{
                                padding: "auto",
                                margin: "auto",
                                fontWeight: 500,
                                lineHeight: "24px"
                            }}>{i18n.t('menampilkan')} {this.state.dataTrash.length === 0 || this.state.dataTrash.length === null ? "0" : this.state.dataTrash.length} {i18n.t('dari')} {this.state.totalData} {i18n.t('data')}
                            </div>
                            <div className='col'>
                                <ReactPaginate
                                    previousLabel={<img src={ArrowLeft} alt=''/>}
                                    nextLabel={<img src={ArrowRight} alt=''/>}
                                    pageCount={this.state.pageCount}
                                    forcePage={this.state.currentPage}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={this.handlePageClick}
                                    breakClassName={'page-item'}
                                    breakLinkClassName={'page-link custom-page-link'}
                                    containerClassName={'pagination my-2'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link custom-page-link'}
                                    previousClassName={'page-item'}
                                    previousLinkClassName={'page-link custom-prev-next-link'}
                                    nextClassName={'page-item'}
                                    nextLinkClassName={'page-link custom-prev-next-link'}
                                    activeClassName={'active custom-active'}
                                />
                            </div>
                        </>
                    }
                </ModalFooter>
            </Modal>
        )
    }
}
export default TrashTunjangan;
