import React from "react";
import i18n from "i18next";

export function Page404 () {
    return (
        <div className="container-fluid d-flex align-items-center justify-content-center bg-white" style={{height: '650px' }}>
            <div className="">
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <h5 className="" style={{fontWeight: 800, fontSize: '80px', color: '#8C2BEE'}}>404</h5>
                    <h5>{i18n.t('halamantidakditemukan')}</h5>
                </div>
            </div>
        </div>
    )
}
export default Page404;