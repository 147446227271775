import React, {Component} from "react";
import {Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import i18n from "i18next";
import ReactPaginate from 'react-paginate';
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import ArrowRight from "../../assets/icons/arrow-right.svg";
import {Spin} from "antd";
import {DEFAULT_IMG} from "../../helper/host";
import moment from "moment/moment";

class HistoryPembayaranTagihan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            link_id: 0,
            pageCount: 0,
            currentPage: 0,
            history: [],
        }
        this.handlePageHistory = this.handlePageHistory.bind(this);
    }

    handlePageHistory(history) {
        let selected = history.selected;
        this.setState({ currentPage: selected, loading: true, history: [] }, () => this.loadData());
    }

    componentDidUpdate(prevProps) {
        if (this.props.id !== prevProps.id) {
            this.setState({
                link_id: this.props.id,
            }, () => {
                this.loadData(this.props.id);
            });
        }
    }

    loadData(id) {
        let form = {
            link_id: id || this.state.link_id,
            page: this.state.currentPage + 1,
            paginate: 2
        }
        this.props.request('tagihan_siswa/history-link', form, 'POST')
            .then((response) => {
                if (response.success) {
                    this.setState({
                        history: response.data.data,
                        loading: false,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page
                    })
                }
            })
    }

    render() {
        return (
            <Modal size="lg" zIndex={999} isOpen={this.props.history_visible} toggle={() => this.props.changeState({ history_visible: false })}>
                <ModalHeader toggle={() => this.props.changeState({ history_visible: false })}>
                    <div>{i18n.t("riwayatpembayarantagihan")}</div>
                </ModalHeader>
                <ModalBody>
                    <div className="list-group">
                        {this.state.loading && (
                            <div style={{textAlign: "center", margin: '10px auto'}}>
                                <Spin size="large" tip={i18n.t('loading')}
                                      style={{width: "100%", margin: "auto"}}/>
                            </div>
                        )}
                        {this.state.history.length !== 0 && (
                            <div>
                                <Row>
                                    <Col xs={0} md={0} className="text-left" style={{ marginLeft: '20px' }}>
                                        <h4>{i18n.t('user')}</h4>
                                    </Col>
                                    <Col xs={0} md={0} className="text-left"style={{ marginRight: '20px' }}>
                                        <h4>{i18n.t('aktivitas')}</h4>
                                    </Col>
                                </Row>

                                {this.state.history.map((item, index) => {
                                    return (
                                        <div key={index} style={{ marginBottom : "20px" }}>
                                            <Row>
                                                <Col xs={12} md={6} className="text-left">
                                                    <div style={{ display: 'flex', alignItems: 'center', padding: '16px 12px' }}>
                                                        <div style={{marginRight: '16px'}}>
                                                            <img
                                                                src={DEFAULT_IMG}
                                                                alt=""
                                                                style={{ width: '48px' , marginBottom:'25px', height:'48px'}}
                                                            />
                                                        </div>
                                                        <div>
                                                            <h6 className="mb-0 font-13" style={{
                                                                fontWeight: 500,
                                                                fontSize: '16px',
                                                                lineHeight: '24px'
                                                            }}>{item.p_user_name} | {item.level_name}</h6>
                                                            <p className="m-0 font-12">
                                                                            <span
                                                                                className="col-green font-weight-bold">
                                                                              {moment(item.created_at).format('lll')}
                                                                            </span>
                                                            </p>
                                                            <div style={{ marginTop: 8 }}>
                                                                {item.history_status === "created" ? (
                                                                    <div
                                                                        className="m-0"
                                                                        style={{
                                                                            border: '1px solid #000',
                                                                            borderRadius: '25px',
                                                                            width: '50%',
                                                                            color: '#057CE4',
                                                                            borderColor: '#057CE4',
                                                                        }}
                                                                    >
                                                                        <div className="d-flex justify-content-center" style={{
                                                                            paddingTop: '6px',
                                                                            paddingBottom: '6px'
                                                                        }}>
                                                                            {i18n.t('dibuat')}
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        className="m-0"
                                                                        style={{
                                                                            border: '1px solid #000',
                                                                            borderRadius: '25px',
                                                                            width: '50%',
                                                                            color: '#E6462E',
                                                                            borderColor: '#E6462E',
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className="d-flex justify-content-center"
                                                                            style={{
                                                                                paddingTop: '6px',
                                                                                paddingBottom: '6px'
                                                                            }}>
                                                                            {i18n.t('diubah')}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={6} className="text-left" style={{
                                                    padding: '28px 12px 16px',
                                                }}>
                                                    {(item.history_status !== 'created' && item.history) && (
                                                        <div>
                                                            {item.history?.link_status !== item.link_status && (
                                                                <div>
                                                                    <h6
                                                                        className="mb-3 font-13 text-left"
                                                                        style={{
                                                                            fontWeight: 100,
                                                                            fontSize: '16px',
                                                                            lineHeight: '24px',
                                                                        }}
                                                                    >
                                                                        {i18n.t('status')} : {i18n.t('dari')}{' '}
                                                                        {item.history?.link_status}
                                                                        <img
                                                                            src={ArrowRight}
                                                                            alt=""
                                                                            style={{
                                                                                width: '20x',
                                                                                marginBottom: '1px',
                                                                                height: '18px'
                                                                            }}
                                                                        /> {i18n.t('jadi')}
                                                                        : {item?.link_status}
                                                                    </h6>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </Col>
                                            </Row>
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                </ModalBody>
                <ModalFooter>
                    {this.state.history?.length !== 0 && (
                        <>
                            <div className="col font-14"
                                 style={{padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>
                                {i18n.t('menampilkan')} {this.state.history.length === 0 || this.state.history.length === null ? "0" : this.state.history.length} {i18n.t('dari')} {this.state.totalData} {i18n.t('data')}
                            </div>
                            <div className="col">
                                <ReactPaginate
                                    previousLabel={<img src={ArrowLeft} alt=''/>}
                                    nextLabel={<img src={ArrowRight} alt=''/>}
                                    pageCount={this.state.pageCount}
                                    forcePage={this.state.currentPage}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={this.handlePageHistory}
                                    breakClassName={'page-item'}
                                    breakLinkClassName={'page-link custom-page-link'}
                                    containerClassName={'pagination my-2'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link custom-page-link'}
                                    previousClassName={'page-item'}
                                    previousLinkClassName={'page-link custom-prev-next-link'}
                                    nextClassName={'page-item'}
                                    nextLinkClassName={'page-link custom-prev-next-link'}
                                    activeClassName={'active custom-active'}
                                />
                            </div>
                        </>
                    )}
                </ModalFooter>
            </Modal>
        )
    }
}

export default HistoryPembayaranTagihan;