import React, {Component} from "react";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import i18n from "i18next";
import { Empty, Spin } from "antd";
import EmptyDataImage from "../../assets/images/data-kosong.png";
import { DEFAULT_IMG, IMG_HOST } from "../../helper/host";
import ReactPaginate from "react-paginate";
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import ArrowRight from "../../assets/icons/arrow-right.svg";
import Swal from "sweetalert2";

class TrashFasilitasPerusahaan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataTrash: [],
            checked: [],
            loadingTrash: false,
            checked_all: false,
            paginate: 5,
            currentPage: 0,
            perPage: 0,
            totalData: 0,
            pageCount: 0,
        }
        this.handlePageClick = this.handlePageClick.bind(this);
    }

    handleCheck(id) {
        this.state.checked.includes(id) ?
            this.setState(prevState => ({
                checked: prevState.checked.filter(currId => currId !== id),
            }), () => {
                if (this.state.checked.length === this.state.totalData) {
                    this.setState({
                        checked_all: true
                    })
                } else {
                    this.setState({
                        checked_all: false
                    })
                }
            })
            :
            this.setState(prevState => ({
                checked: [...prevState.checked, id],
            }), () => {
                if (this.state.checked.length === this.state.totalData) {
                    this.setState({
                        checked_all: true
                    })
                } else {
                    this.setState({
                        checked_all: false
                    })
                }
            })
    }

    handleCheckAll(e) {
        let value = e.target.checked
        if (value) {
            this.state.dataTrash.map((item) => {
                if (!this.state.checked.includes(item.facility_id)) {
                    this.setState(prevState => {
                        return {
                            ...prevState,
                            checked: [...prevState.checked, item.facility_id]
                        }
                    }, () => {
                        this.setState({
                            checked_all: true
                        })
                    })
                }
            })
        } else {
            this.setState({
                checked_all: false,
                checked: []
            })
        }
    }

    handlePageClick(dataTrash) {
        let selected = dataTrash.selected;
        this.setState({ currentPage: selected, dataTrash: [], loadingTrash: true}, () => this.loadTrash())
    }

    loadTrash() {
        this.setState({ loadingTrash: true, dataTrash: [] })
        let form = {
            page: this.state.currentPage + 1,
            paginate: this.state.paginate
        }
        this.props.request('fasilitas/trash', form, 'POST')
        .then((response) => {
            if (response.success) {
                this.setState({
                    dataTrash: response.data.data,
                    totalData: response.data.total,
                    pageCount: response.data.last_page,
                    perPage: response.data.per_page,
                    loadingTrash: false
                })
            }
        })
    }

    submitRestored() {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: `${this.state.checked.length} ${i18n.t('datafasilitasakandipulihkan')}`,
            icon: "warning",
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
        .then((result) => {
            if (result.isConfirmed) {
                let form = {
                    facilityIds: this.state.checked
                }
                this.props.request('fasilitas/restore', form, 'POST')
                .then((response) => {
                    if (response.success) {
                        this.props.changeState({ trash_visible: false, data: [], loading: false }, () => this.props.loadData())
                        Swal.fire({
                            title: i18n.t('berhasil'),
                            text: i18n.t('fasilitasberhasildipulihkan'),
                            icon: 'success'
                        })
                    } else {
                        Swal.fire({
                            title: i18n.t('gagal'),
                            text: response.message,
                            icon: 'warning',
                            confirmButtonText: 'OK'
                        })
                    }
                    this.setState({ currentPage: 0, dataTrash: [], loadingTrash: false, checked: [], checked_all: false })
                })
            }
        })
    }

    deletePermanent() {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: `${this.state.checked.length} ${i18n.t('datafasilitasakandihapuspermanen')}`,
            icon: "warning",
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
        .then((result) => {
            if (result.isConfirmed) {
                let form = {
                    facilityIds: this.state.checked
                }
                this.props.request('fasilitas/delete-permanent', form, 'POST')
                .then((response) => {
                    if (response.success) {
                        this.props.changeState({ trash_visible: false, data: [], loading: false }, () => this.props.loadData())
                        Swal.fire({
                            title: i18n.t('berhasil'),
                            text: i18n.t('fasilitasberhasildihapuspermanent'),
                            icon: 'success'
                        })
                    } else {
                        Swal.fire({
                            title: i18n.t('gagal'),
                            text: response.message,
                            icon: 'warning',
                            confirmButtonText: 'OK'
                        })
                    }
                    this.setState({ currentPage: 0, dataTrash: [], loadingTrash: false, checked: [], checked_all: false })
                })
            }
        })
    }

    componentDidUpdate(prevProps) {
    if (prevProps.trash_visible !== this.props.trash_visible && this.props.trash_visible) {
         this.setState({ loadingTrash: true}, () => this.loadTrash())
        }
    }

    render() {
        return (
            <Modal isOpen={this.props.trash_visible} size="lg" style={{  minWidth: '1100px' }} zIndex={999} toggle={() => this.props.changeState({ trash_visible: false })}>
                <ModalHeader toggle={() => this.props.changeState({ trash_visible: false })}>
                     <div className="pengumuman-modal-header-font">{i18n.t('sampahfasilitas')}</div>
                </ModalHeader>
                <ModalBody>
                    <div>
                        <div className="d-flex align-items-center justify-content-end" style={{  gap: 5 }}>
                            <button className="btn ipresens-btn-color" disabled={this.state.checked.length === 0} onClick={() => this.submitRestored()}>{i18n.t('pulihkan')}</button>
                            <button className="btn ipresens-btn-color" disabled={this.state.checked.length === 0} onClick={() => this.deletePermanent()}>{i18n.t('hapuspermanent')}</button>
                        </div>

                        <div>
                            {!this.state.loadingTrash && this.state.dataTrash.length === 0 ? (
                                <Empty description={i18n.t('datakosong')}
                                image={EmptyDataImage}
                                className="ipresens-image-empty mb-5"/>
                            ) : (
                                <table className="table table-borderless">
                                <thead>
                                    <tr>
                                        <th className="lembur-th-style text-dark" style={{ width: 10 }}>
                                            <input type="checkbox" name="checked_all" checked={this.state.checked_all} className="form-control" onChange={(e) => this.handleCheckAll(e)}/>
                                        </th>
                                        <th className="lembur-th-style text-dark text-center" style={{  width: '180px' }}>{i18n.t('fotofasilitas')}</th>
                                        <th className="lembur-th-style text-dark" style={{  width: '300px' }}>{i18n.t('namafasilitas')}</th>
                                        <th className="lembur-th-style text-dark">{i18n.t('deskripsifasilitas')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.loadingTrash &&
                                            <tr>
                                                <td colSpan={5}>
                                                    <Spin size="large" tip="Loading..." style={{
                                                        width: '100%',
                                                        margin: 'auto',
                                                        marginBottom: '10px',
                                                        marginTop: '10px'
                                                    }} />
                                                </td>
                                            </tr>
                                        }
                                    {this.state.dataTrash.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="lembur-td-style text-dark" style={{ width: 10 }}>
                                                    <input type="checkbox" name={"row-" + item.facility_id} className="form-control" checked={this.state.checked.includes(item.facility_id)} onChange={() => this.handleCheck(item.facility_id)}/>
                                                </td>
                                                <td className="lembur-td-style text-center" style={{  width: '180px' }}>
                                                    <a href={item.facility_image != null ? IMG_HOST + 'mitra/facility/' + item.facility_image : DEFAULT_IMG} target="_blank">
                                                        <img style={{ maxWidth: 130, borderRadius: "4px", objectFit: "cover", }} src={item.facility_image != null ? IMG_HOST + 'mitra/facility/' + item.facility_image : null} alt={item.facility_name}/>
                                                    </a>
                                                </td>
                                                <td className="lembur-td-style text-dark" style={{  width: '300px' }}>
                                                    {item.facility_name}
                                                </td>
                                                <td className="lembur-td-style text-dark">
                                                    {item.facility_desc}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            )}
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                {this.state.dataTrash.length !== 0 &&
                            <>
                                <div className='col font-14' style={{
                                    padding: "auto",
                                    margin: "auto",
                                    fontWeight: 500,
                                    lineHeight: "24px"
                                }}>{i18n.t('menampilkan')} {this.state.dataTrash.length === 0 || this.state.dataTrash.length === null ? "0" : this.state.dataTrash.length} {i18n.t('dari')} {this.state.totalData} {i18n.t('data')}
                                </div>
                                <div className='col'>
                                    <ReactPaginate
                                        previousLabel={<img src={ArrowLeft} alt=''/>}
                                        nextLabel={<img src={ArrowRight} alt=''/>}
                                        pageCount={this.state.pageCount}
                                        forcePage={this.state.currentPage}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={3}
                                        onPageChange={this.handlePageClick}
                                        breakClassName={'page-item'}
                                        breakLinkClassName={'page-link custom-page-link'}
                                        containerClassName={'pagination my-2'}
                                        pageClassName={'page-item'}
                                        pageLinkClassName={'page-link custom-page-link'}
                                        previousClassName={'page-item'}
                                        previousLinkClassName={'page-link custom-prev-next-link'}
                                        nextClassName={'page-item'}
                                        nextLinkClassName={'page-link custom-prev-next-link'}
                                        activeClassName={'active custom-active'}
                                    />
                                </div>
                            </>
                        }
                </ModalFooter>
            </Modal>
        )
    }
}
export default TrashFasilitasPerusahaan;
