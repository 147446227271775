import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import '../../index.scss';

import App from '../../components/app';
import { ScrollContext } from 'react-router-scroll-4';
import { connect } from 'react-redux'
import { clear_auth, init_auth } from "../../reducers/Auth";
import { init_filter, clear_filter } from "../../reducers/Filter"
import { requestAuth, requestData } from "../../helper/request";
import AdminRoute from "../../adminRoute";
import PendingRoute from "../../pendingRoute";
import { ToastContainer } from 'react-toastify';
import "./Toast.css"
// Homepage
import Home from '../../components/home';
import Blog from "../blog";
import BlogDetail from "../blog/detail";
import KebijakanPrivasi from "../kebijakan-privasi";
// Components
import Dashboard from '../dashboard';
import Monitor from "../pengamatan/monitor";

import Profile from '../../components/settings/profile/index';
import SettingMobile from '../../components/settings/mobile';
import PresensiMobile from '../../components/settings/presensi';
import SettingRecognition from '../../components/settings/recognition';
import UserPerusahaan from '../../components/settings/user/index';
import UserLevelPerusahaan from '../../components/settings/user/level';

import Login from '../../components/auth/login';
import LoginV2 from '../../components/auth/login-v2';
import Register from '../../components/auth/register';
import HapusKaryawan from '../../components/auth/hapus-karyawan';
import VerifyEmail from '../../components/auth/verifyEmail';
import VerifyEmailHapusKaryawan from '../../components/auth/verifyEmailHapusKaryawan';
import ResetPasswordPerusahaan from '../../components/auth/resetPassword';
import LupaPassword from '../../components/auth/lupaPassword';
import LupaPasswordV2 from '../auth/lupa-password-v2';
import AktifasiKaryawan from "../aktifasi-karyawan";
import ResetPassword from "../reset-password";
import ResetPasswordV2 from '../auth/reset-password-v2';
import TagihanRegister from "../tagihan-register";
//Pengajuan
import List_izin from "../pengajuan/izin";
import List_lembur from "../pengajuan/lembur";
import List_kasbon from "../pengajuan/kasbon/kasbon";
import Detail_kasbon from "../pengajuan/kasbon/detail/detail";
import List_klaim_beban from "../pengajuan/klaim-beban/klaim-beban";
import List_revisi_data from "../pengajuan/revisi-data/revisi_data";
//karyawan
import List_karyawan from '../../components/karyawan/list-karyawan';
import Add_karyawan from '../../components/karyawan/add-karyawan';
import Detail from '../karyawan/profil/detail';
import Edit_karyawan from '../../components/karyawan/edit-karyawan';
import List_cuti_karyawan from '../../components/karyawan/laporan-cuti/list-cuti-karyawan';
//guru
import ListGuru from "../guru/list";
//jabatan
import List_jabatan_karyawan from '../../components/jabatan-karyawan/list-jabatan-karyawan';
import Edit_jabatan_karyawan from '../../components/jabatan-karyawan/edit-jabatan-karyawan';
//shift
import List_shift from '../../components/shift/list';
import Edit_shift from '../../components/shift/edit';
//jam_kerja
import List_Work_Clock from '../../components/jam_kerja/list';
import Edit_Work_Clock from '../../components/jam_kerja/edit';
//absensi
import List_absensi from "../absensi/list";
import Detail_absensi from "../absensi/detail";
import Add_absensi from "../absensi/add";
import Edit_absensi from "../absensi/edit";

import List_absensi_bulan from "../absensi_bulan/list";

import Langganan from "../tagihan-register/langganan";
import ListPaketLangganan from "../tagihan-register/list_paket";
//payroll
import List_istillah from "../payroll-istillah/list"
import EditIstillah from "../payroll-istillah/edit"
import List_GajiKaryawan from "../payroll/list-gaji-karyawan"
import DetailGajiKaryawan from "../payroll/detail-gaji-karyawan"
import ListCutiKaryawan from "../payroll/list-cuti-karyawan"
import List_payroll from "../payroll/list";
import Detail_payroll from "../payroll/detail"

//pengumuman
import List_pengumuman from '../../components/pengumuman/list';
import Add_pengumuman from "../pengumuman/add";
import Detail_pengumuman from "../pengumuman/detail";
import Edit_pengumuman from "../pengumuman/edit";
//divisi
import List_divisi_karyawan from "../divisi_karyawan/list-divisi-karyawan";
import Edit_divisi_karyawan from "../divisi_karyawan/edit-divisi-karyawan";
//lokasi
import List_lokasi_absen from "../lokasi_absen/list-lokasi-absen";
import Add_lokasi_absensi from "../lokasi_absen/add-lokasi-absen";
import Edit_lokasi_absensi from "../lokasi_absen/edit-lokasi-absen";
//outlet
import List_outlet from "../outlet/list";
import Add_outlet from "../outlet/add";
import Edit_outlet from "../outlet/edit";
//kunjungan
import List_kunjungan from "../kunjungan/list";
import List_tracking from "../kunjungan/list_tracking";
import Detail_tracking from "../kunjungan/detail_tracking";
import Detail_kunjungan from "../kunjungan/detail";
import Laporan_Kunjungan from "../kunjungan/laporan_kunjungan";
//pembayaran_kasbon
import KasbonBayar from "../pembayaran-kasbon/list";
import KasbonBayarDetail from "../pembayaran-kasbon/detail";
import configureStore from '../../config/configureStore';
import PayrollSetting from "../payroll/setting";
// guide
import JamkerjaGuide from '../menu-guide/jam-kerja';
import DivisiGuide from '../menu-guide/divisi';
import LokasiGuide from '../menu-guide/lokasi';
import KaryawanGuide from '../menu-guide/karyawan';
import GuideKaryawanAdd from '../menu-guide/karyawan-add';
import GuideKaryawanEdit from '../menu-guide/karyawan-edit';
import GuideKaryawanDetail from '../menu-guide/detail-karyawan';
import NotificationList from "../notification";
import TaskList from "../task/list";
import FinishedTaskList from "../task/finished";
import TaskActivity from "../task/activity"
// shift jam kerja
import ShiftJamKerja from "../shift-jam-kerja/list";
// BPJS
import BPJSSetting from "../bpjs/settings";
import RestSetting from "../settings/istirahat";
import DetailPerusahaanUser from "../settings/user/detail";
import HistoryAdminList from "../history-admin";
//agenda
import AddAgenda from "../agenda/add"
import ListAgenda from "../agenda/list"
import Edit_Agenda from "../agenda/edit"
//inventaris
import ListInventaris from "../inventaris/list";
//banner
import List_banner from "../banner/list_banner";
//nilai siswa
import Nilai from "../nilai-siswa/nilai_list";
import ListNilaiKategori from '../nilai-siswa/kategori_nilai'
//pembayaran siswa
import Pembayaran from "../pembayaran-siswa/pembayaran_list";
import ListPaymentTerm from '../pembayaran-siswa/istilah_pembayaran'
import i18n from "i18next";
// Perurusahaan Structure
import PerusahaanStrukture from '../structure/view-structure'
// Kebijakan Perusahaan
import KebijakanPerusahaan from '../kebijakan-perusahaan/index'
// Fasilitas
import PerusahaanFacility from "../perusahaan-facility/list";
// Tunjangan
import TunjanganPerusahaan from "../perusahaan-tunjangan/list";
// Semester
import SemesterList from '../semester-sekolah/semester-list'
import List_Mapel from "../mapel/list";
import List_Jadwal from "../jadwal/list";
// Tagihan siswa
import TagihanSiswa from '../tagihan-siswa/list'
import PembayaranTagihan from '../pembayaran-tagihan/list'
import Page404 from "../404/404-page";
import IpWhitelist from "../settings/ip-whitelist/list";

const store = configureStore();

class Main extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active: false,
            data: [],
            user: [],
        };
    }

    componentDidMount() {
        var Auth = localStorage.getItem('Presensi-Auth')
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        var AuthUser = localStorage.getItem('Presensi-Auth-User')
        var all_data = localStorage.getItem('Presensi-Company-Data')

        if (Auth != null && AuthKey != null && AuthUser != null)
            this.props.init_auth(Auth, AuthKey, JSON.parse(AuthUser))
        if (all_data != null)
            this.props.init_filter(JSON.parse(all_data))
    }

    request = async (action, data, method) => {
        var AuthKey = await localStorage.getItem('Presensi-Auth-Key')

        await requestAuth('auth', AuthKey)
            .then((response) => {
                if (!response.auth) {
                    this.props.clear_auth()
                    this.props.clear_filter()
                }
            })

        if (action && data) {
            var AuthKey = await localStorage.getItem('Presensi-Auth-Key')

            data = { token: AuthKey, lang: i18n.language, ...data }

            if (this.props.user) {
                this.setState({
                    user: this.props.user.p_user_email
                })
            }

            return requestData(action, data, method)
        }
    }

    render() {
        return (
            <BrowserRouter basename={'/'}>
                <ScrollContext>
                    <Switch>
                        {/* <Route exact path="/"
                            render={(props) => {
                                return <Login
                                    request={(action, data, method = 'GET') => requestData(action, data, method)}
                                    {...props} />
                            }} /> */}
                        <Route exact path="/"
                            render={(props) => {
                                return <LoginV2
                                    request={(action, data, method = 'GET') => requestData(action, data, method)}
                                    {...props} />
                            }} />
                        <Route exact path="/absensi"
                            render={(props) => {
                                return <Home
                                    request={(action, data, method = 'GET') => requestData(action, data, method)}
                                    {...props} />
                            }} />
                        <Route exact path="/blog"
                            render={(props) => {
                                return <Blog
                                    request={(action, data, method = 'GET') => requestData(action, data, method)}
                                    {...props} />
                            }} />
                        <Route exact path="/blog/:alias"
                            render={(props) => {
                                return <BlogDetail
                                    request={(action, data, method = 'GET') => requestData(action, data, method)}
                                    {...props} />
                            }} />
                        <Route exact path="/kebijakan-privasi"
                            render={(props) => {
                                return <KebijakanPrivasi
                                    request={(action, data, method = 'GET') => requestData(action, data, method)}
                                    {...props} />
                            }} />
                        {/* <Route exact path="/login"
                            render={(props) => {
                                return <Login
                                    request={(action, data, method = 'GET') => requestData(action, data, method)}
                                    {...props} />
                            }} /> */}
                        <Route exact path="/login"
                            render={(props) => {
                                return <LoginV2
                                    request={(action, data, method = 'GET') => requestData(action, data, method)}
                                    {...props} />
                            }} />
                        <Route path="/register/:alias?"
                            render={(props) => {
                                return <Register
                                    request={(action, data, method = 'GET') => requestData(action, data, method)}
                                    {...props} />
                            }} />
                        <Route path="/hapus-karyawan"
                               render={(props) => {
                                   return <HapusKaryawan
                                       request={(action, data, method = 'GET') => requestData(action, data, method)}
                                       {...props} />
                               }} />
                        <Route path="/verify-hapus-karyawan"
                               render={(props) => {
                                   return <VerifyEmailHapusKaryawan
                                       request={(action, data, method = 'GET') => requestData(action, data, method)}
                                       {...props} />
                               }} />
                        <Route path="/verify"
                            render={(props) => {
                                return <VerifyEmail
                                    request={(action, data, method = 'GET') => requestData(action, data, method)}
                                    {...props} />
                            }} />
                        {/* <Route path="/lupa-password"
                            render={(props) => {
                                return <LupaPassword
                                    request={(action, data, method = 'GET') => requestData(action, data, method)}
                                    {...props} />
                            }} /> */}
                        <Route path="/lupa-password"
                            render={(props) => {
                                return <LupaPasswordV2
                                    request={(action, data, method = 'GET') => requestData(action, data, method)}
                                    {...props} />
                            }} />
                        {/* <Route path="/reset-password"
                            render={(props) => {
                                return <ResetPasswordPerusahaan
                                    request={(action, data, method = 'GET') => requestData(action, data, method)}
                                    {...props} />
                            }} /> */}
                        <Route path="/reset-password"
                            render={(props) => {
                                return <ResetPasswordV2
                                    request={(action, data, method = 'GET') => requestData(action, data, method)}
                                    {...props} />
                            }} />
                        <Route path="/karyawan/aktifasi-akun/:id?"
                            render={(props) => {
                                return <AktifasiKaryawan
                                    request={(action, data, method = 'GET') => requestData(action, data, method)}
                                    {...props} />
                            }} />
                        <Route path="/karyawan/reset-password/:token?"
                            render={(props) => {
                                return <ResetPassword
                                    request={(action, data, method = 'GET') => requestData(action, data, method)}
                                    {...props} />
                            }} />
                        <PendingRoute path="/tagihan"
                            component={TagihanRegister}
                            request={(action, data, method = 'GET') => this.request(action, data, method)} />

                            {/*Dashboard*/}
                            <AdminRoute exact path="/monitor"
                                component={Monitor}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/dashboard"
                                component={Dashboard}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/notification"
                                component={NotificationList}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/history-admin"
                                        component={HistoryAdminList}
                                        request={(action, data, method = 'GET') => this.request(action, data, method)} />

                                {/* Agenda */}
                            <AdminRoute exact path="/agenda/list-agenda"
                                component={ListAgenda}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/agenda/add"
                                        component={AddAgenda}
                                        request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/agenda/edit/:id"
                                        component={Edit_Agenda}
                                        request={(action, data, method = 'GET') => this.request(action, data, method)} />

                            {/* Guide */}
                            <AdminRoute exact
                                path="/guide/working-hours"
                                component={JamkerjaGuide}
                                request={(action, data, method = 'GET') => this.request(action, data, method)}
                            />
                            <AdminRoute exact
                                path="/guide/divisi"
                                component={DivisiGuide}
                                request={(action, data, method = 'GET') => this.request(action, data, method)}
                            />
                            <AdminRoute exact
                                path="/guide/lokasi"
                                component={LokasiGuide}
                                request={(action, data, method = 'GET') => this.request(action, data, method)}
                            />
                            <AdminRoute exact
                                path="/guide/karyawan"
                                component={KaryawanGuide}
                                request={(action, data, method = 'GET') => this.request(action, data, method)}
                            />
                            <AdminRoute exact
                                path="/guide/add-karyawan"
                                component={GuideKaryawanAdd}
                                request={(action, data, method = 'GET') => this.request(action, data, method)}
                            />
                            <AdminRoute exact
                                path="/guide/edit-karyawan/:id"
                                component={GuideKaryawanEdit}
                                request={(action, data, method = 'GET') => this.request(action, data, method)}
                            />
                            <AdminRoute exact
                                path="/guide/detail-karyawan/:id"
                                component={GuideKaryawanDetail}
                                request={(action, data, method = 'GET') => this.request(action, data, method)}
                            />

                            {/*Setting*/}
                            <AdminRoute exact path="/settings/profile"
                                component={Profile}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/settings/mobile"
                                        component={SettingMobile}
                                        request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/settings/presensi"
                                        component={PresensiMobile}
                                        request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            {/*<AdminRoute exact path="/settings/recognition"*/}
                            {/*            component={SettingRecognition}*/}
                            {/*            request={(action, data, method = 'GET') => this.request(action, data, method)} />*/}
                            <AdminRoute exact path="/settings/user"
                                component={UserPerusahaan}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/settings/user/level"
                                component={UserLevelPerusahaan}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/settings/user/detail/:id"
                                        component={DetailPerusahaanUser}
                                        request={(action, data, method = 'GET') => this.request(action, data, method)} />


                            {/*Pengajuan*/}
                            <AdminRoute exact path="/submission/permit" component={List_izin}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/submission/overtime" component={List_lembur} allowed={'perusahaan'}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/submission/casbon" component={List_kasbon} allowed={'perusahaan'}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/submission/casbon-detail/:id" component={Detail_kasbon} allowed={'perusahaan'}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/submission/claim-expenses" component={List_klaim_beban} allowed={'perusahaan'}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/pengajuan/revisi" component={List_revisi_data}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />


                            {/*Karyawan*/}
                            <AdminRoute exact path="/employee/employee-list/:page" component={List_karyawan}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/karyawan/tambah-karyawan" component={Add_karyawan}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/karyawan/profil/detail/:id" component={Detail}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/karyawan/edit-karyawan/:id/:page" component={Edit_karyawan}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/report-leave" component={List_cuti_karyawan}
                                        request={(action, data, method = 'GET') => this.request(action, data, method)} />

                            {/*Guru*/}
                            <AdminRoute exact path="/teacher/teacher-list" component={ListGuru} allowed={'sekolah'}
                                        request={(action, data, method = 'GET') => this.request(action, data, method)} />

                            {/*Jabatan Karyawan*/}
                            <AdminRoute exact path="/employee/employee-position" component={List_jabatan_karyawan} allowed={'perusahaan'}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/karyawan/edit-jabatan-karyawan/:id" component={Edit_jabatan_karyawan} allowed={'perusahaan'}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />

                            {/*Daftar Gaji*/}
                            <AdminRoute exact path="/employee-payroll" component={List_GajiKaryawan} allowed={'perusahaan'}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/detail-gaji-karyawan/:id" component={DetailGajiKaryawan} allowed={'perusahaan'}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/salary-list/list" component={List_payroll} allowed={'perusahaan'}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/daftar-gaji/detail/:id" component={Detail_payroll} allowed={'perusahaan'}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/payroll/setting" component={PayrollSetting} allowed={'perusahaan'}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/pengaturan/cuti" component={ListCutiKaryawan} allowed={'perusahaan'}
                                        request={(action, data, method = 'GET') => this.request(action, data, method)} />

                            {/* BPJS */}
                            <AdminRoute exact path="/bpjs/setting" component={BPJSSetting} allowed={'perusahaan'}
                                        request={(action, data, method = 'GET') => this.request(action, data, method)} />

                            {/*Pembayaran Kasbon*/}
                            <AdminRoute exact path="/cashbon-payment/list" component={KasbonBayar} allowed={'perusahaan'}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/pembayaran-kasbon/detail/:id" component={KasbonBayarDetail} allowed={'perusahaan'}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />

                            {/*Karyawan Shift*/}
                            <AdminRoute exact path="/employee/shift" component={List_shift} allowed={'perusahaan'}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/karyawan/edit-shift-karyawan/:id" component={Edit_shift} allowed={'perusahaan'}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />

                            {/* Shift Jam Kerja */}
                            <AdminRoute exact path="/karyawan/shift-jam-kerja" component={ShiftJamKerja} allowed={'perusahaan'}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />

                            {/*payroll istilah*/}
                            <AdminRoute exact path="/payroll-term-list" component={List_istillah} allowed={'perusahaan'}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/payroll-term-list/:id" component={EditIstillah} allowed={'perusahaan'}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />

                            {/*Karyawan Jam Kerja*/}
                            <AdminRoute exact path="/employee/hour-work" component={List_Work_Clock}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/karyawan/edit-jamKerja-karyawan/:id" component={Edit_Work_Clock}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />

                            {/*Tugas*/}
                            <AdminRoute exact path="/task" component={TaskList}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/task-completed" component={FinishedTaskList}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/task-activity" component={TaskActivity}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />

                            {/*Kunjungan*/}
                            <AdminRoute exact path="/visit/list-visit" component={List_kunjungan} allowed={'perusahaan'}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/visit/visit-report" component={Laporan_Kunjungan} allowed={'perusahaan'}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/kunjungan/daftar-tracking" component={List_tracking} allowed={'perusahaan'}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/kunjungan/detail-tracking/:id" component={Detail_tracking} allowed={'perusahaan'}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/detail-kunjungan/:id" component={Detail_kunjungan} allowed={'perusahaan'}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />

                            {/*Pengumuman*/}
                            <AdminRoute exact path="/announcement" component={List_pengumuman}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/tambah-pengumuman" component={Add_pengumuman}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/detail-pengumuman/:id" component={Detail_pengumuman}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/edit-pengumuman/:id" component={Edit_pengumuman}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />

                            {/*Absensi*/}
                            <AdminRoute exact path="/presence" component={List_absensi}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/tambah-presensi" component={Add_absensi}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/detail-presensi/:id" component={Detail_absensi}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/edit-presensi/:id" component={Edit_absensi}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/presence-month" component={List_absensi_bulan}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/pengaturan/istirahat" component={RestSetting}
                                        request={(action, data, method = 'GET') => this.request(action, data, method)} />

                            {/*Karyawan Divisi*/}
                            <AdminRoute exact path="/employee/division-employee" component={List_divisi_karyawan}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/karyawan/edit-divisi-karyawan/:id" component={Edit_divisi_karyawan}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />

                            {/*Langganan*/}
                            <PendingRoute exact path="/subscription" component={Langganan}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <PendingRoute exact path="/subscription/package" component={ListPaketLangganan}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />

                            {/*Lokasi Absensi*/}
                            <AdminRoute exact path="/presensi-location" component={List_lokasi_absen}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/add-presensi-location" component={Add_lokasi_absensi}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/edit-lokasi-presensi/:id" component={Edit_lokasi_absensi}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />

                            {/*Outlet*/}
                            <AdminRoute exact path="/visitation-location" component={List_outlet} allowed={'perusahaan'}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/add-visitation-location" component={Add_outlet} allowed={'perusahaan'}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/visitation-location/detail/:id" component={Detail_absensi} allowed={'perusahaan'}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/edit-visitation-location/:id" component={Edit_outlet} allowed={'perusahaan'}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />

                            {/*Inventaris*/}
                            <AdminRoute exact path="/inventaris" component={ListInventaris}
                                request={(action, data, method = 'GET') => this.request(action, data, method)} />

                            {/*Banner*/}
                            <AdminRoute exact path="/banner" component={List_banner}
                                        request={(action, data, method = 'GET') => this.request(action, data, method)} />

                            {/*Nilai Siswa*/}
                            <AdminRoute exact path="/student-score" component={Nilai} allowed={'sekolah'}
                                        request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/category-list-score" component={ListNilaiKategori} allowed={'sekolah'}
                                        request={(action, data, method = 'GET') => this.request(action, data, method)} />

                            {/*Penbayaran Siswa*/}
                            <AdminRoute exact path="/student-pay" component={Pembayaran} allowed={'sekolah'}
                                        request={(action, data, method = 'GET') => this.request(action, data, method)} />
                            <AdminRoute exact path="/term-list" component={ListPaymentTerm} allowed={'sekolah'}
                                        request={(action, data, method = 'GET') => this.request(action, data, method)} />

                            {/*Perusahaan Struktur*/}
                            <AdminRoute exact path="/structure" component={PerusahaanStrukture}
                                        request={(action, data, method = 'GET') => this.request(action, data, method)} />

                            {/*Kebijakan Perusahaan*/}
                            <AdminRoute exact path="/policy" component={KebijakanPerusahaan}
                                        request={(action, data, method = 'GET') => this.request(action, data, method)} />

                            {/*Fasilitas Perusahaan*/}
                            <AdminRoute exact path="/facility" component={PerusahaanFacility}
                                        request={(action, data, method = 'GET') => this.request(action, data, method)} />

                            {/*Informasi Tunjangan*/}
                            <AdminRoute exact path="/company-allowance" component={TunjanganPerusahaan} allowed={'perusahaan'}
                                        request={(action, data, method = 'GET') => this.request(action, data, method)} />

                            {/*Semester*/}
                            <AdminRoute exact path="/school-semester" component={SemesterList} allowed={'sekolah'}
                                        request={(action, data, method = 'GET') => this.request(action, data, method)} />

                            {/*Mata Pelajaran*/}
                            <AdminRoute exact path="/employee/subject" component={List_Mapel} allowed={'sekolah'}
                                        request={(action, data, method = 'GET') => this.request(action, data, method)} />

                            {/*Jadwal Mata Pelajaran*/}
                            <AdminRoute exact path="/employee/schedule" component={List_Jadwal} allowed={'sekolah'}
                                        request={(action, data, method = 'GET') => this.request(action, data, method)} />

                            {/*Tagihan Siswa*/}
                            <AdminRoute exact path="/student-bill" component={TagihanSiswa} allowed={'sekolah'}
                                        request={(action, data, method = 'GET') => this.request(action, data, method)} />

                            {/*List Pembayaran Tagihan*/}
                            <AdminRoute exact path="/bill-payment" component={PembayaranTagihan} allowed={'sekolah'}
                                        request={(action, data, method = 'GET') => this.request(action, data, method)} />

                            <AdminRoute path="/settings/ip-whitelist" component={IpWhitelist}
                                        request={(action, data, method = 'GET') => this.request(action, data, method)} />

                            <AdminRoute path="*" component={Page404} request={(action, data, method = 'GET') => this.request(action, data, method)}/>

                            <ToastContainer
                                autoClose={2000}
                                position="top-right"
                                className="toast-container"
                                toastClassName="dark-toast"
                            />

                    </Switch>
                </ScrollContext>
            </BrowserRouter>
        )
    }
}

const mapStateToProps = state => ({
    api_token: state.Auth.api_token,
    user: state.Auth.user
})

const mapDispatchToProps = dispatch => ({
    init_auth: (api_token, auth_key, data) => dispatch(init_auth(api_token, auth_key, data)),
    clear_auth: () => dispatch(clear_auth()),
    init_filter: (all_data) => dispatch(init_filter(all_data)),
    clear_filter: () => dispatch(clear_filter()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Main)
