import React, {Component} from "react";
import {Col, Modal as ModalRs, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import i18n from "i18next";
import {Spin} from "antd";
import {DEFAULT_IMG} from "../../helper/host";
import moment from "moment/moment";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg";
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import ArrowRight from "../../assets/icons/arrow-right.svg";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";

class HistoryGuru extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pagination: 2,
            pageCountHistory: 0,
            totalPageHistory: 0,
            totalPerpageHistory: 0,
            perPageHistory: 0,
            currentPageHistory: 0,
            guru_id: 0,
            dataHistory: [],
            latestHistory: [],
            loadingHistory: false,
        };
        this.handlePageHistory = this.handlePageHistory.bind(this);
    }

    componentDidUpdate(prevProps) {
        const { history_visible, guru_id } = this.props;

        if (history_visible && (!prevProps.history_visible || guru_id !== prevProps.guru_id)) {
            this.setState({ loadingHistory: true, guru_id: guru_id }, () => this.loadHistory(guru_id));
        }
    }

    handlePageHistory(dataHistory) {
        let selected = dataHistory.selected; // zero based index
        this.setState({ currentPageHistory: selected, dataHistory: [], loadingHistory: true }, () => {
            this.loadHistory();
        });
    }

    loadHistory(id) {
        this.setState({
            dataHistory: [],
            loadingHistory: true
        },()=>{
            let form = {
                id: id ? id : this.state.guru_id,
                paging : this.state.pagination,
                page: this.state.currentPageHistory + 1,
            };

            this.props.request("guru/history", form, 'POST')
                .then(response => {
                    this.setState({ loadingHistory: false })
                    if (response.succes) {
                        this.setState({
                            dataHistory: response.data.data,
                            latestHistory: response.latest,
                            pageCountHistory: response.data.last_page,
                            totalDataHistory: response.data.total,
                            totalPerpageHistory: response.data.to,
                            perPageHistory: response.data.per_page,
                        });
                    }
                });
        })
    }

    submitRevert(history_id, guru_id) {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('yakinrevertguru'),
            icon: "warning",
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        history_id: history_id || this.state.history_id,
                        guru_id: guru_id || this.state.guru_id,
                    };
                    this.props.request("guru/revert", form, 'POST')
                        .then((response) => {
                            this.props.changeState({ history_visible: false, loading: true}, () => this.props.loadData())
                            if (response.success) {
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: i18n.t('berhasilmengembalikandataguru'),
                                    icon: 'success'
                                })
                            } else {
                                Swal.fire({
                                    title: i18n.t('gagal'),
                                    text: response.message,
                                    icon: 'warning',
                                    confirmButtonText: 'OK'
                                })
                            }
                        })
                }
            })
    }

    render() {
        return (
            <ModalRs size="lg" style={{ maxWidth: "1000px" }} isOpen={this.props.history_visible} toggle={() => this.props.changeState({ history_visible: false })}>
                <ModalHeader style={{ backgroundColor: "#FAFDFF" }} toggle={() => this.props.changeState({ history_visible: false })}>
                    <div className='modal-header-reactstrap'>{i18n.t('riwayatperubahan')}</div>
                </ModalHeader>
                <ModalBody>
                    <div className="list-group">
                        <div>
                            {this.state.loadingHistory && (
                                <div style={{ textAlign: 'center', margin: '10px auto' }}>
                                    <Spin
                                        size="large"
                                        tip="Loading..."
                                        style={{
                                            width: '100%',
                                            margin: 'auto',
                                        }}
                                    />
                                </div>
                            )}

                            {this.state.dataHistory.length !== 0 && (
                                <div>
                                    <Row>
                                        <Col xs={0} md={0} className="text-left" style={{ marginLeft: '20px' }}>
                                            <h4>{i18n.t('user')}</h4>
                                        </Col>
                                        <Col xs={0} md={0} className="text-left"style={{ marginRight: '20px' }}>
                                            <h4>{i18n.t('aktivitas')}</h4>
                                        </Col>
                                    </Row>

                                    {this.state.dataHistory.map((item, index) => {
                                        return (
                                            <div key={index} style={{ marginBottom: '20px' }}>
                                                <Row>
                                                    <Col xs={12} md={6} className="text-left">
                                                        <div style={{ display: 'flex', alignItems: 'center', paddingTop: '16px',paddingBottom:'16px', paddingRight:'12px', paddingLeft:'12px' }}>
                                                            <div style={{ marginRight: '16px' }}>
                                                                <img
                                                                    src={DEFAULT_IMG}
                                                                    alt=""
                                                                    style={{ width: '48px' , marginBottom:'25px', height:'48px'}}
                                                                />
                                                            </div>
                                                            <div>
                                                                <h6
                                                                    className="mb-0 font-13"
                                                                    style={{
                                                                        fontWeight: 500,
                                                                        fontSize: '16px',
                                                                        lineHeight: '24px',
                                                                    }}
                                                                >
                                                                    {item.p_user_name} | {item.level_name}
                                                                </h6>
                                                                <p className="m-0 font-12">
                                                                                    <span className="col-green font-weight-bold">
                                                                                      {moment(item.history_create_date).format('lll')}
                                                                                    </span>
                                                                </p>
                                                                <div style={{ marginTop: 12, gap: 5 }} className="d-flex align-items-center">
                                                                    {item.history_status === 'created' ? (
                                                                        <>
                                                                            <div
                                                                                className="m-0"
                                                                                style={{
                                                                                    border: '1px solid #000',
                                                                                    borderRadius: '25px',
                                                                                    width: '50%',
                                                                                    color: '#7805e4',
                                                                                    borderColor: '#7805e4',
                                                                                }}
                                                                            >
                                                                                <div className="d-flex justify-content-center" style={{
                                                                                    paddingTop: '6px',
                                                                                    paddingBottom: '6px'
                                                                                }}>
                                                                                    {i18n.t('dibuat')}
                                                                                </div>
                                                                            </div>
                                                                            {this.state.latestHistory.history_id !== item.history_id && (
                                                                                <button
                                                                                    className="px-5 d-flex justify-content-center ipresens-btn-revert py-2"
                                                                                    style={{borderRadius: "25px"}}
                                                                                    onClick={() => this.setState({
                                                                                        history_id: item.history_id,
                                                                                        guru_id: item.guru_id
                                                                                    }, () => this.submitRevert(item.history_id, item.guru_id))}>
                                                                                    {i18n.t('kembalikan')}
                                                                                </button>
                                                                            )}
                                                                        </>
                                                                        ) : item.history_status === 'reverted' ? (
                                                                        <div className="m-0" style={{
                                                                                border: '1px solid #000',
                                                                                borderRadius: '25px',
                                                                                width: '75%',
                                                                                color: '#7805e4',
                                                                                borderColor: '#7805e4',
                                                                                fontSize: "14px"
                                                                            }}>
                                                                            <div
                                                                                className="d-flex justify-content-center"
                                                                                style={{
                                                                                    paddingTop: '6px',
                                                                                    paddingBottom: '6px'
                                                                                }}>
                                                                                {i18n.t('dikembalikan')}
                                                                            </div>
                                                                        </div>
                                                                    ) :(
                                                                        <>
                                                                            <div
                                                                                className="m-0"
                                                                                style={{
                                                                                    border: '1px solid #000',
                                                                                    borderRadius: '25px',
                                                                                    width: '50%',
                                                                                    color: '#7805e4',
                                                                                    borderColor: '#7805e4',
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    className="d-flex justify-content-center"
                                                                                    style={{
                                                                                        paddingTop: '6px',
                                                                                        paddingBottom: '6px'
                                                                                    }}>
                                                                                    {i18n.t('diubah')}
                                                                                </div>
                                                                            </div>
                                                                            {this.state.latestHistory.history_id !== item.history_id && (
                                                                                <button
                                                                                    className="px-5 d-flex justify-content-center ipresens-btn-revert py-2"
                                                                                    style={{borderRadius: "25px"}}
                                                                                    onClick={() => this.setState({
                                                                                        history_id: item.history_id,
                                                                                        guru_id: item.guru_id
                                                                                    }, () => this.submitRevert(item.history_id, item.guru_id))}>
                                                                                    {i18n.t('kembalikan')}
                                                                                </button>
                                                                            )}
                                                                        </>

                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xs={12} md={6} className="text-left" style={{
                                                        paddingTop: '16px',
                                                        paddingBottom: '16px',
                                                        paddingRight: '12px',
                                                        paddingLeft: '12px'
                                                    }}>
                                                        {item.history_status !== 'created' && (
                                                            <div>
                                                                {item.parent_history ?
                                                                    item.parent_history.guru_nama != item.guru_nama && (
                                                                        <div>
                                                                            <h6
                                                                                className="mb-3 font-13 text-left"
                                                                                style={{
                                                                                    fontWeight: 500,
                                                                                    fontSize: '16px',
                                                                                    lineHeight: '24px',
                                                                                }}
                                                                            >
                                                                                {i18n.t('nama')}: {i18n.t('dari')}{' '}
                                                                                <b>
                                                                                    {item.parent_history
                                                                                        ? item.parent_history.guru_nama
                                                                                        : 'N/A'}{' '}
                                                                                </b>
                                                                                <img
                                                                                    src={ArrowRightIcon}
                                                                                    alt=""
                                                                                    style={{
                                                                                        width: '20x',
                                                                                        marginBottom: '3px',
                                                                                        height: '20px'
                                                                                    }}
                                                                                /> {i18n.t('jadi')}: <b>{item.guru_nama ? item.guru_nama : ''}</b>
                                                                            </h6>
                                                                        </div>
                                                                    ):null
                                                                }
                                                                {item.parent_history ?
                                                                    item.parent_history.guru_alamat != item.guru_alamat && (
                                                                        <div>
                                                                            <h6
                                                                                className="mb-3 font-13 text-left"
                                                                                style={{
                                                                                    fontWeight: 500,
                                                                                    fontSize: '16px',
                                                                                    lineHeight: '24px',
                                                                                }}
                                                                            >
                                                                                {i18n.t('alamat')}: {i18n.t('dari')}{' '}
                                                                                <b>
                                                                                    {item.parent_history
                                                                                        ? item.parent_history.guru_alamat
                                                                                        : 'N/A'}{' '}
                                                                                </b>
                                                                                <img
                                                                                    src={ArrowRightIcon}
                                                                                    alt=""
                                                                                    style={{
                                                                                        width: '20x',
                                                                                        marginBottom: '3px',
                                                                                        height: '20px'
                                                                                    }}
                                                                                /> {i18n.t('jadi')}: <b>{item.guru_alamat ? item.guru_alamat : ''}</b>
                                                                            </h6>
                                                                        </div>
                                                                    ):null
                                                                }
                                                                {item.parent_history ?
                                                                    item.parent_history.guru_no_telp != item.guru_no_telp && (
                                                                        <div>
                                                                            <h6
                                                                                className="mb-3 font-13 text-left"
                                                                                style={{
                                                                                    fontWeight: 500,
                                                                                    fontSize: '16px',
                                                                                    lineHeight: '24px',
                                                                                }}
                                                                            >
                                                                                {i18n.t('notelepon')}: {i18n.t('dari')}{' '}
                                                                                <b>
                                                                                    {item.parent_history
                                                                                        ? item.parent_history.guru_no_telp
                                                                                        : 'N/A'}{' '}
                                                                                </b>
                                                                                <img
                                                                                    src={ArrowRightIcon}
                                                                                    alt=""
                                                                                    style={{
                                                                                        width: '20x',
                                                                                        marginBottom: '3px',
                                                                                        height: '20px'
                                                                                    }}
                                                                                /> {i18n.t('jadi')}: <b>{item.guru_no_telp ? item.guru_no_telp : ''}</b>
                                                                            </h6>
                                                                        </div>
                                                                    ):null
                                                                }
                                                            </div>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter style={{ backgroundColor: "#F5F5F5" }}>
                    <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                        {this.state.dataHistory.length !== 0 &&
                            <>
                                <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>{i18n.t("menampilkan")} {this.state.dataHistory.length === 0 || this.state.dataHistory.length === null ? "0" : this.state.dataHistory.length} {i18n.t("dari")} {this.state.totalDataHistory} data</div>
                                <div className='col'>
                                    <ReactPaginate
                                        previousLabel={<img src={ArrowLeft} alt='' />}
                                        nextLabel={<img src={ArrowRight} alt='' />}
                                        pageCount={this.state.pageCountHistory}
                                        forcePage={this.state.currentPageHistory}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={3}
                                        onPageChange={this.handlePageHistory}
                                        breakClassName={'page-item'}
                                        breakLinkClassName={'page-link custom-page-link'}
                                        containerClassName={'pagination my-2'}
                                        pageClassName={'page-item'}
                                        pageLinkClassName={'page-link custom-page-link'}
                                        previousClassName={'page-item'}
                                        previousLinkClassName={'page-link custom-prev-next-link'}
                                        nextClassName={'page-item'}
                                        nextLinkClassName={'page-link custom-prev-next-link'}
                                        activeClassName={'active custom-active'}
                                    />
                                </div>
                            </>
                        }
                    </div>

                </ModalFooter>
            </ModalRs>
        )
    }
}

export default HistoryGuru;