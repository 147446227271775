import React, { Component, Fragment } from 'react';
import { Empty, Modal, Spin, Tooltip } from 'antd';
import { Modal as ModalRs, ModalHeader, ModalBody } from 'reactstrap';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Swal from "sweetalert2";
import moment from "moment";
import { Helmet } from "react-helmet";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { connect } from "react-redux";
import { pageNumber } from "../../functions/functionGeneral";
import { APIKEY, BACKEND_API_HOST, IMG_HOST } from "../../helper/host";
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker';
import ReactPaginate from "react-paginate";
import DocumentIcon from "../../assets/icons/document-text.svg";
import SearchIcon from "../../assets/icons/search.svg";
import CalendarIcon from "../../assets/icons/calendar-dark-blue.svg";
import ExportIcon from "../../assets/icons/download-white.svg"
import RefreshIcon from "../../assets/icons/refresh.svg";
import ElipsisIcon from "../../assets/icons/ellipsis-vertical.svg";
import EmptyPng from "../../assets/images/data-kosong.png";
import ArrowRight from '../../assets/icons/arrow-right.svg'
import ArrowLeft from '../../assets/icons/arrow-left.svg'
import "./style.css";
import EmptyDataImage from "../../assets/images/data-kosong.png";
import i18n from 'i18next';

export class List_kunjungan extends Component {
    constructor(props) {
        super(props);
        let now = new Date();
        let start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
        let end = moment(start).add(1, "days").subtract(1, "seconds");
        this.state = {
            loading: true,
            karyawan: [],
            data: [],
            idDel: '',
            keyword: "",
            modal: false,
            kunjunganStartDate: '',
            kunjunganEndDate: '',
            modalExport: false,
            pageCount: 0,
            currentPage: 0,
            totalData: 0,
            perPage: 0,
            paging: 20,
            guide: false,
            start: start,
            end: end,
            start_date: "",
            end_date: "",
            date: "",
            lat: "",
            lng: "",
            modalLampiran: false,
            lampiran1: null,
            lampiran2: null,
            lampiran3: null,
            lampiran1_split: null,
            lampiran2_split: null,
            lampiran3_split: null,
            totalPerpage: 0,
            dataDetail: {}
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleDate = this.handleDate.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.exportLaporanKunjungan = this.exportLaporanKunjungan.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.wantDelete = this.wantDelete.bind(this)
        this.applyCallback = this.applyCallback.bind(this);
    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    applyCallback(startDate, endDate) {
        this.setState({
            start: startDate,
            end: endDate,
            start_date: startDate.format("YYYY-MM-DD"),
            end_date: endDate.format("YYYY-MM-DD"),
        }, () => {
            this.setState({
                date: this.state.start_date + "-" + this.state.end_date
            })
            this.loadData()
        })
    }

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, data: [], loading: true }, () => {
            this.loadData();
        });
    }

    handleOk = e => {
        this.setState({
            modalExport: false,
            modal: false,
        });
    };

    handleCancel = e => {
        this.setState({
            modalExport: false,
            modal: false,
        });
    };

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    handleDate(event) {
        const start = event.selectStart;
        const end = event.selectEnd;

        this.setState({
            data: [],
            loading: true,
            modalExport: true,
            kunjunganStartDate: start,
            kunjunganEndDate: end
        }, () => {
            this.loadData()
        });
    }

    handleFilter(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            data: [],
            loading: true
        }, () => this.loadData())
    }

    resetFilter(event) {
        this.setState({
            keyword: '',
            kunjunganStartDate: '',
            kunjunganEndDate: '',
            data: [],
            loading: true,
            date: "",
            start_date: "",
            end_date: ""
        }, () => this.loadData())
    }


    exportLaporanKunjungan() {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'outlet/export?token=' + AuthKey + '&kunjunganStartDate=' + this.state.kunjunganStartDate + '&kunjunganEndDate=' + this.state.kunjunganEndDate, "_blank")
    }

    wantDelete() {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('andayakininginmenghapuskunjungan') ,
            icon: 'warning',
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        id: this.state.idDel
                    }
                    this.setState({
                        loading: true,
                        data: [],
                    })
                    this.props.request("outlet/delete_kunjungan", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    loadData() {
        let form = {
            paginate: 10,
            page: this.state.currentPage + 1,
            keyword: this.state.keyword,
            kunjunganStartDate: this.state.start_date,
            kunjunganEndDate: this.state.end_date,
        }
        this.props.request("outlet/list_kunjungan", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        loading: false,
                        totalData: response.data.total,
                        totalPerpage: response.data.to,
                    })
                }
            });
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        let no = 0;
        const stylefoto = {
            maxHeight: '50px',
            maxWidth: '50px',
        }
        const steps = [
            {
                selector: '.tambah-kunjungan',
                content: 'Anda dapat menambah Kunjungan disini',
            },
            {
                selector: '.daftar-kunjungan',
                content: 'Daftar Kunjungan anda dapat dilihat disini',
            },
            {
                selector: '.filter-kunjungan',
                content: 'Anda dapat mem-Filter daftar Kunjungan anda lewat sini',
            },
        ];
        let now = new Date();
        let start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
        let end = moment(start).add(1, "days").subtract(1, "seconds");
        let ranges = {
            "Today Only": [moment(start), moment(end)],
            "Yesterday Only": [moment(start).subtract(1, "days"), moment(end).subtract(1, "days")],
            "3 Days": [moment(start).subtract(3, "days"), moment(end)]
        }
        let local = {
            "format": "DD-MM-YYYY HH:mm",
            "sundayFirst": false
        }
        let maxDate = moment(start).add(24, "hour")

        if (!this.props.user.user_level.level_page_limited || this.props.user.user_level.level_page_limited.search("Kunjungan") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{i18n.t('daftarkunjungan')} - iPresens</title>
                        <meta name="description" content="Daftar Kunjungan" />
                    </Helmet>
                    <Modal
                        title={i18n.t('exportkunjungan')}
                        visible={this.state.modalExport}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                    >
                        <>
                            <button className={'btn btn-success mt-2 col-md-12'} onClick={this.exportLaporanKunjungan}>{i18n.t('exportlaporankunjungankaryawan')}</button>
                        </>
                    </Modal>
                    <ModalRs size='lg' isOpen={this.state.modal} toggle={() => this.setState({ modal: false })}>
                        <ModalHeader style={{ backgroundColor: "#FAFDFF" }} toggle={() => this.setState({ modal: false })}>{i18n.t('detaillokasi')}</ModalHeader>
                        <ModalBody>
                            <div style={{
                                position: 'relative',
                                width: '100%',
                                height: '250px'
                            }}>
                                <Map google={this.props.google}
                                    initialCenter={{
                                        lat: this.state.lat,
                                        lng: this.state.lng
                                    }}
                                    zoom={15}>
                                    <Marker />
                                </Map>
                            </div>
                        </ModalBody>
                    </ModalRs>
                    <ModalRs size='sm' style={{ marginRight: "24px" }} isOpen={this.state.modalLampiran} toggle={() => this.setState({ modalLampiran: false })}>
                        <ModalHeader style={{ border: "none" }} toggle={() => this.setState({ modalLampiran: false })}><div style={{
                            fontSize: "18px",
                            fontWeight: 700,
                            lineHeight: "27px",
                            color: "#143B5E"
                        }}>{i18n.t('lampiran')}</div></ModalHeader>
                        <ModalBody>
                            <div className='row'>
                                <div className='col-md-12'>
                                    {this.state.lampiran1_split !== null && this.state.lampiran1 !== null && this.state.lampiran1 !== "" ?
                                        this.state.lampiran1_split[1] === "jpg" || this.state.lampiran1_split[1] === "jpeg" || this.state.lampiran1_split[1] === "png" || this.state.lampiran1_split[1] === "webp" ?
                                            <a href={"https://services.presensi.co.id/media/frontapp/kunjungan/" + this.state.lampiran1} target={"_blank"}>
                                                <img src={IMG_HOST + 'frontapp/kunjungan/' + this.state.lampiran1} alt='' className='dk-img-lampiran' />
                                            </a>
                                            :
                                            <a href={"https://services.presensi.co.id/media/frontapp/kunjungan/" + this.state.lampiran1} target={"_blank"}>
                                                <div style={{
                                                    border: "1px solid #DDDDDD",
                                                    borderRadius: "8px",
                                                    padding: "35px 44px 19px"
                                                }}>
                                                    <div className='row'>
                                                        <div className='col-md-12 text-center'>
                                                            <img alt='' src={DocumentIcon} />
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-12 text-center' style={{
                                                            fontSize: "11px",
                                                            fontWeight: 500,
                                                            lineHeight: "17px",
                                                            color: "#5D6F80"
                                                        }}>{this.state.lampiran1}</div>
                                                    </div>
                                                </div>
                                            </a>
                                        :
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className='text-center' style={{
                                                    padding: "35px 44px 19px",
                                                    border: "1px solid #DDDDDD",
                                                    borderRadius: "8px",
                                                }}>
                                                    <img alt='' src={EmptyPng} style={{ height: "100%", width: "100%" }} />
                                                    <div style={{
                                                        fontSize: "11px",
                                                        fontWeight: 500,
                                                        lineHeight: "17px",
                                                        color: "#5D6F80"
                                                    }}>{i18n.t("datakosong")}</div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className='row mb-4'>
                                <div className='col-md-12' style={{
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    lineHeight: "24px",
                                    color: "#5D6F80"
                                }}>
                                    {i18n.t('lampiran')} 1
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    {this.state.lampiran2_split !== null && this.state.lampiran2 !== null && this.state.lampiran2 !== "" ?
                                        this.state.lampiran2_split[1] === "jpg" || this.state.lampiran2_split[1] === "jpeg" || this.state.lampiran2_split[1] === "png" || this.state.lampiran2_split[1] === "webp" ?
                                            <a href={"https://services.presensi.co.id/media/frontapp/kunjungan/" + this.state.lampiran2} target={"_blank"}>
                                                <img src={IMG_HOST + 'frontapp/kunjungan/' + this.state.lampiran2} alt='' className='dk-img-lampiran' />
                                            </a>
                                            :
                                            <a href={"https://services.presensi.co.id/media/frontapp/kunjungan/" + this.state.lampiran2} target={"_blank"}>
                                                <div style={{
                                                    border: "1px solid #DDDDDD",
                                                    borderRadius: "8px",
                                                    padding: "35px 44px 19px"
                                                }}>
                                                    <div className='row'>
                                                        <div className='col-md-12 text-center'>
                                                            <img alt='' src={DocumentIcon} />
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-12 text-center' style={{
                                                            fontSize: "11px",
                                                            fontWeight: 500,
                                                            lineHeight: "17px",
                                                            color: "#5D6F80"
                                                        }}>{this.state.lampiran2}</div>
                                                    </div>
                                                </div>
                                            </a>
                                        :
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className='text-center' style={{
                                                    padding: "35px 44px 19px",
                                                    border: "1px solid #DDDDDD",
                                                    borderRadius: "8px",
                                                }}>
                                                    <img alt='' src={EmptyPng} style={{ height: "100%", width: "100%" }} />
                                                    <div style={{
                                                        fontSize: "11px",
                                                        fontWeight: 500,
                                                        lineHeight: "17px",
                                                        color: "#5D6F80"
                                                    }}>Data Kosong</div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className='row mb-4'>
                                <div className='col-md-12' style={{
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    lineHeight: "24px",
                                    color: "#5D6F80"
                                }}>
                                    {i18n.t('lampiran')} 2
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    {this.state.lampiran3_split !== null && this.state.lampiran3 !== null && this.state.lampiran3 !== "" ?
                                        this.state.lampiran3_split[1] === "jpg" || this.state.lampiran3_split[1] === "jpeg" || this.state.lampiran3_split[1] === "png" || this.state.lampiran3_split[1] === "webp" ?
                                            <a href={"https://services.presensi.co.id/media/frontapp/kunjungan/" + this.state.lampiran3} target={"_blank"}>
                                                <img src={IMG_HOST + 'frontapp/kunjungan/' + this.state.lampiran3} alt='' className='dk-img-lampiran' />
                                            </a>
                                            :
                                            <a href={"https://services.presensi.co.id/media/frontapp/kunjungan/" + this.state.lampiran3} target={"_blank"}>
                                                <div style={{
                                                    border: "1px solid #DDDDDD",
                                                    borderRadius: "8px",
                                                    padding: "35px 44px 19px"
                                                }}>
                                                    <div className='row'>
                                                        <div className='col-md-12 text-center'>
                                                            <img alt='' src={DocumentIcon} />
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-12 text-center' style={{
                                                            fontSize: "11px",
                                                            fontWeight: 500,
                                                            lineHeight: "17px",
                                                            color: "#5D6F80"
                                                        }}>{this.state.lampiran3}</div>
                                                    </div>
                                                </div>
                                            </a>
                                        :
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className='text-center' style={{
                                                    padding: "35px 44px 19px",
                                                    border: "1px solid #DDDDDD",
                                                    borderRadius: "8px",
                                                }}>
                                                    <img alt='' src={EmptyPng} style={{ height: "100%", width: "100%" }} />
                                                    <div style={{
                                                        fontSize: "11px",
                                                        fontWeight: 500,
                                                        lineHeight: "17px",
                                                        color: "#5D6F80"
                                                    }}>Data Kosong</div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12' style={{
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    lineHeight: "24px",
                                    color: "#5D6F80"
                                }}>
                                    {i18n.t('lampiran')} 3
                                </div>
                            </div>
                        </ModalBody>
                    </ModalRs>
                    <ModalRs style={{ maxWidth: "1000px" }} size='lg' isOpen={this.state.modalDetail} toggle={() => this.setState({ modalDetail: false, dataDetail: {} })}>
                        <ModalHeader toggle={() => this.setState({ modalDetail: false, dataDetail: {} })}>
                            <div className='dk-detail-header-font'>{i18n.t('detailkunjungan')}</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className='row'>
                                <div className='col-md-8'>
                                    <div className='row mb-4'>
                                        <div className='col-md-5 mb-2'>
                                            {this.state.dataDetail.foto_kunjungan !== null || this.state.dataDetail.foto_kunjungan !== "" ? (
                                                <a href={"https://services.presensi.co.id/media/frontapp/kunjungan/" + this.state.dataDetail.foto_kunjungan} target={"_blank"}>
                                                    <img
                                                        src={IMG_HOST + 'frontapp/kunjungan/' + this.state.dataDetail.foto_kunjungan}
                                                        style={{
                                                            height: "200px",
                                                            width: "100%",
                                                            objectFit: "cover",
                                                            borderRadius: "8px"
                                                        }}
                                                        className="img-fluid blur-up lazyloaded"
                                                        alt='' /></a>) : (
                                                <img alt='' src={IMG_HOST + 'user-profile.png'}
                                                    style={{ maxWidth: "100%", maxHeight: "250px" }}
                                                    className="img-fluid blur-up lazyloaded" />
                                            )}
                                        </div>
                                        <div className='col-md-7'>
                                            <div className='card' style={{ borderRadius: "8px", height: "100%" }}>
                                                <div className='card-header dk-ch-detail'>{i18n.t('kunjungan')}  {moment(this.state.dataDetail.kunjungan_create_date).format("dddd")} {moment(this.state.dataDetail.kunjungan_create_date).format("Do MMMM YYYY")}</div>
                                                <div className='card-body dk-cb-detail'>
                                                    <div className='row d-flex flex-nowrap'>
                                                        <div className='col-md-5 px-0 dk-cb-detail-label'>{i18n.t('namakaryawan')}</div>
                                                        <div className='col-md-1'>:</div>
                                                        <div className='col-md-6 px-0 dk-cb-detail-value'>{this.state.dataDetail.karyawan && this.state.dataDetail.karyawan.karyawan_nama}</div>
                                                    </div>
                                                    <div className='row d-flex flex-nowrap'>
                                                        <div className='col-md-5 px-0 dk-cb-detail-label'>{i18n.t('lokasikunjungan')}</div>
                                                        <div className='col-md-1'>:</div>
                                                        <div className='col-md-6 px-0 dk-cb-detail-value'>{this.state.dataDetail.outlet && this.state.dataDetail.outlet.outlet_nama}</div>
                                                    </div>
                                                    <div className='row d-flex flex-nowrap'>
                                                        <div className='col-md-5 px-0 dk-cb-detail-label'>{i18n.t('keterangankunjungan')}</div>
                                                        <div className='col-md-1'>:</div>
                                                        <div className='col-md-6 px-0 dk-cb-detail-value'>{this.state.keterangan_kunjungan ? this.state.keterangan_kunjungan : i18n.t('keterangankosong')}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <div className='col-md-12'>
                                            <div className='dk-map-responsive'>
                                                <Map google={this.props.google} style={{ borderRadius: "8px" }}
                                                    initialCenter={{
                                                        lat: this.state.dataDetail && this.state.dataDetail.latitude_kunjungan,
                                                        lng: this.state.dataDetail && this.state.dataDetail.longitude_kunjungan
                                                    }}
                                                    zoom={15}>
                                                    <Marker />
                                                </Map>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            {this.state.lampiran1_split !== null && this.state.lampiran1 !== null && this.state.lampiran1 !== "" ?
                                                this.state.lampiran1_split[1] === "jpg" || this.state.lampiran1_split[1] === "jpeg" || this.state.lampiran1_split[1] === "png" || this.state.lampiran1_split[1] === "webp" ?
                                                    <a href={"https://services.presensi.co.id/media/frontapp/kunjungan/" + this.state.lampiran1} target={"_blank"}>
                                                        <img src={IMG_HOST + 'frontapp/kunjungan/' + this.state.lampiran1} alt='' className='dk-img-lampiran' />
                                                    </a>
                                                    :
                                                    <a href={"https://services.presensi.co.id/media/frontapp/kunjungan/" + this.state.lampiran1} target={"_blank"}>
                                                        <div style={{
                                                            border: "1px solid #DDDDDD",
                                                            borderRadius: "8px",
                                                            padding: "35px 44px 19px"
                                                        }}>
                                                            <div className='row'>
                                                                <div className='col-md-12 text-center'>
                                                                    <img alt='' src={DocumentIcon} />
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-md-12 text-center' style={{
                                                                    fontSize: "11px",
                                                                    fontWeight: 500,
                                                                    lineHeight: "17px",
                                                                    color: "#5D6F80"
                                                                }}>{this.state.lampiran1}</div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                :
                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <div className='text-center' style={{
                                                            padding: "35px 44px 19px",
                                                            border: "1px solid #DDDDDD",
                                                            borderRadius: "8px",
                                                        }}>
                                                            <img alt='' src={EmptyPng} style={{ height: "100%", width: "100%" }} />
                                                            <div style={{
                                                                fontSize: "11px",
                                                                fontWeight: 500,
                                                                lineHeight: "17px",
                                                                color: "#5D6F80"
                                                            }}>Data Kosong</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='row mb-4'>
                                        <div className='col-md-12' style={{
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            lineHeight: "24px",
                                            color: "#5D6F80"
                                        }}>
                                            {i18n.t('lampiran')} 1
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            {this.state.lampiran2_split !== null && this.state.lampiran2 !== null && this.state.lampiran2 !== "" ?
                                                this.state.lampiran2_split[1] === "jpg" || this.state.lampiran2_split[1] === "jpeg" || this.state.lampiran2_split[1] === "png" || this.state.lampiran2_split[1] === "webp" ?
                                                    <a href={"https://services.presensi.co.id/media/frontapp/kunjungan/" + this.state.lampiran2} target={"_blank"}>
                                                        <img src={IMG_HOST + 'frontapp/kunjungan/' + this.state.lampiran2} alt='' className='dk-img-lampiran' />
                                                    </a>
                                                    :
                                                    <a href={"https://services.presensi.co.id/media/frontapp/kunjungan/" + this.state.lampiran2} target={"_blank"}>
                                                        <div style={{
                                                            border: "1px solid #DDDDDD",
                                                            borderRadius: "8px",
                                                            padding: "35px 44px 19px"
                                                        }}>
                                                            <div className='row'>
                                                                <div className='col-md-12 text-center'>
                                                                    <img alt='' src={DocumentIcon} />
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-md-12 text-center' style={{
                                                                    fontSize: "11px",
                                                                    fontWeight: 500,
                                                                    lineHeight: "17px",
                                                                    color: "#5D6F80"
                                                                }}>{this.state.lampiran2}</div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                :
                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <div className='text-center' style={{
                                                            padding: "35px 44px 19px",
                                                            border: "1px solid #DDDDDD",
                                                            borderRadius: "8px",
                                                        }}>
                                                            <img alt='' src={EmptyPng} style={{ height: "100%", width: "100%" }} />
                                                            <div style={{
                                                                fontSize: "11px",
                                                                fontWeight: 500,
                                                                lineHeight: "17px",
                                                                color: "#5D6F80"
                                                            }}>Data Kosong</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='row mb-4'>
                                        <div className='col-md-12' style={{
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            lineHeight: "24px",
                                            color: "#5D6F80"
                                        }}>
                                            {i18n.t('lampiran')} 2
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            {this.state.lampiran3_split !== null && this.state.lampiran3 !== null && this.state.lampiran3 !== "" ?
                                                this.state.lampiran3_split[1] === "jpg" || this.state.lampiran3_split[1] === "jpeg" || this.state.lampiran3_split[1] === "png" || this.state.lampiran3_split[1] === "webp" ?
                                                    <a href={"https://services.presensi.co.id/media/frontapp/kunjungan/" + this.state.lampiran3} target={"_blank"}>
                                                        <img src={IMG_HOST + 'frontapp/kunjungan/' + this.state.lampiran3} alt='' className='dk-img-lampiran' />
                                                    </a>
                                                    :
                                                    <a href={"https://services.presensi.co.id/media/frontapp/kunjungan/" + this.state.lampiran3} target={"_blank"}>
                                                        <div style={{
                                                            border: "1px solid #DDDDDD",
                                                            borderRadius: "8px",
                                                            padding: "35px 44px 19px"
                                                        }}>
                                                            <div className='row'>
                                                                <div className='col-md-12 text-center'>
                                                                    <img alt='' src={DocumentIcon} />
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-md-12 text-center' style={{
                                                                    fontSize: "11px",
                                                                    fontWeight: 500,
                                                                    lineHeight: "17px",
                                                                    color: "#5D6F80"
                                                                }}>{this.state.lampiran3}</div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                :
                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <div className='text-center' style={{
                                                            padding: "35px 44px 19px",
                                                            border: "1px solid #DDDDDD",
                                                            borderRadius: "8px",
                                                        }}>
                                                            <img alt='' src={EmptyPng} style={{ height: "100%", width: "100%" }} />
                                                            <div style={{
                                                                fontSize: "11px",
                                                                fontWeight: 500,
                                                                lineHeight: "17px",
                                                                color: "#5D6F80"
                                                            }}>Data Kosong</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12' style={{
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            lineHeight: "24px",
                                            color: "#5D6F80"
                                        }}>
                                            {i18n.t('lampiran')} 3
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                    </ModalRs>
                    <div className="container-fluid">
                        <div className='row pt-4'>
                            <div className='col-md-12' style={{
                                fontSize: "12px",
                                lineHeight: "16px",
                                color: "#5D6F80"
                            }}>iPresens Admin Panel</div>
                        </div>
                        <div className='row mb-4'>
                            <div className='col-md-12' style={{
                                color: "#143B5E",
                                fontWeight: 700,
                                lineHeight: "36px",
                                fontSize: "24px"
                            }}>{i18n.t('daftarkunjungan')}</div>
                        </div>
                        <div className="card mb-0" style={{
                            borderBottomLeftRadius: "0px",
                            borderBottomRightRadius: "0px"
                        }}>
                            <div className="card-body p-4" style={{
                                border: "1px solid #EAECF0",
                                borderTopLeftRadius: "8px",
                                borderTopRightRadius: "8px"
                            }}>
                                <div className='row' style={{ marginBottom: "16px" }}>
                                    <div className='col-md-3 dk-col-1'>
                                        <div class="input-group btn-srch-kunjungan mb-3">
                                            <span class="input-group-text" id="basic-addon1" style={{
                                                backgroundColor: "#F9F9F9",
                                                borderTopLeftRadius: "8px",
                                                borderBottomLeftRadius: "8px",
                                                borderTopRightRadius: "0px",
                                                border: "1px solid #EFEFEF",
                                                borderBottomRightRadius: "0px",
                                                borderRight: "0px",
                                                paddingLeft: "10px",
                                            }}>
                                                <img alt='' src={SearchIcon} />
                                            </span>
                                            <input name="keyword" type="text" class="form-control dk-filter-name" placeholder={i18n.t('cari')} aria-label="Username" aria-describedby="basic-addon1" value={this.state.keyword}
                                                onChange={this.handleChange}
                                                onKeyUp={(event) => {
                                                    event.preventDefault()
                                                    if (event.which === 13) {

                                                        this.setState({
                                                            page: 0,
                                                            paging: this.state.paging,
                                                            loading: true
                                                        }, () => {
                                                            this.loadData()
                                                        });
                                                    }
                                                }} />
                                        </div>
                                    </div>
                                    <div className='col-md-3 dk-col-2'>
                                        <div className='d-flex flex-nowrap'>
                                            <div className='flex-fill'>
                                                <DateTimeRangeContainer
                                                    ranges={ranges}
                                                    start={this.state.start}
                                                    end={this.state.end}
                                                    local={local}
                                                    applyCallback={this.applyCallback}
                                                >
                                                    <div class="input-group btn-tgl-kunjungan mb-3">
                                                        <input type="text" class="form-control dk-filter-date" placeholder={i18n.t('filtertanggal')} aria-label="Username" aria-describedby="basic-addon1" value={this.state.date} />
                                                        <span class="input-group-text" id="basic-addon1" style={{
                                                            backgroundColor: "#FFFFFF",
                                                            border: "1px solid #EFEFEF",
                                                            borderLeft: "none",
                                                            borderTopLeftRadius: "0px",
                                                            borderBottomLeftRadius: "0px",
                                                            borderTopRightRadius: "8px",
                                                            borderBottomRightRadius: "8px",
                                                        }}>
                                                            <img alt='' src={CalendarIcon} />
                                                        </span>
                                                    </div>
                                                </DateTimeRangeContainer>
                                            </div>
                                            <div>
                                                <button onClick={this.resetFilter} className='ipresens-btn-reset-filter ml-1'><img alt='' src={RefreshIcon} /></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6' style={{ textAlign: "right" }}>
                                        <button onClick={() => this.setState({ modalExport: true })} className='btn btn-exp-kunjungan ipresens-btn-color'><img alt='' src={ExportIcon} /> {i18n.t('exportexcell')}</button>
                                    </div>
                                </div>
                                {!this.state.loading && this.state.data.length === 0 ?
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <Empty description="Data Kosong"
                                                   image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                        </div>
                                    </div>
                                    :
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <Table className="mb-5 table table-striped table-borderless">
                                                <Thead>
                                                    <Tr>
                                                        <Th className="dk-th-style">#</Th>
                                                        <Th className="dk-th-style">{i18n.t('hari')} / {i18n.t('tanggal')}</Th>
                                                        <Th className="dk-th-style">{i18n.t('lokasikunjungan')}</Th>
                                                        <Th className="dk-th-style">{i18n.t('nama')}</Th>
                                                        <Th className="dk-th-style" style={{ minWidth: "140px" }}>{i18n.t('jamkunjungan')}</Th>
                                                        <Th className="dk-th-style">{i18n.t('keterangan')}</Th>
                                                        <Th className="dk-th-style">{i18n.t('lampiran')}</Th>
                                                        <Th className="dk-th-style"></Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {this.state.loading ?
                                                        <Tr>
                                                            <Td colSpan={9}>
                                                                <Spin size="large" tip={i18n.t('loading...')} style={{
                                                                    width: '100%',
                                                                    margin: 'auto',
                                                                    marginBottom: '10px',
                                                                    marginTop: '10px'
                                                                }} />
                                                            </Td>
                                                        </Tr>
                                                        :
                                                        this.state.data.map((item, index) => {
                                                            no += 1
                                                            let status = 'badge'
                                                            return (
                                                                <Tr>
                                                                    <Td scope="row" className={no % 2 === 0 ? "dk-td-style dk-td-first-even" : "dk-td-style"}>{pageNumber(no, this.state.perPage, this.state.currentPage)}</Td>
                                                                    <Td className="dk-td-style">{item.absen_hari} / {moment(item.tanggal_kunjungan).format('ll')}</Td>
                                                                    <Td className="dk-td-style">
                                                                        {item.outlet != null ?
                                                                            <span>{item.outlet.outlet_nama}</span>
                                                                            :
                                                                            <span>-</span>
                                                                        }
                                                                    </Td>
                                                                    <Td className="dk-td-style">
                                                                        <a href={"/karyawan/profil/detail/" + item.karyawan.karyawan_slug} style={{color: "#8c2bee"}}>
                                                                            {item.karyawan != null ?
                                                                                <span>{item.karyawan.karyawan_nama}</span>
                                                                                :
                                                                                <span>-</span>
                                                                            }
                                                                        </a>
                                                                    </Td>
                                                                    <Td className="dk-td-style">{item.jam_kunjungan_absen}</Td>
                                                                    <Td className="dk-td-style">{item.keterangan_kunjungan}</Td>
                                                                    <Td className="dk-td-style">
                                                                        <button onClick={() => {
                                                                            this.setState({
                                                                                modalLampiran: true,
                                                                                lampiran1: item.lampiran_kunjungan_1,
                                                                                lampiran2: item.lampiran_kunjungan_2,
                                                                                lampiran3: item.lampiran_kunjungan_3,
                                                                            }, () => this.setState({
                                                                                lampiran1_split: this.state.lampiran1 !== "" && this.state.lampiran1 !== null && this.state.lampiran1.split("."),
                                                                                lampiran2_split: this.state.lampiran2 !== "" && this.state.lampiran2 !== null && this.state.lampiran2.split("."),
                                                                                lampiran3_split: this.state.lampiran3 !== "" && this.state.lampiran3 !== null && this.state.lampiran3.split(".")
                                                                            }))
                                                                        }} className='dk-btn' style={{ color: "#8c2bee" }}>{i18n.t('lihatlampiran')}</button>
                                                                    </Td>
                                                                    <Td className={no % 2 === 0 ? "dk-td-style dk-td-last-even" : "dk-td-style"}>
                                                                        <Tooltip trigger={'click'} zIndex={100} placement="bottom" color={'#fff'} title={
                                                                            <div className={'p-2'}>
                                                                                <a onClick={() => {
                                                                                    this.setState({
                                                                                        modal: true,
                                                                                        lat: item.latitude_kunjungan,
                                                                                        lng: item.longitude_kunjungan
                                                                                    })
                                                                                }} style={{ marginRight: "50px" }} className={'text-dark'}>{i18n.t('lihatpinlokasi')}</a><br />
                                                                                <a onClick={() => this.setState({
                                                                                    lampiran1: item.lampiran_kunjungan_1,
                                                                                    lampiran2: item.lampiran_kunjungan_2,
                                                                                    lampiran3: item.lampiran_kunjungan_3,
                                                                                }, () => this.setState({
                                                                                    lampiran1_split: this.state.lampiran1 !== "" && this.state.lampiran1 !== null && this.state.lampiran1.split("."),
                                                                                    lampiran2_split: this.state.lampiran2 !== "" && this.state.lampiran2 !== null && this.state.lampiran2.split("."),
                                                                                    lampiran3_split: this.state.lampiran3 !== "" && this.state.lampiran3 !== null && this.state.lampiran3.split("."),
                                                                                    modalDetail: true,
                                                                                    dataDetail: item,
                                                                                }))} className={'text-dark'}>{i18n.t('detail')}</a><br />
                                                                                <a onClick={() => {
                                                                                    this.setState({
                                                                                        idDel: item.kunjungan_id
                                                                                    })
                                                                                    this.wantDelete()
                                                                                }} className={'text-danger'}>{i18n.t('hapus')}</a>
                                                                            </div>
                                                                        }>
                                                                            <button className='dk-btn'>
                                                                                <img alt='' src={ElipsisIcon} />
                                                                            </button>
                                                                        </Tooltip>
                                                                    </Td>
                                                                </Tr>
                                                            )
                                                        })}
                                                </Tbody>
                                            </Table>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='row mx-0 mb-4' style={{
                            backgroundColor: "#F5F5F5",
                            width: "100%",
                            border: "1px solid #EAECF0",
                            borderTop: "none",
                            borderBottomLeftRadius: "8px",
                            borderBottomRightRadius: "8px",
                        }}>
                            {this.state.data.length !== 0 &&
                                <>
                                    <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>menampilkan {this.state.data.length === 0 ? "0" : this.state.data.length} dari {this.state.totalData} data</div>
                                    <div className='col'>
                                        <ReactPaginate
                                            previousLabel={<img src={ArrowLeft} alt='' />}
                                            nextLabel={<img src={ArrowRight} alt='' />}
                                            pageCount={this.state.pageCount}
                                            forcePage={this.state.currentPage}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={this.handlePageClick}
                                            breakClassName={'page-item'}
                                            breakLinkClassName={'page-link custom-page-link'}
                                            containerClassName={'pagination my-2'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link custom-page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link custom-prev-next-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link custom-prev-next-link'}
                                            activeClassName={'active custom-active'}
                                        />
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">Anda tidak punya akses ke halaman ini.</h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user
})

export default connect(mapStateToProps)(GoogleApiWrapper({
    apiKey: APIKEY
})(List_kunjungan));

