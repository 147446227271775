import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Swal from "sweetalert2";
import {Empty, Select, Spin, Tooltip} from 'antd';
import Switch from "react-switch";
import Paging from "../../components/paging";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";
import { Helmet } from "react-helmet";
import { pageNumber } from "../../functions/functionGeneral";
import ModalDefault from '../menu-guide/modal-default';
import i18n from "i18next";
import TimeInput from '../../helper/time_input'
import { clear_auth, set_auth } from "../../reducers/Auth";
import { connect } from "react-redux";
import {Col, Modal as ModalRs, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import RefreshIcon from "../../assets/icons/refresh.svg";
import PlusWhiteIcon from "../../assets/icons/plus-white.svg";
import EmptyDataImage from "../../assets/images/data-kosong.png";
import PencilIcon from "../../assets/icons/pencil.svg";
import TrashIcon from "../../assets/icons/trash.svg";
import ReactPaginate from "react-paginate";
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import ArrowRight from "../../assets/icons/arrow-right.svg";
import ImportIcon from "../../assets/icons/import-icon.svg";
import {BACKEND_API_HOST, DEFAULT_IMG} from "../../helper/host";
import moment from "moment";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg";
import SearchIcon from "../../assets/icons/search.svg";
import './style.css';
import MoreIcon from "../../assets/icons/more.svg";
import Restore from "../../assets/icons/update.svg";
import Trash from "../../assets/icons/trash-custom.svg";

let days = ["senin", "selasa", "rabu", "kamis", "jumat", "sabtu", "minggu"]
export class JamKerja extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            shift: [],
            data_byid: {},
            data_divisi: [],
            hari_jam_kerja: [],
            rows: [],
            rows_length: 1,
            pageCount: 0,
            currentPage: 0,
            totalData: 0,
            totalPerpage: 0,
            perPage: 0,
            paginate: 20,
            pagination: 6,
            id_jam_kerja: '',
            hari_kerja: '',
            dataEdit: [],
            sift_id: '',
            modalAdd: false,
            nama_shift: "",
            toleransi_keterlambatan: 0,
            hari: false,
            senin: false,
            selasa: false,
            rabu: false,
            kamis: false,
            jumat: false,
            sabtu: false,
            minggu: false,
            jam_masuk: "",
            jam_keluar: "",
            progresModal: false,
            status: false,
            reload_check: false,
            filter_shift: '',
            guide: false,
            shift_check: [],
            lokasi_check: [],
            divisi_check: [],
            karyawan_check: [],
            jam_kerja_check: [],
            filter_nama: '',
            disable_add: false,
            disable_edit: false,
            loadingList: true,
            importFile: null,
            modalimport: false,
            modalHistory: false,
            dataHistory: [],
            loadingHistory: false,
            currentPageHistory: 0,
            idRev: '',
            id_History: '',
            modalTrash: false,
            dataTrash: [],
            historyLast: [],
            loadingTrash: false,
            currentPageTrash: 0,
            paginationTrash: 4,
            checkAll: false,
            checkedTrash: [],
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleCheckboxAdd = this.handleCheckboxAdd.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this);
        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.handlePageHistory = this.handlePageHistory.bind(this);
        this.importExcel = this.importExcel.bind(this);
        this.handlePageTrash = this.handlePageTrash.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.handleCheckBoxAllChange = this.handleCheckBoxAllChange.bind(this);
    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, data: [], loading: true }, () => {
            this.loadData();
        });
    }

    handleChange(e) {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    handlePageHistory(dataHistory) {
        let selected = dataHistory.selected; // zero based index
        this.setState({ currentPageHistory: selected, dataHistory: [], loadingHistory: true }, () => {
            this.loadHistory();
        });
    }

    setTimeInput(index, jam_masuk, jam_pulang){
        for (let i = 1; i < 8; i++) {
            if(index == i){
                for (let j = 0; j < days.length; j++) {
                    if(this.state[`${days[j]}_${index}`]){
                        let tempRows = this.state.rows
                        let currentIndex = tempRows.findIndex(item => item.kerja_hari == days[j]);
                        if(currentIndex > -1) {
                            tempRows[currentIndex] = {
                                "kerja_hari": days[j],
                                "jam_masuk": jam_masuk,
                                "jam_keluar": jam_pulang
                            }
                            this.setState({
                                rows: tempRows
                            })
                        }
                    }
                }
            }
        }
    }

    clearState() {
        const initialState = {
            modalAdd: false,
            modalEdit: false,
            nama_shift: "",
            toleransi_keterlambatan: 0,
            jam_masuk: "",
            jam_keluar: "",
            hari_jam_kerja: [],
            rows_length: 1
        };

        const days = ["hari", "senin", "selasa", "rabu", "kamis", "jumat", "sabtu", "minggu"];
        const index = ["_1", "_2", "_3", "_4", "_5", "_6", "_7"];

        days.forEach(day => {
            index.forEach(index => {
                initialState[`${day}${index}`] = false;
            });
        });

        this.setState(initialState);
    }

    clearStateDay() {
        const initialState = {
            jam_masuk: "",
            jam_keluar: ""
        };

        const days = ["hari", "senin", "selasa", "rabu", "kamis", "jumat", "sabtu", "minggu"];
        const index = ["_1", "_2", "_3", "_4", "_5", "_6", "_7"];

        days.forEach(day => {
            index.forEach(index => {
                initialState[`${day}${index}`] = false;
            });
        });

        this.setState(initialState);
    }

    handleButtonClick = () => {
        this.clearState();
    };

    handleButtonClickDay = () => {
        this.clearStateDay();
    };

    handleCheckboxAdd(e) {
        const name = e.target.name
        const value = e.target.checked
        let tempName = name.split("_")
        let realName = tempName[0]
        let index = tempName[1]

        let tempRows
        if(realName === "hari"){
            if (value) {
                tempRows = [];
                for (let i = 0; i < days.length ; i++) {
                    let item = {
                        "kerja_hari": days[i],
                        "jam_masuk": this.state['jam_masuk_'+index] ? this.state['jam_masuk_'+index] : "00:00",
                        "jam_keluar": this.state['jam_keluar_'+index] ? this.state['jam_keluar_'+index] : "00:00",
                    }
                    tempRows.push(item)
                    this.setState({
                        [`${days[i]}_`+index]: value,
                        ['hari_'+index]: value,
                        rows: tempRows,
                        rows_length: 1
                    })
                }
            } else {
                for (let i = 0; i < days.length ; i++) {
                    this.setState({
                        [`${days[i]}_`+index]: false,
                        rows: [],
                        ['hari_'+index]: false
                    })
                }
            }
        }else{
            if(value) {
                tempRows = this.state.rows
                tempRows = tempRows.filter(item => item.kerja_hari != realName);
                for (let i = 1; i < days.length + 1 ; i++) {
                    this.setState({
                        [realName+'_'+i]: false,
                    })
                }

                let item = {
                    "kerja_hari": realName,
                    "jam_masuk": this.state['jam_masuk_'+index] ? this.state['jam_masuk_'+index] : "00:00",
                    "jam_keluar": this.state['jam_keluar_'+index] ? this.state['jam_keluar_'+index] : "00:00",
                }
                tempRows.push(item)
            }else{
                let newRows = this.state.rows;
                tempRows = newRows.filter(item => item.kerja_hari != realName);
            }

            this.setState({
                [name]: value,
                rows: tempRows
            })
        }
    }

    handleOk = e => {
        this.setState({
            modalimport: false
        });
    };

    handleCancel = e => {
        this.setState({
            modalimport: false
        });
    };

    updateStatus() {
        let form = {
            status: this.state.status,
            id: this.state.shift_id
        }
        this.props.request("jamKerja/update_status", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        modalEdit: false
                    })
                    this.loadData()
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t(response.message),
                        icon: 'success',
                        confirmButtonText: 'OK'
                    })
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t(response.message),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            })
    }

    deleteJamKerja() {
        let form = {
            id: this.state.shift_id
        }
        this.props.request("jamKerja/delete", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.loadData()
                    this.loadCheck()
                    this.props.set_auth(this.props.api_token, response.perusahaan);
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t(response.message),
                        icon: 'success',
                        confirmButtonText: 'OK'
                    })
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t(response.message),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            })
    }

    byIdJamKerja() {
        let form = {
            id: this.state.shift_id
        }
        this.props.request("jamKerja/by-id-jamkerja", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        data_byid: response.data,
                        rows_length: response.jam_kerja.length,
                        rows: [],
                    }, () => {
                        let tempRows = this.state.rows;
                        response.jam_kerja.map((item, index) => {
                            index = index + 1;
                            item.hari.map((itemhari) => {
                                this.setState({
                                    [itemhari.toLowerCase()+"_"+index]: true
                                })

                                let rowsItem = {
                                    "kerja_hari": itemhari.toLowerCase(),
                                    "jam_masuk": item.jam_masuk,
                                    "jam_keluar": item.jam_keluar,
                                }
                                tempRows.push(rowsItem)
                            })
                            if(item.hari.length == 7){
                                this.setState({
                                    ["hari_"+index]: true
                                })
                            } else {
                                this.setState({
                                    ["hari_"+index]: false
                                })
                            }
                            this.setState({
                                ["jam_masuk_"+index]: item.jam_masuk,
                                ["jam_keluar_"+index]: item.jam_keluar,
                            })
                        })

                        this.setState({
                            shift_id: this.state.shift_id,
                            nama_shift: response.data.sift_name,
                            toleransi_keterlambatan: response.data.sift_late_tolerance,
                            jam_masuk: response.data.jamkerja ? response.data.jamkerja.jam_masuk.slice(0, 5) : "00:00",
                            jam_keluar: response.data.jamkerja ? response.data.jamkerja.jam_keluar.slice(0, 5) : "00:00",
                            rows: tempRows,
                            modalEdit: true
                        })
                    })
                }
            })
    }

    updateJamKerja() {
        this.setState({
            disable_edit: true
        })
        let form = {
            id: this.state.shift_id,
            nama_shift: this.state.nama_shift,
            toleransi_terlambat: this.state.toleransi_keterlambatan,
            rows: this.state.rows
        }
        this.props.request("jamKerja/update", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        modalEdit: false,
                        nama_shift: "",
                        toleransi_keterlambatan: 0,
                        hari: false,
                        senin: false,
                        selasa: false,
                        rabu: false,
                        kamis: false,
                        jumat: false,
                        sabtu: false,
                        minggu: false,
                        jam_masuk: "",
                        jam_keluar: "",
                        hari_jam_kerja: [],
                        disable_edit: false,
                        rows: [{}],
                    })
                    this.loadData()
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t(response.message),
                        icon: 'success',
                        confirmButtonText: 'OK'
                    })
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t('gagalmengubahdata'),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            })
    }

    addJamKerja() {
        this.setState({
            disable_add: true
        })
        let form = {
            nama_shift: this.state.nama_shift,
            toleransi_terlambat: this.state.toleransi_keterlambatan,
            rows: this.state.rows
        }
        this.props.request("jamKerja/add_row", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        nama_shift: "",
                        toleransi_keterlambatan: 0,
                        hari: false,
                        senin: false,
                        selasa: false,
                        rabu: false,
                        kamis: false,
                        jumat: false,
                        sabtu: false,
                        minggu: false,
                        jam_masuk: "",
                        jam_keluar: "",
                        modalAdd: false,
                        modalEdit: false,
                        hari_jam_kerja: [],
                        disable_add: false,
                        rows: [{}],
                    })
                    this.loadData()
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t(response.message),
                        icon: 'success',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.loadCheck()
                            this.setState({
                                progresModal: true
                            })
                            this.props.set_auth(this.props.api_token, response.perusahaan);
                        } else {
                            this.loadCheck()
                            this.setState({
                                progresModal: true
                            })
                            this.props.set_auth(this.props.api_token, response.perusahaan);
                        }
                    })
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t(response.message),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            })
    }

    loadData() {
        this.setState({
            loadingList: true
        })
        let form = {
            page: this.state.currentPage + 1,
            perusahaan_id: this.props.user.perusahaan_id,
            shift_nama: this.state.filter_nama,
            paging: this.state.paginate
        }
        this.props.request("jamKerja/list", form, 'POST')
            .then((response) => {
                if (response.success) {
                    this.setState({
                        loadingList: false,
                        data: response.data.data,
                        totalData: response.data.total,
                        totalPerpage: response.data.to,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        loading: false,
                    })
                }
            })
    }

    loadCheck() {
        let form = {
            perusahaan_id: this.props.user.perusahaan_id
        }
        this.props.request("check-guide-data", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        shift_check: response.shift,
                        lokasi_check: response.lokasi,
                        divisi_check: response.divisi,
                        karyawan_check: response.karyawan,
                        jam_kerja_check: response.jam_kerja
                    }, () => {
                        if (this.state.shift_check.length === 0 || this.state.lokasi_check.length === 0 || this.state.divisi_check.length === 0 || this.state.karyawan_check.length === 0 || this.state.jam_kerja_check.length === 0) {
                            this.setState({
                                progresModal: true
                            })
                        }
                    })
                }
            })
    }

    exportJamKerja = () => {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'jamKerja/export_template?token=' + AuthKey, "_blank")
    }

    importExcel() {
        this.setState({
            importLoading: true
        })

        var AuthKey = localStorage.getItem('Presensi-Auth-Key')

        let formData = new FormData()
        formData.append('file', this.state.importFile)
        formData.append('token', AuthKey)

        fetch(BACKEND_API_HOST + 'jamKerja/import', {
            method: 'POST',
            headers: {
                'Authorization': 'bearer ' + AuthKey
            },
            body: formData
        })
            .catch((error) => {
                console.error(error);
            })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t(response.message),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                    window.location.reload();
                    this.setState({
                        modalimport: false,
                        loading: true,
                        importFile: null,
                        data: [],
                    }, () => {
                        this.loadData()
                    })
                }
                else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t(response.message),
                        icon: 'warning',
                        showConfirmButton: true
                    })
                    this.setState({
                        modalimport: false,
                    })
                }

                this.setState({ importLoading: false })
            })
    }

    loadHistory(id) {
        this.setState({
            dataHistory: [],
            loadingHistory: true
        }, () => {
            let form = {
                id: id ? id : this.state.sift_id,
                paging: this.state.pagination,
                page: this.state.currentPageHistory + 1,
            };

            this.props.request("jamKerja/history", form, 'POST')
                .then(response => {
                    this.setState({
                        loadingHistory: false
                    })
                    if (response.succes) {
                        this.setState({
                            dataHistory: response.data.data,
                            historyLast: response.history,
                            pageCountHistory: response.data.last_page,
                            totalDataHistory: response.data.total,
                            totalPerpageHistory: response.data.to,
                            perPageHistory: response.data.per_page,
                        });
                    } else {
                        this.setState({
                            modalHistory: false,
                            sift_id: ''
                        });
                    }
                });
        })
    }

    handlePageTrash(dataTrash) {
        let selected = dataTrash.selected;
        this.setState({ currentPageTrash: selected, dataTrash: [], loadingTrash: true }, () => {
            this.loadTrash();
        });
    }

    handleCheckBoxChange(id) {
        this.setState((prevState) => {
            let updatedcheckedTrash = [...prevState.checkedTrash];
            if (updatedcheckedTrash.includes(id)) {
                updatedcheckedTrash = updatedcheckedTrash.filter(item => item !== id);
            } else {
                updatedcheckedTrash.push(id);
            }

            if (updatedcheckedTrash.length !== this.state.totalDataTrashJam) {
                this.setState({
                    checkAll: false
                })
            } else if (updatedcheckedTrash.length === this.state.totalDataTrashJam) {
                this.setState({
                    checkAll: true
                })
            }

            return {
                checkedTrash: updatedcheckedTrash,
            };
        });
    }

    handleCheckBoxAllChange() {
        this.setState(prevState => ({
            checkAll: !prevState.checkAll
        }), () => {
            this.state.checkAll
                ? this.setState({
                    checkedTrash: this.state.dataTrash.map(item => item.sift_id)
                })
                : this.setState({
                    checkedTrash: []
                })
        })
    }

    revertJam() {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('revertdata'),
            icon: 'warning',
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        id: this.state.idRev,
                        historyId: this.state.id_History
                    }
                    this.setState({
                        data: [],
                        loading: true
                    })
                    this.props.request("jamKerja/revert", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                                this.setState({
                                    modalHistory: false,
                                    currentPageHistory: 0
                                })
                            } else {
                                Swal.fire({
                                    title: i18n.t('gagal'),
                                    text: i18n.t(response.message),
                                    icon: 'warning',
                                    confirmButtonText: 'OK'
                                })
                                this.loadData()
                                this.setState({
                                    loading: false
                                })
                            }
                        });
                }
            })
    }

    loadTrash() {
        this.setState({
            dataTrash: [],
            loadingTrash: true
        }, () => {
            let form = {
                paginate: this.state.paginationTrash,
                page: this.state.currentPageTrash + 1,
            };

            this.props.request("jamKerja/trash", form, 'POST')
                .then(response => {
                    this.setState({
                        loadingTrash: false
                    })
                    if (response.success) {
                        this.setState({
                            dataTrash: response.data.data,
                            pageCountTrash: response.data.last_page,
                            totalDataTrashJam: response.data.total,
                            totalPerpageTrash: response.data.to,
                            perPageTrash: response.data.per_page,
                        });
                    } else {
                        this.setState({
                            modalTrash: false,
                            sift_id: ''
                        });
                    }
                });
        })
    }

    pemulihanJam = () => {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: this.props.user.perusahaan.perusahaan_type === "sekolah" ? i18n.t('pulihkanjambelajar') : i18n.t('pulihkanjamkerja'),
            icon: 'warning',
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed){
                    this.setState({
                        data: [],
                        loading: true
                    })
                    let form = {
                        id: this.state.checkedTrash
                    }
                    this.props.request("jamKerja/restore", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: i18n.t(response.message),
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.setState({
                                    checkedTrash: [],
                                    checkAll: false,
                                    modalTrash: false,
                                    restoreJam: false
                                })
                            }
                            this.loadData()
                        });
                }
            })
    }

    trashJam = () => {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: this.props.user.perusahaan.perusahaan_type === "sekolah" ? i18n.t('hapusjambelajarpermanen') : i18n.t('hapusjamkerjapermanen'),
            icon: 'warning',
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed){
                    this.setState({
                        data: [],
                        loading: true
                    })
                    let form = {
                        sift_ids: this.state.checkedTrash
                    }
                    this.props.request("jamKerja/delete-permanent", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: i18n.t(response.message),
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.setState({
                                    checkedTrash: [],
                                    checkAll: false,
                                    modalTrash: false,
                                    permanentDelete: false
                                })
                            }
                            this.loadData()
                        });
                }
            })
    }

    componentDidMount() {
        this.loadData()
        this.loadCheck()
    }

    render() {
        let no = 0;
        const steps = [
            {
                selector: '.tambah-jamker',
                content: 'Anda dapat membuat Jam Kerja disini',
            },
            {
                selector: '.daftar-jamker',
                content: 'Daftar Jam Kerja anda dapat dilihat disini',
            },
        ];
        const style = {
            card_header: {
                backgroundColor: "#FAFDFF",
                color: "#41146c",
                padding: "12px 24px",
                fontSize: "24px",
                lineHeight: "36px",
                fontWeight: 700
            },
            button_refresh: {
                padding: "8px",
                border: "1px solid #DDDDDD",
                borderRadius: "8px",
                backgroundColor: "#FFFFFF"
            },
            filter_nama: {
                backgroundColor: "#F9F9F9",
                padding: "12px 10px",
                color: "#5D6F80",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "16px"
            },
            th_style: {
                borderBottom: "0px",
                padding: "16px 12px",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "24px",
                color: "#252B2B"
            },
            td_style: {
                padding: "16px 12px",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "24px",
                color: "#252B2B"
            }
        }
        const style_add = {
            modal_header: {
                backgroundColor: "#FAFDFF",
                padding: "12px",
            },
            title_header: {
                color: "#41146c",
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "36px"
            },
            label_input: {
                color: "#41146c",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "24px"
            },
            input_group_text: {
                backgroundColor: "#EFEFEF",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 500,
                padding: "12px"
            },
            input: {
                backgroundColor: "#FFFFFF",
                border: "1px solid #DDDDDD",
                borderRadius: "4px",
                padding: "12px",
                fontSize: "12px",
                lineHeight: "18px",
                fontWeight: 400
            },
            select: {
                backgroundColor: "#FFFFFF",
                border: "1px solid #DDDDDD",
                borderRadius: "4px",
                padding: "12px",
                minHeight: "44px",
                fontSize: "12px",
                lineHeight: "18px",
                fontWeight: 400
            },
            card_header: {
                backgroundColor: "#FAFDFF",
                padding: "10px 16px",
                color: "#41146c",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "24px"
            },
            card_body: {
                backgroundColor: "#F9F9F9",
                padding: "12px 16px"
            },
            column_card_body: {
                backgroundColor: "#F9F9F9",
                textAlign: "center"
            },
            hari: {
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "24px",
                color: "#252B2",
                textAlign: "left",
                margin: "0px"
            },
            time_input: {
                padding: "12px",
                fontSize: "14px",
                color: "#252B2B",
                backgroundColor: "#FFFFFF",
                lineHeight: "16px",
                width: "88px"
            }
        }
        moment.locale(i18n.language);
        const { Option } = Select;
        const tipe = this.props.user.perusahaan.perusahaan_type === "sekolah";
        const levelPageLimited = this.props.user.user_level.level_page_limited;

        if (!levelPageLimited ||
            (tipe
                ? levelPageLimited.search("Jam Belajar") >= 0
                : levelPageLimited.search("Jam Kerja") >= 0)) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{tipe? i18n.t("daftarjambelajar") : i18n.t("daftarjamkerja")} - iPresens</title>
                        <meta name="description" content={i18n.t("daftarjamkerja")} />
                    </Helmet>
                    <Breadcrumb parent={tipe? i18n.t("manajemenjambelajar") : i18n.t("manajemenjamkerja")} title={tipe? i18n.t("daftarjambelajar") : i18n.t("daftarjamkerja")} setGuide={() => {
                        this.setState({ guide: true })
                    }} />
                    <Tour steps={steps} isOpen={this.state.guide === true} onAfterOpen={this.disableBody}
                          onBeforeClose={this.enableBody} onRequestClose={() => this.setState({ guide: false })} />
                    <ModalDefault
                        jam_kerja={this.state.jam_kerja_check}
                        shift={this.state.shift_check}
                        lokasi={this.state.lokasi_check}
                        divisi={this.state.divisi_check}
                        karyawan={this.state.karyawan_check}
                        confirm_modal_default={this.state.confirm_modal_default}
                        setState={(name, val) => this.setState(name, val)}
                        history={this.props.history}
                    />
                    <Modal isOpen={this.state.modalAdd} style={{ maxWidth: "1000px" }} size='lg' toggle={() => this.setState({
                        modalAdd: false, nama_shift: "",
                        toleransi_keterlambatan: 0,
                        hari: false,
                        senin: false,
                        selasa: false,
                        rabu: false,
                        kamis: false,
                        jumat: false,
                        sabtu: false,
                        minggu: false,
                        jam_masuk: "",
                        jam_keluar: "",
                        hari_jam_kerja: [],
                        rows_length: 1
                    })}>
                        <ModalHeader style={style_add.modal_header} onClick={this.handleButtonClick} toggle={() => this.setState({
                            modalAdd: false,
                            nama_shift: "",
                            toleransi_keterlambatan: 0,
                            hari_1: false,
                            jam_masuk: "",
                            jam_keluar: "",
                            hari_jam_kerja: [],
                            rows_length: 1
                        })}><div style={style_add.title_header}>{tipe? i18n.t("tambahjambelajar") : i18n.t('tambahjamkerja')}</div></ModalHeader>
                        <ModalBody className='p-4'>
                            <div className='row mb-2'>
                                <div className='col-md-3'>
                                    <label className='form-label' style={style_add.label_input}>{tipe? i18n.t("namajambelajar") : i18n.t('namajamkerja')}</label>
                                    <input className='form-control' type='text' name='nama_shift' onChange={this.handleChange} value={this.state.nama_shift} style={style_add.input} placeholder={tipe? i18n.t("ketikjambelajar") : i18n.t('ketikjamkerja')} />
                                </div>
                                <div className='col-md-5'>
                                    <label className='form-label' style={style_add.label_input}>{i18n.t('toleransiketerlambatan')}</label>
                                    <div className='input-group form-toleran-jamker'>
                                        <input className='form-control' type='number' onChange={this.handleChange} name='toleransi_keterlambatan' style={style_add.input} placeholder={i18n.t('ketiktoleransi')} />
                                        <div className='input-group-append'>
                                            <span className='input-group-text' style={style_add.input_group_text}>{i18n.t('menit')}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'></div>
                            </div>
                            {Array.from({ length: this.state.rows_length }, (item, index) => {
                                index = index + 1;
                                return (
                                    <div className='row pl-2 mt-4'>
                                        <div className='col-md-5 px-2'>
                                            <div className='card border'>
                                                <div className='card-header p-0 border' style={{ backgroundColor: "#FAFDFF" }}>
                                                    <div className='row m-0'>
                                                        <div className='col-md-12 d-flex flex-nowrap' style={style_add.card_header}>
                                                            <input type='checkbox' checked={this.state['hari_'+index]} name={'hari_'+[index]} id={'hari_'+[index]} onClick={this.handleButtonClickDay} onChange={this.handleCheckboxAdd} />
                                                            <label htmlFor={'hari_'+[index]} style={{
                                                                padding: "0px 8px",
                                                                margin: "0px",
                                                                fontWeight: 500,
                                                                fontSize: "16px",
                                                                lineHeight: "24px",
                                                                color: "#59145e"
                                                            }}>{i18n.t('hari')}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='card-body' style={style_add.card_body}>
                                                    <div className='row'>
                                                        <div className='col-md-12' style={style_add.column_card_body}>
                                                            <div className='row d-flex flex-nowrap'>
                                                                <div className='col-md-6 d-flex flex-column flex-nowrap'>
                                                                    <div className='d-flex justify-content-start py-1'>
                                                                        <input type='checkbox' checked={this.state['senin_'+index] === true} value={this.state['senin_'+index]} onChange={this.handleCheckboxAdd} name={'senin_'+[index]} id={'senin_'+[index]} />
                                                                        <label className='px-1' htmlFor={'senin_'+[index]} style={style_add.hari}>
                                                                            {i18n.t('senin')}
                                                                        </label>
                                                                    </div>
                                                                    <div className='d-flex justify-content-start py-1'>
                                                                        <input type='checkbox' checked={this.state['selasa_'+index] === true} name={'selasa_' + [index]} id={'selasa_'+[index]} value={this.state['selasa_'+index]} onChange={this.handleCheckboxAdd} />
                                                                        <label className='px-1' htmlFor={'selasa_'+[index]} style={style_add.hari}>
                                                                            {i18n.t('selasa')}
                                                                        </label>
                                                                    </div>
                                                                    <div className='d-flex justify-content-start py-1'>
                                                                        <input type='checkbox' checked={this.state['rabu_'+index] === true} name={'rabu_'+[index]} id={'rabu_'+[index]} value={this.state['rabu_'+index]} onChange={this.handleCheckboxAdd} />
                                                                        <label className='px-1' htmlFor={'rabu_'+[index]} style={style_add.hari}>
                                                                            {i18n.t('rabu')}
                                                                        </label>
                                                                    </div>
                                                                    <div className='d-flex justify-content-start py-1'>
                                                                        <input type='checkbox' checked={this.state['kamis_'+index] === true} name={'kamis_'+[index]} id={'kamis_'+[index]} value={this.state['kamis_'+index]} onChange={this.handleCheckboxAdd} />
                                                                        <label className='px-1' htmlFor={'kamis_'+[index]} style={style_add.hari}>
                                                                            {i18n.t('kamis')}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-6 d-flex flex-column flex-nowrap'>
                                                                    <div className='d-flex justify-content-start py-1'>
                                                                        <input type='checkbox' checked={this.state['jumat_'+index] === true} name={'jumat_'+[index]} id={'jumat_'+[index]} value={this.state['jumat_'+index]} onChange={this.handleCheckboxAdd} />
                                                                        <label className='px-1' htmlFor={'jumat_'+[index]} style={style_add.hari}>
                                                                            {i18n.t('jumat')}
                                                                        </label>
                                                                    </div>
                                                                    <div className='d-flex justify-content-start py-1'>
                                                                        <input type='checkbox' checked={this.state['sabtu_'+index] === true} name={'sabtu_'+[index]} id={'sabtu_'+[index]} value={this.state['sabtu_'+index]} onChange={this.handleCheckboxAdd} />
                                                                        <label className='px-1' htmlFor={'sabtu_'+[index]} style={style_add.hari}>
                                                                            {i18n.t('sabtu')}
                                                                        </label>
                                                                    </div>
                                                                    <div className='d-flex justify-content-start py-1'>
                                                                        <input type='checkbox' checked={this.state['minggu_'+index] === true} name={'minggu_'+[index]} id={'minggu_'+[index]} value={this.state['minggu_'+index]} onChange={this.handleCheckboxAdd} />
                                                                        <label className='px-1' htmlFor={'minggu_'+[index]} style={style_add.hari}>
                                                                            {i18n.t('minggu')}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-5 px-2'>
                                            <div className='card border' style={{ minHeight: "200px" }}>
                                                <div className='card-header p-0 border' style={{ backgroundColor: "#FAFDFF" }}>
                                                    <div className='row d-flex flex-nowrap m-0 px-3'>
                                                        <div className='col-md-6 px-0' style={style_add.card_header}>{i18n.t('jammasuk')}</div>
                                                        <div className='col-md-6 px-0' style={style_add.card_header}>{i18n.t('jamkeluar')}</div>
                                                    </div>
                                                </div>
                                                <div className='card-body' style={style_add.card_body}>
                                                    <div className='row'>
                                                        <div className='col-md-12 pl-3' style={style_add.column_card_body}>
                                                            <div className='row d-flex flex-nowrap'>
                                                                <div className='col-md-6' style={style_add.column_card_body}>
                                                                    <TimeInput
                                                                        style={style_add.time_input}
                                                                        initTime={''}
                                                                        name={'jam_masuk_' + index}
                                                                        className='s-input -time form-control'

                                                                        onTimeChange={(event) => {
                                                                            if (event.length === 5) {
                                                                                this.setState({
                                                                                    ['jam_masuk_' + index]: event + ":00"
                                                                                }, () => {this.setTimeInput(index, this.state['jam_masuk_' + index], this.state['jam_keluar_' + index])})
                                                                            } else if (event.length === 0) {
                                                                                this.setState({
                                                                                    ['jam_masuk_' + index]: event
                                                                                }, () => {this.setTimeInput(index, this.state['jam_masuk_' + index], this.state['jam_keluar_' + index])})
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className='col-md-6 pl-0' style={style_add.column_card_body}>
                                                                    <TimeInput
                                                                        initTime={''}
                                                                        name={'jam_keluar_' + index}
                                                                        style={style_add.time_input}
                                                                        className='s-input -time form-control'

                                                                        onTimeChange={(event) => {
                                                                            if (event.length === 5) {
                                                                                this.setState({
                                                                                    ['jam_keluar_' + index]: event + ":00"
                                                                                }, () => {this.setTimeInput(index, this.state['jam_masuk_' + index], this.state['jam_keluar_' + index])})
                                                                            } else if (event.length === 0) {
                                                                                this.setState({
                                                                                    ['jam_keluar_' + index]: event
                                                                                }, () => {this.setTimeInput(index, this.state['jam_masuk_' + index], this.state['jam_keluar_' + index])})
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            {!this.state.hari_1 && this.state.rows_length <= 6 &&
                                <div className="col-md-0 mr-2 mt-0 text-capitalize pull-left button-w-full">
                                    <button onClick={() => this.setState({ rows_length: this.state.rows_length + 1 })} className='btn ipresens-btn-color mt-0 text-capitalize pull-left button-w-full'><img className='mr-2' alt='' src={PlusWhiteIcon} />{i18n.t('tambahhari')}</button>
                                </div>
                            }
                            {this.state.rows_length !== 1 &&
                                <div>
                                    <button
                                        onClick={() => this.setState({
                                            rows_length: this.state.rows_length - 1
                                        })}
                                        className='btn ipresens-delete-button'
                                    >
                                        <img src={TrashIcon} alt='' />
                                    </button>
                                </div>
                            }
                        </ModalBody>
                        <ModalFooter style={{
                            backgroundColor: "#F9F9F9",
                            borderBottomLeftRadius: "8px",
                            borderBottomRightRadius: "8px"
                        }} className='px-4 py-3'>
                            <button className='btn ipresens-btn-cancel text-capitalize' onClick={this.handleButtonClick}>{i18n.t('batalkan')}</button>
                            <button className='btn ipresens-btn-color text-capitalize' disabled={this.state.loading} onClick={(event) => this.addJamKerja(event) || (this.clearState)} style={{ padding: "8px 14px", fontWeight: 500, fontSize: "14px", lineHeight: "24px" }}>{tipe? i18n.t("tambahjambelajar") : i18n.t('tambahjamkerja')}</button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={this.state.modalEdit} style={{ maxWidth: "1000px" }} size='lg' toggle={() => this.setState({
                        modalEdit: false,
                        nama_shift: "",
                        toleransi_keterlambatan: 0,
                        hari: false,
                        senin: false,
                        selasa: false,
                        rabu: false,
                        kamis: false,
                        jumat: false,
                        sabtu: false,
                        minggu: false,
                        jam_masuk: "",
                        jam_keluar: "",
                        hari_jam_kerja: [],
                        rows_length: []
                    }, this.handleButtonClick)}>
                        <ModalHeader style={style_add.modal_header} onClick={this.handleButtonClick} toggle={() => this.setState({
                            modalEdit: false,
                            nama_shift: "",
                            toleransi_keterlambatan: 0,
                            hari_1: false,
                            jam_masuk: "",
                            jam_keluar: "",
                            hari_jam_kerja: [],
                            rows_length: 1
                        })}><div style={style_add.title_header}>{tipe? i18n.t("editjambelajar") : i18n.t('editjamkerja')}</div></ModalHeader>
                        <ModalBody className='p-4'>
                            <div className='row mb-2'>
                                <div className='col-md-3'>
                                    <label className='form-label' style={style_add.label_input}>{tipe? i18n.t("namajambelajar") : i18n.t('namajamkerja')}</label>
                                    <input className='form-control' type='text' name='nama_shift' onChange={this.handleChange} value={this.state.nama_shift} style={style_add.input} placeholder={tipe? i18n.t("ketikjambelajar") : i18n.t('ketikjamkerja')} />
                                </div>
                                <div className='col-md-5'>
                                    <label className='form-label' style={style_add.label_input}>{i18n.t('toleransiketerlambatan')}</label>
                                    <div className='input-group form-toleran-jamker'>
                                        <input className='form-control' type='number' value={this.state.toleransi_keterlambatan} onChange={this.handleChange} name='toleransi_keterlambatan' style={style_add.input} placeholder={i18n.t('ketiktoleransi')} />
                                        <div className='input-group-append'>
                                            <span className='input-group-text' style={style_add.input_group_text}>{i18n.t('menit')}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'></div>
                            </div>
                            {Array.from({ length: this.state.rows_length }, (item, index) => {
                                index = index + 1;
                                return (
                                    <div className='row pl-2 mt-4'>
                                        <div className='col-md-5 px-2'>
                                            <div className='card border'>
                                                <div className='card-header p-0 border' style={{ backgroundColor: "#FAFDFF" }}>
                                                    <div className='row m-0'>
                                                        <div className='col-md-12 d-flex flex-nowrap' style={style_add.card_header}>
                                                            <input type='checkbox' checked={this.state['hari_'+index]} name={'hari_'+[index]} id={'hari_'+[index]} onClick={this.handleButtonClickDay} onChange={this.handleCheckboxAdd} />
                                                            <label htmlFor={'hari_'+[index]} style={{
                                                                padding: "0px 8px",
                                                                margin: "0px",
                                                                fontWeight: 500,
                                                                fontSize: "16px",
                                                                lineHeight: "24px",
                                                                color: "#59145e"
                                                            }}>{i18n.t('hari')}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='card-body' style={style_add.card_body}>
                                                    <div className='row'>
                                                        <div className='col-md-12' style={style_add.column_card_body}>
                                                            <div className='row d-flex flex-nowrap'>
                                                                <div className='col-md-6 d-flex flex-column flex-nowrap'>
                                                                    <div className='d-flex justify-content-start py-1'>
                                                                        <input type='checkbox' checked={this.state['senin_'+ index] === true} value={this.state['senin_'+index]} onChange={this.handleCheckboxAdd} name={'senin_'+[index]} id={'senin_'+[index]} />
                                                                        <label className='px-1' htmlFor={'senin_'+[index]} style={style_add.hari}>
                                                                            {i18n.t('senin')}
                                                                        </label>
                                                                    </div>
                                                                    <div className='d-flex justify-content-start py-1'>
                                                                        <input type='checkbox' checked={this.state['selasa_'+ index] === true} name={'selasa_'+[index]} id={'selasa_'+[index]} value={this.state['selasa_'+ index]} onChange={this.handleCheckboxAdd} />
                                                                        <label className='px-1' htmlFor={'selasa_'+[index]} style={style_add.hari}>
                                                                            {i18n.t('selasa')}
                                                                        </label>
                                                                    </div>
                                                                    <div className='d-flex justify-content-start py-1'>
                                                                        <input type='checkbox' checked={this.state['rabu_'+ index] === true} name={'rabu_'+[index]} id={'rabu_'+[index]} value={this.state['rabu_'+ index]} onChange={this.handleCheckboxAdd} />
                                                                        <label className='px-1' htmlFor={'rabu_'+[index]} style={style_add.hari}>
                                                                            {i18n.t('rabu')}
                                                                        </label>
                                                                    </div>
                                                                    <div className='d-flex justify-content-start py-1'>
                                                                        <input type='checkbox' checked={this.state['kamis_'+index] === true} name={'kamis_'+[index]} id={'kamis_'+[index]} value={this.state['kamis_'+index]} onChange={this.handleCheckboxAdd} />
                                                                        <label className='px-1' htmlFor={'kamis_'+[index]} style={style_add.hari}>
                                                                            {i18n.t('kamis')}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-6 d-flex flex-column flex-nowrap'>
                                                                    <div className='d-flex justify-content-start py-1'>
                                                                        <input type='checkbox' checked={this.state['jumat_'+index] === true} name={'jumat_'+[index]} id={'jumat_'+[index]} value={this.state['jumat_'+index]} onChange={this.handleCheckboxAdd} />
                                                                        <label className='px-1' htmlFor={'jumat_'+[index]} style={style_add.hari}>
                                                                            {i18n.t('jumat')}
                                                                        </label>
                                                                    </div>
                                                                    <div className='d-flex justify-content-start py-1'>
                                                                        <input type='checkbox' checked={this.state['sabtu_'+index] === true} name={'sabtu_'+[index]} id={'sabtu_'+[index]} value={this.state['sabtu_'+index]} onChange={this.handleCheckboxAdd} />
                                                                        <label className='px-1' htmlFor={'sabtu_'+[index]} style={style_add.hari}>
                                                                            {i18n.t('sabtu')}
                                                                        </label>
                                                                    </div>
                                                                    <div className='d-flex justify-content-start py-1'>
                                                                        <input type='checkbox' checked={this.state['minggu_'+index] === true} name={'minggu_'+[index]} id={'minggu_'+[index]} value={this.state['minggu_'+index]} onChange={this.handleCheckboxAdd} />
                                                                        <label className='px-1' htmlFor={'minggu_'+[index]} style={style_add.hari}>
                                                                            {i18n.t('minggu')}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-5 px-2'>
                                            <div className='card border' style={{ minHeight: "200px" }}>
                                                <div className='card-header p-0 border' style={{ backgroundColor: "#FAFDFF" }}>
                                                    <div className='row d-flex flex-nowrap m-0 px-3'>
                                                        <div className='col-md-6 px-0' style={style_add.card_header}>{i18n.t('jammasuk')}</div>
                                                        <div className='col-md-6 px-0' style={style_add.card_header}>{i18n.t('jamkeluar')}</div>
                                                    </div>
                                                </div>
                                                <div className='card-body' style={style_add.card_body}>
                                                    <div className='row'>
                                                        <div className='col-md-12 pl-3' style={style_add.column_card_body}>
                                                            <div className='row d-flex flex-nowrap'>
                                                                <div className='col-md-6' style={style_add.column_card_body}>
                                                                    <TimeInput
                                                                        style={style_add.time_input}
                                                                        name={'jam_masuk_' + index}
                                                                        className='s-input -time form-control'
                                                                        initTime={this.state["jam_masuk_" + index]}

                                                                        onTimeChange={(event) => {
                                                                            if (event.length === 5) {
                                                                                this.setState({
                                                                                    ['jam_masuk_' + index]: event + ":00"
                                                                                }, () => {this.setTimeInput(index, this.state['jam_masuk_' + index], this.state['jam_keluar_' + index])})
                                                                            } else if (event.length === 0) {
                                                                                this.setState({
                                                                                    ['jam_masuk_' + index]: event
                                                                                }, () => {this.setTimeInput(index, this.state['jam_masuk_' + index], this.state['jam_keluar_' + index])})
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className='col-md-6 pl-0' style={style_add.column_card_body}>
                                                                    <TimeInput
                                                                        name={'jam_keluar_' + index}
                                                                        style={style_add.time_input}
                                                                        className='s-input -time form-control'
                                                                        initTime={this.state["jam_keluar_" + index]}

                                                                        onTimeChange={(event) => {
                                                                            if (event.length === 5) {
                                                                                this.setState({
                                                                                    ['jam_keluar_' + index]: event + ":00"
                                                                                }, () => {this.setTimeInput(index, this.state['jam_masuk_' + index], this.state['jam_keluar_' + index])})
                                                                            } else if (event.length === 0) {
                                                                                this.setState({
                                                                                    ['jam_keluar_' + index]: event
                                                                                }, () => {this.setTimeInput(index, this.state['jam_masuk_' + index], this.state['jam_keluar_' + index])})
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            {!this.state.hari_1 && this.state.rows_length <= 6 &&
                                <div className="col-md-0 mr-2 mt-0 text-capitalize pull-left button-w-full">
                                    <button onClick={() => this.setState({ rows_length: this.state.rows_length + 1 })} className='btn ipresens-btn-color mt-0 text-capitalize pull-left button-w-full'><img className='mr-2' alt='' src={PlusWhiteIcon} />{i18n.t('tambahhari')}</button>
                                </div>
                            }
                            {this.state.rows_length !== 1 &&
                                <div>
                                    <button
                                        onClick={() => this.setState({
                                            rows_length: this.state.rows_length - 1
                                        })}
                                        className='btn ipresens-delete-button'
                                    >
                                        <img src={TrashIcon} alt='' />
                                    </button>
                                </div>
                            }
                        </ModalBody>
                        <ModalFooter style={{
                            backgroundColor: "#F9F9F9",
                            borderBottomLeftRadius: "8px",
                            borderBottomRightRadius: "8px"
                        }} className='px-4 py-3'>
                            <button className='btn ipresens-btn-cancel text-capitalize' onClick={this.handleButtonClick}>{i18n.t('batalkan')}</button>
                            <button className='btn ipresens-btn-color text-capitalize' disabled={this.state.loading} onClick={() => this.updateJamKerja()} style={{ padding: "8px 14px", fontWeight: 500, fontSize: "14px", lineHeight: "24px" }}>{i18n.t('simpan')}</button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={this.state.modalimport}>
                        <ModalHeader onClick={this.handleCancel} toggle={() => this.setState({importFile: null})}>
                            <div>{tipe? i18n.t("importjambelajar") :i18n.t('importjamkerja')}</div>
                        </ModalHeader>
                        <ModalBody style={{ padding: "20px" }}>
                            <div className='row mb-2'>
                                <div className="col">
                                    <a style={{color: "#5D6F80", cursor: "default"}}>{i18n.t('andabisamengunduhtemplate')}</a><br/>
                                    <a onClick={this.exportJamKerja} style={{color: "#057CE4"}}>{i18n.t('unduhtemplatedisini')}</a>
                                    <div style={{
                                        position: 'relative',
                                        width: '100%',
                                        backgroundColor: '#F9F9F9',
                                        padding: 16,
                                        borderRadius: 6,
                                        marginTop: 8,
                                        border: "1px dashed #DDDDDD"
                                    }}>
                                        <input type='file'
                                               accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                               onChange={(event) => {
                                                   this.setState({
                                                       importFile: event.target.files[0],
                                                   })
                                               }}/><br/>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            {[
                                this.state.importLoading ?
                                    <button className={'btn ipresens-btn-color'} style={{textTransform: "none", width: "100%"}}
                                            disabled={true}><i className="fa fa-refresh fa-spin"></i> {i18n.t('prosesupload')}</button>
                                    :
                                    <button className={this.state.importFile === null ? 'btn bg-secondary-custom' : 'btn ipresens-btn-color'} style={{textTransform: "none", width: "100%"}} disabled={this.state.importFile === null} onClick={this.importExcel}>Import Data</button>
                            ]}
                        </ModalFooter>
                    </Modal>
                    <ModalRs size="lg" style={{ maxWidth: "1000px" }} isOpen={this.state.modalHistory} toggle={() => this.setState({ modalHistory: false, currentPageHistory: 0 })}>
                        <ModalHeader style={{ backgroundColor: "#FAFDFF" }} toggle={() => this.setState({ modalHistory: false, currentPageHistory: 0 })}>
                            <div className='modal-header-reactstrap'>{i18n.t('riwayatperubahan')}</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="list-group">
                                <div>
                                    {this.state.loadingHistory && (
                                        <div style={{ textAlign: 'center', margin: '10px auto' }}>
                                            <Spin
                                                size="large"
                                                tip="Loading..."
                                                style={{
                                                    width: '100%',
                                                    margin: 'auto',
                                                }}
                                            />
                                        </div>
                                    )}

                                    {this.state.dataHistory.length !== 0 &&(
                                        <div>
                                            <Row>
                                                <Col xs={0} md={0} className="text-left" style={{ marginLeft: '20px' }}>
                                                    <h4>User</h4>
                                                </Col>
                                                <Col xs={0} md={0} className="text-left" style={{ marginRight: '20px' }}>
                                                    <h4>{i18n.t('aktivitas')}</h4>
                                                </Col>
                                            </Row>

                                            {this.state.dataHistory.map((item, index) => {
                                                return(
                                                    <div key={index} style={{ marginBottom: '20px' }}>
                                                        <Row>
                                                            <Col xs={12} md={6} className="text-left">
                                                                <div style={{ display: 'flex', alignItems: 'center', paddingTop: '16px', paddingBottom: '16', paddingRight: '12px', paddingLeft: '12px' }}>
                                                                    <div style={{ marginRight: '16px' }}>
                                                                        <img
                                                                            src={DEFAULT_IMG}
                                                                            alt=""
                                                                            style={{
                                                                                width: '48x',
                                                                                marginBottom: '25px',
                                                                                height: '48px'
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <h6
                                                                            className="mb-0 font-13"
                                                                            style={{
                                                                                fontWeight: 500,
                                                                                fontSize: '16px',
                                                                                lineHeight: '24px',
                                                                            }}
                                                                        >
                                                                            {item.p_user_name} | {item.level_name}
                                                                        </h6>
                                                                        <p className="m-0 font-12">
                                                                            <span className="col-green font-weight-bold">
                                                                                {moment(item.created_at).format('lll')}
                                                                            </span>
                                                                        </p>
                                                                        <div style={{ marginTop: 12 }}>
                                                                            {item.history_status === "created" && (
                                                                                <div className="row px-3">
                                                                                    <div
                                                                                        className="mr-2"
                                                                                        style={{
                                                                                            border: '1px solid #000',
                                                                                            borderRadius: '25px',
                                                                                            width: '50%',
                                                                                            color: '#8c2bee',
                                                                                            borderColor: '#8c2bee',
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            className="d-flex justify-content-center"
                                                                                            style={{
                                                                                                paddingTop: '6px',
                                                                                                paddingBottom: '6px'
                                                                                            }}>
                                                                                            {i18n.t('dibuat')}
                                                                                        </div>
                                                                                    </div>
                                                                                    {this.state.historyLast.length !== this.state.dataHistory.length &&
                                                                                        <div
                                                                                            className="col-md-5 btn ipresens-btn-revert"
                                                                                            onClick={() => {
                                                                                                this.setState({
                                                                                                    idRev: item.id_jam_kerja,
                                                                                                    id_History: item.history_id
                                                                                                })
                                                                                                this.revertJam()
                                                                                            }}
                                                                                            style={{
                                                                                                border: '1px solid #000',
                                                                                                borderRadius: '25px',
                                                                                                width: '50%',
                                                                                                color: '#8c2bee',
                                                                                                borderColor: '#8c2bee',
                                                                                                lineHeight: '0px',
                                                                                                textTransform: "none"
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                className="d-flex justify-content-center"
                                                                                                style={{
                                                                                                    paddingTop: '6px',
                                                                                                    paddingBottom: '6px'
                                                                                                }}>
                                                                                                Revert
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            )}
                                                                            {item.history_status === "edited" && (
                                                                                <div className="row px-3">
                                                                                    <div
                                                                                        className="mr-2"
                                                                                        style={{
                                                                                            border: '1px solid #000',
                                                                                            borderRadius: '25px',
                                                                                            width: '50%',
                                                                                            color: '#E6462E',
                                                                                            borderColor: '#E6462E',
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            className="d-flex justify-content-center"
                                                                                            style={{
                                                                                                paddingTop: '6px',
                                                                                                paddingBottom: '6px'
                                                                                            }}>
                                                                                            {i18n.t('diubah')}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-md-5 btn ipresens-btn-revert"
                                                                                        onClick={() => {
                                                                                            this.setState({
                                                                                                idRev: item.id_jam_kerja,
                                                                                                id_History: item.history_id
                                                                                            })
                                                                                            this.revertJam()
                                                                                        }}
                                                                                        style={{
                                                                                            border: '1px solid #000',
                                                                                            borderRadius: '25px',
                                                                                            width: '50%',
                                                                                            color: '#8c2bee',
                                                                                            borderColor: '#8c2bee',
                                                                                            lineHeight: '0px',
                                                                                            textTransform: "none"
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            className="d-flex justify-content-center"
                                                                                            style={{
                                                                                                paddingTop: '6px',
                                                                                                paddingBottom: '6px'
                                                                                            }}>
                                                                                            Revert
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                            {item.history_status === "deleted" && (
                                                                                <div
                                                                                    className="m-0"
                                                                                    style={{
                                                                                        border: '1px solid #000',
                                                                                        borderRadius: '25px',
                                                                                        width: '50%',
                                                                                        color: '#ff0000',
                                                                                        borderColor: '#ff0000',
                                                                                    }}
                                                                                >
                                                                                    <div className="d-flex justify-content-center" style={{
                                                                                        paddingTop: '6px',
                                                                                        paddingBottom: '6px'
                                                                                    }}>
                                                                                        {i18n.t('dihapus')}
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                            {item.history_status === "reverted" && (
                                                                                <div className="row px-3">
                                                                                    <div
                                                                                        className="mr-2"
                                                                                        style={{
                                                                                            border: '1px solid #000',
                                                                                            borderRadius: '25px',
                                                                                            width: '50%',
                                                                                            color: '#8c2bee',
                                                                                            borderColor: '#8c2bee',
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            className="d-flex justify-content-center"
                                                                                            style={{
                                                                                                paddingTop: '6px',
                                                                                                paddingBottom: '6px'
                                                                                            }}>
                                                                                            {i18n.t('dikembalikan')}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} md={6} className="text-left" style={{
                                                                paddingTop: '16px',
                                                                paddingBottom: '16px',
                                                                paddingRight: '12px',
                                                                paddingLeft: '12px'
                                                            }}>
                                                                {item.history_status === 'reverted' && (
                                                                    <div>
                                                                        {(tipe ? i18n.t('namajambelajar') : i18n.t('namashift'))} : <b>{item.history_sift ? item.history_sift.sift_name : ''}</b>
                                                                    </div>
                                                                )}
                                                                <div>{i18n.t('hari')}: <b>{item.kerja_hari ? item.kerja_hari : ''}</b></div>
                                                                {item.history_status !== 'edited' && (
                                                                    <div>
                                                                        <div>{i18n.t('jammasuk')}: <b>{item.jam_masuk ? item.jam_masuk : ''}</b>
                                                                        </div>
                                                                        <div>{i18n.t('jamkeluar')}: <b>{item.jam_keluar ? item.jam_keluar : ''}</b>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {item.history_status === 'edited' && (
                                                                    <div>
                                                                        {item.parent_history ?
                                                                            item.parent_history.parent_history_shift.sift_name != item.history_sift.sift_name && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {(tipe ? i18n.t('namajambelajar') : i18n.t('namashift'))}: {i18n.t('dari')}{' '}
                                                                                        <b>{item.parent_history.parent_history_shift
                                                                                            ? item.parent_history.parent_history_shift.sift_name
                                                                                            : 'N/A'}</b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t('jadi')}: <b>{item.history_sift.sift_name ? item.history_sift.sift_name : ''}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ) : null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.parent_history_shift.sift_late_tolerance != item.history_sift.sift_late_tolerance && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t('toleransiketerlambatan')}: {i18n.t('dari')}{' '}
                                                                                        <b>{item.parent_history.parent_history_shift
                                                                                            ? item.parent_history.parent_history_shift.sift_late_tolerance
                                                                                            : 'N/A'}</b>{' '} {i18n.t('menit')}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t('jadi')}: <b>{item.history_sift.sift_late_tolerance ? item.history_sift.sift_late_tolerance : ''}</b> {i18n.t('menit')}
                                                                                    </h6>
                                                                                </div>
                                                                            ) : null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.kerja_hari != item.kerja_hari && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {tipe? i18n.t("haribelajar") : i18n.t('harikerja')}: {i18n.t('dari')}{' '}
                                                                                        <b>{item.parent_history
                                                                                            ? item.parent_history.kerja_hari
                                                                                            : 'N/A'}</b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t('jadi')}: <b>{item.kerja_hari ? item.kerja_hari : ''}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ) : null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.jam_masuk != item.jam_masuk && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t('jammasuk')}: {i18n.t('dari')}{' '}
                                                                                        <b>{item.parent_history
                                                                                            ? item.parent_history.jam_masuk
                                                                                            : 'N/A'}</b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t('jadi')}: <b>{item.jam_masuk ? item.jam_masuk : ''}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ) : null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.jam_keluar != item.jam_keluar && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t('jamkeluar')}: {i18n.t('dari')}{' '}
                                                                                        <b>{item.parent_history
                                                                                            ? item.parent_history.jam_keluar
                                                                                            : 'N/A'}</b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t('jadi')}: <b>{item.jam_keluar ? item.jam_keluar : ''}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ) : null
                                                                        }
                                                                    </div>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{ backgroundColor: "#F5F5F5" }}>
                            <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                                {this.state.dataHistory.length !== 0 &&
                                    <>
                                        <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>{i18n.t('menampilkan')} {this.state.dataHistory.length === 0 || this.state.dataHistory.length === null ? "0" : this.state.dataHistory.length} {i18n.t('dari')} {this.state.totalDataHistory} data</div>
                                        <div className='col'>
                                            <ReactPaginate
                                                previousLabel={<img src={ArrowLeft} alt='' />}
                                                nextLabel={<img src={ArrowRight} alt='' />}
                                                pageCount={this.state.pageCountHistory}
                                                forcePage={this.state.currentPageHistory}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={this.handlePageHistory}
                                                breakClassName={'page-item'}
                                                breakLinkClassName={'page-link custom-page-link'}
                                                containerClassName={'pagination my-2'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link custom-page-link'}
                                                previousClassName={'page-item'}
                                                previousLinkClassName={'page-link custom-prev-next-link'}
                                                nextClassName={'page-item'}
                                                nextLinkClassName={'page-link custom-prev-next-link'}
                                                activeClassName={'active custom-active'}
                                            />
                                        </div>
                                    </>
                                }
                            </div>

                        </ModalFooter>
                    </ModalRs>

                    <ModalRs
                        size="lg" style={{maxWidth: "1000px"}}
                        isOpen={this.state.modalTrash}
                        toggle={() => this.setState({
                            modalTrash: false,
                            currentPageTrash: 0,
                            checkedTrash: [],
                            checkAll: false
                        })}
                    >
                        <ModalHeader
                            style={{backgroundColor: "#F8F3FD"}}
                            toggle={() => this.setState({
                                modalTrash: false,
                                currentPageTrash: 0,
                                checkedTrash: [],
                                checkAll: false
                            })}
                        >
                            <div className='modal-header-ipresens'>{tipe ? i18n.t('daftarsampahjambelajar') : i18n.t('daftarsampahjamkerja')}</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="row d-flex justify-content-end">
                                <div className="col-md-6"></div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <button
                                        className="btn ipresens-btn-color px-3 py-2 mx-1"
                                        disabled={this.state.checkedTrash.length === 0}
                                        onClick={() => {
                                            this.setState({restoreJam: true});
                                        }}>
                                        {i18n.t('pulihkan')}
                                    </button>
                                    <button
                                        className="btn ipresens-btn-color px-3 py-2 mx-1"
                                        disabled={this.state.checkedTrash.length === 0}
                                        onClick={() => {
                                            this.setState({permanentDelete: true});
                                        }}>
                                        {i18n.t('hapuspermanen')}
                                    </button>
                                </div>
                            </div>
                            <div className="list-group">
                                <div className="table-responsive">
                                    {!this.state.loadingTrash && this.state.dataTrash.length === 0 ?
                                        <Empty
                                            description={i18n.t('datasampahkosong')}
                                            image={EmptyDataImage} className="ipresens-image-empty mb-5 mt-5"/>
                                        :
                                        <table className="table table-striped mb-5">
                                            <thead>
                                            <tr>
                                                <th style={{border: "none", fontSize: "13px"}} className="modal-header-ipresens-table" width="30">
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox-custom"
                                                        checked={this.state.checkAll}
                                                        onChange={this.handleCheckBoxAllChange}
                                                    ></input>
                                                </th>
                                                <th style={{border: "none", fontSize: "13px", textAlign: 'center'}} className="modal-header-ipresens-table" width="30">#</th>
                                                <th style={{border: "none", fontSize: "13px"}} className="text-left modal-header-ipresens-table" width="200">{i18n.t("nama")}</th>
                                                <th style={{border: "none", fontSize: "13px"}} className="text-left modal-header-ipresens-table" width="200">{tipe? i18n.t("jadwalbelajar") :i18n.t("jadwalkerja")}</th>
                                                <th style={{border: "none", fontSize: "13px"}} className="text-left modal-header-ipresens-table" width="200">{tipe? i18n.t("jambelajar") :i18n.t("jamkerja")}</th>
                                                <th style={{border: "none", fontSize: "13px"}} className="text-left modal-header-ipresens-table" width="200">{i18n.t("toleransitelat")}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.loadingTrash &&
                                                <Tr>
                                                    <Td colSpan={6}>
                                                        <Spin size="large" tip="Loading..." style={{
                                                            width: '100%',
                                                            margin: 'auto',
                                                            marginBottom: '10px',
                                                            marginTop: '10px'
                                                        }}/>
                                                    </Td>
                                                </Tr>
                                            }
                                            {this.state.dataTrash.map((item, index) => {
                                                let no = index + 1;
                                                return (
                                                    <tr>
                                                        <td style={{border: "none"}}>
                                                            <input
                                                                className="checkbox-custom"
                                                                type="checkbox"
                                                                checked={this.state.checkedTrash.includes(item.sift_id)}
                                                                onChange={() => this.handleCheckBoxChange(item.sift_id)}
                                                            ></input>
                                                        </td>
                                                        <td style={{border: "none"}}>
                                                            <small>
                                                                <th
                                                                    style={{
                                                                        border: "none",
                                                                        color: "#38115F"
                                                                    }}
                                                                    align='left'
                                                                    scope="row"
                                                                >
                                                                    {pageNumber(no, this.state.perPageTrash, this.state.currentPageTrash)}
                                                                </th>
                                                            </small>
                                                        </td>
                                                        <td style={{border: "none"}} align='left'>
                                                            <div>
                                                                <p style={{
                                                                    color: "#38115F",
                                                                    marginBottom: "0px"
                                                                }}>{item.sift_name}</p>
                                                            </div>
                                                        </td>
                                                        <td style={{border: "none"}} align='left'>
                                                            <div>
                                                                <p style={{
                                                                    color: "#38115F",
                                                                    marginBottom: "0px"
                                                                }}>{item.jam_kerja.length} {tipe ? i18n.t("haribelajar") : i18n.t("harikerja")}</p>
                                                            </div>
                                                        </td>
                                                        <td style={{border: "none"}} align='left'>
                                                            <div>
                                                                <p style={{
                                                                    color: "#38115F",
                                                                    marginBottom: "0px"
                                                                }}>{item.jamkerja ? item.jamkerja.jam_masuk !== null ? item.jamkerja.jam_masuk.slice(0, 5) : "" : "-"} - {item.jamkerja ? item.jamkerja.jam_keluar !== null ? item.jamkerja.jam_keluar.slice(0, 5) : "" : "-"}</p>
                                                            </div>
                                                        </td>
                                                        <td style={{border: "none"}} align='left'>
                                                            <div>
                                                                <p style={{
                                                                    color: "#38115F",
                                                                    marginBottom: "0px"
                                                                }}>{item.sift_late_tolerance} {i18n.t("menit")}</p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </table>
                                    }
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{
                            backgroundColor: "#F5F5F5",
                            borderBottomLeftRadius: "8px",
                            borderBottomRightRadius: "8px"
                        }}>
                            <div className='row mx-0' style={{backgroundColor: "#F5F5F5", width: "100%"}}>
                                {this.state.dataTrash.length !== 0 &&
                                    <>
                                        <div className='col font-14' style={{
                                            padding: "auto",
                                            margin: "auto",
                                            fontWeight: 500,
                                            lineHeight: "24px"
                                        }}>{i18n.t("menampilkan")} {this.state.dataTrash.length === 0 || this.state.dataTrash.length === null ? "0" : this.state.dataTrash.length} {i18n.t("dari")} {this.state.totalDataTrashJam} {i18n.t("data")}</div>
                                        <div className='col'>
                                            <ReactPaginate
                                                previousLabel={<img src={ArrowLeft} alt=''/>}
                                                nextLabel={<img src={ArrowRight} alt=''/>}
                                                pageCount={this.state.pageCountTrash}
                                                forcePage={this.state.currentPageTrash}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={this.handlePageTrash}
                                                breakClassName={'page-item'}
                                                breakLinkClassName={'page-link custom-page-link'}
                                                containerClassName={'pagination my-2'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link custom-page-link'}
                                                previousClassName={'page-item'}
                                                previousLinkClassName={'page-link custom-prev-next-link'}
                                                nextClassName={'page-item'}
                                                nextLinkClassName={'page-link custom-prev-next-link'}
                                                activeClassName={'active custom-active'}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </ModalFooter>
                    </ModalRs>

                    <Modal centered isOpen={this.state.restoreJam} toggle={() => {
                        this.setState({
                            restoreJam: false
                        })
                    }}>
                        <ModalHeader className='pl-4 border-bottom-0' toggle={() => {
                            this.setState({
                                restoreJam: false
                            })
                        }}>
                            <div className='d-flex'>
                                <div className='p-3 mr-4' style={{
                                    backgroundColor: "#8C2BEE",
                                    borderRadius: "8px"
                                }}>
                                    <img alt='' src={Restore} />
                                </div>
                                <div>
                                    <div style={{
                                        color: "#143B5E",
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        lineHeight: "27px"
                                    }}>{tipe ? i18n.t('pulihkandatajambelajar') : i18n.t('pulihkandatajamkerja')}</div>
                                    <div style={{
                                        color: "#5D6F80",
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "24px"
                                    }}>{this.state.checkedTrash.length} {tipe ? i18n.t('jambelajardipilih') : i18n.t('jamkerjadipilih')}</div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalFooter style={{ borderTop: 0 }}>
                            <div className='row mx-0' style={{ width: "100%" }}>
                                <div className='col-md-6 px-1'>
                                    <button className='btn ipresens-btn-cancel' style={{ width: "100%" }} onClick={() => {
                                        this.setState({
                                            restoreJam: false
                                        })
                                    }}>
                                        {i18n.t('batal')}
                                    </button>
                                </div>
                                <div className='col-md-6 px-1'>
                                    <button className='btn ipresens-btn-color' style={{
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        lineHeight: "24px",
                                        width: "100%"
                                    }} onClick={this.pemulihanJam}>
                                        {i18n.t('ya')}
                                    </button>
                                </div>
                            </div>
                        </ModalFooter>
                    </Modal>

                    <Modal style={{maxWidth: "600px"}} centered isOpen={this.state.permanentDelete} toggle={() => {this.setState({permanentDelete: false})}}>
                        <ModalHeader className='pl-6 border-bottom-0' toggle={() => {
                            this.setState({
                                permanentDelete: false
                            })
                        }}>
                            <div className='d-flex'>
                                <div className='p-3 mr-4' style={{
                                    backgroundColor: "#8C2BEE",
                                    borderRadius: "8px"
                                }}>
                                    <img alt='' src={Trash} />
                                </div>
                                <div>
                                    <div style={{
                                        color: "#143B5E",
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        lineHeight: "27px"
                                    }}>{tipe ? i18n.t('hapuspermanenjambelajar') : i18n.t('hapuspermanenjamkerja')}</div>
                                    <div style={{
                                        color: "#5D6F80",
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "24px"
                                    }}>{this.state.checkedTrash.length} {tipe ? i18n.t('jambelajarterpilih') : i18n.t('jamkerjaterpilih')}</div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalFooter style={{ borderTop: 0 }}>
                            <div className='row mx-0' style={{ width: "100%" }}>
                                <div className='col-md-6 px-1'>
                                    <button className='btn ipresens-btn-cancel' style={{ width: "100%" }} onClick={() => {
                                        this.setState({
                                            permanentDelete: false
                                        })
                                    }}>
                                        {i18n.t('batal')}
                                    </button>
                                </div>
                                <div className='col-md-6 px-1'>
                                    <button className='btn ipresens-btn-color' style={{
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        lineHeight: "24px",
                                        width: "100%"
                                    }} onClick={this.trashJam}>
                                        {i18n.t('ya')}
                                    </button>
                                </div>
                            </div>
                        </ModalFooter>
                    </Modal>

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card border">
                                    <div className='card-header border-bottom' style={style.card_header}>
                                        {tipe? i18n.t("daftarjambelajar") :i18n.t("daftarjamkerja")}
                                    </div>
                                    <div className='card-body' style={{padding: "24px"}}>
                                        <div className='row mb-3'>
                                            <div className="col-md-6 mb-2">
                                                <div className='d-flex flex-row'>
                                                    <div className='input-group btn-srch-pengumuman mb-0 mr-1'>
                                                        <span className='input-group-text jam-kerja-filter-nama-input-text'>
                                                            <img alt='' src={SearchIcon}/>
                                                        </span>
                                                        <input type='text' onChange={this.handleChange}
                                                               name='filter_nama' value={this.state.filter_nama}
                                                               className='form-control mr-2 jam-kerja-filter-nama-input'
                                                               placeholder={tipe? i18n.t("carijambelajar") :i18n.t("carinamajamkerja") + "..."}
                                                               style={style.filter_nama} onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                this.loadData()
                                                            }
                                                        }}></input>
                                                    </div>
                                                    <div>
                                                        <button className='hover-pointer' style={style.button_refresh}>
                                                        <img alt="" src={RefreshIcon} onClick={(e) => {
                                                                this.setState({
                                                                    filter_nama: "",
                                                                    data: []
                                                                }, () => this.loadData())
                                                            }}/></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col"></div>
                                            <div className="col-auto">
                                                <button onClick={() => this.setState({modalAdd: true})}
                                                        className='btn ipresens-btn-color text-capitalize pull-right button-w-full'>
                                                    <img className='mr-2' alt=''
                                                         src={PlusWhiteIcon}/>{tipe ? i18n.t("tambahjambelajar") : i18n.t("tambahjamkerja")}
                                                </button>
                                            </div>
                                            <div className="col-auto px-0 mr-3" align="center">
                                                <Tooltip rigger={'click'} placement="bottomLeft" color={'#FFF'} title={
                                                    <div className='row px-4 py-2'>
                                                        <a
                                                            onClick={() =>
                                                                this.setState({
                                                                    modalimport: true
                                                                })}
                                                            style={{marginRight: "50px"}}
                                                            className={'row px-2 text-dark'}
                                                        >{i18n.t('import')}</a><br/>
                                                        <a
                                                            onClick={() =>
                                                                this.setState({
                                                                    modalTrash: true
                                                                }, () => {
                                                                    this.loadTrash();
                                                                })}
                                                            style={{marginRight: "50px"}}
                                                            className={'row px-2 text-dark'}
                                                        >{tipe ? i18n.t("sampahjambelajar") : i18n.t("sampahjamkerja")}</a>
                                                    </div>
                                                }>
                                                    <a><i
                                                        style={{
                                                            width: "15",
                                                            fontSize: 18,
                                                            color: 'rgb(51 51 51)',
                                                            alignItems: "center",
                                                            height: "42px",
                                                            paddingRight: "30px"
                                                        }}
                                                        className='btn border border-dark border-1 px-2'
                                                    ><img src={MoreIcon}/></i></a>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className='table-responsive'>
                                                    {!this.state.loadingList && this.state.data.length === 0 ?
                                                        <Empty description={i18n.t("datakosong")}
                                                               image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                                        :
                                                        <table className='table table-striped table-borderless'>
                                                            <thead>
                                                            <tr>
                                                                <th style={{
                                                                    borderBottom: "0px",
                                                                    padding: "16px 12px",
                                                                    fontWeight: 500,
                                                                    fontSize: "14px",
                                                                    lineHeight: "24px",
                                                                    color: "#252B2B"
                                                                }}>#</th>
                                                                <th style={{
                                                                    borderBottom: "0px",
                                                                    padding: "16px 12px",
                                                                    fontWeight: 500,
                                                                    fontSize: "14px",
                                                                    lineHeight: "24px",
                                                                    color: "#252B2B",
                                                                    minWidth: "126px"
                                                                }}>{i18n.t("nama")}</th>
                                                                <th style={{
                                                                    borderBottom: "0px",
                                                                    padding: "16px 12px",
                                                                    fontWeight: 500,
                                                                    fontSize: "14px",
                                                                    lineHeight: "24px",
                                                                    color: "#252B2B",
                                                                    minWidth: "126px"
                                                                }}>{tipe? i18n.t("jadwalbelajar") :i18n.t("jadwalkerja")}</th>
                                                                <th style={{
                                                                    borderBottom: "0px",
                                                                    padding: "16px 12px",
                                                                    fontWeight: 500,
                                                                    fontSize: "14px",
                                                                    lineHeight: "24px",
                                                                    color: "#252B2B",
                                                                    minWidth: "126px"
                                                                }}>{tipe? i18n.t("jambelajar") :i18n.t("jamkerja")}</th>
                                                                <th style={{
                                                                    borderBottom: "0px",
                                                                    padding: "16px 12px",
                                                                    fontWeight: 500,
                                                                    fontSize: "14px",
                                                                    lineHeight: "24px",
                                                                    color: "#252B2B",
                                                                    minWidth: "196px"
                                                                }} maxWidth={"100px"}>{i18n.t("toleransitelat")}</th>
                                                                <th style={{
                                                                    borderBottom: "0px",
                                                                    padding: "16px 12px",
                                                                    fontWeight: 500,
                                                                    fontSize: "14px",
                                                                    lineHeight: "24px",
                                                                    color: "#252B2B",
                                                                    minWidth: "96px"
                                                                }}>Status</th>
                                                                <th style={{
                                                                    borderBottom: "0px",
                                                                    padding: "16px 12px",
                                                                    fontWeight: 500,
                                                                    fontSize: "14px",
                                                                    lineHeight: "24px",
                                                                    color: "#252B2B",
                                                                    minWidth: "136px"
                                                                }}></th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.data.map((item, index) => {
                                                                    no += 1
                                                                    return (
                                                                        <tr>
                                                                            <td style={style.td_style}>{pageNumber(no, this.state.perPage, this.state.currentPage)}</td>
                                                                            <td style={style.td_style}>{item.sift_name}</td>
                                                                            <td style={style.td_style}>{item.jam_kerja.length} {tipe? i18n.t("haribelajar") : i18n.t("harikerja")}</td>
                                                                            <td style={style.td_style}>{item.jamkerja ? item.jamkerja.jam_masuk !== "" ? item.jamkerja.jam_masuk.slice(0, 5) : "" : "-"} - {item.jamkerja ? item.jamkerja.jam_keluar !== "" ? item.jamkerja.jam_keluar.slice(0, 5) : "" : "-"}</td>
                                                                            <td style={style.td_style}>{item.sift_late_tolerance} {i18n.t("menit")}</td>
                                                                            <td style={style.td_style} className='d-flex flex-nowrap'>
                                                                                <Switch onChange={(e) => this.setState({ status: e, shift_id: item.sift_id }, () => this.updateStatus())}
                                                                                        checked={item.sift_status === "1"}
                                                                                        onColor="#8c2bee"
                                                                                        offColor="#eb4034"
                                                                                        handleDiameter={18}
                                                                                        uncheckedIcon={false}
                                                                                        checkedIcon={false}
                                                                                        boxShadow="0px 1px 1px rgba(0, 0, 0, 0.6)"
                                                                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                                        height={24}
                                                                                        width={48}
                                                                                        className="mr-1"
                                                                                /> {item.sift_status === "1" ? <div className='flex-fill pl-1' style={{ color: "#8c2bee" }}>{i18n.t("aktif")}</div> : <div className='flex-fill pl-1'>{i18n.t("nonaktif")}</div>}
                                                                            </td>
                                                                            <td style={style.td_style}>
                                                                                <Tooltip placement="bottom" color={'#fff'} title={
                                                                                    <div className={'p-2'}>
                                                                                        <a onClick={() => {
                                                                                            this.setState({
                                                                                                modalHistory: true,
                                                                                                sift_id: item.sift_id
                                                                                            }, () => {
                                                                                                this.loadHistory(item.sift_id);
                                                                                            })
                                                                                        }} className={'text-dark'}>{i18n.t('riwayat')}</a><br/>
                                                                                        <a onClick={() => this.setState({shift_id: item.sift_id}, () => this.byIdJamKerja())} className={'text-dark'}>Edit</a><br/>
                                                                                        <a onClick={() => this.setState({shift_id: item.sift_id}, () => {
                                                                                            Swal.fire({
                                                                                                title: i18n.t('yakinhapus'),
                                                                                                text: i18n.t('pesanandayakin'),
                                                                                                icon: 'warning',
                                                                                                showCancelButton: true,
                                                                                                confirmButtonColor: '#3085d6',
                                                                                                cancelButtonColor: '#d33',
                                                                                                confirmButtonText: i18n.t('hapus'),
                                                                                                cancelButtonText: i18n.t('batal')
                                                                                            }).then((result) => {
                                                                                                if (result.isConfirmed) {
                                                                                                    this.deleteJamKerja()
                                                                                                }
                                                                                            })
                                                                                        })}
                                                                                           className={'text-danger'}>{i18n.t("hapus")}</a>
                                                                                    </div>
                                                                                }>
                                                                                    <a><i
                                                                                        className="fa fa-ellipsis-v"
                                                                                        style={{
                                                                                            width: 35,
                                                                                            fontSize: 18,
                                                                                            padding: 11,
                                                                                            color: 'rgb(51 51 51)'
                                                                                    }}
                                                                                    ></i></a>
                                                                                </Tooltip>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                                {this.state.loadingList &&
                                                                    <Tr>
                                                                        <Td colSpan={9}>
                                                                            <Spin size="large" tip={i18n.t('loading...')} style={{
                                                                                width: '100%',
                                                                                margin: 'auto',
                                                                                marginBottom: '10px',
                                                                                marginTop: '10px'
                                                                            }} />
                                                                        </Td>
                                                                    </Tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-footer p-0'>
                                    <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                                        {/* {this.state.data.length !== 0 && */}
                                        <>
                                            <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>{i18n.t('menampilkan')} {this.state.data.length === 0 || this.state.data.length === null ? "0" : this.state.data.length} {i18n.t('dari')} {this.state.totalData} data</div>
                                            <div className='col'>
                                                <ReactPaginate
                                                    previousLabel={<img src={ArrowLeft} alt='' />}
                                                    nextLabel={<img src={ArrowRight} alt='' />}
                                                    pageCount={this.state.pageCount}
                                                    forcePage={this.state.currentPage}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={this.handlePageClick}
                                                    breakClassName={'page-item'}
                                                    breakLinkClassName={'page-link custom-page-link'}
                                                    containerClassName={'pagination my-2'}
                                                    pageClassName={'page-item'}
                                                    pageLinkClassName={'page-link custom-page-link'}
                                                    previousClassName={'page-item'}
                                                    previousLinkClassName={'page-link custom-prev-next-link'}
                                                    nextClassName={'page-item'}
                                                    nextLinkClassName={'page-link custom-prev-next-link'}
                                                    activeClassName={'active custom-active'}
                                                />
                                                {/* catatan.
                                                custom classname pagination dipanggil dari file css di folder absensi harian
                                                ga hanya pagination, semua classname dari css absensi harian bisa dipanggil file ini */}
                                            </div>
                                        </>
                                        {/* // } */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">{i18n.t('tidakadaakseshalaman')}</h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(JamKerja);
