import React, { Component, Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb';
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Swal from "sweetalert2";
import ImgCrop from "antd-img-crop";
import { Select, Upload } from "antd";
import { API_HOST, IMG_HOST } from "../../helper/host";
import { Settings, User } from "react-feather";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { CloudUpload } from "@material-ui/icons";
import eyeIcon from "../../assets/icons/eye.svg";
import eyeIconSlash from "../../assets/icons/eye-slash-grey.svg";
import i18n from "i18next";
import "../styling.css"
// import { Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import { Modal, Button, message } from 'antd'
import { connect } from "react-redux";
import {clear_auth, set_auth} from "../../reducers/Auth";
import {Add_karyawan} from "./add-karyawan";
import {List_karyawan} from "./list-karyawan";
import UploadIcon from "../../assets/icons/import-icon-blue-light.svg";

export class Edit_karyawan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prosesSimpan: false,
            jabatan: [],
            lokasi: [],
            fileFoto: [],
            fileList: [],
            fileKtp: [],
            fileSelfie: [],
            fileIjazah: [],
            data_karyawan: [],
            divisi: [],
            bpjs_data: [],
            perusahaan_karyawan: '',
            foto_karyawan: '',
            foto_ktp: '',
            foto_selfie: '',
            foto_ijazah: '',
            nama_karyawan: '',
            no_induk_karyawan: '',
            gender_karyawan: '',
            jabatan_karyawan: '',
            divisi_karyawan: '',
            lokasi_karyawan: null,
            lokasi_karyawanData: '',
            email_karyawan: '',
            password_karyawan: '',
            ttl_karyawan: '',
            join_date_karyawan: '',
            no_hp_karyawan: '',
            alamat_karyawan: '',
            gaji_karyawan: '',
            no_npwp: '',
            no_bpjs: '',
            karyawan_bpjs: '',
            hidePassword: false,
            hideConfirmPassword: false,
            modalUpload: false,
            no_hp_ortu: '',
            phone_number_ortu: "",

        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    handlePressChange(event) {


        let formData = {
            karyawan_slug: this.props.match.params.id,
        }

        this.props.request('karyawan/ubah-status', formData, 'POST')
            .then((response) => {
                if (response.success) {
                    toast['success'](i18n.t(response.message))
                    this.loadDataKaryawan()
                }
                else {
                    if (Array.isArray(i18n.t(response.message))) {

                        response.message.map(item => {
                            toast['error'](item)
                            return true
                        })
                    } else {
                        toast['error'](response.message)
                    }
                }
            })
    }

    loadJabatan() {
        let form = {}
        this.props.request("get_jabatan_perusahaan", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        jabatan: response.data,
                    })
                }
            });
    }



    loadLokasi() {
        let form = {}
        this.props.request("get_lokasi_perusahaan", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        lokasi: response.data,
                    })
                }
            });
    }


    loadBPJSSetting() {
        let form = {}
        this.props.request("bpjs/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        isLoading: false,
                        bpjs_data: response.data
                    })
                }
            });
    }

    lokasiHandler = () => {
        if (this.state.lokasi_karyawan === null) {
            if (this.state.lokasi_karyawanData !== null) {
                this.setState({
                    lokasi_karyawan: this.state.lokasi_karyawanData
                })
            } else {
                this.setState({
                    lokasi_karyawan: "" + this.props.lokasi[0].lokasi_id + ""
                })
            }
        } else {
            this.setState({
                lokasi_karyawan: null
            })
        }
    }

    simpanKaryawan = () => {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            karyawan_id: this.props.match.params.id,
            lokasi_karyawan: this.state.lokasi_karyawan,
            foto_karyawan: this.state.foto_karyawan === null ? this.state.data_karyawan.karyawan_foto : this.state.foto_karyawan,
            foto_ktp: this.state.foto_ktp === null ? this.state.data_karyawan.karyawan_ktp : this.state.foto_ktp,
            foto_selfie: this.state.foto_selfie === null ? this.state.data_karyawan.karyawan_selfie : this.state.foto_selfie,
            foto_ijazah: this.state.foto_ijazah === null ? this.state.data_karyawan.karyawan_selfie : this.state.foto_ijazah,
            nama_karyawan: this.state.nama_karyawan,
            no_induk_karyawan: this.state.no_induk_karyawan,
            gender_karyawan: this.state.gender_karyawan,
            email_karyawan: this.state.email_karyawan,
            jabatan_karyawan: this.state.jabatan_karyawan,
            divisi_karyawan: this.state.divisi_karyawan,
            ttl_karyawan: this.state.ttl_karyawan,
            join_date_karyawan: this.state.join_date_karyawan,
            no_hp_karyawan: this.state.no_hp_karyawan,
            alamat_karyawan: this.state.alamat_karyawan,
            no_npwp: this.state.no_npwp,
            no_bpjs: this.state.no_bpjs,
            karyawan_bpjs: this.state.karyawan_bpjs,
            outlet: this.state.pilih_outlet,
            no_hp_ortu: this.state.no_hp_ortu,
            tipe_instansi: this.props.user.perusahaan.perusahaan_type
        }
        this.props.request("karyawan/update_karyawan", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t(response.success),
                        icon: 'success'
                    })
                    this.props.history.push('/employee/employee-list/' + this.props.match.params.page)
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: response.failed,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    prosesSimpan: false,
                    modalUpload: false,
                    fileFoto: [],
                    foto_karyawan: null,
                }, () => this.loadDataKaryawan())
            });
    }

    simpanPasswordKaryawan = () => {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            id_karyawan: this.props.match.params.id,
            password_baru: this.state.password_baru,
            confirm_password: this.state.confirm_password,
        }

        this.props.request("karyawan/update_password_karyawan", form)
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t("berhasil"),
                        text: i18n.t(response.message),
                        icon: 'success'
                    })
                    this.props.history.push('/employee/employee-list/' + this.props.match.params.page)
                } else {
                    Swal.fire({
                        title: i18n.t("gagal"),
                        text: i18n.t(response.message),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    prosesSimpan: false,
                })
            });
    }

    loadDivisi() {
        let form = {}
        this.props.request("get_divisi_perusahaan", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        isLoading: false,
                        divisi: response.data
                    })
                }
            });
    }

    loadDataKaryawan() {
        let form = {
            slug: this.props.match.params.id
        }
        this.props.request("karyawan/get_karyawan_by_slug", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data_karyawan: response.data,
                        jabatan_karyawan: response.data.jabatan_id,
                        divisi_karyawan: response.data.divisi_id,
                        lokasi_karyawan: response.data.lokasi_id,
                        lokasi_karyawanData: response.data.lokasi_id,
                        nama_karyawan: response.data.karyawan_nama,
                        no_induk_karyawan: response.data.karyawan_no_induk,
                        gender_karyawan: response.data.karyawan_gender,
                        foto_karyawan: response.data.karyawan_foto,
                        foto_ktp: response.data.karyawan_ktp,
                        foto_selfie: response.data.karyawan_selfie,
                        foto_ijazah: response.data.karyawan_ijazah,
                        email_karyawan: response.data.karyawan_email,
                        ttl_karyawan: response.data.karyawan_ttl,
                        join_date_karyawan: response.data.karyawan_join_date,
                        no_hp_karyawan: response.data.no_hp,
                        no_npwp: response.data.karyawan_no_npwp,
                        no_bpjs: response.data.karyawan_no_bpjs,
                        alamat_karyawan: response.data.karyawan_alamat,
                        no_hp_ortu: response.data.no_hp_ortu,

                    })
                    if (response.data.karyawan_foto !== null) {
                        this.setState({
                            fileList: [
                                {
                                    uid: "-1",
                                    name: response.data.karyawan_foto,
                                    status: "done",
                                    url: IMG_HOST + 'mitra/frontapp/karyawan/' + response.data.karyawan_foto
                                }
                            ],
                        })
                    }

                    if (response.data.karyawan_selfie !== null) {
                        this.setState({
                            fileSelfie: [
                                {
                                    uid: "-1",
                                    name: response.data.karyawan_selfie,
                                    status: "done",
                                    url: IMG_HOST + 'mitra/frontapp/karyawan/' + response.data.karyawan_selfie
                                }
                            ],
                        })
                    }

                    if (response.data.karyawan_ktp !== null) {
                        this.setState({
                            fileKtp: [
                                {
                                    uid: "-1",
                                    name: response.data.karyawan_ktp,
                                    status: "done",
                                    url: IMG_HOST + 'mitra/frontapp/karyawan/' + response.data.karyawan_ktp
                                }
                            ],
                        })
                    }
                    if (response.data.karyawan_ijazah !== null) {
                        this.setState({
                            fileIjazah: [
                                {
                                    uid: "-1",
                                    name: response.data.karyawan_ijazah,
                                    status: "done",
                                    url: IMG_HOST + 'mitra/frontapp/karyawan/' + response.data.karyawan_ijazah
                                }
                            ],
                        })
                    }
                }
            });
    }

    componentDidMount() {
        this.loadBPJSSetting()
        this.loadDataKaryawan()
    }

    handlUpload = e => {
        this.setState({
            modalUpload: true
        })
    }

    handleOk = e => {
        this.setState({
            modalUpload: false
        });
    };

    handleCancel = e => {
        this.setState({
            foto_karyawan: null,
            modalUpload: false
        });
    };


    render() {
        const { Option } = Select;
        const isSekolah = this.props.user.perusahaan.perusahaan_type === "sekolah";
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{isSekolah ? i18n.t('editsiswa'): i18n.t('editkaryawan')} - Presensi</title>
                    <meta name="description" content="Detail Absensi" />
                </Helmet>
                <Breadcrumb title={isSekolah ? i18n.t('editsiswa'): i18n.t('editkaryawan')} parent={isSekolah ? i18n.t('siswa'): i18n.t('karyawan')} />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header pb-3">
                                    <h5>{isSekolah ? i18n.t('editsiswa'): i18n.t('editkaryawan')}</h5>
                                </div>
                                <div className="card-body pt-2">
                                    <Tabs>
                                        <TabList className="nav nav-tabs tab-coupon pt-0">
                                            <Tab className="nav-link d-flex align-items-center"><User className="mr-2" style={{ width: '22px', height: '22px' }}/><b>{isSekolah ? i18n.t('detailsiswa'): i18n.t('detailkaryawan')}</b></Tab>
                                            <Tab className="nav-link d-flex align-items-center"><Settings className="mr-2" style={{ width: '22px', height: '22px' }}/><b>{i18n.t('gantipassword')}</b></Tab>
                                        </TabList>

                                        <TabPanel>
                                            <form className="needs-validation user-add" noValidate="">
                                                <div className="tab-pane fase show active">
                                                    <div className="form-group row col-md-12">
                                                        <div className="col-lg-3 col-md-6">
                                                            <ImgCrop
                                                                rotate
                                                                width={750}
                                                                height={1000}>
                                                                <Upload
                                                                    action={"https://www.mocky.io/v2/5cc8019d300000980a055e76"}
                                                                    listType="picture-card"
                                                                    fileList={this.state.fileList}
                                                                    className="antd-upload-custom"
                                                                    onRemove={() => {
                                                                        this.setState({foto_karyawan: null})
                                                                    }}
                                                                    onChange={(file) => {
                                                                        this.setState({fileList: file.fileList})

                                                                        if (file.fileList.length > 0) {
                                                                            let files = file.file.originFileObj
                                                                            let reader = new FileReader()

                                                                            reader.readAsDataURL(files)
                                                                            reader.onloadend = () => {
                                                                                this.setState({
                                                                                    foto_karyawan: {
                                                                                        name: file.file.name,
                                                                                        file: reader.result
                                                                                    }
                                                                                })
                                                                            }
                                                                        } else {
                                                                            this.setState({foto_karyawan: []})
                                                                        }
                                                                    }}
                                                                >
                                                                    {this.state.fileList.length >= 1 ? null :
                                                                        <div className="p-2 d-flex align-items-center justify-content-center flex-column" style={{height: "167px"}}>
                                                                <span>
                                                                    <img src={UploadIcon}
                                                                         style={{height: "24px", width: "24px"}}/>
                                                                </span>
                                                                            <p className="ant-upload-text mb-3" style={{
                                                                                fontSize: "12px",
                                                                                fontWeight: 400,
                                                                                lineHeight: "16px",
                                                                                marginTop: "6px"
                                                                            }}>{isSekolah ? i18n.t("uploadfotosiswa") : i18n.t("uploadfotokaryawan")}</p>
                                                                            <a className="btn btn-outline-custom tambah-karyawan">{i18n.t('carigambar')}</a>
                                                                        </div>
                                                                    }
                                                                </Upload>
                                                            </ImgCrop>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6">
                                                            <ImgCrop
                                                                rotate
                                                                width={550}
                                                                height={860}>
                                                                <Upload
                                                                    action={"https://www.mocky.io/v2/5cc8019d300000980a055e76"}
                                                                    listType="picture-card"
                                                                    fileList={this.state.fileSelfie}
                                                                    className="antd-upload-custom"
                                                                    onRemove={() => {
                                                                        this.setState({foto_selfie: null})
                                                                    }}
                                                                    onChange={(file) => {
                                                                        this.setState({fileSelfie: file.fileList})

                                                                        if (file.fileList.length > 0) {
                                                                            let files = file.file.originFileObj
                                                                            let reader = new FileReader()

                                                                            reader.readAsDataURL(files)
                                                                            reader.onloadend = () => {
                                                                                this.setState({
                                                                                    foto_selfie: {
                                                                                        name: file.file.name,
                                                                                        file: reader.result
                                                                                    }
                                                                                })
                                                                            }
                                                                        } else {
                                                                            this.setState({foto_selfie: []})
                                                                        }
                                                                    }}
                                                                >
                                                                    {this.state.fileSelfie.length >= 1 ? null :
                                                                        <div className="p-2 d-flex align-items-center justify-content-center flex-column" style={{height: "167px"}}>
                                                                <span>
                                                                    <img src={UploadIcon}
                                                                         style={{height: "24px", width: "24px"}}/>
                                                                </span>
                                                                            <p className="ant-upload-text mb-3" style={{
                                                                                fontSize: "12px",
                                                                                fontWeight: 400,
                                                                                lineHeight: "16px",
                                                                                marginTop: "6px"
                                                                            }}>{isSekolah ? i18n.t("uploadfotoselfisiswa") : i18n.t("uploadfotoselfikaryawan")}</p>
                                                                            <a className="btn btn-outline-custom tambah-karyawan">{i18n.t('carigambar')}</a>
                                                                        </div>
                                                                    }
                                                                </Upload>
                                                            </ImgCrop>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6">
                                                            <ImgCrop
                                                                rotate
                                                                width={960}
                                                                height={480}>
                                                                <Upload
                                                                    action={"https://www.mocky.io/v2/5cc8019d300000980a055e76"}
                                                                    listType="picture-card"
                                                                    fileList={this.state.fileKtp}
                                                                    className="antd-upload-custom"
                                                                    onRemove={() => {
                                                                        this.setState({foto_ktp: null})
                                                                    }}
                                                                    onChange={(file) => {
                                                                        this.setState({fileKtp: file.fileList})

                                                                        if (file.fileList.length > 0) {
                                                                            let files = file.file.originFileObj
                                                                            let reader = new FileReader()

                                                                            reader.readAsDataURL(files)
                                                                            reader.onloadend = () => {
                                                                                this.setState({
                                                                                    foto_ktp: {
                                                                                        name: file.file.name,
                                                                                        file: reader.result
                                                                                    }
                                                                                })
                                                                            }
                                                                        } else {
                                                                            this.setState({foto_ktp: []})
                                                                        }
                                                                    }}
                                                                >
                                                                    {this.state.fileKtp.length >= 1 ? null :
                                                                        <div className="p-2 d-flex align-items-center justify-content-center flex-column" style={{height: "167px"}}>
                                                                <span>
                                                                    <img src={UploadIcon}
                                                                         style={{height: "24px", width: "24px"}}/>
                                                                </span>
                                                                            <p className="ant-upload-text mb-3" style={{
                                                                                fontSize: "12px",
                                                                                fontWeight: 400,
                                                                                lineHeight: "16px",
                                                                                marginTop: "6px"
                                                                            }}>{isSekolah ? i18n.t("uploadfotoktpsiswa") : i18n.t("uploadfotoktpkaryawan")}</p>
                                                                            <a className="btn btn-outline-custom tambah-karyawan">{i18n.t('carigambar')}</a>
                                                                        </div>
                                                                    }
                                                                </Upload>
                                                            </ImgCrop>
                                                        </div>
                                                        {!isSekolah && (
                                                            <div className="col-lg-3 col-md-6">
                                                                <ImgCrop
                                                                    rotate
                                                                    width={550}
                                                                    height={860}>
                                                                    <Upload
                                                                        action={"https://www.mocky.io/v2/5cc8019d300000980a055e76"}
                                                                        listType="picture-card"
                                                                        fileList={this.state.fileIjazah}
                                                                        className="antd-upload-custom"
                                                                        onRemove={() => {
                                                                            this.setState({foto_ijazah: null})
                                                                        }}
                                                                        onChange={(file) => {
                                                                            this.setState({fileIjazah: file.fileList})

                                                                            if (file.fileList.length > 0) {
                                                                                let files = file.file.originFileObj
                                                                                let reader = new FileReader()

                                                                                reader.readAsDataURL(files)
                                                                                reader.onloadend = () => {
                                                                                    this.setState({
                                                                                        foto_ijazah: {
                                                                                            name: file.file.name,
                                                                                            file: reader.result
                                                                                        }
                                                                                    })
                                                                                }
                                                                            } else {
                                                                                this.setState({foto_ijazah: []})
                                                                            }
                                                                        }}
                                                                    >
                                                                        {this.state.fileIjazah.length >= 1 ? null :
                                                                            <div className="p-2 d-flex align-items-center justify-content-center flex-column"
                                                                                 style={{height: "167px"}}>
                                                                <span>
                                                                    <img src={UploadIcon}
                                                                         style={{height: "24px", width: "24px"}}/>
                                                                </span>
                                                                                <p className="ant-upload-text mb-3"
                                                                                   style={{
                                                                                       fontSize: "12px",
                                                                                       fontWeight: 400,
                                                                                       lineHeight: "16px",
                                                                                       marginTop: "6px"
                                                                                   }}>{i18n.t('uploadfotoisajasahkaryawan')}</p>
                                                                                <a className="btn btn-outline-custom tambah-karyawan">{i18n.t('carigambar')}</a>
                                                                            </div>
                                                                        }
                                                                    </Upload>
                                                                </ImgCrop>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label style={{fontSize: "12px", color: "#38115F"}}
                                                                   className="mb-2 font-weight-bold">{isSekolah ? i18n.t("namasiswa") : i18n.t("namakaryawan")}</label>
                                                            <div>
                                                                <input name="nama_karyawan"
                                                                       value={this.state.nama_karyawan}
                                                                       className="form-control"
                                                                       placeholder={isSekolah ? i18n.t('namasiswa') : i18n.t('namakaryawan')}
                                                                       type="text" required=""
                                                                       defaultValue={this.state.data_karyawan.karyawan_nama}
                                                                       onChange={this.handleChange}/>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label style={{fontSize: "12px", color: "#38115F"}}
                                                                   className="mb-2 font-weight-bold">{isSekolah ? i18n.t("nomorinduksiswa") : i18n.t("nomorindukkaryawan") + (' ') + '(' + i18n.t('opsional') + ')'}</label>
                                                            <input name="no_induk_karyawan"
                                                                   className="form-control"
                                                                   placeholder={isSekolah ? i18n.t("nomorinduksiswa") : i18n.t("nomorindukkaryawan") + (' ') + '(' + i18n.t('opsional') + ')'}
                                                                   type="text" required=""
                                                                   defaultValue={this.state.data_karyawan.karyawan_no_induk}
                                                                   onChange={this.handleChange}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label style={{fontSize: "12px", color: "#38115F"}}
                                                                   className="mb-2 font-weight-bold">{i18n.t('email')}</label>
                                                            <input name="email_karyawan"
                                                                   value={this.state.email_karyawan}
                                                                   className="form-control"
                                                                   placeholder={i18n.t('email')}
                                                                   type="email" required=""
                                                                   defaultValue={this.state.data_karyawan.karyawan_email}
                                                                   onChange={this.handleChange}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label style={{fontSize: "12px", color: "#38115F"}}
                                                                   className="mb-2 font-weight-bold">{i18n.t('nomorhandphone')}</label>
                                                            <input name="no_hp_karyawan"
                                                                   value={this.state.no_hp_karyawan}
                                                                   className="form-control"
                                                                   type="number" required=""
                                                                   placeholder={i18n.t('nomorhandphone')}
                                                                   defaultValue={this.state.data_karyawan.no_hp}
                                                                   onChange={this.handleChange}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label style={{fontSize: "12px", color: "#38115F"}}
                                                                   className="mb-2 font-weight-bold">{i18n.t('alamat')}</label>
                                                            <input name="alamat_karyawan" type="text"
                                                                   placeholder={i18n.t('alamat')}
                                                                   className="form-control"
                                                                   defaultValue={this.state.data_karyawan.karyawan_alamat}
                                                                   onChange={this.handleChange}/>
                                                        </div>
                                                        {!isSekolah && (
                                                            <>
                                                                <div className="form-group">
                                                                    <label style={{fontSize: "12px", color: "#38115F"}}
                                                                           className="mb-2 font-weight-bold">{i18n.t('nomorpokokwajibpajak')} {'(' + i18n.t('opsional') + ')'}</label>
                                                                    <div>
                                                                        <input maxLength={16} name="no_npwp" type="text"
                                                                               placeholder={i18n.t('nomorpokokwajibpajak') + ' ' + '(' + i18n.t('opsional') + ')'}
                                                                               className="form-control"
                                                                               defaultValue={this.state.data_karyawan.karyawan_no_npwp}
                                                                               onChange={this.handleChange}/>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label style={{fontSize: "12px", color: "#38115F"}}
                                                                           className="mb-2 font-weight-bold">{i18n.t('nobpjs')} {'(' + i18n.t('opsional') + ')'}</label>
                                                                    <div>
                                                                        <input maxLength={16} name="no_bpjs" type="text"
                                                                               placeholder={i18n.t('nobpjs') + ' ' + '(' + i18n.t('opsional') + ')'}
                                                                               className="form-control"
                                                                               defaultValue={this.state.data_karyawan.karyawan_no_bpjs}
                                                                               onChange={this.handleChange}/>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label style={{fontSize: "12px", color: "#38115F"}}
                                                                           className="mb-2 font-weight-bold">{i18n.t('pengaturanbpjs')} {'(' + i18n.t('opsional') + ')'}</label>
                                                                    <div>
                                                                        <select name="karyawan_bpjs"
                                                                                className="form-control" style={{ fontSize: 13 }}
                                                                                onChange={this.handleChange}>
                                                                            <option>{i18n.t('pilihbpjs')}</option>
                                                                            {this.state.bpjs_data.map((item, index) => {
                                                                                return (
                                                                                    <option value={item.bpjs_setting_id} style={{ fontSize: 13 }}
                                                                                            selected={this.state.data_karyawan.karyawan_bpjs == item.bpjs_setting_id}>{item.bpjs_setting_name}</option>
                                                                                )
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <div className="form-group">
                                                                    <label style={{fontSize: "12px", color: "#38115F"}}
                                                                           className="mb-2 font-weight-bold">{i18n.t('tanggallahir')}</label>
                                                                    <input name="ttl_karyawan"
                                                                           value={this.state.ttl_karyawan}
                                                                           defaultValue={this.state.data_karyawan.karyawan_ttl}
                                                                           className="form-control"
                                                                           onChange={this.handleChange}
                                                                           type="date" required=""/>
                                                                </div>
                                                            </div>
                                                            <div className="col-6">
                                                                <div className="form-group">
                                                                    <label style={{fontSize: "12px", color: "#38115F"}}
                                                                           className="mb-2 font-weight-bold">{i18n.t('jeniskelamin')}</label>
                                                                    <div className="d-flex align-items-center mt-2">
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input"
                                                                                   type="radio"
                                                                                   checked={this.state.gender_karyawan === 'Laki-laki'}
                                                                                   onClick={(e) => this.setState({gender_karyawan: e.target.value})}
                                                                                   name="inlineRadioOptions"
                                                                                   id="inlineRadio1"
                                                                                   value="Laki-laki"/>
                                                                            <label className="form-check-label"
                                                                                   style={{fontSize: "12px"}}
                                                                                   htmlFor="inlineRadio1">{i18n.t('lakilaki')}</label>
                                                                        </div>
                                                                        <div class="form-check form-check-inline">
                                                                            <input className="form-check-input" type="radio"
                                                                                   name="inlineRadioOptions"
                                                                                   id="inlineRadio2"
                                                                                   onClick={(e) => this.setState({gender_karyawan: e.target.value})}
                                                                                   checked={this.state.gender_karyawan === 'Perempuan'}
                                                                                   value="Perempuan"/>
                                                                            <label className="form-check-label"
                                                                                   style={{fontSize: "12px"}}
                                                                                   for="inlineRadio2">{i18n.t('perempuan')}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="form-group col-6">
                                                                <label style={{fontSize: "12px", color: "#38115F"}}
                                                                       className="mb-2 font-weight-bold">{i18n.t('tanggalbergabung')}</label>
                                                                <input name="join_date_karyawan"
                                                                       defaultValue={this.state.data_karyawan.karyawan_join_date}
                                                                       className="form-control"
                                                                       onChange={this.handleChange}
                                                                       type="date" required=""/>
                                                            </div>
                                                            <div className="form-group col-6">
                                                                <label style={{fontSize: "12px", color: "#38115F"}}
                                                                       className="mb-2 font-weight-bold">{i18n.t('status')}</label>
                                                                <div className="d-flex align-items-center mt-2">
                                                                    <div className="form-check form-check-inline">
                                                                        <input
                                                                            name="status_karyawan"
                                                                            id="active"
                                                                            type="radio"
                                                                            value="active"
                                                                            checked={this.state.data_karyawan.status_karyawan === "active"}
                                                                            onClick={(event) => this.handlePressChange(event)}
                                                                        />
                                                                        <label className="form-check-label ml-1"
                                                                               style={{fontSize: "12px"}}
                                                                               htmlFor="inlineRadio1">{i18n.t('aktif')}</label>
                                                                    </div>
                                                                    <div className="form-check form-check-inline">
                                                                        <input
                                                                            name="status_karyawan"
                                                                            type="radio"
                                                                            value="non-active"
                                                                            checked={this.state.data_karyawan.status_karyawan === "non-active"}
                                                                            onClick={(event) => this.handlePressChange(event)}
                                                                        />
                                                                        <label className="form-check-label ml-1"
                                                                               style={{fontSize: "12px"}}
                                                                               htmlFor="inlineRadio2">{i18n.t('non-active')}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-group">
                                                            <label style={{fontSize: "12px", color: "#38115F"}}
                                                                   className="mb-2 font-weight-bold">{isSekolah ? i18n.t('kelas') : i18n.t('divisi')}</label>
                                                            <select name="divisi_karyawan"
                                                                    className="form-control"
                                                                    onChange={this.handleChange}>
                                                                <option
                                                                    value="">{isSekolah ? i18n.t('pilihkelas') : i18n.t('pilihdivisi')}</option>
                                                                {this.props.divisi.map((item, index) => {
                                                                    return (
                                                                        <option value={item.divisi_id}
                                                                                selected={this.state.data_karyawan.divisi_id === item.divisi_id}>{item.divisi_name}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                        {!isSekolah && (
                                                            <div className="form-group">
                                                                <label style={{fontSize: "12px", color: "#38115F"}}
                                                                       className="mb-2 font-weight-bold">{i18n.t('jabatankaryawan')}</label>
                                                                <select name="jabatan_karyawan"
                                                                        className="form-control"
                                                                        onChange={this.handleChange}>
                                                                    <option value="">{i18n.t('pilihjabatan')}</option>
                                                                    {this.props.jabatan.map((item, index) => {
                                                                        return (
                                                                            <option value={item.jabatan_id}
                                                                                    selected={this.state.data_karyawan.jabatan_id === item.jabatan_id}>{item.jabatan_nama}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                        )}
                                                        <div className="form-group">
                                                            <label style={{fontSize: "12px", color: "#38115F"}}
                                                                   className="mb-2 font-weight-bold">{i18n.t('nomorhandphoneorangtua')}</label>
                                                            <input name="no_hp_ortu"
                                                                   value={this.state.no_hp_ortu}
                                                                   className="form-control"
                                                                   type="number" required=""
                                                                   placeholder={i18n.t('nomorhandphoneorangtua')}
                                                                   defaultValue={this.state.data_karyawan.no_hp_ortu}
                                                                   onChange={this.handleChange}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label style={{fontSize: "12px", color: "#38115F"}}
                                                                   className="mb-2 font-weight-bold">{i18n.t('lokasi')}</label>
                                                            <label style={{fontSize: "12px"}} className="d-flex align-items-center">
                                                                <input style={{fontSize: "12px"}} type="checkbox"
                                                                       checked={this.state.lokasi_karyawan === null}
                                                                       onChange={() => this.lokasiHandler()}/>
                                                                &nbsp;{i18n.t('semualokasi')}
                                                            </label>
                                                            {this.state.lokasi_karyawan !== null &&
                                                                <Select name="lokasi_karyawan"
                                                                        style={{width: '100%'}}
                                                                        mode={"multiple"}
                                                                        placeholder={i18n.t('pilihlokasi')}
                                                                        filterOption={(input, option) =>
                                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                        }
                                                                        onChange={(event) => {
                                                                            this.setState({
                                                                                lokasi_karyawan: event
                                                                            })
                                                                        }}>
                                                                    {this.props.lokasi.map((item, index) => {
                                                                        return (<Option
                                                                            key={item.lokasi_id}>{item.lokasi_nama}</Option>)
                                                                    })}
                                                                </Select>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                            <div className="pull-right">
                                                <a className='btn ipresens-btn-cancel-add mr-2 py-2 text-capitalize'
                                                   href="/employee/employee-list/0">{i18n.t('batal')}</a>
                                                {this.state.prosesSimpan === false ?
                                                    <button type="button" className="btn ipresens-btn-color"
                                                            onClick={this.simpanKaryawan}>{i18n.t("simpan")}</button>
                                                    :
                                                    <button type="button" className="btn btn-primary"><i
                                                        className="fa fa-refresh fa-spin"></i> Loading</button>
                                                }
                                            </div>
                                        </TabPanel>
                                        <TabPanel>
                                        <form className="needs-validation user-add" noValidate="">
                                                <div className="input-group row">
                                                    <label
                                                        className="col-xl-3 col-md-4"><span className="text-danger">*</span> {i18n.t("passwordbaru")}
                                                    </label>
                                                    <input name="password_baru"
                                                           className="form-control col-xl-8 col-md-7"
                                                           placeholder={i18n.t("passbaru")}
                                                           type={this.state.hidePassword ? "text" : "password"}
                                                           required="" onChange={this.handleChange} onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            this.simpanPasswordKaryawan(e)
                                                        }
                                                    }}/>
                                                    <div className="input-group-append">
                                                        <div className="input-group-text">
                                                            <img className="show-hide-button cursor-pointer" style={{
                                                                width: "16px",
                                                                height: "16px",
                                                                backgroundColor: "#E9ECEF"
                                                            }} src={this.state.hidePassword ? eyeIcon : eyeIconSlash}
                                                                 onClick={() => this.setState({
                                                                     hidePassword: !this.state.hidePassword
                                                                 })}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="input-group row">
                                                    <label
                                                        className="col-xl-3 col-md-4"><span className="text-danger">*</span> {i18n.t("konfirmasipassbaru")}
                                                    </label>
                                                    <input name="confirm_password"
                                                           className="form-control col-xl-8 col-md-7"
                                                           placeholder={i18n.t("konfirmpassbaru")}
                                                           type={this.state.hideConfirmPassword ? "text" : "password"}
                                                           required="" onChange={this.handleChange} onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            this.simpanPasswordKaryawan(e)
                                                        }
                                                    }}/>
                                                    <div className="input-group-append">
                                                        <div className="input-group-text">
                                                            <img className="show-hide-button cursor-pointer" style={{
                                                                width: "16px",
                                                                height: "16px",
                                                                backgroundColor: "#E9ECEF"
                                                            }} src={this.state.hideConfirmPassword ? eyeIcon : eyeIconSlash}
                                                                 onClick={() => this.setState({
                                                                     hideConfirmPassword: !this.state.hideConfirmPassword
                                                                 })}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>

                                            <div className="pull-right mt-2">
                                                {this.state.prosesSimpan === false ?
                                                    <button type="button" className="btn ipresens-btn-color"
                                                            onClick={this.simpanPasswordKaryawan}>{i18n.t("simpan")}</button>
                                                    :
                                                    <button type="button" className="btn btn-primary"><i
                                                        className="fa fa-refresh fa-spin"></i> Loading</button>
                                                }
                                            </div>
                                        </TabPanel>
                                        <Modal
                                            visible={this.state.modalUpload}
                                            onCancel={this.handleCancel}
                                            onOk={this.handleOk}
                                            title={i18n.t('uploadfotokaryawan')}
                                        >
                                            <div>
                                                <div>
                                                    <a style={{color: "#d10000", cursor: "default", fontSize: '14px'}}>
                                                        {i18n.t('verifikasifotokaryawan')}
                                                    </a>
                                                    <br/>
                                                    <br/>
                                                </div>
                                            </div>
                                            <div>
                                                <ImgCrop
                                                    aspect={750 / 1000}
                                                    width={750}
                                                    height={1000}>
                                                    <Upload action={API_HOST}
                                                            listType="picture-card"
                                                            style={{width: '200px !important'}}
                                                            fileList={this.state.fileList}
                                                            onRemove={() => {
                                                                this.setState({foto_karyawan: null})
                                                            }}
                                                            onChange={(file) => {
                                                                this.setState({fileList: file.fileList})
                                                                if (file.fileList.length > 0) {
                                                                    let files = file.file.originFileObj
                                                                    let reader = new FileReader()
                                                                    reader.readAsDataURL(files)
                                                                    reader.onloadend = () => {
                                                                        this.setState({
                                                                            foto_karyawan: {
                                                                                name: file.file.name,
                                                                                file: reader.result
                                                                            }
                                                                        })
                                                                    }
                                                                } else {
                                                                    this.setState({foto_karyawan: []})
                                                                }
                                                            }}
                                                    >
                                                        {this.state.fileList.length >= 1 ? null :
                                                            <div className="p-2">
                                                                            <span>
                                                                                <CloudUpload color="primary"
                                                                                             fontSize="large"/>
                                                                            </span><br/>
                                                                <a className="btn btn-outline-custom tambah-karyawan">{i18n.t('carigambar')}</a>
                                                            </div>
                                                        }
                                                    </Upload>
                                                </ImgCrop>
                                            </div>
                                        </Modal>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token,
    divisi: state.Filter._divisi,
    jabatan: state.Filter._jabatan,
    lokasi: state.Filter._lokasi
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Edit_karyawan);

