import React, { Component } from 'react'
import { Button, Drawer, Form, Select } from 'antd';
import Swal from "sweetalert2";
import i18n from "i18next";

class EditUserLevel extends Component {

    levelHandler = () => {
        if (this.props.data.level_page_limited == null) {
            this.props.changeState({
                level_page_limited: []
            })
        } else {
            this.props.changeState({
                level_page_limited: null
            })
        }
    }

    divisiHandler = () => {
        if (this.props.data.level_divisi_limited == null) {
            this.props.changeState({
                level_divisi_limited: []
            })
        } else {
            this.props.changeState({
                level_divisi_limited: null
            })
        }
    }

    handleEdit = () => {
        let form = {
            id: this.props.data.level_id,
            level_name: this.props.data.level_name,
            level_page_limited: this.props.data.level_page_limited,
            level_divisi_limited: this.props.data.level_divisi_limited,
        }
        this.props.request("userlevel/update", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        level_id: ''
                    }, () => this.props.changeState({
                        visable: false,
                        level_id: '',
                        level_name: '',
                        level_page_limited: '',
                        data: [],
                        isLoading: true,
                    }, () => {
                        this.props.loadData()
                    }))
                    Swal.fire({
                        title: i18n.t("berhasil"),
                        text: i18n.t(response.message),
                        icon: 'success'
                    })
                }
                if (response.failed) {
                    Swal.fire({
                        title: i18n.t("gagal"),
                        text: i18n.t(response.message),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            });
    }


    render() {
        const { Option } = Select;
        const tipe = this.props.perusahaan_level.perusahaan_type === "sekolah";
        return (
            <Drawer
                title="Edit User Level"
                width={350}
                onClose={() => {
                    this.props.changeState({ visable: false, p_user_name: '' });
                }}
                visible={this.props.data.visable}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <Button
                            onClick={() => {
                                this.props.changeState({ visable: false, p_user_name: '' });
                            }}
                            style={{ marginRight: 8 }}>
                            {i18n.t("batal")}
                        </Button>
                        <Button onClick={this.handleEdit} type="primary">
                            {i18n.t("simpan")}
                        </Button>
                    </div>
                }
            >
                <Form layout="vertical" hideRequiredMark>
                    <div className="form-group">
                        <label>{i18n.t('namauserlevel')}</label>
                        <input name="level_name" className="form-control col-xl-12 col-md-12" placeholder={i18n.t('namauserlevel')}
                            type="text" required="" value={this.props.data.level_name}
                            onChange={(event) => {
                                const target = event.target;
                                const value = target.value;
                                const name = target.name;
                                this.props.changeState({
                                    [name]: value
                                });
                            }} />
                    </div>
                </Form>
                <Form layout="vertical" hideRequiredMark>
                    <div className="form-group">
                        <label>{i18n.t('akseshalamanuser')}</label><br />
                        <label>
                            <input type="checkbox"
                                checked={this.props.data.level_page_limited === null}
                                onChange={() => this.levelHandler()} />
                            &nbsp;{i18n.t('seluruhakseshalaman')}
                        </label>
                        {this.props.data.level_page_limited !== null &&
                            <Select name="level_page_limited"
                                style={{ width: '100%' }}
                                mode={"multiple"}
                                placeholder={i18n.t('pilihakaseshalaman')}
                                value={this.props.data.level_page_limited}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={(event) => {
                                    this.props.changeState({
                                        level_page_limited: event
                                    })
                                }}
                                    options={tipe ?
                                        [
                                            { label: i18n.t('pengumuman'), value: 'Pengumuman' },
                                            {
                                                label: i18n.t('aplikasi'),
                                                options: [
                                                    { label: i18n.t('banneraplikasi'), value: 'Banner Aplikasi' }
                                                ],
                                            },
                                            {
                                                label: i18n.t('masterdata'),
                                                options: [
                                                    { label: i18n.t('kelas'), value: 'Kelas' },
                                                    { label: i18n.t('mapel'), value: 'Mata Pelajaran' },
                                                    { label: i18n.t('jadwal'), value: 'Jadwal' },
                                                    { label: i18n.t('lokasipresensi'), value: 'Lokasi Presensi' },
                                                    { label: i18n.t('semester'), value: 'Semester' }
                                                ],
                                            },
                                            {
                                                label: i18n.t('pengajuansiswa'),
                                                options: [
                                                    { label: i18n.t('pengajuanizin'), value: 'Pengajuan Izin/Cuti' }
                                                ],
                                            },
                                            { label: i18n.t('siswa'), value: 'Siswa' },
                                            { label: i18n.t('guru'), value: 'Guru' },
                                            { label: i18n.t('presensi'), value: 'Presensi' },
                                            { label: i18n.t('agenda'), value: 'Agenda' },
                                            { label: i18n.t('tugas'), value: 'Tugas' },
                                            { label: i18n.t('nilai'), value: 'Nilai' },
                                            { label: i18n.t('pembayaran'), value: 'Pembayaran' },
                                            { label: i18n.t('langganan'), value: 'Langganan' },
                                            { label: i18n.t('pengaturan'), value: 'Pengaturan' },
                                        ]
                                        :
                                        [
                                            { label: i18n.t('pengumuman'), value: 'Pengumuman' },
                                            {
                                                label: i18n.t('aplikasi'),
                                                options: [
                                                    { label: i18n.t('banneraplikasi'), value: 'Banner Aplikasi' }
                                                ],
                                            },
                                            {
                                                label: i18n.t('masterdata'),
                                                options: [
                                                    { label: i18n.t('shift'), value: 'Shift' },
                                                    { label: i18n.t('jamkerja'), value: 'Jam Kerja' },
                                                    { label: i18n.t('divisi'), value: 'Divisi' },
                                                    { label: i18n.t('jabatan'), value: 'Jabatan' },
                                                    { label: i18n.t('lokasikunjungan'), value: 'Lokasi Kunjungan' },
                                                    { label: i18n.t('lokasikerja'), value: 'Lokasi Kerja' },
                                                    { label: i18n.t('daftaristillah'), value: 'Daftar Istillah' },
                                                ],
                                            },
                                            { label: i18n.t('inventaris'), value: 'Inventaris' },
                                            {
                                                label: i18n.t('pengajuankaryawan'),
                                                options: [
                                                    { label: i18n.t('pengajuanizin'), value: 'Pengajuan Izin' },
                                                    { label: i18n.t('pengajuanlembur'), value: 'Pengajuan Lembur' },
                                                    { label: i18n.t('pengajuankasbon'), value: 'Pengajuan Kasbon' },
                                                    { label: i18n.t('pengajuanklaimbeban'), value: 'Pengajuan Klaim beban' },
                                                ],
                                            },
                                            {
                                                label: i18n.t('karyawan'),
                                                options: [
                                                    { label: i18n.t('daftarkaryawan'), value: 'Daftar Karyawan' },
                                                    { label: i18n.t('daftargajikaryawan'), value: 'Daftar Gaji Karyawan' },
                                                    { label: i18n.t('laporancuti'), value: 'Laporan Cuti' },
                                                ]
                                            },
                                            { label: i18n.t('presensi'), value: 'Presensi' },
                                            { label: i18n.t('agenda'), value: 'Agenda' },
                                            { label: i18n.t('kunjungan'), value: 'Kunjungan' },
                                            { label: i18n.t('tugas'), value: 'Tugas' },
                                            { label: 'BPJS', value: 'Pengaturan BPJS' },
                                            {
                                                label: i18n.t('payroll'),
                                                options: [
                                                    { label: i18n.t('daftaristilah'), value: 'Daftar Istilah' },
                                                    { label: i18n.t('daftarpayroll'), value: 'Daftar Payroll' },
                                                    { label: i18n.t('pengaturanpayroll'), value: 'Pengaturan Payroll' },
                                                ],
                                            },
                                            { label: i18n.t('pembayarankasbon'), value: 'Pembayaran Kasbon' },
                                            { label: i18n.t('langganan'), value: 'Langganan' },
                                            { label: i18n.t('pengaturan'), value: 'Pengaturan' },
                                        ]
                                    }
                            >
                            </Select>
                        }
                    </div>
                    <div className="form-group">
                        <label>{i18n.t('aksesdivisikaryawan')}</label><br />
                        <label>
                            <input type="checkbox"
                                checked={this.props.data.level_divisi_limited === null}
                                onChange={() => this.divisiHandler()} />
                            &nbsp;{i18n.t('seluruhaksesdivisi')}
                        </label>
                        {this.props.data.level_divisi_limited !== null &&
                            <Select name="level_divisi_limited"
                                style={{ width: '100%' }}
                                mode={"multiple"}
                                placeholder={i18n.t('pilihaksesdivisi')}
                                value={this.props.data.level_divisi_limited}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={(event) => {
                                    this.props.changeState({
                                        level_divisi_limited: event
                                    })
                                }}
                            >
                                {this.props.data.divisi.map((item, index) => {
                                    return (
                                        <Option value={item.divisi_id} key={item.divisi_id}>{item.divisi_name}</Option>
                                    )
                                })}
                            </Select>
                        }
                    </div>
                </Form>
            </Drawer>

        )
    }
}

export default EditUserLevel
