import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Swal from "sweetalert2";
import Paging from "../../components/paging";
import {Empty, Spin, Tooltip, Modal, Checkbox} from 'antd';
import {BACKEND_API_HOST, DEFAULT_IMG} from "../../helper/host";
import Edit from './edit'
import Add from "./add";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";
import { Helmet } from "react-helmet";
import { pageNumber } from "../../functions/functionGeneral";
import Switch from "react-switch";
import { toast } from "react-toastify";
import "../../assets/css/style.css"
import { clear_auth, set_auth } from "../../reducers/Auth";
import { connect } from "react-redux";
import ModalDefault from '../menu-guide/modal-default';
import EmptyDataImage from "../../assets/images/data-kosong.png";
import RefreshIcon from "../../assets/icons/refresh.svg";
import {Col, Modal as ModalRs, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import moment from "moment/moment";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg";
import ReactPaginate from "react-paginate";
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import ArrowRight from "../../assets/icons/arrow-right.svg";
import ImportIcon from "../../assets/icons/import-icon.svg";
import i18n from "i18next";
import SearchIcon from "../../assets/icons/search.svg";
import PlusIcon from "../../assets/icons/plus-white.svg";
import MoreIcon from "../../assets/icons/more.svg";
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';

export class ListShift extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            idDel: '',
            isLoading: true,
            pageCount: 0,
            currentPage: 0,
            totalData: 0,
            perPage: 0,
            paging: 20,
            pagination: 2,
            sift_name: '',
            sift_late_tolerance: '',
            sift_id: '',
            nama_jabatan: '',
            filter_nama: '',
            add_visible: false,
            visable: false,
            guide: false,
            shift_check: [],
            lokasi_check: [],
            divisi_check: [],
            karyawan_check: [],
            jam_kerja_check: [],
            confirm_modal_default: false,
            importFile: null,
            modalimport: false,
            modalHistory: false,
            dataHistory: [],
            historyLast: [],
            dataTrash: [],
            checked: [],
            loadingHistory: false,
            currentPageHistory: 0
        }
        this.handleChange = this.handleChange.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.handlePageHistory = this.handlePageHistory.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.wantDelete = this.wantDelete.bind(this)
        this.importExcel = this.importExcel.bind(this)
    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected }, () => {
            this.loadData();
        });
    }

    handleOk = e => {
        this.setState({
            modalimport: false
        });
    };

    handleCancel = e => {
        this.setState({
            modalimport: false
        });
    };

    handlePageHistory(dataHistory) {
        let selected = dataHistory.selected; // zero based index
        this.setState({ currentPageHistory: selected, dataHistory: [], loadingHistory: true }, () => {
            this.loadHistory();
        });
    }

    handlePageTrashHistory = (dataHistory) => {
        let selected = dataHistory.selected; // zero based index
        this.setState({ currentPageTrash: selected, dataTrash: [], loadingTrash: true }, () => {
            this.loadTrash();
        });
    }

    resetFilter(event) {
        this.setState({
            filter_nama: '',
            data: [],
            isLoading: true
        }, () => this.loadData())
    }


    wantDelete() {
        Swal.fire({
            title: i18n.t("pemberitahuan"),
            text: i18n.t("andayakinhapus"),
            icon: 'warning',
            confirmButtonText: i18n.t("ya"),
            showCancelButton: true,
            cancelButtonText: i18n.t("batal"),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        isLoading: true,
                        data: []
                    })
                    let form = {
                        id: this.state.idDel
                    }
                    this.props.request("shift/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                const filter_data = JSON.parse(localStorage.getItem('Presensi-Company-Data'));
                                filter_data._sift = filter_data._sift.filter(sift => sift.sift_id !== this.state.idDel);
                                localStorage.setItem('Presensi-Company-Data', JSON.stringify(filter_data));
                                Swal.fire({
                                    title: i18n.t("berhasil"),
                                    text: i18n.t(response.message),
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.setState({
                                    isLoading: true,
                                    data: []
                                })
                                this.props.set_auth(this.props.api_token, response.perusahaan);
                                this.loadData()
                                this.loadCheck()
                            }
                        });
                }
            })
    }

    loadData() {
        this.setState({isLoading: true})
        let form = {
            filter_nama: this.state.filter_nama,
            paginate: 10,
            page: this.state.currentPage + 1,
        }
        this.props.request("shift/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        isLoading: false,
                    })
                }
            });
    }
    loadCheck() {
        let form = {
            perusahaan_id: this.props.user.perusahaan_id
        }
        this.props.request("check-guide-data", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        shift_check: response.shift,
                        lokasi_check: response.lokasi,
                        divisi_check: response.divisi,
                        karyawan_check: response.karyawan,
                        jam_kerja_check: response.jam_kerja
                    }, () => {
                        if (this.state.shift_check.length === 0 || this.state.lokasi_check.length === 0 || this.state.divisi_check.length === 0 || this.state.karyawan_check.length === 0 || this.state.jam_kerja_check.length === 0) {
                            this.setState({
                                confirm_modal_default: true
                            })
                        }
                    })
                }
            })
    }

    exportShift = () => {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'shift/export_template?token=' + AuthKey, "_blank")
    }

    importExcel() {
        this.setState({
            importLoading: true
        })

        var AuthKey = localStorage.getItem('Presensi-Auth-Key')

        let formData = new FormData()
        formData.append('file', this.state.importFile)
        formData.append('token', AuthKey)

        fetch(BACKEND_API_HOST + 'shift/import', {
            method: 'POST',
            headers: {
                'Authorization': 'bearer ' + AuthKey
            },
            body: formData
        })
            .catch((error) => {
                console.error(error);
            })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t("berhasil"),
                        text: i18n.t(response.message),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                    window.location.reload();
                    this.setState({
                        modalimport: false,
                        loading: true,
                        importFile: null,
                        data: [],
                    }, () => {
                        this.loadData()
                    })
                }
                else {
                    Swal.fire({
                        title: i18n.t("gagal"),
                        text: i18n.t(response.message),
                        icon: 'warning',
                        showConfirmButton: false,
                        timer: 1200,
                    })
                    this.setState({
                        modalimport: false,
                    })
                }

                this.setState({ importLoading: false })
            })
    }

    loadHistory(id) {
        this.setState({
            dataHistory: [],
            loadingHistory: true
        }, () => {
            let form = {
                id: id ? id : this.state.sift_id,
                paging: this.state.pagination,
                page: this.state.currentPageHistory + 1,
            };

            this.props.request("shift/history", form, 'POST')
                .then(response => {
                    this.setState({
                        loadingHistory: false
                    })
                    if (response.succes) {
                        this.setState({
                            dataHistory: response.data.data,
                            historyLast: response.history,
                            pageCountHistory: response.data.last_page,
                            totalDataHistory: response.data.total,
                            totalPerpageHistory: response.data.to,
                            perPageHistory: response.data.per_page,
                        });
                    } else {
                        this.setState({
                            modalHistory: false,
                            sift_id: ''
                        });
                    }
                });
        })
    }

    handleRevert = () => {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('yakinmengembalikandata'),
            icon: "warning",
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        sift_id: this.state.sift_id,
                        history_id: this.state.history_id,
                    }
                    this.props.request("shift/revert", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: i18n.t(response.message),
                                    icon: 'success'
                                })
                                this.loadData()
                                this.setState({
                                    modalHistory: false,
                                    checked: []
                                })
                            } else {
                                Swal.fire({
                                    title: i18n.t('gagal'),
                                    text: i18n.t(response.message),
                                    icon: 'warning',
                                    confirmButtonText: 'OK'
                                })
                            }
                        })
                    }
                }
            )
    }

    loadTrash() {
        this.setState({
            loadingHistory: true,
        }, () => {
            let form = {
                page: this.state.currentPageTrash + 1,
                paginate: 5,
            };
            this.props.request('shift/trash', form, 'POST')
                .then(response => {
                    if (response.success) {
                        this.setState({
                            dataTrash: response.data.data,
                            pageCountTrash: response.data.last_page,
                            totalDataTrash: response.data.total,
                            totalPerpageTrash: response.data.to,
                            perPageTrash: response.data.per_page,
                            loadingTrash: false
                        })
                    }
                })
        })
    }

    HandleRestore= () => {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: `${this.state.checked.length} ${i18n.t('dataakandipulihkan')}`,
            iconHtml: '<i style="color: #8c2bee;" class="fa fa-refresh"></i>',
            confirmButtonText: i18n.t('restore'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        sift_id: this.state.checked,
                    }
                    this.props.request('shift/restore', form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: i18n.t(response.message),
                                    icon: "success",
                                    showConfirmButton: false,
                                    timer: 900,
                                })
                                this.loadData()
                                this.setState({
                                    modaltrash: false,
                                    checked: [],
                                    checkAll: false,
                                })
                            }
                        })
                }
            })
    }

    HandleDeletePermanent = () => {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: `${this.state.checked.length} ${i18n.t('dataakandihapuspermanen')}`,
            iconHtml: '<i style="color: #eb4034;" class="fa fa-trash"></i>',
            confirmButtonText: i18n.t('hapus'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        sift_id: this.state.checked,
                    }
                    this.props.request('shift/delete-permanent', form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: i18n.t(response.message),
                                    icon: "success",
                                    showConfirmButton: false,
                                    timer: 900,
                                })
                                this.loadData()
                                this.setState({
                                    modaltrash: false,
                                    checked: [],
                                    checkAll: false,
                                })
                            }
                        })
                }
            })
    }

    handleCheckBoxChangeTrash = (id)=> {
        this.state.checked.includes(id) ?
            this.setState(prevState => ({
                checked: prevState.checked.filter(currId => currId !== id),
            }), () => {
                if (this.state.checked.length !== this.state.totalDataTrash) {
                    this.setState({
                        checkAll: false
                    })
                }
            })
            :
            this.setState(prevState => ({
                checked: [...prevState.checked, id],
            }), () => {
                if (this.state.checked.length === this.state.totalDataTrash) {
                    this.setState({
                        checkAll: true
                    })
                }
            })
    }

    handleCheckBoxAllChangeTrash = () => {
        this.setState(prevState => ({
            checkAll: !prevState.checkAll
        }), () => {
            this.state.checkAll
                ? this.setState({
                    checked: this.state.dataTrash.map(item => item.sift_id)
                })
                : this.setState({
                    checked: []
                })
        })
    }

    componentDidMount() {
        this.loadData()
        this.loadCheck()
    }

    render() {
        let no = 0;
        const steps = [
            {
                selector: '.tambah-shift',
                content: 'Anda dapat membuat Shift disini',
            },
            {
                selector: '.daftar-shift',
                content: 'Daftar Shift anda dapat dilihat disini',
            },
        ];
        const style = {
            card_header: {
                backgroundColor: "#FAFDFF",
                color: "#143B5E",
                padding: "12px 24px",
                fontSize: "24px",
                lineHeight: "36px",
                fontWeight: 700
            },
            button_refresh: {
                padding: "8px",
                border: "1px solid #DDDDDD",
                borderRadius: "8px",
                backgroundColor: "#FFFFFF"
            },
            filter_nama: {
                backgroundColor: "#F9F9F9",
                padding: "12px 10px",
                color: "#5D6F80",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "16px"
            },
            th_style: {
                borderBottom: "0px",
                padding: "16px 12px",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "24px",
                color: "#252B2B"
            },
            td_style: {
                padding: "16px 12px",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "24px",
                color: "#252B2B"
            }
        }
        const style_add = {
            modal_header: {
                backgroundColor: "#FAFDFF",
                padding: "12px",
            },
            title_header: {
                color: "#143B5E",
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "36px"
            },
            label_input: {
                color: "#143B5E",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "24px"
            },
            input_group_text: {
                backgroundColor: "#EFEFEF",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 500,
                padding: "12px"
            },
            input: {
                backgroundColor: "#FFFFFF",
                border: "1px solid #DDDDDD",
                borderRadius: "4px",
                padding: "12px",
                fontSize: "12px",
                lineHeight: "18px",
                fontWeight: 400
            },
            select: {
                backgroundColor: "#FFFFFF",
                border: "1px solid #DDDDDD",
                borderRadius: "4px",
                padding: "12px",
                minHeight: "44px",
                fontSize: "12px",
                lineHeight: "18px",
                fontWeight: 400
            },
            card_header: {
                backgroundColor: "#FAFDFF",
                padding: "10px 16px",
                color: "#143B5E",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "24px"
            },
            card_body: {
                backgroundColor: "#F9F9F9",
                padding: "12px 16px"
            },
            column_card_body: {
                backgroundColor: "#F9F9F9",
                textAlign: "center"
            },
            hari: {
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "24px",
                color: "#252B2",
                textAlign: "left",
                margin: "0px"
            },
            time_input: {
                padding: "12px",
                fontSize: "14px",
                color: "#252B2B",
                backgroundColor: "#FFFFFF",
                lineHeight: "16px",
                width: "88px"
            }
        }
        if (!this.props.user.user_level.level_page_limited || this.props.user.user_level.level_page_limited.search("Shift") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{i18n.t("daftarshift")} - iPresens</title>
                        <meta name="description" content="Daftar Shift" />
                    </Helmet>
                    <Edit
                        data={this.state}
                        request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                        changeState={(state, callback) => {
                            this.setState(state, () => {
                                if (typeof callback != 'undefined')
                                    callback()
                            })
                        }}
                        loadData={() => this.loadData()}
                    />
                    <Add
                        add_visible={this.state.add_visible}
                        request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                        changeState={(state, callback) => {
                            this.setState(state, () => {
                                if (typeof callback != 'undefined')
                                    callback()
                            })
                        }}
                        loadData={() => this.loadData()}
                        loadCheck={() => this.loadCheck()}
                    />
                    <Breadcrumb parent={i18n.t("manajemenshift")} title={i18n.t("daftarshift")} setGuide={() => { this.setState({ guide: true }) }} />
                    <Tour steps={steps} isOpen={this.state.guide === true} onAfterOpen={this.disableBody} onBeforeClose={this.enableBody} onRequestClose={() => this.setState({ guide: false })} />
                    <ModalDefault
                        jam_kerja={this.state.jam_kerja_check}
                        shift={this.state.shift_check}
                        lokasi={this.state.lokasi_check}
                        divisi={this.state.divisi_check}
                        karyawan={this.state.karyawan_check}
                        confirm_modal_default={this.state.confirm_modal_default}
                        setState={(name, val) => this.setState(name, val)}
                        history={this.props.history}
                    />
                    <Modal
                        title="Import Shift"
                        visible={this.state.modalimport}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        footer={[
                            this.state.importLoading ?
                                <button className={'btn ipresens-btn-color'} style={{textTransform: "none", width: "100%"}} disabled={true}><i className="fa fa-refresh fa-spin"></i> {i18n.t("sedangmenguploaddata")}</button>
                                :
                                <button className={this.state.importFile === null ? 'btn bg-secondary-custom' : 'btn ipresens-btn-color'} style={{textTransform: "none", width: "100%"}} disabled={this.state.importFile === null} onClick={this.importExcel}>Import Data</button>
                        ]}
                    >
                        <div>
                            <a style={{ color: "#5D6F80", cursor: "default" }}>{i18n.t("importcutitext")}</a><br />
                            <a onClick={this.exportShift} style={{ color: "#057CE4" }}>{i18n.t("importcutitemplates")}</a>
                            <div style={{ position: 'relative', width: '100%', backgroundColor: '#F9F9F9', padding: 16, borderRadius: 6, marginTop: 8, border: "1px dashed #DDDDDD" }}>
                                <input type='file'
                                       accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                       onChange={(event) => {
                                           this.setState({
                                               importFile: event.target.files[0],
                                           })
                                       }} /><br />
                            </div>
                        </div>
                    </Modal>
                    <ModalRs size="lg" style={{ maxWidth: "1000px" }} isOpen={this.state.modalHistory} toggle={() => this.setState({ modalHistory: false, currentPageHistory: 0 })}>
                        <ModalHeader style={{ backgroundColor: "#FAFDFF" }} toggle={() => this.setState({ modalHistory: false, currentPageHistory: 0 })}>
                            <div className='modal-header-reactstrap'>{i18n.t("riwayatperubahan")}</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="list-group">
                                <div>
                                    {this.state.loadingHistory && (
                                        <div style={{ textAlign: 'center', margin: '10px auto' }}>
                                            <Spin
                                                size="large"
                                                tip="Loading..."
                                                style={{
                                                    width: '100%',
                                                    margin: 'auto',
                                                }}
                                            />
                                        </div>
                                    )}

                                    {this.state.dataHistory.length !== 0 && (
                                        <div>
                                            <Row>
                                                <Col xs={0} md={0} className="text-left" style={{ marginLeft: '20px' }}>
                                                    <h4>User</h4>
                                                </Col>
                                                <Col xs={0} md={0} className="text-left" style={{ marginRight: '20px' }}>
                                                    <h4>{i18n.t("aktivitas")}</h4>
                                                </Col>
                                            </Row>

                                            {this.state.dataHistory.map((item, index) => {
                                                return(
                                                    <div key={index} style={{ marginBottom: '20px' }}>
                                                        <Row>
                                                            <Col xs={12} md={6} className="text-left">
                                                                <div className="d-flex align-items-center"
                                                                     style={{
                                                                         padding: '16px 12px',
                                                                     }}>
                                                                    <div style={{marginRight: '16px'}}>
                                                                        <img
                                                                            src={DEFAULT_IMG}
                                                                            alt=""
                                                                            style={{
                                                                                width: '48x',
                                                                                marginBottom: '25px',
                                                                                height: '48px'
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div style={{flex: 1}}>
                                                                        <h6 className="mb-0 font-13"
                                                                            style={{
                                                                                fontWeight: 500,
                                                                                fontSize: '16px',
                                                                                lineHeight: '24px',
                                                                            }}
                                                                        >
                                                                            {item.p_user_name} | {item.level_name}
                                                                        </h6>
                                                                        <p className="m-0 font-12">
                                                                            <span className="col-green font-weight-bold">
                                                                                {moment(item.created_at).format('lll')}
                                                                            </span>
                                                                        </p>
                                                                        <div className="d-flex mt-3">
                                                                            {item.history_status === "created" ? (
                                                                                <div className="d-flex justify-content-between w-75">
                                                                                    <div className="col d-flex ipresens-text-color justify-content-center align-items-center mr-2 py-2"
                                                                                        style={{
                                                                                            border: '1px solid #000',
                                                                                            borderRadius: '25px',
                                                                                            borderColor: '#7805e4',
                                                                                        }}>
                                                                                        {i18n.t('dibuat')}
                                                                                    </div>
                                                                                    {this.state.historyLast.history_id === item.history_id ?
                                                                                        "" :
                                                                                        <div
                                                                                            className="col d-flex justify-content-center align-items-center ipresens-btn-revert py-2"
                                                                                            onClick={() => {
                                                                                                this.setState({
                                                                                                    sift_id: item.sift_id,
                                                                                                    history_id: item.history_id,
                                                                                                });
                                                                                                this.handleRevert();
                                                                                            }}
                                                                                        >
                                                                                            {i18n.t('revert')}
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            ) : item.history_status === "reverted" ? (
                                                                                <div className="w-75 text-center py-2"
                                                                                     style={{
                                                                                         padding: '6px 0',
                                                                                         borderRadius: '25px',
                                                                                         color: '#7805e4',
                                                                                         border: '1px solid #7805e4',
                                                                                     }}
                                                                                >
                                                                                    {i18n.t('dikembalikan')}
                                                                                </div>
                                                                            ) : (
                                                                                <div className="d-flex justify-content-between w-75">
                                                                                    <div className="col d-flex justify-content-center align-items-center border border-danger text-danger mr-2 py-2"
                                                                                        style={{
                                                                                            borderRadius: '25px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t('diubah')}
                                                                                    </div>
                                                                                    {this.state.historyLast.history_id === item.history_id ?
                                                                                        <div className="col"></div>
                                                                                        :
                                                                                        <div className="col d-flex justify-content-center align-items-center ipresens-btn-revert py-2"
                                                                                            style={{
                                                                                                border: '1px solid #000',
                                                                                                borderRadius: '25px',
                                                                                                color: '#7805e4',
                                                                                                borderColor: '#7805e4',
                                                                                            }}
                                                                                            onClick={() => {
                                                                                                this.setState({
                                                                                                    sift_id: item.sift_id,
                                                                                                    history_id: item.history_id,
                                                                                                });
                                                                                                this.handleRevert();
                                                                                            }}
                                                                                        >
                                                                                            {i18n.t('revert')}
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} md={6} className="text-left" style={{
                                                                paddingTop: '16px',
                                                                paddingBottom: '16px',
                                                                paddingRight: '12px',
                                                                paddingLeft: '12px'
                                                            }}>
                                                                {item.hitory_status !== 'created' && (
                                                                    <div>
                                                                        {item.parent_history ?
                                                                            item.parent_history.sift_name != item.sift_name && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t("namasiftdari")}{' '}
                                                                                        <b>{item.parent_history
                                                                                            ? item.parent_history.sift_name
                                                                                            : 'N/A'}</b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t("jadi")} : <b>{item.sift_name ? item.sift_name : ''}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ) : null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.sift_late_tolerance != item.sift_late_tolerance && (
                                                                                <div>
                                                                                <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t("toleransitelatdari")}{' '}
                                                                                        <b>{item.parent_history
                                                                                            ? item.parent_history.sift_late_tolerance
                                                                                            : 'N/A'}</b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t("jadi")} : <b>{item.sift_late_tolerance ? item.sift_late_tolerance : ''}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ) : null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.sift_status != item.sift_status && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t("statussiftdari")}{' '}
                                                                                        <b>{item.parent_history
                                                                                            ? item.parent_history.sift_status === "1" ? i18n.t("aktif") : i18n.t("nonaktif")
                                                                                            : 'N/A'}</b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t("jadi")} : <b>{item.sift_status ? item.sift_status === "1" ? i18n.t("aktif") : i18n.t("nonaktif") : ''}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                    </div>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{ backgroundColor: "#F5F5F5" }}>
                            <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                                {this.state.dataHistory.length !== 0 &&
                                    <>
                                        <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>{i18n.t("menampilkan")} {this.state.dataHistory.length === 0 || this.state.dataHistory.length === null ? "0" : this.state.dataHistory.length} {i18n.t("dari")} {this.state.totalDataHistory} data</div>
                                        <div className='col'>
                                            <ReactPaginate
                                                previousLabel={<img src={ArrowLeft} alt='' />}
                                                nextLabel={<img src={ArrowRight} alt='' />}
                                                pageCount={this.state.pageCountHistory}
                                                forcePage={this.state.currentPageHistory}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={this.handlePageHistory}
                                                breakClassName={'page-item'}
                                                breakLinkClassName={'page-link custom-page-link'}
                                                containerClassName={'pagination my-2'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link custom-page-link'}
                                                previousClassName={'page-item'}
                                                previousLinkClassName={'page-link custom-prev-next-link'}
                                                nextClassName={'page-item'}
                                                nextLinkClassName={'page-link custom-prev-next-link'}
                                                activeClassName={'active custom-active'}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </ModalFooter>
                    </ModalRs>

                    <ModalRs size="lg" style={{ maxWidth: "1000px" }} isOpen={this.state.modaltrash} toggle={() => this.setState({ modaltrash: false, checked: [], checkAll: false,})}>
                        <ModalHeader className='pengumuman-modal-header' toggle={() => this.setState({modaltrash: false, checked: [], checkAll: false,})}>
                            <div className='pengumuman-modal-header-font'>{i18n.t("trashshift")}</div>
                        </ModalHeader>
                        <ModalBody className="pengumuman-modal-body">
                            <div className="d-flex justify-content-end">
                                <button className="btn ipresens-btn-color mr-2 text-capitalize" disabled={this.state.checked.length === 0} onClick={this.HandleRestore}>
                                    {i18n.t('restore')}
                                </button>
                                <button className="btn ipresens-btn-color text-capitalize" disabled={this.state.checked.length === 0} onClick={this.HandleDeletePermanent}>
                                    {i18n.t('hapuspermanent')}
                                </button>
                            </div>
                            <div className="list-group">
                                <div>
                                    {!this.state.loadingTrash && this.state.dataTrash.length === 0 ?
                                        <Empty description="Data Kosong"
                                               image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                        :
                                        <>
                                            <Table className="table table-borderless table-striped mb-5">
                                                <Thead>
                                                    <Tr>
                                                        <Th width="30"><Checkbox checked={this.state.checkAll} onChange={this.handleCheckBoxAllChangeTrash}></Checkbox></Th>
                                                        <Th className="lembur-th-style">{i18n.t("namashift")}</Th>
                                                        <Th className="lembur-th-style">{i18n.t("toleransitelat")}</Th>
                                                        <Th className="lembur-th-style">Status</Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {this.state.loadingTrash ?
                                                        <Tr>
                                                            <Td colSpan="9" style={{
                                                                textAlign: "center",
                                                                backgroundColor: "#FFFFFF"
                                                            }}>
                                                                <Spin size="large"
                                                                      tip={i18n.t("sedangmemuatdata") + ' ...'}
                                                                      style={{
                                                                          width: '100%',
                                                                          margin: 'auto',
                                                                          marginBottom: '10px',
                                                                          marginTop: '10px'
                                                                      }}/>
                                                            </Td>
                                                    </Tr>
                                                    :
                                                        this.state.dataTrash.map((item) => {
                                                            return (
                                                                <Tr>
                                                                    <Td><Checkbox checked={this.state.checked.includes(item.sift_id)} onChange={() => this.handleCheckBoxChangeTrash(item.sift_id)}></Checkbox></Td>
                                                                    <Td className="lembur-td-style" align='left'>{item.sift_name}</Td>
                                                                    <Td className="lembur-td-style" align='left'>{item.sift_late_tolerance}</Td>
                                                                    <Td className="lembur-td-style" align='left'>{item.sift_status === "1" ? i18n.t("aktif") : i18n.t("nonaktif")}</Td>
                                                                </Tr>
                                                            );
                                                        })
                                                    }
                                                </Tbody>
                                            </Table>
                                        </>
                                    }
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{ backgroundColor: "#F5F5F5", borderBottomLeftRadius: "8px", borderBottomRightRadius: "8px" }}>
                            <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                                {this.state.dataTrash.length !== 0 &&
                                    <>
                                        <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>{i18n.t("menampilkan")} {this.state.dataTrash.length === 0 || this.state.dataTrash.length === null ? "0" : this.state.dataTrash.length} {i18n.t("dari")} {this.state.totalDataHistory} {i18n.t("data")}</div>
                                        <div className='col'>
                                            <Paging
                                                className="float-right"
                                                handlePageClick={this.handlePageTrashHistory}
                                                pageCount={this.state.pageCountTrash}
                                                currentPage={this.state.currentPageTrash}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </ModalFooter>
                    </ModalRs>

                    <div className="container-fluid">
                        <div className="card" style={{ border: "1px solid #DDDDDD" }}>
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className='d-flex flex-row mb-2'>
                                            <div className='flex-fill pr-2'>
                                                <div className='input-group btn-srch-kbeban'>
                                                <span
                                                    className='input-group-text izin-search-karyawan-img'>
                                                    <img alt='' src={SearchIcon}/>
                                                </span>
                                                    <input type='text' onChange={this.handleChange}
                                                           name='filter_nama' value={this.state.filter_nama}
                                                           className='form-control izin-search-karyawan-form'
                                                           placeholder={i18n.t("namashift") + "..."}
                                                           style={style.filter_nama} onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            this.loadData()
                                                        }
                                                    }}></input>
                                                </div>
                                            </div>
                                            <div>
                                                <button className='btn ipresens-btn-reset border px-2'>
                                                    <img alt="" src={RefreshIcon} onClick={(e) => {
                                                        this.setState({
                                                            filter_nama: ""
                                                        }, () => this.loadData())
                                                    }}/></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7 d-flex justify-content-end col-btn-add-absn flex-wrap">
                                        <div className="flex-grow-1 mr-2">
                                            <button
                                                className="btn ipresens-btn-color text-capitalize width-filter pull-right"
                                                onClick={() => {
                                                    this.setState({add_visible: true});
                                                }}
                                            >
                                                <img src={PlusIcon} alt="Tambah Shift"/> {i18n.t('tambahshift')}
                                            </button>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <Tooltip zIndex={1} trigger={'click'} placement="bottomLeft" color={'#FFF'}
                                                     title={
                                                         <div className={'p-2'}>
                                                             <a onClick={() => {this.setState({modalimport: true})}}
                                                                style={{marginRight: "50px"}}
                                                                className={'text-dark'}>Import Excel
                                                             </a><br/>
                                                             <a onClick={() => {this.setState({modaltrash: true, currentPageTrash : 0},() => this.loadTrash())}}
                                                                style={{marginRight: "50px"}}
                                                                className={'text-dark'}>{i18n.t('trashshift')}
                                                             </a><br/>
                                                         </div>
                                                     }>
                                                <a>
                                                    <i className='btn border border-dark border-1 px-2'>
                                                        <img alt="More" src={MoreIcon}/>
                                                    </i>
                                                </a>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='table-responsive'>
                                            {!this.state.isLoading && this.state.data.length === 0 ?
                                                <Empty description={i18n.t("datakosong")}
                                                       image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                                :
                                                <table className='table table-striped table-borderless mb-5'>
                                                    <thead>
                                                    <tr>
                                                        <th className='lembur-th-style' style={{maxWidth: "30px"}}>#
                                                        </th>
                                                        <th className='lembur-th-style mr-2'>{i18n.t('shift')}</th>
                                                        <th className='lembur-th-style'>{i18n.t("toleransitelat")}</th>
                                                        <th className='lembur-th-style'>Status</th>
                                                        <th className='lembur-th-style text-center'></th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.isLoading ?
                                                            <tr>
                                                                <td colSpan={5} style={{
                                                                    textAlign: "center",
                                                                    backgroundColor: "#FFFFFF"
                                                                }}>
                                                                    <Spin size="large"
                                                                          tip={i18n.t("sedangmemuatdata") + ' ...'}
                                                                          style={{
                                                                              width: '100%',
                                                                              margin: 'auto',
                                                                              marginBottom: '10px',
                                                                              marginTop: '10px'
                                                                          }}/>
                                                                </td>
                                                            </tr>
                                                            :
                                                            this.state.data.map((item, index) => {
                                                                no += 1
                                                                return (
                                                                    <tr>
                                                                        <td style={style.td_style}>{pageNumber(no, this.state.perPage, this.state.currentPage)}</td>
                                                                        <td style={{...style.td_style,fontWeight: 700,
                                                                            fontSize: "14px",
                                                                            color: "#252B2B",
                                                                            lineHeight: "24px"}}>{item.sift_name}</td>
                                                                        <td style={style.td_style}>{item.sift_late_tolerance}</td>
                                                                        <td style={{
                                                                            ...style.td_style,
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            padding: 22,}}>
                                                                            <Switch
                                                                                id={`detail-${item.sift_id}`}
                                                                                name="sift_status"
                                                                                checked={item.sift_status === '1'}
                                                                                onChange={(val) => {
                                                                                    this.setState({
                                                                                        loading: true,
                                                                                        data: []
                                                                                    });

                                                                                    const formData = {id: item.sift_id};

                                                                                    this.props
                                                                                        .request('shift/ubah-status', formData, 'POST')
                                                                                        .then((response) => {
                                                                                            if (response.success) {
                                                                                                const filter_data = JSON.parse(localStorage.getItem('Presensi-Company-Data'));
                                                                                                if (filter_data && filter_data._sift) {
                                                                                                    const updateShift = filter_data._sift.map((sift) =>
                                                                                                        sift.sift_id === item.sift_id
                                                                                                            ? {
                                                                                                                ...sift,
                                                                                                                sift_status: response.data.sift_status
                                                                                                            }
                                                                                                            : sift
                                                                                                    );
                                                                                                    const updateFilter = {
                                                                                                        ...filter_data,
                                                                                                        _sift: updateShift,
                                                                                                    };
                                                                                                    localStorage.setItem('Presensi-Company-Data', JSON.stringify(updateFilter));
                                                                                                }
                                                                                                Swal.fire({
                                                                                                    title: i18n.t("berhasil"),
                                                                                                    text: i18n.t(response.message),
                                                                                                    icon: 'success',
                                                                                                    showConfirmButton: false,
                                                                                                    timer: 1500,
                                                                                                });
                                                                                            } else {
                                                                                                if (Array.isArray(response.message)) {
                                                                                                    response.message.forEach((msg) => toast.error(msg));
                                                                                                } else {
                                                                                                    toast.error(response.message);
                                                                                                }
                                                                                            }
                                                                                            this.loadData();
                                                                                            this.setState({loading: false});
                                                                                        });
                                                                                }}
                                                                                onColor="#8c2bee"
                                                                                offColor="#eb4034"
                                                                                handleDiameter={18}
                                                                                uncheckedIcon={false}
                                                                                checkedIcon={false}
                                                                                boxShadow="0px 1px 1px rgba(0, 0, 0, 0.6)"
                                                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                                height={24}
                                                                                width={48}
                                                                                className="mr-1"
                                                                            />
                                                                            <span style={{
                                                                                color: item.sift_status === '1' ? '#8c2bee' : '#5D6F80',
                                                                                fontSize: '14px'
                                                                            }}>
                                                                                {item.sift_status === '1' ? i18n.t('aktif') : i18n.t('nonaktif')}
                                                                            </span>
                                                                        </td>
                                                                        <td style={style.td_style} className='text-center'>
                                                                            <Tooltip trigger={'click'}
                                                                                     placement="bottom"
                                                                                     color={'#fff'} title={
                                                                                <div className={'text-dark'}>
                                                                                    <a onClick={() => {
                                                                                        this.setState({
                                                                                            modalHistory: true,
                                                                                            sift_id: item.sift_id
                                                                                        }, () => {
                                                                                            this.loadHistory(item.sift_id);
                                                                                        })
                                                                                    }}>{i18n.t("riwayat")}</a><br/>
                                                                                    <a className={'text-dark'}
                                                                                       onClick={() => {
                                                                                           this.setState({
                                                                                               sift_id: item.sift_id,
                                                                                               sift_name: item.sift_name,
                                                                                               sift_late_tolerance: item.sift_late_tolerance,
                                                                                               visable: true
                                                                                           })
                                                                                       }}>{i18n.t("edit")}</a><br/>
                                                                                    <a className={'text-danger'}
                                                                                       onClick={() => {
                                                                                           this.setState({
                                                                                               idDel: item.sift_id
                                                                                           })
                                                                                           this.wantDelete()
                                                                                       }}>{i18n.t("hapus")}</a>
                                                                                </div>
                                                                            }>
                                                                                <a><i
                                                                                    className="fa fa-ellipsis-v"
                                                                                    style={{
                                                                                        width: 35,
                                                                                        fontSize: 18,
                                                                                        padding: 11,
                                                                                        color: 'rgb(51 51 51)'
                                                                                    }}
                                                                                ></i></a>
                                                                            </Tooltip>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            }
                                            <Paging
                                                className="float-right"
                                                handlePageClick={this.handlePageClick}
                                                pageCount={this.state.pageCount}
                                                currentPage={this.state.currentPage}
                                            />
                                            <div className='col font-14' style={{
                                                padding: "auto",
                                                margin: "auto",
                                                fontWeight: 500,
                                                lineHeight: "24px"
                                            }}>
                                                {i18n.t("menampilkan")} {this.state.data.length === 0 || this.state.data.length === null ? "0" : this.state.data.length} {i18n.t("dari")} {this.state.totalData} {i18n.t("data")}.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">{i18n.t("tidakadaakseshalaman")}</h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token,
    sift: state.Filter._sift
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ListShift);
