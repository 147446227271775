import React, {Component, Fragment} from "react";
import {Helmet} from "react-helmet";
import {clear_auth, set_auth} from "../../reducers/Auth";
import {connect} from "react-redux";
import i18n from "i18next";
import SearchIcon from "../../assets/icons/search.svg";
import RefreshIcon from "../../assets/icons/refresh.svg";
import PlusIcon from "../../assets/icons/plus-white.svg";
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table";
import AddBanner from "./add";
import EmptyDataImage from "../../assets/images/data-kosong.png";
import {Checkbox, Empty, Form, Spin, Tooltip, Upload} from "antd";
import {pageNumber} from "../../functions/functionGeneral";
import {API_HOST, DEFAULT_IMG, IMG_HOST} from "../../helper/host";
import Paging from "../paging";
import {Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import ImgCrop from "antd-img-crop";
import ImportIcon from "../../assets/icons/import-icon-blue-light.svg";
import Swal from "sweetalert2";
import moment from "moment/moment";
import ArrowRight from "../../assets/icons/arrow-right.svg";
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import ReactPaginate from 'react-paginate';
import MoreIcon from "../../assets/icons/more.svg";
import Restore from "../../assets/icons/update.svg";
import Trash from "../../assets/icons/trash.svg";

export class List_banner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            add_visible: false,
            modal_edit: false,
            visable: false,
            modal_history: false,
            modalTrash: false,
            loading_history: false,
            data: [],
            banner: [],
            history: [],
            trashHistory: [],
            historyLast: [],
            filter_judul: '',
            paginate: 5,
            currentPage: 0,
            pageCount: 0,
            totalData: 0,
            perPage: 0,
            idDetail: 0,
            idDetele: 0,
            fileList: [],
            gambar: '',
            banner_id: 0,
            perusahaan_id: 0,
            banner_desc: '',
            banner_start_date: '',
            banner_end_date: '',
            banner_img: '',
            id_delete: 0,
            id_history: 0,
            id_bannerhistory: 0,
            currentPageHistory: 0,
            checked: [],
            checkAll: false,
            restore: false,
            permanentDelete: false,
        }
        this.resetFilter = this.resetFilter.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.toDelete = this.toDelete.bind(this);
        this.handlePageHistory = this.handlePageHistory.bind(this);
        this.handlePageTrashHistory = this.handlePageTrashHistory.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.handleCheckBoxAllChange = this.handleCheckBoxAllChange.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    resetFilter() {
        this.setState({
            filter_judul: '',
            data: [],
            loading: true
        }, () => this.loadData())
    }

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({currentPage: selected, loading: true, data: []}, () => {
            this.loadData();
        });
    }

    handlePageHistory(history) {
        let selected = history.selected;
        this.setState({currentPageHistory: selected, history: [], loadingHistory: true}, () => {
            this.loadHistory();
        });
    }

    handlePageTrashHistory(trashHistory) {
        let selected = trashHistory.selected;
        this.setState({currentPageHistory: selected, trashHistory: [], loadingHistory: true}, () => {
            this.loadTrash();
        });
    }

    loadData() {
        this.setState({
            loading: true
        })
        let form = {
            filter: this.state.filter_judul,
            paginate: this.state.paginate,
            page: this.state.currentPage + 1,
        }
        this.props.request('banner/list', form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                    })
                }
                this.setState({
                    loading: false
                })
            })
    }

    loadDetail() {
        let form = {
            perusahaan_id: this.state.perusahaan_id,
            banner_id: this.state.banner_id,
        }
        this.props.request("banner/detail", form, 'POST')
            .then((response) => {
                if (response.success) {
                    this.setState({
                        banner: response.data,
                        banner_id: response.data.banner_id,
                        banner_desc: response.data.banner_desc,
                        banner_start_date: response.data.banner_start_date,
                        banner_end_date: response.data.banner_end_date,
                    })
                    if (response.data.banner_image !== null) {
                        this.setState({
                            fileList: [
                                {
                                    uid: "-1",
                                    name: response.data.banner_image,
                                    status: "done",
                                    url: IMG_HOST + 'mitra/banner/' + response.data.banner_image
                                }
                            ],
                        })
                    }
                }
            })
    }

    handleSubmitEdit = () => {
        let form = {
            banner_id: this.state.banner_id,
            banner_desc: this.state.banner_desc,
            banner_start_date: this.state.banner_start_date,
            banner_end_date: this.state.banner_end_date,
            banner_image: this.state.banner_img === null ? this.state.banner.banner_image : this.state.banner_img,
        }
        this.props.request("banner/edit", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('berhasilmengubahdata'),
                        icon: 'success'
                    })
                    this.props.history.push('/banner')
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t('gagallmengubahdata'),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    modal_edit: false,
                    fileList: [],
                    banner_img: '',
                    banner_desc: '',
                    banner_start_date: '',
                    banner_end_date: ''
                }, () => this.loadData())
            })
    }

    handleSubmitRevert = () => {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('yakinmengembalikanbanner'),
            icon: "warning",
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                    if (result.isConfirmed) {
                        let form = {
                            banner_id: this.state.id_bannerhistory,
                            history_id: this.state.id_history,
                        }
                        this.props.request("banner/revert", form, 'POST')
                            .then(response => {
                                if (response.success) {
                                    Swal.fire({
                                        title: i18n.t('berhasil'),
                                        text: response.message,
                                        icon: 'success'
                                    })
                                    this.setState({
                                        modal_history: false
                                    })
                                    this.props.history.push('/banner')
                                    this.loadData()
                                } else {
                                    Swal.fire({
                                        title: i18n.t('gagal'),
                                        text: response.message,
                                        icon: 'warning',
                                        confirmButtonText: 'OK'
                                    })
                                }
                            })
                    }
                }
            )
    }


    toDelete = () => {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('yakinmenghapusbanner'),
            icon: "warning",
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        id_banner: this.state.id_delete,
                        id_perusahaan: this.state.perusahaan_id,
                    }
                    this.setState({
                        data: [], loading: true,
                    })
                    this.props.request('banner/delete', form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: i18n.t('databerhasildihapus'),
                                    icon: "success",
                                    showConfirmButton: false,
                                    timer: 900,
                                })
                                this.loadData()
                            }
                        })
                }
            })
    }

    loadHistory(id) {
        this.setState({
            history: [],
            loading_history: true,
        }, () => {
            let form = {
                banner_id: this.state.banner_id,
                perusahaan_id: this.state.perusahaan_id,
                page: this.state.currentPageHistory + 1,
                paginate: 2,
            };
            this.props.request('banner/history', form, 'POST')
                .then(response => {
                    if (response.success) {
                        this.setState({
                            history: response.data.data,
                            historyLast: response.history,
                            pageCountHistory: response.data.last_page,
                            totalDataHistory: response.data.total,
                            totalPerpageHistory: response.data.to,
                            perPageHistory: response.data.per_page,
                            loading_history: false
                        })
                    } else {
                        this.setState({
                            modal_history: false, banner_id: 0, perusahaan_id: 0,
                            modalTrash: false,
                        })
                    }
                })
        })
    }

    loadTrash(id) {
        this.setState({
            trashHistory: [],
            loading_history: true,
        }, () => {
            let form = {
                perusahaan_id: this.state.perusahaan_id,
                page: this.state.currentPageHistory + 1,
                paginate: 4,
            };
            this.props.request('banner/trash', form, 'POST')
                .then(response => {
                    if (response.success) {
                        this.setState({
                            trashHistory: response.data.data,
                            pageCountHistory: response.data.last_page,
                            totalDataHistory: response.data.total,
                            totalPerpageHistory: response.data.to,
                            perPageHistory: response.data.per_page,
                            loading_history: false
                        })
                    } else {
                        this.setState({
                            modal_history: false, banner_id: 0, perusahaan_id: 0,
                            modalTrash: false,
                        })
                    }
                })
        })
    }

    handleCheckBoxAllChange() {
        this.setState(prevState => ({
            checkAll: !prevState.checkAll
        }), () => {
            this.state.checkAll
                ? this.setState({
                    checked: this.state.trashHistory.map(item => item.banner_id)
                })
                : this.setState({
                    checked: []
                })
        })
    }

    handleCheckBoxChange(id) {
        this.state.checked.includes(id) ?
            this.setState(prevState => ({
                checked: prevState.checked.filter(currId => currId !== id),
            }), () => {
                if (this.state.checked.length !== this.state.totalDataHistory) {
                    this.setState({
                        checkAll: false
                    })
                }
            })
            :
            this.setState(prevState => ({
                checked: [...prevState.checked, id],
            }), () => {
                if (this.state.checked.length === this.state.totalDataHistory) {
                    this.setState({
                        checkAll: true
                    })
                }
            })
    }

    bannerRestore = (id) => {
        let form = {
            banner_id: this.state.checked,
        }
        this.props.request('banner/restore', form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('databerhasildikembalikan'),
                        icon: "success",
                        showConfirmButton: false,
                        timer: 900,
                    })
                    this.loadData()
                    this.setState({
                        modalTrash: false,
                        checked: [],
                        restoreBanner: false,
                        checkAll: false,
                    })
                }
            })
    }

    deletePermanent = (id) => {
        let form = {
            banner_id: this.state.checked,
        }
        this.props.request('banner/delete-permanent', form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('databerhasildihapus'),
                        icon: "success",
                        showConfirmButton: false,
                        timer: 900,
                    })
                    this.loadData()
                    this.setState({
                        modalTrash: false,
                        checked: [],
                        permanentDelete: false,
                        checkAll: false,
                    })
                }
            })
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        let no = 0;
        const stylefoto = {
            maxWidth: 130,
            borderRadius: "4px",
            objectFit: "cover",
        }
        if (!this.props.user.user_level.level_page_limited || this.props.user.user_level.level_page_limited.search("Banner Aplikasi") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8"/>
                        <title>{i18n.t("banneraplikasi")} - iPresens</title>
                        <meta name="description" content="Banner Aplikasi"/>
                    </Helmet>

                    <AddBanner
                        add_visible={this.state.add_visible}
                        request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                        changeState={(state, callback) => {
                            this.setState(state, () => {
                                if (typeof callback != 'undefined')
                                    callback()
                            })
                        }}
                        loadData={() => this.loadData()}
                    />

                    <Modal size="lg" zIndex={999} isOpen={this.state.modal_edit} toggle={() => this.setState({
                        modal_edit: false,
                        fileList: [],
                        gambar: '',
                        banner_desc: '',
                        banner_start_date: '',
                        banner_end_date: '',
                    })} style={{maxWidth: "800px"}}>
                        <ModalHeader>
                            <div className='text-dark' style={{
                                fontWeight: 'bold', fontSize: "18px"
                            }}>{i18n.t("editbanner")}</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="">
                                <Form layout="vertical" hideRequiredMark>
                                    <div className="form-group">
                                        <label>{i18n.t('foto')}</label>
                                        <ImgCrop width={1920}
                                                 height={600}>
                                            <Upload action={API_HOST} listType="picture-card"
                                                    style={{width: '400px !important'}}
                                                    fileList={this.state.fileList}
                                                    onChange={(file) => {
                                                        this.setState({fileList: file.fileList})

                                                        if (file.fileList.length > 0) {
                                                            let files = file.file.originFileObj
                                                            let reader = new FileReader()

                                                            reader.readAsDataURL(files)
                                                            reader.onloadend = () => {
                                                                this.setState({
                                                                    banner_img: {
                                                                        name: file.file.name,
                                                                        file: reader.result
                                                                    }
                                                                })
                                                            }
                                                        } else {
                                                            this.setState({gambar: []})
                                                        }
                                                    }}>
                                                {this.state.fileList.length >= 1 ? null :
                                                    <div className="p-2">
                                                        <img alt='' src={ImportIcon}
                                                             style={{width: "32px", height: "32px"}}/><br/>
                                                        <div style={{
                                                            fontSize: "12px",
                                                            lineHeight: "16px",
                                                            color: "#5D6F80",
                                                            marginTop: "8px"
                                                        }}>{i18n.t('uploadgambar')}</div>
                                                        <br/>
                                                        <a className="btn btn-outline-custom tambah-karyawan">{i18n.t('carigambar')}</a>
                                                    </div>
                                                }
                                            </Upload>
                                        </ImgCrop>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="banner_desc">{i18n.t('deskripsi')}</label>
                                        <input type="text" name="banner_desc" id="banner_desc"
                                               placeholder={i18n.t('ketikandeskripsi')} className="form-control"
                                               onChange={this.handleChange} value={this.state.banner_desc}/>
                                    </div>
                                    <div className="d-flex flex-row" style={{gap: 20}}>
                                        <div className="form-group" style={{width: "100%"}}>
                                            <label htmlFor="banner_start_date">{i18n.t('tanggalmulai')}</label>
                                            <input type="date" name="banner_start_date" id="banner_start_date"
                                                   className="form-control" onChange={this.handleChange}
                                                   value={this.state.banner_start_date}/>
                                        </div>
                                        <div className="form-group" style={{width: "100%"}}>
                                            <label htmlFor="banner_end_date">{i18n.t('tanggalselesai')}</label>
                                            <input type="date" name="banner_end_date" id="banner_end_date"
                                                   className="form-control" onChange={this.handleChange}
                                                   value={this.state.banner_end_date}/>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <div
                                style={{
                                    textAlign: 'right',
                                }}
                            >
                                <button type="button" className="btn ipresens-btn-cancel" onClick={() => {
                                    this.setState({
                                        modal_edit: false,
                                        fileList: [],
                                        gambar: '',
                                        banner_desc: '',
                                        banner_start_date: '',
                                        banner_end_date: '',
                                    });
                                }}
                                        style={{marginRight: 8}}>
                                    {i18n.t("batal")}
                                </button>
                                <button type="button" className="btn ipresens-btn-color"
                                        disabled={this.state.loading} onClick={this.handleSubmitEdit}
                                        disabled={this.state.loading}>
                                    {this.state.loading ?
                                        <><i className={"fa fa-refresh fa-spin"}></i> {i18n.t("loading")}</>
                                        :
                                        i18n.t("simpan")
                                    }
                                </button>
                            </div>
                        </ModalFooter>
                    </Modal>

                    <Modal size="lg" zIndex={999} isOpen={this.state.modal_history} toggle={() => this.setState({
                        modal_history: false,
                    })} style={{maxWidth: "1000px"}}>
                        <ModalHeader className='pengumuman-modal-header'>
                            <div className='pengumuman-modal-header-font'>{i18n.t("riwayatbanner")}</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="list-group">
                                <div>
                                    {this.state.loading_history && (
                                        <div style={{textAlign: "center", margin: '10px auto'}}>
                                            <Spin size="large" tip={i18n.t('loading')}
                                                  style={{width: "100%", margin: "auto"}}/>
                                        </div>
                                    )}
                                    {this.state.history.length !== 0 && (
                                        <div>
                                            <Row>
                                                <Col xs={0} md={0} className="text-left" style={{marginLeft: '20px'}}>
                                                    <h4>{i18n.t('user')}</h4>
                                                </Col>
                                                <Col xs={0} md={0} className="text-left" style={{marginRight: '20px'}}>
                                                    <h4>{i18n.t('aktivitas')}</h4>
                                                </Col>
                                            </Row>

                                            {this.state.history.map((item, i) => {
                                                return (
                                                    <div key={i} style={{marginBottom: "20px"}}>
                                                        <Row>
                                                            <Col xs={12} md={6} className="text-left">
                                                                <div style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    padding: '16px 12px'
                                                                }}>
                                                                    <div style={{marginRight: '16px'}}>
                                                                        <img
                                                                            src={DEFAULT_IMG}
                                                                            alt=""
                                                                            style={{
                                                                                width: '48px',
                                                                                marginBottom: '25px',
                                                                                height: '48px'
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <h6 className="mb-0 font-13" style={{
                                                                            fontWeight: 500,
                                                                            fontSize: '16px',
                                                                            lineHeight: '24px'
                                                                        }}>{item.p_user_name} | {item.level_name}</h6>
                                                                        <p className="m-0 font-12">
                                                                            <span
                                                                                className="col-green font-weight-bold">
                                                                              {moment(item.created_at).format('lll')}
                                                                            </span>
                                                                        </p>
                                                                        <div style={{marginTop: 8}}>
                                                                            {item.history_status === "created" ? (
                                                                                <div className="row px-3">
                                                                                    <div
                                                                                        className="mr-2"
                                                                                        style={{
                                                                                            border: '1px solid #000',
                                                                                            borderRadius: '25px',
                                                                                            width: '50%',
                                                                                            color: '#7805e4',
                                                                                            borderColor: '#7805e4',
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            className="d-flex justify-content-center"
                                                                                            style={{
                                                                                                paddingTop: '6px',
                                                                                                paddingBottom: '6px'
                                                                                            }}>
                                                                                            {i18n.t('dibuat')}
                                                                                        </div>
                                                                                    </div>
                                                                                    {this.state.historyLast.history_id === item.history_id ?
                                                                                        "" :
                                                                                        <button
                                                                                            className='col-md-5 d-flex justify-content-center ipresens-btn-revert'
                                                                                            onClick={() => {
                                                                                                this.setState({
                                                                                                    id_bannerhistory: item.banner_id,
                                                                                                    id_history: item.history_id,
                                                                                                })
                                                                                                this.handleSubmitRevert()
                                                                                            }}>revert
                                                                                        </button>
                                                                                    }
                                                                                </div>
                                                                            ) : item.history_status === "reverted" ?
                                                                                <div
                                                                                    className="mr-2 px-5"
                                                                                    style={{
                                                                                        border: '1px solid #000',
                                                                                        borderRadius: '25px',
                                                                                        width: '70%',
                                                                                        color: '#7805e4',
                                                                                        borderColor: '#7805e4',
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        className="d-flex justify-content-center"
                                                                                        style={{
                                                                                            paddingTop: '6px',
                                                                                            paddingBottom: '6px'
                                                                                        }}>
                                                                                        {i18n.t('dikembalikan')}
                                                                                    </div>
                                                                                </div>
                                                                                : (
                                                                                    <div className="row px-3">
                                                                                        <div
                                                                                            className="mr-2"
                                                                                            style={{
                                                                                                border: '1px solid #000',
                                                                                                borderRadius: '25px',
                                                                                                width: '50%',
                                                                                                color: '#E6462E',
                                                                                                borderColor: '#E6462E',
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                className="d-flex justify-content-center"
                                                                                                style={{
                                                                                                    paddingTop: '6px',
                                                                                                    paddingBottom: '6px'
                                                                                                }}>
                                                                                                {i18n.t('diubah')}
                                                                                            </div>
                                                                                        </div>
                                                                                        {this.state.historyLast.history_id === item.history_id ?
                                                                                            "" :
                                                                                            <button
                                                                                                className='col-md-5 d-flex justify-content-center ipresens-btn-revert'
                                                                                                onClick={() => {
                                                                                                    this.setState({
                                                                                                        id_bannerhistory: item.banner_id,
                                                                                                        id_history: item.history_id,
                                                                                                    })
                                                                                                    this.handleSubmitRevert()
                                                                                                }}>revert
                                                                                            </button>
                                                                                        }
                                                                                    </div>
                                                                                )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} md={6} className="text-left" style={{
                                                                padding: '28px 12px 16px',
                                                            }}>
                                                                {item.history_status !== 'created' && (
                                                                    <div>
                                                                        {item.parent_history ? item.parent_history.banner_desc != item.banner_desc && (
                                                                            <div>
                                                                                <h6
                                                                                    className="mb-3 font-13 text-left"
                                                                                    style={{
                                                                                        fontWeight: 100,
                                                                                        fontSize: '16px',
                                                                                        lineHeight: '24px',
                                                                                    }}
                                                                                >
                                                                                    {i18n.t('deskripsibanner')} : {i18n.t('dari')}{' '}
                                                                                    {item.parent_history
                                                                                        ? item.parent_history.banner_desc
                                                                                        : 'N/A'}{' '}
                                                                                    <img
                                                                                        src={ArrowRight}
                                                                                        alt=""
                                                                                        style={{
                                                                                            width: '20x',
                                                                                            marginBottom: '1px',
                                                                                            height: '18px'
                                                                                        }}
                                                                                    /> {i18n.t('jadi')}
                                                                                    : {item.banner_desc ? item.banner_desc : ''}
                                                                                </h6>
                                                                            </div>
                                                                        ) : null}
                                                                        {item.parent_history ? item.parent_history.banner_start_date != item.banner_start_date && (
                                                                            <div>
                                                                                <h6
                                                                                    className="mb-3 font-13 text-left"
                                                                                    style={{
                                                                                        fontWeight: 100,
                                                                                        fontSize: '16px',
                                                                                        lineHeight: '24px',
                                                                                    }}
                                                                                >
                                                                                    {i18n.t('tanggalmulai')} : {i18n.t('dari')}{' '}
                                                                                    {item.parent_history
                                                                                        ? moment(item.parent_history.banner_start_date).format('ll')
                                                                                        : 'N/A'}{' '}
                                                                                    <img
                                                                                        src={ArrowRight}
                                                                                        alt=""
                                                                                        style={{
                                                                                            width: '20x',
                                                                                            marginBottom: '1px',
                                                                                            height: '18px'
                                                                                        }}
                                                                                    /> {i18n.t('jadi')}
                                                                                    : {item.banner_start_date ? moment(item.banner_start_date).format('ll') : ''}
                                                                                </h6>
                                                                            </div>
                                                                        ) : null}
                                                                        {item.parent_history ? item.parent_history.banner_end_date != item.banner_end_date && (
                                                                            <div>
                                                                                <h6
                                                                                    className="mb-3 font-13 text-left"
                                                                                    style={{
                                                                                        fontWeight: 100,
                                                                                        fontSize: '16px',
                                                                                        lineHeight: '24px',
                                                                                    }}
                                                                                >
                                                                                    {i18n.t('tanggalselesai')} : {i18n.t('dari')}{' '}
                                                                                    {item.parent_history
                                                                                        ? moment(item.parent_history.banner_end_date).format('ll')
                                                                                        : 'N/A'}{' '}
                                                                                    <img
                                                                                        src={ArrowRight}
                                                                                        alt=""
                                                                                        style={{
                                                                                            width: '20x',
                                                                                            marginBottom: '1px',
                                                                                            height: '18px'
                                                                                        }}
                                                                                    /> {i18n.t('jadi')}
                                                                                    : {item.banner_end_date ? moment(item.banner_end_date).format('ll') : ''}
                                                                                </h6>
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            {this.state.history.length !== 0 &&
                                <>
                                    <div className='col font-14' style={{
                                        padding: "auto",
                                        margin: "auto",
                                        fontWeight: 500,
                                        lineHeight: "24px"
                                    }}>{i18n.t('menampilkan')} {this.state.history.length === 0 || this.state.history.length === null ? "0" : this.state.history.length} {i18n.t('dari')} {this.state.totalDataHistory} {i18n.t('data')}
                                    </div>
                                    <div className='col'>
                                        <ReactPaginate
                                            previousLabel={<img src={ArrowLeft} alt=''/>}
                                            nextLabel={<img src={ArrowRight} alt=''/>}
                                            pageCount={this.state.pageCountHistory}
                                            forcePage={this.state.currentPageHistory}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={this.handlePageHistory}
                                            breakClassName={'page-item'}
                                            breakLinkClassName={'page-link custom-page-link'}
                                            containerClassName={'pagination my-2'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link custom-page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link custom-prev-next-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link custom-prev-next-link'}
                                            activeClassName={'active custom-active'}
                                        />
                                    </div>
                                </>
                            }
                        </ModalFooter>
                    </Modal>
                    <Modal size="lg" zIndex={999} isOpen={this.state.modalTrash} toggle={() => this.setState({
                        modalTrash: false,
                        checked: [],
                        trashHistory: [],
                        checkAll: false,
                    })} style={{maxWidth: "1000px"}}>
                        <ModalHeader className='pengumuman-modal-header'>
                            <div className='pengumuman-modal-header-font'>{i18n.t("trashbanner")}</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="row d-flex justify-content-end">
                                <div className="col-md-6"></div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <button className="btn ipresens-btn-color px-3 py-2 mx-1"
                                            disabled={this.state.checked.length === 0}
                                            onClick={() => {
                                                    this.setState({restoreBanner: true});
                                            }}>
                                        Restore
                                    </button>
                                    <button className="btn ipresens-btn-color px-3 py-2 mx-1"
                                            disabled={this.state.checked.length === 0} onClick={() => {
                                        this.setState({permanentDelete: true});
                                    }}>Delete Permanent
                                    </button>
                                </div>
                            </div>
                            <div className="list-group">
                                <div>
                                    {this.state.loading_history && (
                                        <div style={{textAlign: "center", margin: '10px auto'}}>
                                            <Spin size="large" tip={i18n.t('loading')}
                                                  style={{width: "100%", margin: "auto"}}/>
                                        </div>
                                    )}
                                    {this.state.trashHistory.length !== 0 && (
                                        <Table className="table table-striped mb-5">
                                            <Thead>
                                                <Tr>
                                                    <Th><Checkbox checked={this.state.checkAll}
                                                                  onChange={this.handleCheckBoxAllChange}></Checkbox></Th>
                                                    <Th style={{border: "none", fontSize: "13px"}}
                                                        className="text-left modal-header-ipresens-table"
                                                        width="300">{i18n.t("gambar")}</Th>
                                                    <Th style={{border: "none", fontSize: "13px"}} className="text-left modal-header-ipresens-table" width="300">{i18n.t("deskripsi")}</Th>
                                                    <Th style={{border: "none", fontSize: "13px"}} className="text-left modal-header-ipresens-table" width="200">{i18n.t("tanggalmulai")}</Th>
                                                    <Th style={{border: "none", fontSize: "13px"}} className="text-left modal-header-ipresens-table" width="200">{i18n.t("tanggalselesai")}</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {this.state.trashHistory.map((item, index) => {
                                                    let no = index + 1;
                                                    return (
                                                        <Tr>
                                                            <Td><Checkbox checked={this.state.checked.includes(item.banner_id)} onChange={() => this.handleCheckBoxChange(item.banner_id)}></Checkbox></Td>
                                                            <Td style={{border: "none"}}>
                                                                <a href={item.banner_image != null ? IMG_HOST + 'mitra/banner/' + item.banner_image : DEFAULT_IMG}
                                                                   target={"_blank"}>
                                                                    <img style={stylefoto}
                                                                         src={item.banner_image != null ? IMG_HOST + 'mitra/banner/' + item.banner_image : null}
                                                                         alt="Banner Image"/>
                                                                </a>
                                                            </Td>
                                                            <Td style={{border: "none"}} align='left'>
                                                                <div className="mt-0">
                                                                    <p style={{
                                                                        color: "#38115F",
                                                                        marginBottom: "0px"
                                                                    }}>{item.banner_desc}</p>
                                                                </div>
                                                            </Td>
                                                            <Td style={{border: "none"}} align='left'>
                                                                <div>
                                                                    <p style={{color: "#38115F", marginBottom: "0px"}}>{item.banner_start_date} - {item.banner_end_date}</p>
                                                                </div>
                                                            </Td>
                                                            <Td style={{border: "none"}} align='left'>
                                                                <div>
                                                                    <p style={{
                                                                        color: "#38115F",
                                                                        marginBottom: "0px"
                                                                    }}>{item.banner_end_date}</p>
                                                                </div>
                                                            </Td>
                                                        </Tr>
                                                    );
                                                })}
                                            </Tbody>
                                        </Table>
                                        )
                                    }
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{ backgroundColor: "#F5F5F5", borderBottomLeftRadius: "8px", borderBottomRightRadius: "8px" }}>
                            <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                                {this.state.trashHistory.length !== 0 &&
                                    <>
                                        <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>{i18n.t("menampilkan")} {this.state.trashHistory.length === 0 || this.state.trashHistory.length === null ? "0" : this.state.trashHistory.length} {i18n.t("dari")} {this.state.totalDataHistory} {i18n.t("data")}</div>
                                        <div className='col'>
                                            <ReactPaginate
                                                previousLabel={<img src={ArrowLeft} alt='' />}
                                                nextLabel={<img src={ArrowRight} alt='' />}
                                                pageCount={this.state.pageCountHistory}
                                                forcePage={this.state.currentPageHistory}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={this.handlePageTrashHistory}
                                                breakClassName={'page-item'}
                                                breakLinkClassName={'page-link custom-page-link'}
                                                containerClassName={'pagination my-2'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link custom-page-link'}
                                                previousClassName={'page-item'}
                                                previousLinkClassName={'page-link custom-prev-next-link'}
                                                nextClassName={'page-item'}
                                                nextLinkClassName={'page-link custom-prev-next-link'}
                                                activeClassName={'active custom-active'}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </ModalFooter>
                    </Modal>
                    <Modal centered isOpen={this.state.permanentDelete} toggle={() => {
                        this.setState({
                            permanentDelete: false
                        })
                    }}>
                        <ModalHeader className='pl-4 border-bottom-0' toggle={() => {
                            this.setState({
                                permanentDelete: false
                            })
                        }}>
                            <div className='d-flex'>
                                <div className='p-3 mr-4' style={{
                                    backgroundColor: "transparent",
                                    borderRadius: "8px"
                                }}>
                                    <img alt='' src={Trash}/>
                                </div>
                                <div>
                                    <div style={{
                                        color: "#143B5E",
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        lineHeight: "27px"
                                    }}>Hapus Permanen Data Banner?</div>
                                    <div style={{
                                        color: "#5D6F80",
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "24px"
                                    }}>{this.state.checked.length} Banner Terpilih</div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalFooter style={{
                            borderTop: 0
                        }}>
                            <div className='row mx-0' style={{ width: "100%" }}>
                                <div className='col-md-6 px-1'>
                                    <button className='btn ipresens-btn-cancel' style={{ width: "100%" }} onClick={() => {
                                        this.setState({
                                            permanentDelete: false
                                        })
                                    }}>
                                        {i18n.t('batal')}
                                    </button>
                                </div>
                                <div className='col-md-6 px-1'>
                                    <button className='btn ipresens-btn-color' style={{
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        lineHeight: "24px",
                                        width: "100%"
                                    }} onClick={this.deletePermanent}>
                                        {i18n.t('ya')}
                                    </button>
                                </div>
                            </div>
                        </ModalFooter>
                    </Modal>
                    <Modal centered isOpen={this.state.restoreBanner} toggle={() => {
                        this.setState({
                            restoreBanner: false
                        })
                    }}>
                        <ModalHeader className='pl-4 border-bottom-0' toggle={() => {
                            this.setState({
                                restoreBanner: false
                            })
                        }}>
                            <div className='d-flex'>
                                <div className='p-3 mr-4' style={{
                                    backgroundColor: "#8C2BEE",
                                    borderRadius: "8px"
                                }}>
                                    <img alt='' src={Restore} />
                                </div>
                                <div>
                                    <div style={{
                                        color: "#143B5E",
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        lineHeight: "27px"
                                    }}>Pulihkan Data Banner</div>
                                    <div style={{
                                        color: "#5D6F80",
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "24px"
                                    }}>{this.state.checked.length} Banner Terpilih</div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalFooter style={{
                            borderTop: 0
                        }}>
                            <div className='row mx-0' style={{ width: "100%" }}>
                                <div className='col-md-6 px-1'>
                                    <button className='btn ipresens-btn-cancel' style={{ width: "100%" }} onClick={() => {
                                        this.setState({
                                            restoreBanner: false
                                        })
                                    }}>
                                        {i18n.t('batal')}
                                    </button>
                                </div>
                                <div className='col-md-6 px-1'>
                                    <button className='btn ipresens-btn-color' style={{
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        lineHeight: "24px",
                                        width: "100%"
                                    }} onClick={this.bannerRestore}>
                                        {i18n.t('ya')}
                                    </button>
                                </div>
                            </div>
                        </ModalFooter>
                    </Modal>
                    <div className="container-fluid pt-4">
                        <div className='row'>
                            <div className='col-md-12' style={{
                                fontWeight: 400,
                                fontSize: "12px",
                                lineHeight: "16px",
                                color: "#5D6F80"
                            }}>iPresens Admin Panel
                            </div>
                        </div>
                        <div className='row mb-4'>
                            <div className='col-md-12' style={{
                                fontWeight: 700,
                                fontSize: "24px",
                                lineHeight: "36px",
                                color: "#143B5E"
                            }}>{i18n.t("daftarbanneraplikasi")}</div>
                        </div>

                        <div className="card">
                            <div className="card-header border-0 p-4">
                                <div className='row'>
                                    <div className='col-md-6 pengumuman-col-filter'>
                                        <div className='flex-row flex-nowrap'>
                                            <div className='input-group btn-srch-pengumuman  mr-1'>
                                                <span className='input-group-text pengumuman-filter-nama-input-text'>
                                                    <img alt='' src={SearchIcon}/>
                                                </span>
                                                <input className='form-control mr-2 pengumuman-filter-nama-input'
                                                       type='text' placeholder={i18n.t("caribanner")}
                                                       onKeyUp={(event) => {
                                                           if (event.which === 13) {
                                                               event.preventDefault()
                                                               this.setState({
                                                                   page: 0,
                                                                   paging: this.state.paging,
                                                                   loading: true,
                                                                   data: []
                                                               }, () => {
                                                                   this.loadData()
                                                               });
                                                           }
                                                       }} onChange={this.handleChange} name="filter_judul"
                                                       value={this.state.filter_judul}/>
                                                <button className='btn ipresens-btn-reset-filter'
                                                        onClick={this.resetFilter}>
                                                    <img alt='' src={RefreshIcon}/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 d-flex justify-content-end flex-wrap">
                                        <div className="flex-grow-1 mr-2">
                                            <button className='ipresens-btn-add-pengumuman btn-add-pengumuman'
                                                    onClick={() => this.setState({
                                                        add_visible: true
                                                    })}><img alt='' src={PlusIcon}/> {i18n.t("tambahbanner")}
                                            </button>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <Tooltip trigger={'click'} placement="bottomLeft" color={'#FFF'} title={
                                                <div className={'p-2'}>
                                                <a onClick={() => {
                                                        this.setState({modalTrash: true});
                                                        this.loadTrash();
                                                    }} style={{marginRight: "50px"}} className={'text-dark'}>Trash
                                                        Banner</a>
                                                </div>
                                            }>
                                                <a><i
                                                    style={{
                                                        width: "15",
                                                        fontSize: 18,
                                                        color: 'rgb(51 51 51)',
                                                        alignItems: "center",
                                                        height: "45px",
                                                    }}
                                                    className='btn border border-dark border-1 p-2'
                                                ><img src={MoreIcon}/></i></a>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body daftar-pengumuman pt-1">
                                {!this.state.loading && this.state.data.length === 0 ?
                                    <Empty description={i18n.t('datakosong')}
                                           image={EmptyDataImage}
                                           className="ipresens-image-empty mb-5"/>
                                    :
                                    <Table className="mb-5 table table-borderless table-striped">
                                        <Thead>
                                            <Tr>
                                                <Th className="pengumuman-th-table">#</Th>
                                                <Th className="pengumuman-th-table">{i18n.t("gambar")}</Th>
                                                <Th className="pengumuman-th-table"
                                                    style={{minWidth: '300px'}}>{i18n.t("deskripsi")}</Th>
                                                <Th className="pengumuman-th-table">{i18n.t("tanggalmulai")}</Th>
                                                <Th className="pengumuman-th-table">{i18n.t("tanggalselesai")}</Th>
                                                <Th className="pengumuman-th-table"></Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {this.state.loading &&
                                                <Tr>
                                                    <Td colSpan={5}>
                                                        <Spin size="large" tip="Loading..." style={{
                                                            width: '100%',
                                                            margin: 'auto',
                                                            marginBottom: '10px',
                                                            marginTop: '10px'
                                                        }}/>
                                                    </Td>
                                                </Tr>
                                            }
                                            {this.state.data.map((item, index) => {
                                                no += 1
                                                return (
                                                    <Tr>
                                                        <Td className="pengumuman-td-table">{pageNumber(no, this.state.perPage, this.state.currentPage)}</Td>
                                                        <Td className="pengumuman-td-table">
                                                            <a href={item.banner_image != null ? IMG_HOST + 'mitra/banner/' + item.banner_image : DEFAULT_IMG}
                                                               target={"_blank"}>
                                                                <img style={stylefoto}
                                                                     src={item.banner_image != null ? IMG_HOST + 'mitra/banner/' + item.banner_image : null}
                                                                     alt="Banner Image"/>
                                                            </a>
                                                        </Td>
                                                        <Td className="pengumuman-td-table"
                                                            style={{minWidth: '120px'}}>{item.banner_desc}</Td>
                                                        <Td className="pengumuman-td-table">{moment(item.banner_start_date).format('LL')}</Td>
                                                        <Td className="pengumuman-td-table">{moment(item.banner_end_date).format('LL')}</Td>
                                                        <Td className="pengumuman-td-table">
                                                            <Tooltip trigger={'click'} placement="bottom" color={'#fff'}
                                                                     title={
                                                                         <div className="p-2">
                                                                             <a className="text-dark" onClick={() => {
                                                                                 this.setState({
                                                                                     banner_id: item.banner_id,
                                                                                     perusahaan_id: item.perusahaan_id,
                                                                                     modal_edit: true,
                                                                                 }, () => this.loadDetail())
                                                                             }}>
                                                                                 {i18n.t('edit')}
                                                                             </a><br/>
                                                                             <a className="text-dark" onClick={() => {
                                                                                 this.setState({
                                                                                     banner_id: item.banner_id,
                                                                                     perusahaan_id: item.perusahaan_id,
                                                                                     modal_history: true,
                                                                                 }, () => this.loadHistory(item.banner_id))
                                                                             }}>
                                                                                 {i18n.t('riwayat')}
                                                                             </a><br/>
                                                                             <a className="text-danger" onClick={() => {
                                                                                 this.setState({
                                                                                     id_delete: item.banner_id,
                                                                                     perusahaan_id: item.perusahaan_id,
                                                                                 })
                                                                                 this.toDelete()
                                                                             }}>
                                                                                 {i18n.t('hapus')}
                                                                             </a>
                                                                         </div>
                                                                     }>
                                                                <a><i className="fa fa-ellipsis-v" style={{
                                                                    width: 35,
                                                                    fontSize: 18,
                                                                    padding: 11,
                                                                    color: 'rgb(51 51 51)'
                                                                }}/></a>
                                                            </Tooltip>
                                                        </Td>
                                                    </Tr>
                                                )
                                            })}
                                        </Tbody>
                                    </Table>
                                }
                                <Paging
                                    className="float-right"
                                    handlePageClick={this.handlePageClick}
                                    pageCount={this.state.pageCount}
                                    currentPage={this.state.currentPage}
                                />
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">{i18n.t('tidakadaakseshalaman')}</h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(List_banner);