   import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom'
import {Checkbox, Empty, Modal, Spin, Tooltip} from 'antd';
import {
    Col,
    Input,
    InputGroup,
    InputGroupAddon,
    Modal as ModalRs,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from 'reactstrap';
import Swal from "sweetalert2";
import moment from "moment";
import {Helmet} from "react-helmet";
import {disableBodyScroll, enableBodyScroll} from "body-scroll-lock";
import {pageNumber} from "../../functions/functionGeneral";
import {APIKEY, BACKEND_API_HOST, DEFAULT_IMG, IMG_HOST} from "../../helper/host";
import DatePicker from "react-datepicker";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import {clear_auth, set_auth} from "../../reducers/Auth";
import {connect} from "react-redux";
import ReactPaginate from "react-paginate";
import ArrowRight from '../../assets/icons/arrow-right.svg'
import ArrowLeft from '../../assets/icons/arrow-left.svg'
import FilterIcon from '../../assets/icons/filter.svg';
import PlusIcon from '../../assets/icons/plus.svg';
import RefreshIcon from '../../assets/icons/refresh.svg';
import EmptyDataImage from '../../assets/images/data-kosong.png';
import WarningIcon from '../../assets/icons/warning-icon.svg';
import MoreIcon from '../../assets/icons/more.svg'
import IconPlusWhite from "../../assets/icons/IconPlusWhite.svg"
import './style-absensi.css';
import {GoogleApiWrapper, Map, Marker} from 'google-maps-react';
import Select from "react-select";
import TimeInput from '../../helper/time_input';
import {faCalendarAlt,} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {DateRange} from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file daterange
import 'react-date-range/dist/theme/default.css';
import i18n from "i18next";
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table"; // theme css file daterange
//css ipresens
import "../iPresens-style.css";
import TrashIcon from "../../assets/icons/trash.svg";
import AddModalAbsensi from "./absensi-modal/add-modal";
import EditModalAbsensi from "./absensi-modal/edit-modal";
import Trash from "../../assets/icons/trash.svg";
import Restore from "../../assets/icons/update.svg";


export class List_absensi extends Component {
    constructor(props) {
        super(props);
        var namabulan = ("01 02 03 04 05 06 07 08 09 10 11 12");
        namabulan = namabulan.split(" ");
        var date = new Date();
        var lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
        var bulan = lastDay.getMonth();
        var tahun = lastDay.getFullYear();
        var tanggal = new Date();
        this.state = {
            loading: true,
            karyawan: [],
            data: [],
            idDel: '',
            absensi_id: '',
            filter_nama: "",
            filter_status_in: "",
            filter_status_out: "",
            year: tahun,
            month: namabulan[bulan],
            tanggal: tanggal,
            sort_by: '',
            sort_value: '',
            absensiStartDate: '',
            absensiEndDate: '',
            modalexport: false,
            modalexportimage: false,
            modalupdate: false,
            modalAction: false,
            pageCount: 0,
            currentPage: 0,
            currentPageHistory:0,
            totalData: 0,
            perPage: 0,
            paging: 20,
            pagination:2,
            dataKosong: "",
            guide: false,
            dateFilter: moment(),
            totalPerpage: 0,
            filter: false,
            data_lokasi: [],
            data_divisi: [],
            data_shift: [],
            filter_divisi: "",
            filter_lokasi: "",
            filter_belum_masuk: "",
            filter_belum_keluar: "",
            filter_status: "",
            filter_shift: "",
            modalManagement: false,
            idDetail: "",
            modalDetail: false,
            modalHistory: false,
            dataDetail: [],
            dataHistory: [],
            dataLembur: [],
            dataKaryawan: {},
            DataDivisi: {},
            datepicker: false,
            valueFilterDate: moment()._d,
            detail_statusin: "",
            detail_statusout: "",
            modalMapIn: false,
            modalMapOut: false,
            karyawan_id: 0,
            shift_nama: "",
            jam_masuk: "",
            lokasi_masuk: 0,
            status_masuk: "",
            keterangan_masuk: "",
            jam_keluar: null,
            lokasi_keluar: 0,
            status_keluar: "",
            keterangan_keluar: "",
            karyawan_selected: null,
            shift: [],
            mapel: [],
            modalAdd: false,
            shift_id: 0,
            lokasi_nama_masuk: "",
            lokasi_nama_keluar: "",
            lokasi_masuk_id: 0,
            lokasi_keluar_id: 0,
            lokasi_in: [],
            lokasi_out: [],
            exportStartDate: "",
            exportEndDate: "",
            valueDate: "",
            start_date: new Date(),
            end_date: new Date(),
            reactDateRange: false,
            loadingHistory: false,
            slice_karyawan_nama: true,
            sift_selected:null,
            mapel_selected:null,
            rows_length: 0,
            istirahat_start: [],
            istirahat_end: [],
            ist_end: [],
            ist_start: [],
            istirahat_times: [{ start: '', end: '' }],
            index_item: '',
            istirahat:[],
            addIsOpen: false,
            editIsOpen: false,
            absensi: [],
            id_history: 0,
            id_absensiHistory: 0,
            historyLast: [],
            trashHistory: [],
            checkedTrash: [],
            checkAllTrash: false,
            restore: false,
            deletePermanent: false,
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleDate = this.handleDate.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.exportImage = this.exportImage.bind(this)
        this.exportAbsensi = this.exportAbsensi.bind(this)
        this.exportLaporanLembur = this.exportLaporanLembur.bind(this)
        this.exportLaporanAbsensi = this.exportLaporanAbsensi.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.handlePageHistory = this.handlePageHistory.bind(this)
        this.wantDelete = this.wantDelete.bind(this)
        this.searchKaryawan = this.searchKaryawan.bind(this)
        this.searchLokasiIn = this.searchLokasiIn.bind(this)
        this.searchLokasiOut = this.searchLokasiOut.bind(this)
        this.applyCallback = this.applyCallback.bind(this);
        this.handleCheckBoxChangeTrash = this.handleCheckBoxChangeTrash.bind(this);
        this.handleCheckBoxAllChangeTrash = this.handleCheckBoxAllChangeTrash.bind(this);
    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, data: [], loading: true }, () => {
            this.loadData();
        });
    }

    handlePageHistory(dataHistory) {
        let selected = dataHistory.selected; // zero based index
        this.setState({ currentPageHistory: selected, dataHistory: [], loadingHistory: true }, () => {
            this.loadHistory();
        });
    }

    handleOk = e => {
        this.setState({
            modalexport: false,
            modalexportimage: false,
            modalAction: false,
            modalupdate: false,
            modalManagement: false,
            valueDate: "",
            exportStartDate: "",
            exportEndDate: "",
            start_date: new Date(),
            end_date: new Date()
        });
    };

    handleCancel = e => {
        this.setState({
            modalAction: false,
            modalexport: false,
            modalexportimage: false,
            modaludpate: false,
            modalManagement: false,
            valueDate: "",
            exportStartDate: "",
            exportEndDate: "",
            start_date: new Date(),
            end_date: new Date()
        });
    };

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    handleDate(event) {
        const start = event.selectStart;
        const end = event.selectEnd;

        this.setState({
            data: [],
            loading: true,
            modalexport: true,
            mdoalupdate: true,
            exportStartDate: start,
            exportEndDate: end
        }, () => {
            this.loadData()
        });
    }

    handleFilter(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            data: [],
            loading: true
        }, () => this.loadData())
    }

    resetFilter(event) {
        this.setState({
            filter_nama: '',
            filter_status_in: '',
            filter_status_out: '',
            filter_shift: '',
            dataKosong: '',
            absensiStartDate: '',
            absensiEndDate: '',
            data: [],
            loading: true,
            status: "",
        }, () => this.loadData())
    }

    exportImage() {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'presensi/export-foto-absen-zip?token=' + AuthKey + '&filter_tanggal=' + this.state.dateFilter.format('L'), "_blank")
    }

    exportAbsensi() {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'presensi/export?token=' + AuthKey + '&presensiStartDate=' + this.state.exportStartDate + '&presensiEndDate=' + this.state.exportEndDate, "_blank")
    }

    exportLaporanLembur() {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'presensi/export-lembur?token=' + AuthKey + '&presensiStartDate=' + this.state.exportStartDate + '&presensiEndDate=' + this.state.exportEndDate, "_blank")
    }

    exportLaporanAbsensi() {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'presensi/export_laporan?token=' + AuthKey + '&presensiStartDate=' + this.state.exportStartDate + '&presensiEndDate=' + this.state.exportEndDate, "_blank")
    }

    applyCallback(startDate, endDate) {
        var valueStart = moment(startDate).format("YYYY-MM-DD");
        var valueEnd = moment(endDate).format("YYYY-MM-DD");
        this.setState({
            start: startDate,
            end: endDate,
            exportStartDate: valueStart,
            exportEndDate: valueEnd,
            valueDate: valueStart + " - " + valueEnd
        })
    }

    wantGenerateAlpa = e => {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghitung alpa absen karyawan?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        loading: true,
                    })
                    let form = {
                        id: this.state.idDel,
                        presensiStartDate: this.state.exportStartDate,
                        presensiEndDate: this.state.exportEndDate,
                    }
                    this.props.request("presensi/generate-absen", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            }
                        });
                }
            })
    };

    wantDeleteDuplicateData = e => {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus data absen karyawan yang terdeteksi sebagai duplikat?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        data: [],
                        loading: true,
                    })
                    let form = {
                        presensiStartDate: this.state.exportStartDate,
                        presensiEndDate: this.state.exportEndDate,
                    }
                    this.props.request("presensi/delete-duplicate", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            }
                        });
                }
            })
    };

    wantDelete() {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus presensi?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        id: this.state.idDel
                    }
                    this.setState({
                        loading: true,
                        data: [],
                    })
                    this.props.request("presensi/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    loadHistory(id) {
        this.setState({
            dataHistory: [],
            loadingHistory: true
        },()=>{
            let form = {
                id: id ? id : this.state.absensi_id,
                paging : this.state.pagination,
                page: this.state.currentPageHistory + 1,
            };

            this.props.request("presensi/history", form, 'POST')
                .then(response => {
                    this.setState({
                        loadingHistory: false
                    })
                    if (response.succes) {
                        this.setState({
                            dataHistory: response.data.data,
                            historyLast: response.history,
                            pageCountHistory: response.data.last_page,
                            totalDataHistory: response.data.total,
                            totalPerpageHistory: response.data.to,
                            perPageHistory: response.data.per_page,
                        });
                    }
                    else {
                        this.setState({
                            modalHistory: false,
                            absensi_id: ''
                        });
                    }
                });
        })
    }

    handleSubmitRevert = () => {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('yakinmengembalikanbanner'),
            icon: "warning",
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                    if (result.isConfirmed) {
                        let form = {
                            id_absensi: this.state.id_absensiHistory,
                            history_id: this.state.id_history,
                        }
                        this.props.request("presensi/revert", form, 'POST')
                            .then(response => {
                                if (response.success) {
                                    Swal.fire({
                                        title: i18n.t('berhasil'),
                                        text: response.message,
                                        icon: 'success'
                                    })
                                    this.setState({
                                        modalHistory: false
                                    })
                                    this.props.history.push('/presence')
                                    this.loadData()
                                } else {
                                    Swal.fire({
                                        title: i18n.t('gagal'),
                                        text: response.message,
                                        icon: 'warning',
                                        confirmButtonText: 'OK'
                                    })
                                }
                            })
                    }
                }
            )
    }

    loadTrash(id) {
        this.setState({
            trashHistory: [],
            loading_history: true,
        }, () => {
            let form = {
                perusahaan_id: this.state.perusahaan_id,
                page: this.state.currentPageHistory + 1,
                tanggal: this.state.dateFilter.format('YYYY-MM-DD'),
            };
            this.props.request('presensi/trash', form, 'POST')
                .then(response => {
                    if (response.success) {
                        this.setState({
                            trashHistory: response.data.data,
                            pageCountHistory: response.data.last_page,
                            totalDataHistory: response.data.total,
                            totalPerpageHistory: response.data.to,
                            perPageHistory: response.data.per_page,
                            loading_history: false
                        })
                    } else {
                        this.setState({
                            modalHistory: false, id_absensi: 0, perusahaan_id: 0,
                            modalTrash: false,
                        })
                    }
                })
        })
    }

    restore = (id) => {
        let form = {
            id_absensi: this.state.checkedTrash,
        }
        this.props.request('presensi/restore', form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('databerhasildikembalikan'),
                        icon: "success",
                        showConfirmButton: false,
                        timer: 900,
                    })
                    this.loadData()
                    this.setState({
                        modalTrash: false,
                        checkedTrash: [],
                        restore: false,
                        checkAllTrash: false,
                    })
                }
            })
    }

    deletePermanent = (id) => {
        let form = {
            id_absensi: this.state.checkedTrash,
        }
        this.props.request('presensi/delete-permanent', form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('databerhasildihapus'),
                        icon: "success",
                        showConfirmButton: false,
                        timer: 900,
                    })
                    this.loadData()
                    this.setState({
                        modalTrash: false,
                        checkedTrash: [],
                        permanentDelete: false,
                        checkAllTrash: false,
                    })
                }
            })
    }

    handleCheckBoxChangeTrash(id) {
        this.state.checkedTrash.includes(id) ?
            this.setState(prevState => ({
                checkedTrash: prevState.checkedTrash.filter(currId => currId !== id),
            }), () => {
                if (this.state.checkedTrash.length !== this.state.totalDataHistory) {
                    this.setState({
                        checkAllTrash: false
                    })
                }
            })
            :
            this.setState(prevState => ({
                checkedTrash: [...prevState.checkedTrash, id],
            }), () => {
                if (this.state.checkedTrash.length === this.state.totalDataHistory) {
                    this.setState({
                        checkAllTrash: true
                    })
                }
            })
    }

    handleCheckBoxAllChangeTrash() {
        this.setState(prevState => ({
            checkAllTrash: !prevState.checkAllTrash
        }), () => {
            this.state.checkAllTrash
                ? this.setState({
                    checkedTrash: this.state.trashHistory.map(item => item.id_absensi)
                })
                : this.setState({
                    checkedTrash: []
                })
        })
    }

    loadDetail(type) {
        let form = {
            id: this.state.idDetail
        }
        this.props.request("presensi/profil", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        dataDetail: response.data,
                        detail_statusin: response.data.status_in,
                        detail_statusout: response.data.status_out,
                        dataLembur: response.lembur,
                        dataKaryawan: response.data.karyawan,
                        DataDivisi: response.data.divisi,
                        absenMasuk: {
                            lat: response.data.latitude_in,
                            lng: response.data.longitude_in,
                        },
                        absenPulang: {
                            lat: response.data.latitude_out,
                            lng: response.data.longitude_out,
                        },
                        tanggal: response.data.tanggal,
                        karyawan_id: response.data.karyawan_id,
                        shift_nama: response.data.sif_name,
                        jam_masuk: response.data.jam_masuk_absen,
                        status_masuk: response.data.status_in,
                        keterangan_masuk: response.data.keterangan_in,
                        jam_keluar: response.data.jam_keluar_absen,
                        status_keluar: response.data.status_out,
                        keterangan_keluar: response.data.keterangan_out,
                        istirahat_start: response.data.istirahat_mulai,
                        istirahat_end: response.data.istirahat_selesai,
                        karyawan_selected: {
                            value: response.data.karyawan_id,
                            label: response.data.karyawan.karyawan_nama
                        },
                        sift_selected: {
                            value: response.data.sift_id,
                            label: response.data.sift_name,
                        },
                        mapel_selected: {
                            value: response.data.mapel_id,
                            label: response.data.mapel_nama,
                        },
                        shift: response.data.karyawan.divisi_sift,
                        mapel: response.data.karyawan.divisi_sift_mapel,
                        rows_length: response.data.istirahat.length,
                        istirahat: response.data.istirahat,
                        istirahat_times: response.data.istirahat.map(item => ({
                            start: item.istirahat_start,
                            end: item.istirahat_end
                        }))
                    })
                }
                if (type === "detail") {
                    this.setState({
                        modalDetail: true
                    })
                }
            });
    }

    searchLokasiIn() {
        let form = {
            lokasi_nama: this.state.lokasi_nama_masuk
        }
        this.props.request("search_lokasi_perusahaan", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        lokasi_in: response.data,
                        lokasi_in_loading: false
                    })
                }
            })
    }

    searchLokasiOut() {
        let form = {
            lokasi_nama: this.state.lokasi_nama_keluar
        }
        this.props.request("search_lokasi_perusahaan", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        lokasi_out: response.data,
                        lokasi_out_loading: false
                    })
                }
            })
    }

    loadData() {
        let form = {
            paginate: this.state.paging,
            page: this.state.currentPage + 1,
            filter_nama: this.state.filter_nama,
            filter_lokasi: this.state.filter_lokasi,
            filter_divisi: this.state.filter_divisi,
            filter_shift: this.state.filter_shift,
            filter_tanggal: this.state.dateFilter.format('L'),
            filter_dataKosong: this.state.dataKosong,
            filter_belum_masuk: this.state.filter_belum_masuk,
            filter_belum_keluar: this.state.filter_belum_keluar,
            filter_status: this.state.filter_status,
            month: this.state.month,
            year: this.state.year,
            valueFilterDate: this.state.valueFilterDate,
            sort_by: this.state.sort_by,
            sort_value: this.state.sort_value
        }
        this.props.request("presensi/list-v3", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        absensi: response.data.data.map(item => item.absensi),
                        totalData: response.data.total,
                        totalPerpage: response.data.to,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        loading: false
                    })
                }
            });
    }

    searchKaryawan() {
        let form = {
            karyawan_name: this.state.karyawan_name
        }
        this.props.request("karyawan/search-karyawan", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        karyawan: response.data,
                        karyawan_loading: false
                    })
                }
            })
    }

    updateAbsensi()  {
        this.setState({
            loading: true,
            data: []
        })
        let formData = {
            dateFilter: this.state.dateFilter.format('YYYY-MM-DD'),
        }
        this.props.request('presensi/set-clockout-presensi', formData, 'POST')
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        timer: 1500,
                    })
                    this.loadData()
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'error',
                        timer: 5000,
                    })
                    this.loadData()
                }})
            }

    Capitalize(str){
        const Capitalize = str
            .toLowerCase()
            .split(' ')
            .map(word => {
                return word.charAt(0).toUpperCase() + word.slice(1);
            })
            .join(' ');

        return Capitalize;
    }

    convertToHoursMins(time) {
        if (time < 1) {
            return;
        }

        let hours = Math.floor(time / 60);
        let minutes = time % 60;

        let formattedHours = hours < 10 ? '0' + hours + 'j' : hours + 'j';
        let formattedMinutes = minutes < 10 ? '0' + minutes + 'm' : minutes + 'm';

        return formattedHours + ' ' + formattedMinutes;
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        const selectionRange = {
            startDate: this.state.start_date,
            endDate: this.state.end_date,
            key: 'selection',
        }

        const detail_statusin = this.state.detail_statusin
        const detail_statusout = this.state.detail_statusout
        let no = 0;
        const tipe = this.props.user.perusahaan.perusahaan_type === "sekolah";
        if (!this.props.user.user_level.level_page_limited || this.props.user.user_level.level_page_limited.search("Presensi") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Daftar Presensi - Presensi</title>
                        <meta name="description" content="Daftar Presensi" />
                    </Helmet>

                    <AddModalAbsensi
                        is_sekolah={tipe}
                        addIsOpen={this.state.addIsOpen}
                        jam_masuk={this.state.jam_masuk}
                        jam_keluar={this.state.jam_keluar}
                        tanggal={this.state.tanggal}
                        request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                        changeState={(state, callback) => {
                            this.setState(state, () => {
                                if (typeof callback != 'undefined')
                                    callback()
                            })
                        }}
                        loadData={() => this.loadData()}
                    />

                    <EditModalAbsensi
                        is_sekolah={tipe}
                        editIsOpen={this.state.editIsOpen}
                        id={this.state.idDetail}
                        tanggal={moment(this.state.dateFilter).format("YYYY-MM-DD")}
                        request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                        changeState={(state, callback) => {
                            this.setState(state, () => {
                                if (typeof callback != 'undefined')
                                    callback()
                            })
                        }}
                        loadData={() => this.loadData()}
                    />


                    <div className="container-fluid" style={{ paddingTop: "30px" }}>
                        <div className="card border">
                            <div className="card-header" style={{ backgroundColor: "#FAFDFF", padding: "12px 24px", fontSize: "24px", fontWeight: 700 }}>
                                {i18n.t('presensiharian')}
                            </div>
                            <ModalRs size='lg' isOpen={this.state.modalexport} toggle={this.handleCancel}>
                                <ModalHeader style={{ backgroundColor: "#FAFDFF" }} toggle={this.handleCancel}>Export Excel</ModalHeader>
                                <ModalBody>
                                    <div className='row'>
                                        <div className='col'>
                                            <div className='d-flex flex-row warning-info px-2 mb-2'>
                                                <div className='px-0 mr-1'><img src={WarningIcon} alt="" /></div>
                                                <div className='px-0' style={{ fontSize: "14px" }}>
                                                    Filter berdasarkan tanggal terlebih dahulu
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <div className='col-md-5'>
                                            <InputGroup className="form-tgl-absen">
                                                <InputGroupAddon addonType="prepend">
                                                    <div className="input-group-text">
                                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                                    </div>
                                                </InputGroupAddon>

                                                <Input bsSize="sm" className='font-edit-value' style={{ paddingTop: "8px", paddingBottom: "8px" }} onFocus={() => this.setState({ reactDateRange: true })} placeholder="Filter Tanggal" value={this.state.valueDate} />
                                            </InputGroup>
                                        </div>
                                        <div className='col-md-1'></div>
                                        <div className='col-md-6'></div>
                                    </div>
                                    {this.state.reactDateRange === true &&
                                        <div className='shadow d-flex flex-column' style={{ maxWidth: "332px" }}>
                                            <div>
                                                <DateRange
                                                    onBlur={() => this.setState({ reactDateRange: false })}
                                                    ranges={[selectionRange]}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            start_date: e.selection.startDate,
                                                            end_date: e.selection.endDate,
                                                            exportStartDate: moment(e.selection.startDate).format("YYYY-MM-DD"),
                                                            exportEndDate: moment(e.selection.endDate).format("YYYY-MM-DD")
                                                        })
                                                    }
                                                    }
                                                />
                                            </div>
                                            <div>
                                                <div className='d-flex justify-content-end pr-2'>
                                                    <button className='btn ipresens-btn-cancel text-capitalize mb-2' style={{ fontSize: "14px", fontWeight: 500 }} onClick={() => this.setState({ reactDateRange: false })}>Batal</button>
                                                    <button className='btn ipresens-btn-color mb-2 ml-2 text-capitalize' style={{ fontSize: "14px", fontWeight: 500, lineHeight: "24px" }} onClick={
                                                        () => this.setState({
                                                            valueDate: this.state.exportStartDate + " - " + this.state.exportEndDate,
                                                            reactDateRange: false
                                                        })
                                                    }>Terapkan</button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {this.state.valueDate !== "" &&
                                        <>
                                            <div className='row pl-2 mb-2'>
                                                <div className="col-md-4 px-1">
                                                    <button onClick={() => { this.wantGenerateAlpa() }} className={"btn btn-block text-capitalize px-3 py-1 mb-2"} style={{
                                                        backgroundColor: "#FFFFFF",
                                                        color: "#8c2bee",
                                                        borderColor: "#8c2bee",
                                                        fontSize: "12px",
                                                        borderRadius: "12px"
                                                    }}><i className="fa fa-book" /> Hitung Alpa</button>
                                                </div>
                                                <div className='col-md-4 px-1'>
                                                    <button onClick={() => { this.wantDeleteDuplicateData() }} className={"btn btn-block text-capitalize px-3 py-1"} style={{
                                                        backgroundColor: "#FFECE8",
                                                        color: "#E03616",
                                                        borderColor: "#E03616",
                                                        fontSize: "12px",
                                                        borderRadius: "12px"
                                                    }}><i className="fa fa-book" /> Hapus Data Duplikat</button>
                                                </div>
                                                <div className='col-md'></div>
                                            </div>
                                            <hr style={{ marginBottom: "40px" }} />
                                            <div className='row px-2'>
                                                <div className='col-md-4 px-1'>
                                                    <button className={'btn btn-block ipresens-btn-color px-3 py-2 text-capitalize mb-2'} style={{ fontSize: "14px", borderRadius: "11px" }} onClick={this.exportAbsensi}>Export Data Presensi</button>
                                                </div>
                                                <div className='col-md-4 px-1'>
                                                    <button className={'btn btn-block ipresens-btn-color px-3 py-2 text-capitalize mb-2'} style={{ fontSize: "14px", borderRadius: "11px" }} onClick={this.exportLaporanLembur}>Export Data Lembur</button>
                                                </div>
                                                <div className='col-md-4 px-1'>
                                                    <button className={'btn btn-block ipresens-btn-color px-3 py-2 text-capitalize'} style={{ fontSize: "14px", borderRadius: "11px" }} onClick={this.exportLaporanAbsensi}>Export Data Laporan Presensi</button>
                                                </div>

                                            </div>
                                        </>
                                    }
                                </ModalBody>
                                <ModalFooter style={{ backgroundColor: "#F5F5F5", borderTop: 0 }}>
                                    <button className="btn ipresens-btn-cancel text-capitalize" onClick={this.handleCancel}>
                                        Batalkan
                                    </button>
                                </ModalFooter>
                            </ModalRs>
                            <ModalRs size='lg' style={{ maxWidth: "500px" }} isOpen={this.state.modalexportimage} toggle={this.handleCancel}>
                                <ModalHeader style={{ backgroundColor: "#FAFDFF" }} toggle={this.handleCancel}>{i18n.t('exportfotoabsen')}</ModalHeader>
                                <ModalBody>
                                    <div className='row px-2'>
                                        <div className='col text-center px-4'>
                                            {this.state.data.length !== 0 ?
                                                <button
                                                    className={'btn btn-block ipresens-btn-color px-0 py-2 text-capitalize'}
                                                    style={{fontSize: "14px", borderRadius: "11px"}}
                                                    onClick={this.exportImage}
                                                >
                                                    {i18n.t('exportfotohariini')} ({this.state.dateFilter.format('L')})
                                                </button>
                                                :
                                                <button
                                                    className={'btn btn-block ipresens-btn-color px-0 py-2 text-capitalize'}
                                                    style={{fontSize: "14px", borderRadius: "11px"}}
                                                    onClick={this.exportImage}
                                                    disabled={true}
                                                >
                                                    {i18n.t('exportfotohariini')} ({this.state.dateFilter.format('L')})
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </ModalBody>
                                <ModalFooter style={{backgroundColor: "#F5F5F5", borderTop: 0}}>
                                    <button className="btn ipresens-btn-cancel text-capitalize" onClick={this.handleCancel}>
                                        {i18n.t('batalkan')}
                                    </button>
                                </ModalFooter>
                            </ModalRs>
                            <Modal
                                title="Olah Data Absensi"
                                open={this.state.modalManagement}
                                onOk={this.handleOk}
                                onCancel={this.handleCancel}
                            >
                                <div className='pb-4'>
                                    <button onClick={() => { this.wantDeleteDuplicateData() }} className={"btn btn-danger ml-2"} style={{ float: "right" }}><i className="fa fa-book" /> Hapus Data Duplikat</button>
                                    <button onClick={() => { this.wantGenerateAlpa() }} className={"btn btn-warning ml-2"} style={{ float: "right" }}><i className="fa fa-book" /> Hitung Alpa</button>
                                </div>
                            </Modal>
                            <ModalRs size='lg' isOpen={this.state.modalMapIn} toggle={() => this.setState({ modalMapIn: false })}>
                                <ModalHeader style={{ backgroundColor: "#FAFDFF" }} toggle={() => this.setState({ modalMapIn: false })}>Detail Lokasi</ModalHeader>
                                <ModalBody>
                                    <div style={{
                                        position: 'relative',
                                        width: '100%',
                                        height: '250px'
                                    }}>
                                        <Map google={this.props.google}
                                            initialCenter={{
                                                lat: this.state.dataDetail.latitude_in,
                                                lng: this.state.dataDetail.longitude_in
                                            }}
                                            zoom={15}>
                                            <Marker />
                                        </Map>
                                    </div>
                                </ModalBody>
                            </ModalRs>
                            <ModalRs size='lg' isOpen={this.state.modalMapOut} toggle={() => this.setState({ modalMapOut: false })}>
                                <ModalHeader style={{ backgroundColor: "#FAFDFF" }} toggle={() => this.setState({ modalMapOut: false })}>Detail Lokasi</ModalHeader>
                                <ModalBody>
                                    <div style={{
                                        position: 'relative',
                                        width: '100%',
                                        height: '250px'
                                    }}>
                                        <Map google={this.props.google}
                                            initialCenter={{
                                                lat: this.state.dataDetail.latitude_out,
                                                lng: this.state.dataDetail.longitude_out
                                            }}
                                            zoom={15}>
                                            <Marker />
                                        </Map>
                                    </div>
                                </ModalBody>
                            </ModalRs>
                            <ModalRs size='md' isOpen={this.state.modalupdate} toggle={() => this.setState({ modalupdate: false })} style={{ justifyContent:"center", alignItems:"center" }}>
                                <ModalHeader style={{ backgroundColor: "#FAFDFF" }} toggle={() => this.setState({ modalupdate: false })}>Apakah Anda Yakin Untuk Clockout Otomatis presensi harian?</ModalHeader>
                                <ModalBody>
                                    <span>{this.Capitalize("ada     data yang belum di clockout")}</span>
                                </ModalBody>
                                <ModalFooter style={{ backgroundColor: "#F5F5F5", borderTop: 0 }}>
                                    <button className="btn ipresens-btn-cancel" onClick={() => this.setState({ modalupdate: false })}>
                                        Batal
                                    </button>
                                <button className='btn ipresens-btn-color' onClick={() => { this.updateAbsensi() }}>
                                    <span>Yakin</span>
                                </button>
                                </ModalFooter>
                            </ModalRs>

                            <ModalRs size="lg" style={{maxWidth: "1000px"}} isOpen={this.state.modalDetail}
                                     toggle={() => this.setState({modalDetail: false})}>
                                <ModalHeader style={{backgroundColor: "#FAFDFF"}}
                                             toggle={() => this.setState({modalDetail: false})}>
                                    <div className='modal-header-reactstrap'>{tipe ? i18n.t('detailabsensiswa') : i18n.t('detailabsenkaryawan')}</div>
                                </ModalHeader>
                                <ModalBody>
                                    <div className='d-flex flex-row warning-info px-2 mb-2'>
                                        <div className='px-0 mr-1'><img src={WarningIcon} alt=""/></div>
                                        <div className='px-0' style={{fontSize: "14px"}}>
                                            {i18n.t('warningresetfotopresensi')}
                                        </div>
                                    </div>
                                    <div className='row px-3'>
                                        <div className='col-md-3 pl-0 pr-1'>
                                            <div className='card border px-0'>
                                                <div className='card-body px-2 py-2'>
                                                    <h5 className='font-14 mb-0' style={{
                                                        fontWeight: 700,
                                                        lineHeight: "24px",
                                                        color: "#143B5E"
                                                    }}>{i18n.t('presensimasuk')}</h5>
                                                    <div className='d-flex justify-content-start' style={{
                                                        fontSize: "12px",
                                                        fontWeight: 500,
                                                        lineHeight: "16px",
                                                        marginBottom: "12px"
                                                    }}>
                                                        <div
                                                            className='mr-1'>{this.state.dataDetail.jam_masuk_absen}</div>
                                                        <div
                                                            className={detail_statusin !== "Masuk Kerja" ? "warning-color mx-0 my-0 px-0" : "success-color mx-0 my-0 px-0"}>{detail_statusin.replace(" Kerja", "")}</div>
                                                    </div>
                                                    <div className='mb-3'>
                                                        {this.state.dataDetail.foto_in !== null ?
                                                            <img style={{width: "100%", borderRadius: "8px"}}
                                                                 src={IMG_HOST + 'mitra/frontapp/absensi/' + this.state.dataDetail.foto_in}
                                                                 alt={i18n.t('fotopresensimasuk')}/>
                                                            :
                                                            <img style={{width: "100%", borderRadius: "8px"}}
                                                                 src={IMG_HOST + "user-profile.png"}
                                                                 alt={i18n.t('fotopresensimasuk')}/>
                                                        }
                                                    </div>
                                                    <div>
                                                        <button className='btn button-add text-capitalize btn-block'
                                                                onClick={() => this.setState({modalDetail: false}, () => this.setState({modalMapIn: true}))}>{i18n.t('lihatlokasi')}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-3 pl-1 pr-0 mx-0'>
                                            <div className='card border px-0'>
                                                <div className='card-body px-2 py-2'>
                                                    <h5 className='font-14 mb-0' style={{
                                                        fontWeight: 700,
                                                        lineHeight: "24px",
                                                        color: "#143B5E"
                                                    }}>{i18n.t('presensikeluar')}</h5>
                                                    <div className='d-flex justify-content-start' style={{
                                                        fontSize: "12px",
                                                        fontWeight: 500,
                                                        lineHeight: "16px",
                                                        marginBottom: "12px"
                                                    }}>
                                                        <div
                                                            className='mr-1'>{this.state.dataDetail.jam_keluar_absen === null || this.state.dataDetail.jam_keluar_absen === "" ? "__:__:__" : this.state.dataDetail.jam_keluar_absen}</div>
                                                        <div
                                                            className={detail_statusout !== "Masuk Kerja" ? "warning-color mx-0 my-0 px-0" : "success-color mx-0 my-0 px-0"}>{detail_statusout.replace(" Kerja", "")}</div></div>
                                                    <div className='mb-3'>
                                                        {this.state.dataDetail.foto_out !== null ?
                                                            <img style={{ width: "100%", borderRadius: "8px" }} src={IMG_HOST + 'mitra/frontapp/absensi/' + this.state.dataDetail.foto_out} alt={i18n.t('fotopresensikeluar')} />
                                                            :
                                                            <img style={{ width: "100%", borderRadius: "8px" }} src={IMG_HOST + "user-profile.png"} alt={i18n.t('fotopresensikeluar')} />
                                                        }
                                                    </div>
                                                    <div>
                                                        <button className='btn button-add text-capitalize btn-block' onClick={() => this.setState({ modalDetail: false }, () => this.setState({ modalMapOut: true }))}>{i18n.t('lihatlokasi')}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6 pl-3 pr-0 mx-0'>
                                            <div className='card border'>
                                                <div className='card-header px-3 py-2' style={{ backgroundColor: "#FAFDFF", color: "#143B5E", fontSize: "16px", fontWeight: 700 }}>{i18n.t('presensi')} {this.state.dateFilter.format('dddd')} {this.state.dateFilter.format('LL')}</div>
                                                <div className='card-body px-3'>
                                                    <div className='row d-flex justify-content-start flex-nowrap'>
                                                        <div className='col-md-5 font-detail-label pr-0'>{tipe ? i18n.t('namasiswa') : i18n.t('namakaryawan')}</div>
                                                        <div className='col-md-1 px-0' style={{ maxWidth: "20px" }}>:</div>
                                                        <div className='col-md-6 px-0 font-detail-value'>{this.state.dataDetail.karyawan ? this.state.dataDetail.karyawan.karyawan_nama : "-"}</div>
                                                    </div>
                                                    <div className='row d-flex justify-content-start flex-nowrap'>
                                                        <div className='col-md-5 font-detail-label pr-0'>{tipe ? i18n.t('kelas') : i18n.t('divisi')}</div>
                                                        <div className='col-md-1 px-0' style={{ maxWidth: "20px" }}>:</div>
                                                        <div className='col-md-6 px-0 font-detail-value'>
                                                            {this.state.dataDetail.divisi ? this.state.dataDetail.divisi.divisi_name : "-"}
                                                        </div>
                                                    </div>
                                                    <div className='row d-flex justify-content-start flex-nowrap'>
                                                        <div className='col-md-5 font-detail-label pr-0'>{tipe ? i18n.t('jadwalmapel') : i18n.t('sift')}</div>
                                                        <div className='col-md-1 px-0' style={{ maxWidth: "20px" }}>:</div>
                                                        <div className='col-md-6 px-0 font-detail-value'>
                                                            {this.state.dataDetail.sif_name}
                                                        </div>
                                                    </div>
                                                    <div className='row d-flex justify-content-start flex-nowrap'>
                                                        <div className='col-md-5 font-detail-label pr-0'>{i18n.t('keteranganpresensimasuk')}</div>
                                                        <div className='col-md-1 px-0' style={{ maxWidth: "20px" }}>:</div>
                                                        <div className='col-md-6 px-0 font-detail-value'>
                                                            {this.state.dataDetail.keterangan_in === "" || this.state.dataDetail.keterangan_in === null ?
                                                                "-" : this.state.dataDetail.keterangan_in}
                                                        </div>
                                                    </div>
                                                    <div className='row d-flex justify-content-start flex-nowrap'>
                                                        <div className='col-md-5 font-detail-label pr-0'>{i18n.t('keteranganpresensipulang')}</div>
                                                        <div className='col-md-1 px-0' style={{ maxWidth: "20px" }}>:</div>
                                                        <div className='col-md-6 px-0 font-detail-value'>
                                                            {this.state.dataDetail.keterangan_out === "" || this.state.dataDetail.keterangan_out === null ?
                                                                "-" : this.state.dataDetail.keterangan_out}
                                                        </div>
                                                    </div>
                                                    {this.state.dataDetail && this.state.dataDetail.status_in === "Izin" ?
                                                        <div className='row d-flex justify-content-start flex-nowrap'>
                                                            <div className='col-md-5 font-detail-label pr-0'>{i18n.t('keteranganizin')}</div>
                                                            <div className='col-md-1 px-0' style={{ maxWidth: "20px" }}>:</div>
                                                            <div className='col-md-6 px-0 font-detail-value'>
                                                                {this.state.dataDetail.keterangan_cuti}
                                                            </div>
                                                        </div>
                                                        : this.state.dataDetail && this.state.dataDetail.status_out === "Izin" ?
                                                            <div className='row d-flex justify-content-start flex-nowrap'>
                                                                <div className='col-md-5 font-detail-label pr-0'>{i18n.t('keteranganizin')}</div>
                                                                <div className='col-md-1 px-0' style={{ maxWidth: "20px" }}>:</div>
                                                                <div className='col-md-6 px-0 font-detail-value'>
                                                                    {this.state.dataDetail.keterangan_cuti}
                                                                </div>
                                                            </div>
                                                            : null
                                                    }
                                                    {this.state.dataDetail && this.state.dataDetail.status_in === "Cuti" ?
                                                        <div className='row d-flex justify-content-start flex-nowrap'>
                                                            <div className='col-md-5 font-detail-label pr-0'>{i18n.t('keterangancuti')}</div>
                                                            <div className='col-md-1 px-0' style={{ maxWidth: "20px" }}>:</div>
                                                            <div className='col-md-6 px-0 font-detail-value'>
                                                                {this.state.dataDetail.keterangan_cuti}
                                                            </div>
                                                        </div>
                                                        : this.state.dataDetail && this.state.dataDetail.status_out === "Cuti" ?
                                                            <div className='row d-flex justify-content-start flex-nowrap'>
                                                                <div className='col-md-5 font-detail-label pr-0'>{i18n.t('keterangancuti')}</div>
                                                                <div className='col-md-1 px-0' style={{ maxWidth: "20px" }}>:</div>
                                                                <div className='col-md-6 px-0 font-detail-value'>
                                                                    {this.state.dataDetail.keterangan_cuti}
                                                                </div>
                                                            </div>
                                                            : null
                                                    }
                                                </div>

                                                {this.state.istirahat.length !== 0 && (
                                                    <div className="px-3 py-2">
                                                        <h1 className="font-detail-label pr-0 font-weight-bold text-capitalize">{i18n.t('istirahat')}</h1>

                                                        <div className="d-flex flex-row align-items-start"
                                                             style={{gap: "14px", overflowX: "auto"}}>
                                                            {this.state.istirahat.map((item, index) => {
                                                                return (
                                                                    <div
                                                                        className="d-flex flex-column border rounded px-3 align-items-start justify-content-center"
                                                                        style={{
                                                                            width: "fit-content",
                                                                            paddingTop: '15px',
                                                                            paddingBottom: "10px"
                                                                        }} key={index}>
                                                                        <div
                                                                            className="d-flex flex-row align-items-center justify-content-between"
                                                                            style={{
                                                                                height: "19px",
                                                                                gap: "20px",
                                                                                width: "100%"
                                                                            }}>
                                                                            <h1 className="font-detail-label text-capitalize lh-1">{i18n.t('mulaiistirahat')}</h1>
                                                                            <h2 className="font-detail-value lh-1">{item.istirahat_start ? item.istirahat_start.slice(0, 5) : '-'}</h2>
                                                                        </div>

                                                                        <div
                                                                            className="d-flex flex-row align-items-center justify-content-between"
                                                                            style={{
                                                                                height: '15px',
                                                                                gap: "20px",
                                                                                width: "100%"
                                                                            }}>
                                                                            <h1 className="font-detail-label text-capitalize lh-1">{i18n.t('selesaiistirahat')}</h1>
                                                                            <h2 className="font-detail-value lh-1">{item.istirahat_end ? item.istirahat_end.slice(0, 5): "-"}</h2>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                </ModalBody>
                                <ModalFooter style={{backgroundColor: "#F5F5F5"}}>
                                    <button className='btn ipresens-btn-color text-capitalize'
                                            style={{fontFamily: 'DM Sans', fontSize: "14px", fontWeight: "14px"}}
                                            onClick={() => this.setState({modalDetail: false}, () => this.setState({editIsOpen: true}))}>Edit
                                        Presensi
                                    </button>
                                </ModalFooter>
                            </ModalRs>
                            <ModalRs size="lg" style={{maxWidth: "1000px"}} isOpen={this.state.modalHistory}
                                     toggle={() => this.setState({modalHistory: false})}>
                                <ModalHeader style={{backgroundColor: "#FAFDFF"}}
                                             toggle={() => this.setState({modalHistory: false})}>
                                    <div className='modal-header-reactstrap'>Riwayat Perubahan</div>
                                </ModalHeader>
                                <ModalBody>
                                    <div className="list-group">
                                        <div>
                                            {this.state.loadingHistory && (
                                                <div style={{textAlign: 'center', margin: '10px auto'}}>
                                                    <Spin
                                                        size="large"
                                                        tip="Loading..."
                                                        style={{
                                                            width: '100%',
                                                            margin: 'auto',
                                                        }}
                                                    />
                                                </div>
                                            )}

                                            {this.state.dataHistory.length !== 0 && (
                                                <div>
                                                <Row>
                                                        <Col xs={0} md={0} className="text-left" style={{ marginLeft: '20px' }}>
                                                            <h4>User</h4>
                                                        </Col>
                                                        <Col xs={0} md={0} className="text-left"style={{ marginRight: '20px' }}>
                                                            <h4>Aktivitas</h4>
                                                        </Col>
                                                    </Row>

                                                    {this.state.dataHistory.map((item, index) => {
                                                        return (
                                                            <div key={index} style={{ marginBottom: '20px' }}>
                                                                <Row>
                                                                    <Col xs={12} md={6} className="text-left">
                                                                        <div style={{ display: 'flex', alignItems: 'center', paddingTop: '16px',paddingBottom:'16px', paddingRight:'12px', paddingLeft:'12px' }}>
                                                                            <div style={{ marginRight: '16px' }}>
                                                                                <img
                                                                                    src={DEFAULT_IMG}
                                                                                    alt=""
                                                                                    style={{ width: '48px' , marginBottom:'25px', height:'48px'}}
                                                                                />
                                                                            </div>
                                                                            <div>
                                                                                <h6
                                                                                    className="mb-0 font-13"
                                                                                    style={{
                                                                                        fontWeight: 500,
                                                                                        fontSize: '16px',
                                                                                        lineHeight: '24px',
                                                                                    }}
                                                                                >
                                                                                    {item.p_user_name} | {item.level_name}
                                                                                </h6>
                                                                                <p className="m-0 font-12">
                                                                                    <span className="col-green font-weight-bold">
                                                                                      {moment(item.created_at).format('lll')}
                                                                                    </span>
                                                                                </p>
                                                                                <div style={{ marginTop: 12 }}>
                                                                                    {item.history_status === "created" ? (
                                                                                        <div className="row px-3">
                                                                                            <div
                                                                                                className="mr-2"
                                                                                                style={{
                                                                                                    border: '1px solid #000',
                                                                                                    borderRadius: '25px',
                                                                                                    width: '50%',
                                                                                                    color: '#7805e4',
                                                                                                    borderColor: '#7805e4',
                                                                                                }}
                                                                                            >
                                                                                                <div
                                                                                                    className="d-flex justify-content-center"
                                                                                                    style={{
                                                                                                        paddingTop: '6px',
                                                                                                        paddingBottom: '6px'
                                                                                                    }}>
                                                                                                    {i18n.t('dibuat')}
                                                                                                </div>
                                                                                            </div>
                                                                                            {this.state.historyLast.history_id === item.history_id ?
                                                                                                "" :
                                                                                                <button
                                                                                                    className='col-md-5 d-flex justify-content-center ipresens-btn-revert'
                                                                                                    onClick={() => {
                                                                                                        this.setState({
                                                                                                            id_absensiHistory: item.id_absensi,
                                                                                                            id_history: item.history_id,
                                                                                                        })
                                                                                                        this.handleSubmitRevert()
                                                                                                    }}>{i18n.t('revert')}
                                                                                                </button>
                                                                                            }
                                                                                        </div>
                                                                                    ) : item.history_status === "reverted" ?
                                                                                        <div
                                                                                            className="mr-2 px-5"
                                                                                            style={{
                                                                                                border: '1px solid #000',
                                                                                                borderRadius: '25px',
                                                                                                width: '70%',
                                                                                                color: '#7805e4',
                                                                                                borderColor: '#7805e4',
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                className="d-flex justify-content-center"
                                                                                                style={{
                                                                                                    paddingTop: '6px',
                                                                                                    paddingBottom: '6px'
                                                                                                }}>
                                                                                                {i18n.t('dikembalikan')}
                                                                                            </div>
                                                                                        </div>
                                                                                        : (
                                                                                            <div className="row px-3">
                                                                                                <div
                                                                                                    className="mr-2"
                                                                                                    style={{
                                                                                                        border: '1px solid #000',
                                                                                                        borderRadius: '25px',
                                                                                                        width: '50%',
                                                                                                        color: '#E6462E',
                                                                                                        borderColor: '#E6462E',
                                                                                                    }}
                                                                                                >
                                                                                                    <div
                                                                                                        className="d-flex justify-content-center"
                                                                                                        style={{
                                                                                                            paddingTop: '6px',
                                                                                                            paddingBottom: '6px'
                                                                                                        }}>
                                                                                                        {i18n.t('diubah')}
                                                                                                    </div>
                                                                                                </div>
                                                                                                {this.state.historyLast.history_id === item.history_id ?
                                                                                                    "" :
                                                                                                    <button
                                                                                                        className='col-md-5 d-flex justify-content-center ipresens-btn-revert'
                                                                                                        onClick={() => {
                                                                                                            this.setState({
                                                                                                                id_absensiHistory: item.id_absensi,
                                                                                                                id_history: item.history_id,
                                                                                                            })
                                                                                                            this.handleSubmitRevert()
                                                                                                        }}>{i18n.t('revert')}
                                                                                                    </button>
                                                                                                }
                                                                                            </div>
                                                                                        )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col xs={12} md={6} className="text-left" style={{ paddingTop: '16px',paddingBottom:'16px', paddingRight:'12px', paddingLeft:'12px' }}>
                                                                        {item.history_status !== 'created' && (
                                                                            <div>
                                                                                {item.parent_history ?
                                                                                        item.parent_history.jam_masuk_absen != item.jam_masuk_absen && (
                                                                                        <div>
                                                                                            <h6
                                                                                               className="mb-3 font-13 text-left"
                                                                                                      style={{
                                                                                                          fontWeight: 500,
                                                                                                          fontSize: '16px',
                                                                                                          lineHeight: '24px',
                                                                                                      }}
                                                                                            >
                                                                                                Jam Masuk Absen: dari{' '}
                                                                                                {item.parent_history
                                                                                                    ? item.parent_history.jam_masuk_absen
                                                                                                    : 'Kosong'}{' '}
                                                                                                <img
                                                                                                    src={ArrowRight}
                                                                                                    alt=""
                                                                                                    style={{
                                                                                                        width: '20x',
                                                                                                        marginBottom: '3px',
                                                                                                        height: '20px'
                                                                                                    }}
                                                                                                />jadi: {item.jam_masuk_absen ? item.jam_masuk_absen : ''}
                                                                                            </h6>
                                                                                        </div>
                                                                                    ):null
                                                                                }
                                                                                {item.parent_history ?
                                                                                    item.parent_history.jam_keluar_absen != item.jam_keluar_absen && (
                                                                                        <div>
                                                                                            <h6
                                                                                                className="mb-3 font-13 text-left"
                                                                                                style={{
                                                                                                    fontWeight: 500,
                                                                                                    fontSize: '16px',
                                                                                                    lineHeight: '24px',
                                                                                                }}
                                                                                            >
                                                                                                Jam Keluar Absen: dari{' '}
                                                                                                {item.parent_history
                                                                                                    ? item.parent_history.jam_keluar_absen
                                                                                                    : 'Kosong'}{' '}
                                                                                                <img
                                                                                                    src={ArrowRight}
                                                                                                    alt=""
                                                                                                    style={{
                                                                                                        width: '20x',
                                                                                                        marginBottom: '3px',
                                                                                                        height: '20px'
                                                                                                    }}
                                                                                                />jadi: {item.jam_keluar_absen ? item.jam_keluar_absen : ''}
                                                                                            </h6>
                                                                                        </div>
                                                                                    ):null
                                                                                }
                                                                                {item.parent_history ?
                                                                                    item.parent_history.sif_name != item.sif_name && (
                                                                                        <div>
                                                                                            <h6
                                                                                                className="mb-3 font-13 text-left"
                                                                                                style={{
                                                                                                    fontWeight: 500,
                                                                                                    fontSize: '16px',
                                                                                                    lineHeight: '24px',
                                                                                                }}
                                                                                            >
                                                                                                Shift: dari{' '}
                                                                                                {item.parent_history
                                                                                                    ? item.parent_history.sif_name
                                                                                                    : 'Kosong'}{' '}
                                                                                                <img
                                                                                                    src={ArrowRight}
                                                                                                    alt=""
                                                                                                    style={{
                                                                                                        width: '20x',
                                                                                                        marginBottom: '3px',
                                                                                                        height: '20px'
                                                                                                    }}
                                                                                                />jadi: {item.sif_name ? item.sif_name : ''}
                                                                                            </h6>
                                                                                        </div>
                                                                                    ):null
                                                                                }
                                                                                {item.parent_history ?
                                                                                    item.parent_history.status_in != item.status_in && (
                                                                                        <div>
                                                                                            <h6
                                                                                                className="mb-3 font-13 text-left"
                                                                                                style={{
                                                                                                    fontWeight: 500,
                                                                                                    fontSize: '16px',
                                                                                                    lineHeight: '24px',
                                                                                                }}
                                                                                            >
                                                                                                Status Masuk: dari{' '}
                                                                                                {item.parent_history
                                                                                                    ? item.parent_history.status_in
                                                                                                    : 'Kosong'}{' '}
                                                                                                <img
                                                                                                    src={ArrowRight}
                                                                                                    alt=""
                                                                                                    style={{
                                                                                                        width: '20x',
                                                                                                        marginBottom: '3px',
                                                                                                        height: '20px'
                                                                                                    }}
                                                                                                />jadi: {item.status_in ? item.status_in : ''}
                                                                                            </h6>
                                                                                        </div>
                                                                                    ):null
                                                                                }
                                                                                {item.parent_history ?
                                                                                    item.parent_history.status_out != item.status_out && (
                                                                                        <div>
                                                                                            <h6
                                                                                                className="mb-3 font-13 text-left"
                                                                                                style={{
                                                                                                    fontWeight: 500,
                                                                                                    fontSize: '16px',
                                                                                                    lineHeight: '24px',
                                                                                                }}
                                                                                            >
                                                                                                Status Keluar: dari{' '}
                                                                                                {item.parent_history
                                                                                                    ? item.parent_history.status_out
                                                                                                    : 'Kosong'}{' '}
                                                                                                <img
                                                                                                    src={ArrowRight}
                                                                                                    alt=""
                                                                                                    style={{
                                                                                                        width: '20x',
                                                                                                        marginBottom: '3px',
                                                                                                        height: '20px'
                                                                                                    }}
                                                                                                />jadi: {item.status_out ? item.status_out : ''}
                                                                                            </h6>
                                                                                        </div>
                                                                                    ):null
                                                                                }
                                                                                {item.parent_history ?
                                                                                    item.parent_history.istirahat_mulai != item.istirahat_mulai && (
                                                                                        <div>
                                                                                            <h6
                                                                                                className="mb-3 font-13 text-left"
                                                                                                style={{
                                                                                                    fontWeight: 500,
                                                                                                    fontSize: '16px',
                                                                                                    lineHeight: '24px',
                                                                                                }}
                                                                                            >
                                                                                                Istirahat Mulai: dari{' '}
                                                                                                {item.parent_history
                                                                                                    ? item.parent_history.istirahat_mulai
                                                                                                    : 'Kosong'}{' '}
                                                                                                <img
                                                                                                    src={ArrowRight}
                                                                                                    alt=""
                                                                                                    style={{
                                                                                                        width: '20x',
                                                                                                        marginBottom: '3px',
                                                                                                        height: '20px'
                                                                                                    }}
                                                                                                />jadi: {item.istirahat_mulai ? item.istirahat_mulai : ''}
                                                                                            </h6>
                                                                                        </div>
                                                                                    ):null
                                                                                }
                                                                                {item.parent_history ?
                                                                                    item.parent_history.istirahat_selesai != item.istirahat_selesai && (
                                                                                        <div>
                                                                                            <h6
                                                                                                className="mb-3 font-13 text-left"
                                                                                                style={{
                                                                                                    fontWeight: 500,
                                                                                                    fontSize: '16px',
                                                                                                    lineHeight: '24px',
                                                                                                }}
                                                                                            >
                                                                                                Istirahat Mulai: dari {' '}
                                                                                                {item.parent_history
                                                                                                    ? item.parent_history.istirahat_selesai
                                                                                                    : 'Kosong'}{' '}
                                                                                                <img
                                                                                                    src={ArrowRight}
                                                                                                    alt=""
                                                                                                    style={{
                                                                                                        width: '20x',
                                                                                                        marginBottom: '3px',
                                                                                                        height: '20px'
                                                                                                    }}
                                                                                                />jadi: {item.istirahat_selesai ? item.istirahat_selesai : ''}
                                                                                            </h6>
                                                                                        </div>
                                                                                    ):null
                                                                                }
                                                                            </div>
                                                                        )}
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </ModalBody>
                                <ModalFooter style={{ backgroundColor: "#F5F5F5" }}>
                                    <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                                        {this.state.dataHistory.length !== 0 &&
                                            <>
                                                <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>menampilkan {this.state.dataHistory.length === 0 || this.state.dataHistory.length === null ? "0" : this.state.dataHistory.length} dari {this.state.totalDataHistory} data</div>
                                                <div className='col'>
                                                    <ReactPaginate
                                                        previousLabel={<img src={ArrowLeft} alt='' />}
                                                        nextLabel={<img src={ArrowRight} alt='' />}
                                                        pageCount={this.state.pageCountHistory}
                                                        forcePage={this.state.currentPageHistory}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={3}
                                                        onPageChange={this.handlePageHistory}
                                                        breakClassName={'page-item'}
                                                        breakLinkClassName={'page-link custom-page-link'}
                                                        containerClassName={'pagination my-2'}
                                                        pageClassName={'page-item'}
                                                        pageLinkClassName={'page-link custom-page-link'}
                                                        previousClassName={'page-item'}
                                                        previousLinkClassName={'page-link custom-prev-next-link'}
                                                        nextClassName={'page-item'}
                                                        nextLinkClassName={'page-link custom-prev-next-link'}
                                                        activeClassName={'active custom-active'}
                                                    />
                                                </div>
                                            </>
                                        }
                                    </div>

                                </ModalFooter>
                            </ModalRs>
                            <ModalRs size="lg" zIndex={999} isOpen={this.state.modalTrash} toggle={() => this.setState({
                                modalTrash: false,
                                checkedTrash: [],
                                trashHistory: [],
                                checkAllTrash: false,
                            })} style={{maxWidth: "1000px"}}>
                                <ModalHeader className='pengumuman-modal-header'>
                                    <div className='pengumuman-modal-header-font'>{i18n.t("trashpresensi")} - {this.state.dateFilter.format('L')}</div>
                                </ModalHeader>
                                <ModalBody>
                                    <div className="row d-flex justify-content-end">
                                        <div className="col-md-6"></div>
                                        <div className="col-md-6 d-flex justify-content-end">
                                            <button className="btn ipresens-btn-color px-3 py-2 mx-1"
                                                    disabled={this.state.checkedTrash.length === 0}
                                                    onClick={() => {
                                                        this.setState({restore: true});
                                                    }}>
                                                Restore
                                            </button>
                                            <button className="btn ipresens-btn-color px-3 py-2 mx-1"
                                                    disabled={this.state.checkedTrash.length === 0} onClick={() => {
                                                this.setState({permanentDelete: true});
                                            }}>Delete Permanent
                                            </button>
                                        </div>
                                    </div>
                                    <div className="list-group">
                                        <div>
                                            {this.state.loading_history && (
                                                <div style={{textAlign: "center", margin: '10px auto'}}>
                                                    <Spin size="large" tip={i18n.t('loading')}
                                                          style={{width: "100%", margin: "auto"}}/>
                                                </div>
                                            )}
                                            {this.state.trashHistory.length !== 0 && (
                                                <Table className="table table-striped mb-5">
                                                    <Thead>
                                                        <Tr>
                                                            <Th width="30"><Checkbox checked={this.state.checkAllTrash}
                                                                                     onChange={this.handleCheckBoxAllChangeTrash}></Checkbox></Th>
                                                            <Th className="th-font th-padding"
                                                                style={{maxWidth: "140px"}}>{i18n.t("tglpresensi")}</Th>
                                                            <Th className="th-font th-padding"
                                                                style={{minWidth: "105px"}}>
                                                                <div className='jam-masuk'>{i18n.t("jammasuk")}</div>
                                                            </Th>
                                                            <Th className="th-font th-padding"
                                                                style={{minWidth: "100px"}}>{i18n.t("jamistirahat")}</Th>
                                                            <Th className="th-font th-padding"
                                                                style={{minWidth: "103px"}}>{i18n.t("jamkeluar")}</Th>
                                                            <Th className="th-font th-padding"
                                                                style={{minWidth: "105px"}}>{i18n.t("totaljamkerja")}</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {this.state.trashHistory.map((item) => {
                                                            return (
                                                                <Tr>
                                                                    <Td><Checkbox
                                                                        checked={this.state.checkedTrash.includes(item.id_absensi)}
                                                                        onChange={() => this.handleCheckBoxChangeTrash(item.id_absensi)}></Checkbox></Td>
                                                                    <Td className='lembur-td-style'>
                                                                        {moment(item.lembur_tanggal).format('ll')}
                                                                    </Td>
                                                                    <Td className='lembur-td-style'>
                                                                        {item.jam_masuk_absen !== null ? item.jam_masuk_absen : "__:__:__"}
                                                                    </Td>
                                                                    <Td className='lembur-td-style'>
                                                                        {item.jam_istirahat !== 0 ? item.jam_istirahat : "__:__:__"}
                                                                    </Td>
                                                                    <Td className='lembur-td-style'>
                                                                        {item.jam_keluar_absen !== null ? item.jam_keluar_absen : "__:__:__"}
                                                                    </Td>
                                                                    <Td className='td-font th-padding'
                                                                        align="left">{item.jam_kerja ? this.convertToHoursMins(Math.abs(item.jam_kerja)) : "-"}</Td>
                                                                </Tr>
                                                            );
                                                        })}
                                                    </Tbody>
                                                </Table>
                                            )
                                            }
                                        </div>
                                    </div>
                                </ModalBody>
                                <ModalFooter style={{
                                    backgroundColor: "#F5F5F5",
                                    borderBottomLeftRadius: "8px",
                                    borderBottomRightRadius: "8px"
                                }}>
                                    <div className='row mx-0' style={{backgroundColor: "#F5F5F5", width: "100%"}}>
                                        {this.state.trashHistory.length !== 0 &&
                                            <>
                                                <div className='col font-14' style={{
                                                    padding: "auto",
                                                    margin: "auto",
                                                    fontWeight: 500,
                                                    lineHeight: "24px"
                                                }}>{i18n.t("menampilkan")} {this.state.trashHistory.length === 0 || this.state.trashHistory.length === null ? "0" : this.state.trashHistory.length} {i18n.t("dari")} {this.state.totalDataHistory} {i18n.t("data")}</div>
                                                <div className='col'>
                                                    <ReactPaginate
                                                        previousLabel={<img src={ArrowLeft} alt=''/>}
                                                        nextLabel={<img src={ArrowRight} alt=''/>}
                                                        pageCount={this.state.pageCountHistory}
                                                        forcePage={this.state.currentPageHistory}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={3}
                                                        onPageChange={this.handlePageTrashHistory}
                                                        breakClassName={'page-item'}
                                                        breakLinkClassName={'page-link custom-page-link'}
                                                        containerClassName={'pagination my-2'}
                                                        pageClassName={'page-item'}
                                                        pageLinkClassName={'page-link custom-page-link'}
                                                        previousClassName={'page-item'}
                                                        previousLinkClassName={'page-link custom-prev-next-link'}
                                                        nextClassName={'page-item'}
                                                        nextLinkClassName={'page-link custom-prev-next-link'}
                                                        activeClassName={'active custom-active'}
                                                    />
                                                </div>
                                            </>
                                        }
                                    </div>
                                </ModalFooter>
                            </ModalRs>
                            <ModalRs centered isOpen={this.state.permanentDelete} toggle={() => {
                                this.setState({
                                    permanentDelete: false
                                })
                            }}>
                                <ModalHeader className='pl-4 border-bottom-0' toggle={() => {
                                    this.setState({
                                        permanentDelete: false
                                    })
                                }}>
                                    <div className='d-flex'>
                                        <div className='p-3 mr-4' style={{
                                            backgroundColor: "transparent",
                                            borderRadius: "8px"
                                        }}>
                                            <img alt='' src={Trash} />
                                        </div>
                                        <div>
                                            <div style={{
                                                color: "#143B5E",
                                                fontWeight: 700,
                                                fontSize: "18px",
                                                lineHeight: "27px"
                                            }}>Hapus Permanen Data Presensi?</div>
                                            <div style={{
                                                color: "#5D6F80",
                                                fontWeight: 400,
                                                fontSize: "14px",
                                                lineHeight: "24px"
                                            }}>{this.state.checkedTrash.length} Absensi Terpilih</div>
                                        </div>
                                    </div>
                                </ModalHeader>
                                <ModalFooter style={{
                                    borderTop: 0
                                }}>
                                    <div className='row mx-0' style={{ width: "100%" }}>
                                        <div className='col-md-6 px-1'>
                                            <button className='btn ipresens-btn-cancel' style={{ width: "100%" }} onClick={() => {
                                                this.setState({
                                                    permanentDelete: false
                                                })
                                            }}>
                                                {i18n.t('batal')}
                                            </button>
                                        </div>
                                        <div className='col-md-6 px-1'>
                                            <button className='btn ipresens-btn-color' style={{
                                                fontSize: "14px",
                                                fontWeight: 500,
                                                lineHeight: "24px",
                                                width: "100%"
                                            }} onClick={this.deletePermanent}>
                                                {i18n.t('ya')}
                                            </button>
                                        </div>
                                    </div>
                                </ModalFooter>
                            </ModalRs>
                            <ModalRs centered isOpen={this.state.restore} toggle={() => {
                                this.setState({
                                    restore: false
                                })
                            }}>
                                <ModalHeader className='pl-4 border-bottom-0' toggle={() => {
                                    this.setState({
                                        restore: false
                                    })
                                }}>
                                    <div className='d-flex'>
                                        <div className='p-3 mr-4' style={{
                                            backgroundColor: "#8C2BEE",
                                            borderRadius: "8px"
                                        }}>
                                            <img alt='' src={Restore} />
                                        </div>
                                        <div>
                                            <div style={{
                                                color: "#143B5E",
                                                fontWeight: 700,
                                                fontSize: "18px",
                                                lineHeight: "27px"
                                            }}>Pulihkan Data Presensi</div>
                                            <div style={{
                                                color: "#5D6F80",
                                                fontWeight: 400,
                                                fontSize: "14px",
                                                lineHeight: "24px"
                                            }}>{this.state.checkedTrash.length} Presensi Terpilih</div>
                                        </div>
                                    </div>
                                </ModalHeader>
                                <ModalFooter style={{
                                    borderTop: 0
                                }}>
                                    <div className='row mx-0' style={{ width: "100%" }}>
                                        <div className='col-md-6 px-1'>
                                            <button className='btn ipresens-btn-cancel' style={{ width: "100%" }} onClick={() => {
                                                this.setState({
                                                    restore: false
                                                })
                                            }}>
                                                {i18n.t('batal')}
                                            </button>
                                        </div>
                                        <div className='col-md-6 px-1'>
                                            <button className='btn ipresens-btn-color' style={{
                                                fontSize: "14px",
                                                fontWeight: 500,
                                                lineHeight: "24px",
                                                width: "100%"
                                            }} onClick={this.restore}>
                                                {i18n.t('ya')}
                                            </button>
                                        </div>
                                    </div>
                                </ModalFooter>
                            </ModalRs>
                            <div className='row d-flex justify-content-between mx-0 mt-3 px-3'>
                                <div className="col-md-8 row m-0">
                                    <div className='col-auto px-0 mb-2 col-tgl-absen'>
                                        <div className='d-flex flex-nowrap' style={{ width: "100%" }}>
                                            <button className='ph-filter-date-left' onClick={() => this.setState({
                                                dateFilter: moment(this.state.dateFilter).subtract(1, 'days'),
                                                loading: true
                                            }, () => this.loadData())}>
                                                <img src={ArrowLeft} alt="" style={{ height: "16px", width: "16px" }} />
                                            </button>
                                            <DatePicker
                                                selected={this.state.valueFilterDate}
                                                customInput={<button className='ph-filter-date-mid btn-tgl-absen'>{this.state.dateFilter.format('L')}</button>}
                                                onSelect={(e) =>
                                                    this.setState({
                                                        dateFilter: moment(e),
                                                        valueFilterDate: e,
                                                        loading: true
                                                    }, () => this.loadData())
                                                }
                                            />
                                            <button className='ph-filter-date-right' onClick={() => this.setState({
                                                dateFilter: moment(this.state.dateFilter).add(1, 'days'),
                                                loading: true
                                            }, () => this.loadData())}>
                                                <img src={ArrowRight} alt="" style={{ height: "16px", width: "16px" }} />
                                            </button>
                                        </div>
                                    </div>
                                    <div className='col-md-5 pr-0 filter-ml col-name-filter'>
                                        <input name="filter_nama"
                                               className={this.state.filter_nama === "" ? "form-control bg-image-input filter-name" : "form-control filter-name"}
                                               placeholder={"       "+ i18n.t('carinamakaryawan') + "..."}
                                               type="text"
                                               style={{ backgroundColor: "#F9F9F9", fontSize: "12px", fontWeight: 400, paddingTop: "9px", paddingBottom: "9px" }}
                                               required=""
                                               value={this.state.filter_nama}
                                               onChange={this.handleChange}
                                               onKeyUp={(event) => {
                                                   event.preventDefault()
                                                   if (event.which == 13) {

                                                       this.setState({
                                                           page: 0,
                                                           paging: this.state.paging,
                                                           loading: true
                                                       }, () => {
                                                           this.loadData()
                                                       });
                                                   }
                                               }}
                                        />
                                    </div>
                                    <div className='col-auto pr-0 filter-ml mr-1 col-fltr-absen'>
                                        <button className='btn ipresens-btn-filter px-2 btn-fltr-absen' style={{ width:"94px" }} align="center" onClick={() => this.setState({ filter: !this.state.filter })}><img src={FilterIcon} />Filter</button>
                                    </div>
                                    <div className='col-auto pr-0 filter-ml pr-0'>
                                        <button className='btn ipresens-btn-filter px-2' onClick={
                                            () => {
                                                this.setState({
                                                    loading: true,
                                                    filter_nama: "",
                                                    filter_lokasi: "",
                                                    filter_divisi: "",
                                                    filter_belum_masuk: "",
                                                    filter_belum_keluar: "",
                                                    filter_status: "",
                                                    filter_shift: "",
                                                    data: [],
                                                    dateFilter: moment()
                                                }, () => this.loadData())
                                            }
                                        }><img src={RefreshIcon} /></button>
                                    </div>
                                </div>
                                <div className="col-md-4 row m-0 d-flex justify-content-end col-btn-add-absn">
                                    <div className='col-auto px-0 offset-md-2 mr-1'>
                                            <button onClick={() => {
                                                this.setState({
                                                    addIsOpen: true,
                                                    jam_masuk: moment().format('HH:mm'),
                                                    jam_keluar: moment().format('HH:mm'),
                                                    istirahat_start: null,
                                                    istirahat_end: null,
                                                    karyawan_id: 0,
                                                    shift_nama: "",
                                                    lokasi_masuk: 0,
                                                    status_masuk: "",
                                                    keterangan_masuk: "",
                                                    lokasi_keluar: 0,
                                                    status_keluar: "",
                                                    keterangan_keluar: "",
                                                    karyawan_selected: null,
                                                    shift: [],
                                                    tanggal: moment(this.state.dateFilter._d).format("YYYY-MM-DD")
                                                })
                                            }} className='btn ipresens-btn-color px-3 text-capitalize pull-right width-filter tombol-tambah btn-add-absen'><img src={IconPlusWhite} className='mr-2' alt="" /> {i18n.t('tambahpresensi')}</button>
                                    </div>
                                    <div className='col-auto px-0' align="center">
                                            <Tooltip rigger={'click'} placement="bottomLeft" color={'#FFF'} title={
                                                <div className={'p-2'}>
                                                <a onClick={() => { this.setState({modalexport: true}) }} style={{ marginRight: "50px" }} className={'text-dark'}>Export Excel</a><br />
                                                <a onClick={() => { this.setState({modalexportimage: true}) }} style={{ marginRight: "50px" }} className={'text-dark'}>{i18n.t('exportfotoabsen')}</a><br />
                                                <a onClick={() => { this.setState({modalupdate: true})}} className={'text-dark'}>{i18n.t('clockoutotomatis')}</a><br />
                                                <a onClick={() => {
                                                    this.setState({modalTrash: true});
                                                    this.loadTrash();
                                                }}  className={'text-dark'}>{i18n.t('trashpresensi')}</a><br />
                                            </div>
                                            }>
                                                <a><i
                                                style={{
                                                    width:"15",
                                                    fontSize: 18,
                                                    color: 'rgb(51 51 51)',
                                                    alignItems:"center",
                                                    height:"38px",
                                                    paddingRight: "30px"
                                                }}
                                                className='btn border border-dark border-1 px-2'
                                                ><img src={MoreIcon} /></i></a>
                                            </Tooltip>
                                    </div>
                                </div>
                            </div>
                            {this.state.filter &&
                                <div className="col-xl-12 px-4">
                                    <div className='row mx-0 mb-2'>
                                        <div className='col-md-2 px-1'>
                                            <label className='form-label' style={{ fontSize: "12px" }}>Lokasi</label>
                                            <select className='form-control' name="filter_lokasi" value={this.state.filter_lokasi} onChange={this.handleFilter} style={{ fontSize: "12px" }}>
                                                <option value="">Pilih Lokasi</option>
                                                {this.props.lokasi.length !== 0 &&
                                                    this.props.lokasi.map((item, index) => {
                                                        return (
                                                            <option value={item.lokasi_id}>{item.lokasi_nama}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className='col-md-2 px-1 mb-2'>
                                            <label className='form-label' style={{ fontSize: "12px" }}>Divisi</label>
                                            <select name="filter_divisi" className='form-control' value={this.state.filter_divisi} style={{ fontSize: "12px" }} onChange={this.handleFilter}>
                                                <option value="">Pilih Divisi</option>
                                                {this.props.divisi.length !== 0 &&
                                                    this.props.divisi.map((item, index) => {
                                                        return (
                                                            <option value={item.divisi_id}>{item.divisi_name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className='col-md-2 px-1 mb-2'>
                                            <label className='form-label' style={{ fontSize: "12px" }}>Shift</label>
                                            <select name="filter_shift" className='form-control' value={this.state.filter_shift} style={{ fontSize: "12px" }} onChange={this.handleFilter}>
                                                <option value="">Pilih Shift</option>
                                                {this.props.shift.length !== 0 &&
                                                    this.props.shift.map((item, index) => {
                                                        return (
                                                            <option value={item.sift_id}>{item.sift_name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className='btn-group width-filter px-1'>
                                        <button className='btn text-capitalize ipresens-custom-btn-group filter-masuk' onClick={() => this.setState({ filter_belum_masuk: "", filter_belum_keluar: "", filter_status: "", loading: true }, () => this.loadData())}>Masuk</button>
                                        <button className='btn text-capitalize ipresens-custom-btn-group filter-belum-masuk' onClick={() => this.setState({ filter_belum_masuk: "belum_masuk", filter_belum_keluar: "", filter_status: "", loading: true }, () => this.loadData())}>Belum Masuk</button>
                                        <button className='btn text-capitalize ipresens-custom-btn-group filter-belum-keluar' onClick={() => this.setState({ filter_belum_keluar: "belum_keluar", filter_belum_masuk: "", filter_status: "", loading: true }, () => this.loadData())}>Belum Keluar</button>
                                        <button className='btn text-capitalize ipresens-custom-btn-group filter-terlambat' onClick={() => this.setState({ filter_status: "terlambat", filter_belum_masuk: "", filter_belum_keluar: "", loading: true }, () => this.loadData())}>Terlambat</button>
                                        <button className='btn text-capitalize ipresens-custom-btn-group filter-izin' onClick={() => this.setState({ filter_status: "izin", filter_belum_masuk: "", filter_belum_keluar: "", loading: true }, () => this.loadData())}>Izin</button>
                                    </div>
                                </div>
                            }
                            <div className="card-body daftar-absen padding-list">
                                <div className='row'>
                                    <div className='col pr-custom'>
                                        <div className='table-responsive'>
                                            {!this.state.loading && this.state.data.length === 0 ?
                                                <Empty description={i18n.t("datakosong")}
                                                       image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                                :
                                                <div className='border-table mb-5'>
                                                    <table className='table table-striped table-borderless' style={{ width: "100%" }}>
                                                        <thead>
                                                        <tr>
                                                            <th className="th-font th-padding">#</th>
                                                            <th className="th-font th-padding" style={{ maxWidth: "140px" }}>{i18n.t("nama")}</th>
                                                            <th className="th-font th-padding" style={{ minWidth: "105px" }}><div className='jam-masuk'>{i18n.t("jammasuk")}</div></th>
                                                            <th className="th-font th-padding" style={{ minWidth: "100px" }}>{i18n.t("jamistirahat")}</th>
                                                            <th className="th-font th-padding" style={{ minWidth: "103px" }}>{i18n.t("jamkeluar")}</th>
                                                            <th className="th-font th-padding" style={{ minWidth: "105px" }}>{i18n.t("jamaktual")}</th>
                                                            <th className="th-font th-padding" style={{ minWidth: "135px" }}>{i18n.t("totaljamkerja")}</th>
                                                            <th className="th-font th-padding" style={{ minWidth: "135px" }}>{i18n.t("keterangan")}</th>
                                                            <th className="th-font th-padding text-center" style={{ minWidth: "105px" }}></th>
                                                        </tr>
                                                        </thead>
                                                        <tbody style={{ border: "1px" }}>
                                                        {this.state.loading &&
                                                            <tr>
                                                                <td colSpan={9}>
                                                                    <Spin size="large" tip="Loading..." style={{
                                                                        width: '100%',
                                                                        margin: 'auto',
                                                                        marginBottom: '10px',
                                                                        marginTop: '10px'
                                                                    }} />
                                                                </td>
                                                            </tr>
                                                        }

                                                        {this.state.data.map((item) => {

                                                            return item.absensi.map((abs) => {
                                                                no += 1
                                                                const backgroundColor = (no % 2 === 0) ? "#ffffff" : "#f2f2f2";
                                                                return (
                                                                    <tr>
                                                                        <td className='td-font th-padding'
                                                                            align='left'>{pageNumber(no, this.state.perPage, this.state.currentPage)}</td>
                                                                        <td className='td-font th-padding' align='left'
                                                                            style={{maxWidth: "140px"}}>{item.karyawan_nama}</td>
                                                                        <td className='td-font th-padding' align="left">
                                                                            <div className='jam-masuk'>{this.state.filter_belum_masuk === "belum_masuk" ? "__:__:__" : abs && abs.jam_masuk_absen ? abs.jam_masuk_absen : "__:__:__"}</div>
                                                                        </td>
                                                                        <td className='td-font th-padding'
                                                                            align='left'>{abs && item.absen.istirahat_mulai !== null ? abs.istirahat_mulai.slice(0, 5) : "__:__"}-{abs && abs.istirahat_selesai !== null ? abs.istirahat_selesai.slice(0, 5) : "__:__"}</td>
                                                                        <td className='td-font th-padding' align="left">
                                                                            {abs && abs.jam_keluar_absen !== null ?
                                                                                <div>{this.state.filter_belum_masuk === "belum_masuk" ? "__:__:__" : abs.jam_keluar_absen}</div>
                                                                                :
                                                                                <div>__:__:__</div>
                                                                            }
                                                                        </td>
                                                                        <td className='td-font th-padding'
                                                                            align="left">{this.state.filter_belum_masuk === "belum_masuk" ? "-" : abs.jam_terhitung ? this.convertToHoursMins(Math.abs(abs.jam_terhitung)) : "-"}</td>
                                                                        <td className='td-font th-padding'
                                                                            align="left">{this.state.filter_belum_masuk === "belum_masuk" ? "-" : abs.jam_kerja ? this.convertToHoursMins(Math.abs(abs.jam_kerja)) : "-"}</td>
                                                                        <td className='td-font th-padding border-top-0'
                                                                            align="left">
                                                                            <div className='d-flex flex-nowrap'>
                                                                                {this.state.filter_belum_masuk === "belum_masuk" ?
                                                                                    <div>Belum Masuk</div>
                                                                                    :
                                                                                    <>
                                                                                        <div
                                                                                            className={abs && abs.status_in !== "Masuk Kerja" ? "warning-color mx-0 my-0 px-0" : "success-color mx-0 my-0 px-0"}>{abs && abs.status_in.replace(" Kerja", "")}</div>
                                                                                        <div>-</div>
                                                                                        <div
                                                                                            className={abs && abs.status_out !== "Pulang Kerja" ? "warning-color mx-0 my-0 px-0" : "success-color mx-0 my-0 px-0"}>{abs && abs.status_out.replace(" Kerja", "")}</div>
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                        <td className='td-font th-padding px-0'
                                                                            align="left">
                                                                            {this.state.filter_belum_masuk === "belum_masuk" ? (
                                                                                <Link to="/tambah-presensi"
                                                                                      className='btn button-add px-3 text-capitalize width-filter'>
                                                                                    <img
                                                                                        src={PlusIcon}/> {i18n.t('tambah')}
                                                                                </Link>
                                                                            ) : (
                                                                                <Tooltip trigger="click"
                                                                                         placement="bottom" color="#fff"
                                                                                         title={
                                                                                             <div className={'p-2'}>
                                                                                                 <a onClick={() => {
                                                                                                     this.setState({
                                                                                                         modalHistory: true,
                                                                                                         absensi_id: item.absen.id_absensi
                                                                                                     }, () => {
                                                                                                         this.loadHistory(abs.id_absensi);
                                                                                                     })
                                                                                                 }}
                                                                                                    className={'text-dark'}>Riwayat</a><br/>
                                                                                                 <a onClick={() => {
                                                                                                     this.setState({
                                                                                                         idDetail: abs.id_absensi
                                                                                                     }, () => this.loadDetail('detail'))
                                                                                                 }}
                                                                                                    className={'text-dark'}>Detail</a><br/>
                                                                                                 <a onClick={() => this.setState({
                                                                                                     idDetail: abs.id_absensi,
                                                                                                     editIsOpen: true,
                                                                                                 })}
                                                                                                    className={'text-dark'}>{i18n.t('edit')}</a><br/>
                                                                                                 <a onClick={() => {
                                                                                                     this.setState({
                                                                                                         idDel: abs.id_absensi
                                                                                                     })
                                                                                                     this.wantDelete()
                                                                                                 }}
                                                                                                    className={'text-danger'}>{i18n.t('hapus')}</a>
                                                                                             </div>
                                                                                         }>
                                                                                    <a><i
                                                                                        className="fa fa-ellipsis-v"
                                                                                        style={{
                                                                                            width: 35,
                                                                                            fontSize: 18,
                                                                                            padding: 11,
                                                                                            color: 'rgb(51 51 51)'
                                                                                        }}
                                                                                    ></i></a>
                                                                                </Tooltip>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            });
                                                        })}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mx-0' style={{backgroundColor: "#F5F5F5", width: "100%"}}>
                                {this.state.data.length !== 0 &&
                                    <>
                                        <div className='col font-14' style={{
                                            padding: "auto",
                                            margin: "auto",
                                            fontWeight: 500,
                                            lineHeight: "24px"
                                        }}>{i18n.t("menampilkan")} {this.state.data.length === 0 || this.state.data.length === null ? "0" : this.state.data.length} {i18n.t("dari")} {this.state.totalData} {i18n.t("data")}</div>
                                        <div className='col'>
                                            <ReactPaginate
                                                previousLabel={<img src={ArrowLeft} alt=''/>}
                                                nextLabel={<img src={ArrowRight} alt=''/>}
                                                pageCount={this.state.pageCount}
                                                forcePage={this.state.currentPage}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={this.handlePageClick}
                                                breakClassName={'page-item'}
                                                breakLinkClassName={'page-link custom-page-link'}
                                                containerClassName={'pagination my-2'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link custom-page-link'}
                                                previousClassName={'page-item'}
                                                previousLinkClassName={'page-link custom-prev-next-link'}
                                                nextClassName={'page-item'}
                                                nextLinkClassName={'page-link custom-prev-next-link'}
                                                activeClassName={'active custom-active'}

                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">{i18n.t("tidakadaakseshalaman")}</h5>
                </Fragment>
            )
        }

    }
}

   const mapStateToProps = state => ({
       user: state.Auth.user,
       api_token: state.Auth.api_token,
       lokasi: state.Filter._lokasi,
       divisi: state.Filter._divisi,
       shift: state.Filter._sift
   });

   const mapDispatchToProps = dispatch => ({
       clear_auth: () => dispatch(clear_auth()),
       set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
   })

   export default connect(mapStateToProps, mapDispatchToProps)(GoogleApiWrapper({
       apiKey: APIKEY
   })(List_absensi));
