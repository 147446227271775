import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import i18n from "i18next";
import {clear_auth, set_auth} from "../../reducers/Auth";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import SearchIcon from "../../assets/icons/search.svg";
import RefreshIcon from "../../assets/icons/refresh.svg";
import PlusIcon from "../../assets/icons/plus-white.svg";
import {Empty, Spin, Tooltip} from "antd";
import EmptyDataImage from "../../assets/images/data-kosong.png";
import Paging from "../paging";
import {Modal, ModalHeader, ModalBody, ModalFooter, Modal as ModalRs} from "reactstrap";
import SaveIcon from "../../assets/icons/save.svg";
import Swal from "sweetalert2";
import {BACKEND_API_HOST, DEFAULT_IMG} from "../../helper/host";
import moment from "moment";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg";
import MoreIcon from "../../assets/icons/more.svg";
import {pageNumber} from "../../functions/functionGeneral";
import ReactPaginate from "react-paginate";
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import ArrowRight from "../../assets/icons/arrow-right.svg";
import Restore from "../../assets/icons/update.svg";
import Trash from "../../assets/icons/trash-custom.svg";
import AddMataPelajaran from "./add";
import EditMataPelajaran from "./edit";
import HistoryMataPelajaran from "./history";
import TrashMataPelajaran from "./trash";

export class List_Mapel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data            : [],
            mapel_id        : 0,
            dataHistory     : [],
            historyLast     : [],
            dataTrash       : [],
            loadingTrash    : false,
            modalTrash      : false,
            currentPage     : 0,
            currentPageTrash: 0,
            paginationTrash : 4,
            checkAll        : false,
            checkedTrash    : [],
            add_visible     : false,
            edit_visible    : false,
            history_visible : false,
            trash_visible   : false,
        }
        this.handleChange = this.handleChange.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.handleClear = this.handleClear.bind(this)
        this.importExcel = this.importExcel.bind(this)
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    handleClear() {
        this.setState({
            modaladd            : false,
            modaledit           : false,
            modalHistory        : false,
            modalimport         : false,
            importFile          : null,
            mapel_nama          : "",
            mapel_kkm           : "",
            mapel_nilai_start   : "",
            mapel_nilai_end     : "",
            prosesSimpan        : false,
        });
    };

    loadData() {
        this.setState({loading: true})
        let form = {
            filter_nama: this.state.filter_nama,
            paginate: 10,
            page: this.state.currentPage + 1,
        }
        this.props.request("mapel/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        loading: false,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                    })
                }
            });
    }

    handleDelete() {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('andayakinhapus'),
            icon: 'warning',
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        mapel_id    : this.state.mapel_id
                    }
                    this.setState({
                        data    : [],
                        loading : true
                    })
                    this.props.request("mapel/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: i18n.t(response.message),
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    handlePageClick(data) {
        let selected = data.selected
        this.setState({ currentPage: selected }, () => {
            this.loadData()
        });
    }

    exportData = () => {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'mapel/export?token=' + AuthKey, "_blank")
    }

    exportTemplate = () => {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'mapel/export-template?token=' + AuthKey, "_blank")
    }

    importExcel() {
        this.setState({
            importLoading: true
        })

        var AuthKey = localStorage.getItem('Presensi-Auth-Key')

        let formData = new FormData()
        formData.append('file', this.state.importFile)
        formData.append('token', AuthKey)

        fetch(BACKEND_API_HOST + 'mapel/import', {
            method: 'POST',
            headers: {
                'Authorization': 'bearer ' + AuthKey
            },
            body: formData
        })
            .catch((error) => {
                console.error(error);
            })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t(response.message),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                    this.loadData()
                }
                else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t(response.message),
                        icon: 'warning',
                        showConfirmButton: true,
                    })
                }
                this.setState({ importLoading: false })
                this.handleClear()
            })
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        let no = 0;
        if (!this.props.user.user_level.level_page_limited || this.props.user.user_level.level_page_limited.search("Mata Pelajaran") >= 0) {
            moment.locale(i18n.language)
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{i18n.t("daftarmapel")} - iPresens</title>
                        <meta name="description" content={i18n.t("daftarmapel")} />
                    </Helmet>
                    <Breadcrumb parent={i18n.t("manajemenmapel")} title={i18n.t("daftarmapel")} setGuide={() => { this.setState({ guide: true }) }} />

                    <AddMataPelajaran
                        add_visible={this.state.add_visible}
                        request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                        changeState={(state, callback) => {
                            this.setState(state, () => {
                                if (typeof callback != 'undefined')
                                    callback()
                            })
                        }}
                        loadData={() => this.loadData()}
                    />

                    <EditMataPelajaran
                        edit_visible={this.state.edit_visible}
                        mapel_id={this.state.mapel_id}
                        request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                        changeState={(state, callback) => {
                            this.setState(state, () => {
                                if (typeof callback != 'undefined')
                                    callback()
                            })
                        }}
                        loadData={() => this.loadData()}
                    />

                    <HistoryMataPelajaran
                        history_visible={this.state.history_visible}
                        mapel_id={this.state.mapel_id}
                        request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                        changeState={(state, callback) => {
                            this.setState(state, () => {
                                if (typeof callback != 'undefined')
                                    callback()
                            })
                        }}
                        loadData={() => this.loadData()}
                    />

                    <TrashMataPelajaran
                        trash_visible={this.state.trash_visible}
                        request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                        changeState={(state, callback) => {
                            this.setState(state, () => {
                                if (typeof callback != 'undefined')
                                    callback()
                            })
                        }}
                        loadData={() => this.loadData()}
                    />

                    <Modal isOpen={this.state.modalimport} toggle={this.handleClear}>
                        <ModalHeader toggle={this.handleClear}>
                            <div>{i18n.t('importjadwal')}</div>
                        </ModalHeader>
                        <ModalBody style={{ padding: "20px" }}>
                            <div className='row mb-2'>
                                <div className="col">
                                    <a style={{color: "#5D6F80", cursor: "default"}}>{i18n.t('importcutitext')}</a><br/>
                                    <a onClick={() => this.exportTemplate()} style={{color: "#057CE4"}}>{i18n.t('importcutitemplates')}</a>
                                    <div style={{
                                        position: 'relative',
                                        width: '100%',
                                        backgroundColor: '#F9F9F9',
                                        padding: 16,
                                        borderRadius: 6,
                                        marginTop: 8,
                                        border: "1px dashed #DDDDDD"
                                    }}>
                                        <input type='file'
                                               accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                               onChange={(event) => {
                                                   this.setState({
                                                       importFile: event.target.files[0],
                                                   })
                                               }}/><br/>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            {[
                                this.state.importLoading ?
                                    <button className={'btn ipresens-btn-color'} style={{textTransform: "none", width: "100%"}}
                                            disabled={true}><i className="fa fa-refresh fa-spin"></i>{i18n.t('sedangmenguploaddata')}</button>
                                    :
                                    <button className={this.state.importFile === null ? 'btn bg-secondary-custom' : 'btn ipresens-btn-color'} style={{textTransform: "none", width: "100%"}} disabled={this.state.importFile === null} onClick={() => this.importExcel()}>Import Data</button>
                            ]}
                        </ModalFooter>
                    </Modal>
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-5 d-flex justify-content-end flex-wrap">
                                        <div className="flex-grow-1 mr-2">
                                            <div className='input-group btn-srch-kbeban'>
                                                    <span className='input-group-text izin-search-karyawan-img'>
                                                        <img alt='' src={SearchIcon}/>
                                                    </span>
                                                <input type='text' onChange={this.handleChange}
                                                       name='filter_nama' value={this.state.filter_nama}
                                                       className='form-control izin-search-karyawan-form'
                                                       style={{padding: "12px 10px",}}
                                                       placeholder={i18n.t("namamapel") + "..."}
                                                       onKeyDown={(e) => {
                                                           if (e.key === "Enter") {
                                                               this.loadData()
                                                           }
                                                       }}>
                                                </input>
                                            </div>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <button className='btn border px-2'>
                                                <img alt="" src={RefreshIcon} onClick={() => {
                                                    this.setState({
                                                        filter_nama: ""
                                                    }, () => this.loadData())
                                                }}/></button>
                                        </div>
                                    </div>
                                    <div className="col-md-7 d-flex justify-content-end flex-wrap">
                                        <div className="flex-grow-1 mr-2">
                                            <button
                                                className="btn ipresens-btn-color text-capitalize width-filter pull-right"
                                                onClick={() => {
                                                    this.setState({ add_visible: true});
                                                }}
                                            >
                                                <img src={PlusIcon} alt=""/> {i18n.t('tambahmapel')}
                                            </button>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <Tooltip zIndex={1} trigger={'click'} placement="bottomLeft" color={'#FFF'}
                                                     title={
                                                         <div className={'p-2'}>
                                                             <a onClick={() => {
                                                                 this.setState({modalimport: true})
                                                             }}
                                                                style={{marginRight: "50px"}}
                                                                className={'text-dark'}>Import Excel</a><br/>
                                                             <a onClick={this.exportData}
                                                                style={{marginRight: "50px"}}
                                                                className={'text-dark'}>{i18n.t('eksporexcel')}</a><br/>
                                                             <a onClick={() => this.setState({ trash_visible: true })} style={{marginRight: "50px"}}
                                                                 className={'text-dark'}
                                                             >{i18n.t('sampahmapel')}</a>
                                                         </div>
                                                     }>
                                                <a>
                                                    <button disabled={this.state.kelas_id === ''}
                                                            className='btn border border-dark border-1 px-2'
                                                    ><img alt="More" src={MoreIcon}/></button>
                                                </a>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="table-responsive">
                                            {!this.state.loading && this.state.data.length === 0 ?
                                                <Empty description="Data Kosong"
                                                       image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                                :
                                                <div>
                                                    <table className="table mb-5 table-striped table-borderless">
                                                        <thead>
                                                        <tr>
                                                            <th className='lembur-th-style text-center' width="50">#
                                                            </th>
                                                            <th className='lembur-th-style'>{i18n.t("namamapel")}</th>
                                                            <th className='lembur-th-style'>{i18n.t("guru")}</th>
                                                            <th className='lembur-th-style text-center'>{i18n.t("mapelkkm")}</th>
                                                            <th className='lembur-th-style text-center'>{i18n.t("nilaiawal")}</th>
                                                            <th className='lembur-th-style text-center'>{i18n.t("nilaiakhir")}</th>
                                                            <th className='lembur-th-style text-center'>{i18n.t("aksi")}</th>
                                                        </tr>
                                                        </thead>
                                                        <Tbody>
                                                            {this.state.loading ?
                                                                <Tr>
                                                                    <Td colSpan="9" style={{
                                                                        textAlign: "center",
                                                                        backgroundColor: "#FFFFFF"
                                                                    }}>
                                                                        <Spin size="large"
                                                                              tip={i18n.t("sedangmemuatdata") + ' ...'}
                                                                              style={{
                                                                                  width: '100%',
                                                                                  margin: 'auto',
                                                                                  marginBottom: '10px',
                                                                                  marginTop: '10px'
                                                                              }}/>
                                                                    </Td>
                                                                </Tr>
                                                                :
                                                                this.state.data.map((item, index) => {
                                                                    no += 1
                                                                    return (
                                                                        <Tr key={index}>
                                                                            <Td className="lembur-td-style text-center">{pageNumber(no, this.state.perPage, this.state.currentPage)}</Td>
                                                                            <Td className="lembur-td-style fw-900">{item.mapel_nama}</Td>
                                                                            <Td className="lembur-td-style">{item.guru ? item.guru?.guru_nama : '-'}</Td>
                                                                            <Td className="lembur-td-style text-center">{item.mapel_kkm}</Td>
                                                                            <Td className="lembur-td-style text-center">{item.mapel_nilai_start}</Td>
                                                                            <Td className="lembur-td-style text-center">{item.mapel_nilai_end}</Td>
                                                                            <Td className="lembur-td-style text-center">
                                                                                <Tooltip trigger={'click'} zIndex={1}
                                                                                         placement="bottom"
                                                                                         color={'#fff'} title={
                                                                                    <div className={'text-dark'}>
                                                                                        <a onClick={() => this.setState({
                                                                                            history_visible: true, mapel_id: item.mapel_id
                                                                                        })} className={'text-dark'}>{i18n.t("riwayat")}</a><br/>
                                                                                        <a onClick={() => this.setState({ mapel_id: item.mapel_id, edit_visible: true })}>{i18n.t("edit")}</a><br/>
                                                                                        <a onClick={() => {
                                                                                            this.setState({
                                                                                                mapel_id: item.mapel_id
                                                                                            })
                                                                                            this.handleDelete()
                                                                                        }}
                                                                                           className={'text-danger'}>{i18n.t("hapus")}</a>
                                                                                    </div>
                                                                                }>
                                                                                    <a><i
                                                                                        className="fa fa-ellipsis-v"
                                                                                        style={{
                                                                                            width: 35,
                                                                                            fontSize: 18,
                                                                                            padding: 11,
                                                                                            color: 'rgb(51 51 51)'
                                                                                        }}
                                                                                    ></i></a>
                                                                                </Tooltip>
                                                                            </Td>
                                                                        </Tr>
                                                                    )
                                                                })}
                                                        </Tbody>
                                                    </table>
                                                </div>
                                            }
                                        </div>
                                        <div className="row">
                                            <div className='col font-14' style={{
                                                padding: "auto",
                                                margin: "auto",
                                                fontWeight: 500,
                                                lineHeight: "24px"
                                            }}>
                                                {i18n.t("menampilkan")} {this.state.data.length === 0 || this.state.data.length === null ? "0" : this.state.data.length} {i18n.t("dari")} {this.state.totalData} {i18n.t("data")}.
                                            </div>
                                            <div className="col">
                                                <Paging
                                                    className="float-right"
                                                    handlePageClick={this.handlePageClick}
                                                    pageCount={this.state.pageCount}
                                                    currentPage={this.state.currentPage}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">{i18n.t("tidakadaakseshalaman")}</h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(List_Mapel);
