import React, { Component, Fragment } from 'react';
import i18n from "i18next";
import {clear_auth, set_auth} from "../../reducers/Auth";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import Breadcrumb from "../common/breadcrumb";
import {Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Modal as ModalRs} from "reactstrap";
import PlusIcon from "../../assets/icons/plus-white.svg";
import SaveIcon from "../../assets/icons/save.svg";
import IconPlusWhite from "../../assets/icons/IconPlusWhite.svg";
import TrashIcon from "../../assets/icons/trash.svg";
import Swal from "sweetalert2";
import {Empty, Spin, Tooltip} from "antd";
import EmptyDataImage from "../../assets/images/data-kosong.png";
import {BACKEND_API_HOST, DEFAULT_IMG} from "../../helper/host";
import MoreIcon from "../../assets/icons/more.svg";
import moment from "moment";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg";
import Paging from "../paging";
import {pageNumber} from "../../functions/functionGeneral";
import ReactPaginate from "react-paginate";
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import ArrowRight from "../../assets/icons/arrow-right.svg";
import Restore from "../../assets/icons/update.svg";
import Trash from "../../assets/icons/trash-custom.svg";

export class List_Jadwal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading         : true,
            prosesSimpan    : false,
            kelas_id        : '',
            jadwal_senin    : [],
            jadwal_selasa   : [],
            jadwal_rabu     : [],
            jadwal_kamis    : [],
            jadwal_jumat    : [],
            jadwal_sabtu    : [],
            jadwal_minggu   : [],
            semester        : [],
            mapel           : [],
            dataHistory     : [],
            rows            : [{}],
            importFile      : null,
            historyLast     : [],
            modalTrash      : false,
            dataTrash       : [],
            loadingTrash    : false,
            currentPageTrash: 0,
            paginationTrash : 4,
            checkAll        : false,
            checkedTrash    : [],
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleClear = this.handleClear.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
        this.importData = this.importData.bind(this)
        this.handlePageHistory = this.handlePageHistory.bind(this)
        this.handlePageTrash = this.handlePageTrash.bind(this)
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this)
        this.handleCheckBoxAllChange = this.handleCheckBoxAllChange.bind(this)
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    handleRowChange = (index, field, value) => {
        const newRows = [...this.state.rows];
        newRows[index][field] = value;
        this.setState({ rows: newRows });
    };

    handleClear() {
        this.setState({
            modaladd        : false,
            modaledit       : false,
            importkelas     : false,
            importall       : false,
            modalexport     : false,
            modalHistory    : false,
            semester_id     : "",
            hari            : "",
            jamMulai        : "",
            jamSelesai      : "",
            mapel_id        : "",
            rows            : [{}],
            importFile      : null,
            prosesSimpan    : false,
        });
    };

    handleFilter(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;

        let updates = {
            [name]: value,
            data: [],
            loading: true,
        };

        if (name === "kelas_id") {
            const selectedKelas = this.props.divisi?.find(item => item.divisi_id === parseInt(value, 10));

            updates.kelas_id = value;
            updates.nama_kelas = selectedKelas ? selectedKelas.divisi_name : '';
        } else if (name === "semester_active") {
            const selectedSemester = this.state.semester?.find(item => item.semester_id === parseInt(value, 10));
            updates.semester_active = value;
            updates.semester_type = selectedSemester ? selectedSemester.semester_type : '';
            updates.year = selectedSemester ? new Date(selectedSemester.semester_start_date).getFullYear() : '';
        }
        this.setState(updates, () => this.loadData());
    }

    addRow = () => {
        this.setState(prevState => ({
            rows: [...prevState.rows, { mapel_id: '', hari: '', jamMulai: '', jamSelesai: '' }]
        }));
    };

    removeRow = () => {
        this.setState(prevState => ({
            rows: prevState.rows.slice(0, -1)
        }));
    };

    loadData() {
        this.setState({loading : true})
        let form = {
            kelas_id : this.state.kelas_id,
            semester_id : this.state.semester_active
        }
        this.props.request("jadwal/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        jadwal_senin: response.jadwal_senin,
                        jadwal_selasa: response.jadwal_selasa,
                        jadwal_rabu: response.jadwal_rabu,
                        jadwal_kamis: response.jadwal_kamis,
                        jadwal_jumat: response.jadwal_jumat,
                        jadwal_sabtu: response.jadwal_sabtu,
                        jadwal_minggu: response.jadwal_minggu,
                        loading: false
                    })
                }
            });
    }

    loadSemester() {
        this.props.request("semester/load-data", 'POST')
            .then(response => {
                if (response.success) {
                    const semester = response.data.data
                    const activeSemester = semester.find(item => item.semester_status === 'active')
                    this.setState({
                        semester        : semester,
                        semester_active : activeSemester ? activeSemester.semester_id : "",
                        semester_type   : activeSemester ? activeSemester.semester_type : "",
                        year            : activeSemester ? new Date(activeSemester.semester_start_date).getFullYear() : '',
                        loading         : false
                    })
                }
            });
    }

    loadMapel() {
        this.props.request("mapel/list", 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        mapel: response.data.data,
                        loading: false
                    })
                }
            });
    }

    handleAdd = () => {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            kelas_id    : this.state.kelas_id,
            semester_id : this.state.semester_id,
            rows        : this.state.rows,
        }

        this.props.request("jadwal/add", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t(response.message),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                    this.handleClear()
                    this.loadData()
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t(response.message),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.setState({prosesSimpan: false})
                }
            })
    }

    handleEdit = () => {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            jadwal_id   : this.state.jadwal_id,
            kelas_id    : this.state.kelas_id,
            semester_id : this.state.semester_id,
            hari        : this.state.hari,
            jamMulai    : this.state.jamMulai,
            jamSelesai  : this.state.jamSelesai,
            mapel_id    : this.state.mapel_id,
        }

        this.props.request("jadwal/edit", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t(response.message),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                    this.handleClear()
                    this.loadData()
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t(response.message),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.setState({prosesSimpan: false})
                }
            })
    }

    handleDelete() {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('andayakinhapus'),
            icon: 'warning',
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        jadwal_id    : this.state.jadwal_id
                    }
                    this.setState({
                        loading : true
                    })
                    this.props.request("jadwal/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: i18n.t(response.message),
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    exportData = (kelas_id = null, semester_id = null) => {
        const AuthKey = localStorage.getItem('Presensi-Auth-Key')
        const url = `${BACKEND_API_HOST}jadwal/export?token=${AuthKey}${kelas_id ? `&kelas_id=${kelas_id}` : ''}${semester_id ? `&semester_id=${semester_id}` : ''}`
        window.open(url, "_blank")
    };

    exportTemplate = (kelas_id = null) => {
        const AuthKey = localStorage.getItem('Presensi-Auth-Key')
        const url = `${BACKEND_API_HOST}jadwal/export-template?token=${AuthKey}${kelas_id ? `&kelas_id=${kelas_id}` : ''}`
        window.open(url, "_blank")
    };

    importData = (kelas_id = null) => {
        const { importFile } = this.state;
        const AuthKey = localStorage.getItem('Presensi-Auth-Key');
        const formData = new FormData();
        formData.append('file', importFile);
        formData.append('token', AuthKey);
        if (kelas_id) formData.append('kelas_id', kelas_id);

        this.setState({ importLoading: true });
        fetch(`${BACKEND_API_HOST}jadwal/import`, {
            method: 'POST',
            headers: {
                Authorization: `bearer ${AuthKey}`,
            },
            body: formData,
        })
            .then((res) => res.json())
            .then((response) => {
                Swal.fire({
                    title: response.success ? i18n.t('berhasil') : i18n.t('gagal'),
                    text: i18n.t(response.message),
                    icon: response.success ? 'success' : 'warning',
                    showConfirmButton: false,
                    timer: response.success ? 800 : 1200,
                });
                this.setState({ importLoading: false, modalimport: !response.success });
                if (response.success) {
                    this.handleClear();
                    this.loadData();
                }
            })
            .catch(console.error);
    };

    loadHistory(id) {
        this.setState({
            dataHistory: [],
            loadingHistory: true
        },()=>{
            let form = {
                id: id ? id : this.state.jadwal_id,
                paging : 2 ,
                page: this.state.currentPageHistory + 1,
            };

            this.props.request("jadwal/history", form, 'POST')
                .then(response => {
                    this.setState({
                        loadingHistory: false
                    })
                    if (response.succes) {
                        this.setState({
                            dataHistory: response.data.data,
                            historyLast: response.history,
                            pageCountHistory: response.data.last_page,
                            totalDataHistory: response.data.total,
                            totalPerpageHistory: response.data.to,
                            perPageHistory: response.data.per_page,
                        });
                    }
                    else {
                        this.setState({
                            modalHistory: false,
                            jadwal_id   : ''
                        });
                    }
                });
        })
    }

    handlePageHistory(dataHistory) {
        let selected = dataHistory.selected;
        this.setState({ currentPageHistory: selected, dataHistory: [], loadingHistory: true }, () => {
            this.loadHistory();
        });
    }

    handlePageTrash(dataTrash) {
        let selected = dataTrash.selected; // zero based index
        this.setState({ currentPageTrash: selected, dataTrash: [], loadingTrash: true }, () => {
            this.loadTrash();
        });
    }

    handleCheckBoxChange(id) {
        this.setState((prevState) => {
            let updatedcheckedTrash = [...prevState.checkedTrash];
            if (updatedcheckedTrash.includes(id)) {
                updatedcheckedTrash = updatedcheckedTrash.filter(item => item !== id);
            } else {
                updatedcheckedTrash.push(id);
            }

            if (updatedcheckedTrash.length !== this.state.totalDataTrash) {
                this.setState({
                    checkAll: false
                })
            } else if (updatedcheckedTrash.length === this.state.totalDataTrash) {
                this.setState({
                    checkAll: true
                })
            }

            return {
                checkedTrash: updatedcheckedTrash,
            };
        });
    }

    handleCheckBoxAllChange() {
        this.setState(prevState => ({
            checkAll: !prevState.checkAll
        }), () => {
            this.state.checkAll
                ? this.setState({
                    checkedTrash: this.state.dataTrash.map(item => item.jadwal_id)
                })
                : this.setState({
                    checkedTrash: []
                })
        })
    }

    loadTrash() {
        this.setState({
            dataTrash: [],
            loadingTrash: true
        }, () => {
            let form = {
                paginate: this.state.paginationTrash,
                page: this.state.currentPageTrash + 1,
            };

            this.props.request("jadwal/trash", form, 'POST')
                .then(response => {
                    this.setState({
                        loadingTrash: false
                    })
                    if (response.success) {
                        this.setState({
                            dataTrash: response.data.data,
                            pageCountTrash: response.data.last_page,
                            totalDataTrash: response.data.total,
                            totalPerpageTrash: response.data.to,
                            perPageTrash: response.data.per_page,
                        });
                    } else {
                        this.setState({
                            modalTrash: false,
                            jadwal_id: ''
                        });
                    }
                });
        })
    }

    pemulihanJadwal = () => {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('pulihkanjadwal'),
            icon: 'warning',
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed){
                    this.setState({
                        data: [],
                        loading: true
                    })
                    let form = {
                        id: this.state.checkedTrash
                    }
                    this.props.request("jadwal/restore", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: i18n.t(response.message),
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.setState({
                                    checkedTrash: [],
                                    checkAll: false,
                                    modalTrash: false,
                                    restoreJadwal: false
                                })
                            }
                            this.loadData()
                        });
                }
            })
    }

    trashJadwal = () => {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('hapusjadwalpermanen'),
            icon: 'warning',
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed){
                    this.setState({
                        data: [],
                        loading: true
                    })
                    let form = {
                        jadwal_ids: this.state.checkedTrash
                    }
                    this.props.request("jadwal/delete-permanent", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: i18n.t(response.message),
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.setState({
                                    checkedTrash: [],
                                    checkAll: false,
                                    modalTrash: false,
                                    permanentDelete: false
                                })
                            }
                            this.loadData()
                        });
                }
            })
    }

    revertJadwal() {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('revertdata'),
            icon: 'warning',
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        id: this.state.idRev,
                        historyId: this.state.id_History
                    }
                    this.setState({
                        data: [],
                        loading: true
                    })
                    this.props.request("jadwal/revert", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                                this.setState({
                                    modalHistory: false,
                                    currentPageHistory: 0
                                })
                            } else {
                                Swal.fire({
                                    title: i18n.t('gagal'),
                                    text: i18n.t(response.message),
                                    icon: 'warning',
                                    confirmButtonText: 'OK'
                                })
                                this.loadData()
                                this.setState({
                                    loading: false
                                })
                            }
                        });
                }
            })
    }

    componentDidMount() {
        this.loadSemester()
        this.loadMapel()
    }

    render() {
        moment.locale(i18n.language);
        function formatTime(time) {
            if (!time) return ""
            const [hours, minutes] = time.split(":").map(Number)
            const formattedHours = hours.toString().padStart(2, "0")
            const formattedMinutes = minutes.toString().padStart(2, "0")
            return `${formattedHours}:${formattedMinutes}`
        }

        const { loading, jadwal_senin, jadwal_selasa, jadwal_rabu, jadwal_kamis, jadwal_jumat, jadwal_sabtu, jadwal_minggu } = this.state
        const jadwalHarian = [
            { title: "jadwalsenin", jadwal: jadwal_senin },
            { title: "jadwalselasa", jadwal: jadwal_selasa },
            { title: "jadwalrabu", jadwal: jadwal_rabu },
            { title: "jadwalkamis", jadwal: jadwal_kamis },
            { title: "jadwaljumat", jadwal: jadwal_jumat },
            { title: "jadwalsabtu", jadwal: jadwal_sabtu },
            { title: "jadwalminggu", jadwal: jadwal_minggu },
        ]
        const JadwalTable = ({ title, jadwal }) => (
            <div className="col-md-6">
                <div className="table-responsive">
                    <h4 className="text-center fw-900 mb-2 mt-2">{i18n.t(`${title}`)}</h4>
                    {jadwal.length === 0 ? (
                        <Empty
                            description={i18n.t(`tidakada${title}untukkelas`, { nama_kelas: this.state.nama_kelas }) + (' ') +
                                i18n.t('semester_format', {
                                    semesterType: i18n.t(this.state.semester_type),
                                    year: this.state.year,
                                })}
                            image={EmptyDataImage}
                            className="ipresens-image-empty mb-5"
                        />
                    ) : (
                        <table className="table table-striped table-borderless mb-5">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th className='lembur-th-style'>{i18n.t("jammulai")}</th>
                                <th className='lembur-th-style'>{i18n.t("jamselesai")}</th>
                                <th className='lembur-th-style'>{i18n.t("mapel")}</th>
                                <th className='lembur-th-style'></th>
                            </tr>
                            </thead>
                            <tbody>
                            {jadwal.map((item, index) => (
                                <tr key={index}>
                                    <td className="lembur-td-style">{index + 1}</td>
                                    <td className="lembur-td-style">{formatTime(item.jadwal_jam_mulai)}</td>
                                    <td className="lembur-td-style">{formatTime(item.jadwal_jam_selesai)}</td>
                                    <td className="lembur-td-style">{item.mapel.mapel_nama}</td>
                                    <td className="lembur-td-style">
                                        <Tooltip trigger={'click'} placement="bottom" color={'#fff'} title={
                                            <div className={'text-dark'}>
                                                <a onClick={() => {
                                                    this.setState({
                                                        modalHistory: true,
                                                        jadwal_id: item.jadwal_id,
                                                        currentPageHistory: 0
                                                    }, () => {
                                                        this.loadHistory(item.jadwal_id);
                                                    })
                                                }}
                                                   className={'text-dark'}>{i18n.t("riwayat")}</a><br/>
                                                <a className={'text-dark'}
                                                   onClick={() => {
                                                       this.setState({
                                                           jadwal_id: item.jadwal_id,
                                                           semester_id: item.semester_id,
                                                           mapel_id: item.mapel_id,
                                                           hari: item.jadwal_hari,
                                                           jamMulai: item.jadwal_jam_mulai,
                                                           jamSelesai: item.jadwal_jam_selesai,
                                                           modaledit: true
                                                       })
                                                   }}>{i18n.t("edit")}</a><br/>
                                                <a className={'text-danger'}
                                                   onClick={() => {
                                                       this.setState({
                                                           jadwal_id: item.jadwal_id
                                                       })
                                                       this.handleDelete()
                                                   }}>{i18n.t("hapus")}</a>
                                            </div>
                                        }>
                                            <a><i
                                                className="fa fa-ellipsis-v"
                                                style={{
                                                    width: 35,
                                                    fontSize: 18,
                                                    color: 'rgb(51 51 51)'
                                                }}
                                            ></i></a>
                                        </Tooltip>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        );

        if (!this.props.user.user_level.level_page_limited || this.props.user.user_level.level_page_limited.search("Jadwal") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8"/>
                        <title>{i18n.t('daftarjadwal')} - iPresens</title>
                        <meta name="description" content="Daftar Jadwal"/>
                    </Helmet>
                    <Breadcrumb parent={i18n.t("manajemenjadwal")} title={i18n.t("daftarjadwal")} setGuide={() => {this.setState({guide: true})}}/>
                    <Modal style={{maxWidth: 1000}} zIndex={999} isOpen={this.state.modaladd} toggle={this.handleClear}>
                        <ModalHeader toggle={this.handleClear}>
                            <div className='pengumuman-modal-header-font'>{i18n.t("tambahjadwal")}</div>
                        </ModalHeader>
                        <ModalBody className="pengumuman-modal-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className="mb-2 pengumuman-label-input">{i18n.t("semester")}</label>
                                        <div>
                                            <select style={{fontSize: "12px"}} name="semester_id"
                                                    value={this.state.semester_id}
                                                    className="form-control" onChange={this.handleChange}>
                                                <option style={{fontSize: "12px"}}>{i18n.t('pilihsemester')}</option>
                                                {this.state.semester.map((item) => {
                                                    return (
                                                        <option style={{fontSize: "12px"}} value={item.semester_id}>
                                                            {i18n.t('semester_format', {
                                                                semesterType: i18n.t(item.semester_type),
                                                                year: new Date(item.semester_start_date).getFullYear(),
                                                            })}
                                                        </option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    {this.state.rows.map((row, index) => (
                                        <div className="form-group" key={index}>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    {index === 0 && (
                                                        <label className="mb-2 pengumuman-label-input">
                                                        {i18n.t("hari")}
                                                        </label>
                                                    )}
                                                    <select
                                                        name={`hari_${index}`}
                                                        className="form-control mb-2"
                                                        style={{fontSize: "12px", borderRadius: "4px"}}
                                                        value={row.hari}
                                                        onChange={(e) => this.handleRowChange(index, 'hari', e.target.value)}
                                                    >
                                                        <option value="">{i18n.t("pilihhari")}</option>
                                                        <option value="senin">{i18n.t("senin")}</option>
                                                        <option value="selasa">{i18n.t("selasa")}</option>
                                                        <option value="rabu">{i18n.t("rabu")}</option>
                                                        <option value="kamis">{i18n.t("kamis")}</option>
                                                        <option value="jumat">{i18n.t("jumat")}</option>
                                                        <option value="sabtu">{i18n.t("sabtu")}</option>
                                                        <option value="minggu">{i18n.t("minggu")}</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-3">
                                                    {index === 0 && (
                                                        <label className="mb-2 pengumuman-label-input">
                                                            {i18n.t("jammulai")}
                                                        </label>
                                                    )}
                                                    <input
                                                        name={`jamMulai_${index}`}
                                                        className="form-control mb-2 py-2"
                                                        style={{fontSize: "12px"}}
                                                        placeholder={i18n.t('inputjammulai')}
                                                        type="time"
                                                        value={row.jamMulai}
                                                        onChange={(e) => this.handleRowChange(index, 'jamMulai', e.target.value)}
                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    {index === 0 && (
                                                        <label className="mb-2 pengumuman-label-input">
                                                            {i18n.t("jamselesai")}
                                                        </label>
                                                    )}
                                                    <input
                                                        name={`jamSelesai_${index}`}
                                                        className="form-control mb-2 py-2"
                                                        style={{fontSize: "12px"}}
                                                        placeholder={i18n.t('inputjamselesai')}
                                                        type="time"
                                                        value={row.jamSelesai}
                                                        onChange={(e) => this.handleRowChange(index, 'jamSelesai', e.target.value)}
                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    {index === 0 && (
                                                        <label className="mb-2 pengumuman-label-input">
                                                            {i18n.t("mapel")}
                                                        </label>
                                                    )}
                                                    <select
                                                        style={{fontSize: "12px"}}
                                                        name={`mapel_id_${index}`}
                                                        className="form-control mb-2"
                                                        value={row.mapel_id}
                                                        onChange={(e) => this.handleRowChange(index, 'mapel_id', e.target.value)}
                                                    >
                                                        <option value="">{i18n.t('pilihmapel')}</option>
                                                        {this.state.mapel.map((item) => (
                                                            <option
                                                                key={item.mapel_id}
                                                                style={{fontSize: "12px"}}
                                                                value={item.mapel_id}
                                                            >
                                                                {item.mapel_nama}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    <div className="form-group" style={{marginTop: "10px", display: "flex", gap: "10px"}}>
                                        <button className="btn ipresens-btn-color mt-0 text-capitalize pull-left button-w-full mr-2" onClick={this.addRow}>
                                            <img src={IconPlusWhite} alt="" className="mr-2 mb-1"/> {i18n.t('tambah')}
                                        </button>
                                        {this.state.rows.length > 1 && (
                                            <button className="btn ipresens-delete-button" onClick={this.removeRow}
                                            >
                                                <img src={TrashIcon} alt="" className="mr-2 mb-1"/>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <button onClick={this.handleClear} className='ipresens-cancel-button'>{i18n.t("batal")}</button>
                            <button className='ipresens-save-button' disabled={this.state.prosesSimpan} onClick={this.handleAdd}>
                                <img alt='' src={SaveIcon}/> {this.state.prosesSimpan ? i18n.t("menyimpan") : i18n.t("simpan")}
                            </button>
                        </ModalFooter>
                    </Modal>
                    <Modal style={{maxWidth: 1000}} zIndex={999} isOpen={this.state.modaledit} toggle={this.handleClear}>
                        <ModalHeader toggle={this.handleClear}>
                            <div className='pengumuman-modal-header-font'>{i18n.t("ubahjadwal")}</div>
                        </ModalHeader>
                        <ModalBody className="pengumuman-modal-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className="mb-2 pengumuman-label-input">{i18n.t("semester")}</label>
                                        <div>
                                            <select style={{fontSize: "12px"}} name="semester_id"
                                                    value={this.state.semester_id}
                                                    className="form-control" onChange={this.handleChange}>
                                                <option style={{fontSize: "12px"}}>{i18n.t('pilihsemester')}</option>
                                                {this.state.semester.map((item) => {
                                                    return (
                                                        <option style={{fontSize: "12px"}} value={item.semester_id}>
                                                            {i18n.t('semester_format', {
                                                                semesterType: i18n.t(item.semester_type),
                                                                year: new Date(item.semester_start_date).getFullYear(),
                                                            })}
                                                        </option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <label className="mb-2 pengumuman-label-input">
                                                    {i18n.t("hari")}
                                                </label>
                                                <select
                                                    name='hari'
                                                    className="form-control mb-2"
                                                    style={{fontSize: "12px", borderRadius: "4px"}}
                                                    value={this.state.hari}
                                                    onChange={this.handleChange}
                                                >
                                                    <option value="">{i18n.t("pilihhari")}</option>
                                                    <option value="senin">{i18n.t("senin")}</option>
                                                    <option value="selasa">{i18n.t("selasa")}</option>
                                                    <option value="rabu">{i18n.t("rabu")}</option>
                                                    <option value="kamis">{i18n.t("kamis")}</option>
                                                    <option value="jumat">{i18n.t("jumat")}</option>
                                                    <option value="sabtu">{i18n.t("sabtu")}</option>
                                                    <option value="minggu">{i18n.t("minggu")}</option>
                                                </select>
                                            </div>
                                            <div className="col-md-3">
                                                <label className="mb-2 pengumuman-label-input">
                                                    {i18n.t("jamMulai")}
                                                </label>
                                                <input
                                                    name='jamMulai'
                                                    className="form-control mb-2 py-2"
                                                    style={{fontSize: "12px"}}
                                                    placeholder={i18n.t('inputjammulai')}
                                                    type="time"
                                                    value={this.state.jamMulai}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <label className="mb-2 pengumuman-label-input">
                                                    {i18n.t("jamSelesai")}
                                                </label>
                                                <input
                                                    name='jamSelesai'
                                                    className="form-control mb-2 py-2"
                                                    style={{fontSize: "12px"}}
                                                    placeholder={i18n.t('inputjammulai')}
                                                    type="time"
                                                    value={this.state.jamSelesai}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <label className="mb-2 pengumuman-label-input">
                                                    {i18n.t("mapel")}
                                                </label>
                                                <select
                                                    style={{fontSize: "12px"}}
                                                    name='mapel_id'
                                                    className="form-control mb-2"
                                                    value={this.state.mapel_id}
                                                    onChange={this.handleChange}
                                                >
                                                    <option value="">{i18n.t('pilihmapel')}</option>
                                                    {this.state.mapel.map((item) => (
                                                        <option
                                                            key={item.mapel_id}
                                                            style={{fontSize: "12px"}}
                                                            value={item.mapel_id}
                                                        >
                                                            {item.mapel_nama}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <button onClick={this.handleClear} className='ipresens-cancel-button'>{i18n.t("batal")}</button>
                            <button className='ipresens-save-button' disabled={this.state.prosesSimpan} onClick={this.handleEdit}>
                                <img alt='' src={SaveIcon}/> {this.state.prosesSimpan ? i18n.t("menyimpan") : i18n.t("simpan")}
                            </button>
                        </ModalFooter>
                    </Modal>

                    <Modal size="lg" isOpen={this.state.modalexport} toggle={this.handleClear}>
                        <ModalHeader toggle={this.handleClear}>Export Excel</ModalHeader>
                        <ModalBody>
                            <div className="row">
                                <div className="col-md-6 my-1">
                                    <button className="btn btn-primary text-capitalize w-100 h-100"
                                            style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                                            onClick={() => this.exportData(this.state.kelas_id, this.state.semester_active)}>
                                        {i18n.t("exportjadwalkelas", { nama_kelas: this.state.nama_kelas }) + (' ') +
                                            i18n.t('semester_format', {
                                            semesterType: i18n.t(this.state.semester_type),
                                            year: this.state.year,
                                        })}
                                    </button>
                                </div>
                                <div className="col-md-6 my-1">
                                    <button className="btn btn-primary text-capitalize w-100 h-100"
                                            style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                                            onClick={() => this.exportData()}>
                                        {i18n.t("exprtseluruhjadwal")}
                                    </button>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <button className="btn ipresens-btn-cancel text-capitalize" onClick={this.handleClear}>
                                {i18n.t('batalkan')}
                            </button>
                        </ModalFooter>
                    </Modal>

                    <Modal size="lg" isOpen={this.state.selectimport} toggle={() => this.setState({selectimport: false})}>
                        <ModalHeader toggle={() => this.setState({selectimport: false})}>Import Excel</ModalHeader>
                        <ModalBody>
                            <div className="row">
                                <div className="col-md-6">
                                    <button className="btn btn-primary text-capitalize w-100 mb-2"
                                            onClick={() => this.setState({importkelas: true})}>
                                        {i18n.t("importkekelas", {nama_kelas : this.state.nama_kelas})}
                                    </button>
                                </div>
                                <div className="col-md-6">
                                    <button className="btn btn-primary text-capitalize w-100"
                                            onClick={() => this.setState({importall: true})}>
                                        {i18n.t("importkesemua")}
                                    </button>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <button className="btn ipresens-btn-cancel text-capitalize" onClick={() => this.setState({selectimport: false})}>
                                {i18n.t('batalkan')}
                            </button>
                        </ModalFooter>
                    </Modal>

                    <Modal isOpen={this.state.importkelas} toggle={this.handleClear}>
                        <ModalHeader toggle={this.handleClear}>
                            <div>{i18n.t('importjadwal')}</div>
                        </ModalHeader>
                        <ModalBody style={{ padding: "20px" }}>
                            <div className='row mb-2'>
                                <div className="col">
                                    <a style={{color: "#5D6F80", cursor: "default"}}>{i18n.t("pesanimportjadwalkelas", {nama_kelas : this.state.nama_kelas})}</a><br/>
                                    <a style={{color: "#5D6F80", cursor: "default"}}>{i18n.t('importcutitext')}</a><br/>
                                    <a onClick={() => this.exportTemplate(this.state.kelas_id)} style={{color: "#057CE4"}}>{i18n.t('importcutitemplates')}</a>
                                    <div style={{
                                        position: 'relative',
                                        width: '100%',
                                        backgroundColor: '#F9F9F9',
                                        padding: 16,
                                        borderRadius: 6,
                                        marginTop: 8,
                                        border: "1px dashed #DDDDDD"
                                    }}>
                                        <input type='file'
                                               accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                               onChange={(event) => {
                                                   this.setState({
                                                       importFile: event.target.files[0],
                                                   })
                                               }}/><br/>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            {[
                                this.state.importLoading ?
                                    <button className={'btn ipresens-btn-color'} style={{textTransform: "none", width: "100%"}}
                                            disabled={true}><i className="fa fa-refresh fa-spin"></i>{i18n.t('sedangmenguploaddata')}</button>
                                    :
                                    <button className={this.state.importFile === null ? 'btn bg-secondary-custom' : 'btn ipresens-btn-color'} style={{textTransform: "none", width: "100%"}} disabled={this.state.importFile === null} onClick={() => this.importData(this.state.kelas_id)}>Import Data</button>
                            ]}
                        </ModalFooter>
                    </Modal>

                    <Modal isOpen={this.state.importall} toggle={this.handleClear}>
                        <ModalHeader toggle={this.handleClear}>
                            <div>{i18n.t('importjadwal')}</div>
                        </ModalHeader>
                        <ModalBody style={{ padding: "20px" }}>
                            <div className='row mb-2'>
                                <div className="col">
                                    <a style={{color: "#5D6F80", cursor: "default"}}>{i18n.t('pesanimportjadwalsemua')}</a><br/>
                                    <a style={{color: "#5D6F80", cursor: "default"}}>{i18n.t('importcutitext')}</a><br/>
                                    <a onClick={() => this.exportTemplate()} style={{color: "#057CE4"}}>{i18n.t('importcutitemplates')}</a>
                                    <div style={{
                                        position: 'relative',
                                        width: '100%',
                                        backgroundColor: '#F9F9F9',
                                        padding: 16,
                                        borderRadius: 6,
                                        marginTop: 8,
                                        border: "1px dashed #DDDDDD"
                                    }}>
                                        <input type='file'
                                               accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                               onChange={(event) => {
                                                   this.setState({
                                                       importFile: event.target.files[0],
                                                   })
                                               }}/><br/>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            {[
                                this.state.importLoading ?
                                    <button className={'btn ipresens-btn-color'} style={{textTransform: "none", width: "100%"}}
                                            disabled={true}><i className="fa fa-refresh fa-spin"></i>{i18n.t('sedangmenguploaddata')}</button>
                                    :
                                    <button className={this.state.importFile === null ? 'btn bg-secondary-custom' : 'btn ipresens-btn-color'} style={{textTransform: "none", width: "100%"}} disabled={this.state.importFile === null} onClick={() => this.importData()}>Import Data</button>
                            ]}
                        </ModalFooter>
                    </Modal>

                    <Modal size="lg" style={{ maxWidth: "1000px" }} isOpen={this.state.modalHistory} toggle={this.handleClear}>
                        <ModalHeader toggle={this.handleClear}>
                            <div className='modal-header-reactstrap'>{i18n.t('riwayatperubahan')}</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="list-group">
                                <div>
                                    {this.state.loadingHistory && (
                                        <div style={{textAlign: 'center', margin: '10px auto'}}>
                                            <Spin size="large" tip="Loading..." style={{width: '100%', margin: 'auto',}}/>
                                        </div>
                                    )}
                                    {this.state.dataHistory.length !== 0 && (
                                        <div>
                                            <Row>
                                                <Col xs={0} md={0} className="text-left" style={{marginLeft: '20px'}}>
                                                    <h4>{i18n.t('user')}</h4>
                                                </Col>
                                                <Col xs={0} md={0} className="text-left" style={{marginRight: '20px'}}>
                                                    <h4>{i18n.t('aktivitas')}</h4>
                                                </Col>
                                            </Row>
                                            {this.state.dataHistory.map((item, index) => {
                                                return (
                                                    <div key={index} style={{marginBottom: '20px'}}>
                                                        <Row>
                                                            <Col xs={12} md={6} className="text-left">
                                                                <div style={{display: 'flex', alignItems: 'center', paddingTop: '16px', paddingBottom: '16px', paddingRight: '12px', paddingLeft: '12px'
                                                                }}>
                                                                    <div style={{marginRight: '16px'}}>
                                                                        <img src={DEFAULT_IMG} alt="" style={{width: '48px', marginBottom: '25px', height: '48px'}}/>
                                                                    </div>
                                                                    <div>
                                                                        <h6 className="mb-0 font-13" style={{fontWeight: 500, fontSize: '16px', lineHeight: '24px',}}>
                                                                            {item.p_user_name} | {item.level_name}
                                                                        </h6>
                                                                        <p className="m-0 font-12">
                                                                                    <span className="col-green font-weight-bold">
                                                                                        {moment(item.created_at).format('lll')}
                                                                                    </span>
                                                                        </p>
                                                                        <div style={{marginTop: 12}}>
                                                                            {item.history_status === "created" && (
                                                                                <div className="row px-3">
                                                                                    <div
                                                                                        className="mr-2"
                                                                                        style={{
                                                                                            border: '1px solid #000',
                                                                                            borderRadius: '25px',
                                                                                            width: '50%',
                                                                                            color: '#8c2bee',
                                                                                            borderColor: '#8c2bee',
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            className="d-flex justify-content-center"
                                                                                            style={{
                                                                                                paddingTop: '6px',
                                                                                                paddingBottom: '6px'
                                                                                            }}>
                                                                                            {i18n.t('dibuat')}
                                                                                        </div>
                                                                                    </div>
                                                                                    {this.state.historyLast.history_id !== item.history_id &&
                                                                                        <div
                                                                                            className="col-md-5 btn ipresens-btn-revert"
                                                                                            onClick={() => {
                                                                                                this.setState({
                                                                                                    idRev: item.jadwal_id,
                                                                                                    id_History: item.history_id
                                                                                                })
                                                                                                this.revertJadwal()
                                                                                            }}
                                                                                            style={{
                                                                                                border: '1px solid #000',
                                                                                                borderRadius: '25px',
                                                                                                width: '50%',
                                                                                                color: '#8c2bee',
                                                                                                borderColor: '#8c2bee',
                                                                                                lineHeight: '0px',
                                                                                                textTransform: "none"
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                className="d-flex justify-content-center"
                                                                                                style={{
                                                                                                    paddingTop: '8px',
                                                                                                    paddingBottom: '6px'
                                                                                                }}>
                                                                                                Revert
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            )}
                                                                            {item.history_status === "edited" &&(
                                                                                <div className="row px-3">
                                                                                    <div
                                                                                        className="mr-2"
                                                                                        style={{
                                                                                            border: '1px solid #000',
                                                                                            borderRadius: '25px',
                                                                                            width: '50%',
                                                                                            color: '#E6462E',
                                                                                            borderColor: '#E6462E',
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            className="d-flex justify-content-center"
                                                                                            style={{
                                                                                                paddingTop: '6px',
                                                                                                paddingBottom: '6px'
                                                                                            }}>
                                                                                            {i18n.t('diubah')}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-md-5 btn ipresens-btn-revert"
                                                                                        onClick={() => {
                                                                                            this.setState({
                                                                                                idRev: item.jadwal_id,
                                                                                                id_History: item.history_id
                                                                                            })
                                                                                            this.revertJadwal()
                                                                                        }}
                                                                                        style={{
                                                                                            border: '1px solid #000',
                                                                                            borderRadius: '25px',
                                                                                            width: '50%',
                                                                                            color: '#8c2bee',
                                                                                            borderColor: '#8c2bee',
                                                                                            lineHeight: '0px',
                                                                                            textTransform: "none"
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            className="d-flex justify-content-center"
                                                                                            style={{
                                                                                                paddingTop: '8px',
                                                                                                paddingBottom: '6px'
                                                                                            }}>
                                                                                            Revert
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                            {item.history_status === "reverted" && (
                                                                                <div className="row px-3">
                                                                                    <div
                                                                                        className="mr-2"
                                                                                        style={{
                                                                                            border: '1px solid #000',
                                                                                            borderRadius: '25px',
                                                                                            width: '50%',
                                                                                            color: '#8c2bee',
                                                                                            borderColor: '#8c2bee',
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            className="d-flex justify-content-center"
                                                                                            style={{
                                                                                                paddingTop: '6px',
                                                                                                paddingBottom: '6px'
                                                                                            }}>
                                                                                            {i18n.t('dikembalikan')}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} md={6} className="text-left" style={{
                                                                paddingTop: '16px',
                                                                paddingBottom: '16px',
                                                                paddingRight: '12px',
                                                                paddingLeft: '12px'
                                                            }}>
                                                                {item.history_status !== 'created' && (
                                                                    <div>
                                                                        {item.parent_history ?
                                                                            item.parent_history.semester.semester_type !== item.semester.semester_type && (
                                                                                <div>
                                                                                    <h6 className="mb-3 font-13 text-left" style={{fontWeight: 500, fontSize: '16px', lineHeight: '24px',}}>
                                                                                        {i18n.t('semesterdari')}{' '}
                                                                                        {item.parent_history
                                                                                            ? i18n.t('semester_format', {
                                                                                                semesterType: i18n.t(item.parent_history.semester.semester_type),
                                                                                                year: new Date(item.parent_history.semester.semester_start_date).getFullYear(),
                                                                                            })
                                                                                            : 'N/A'}{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t('jadi')} {item.semester.semester_type ? i18n.t('semester_format', {
                                                                                            semesterType: i18n.t(item.semester.semester_type.toLowerCase()),
                                                                                            year: new Date(item.semester.semester_start_date).getFullYear(),
                                                                                        })
                                                                                        : ''}
                                                                                    </h6>
                                                                                </div>
                                                                            ) : null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.jadwal_hari !== item.jadwal_hari && (
                                                                                <div>
                                                                                    <h6 className="mb-3 font-13 text-left" style={{fontWeight: 500, fontSize: '16px', lineHeight: '24px',}}>
                                                                                        {i18n.t('haridari')}{' '}
                                                                                        {i18n.t(item.parent_history
                                                                                            ? item.parent_history.jadwal_hari
                                                                                            : 'N/A')}{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t('jadi')} {i18n.t(item.jadwal_hari ? item.jadwal_hari : '')}
                                                                                    </h6>
                                                                                </div>
                                                                            ) : null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.jadwal_jam_mulai !== item.jadwal_jam_mulai && (
                                                                                <div>
                                                                                    <h6 className="mb-3 font-13 text-left" style={{fontWeight: 500, fontSize: '16px', lineHeight: '24px',}}>
                                                                                        {i18n.t('jammulaidari')}{' '}
                                                                                        {item.parent_history
                                                                                            ? formatTime(item.parent_history.jadwal_jam_mulai)
                                                                                            : 'N/A'}{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t('jadi')} {item.jadwal_jam_mulai ? formatTime(item.jadwal_jam_mulai) : ''}
                                                                                    </h6>
                                                                                </div>
                                                                            ) : null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.jadwal_jam_selesai !== item.jadwal_jam_selesai && (
                                                                                <div>
                                                                                    <h6 className="mb-3 font-13 text-left" style={{fontWeight: 500, fontSize: '16px', lineHeight: '24px',}}>
                                                                                        {i18n.t('jamselesaidari')}{' '}
                                                                                        {item.parent_history
                                                                                            ? formatTime(item.parent_history.jadwal_jam_selesai)
                                                                                            : 'N/A'}{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t('jadi')} {item.jadwal_jam_selesai ? formatTime(item.jadwal_jam_selesai) : ''}
                                                                                    </h6>
                                                                                </div>
                                                                            ) : null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.mapel.mapel_nama !== item.mapel.mapel_nama && (
                                                                                <div>
                                                                                    <h6 className="mb-3 font-13 text-left" style={{fontWeight: 500, fontSize: '16px', lineHeight: '24px',}}>
                                                                                        {i18n.t('mapeldari')}{' '}
                                                                                        {item.parent_history
                                                                                            ? item.parent_history.mapel.mapel_nama
                                                                                            : 'N/A'}{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t('jadi')} {item.mapel.mapel_nama ? item.mapel.mapel_nama : ''}
                                                                                    </h6>
                                                                                </div>
                                                                            ) : null
                                                                        }
                                                                    </div>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <div className='row mx-0' style={{backgroundColor: "#F5F5F5", width: "100%"}}>
                                {this.state.dataHistory.length !== 0 &&
                                    <>
                                        <div className='col font-14' style={{
                                            padding: "auto",
                                            margin: "auto",
                                            fontWeight: 500,
                                            lineHeight: "24px"
                                        }}>{i18n.t("menampilkan")} {this.state.dataHistory.length === 0 || this.state.dataHistory.length === null ? "0" : this.state.dataHistory.length} {i18n.t("dari")} {this.state.totalDataHistory} data
                                        </div>
                                        <div className='col'>
                                            <Paging
                                                handlePageClick={this.handlePageHistory}
                                                pageCount={this.state.pageCountHistory}
                                                currentPage={this.state.currentPageHistory}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </ModalFooter>
                    </Modal>

                    <ModalRs
                        size="lg" style={{maxWidth: "1000px"}}
                        isOpen={this.state.modalTrash}
                        toggle={() => this.setState({
                            modalTrash: false,
                            currentPageTrash: 0,
                            checkedTrash: [],
                            checkAll: false
                        })}
                    >
                        <ModalHeader
                            style={{backgroundColor: "#F8F3FD"}}
                            toggle={() => this.setState({
                                modalTrash: false,
                                currentPageTrash: 0,
                                checkedTrash: [],
                                checkAll: false
                            })}
                        >
                            <div className='modal-header-ipresens'>{i18n.t('daftarsampahjadwal')}</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="row d-flex justify-content-end">
                                <div className="col-md-6"></div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <button
                                        className="btn ipresens-btn-color px-3 py-2 mx-1"
                                        disabled={this.state.checkedTrash.length === 0}
                                        onClick={() => {
                                            this.setState({restoreJadwal: true});
                                        }}>
                                        {i18n.t('pulihkan')}
                                    </button>
                                    <button
                                        className="btn ipresens-btn-color px-3 py-2 mx-1"
                                        disabled={this.state.checkedTrash.length === 0}
                                        onClick={() => {
                                            this.setState({permanentDelete: true});
                                        }}>
                                        {i18n.t('hapuspermanen')}
                                    </button>
                                </div>
                            </div>
                            <div className="list-group">
                                <div className="table-responsive">
                                    {!this.state.loadingTrash && this.state.dataTrash.length === 0 ?
                                        <Empty
                                            description={i18n.t('datasampahkosong')}
                                            image={EmptyDataImage} className="ipresens-image-empty mb-5 mt-5"/>
                                        :
                                        <table className="table table-striped mb-5">
                                            <thead>
                                            <tr>
                                                <th style={{border: "none", fontSize: "13px"}} className="modal-header-ipresens-table" width="30">
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox-custom"
                                                        checked={this.state.checkAll}
                                                        onChange={this.handleCheckBoxAllChange}
                                                    ></input>
                                                </th>
                                                <th style={{border: "none", fontSize: "13px", textAlign: 'center'}} className="modal-header-ipresens-table" width="30">#</th>
                                                <th style={{border: "none", fontSize: "13px"}} className="text-left modal-header-ipresens-table" width="200">{i18n.t("hari")}</th>
                                                <th style={{border: "none", fontSize: "13px"}} className="text-left modal-header-ipresens-table" width="200">{i18n.t("jammulai")}</th>
                                                <th style={{border: "none", fontSize: "13px"}} className="text-left modal-header-ipresens-table" width="200">{i18n.t("jamselesai")}</th>
                                                <th style={{border: "none", fontSize: "13px"}} className="text-left modal-header-ipresens-table" width="400">{i18n.t("mapel")}</th>
                                                <th style={{border: "none", fontSize: "13px"}} className="text-left modal-header-ipresens-table" width="400">{i18n.t("semester")}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.loadingTrash &&
                                                <tr>
                                                    <td colSpan={7} style={{border: "none"}}>
                                                        <Spin size="large" tip="Loading..." style={{
                                                            width: '100%',
                                                            margin: 'auto',
                                                            marginBottom: '10px',
                                                            marginTop: '10px'
                                                        }}/>
                                                    </td>
                                                </tr>
                                            }
                                            {this.state.dataTrash.map((item, index) => {
                                                let no = index + 1;
                                                return (
                                                    <tr>
                                                        <td style={{border: "none"}}>
                                                            <input
                                                                className="checkbox-custom"
                                                                type="checkbox"
                                                                checked={this.state.checkedTrash.includes(item.jadwal_id)}
                                                                onChange={() => this.handleCheckBoxChange(item.jadwal_id)}
                                                            ></input>
                                                        </td>
                                                        <td style={{border: "none"}}>
                                                            <small>
                                                                <th
                                                                    style={{
                                                                        border: "none",
                                                                        color: "#38115F"
                                                                    }}
                                                                    align='left'
                                                                    scope="row"
                                                                >
                                                                    {pageNumber(no, this.state.perPageTrash, this.state.currentPageTrash)}
                                                                </th>
                                                            </small>
                                                        </td>
                                                        <td style={{border: "none"}} align='left'>
                                                            <div>
                                                                <p style={{
                                                                    color: "#38115F",
                                                                    marginBottom: "0px"
                                                                }}>{item.jadwal_hari}</p>
                                                            </div>
                                                        </td>
                                                        <td style={{border: "none"}} align='left'>
                                                            <div>
                                                                <p style={{
                                                                    color: "#38115F",
                                                                    marginBottom: "0px"
                                                                }}>{formatTime(item.jadwal_jam_mulai)}</p>
                                                            </div>
                                                        </td>
                                                        <td style={{border: "none"}} align='left'>
                                                            <div>
                                                                <p style={{
                                                                    color: "#38115F",
                                                                    marginBottom: "0px"
                                                                }}>{formatTime(item.jadwal_jam_selesai)}</p>
                                                            </div>
                                                        </td>
                                                        <td style={{border: "none"}} align='left'>
                                                            <div>
                                                                <p style={{
                                                                    color: "#38115F",
                                                                    marginBottom: "0px"
                                                                }}>{item.mapel.mapel_nama}</p>
                                                            </div>
                                                        </td>
                                                        <td style={{border: "none"}} align='left'>
                                                            <div>
                                                                <p style={{
                                                                    color: "#38115F",
                                                                    marginBottom: "0px"
                                                                }}>{i18n.t('semester_format', {
                                                                    semesterType: i18n.t(item.semester.semester_type),
                                                                    year: new Date(item.semester.semester_start_date).getFullYear(),
                                                                })}</p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </table>
                                    }
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{
                            backgroundColor: "#F5F5F5",
                            borderBottomLeftRadius: "8px",
                            borderBottomRightRadius: "8px"
                        }}>
                            <div className='row mx-0' style={{backgroundColor: "#F5F5F5", width: "100%"}}>
                                {this.state.dataTrash.length !== 0 &&
                                    <>
                                        <div className='col font-14' style={{
                                            padding: "auto",
                                            margin: "auto",
                                            fontWeight: 500,
                                            lineHeight: "24px"
                                        }}>{i18n.t("menampilkan")} {this.state.dataTrash.length === 0 || this.state.dataTrash.length === null ? "0" : this.state.dataTrash.length} {i18n.t("dari")} {this.state.totalDataTrash} {i18n.t("data")}</div>
                                        <div className='col'>
                                            <ReactPaginate
                                                previousLabel={<img src={ArrowLeft} alt=''/>}
                                                nextLabel={<img src={ArrowRight} alt=''/>}
                                                pageCount={this.state.pageCountTrash}
                                                forcePage={this.state.currentPageTrash}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={this.handlePageTrash}
                                                breakClassName={'page-item'}
                                                breakLinkClassName={'page-link custom-page-link'}
                                                containerClassName={'pagination my-2'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link custom-page-link'}
                                                previousClassName={'page-item'}
                                                previousLinkClassName={'page-link custom-prev-next-link'}
                                                nextClassName={'page-item'}
                                                nextLinkClassName={'page-link custom-prev-next-link'}
                                                activeClassName={'active custom-active'}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </ModalFooter>
                    </ModalRs>

                    <Modal centered isOpen={this.state.restoreJadwal} toggle={() => {
                        this.setState({
                            restoreJadwal: false
                        })
                    }}>
                        <ModalHeader className='pl-4 border-bottom-0' toggle={() => {
                            this.setState({
                                restoreJadwal: false
                            })
                        }}>
                            <div className='d-flex'>
                                <div className='p-3 mr-4' style={{
                                    backgroundColor: "#8C2BEE",
                                    borderRadius: "8px"
                                }}>
                                    <img alt='' src={Restore} />
                                </div>
                                <div>
                                    <div style={{
                                        color: "#143B5E",
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        lineHeight: "27px"
                                    }}>{i18n.t('pulihkandatajadwal')}</div>
                                    <div style={{
                                        color: "#5D6F80",
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "24px"
                                    }}>{this.state.checkedTrash.length} {i18n.t('jadwalterpilih')}</div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalFooter style={{ borderTop: 0 }}>
                            <div className='row mx-0' style={{ width: "100%" }}>
                                <div className='col-md-6 px-1'>
                                    <button className='btn ipresens-btn-cancel' style={{ width: "100%" }} onClick={() => {
                                        this.setState({
                                            restoreJadwal: false
                                        })
                                    }}>
                                        {i18n.t('batal')}
                                    </button>
                                </div>
                                <div className='col-md-6 px-1'>
                                    <button className='btn ipresens-btn-color' style={{
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        lineHeight: "24px",
                                        width: "100%"
                                    }} onClick={this.pemulihanJadwal}>
                                        {i18n.t('ya')}
                                    </button>
                                </div>
                            </div>
                        </ModalFooter>
                    </Modal>

                    <Modal centered isOpen={this.state.permanentDelete} toggle={() => {this.setState({permanentDelete: false})}}>
                        <ModalHeader className='pl-6 border-bottom-0' toggle={() => {
                            this.setState({
                                permanentDelete: false
                            })
                        }}>
                            <div className='d-flex'>
                                <div className='p-3 mr-4' style={{
                                    backgroundColor: "#8C2BEE",
                                    borderRadius: "8px"
                                }}>
                                    <img alt='' src={Trash} />
                                </div>
                                <div>
                                    <div style={{
                                        color: "#143B5E",
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        lineHeight: "27px"
                                    }}>{i18n.t('hapuspermanenjadwal')}</div>
                                    <div style={{
                                        color: "#5D6F80",
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "24px"
                                    }}>{this.state.checkedTrash.length} {i18n.t('jadwalterpilih')}</div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalFooter style={{ borderTop: 0 }}>
                            <div className='row mx-0' style={{ width: "100%" }}>
                                <div className='col-md-6 px-1'>
                                    <button className='btn ipresens-btn-cancel' style={{ width: "100%" }} onClick={() => {
                                        this.setState({
                                            permanentDelete: false
                                        })
                                    }}>
                                        {i18n.t('batal')}
                                    </button>
                                </div>
                                <div className='col-md-6 px-1'>
                                    <button className='btn ipresens-btn-color' style={{
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        lineHeight: "24px",
                                        width: "100%"
                                    }} onClick={this.trashJadwal}>
                                        {i18n.t('ya')}
                                    </button>
                                </div>
                            </div>
                        </ModalFooter>
                    </Modal>

                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="d-flex flex-column flex-md-row">
                                            <select
                                                name="semester_active"
                                                className="form-control karyawan-filter-jabatan btn-slc-krywn mb-2 mr-2"
                                                style={{borderRadius: "4px"}}
                                                onChange={this.handleFilter}
                                                value={this.state.semester_active}
                                            >
                                                {this.state.semester.length !== 0 &&
                                                    this.state.semester.map((item) => (
                                                        <option value={item.semester_id} key={item.semester_id}>
                                                            {i18n.t('semester_format', {
                                                                semesterType: i18n.t(item.semester_type),
                                                                year: new Date(item.semester_start_date).getFullYear(),
                                                            })}
                                                        </option>
                                                    ))}
                                            </select>
                                            <select
                                                name="kelas_id"
                                                className="form-control karyawan-filter-jabatan btn-slc-krywn mx-auto"
                                                style={{borderRadius: "4px"}}
                                                onChange={this.handleFilter}
                                                value={this.state.kelas_id}
                                            >
                                                <option value="">{i18n.t("pilihkelas")}</option>
                                                {this.props.divisi.length !== 0 &&
                                                    this.props.divisi.map((item) => (
                                                        <option value={item.divisi_id} key={item.divisi_id}>
                                                            {item.divisi_name}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6 d-flex justify-content-end col-btn-add-absn flex-wrap">
                                        <div className="flex-grow-1 mr-2">
                                            <button
                                                className="btn ipresens-btn-color text-capitalize width-filter pull-right"
                                                disabled={this.state.kelas_id === ""}
                                                onClick={() => this.setState({modaladd: true})}
                                            >
                                                <img src={PlusIcon} alt="Tambah Jadwal"/>
                                                {i18n.t('tambahjadwal')}
                                            </button>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <Tooltip zIndex={1} trigger={'click'} placement="bottomLeft" color={'#FFF'}
                                                     title={
                                                         <div className={'p-2'}>
                                                             <a onClick={() => {
                                                                 this.setState({selectimport: true})
                                                             }}
                                                                style={{marginRight: "50px"}}
                                                                className={'text-dark'}>Import Excel</a><br/>
                                                             <a onClick={() => {
                                                                 this.setState({modalexport: true})
                                                             }}
                                                                style={{marginRight: "50px"}}
                                                                className={'text-dark'}>{i18n.t('eksporexcel')}</a><br/>
                                                             <a
                                                                 onClick={() =>
                                                                     this.setState({
                                                                         modalTrash: true
                                                                     }, () => {
                                                                         this.loadTrash();
                                                                     })}
                                                                 style={{marginRight: "50px"}}
                                                                 className={'text-dark'}
                                                             >{i18n.t('sampahjadwal')}</a>
                                                         </div>
                                                     }>
                                                <a>
                                                    <button disabled={this.state.kelas_id === ''}
                                                            className='btn border border-dark border-1 px-2'
                                                    ><img alt="More" src={MoreIcon}/></button>
                                                </a>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                {loading ? (
                                    <div className="col-md-12">
                                        <Spin
                                            size="large"
                                            tip={i18n.t("sedangmemuatdata") + " ..."}
                                            style={{
                                                width: "100%",
                                                margin: "auto",
                                                marginBottom: "10px",
                                                marginTop: "10px",
                                            }}
                                        />
                                    </div>
                                ) : (
                                    this.state.kelas_id !== '' &&
                                    <div className="row">
                                        {jadwalHarian.map(({title, jadwal}) => (
                                            <JadwalTable key={title} title={title} jadwal={jadwal}/>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">{i18n.t('tidakadaakseshalaman')}</h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token,
    divisi: state.Filter._divisi
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(List_Jadwal);