import React, { Component } from "react";
import {
    Route,
    Redirect
} from "react-router-dom";
import { connect } from "react-redux"
import App from "./components/app";

const PendingRoute = ({ component: Component, auth, user, ...rest }) => {
    return (
        <App request={(action, data, method = 'GET') => rest.request(action, data, method)}>
            <Route
                {...rest}
                render={(props) => !auth ? (
                    <Redirect to={{
                        pathname: "/login",
                        state: {from: props.location}
                    }}/>
                ) : (
                    <Component {...props} request={(action, data, method = 'POST') => rest.request(action, data, method) } />
                )
                }
            />
        </App>
    );
};

const mapStateToProps = state => ({
    auth: state.Auth.auth,
    user: state.Auth.user
});

export default connect(mapStateToProps)(PendingRoute)
