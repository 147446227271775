import React, {Component} from 'react'
import {Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import i18n from "i18next";
import SaveIcon from "../../../assets/icons/save.svg";
import Swal from "sweetalert2";

class EditIpWhitelist extends Component {
    constructor(props) {
        super(props)
        this.state = {
            prosesSimpan: false
        }
    }

    handleEdit = () => {
        this.setState({ prosesSimpan: true})
        let form = {
            whitelist_id : this.props.data.whitelist_id,
            ip_start: this.props.data.ip_start,
            ip_end: this.props.data.ip_end,
        }
        this.props.request("whitelist/edit", form, 'POST')
            .then((response) => {
                if (response.success) {
                    this.props.changeState({
                        modal_edit: false,
                        data: [],
                    }, () => this.props.loadData())
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t(response.message),
                        icon:'success',
                        confirmButtonText: 'OK'
                    })
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t(response.message),
                        icon: 'error',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({ ip_start: '', ip_end: '', prosesSimpan: false })
            })
    }

    render() {
        const isRange = this.props.isRange === true
        return (
            <Modal isOpen={this.props.modal_edit} toggle={() => this.props.changeState({ modal_edit: false })} style={{ maxWidth: "1000px"}} zIndex={999}>
                <ModalHeader className="modal-header-ipresens" toggle={() => this.props.changeState({ modal_edit: false })}>
                    <div className='modal-header-ipresens'>{i18n.t("editip")}</div>
                </ModalHeader>
                <ModalBody className="inventaris-modal-body">
                    <div className="row p-3">
                        <div className="col">
                            <div className="form-group">
                                <div className="row">
                                    <div className="col">
                                        <label className="pengumuman-label-input">
                                            {isRange ? i18n.t("dari") : i18n.t("ipaddress")}
                                        </label>
                                        <input type='text' onChange={this.props.handleInputIP}
                                               name='ip_start' value={this.props.data.ip_start}
                                               className='form-control font-12'
                                               placeholder={i18n.t("masukkanip") + " (ex : 192.168.1.x)"}>
                                        </input>
                                    </div>
                                    {isRange &&
                                        <div className="col">
                                            <label className="pengumuman-label-input">
                                                {i18n.t("sampai")}
                                            </label>
                                            <input type='text' onChange={this.props.handleInputIP}
                                                   name='ip_end' value={this.props.data.ip_end}
                                                   className='form-control font-12'
                                                   placeholder={i18n.t("masukkanip") + " (ex : 192.168.1.x)"}>
                                            </input>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className="inventaris-modal-footer">
                    <button onClick={() => this.props.changeState({modal_edit: false})}
                            className='ipresens-cancel-button'>{i18n.t("batal")}</button>
                    <button className='ipresens-save-button' disabled={this.state.prosesSimpan}
                            onClick={this.handleEdit}><img alt='' src={SaveIcon}/>{this.state.prosesSimpan ? i18n.t("menyimpan") : i18n.t("simpan")}
                    </button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default EditIpWhitelist;