import React, {Component} from 'react'
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import i18n from "i18next";
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import ArrowRight from "../../assets/icons/arrow-right.svg";
import ReactPaginate from "react-paginate"
import Restore from "../../assets/icons/update.svg";
import Trash from "../../assets/icons/trash-custom.svg";
import EmptyDataImage from "../../assets/images/data-kosong.png";
import {Empty, Spin} from "antd";
import {pageNumber} from "../../functions/functionGeneral";
import Swal from "sweetalert2";
class TrashMataPelajaran extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataTrash: [],
            loadingTrash: false,
            currentPage: 0,
            paginate: 4,
            checked: [],
            restoreMapel: false,
            permanentDelete: false,
            checked_all: false,
            perPage: 0,
            totalData: 0,
            pageCount: 0,
        }
        this.handlePageTrash = this.handlePageTrash.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.trash_visible !== this.props.trash_visible && this.props.trash_visible) {
            this.setState({ loadingTrash: true, currentPage: 0, checked: [], checked_all: false  }, () => this.loadTrash())
        }
    }

    loadTrash() {
        this.setState({
            dataTrash: [],
            loadingTrash: true
        }, () => {
            let form = {
                paginate: this.state.paginate,
                page: this.state.currentPage + 1,
            };

            this.props.request("mapel/trash", form, 'POST')
                .then(response => {
                    this.setState({
                        loadingTrash: false
                    })
                    if (response.success) {
                        this.setState({
                            dataTrash: response.data.data,
                            pageCount: response.data.last_page,
                            totalData: response.data.total,
                            totalPerpage: response.data.to,
                            perPage: response.data.per_page,
                        });
                    }
                });
        })
    }

    handlePageTrash(dataTrash) {
        let selected = dataTrash.selected;
        this.setState({ currentPage: selected,  dataTrash: [], loadingTrash: true }, () => {
            this.loadTrash()
        });
    }

    handleCheckAll(e) {
        const value = e.target.checked;
        if (value) {
            const allChecked = this.state.dataTrash.map(item => item.mapel_id);
            this.setState({
                checked: allChecked,
                checked_all: true
            });
        } else {
            this.setState({
                checked: [],
                checked_all: false
            });
        }
    }

    handleCheck(id) {
        this.setState(prevState => {
            const isChecked = prevState.checked.includes(id);
            const newChecked = isChecked
                ? prevState.checked.filter(currId => currId !== id)
                : [...prevState.checked, id];
            return {
                checked: newChecked,
                checked_all: newChecked.length === prevState.totalData
            };
        });
    }

    pemulihanMapel = () => {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('pulihkanmapel'),
            icon: 'warning',
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed){
                    let form = {
                        id: this.state.checked
                    }
                    this.props.request("mapel/restore", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                this.props.changeState({ trash_visible: false, loading: true }, () => this.props.loadData())
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: i18n.t(response.message),
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.setState({
                                    checked: [],
                                    checked_all: false,
                                    restoreMapel: false
                                })
                            }
                        });
                }
            })
    }

    trashMapel = () => {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('hapusmapelpermanen'),
            icon: 'warning',
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed){
                    let form = {
                        mapel_ids: this.state.checked
                    }
                    this.props.request("mapel/delete-permanent", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                this.props.changeState({ trash_visible: false, loading: true }, () => this.props.loadData())
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: i18n.t(response.message),
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.setState({
                                    checked: [],
                                    checked_all: false,
                                    permanentDelete: false
                                })
                            }
                        });
                }
            })
    }

    render() {
        let no = 0;
        return (
            <>
                <Modal isOpen={this.props.trash_visible} toggle={() => this.props.changeState({ trash_visible: false })} zIndex={999} style={{ maxWidth: "1000px" }}>
                    <ModalHeader toggle={() => this.props.changeState({trash_visible: false})} className="modal-header-ipresens">
                        <div className='modal-header-ipresens'>{i18n.t('daftarsampahmapel')}</div>
                    </ModalHeader>
                    <ModalBody>
                        <div className="row d-flex justify-content-end">
                            <div className="col-md-6 d-flex justify-content-end">
                                <button
                                    className="btn ipresens-btn-color px-3 py-2 mx-1"
                                    disabled={this.state.checked.length === 0}
                                    onClick={() => {
                                        this.setState({restoreMapel: true});
                                    }}>
                                    {i18n.t('pulihkan')}
                                </button>
                                <button
                                    className="btn ipresens-btn-color px-3 py-2 mx-1"
                                    disabled={this.state.checked.length === 0}
                                    onClick={() => {
                                        this.setState({permanentDelete: true});
                                    }}>
                                    {i18n.t('hapuspermanen')}
                                </button>
                            </div>
                        </div>
                          <div className="list-group mt-2">
                              <div className="table-responsive">
                                  {!this.state.loadingTrash && this.state.dataTrash.length === 0 ? (
                                      <Empty
                                          description={i18n.t('datasampahkosong')}
                                          image={EmptyDataImage} className="ipresens-image-empty mb-5 mt-5"/>
                                  ): (
                                      <table className="table table-striped table-borderless mb-5">
                                          <thead>
                                            <tr>
                                                <th className="modal-header-ipresens-table border-0 text-center" style={{ width: '30px'}}>
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox-custom"
                                                        checked={this.state.checked_all}
                                                        onChange={(e) => this.handleCheckAll(e)}
                                                    />
                                                </th>
                                                <th className="modal-header-ipresens-table border-0 text-center" style={{ width: '20px'}}>#</th>
                                                <th className="modal-header-ipresens-table border-0 text-center" style={{ width: "250px"}}>{i18n.t("namamapel")}</th>
                                                <th className="modal-header-ipresens-table border-0 text-center">{i18n.t("guru")}</th>
                                                <th className="modal-header-ipresens-table border-0 text-center" style={{ width: "150px"}}>{i18n.t("mapelkkm")}</th>
                                                <th className="modal-header-ipresens-table border-0 text-center" style={{ width: "140px"}}>{i18n.t("nilaiawal")}</th>
                                                <th className="modal-header-ipresens-table border-0 text-center" style={{ width: "120px"}}>{i18n.t("nilaiakhir")}</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                          {this.state.loadingTrash && (
                                              <tr>
                                                  <td colSpan={7}>
                                                      <Spin size="large" tip="Loading..." style={{
                                                          width: '100%',
                                                          margin: 'auto',
                                                          marginBottom: '10px',
                                                          marginTop: '10px'
                                                      }}/>
                                                  </td>
                                              </tr>
                                          )}
                                          {this.state.dataTrash.map((item, index) => {
                                              let no = index + 1;
                                              return (
                                                  <tr>
                                                      <td className="text-center">
                                                          <input
                                                              className="checkbox-custom"
                                                              type="checkbox"
                                                              checked={this.state.checked.includes(item.mapel_id)}
                                                              onChange={() => this.handleCheck(item.mapel_id)}
                                                          />
                                                      </td>
                                                      <td className="text-center">
                                                          <small>
                                                              <th
                                                                  style={{
                                                                      border: "none",
                                                                      color: "#38115F"
                                                                  }}
                                                                  align='left'
                                                                  scope="row"
                                                              >
                                                                  {pageNumber(no, this.state.perPage, this.state.currentPage)}
                                                              </th>
                                                          </small>
                                                      </td>
                                                      <td className="text-center"
                                                          style={{color: '#38115F', fontSize: 13}}>{item.mapel_nama}</td>
                                                      <td className="text-center"
                                                          style={{color: '#38115F', fontSize: 13}}>{item.guru ? item.guru?.guru_nama : '-'}</td>
                                                      <td className="text-center"
                                                          style={{color: '#38115F', fontSize: 13}}>{item.mapel_kkm}</td>
                                                      <td className="text-center"
                                                          style={{color: '#38115F', fontSize: 13}}>{item.mapel_nilai_start}</td>
                                                      <td className="text-center"
                                                          style={{color: '#38115F', fontSize: 13}}>{item.mapel_nilai_end}</td>
                                                  </tr>
                                              )
                                          })}
                                          </tbody>
                                      </table>
                                  )}
                              </div>
                          </div>
                    </ModalBody>
                    <ModalFooter style={{
                        backgroundColor: "#F5F5F5",
                        borderBottomLeftRadius: "8px",
                        borderBottomRightRadius: "8px"
                    }}>
                        <div className='row mx-0' style={{backgroundColor: "#F5F5F5", width: "100%"}}>
                            {this.state.dataTrash.length !== 0 &&
                                <>
                                    <div className='col font-14' style={{
                                        padding: "auto",
                                        margin: "auto",
                                        fontWeight: 500,
                                        lineHeight: "24px"
                                    }}>{i18n.t("menampilkan")} {this.state.dataTrash.length === 0 || this.state.dataTrash.length === null ? "0" : this.state.dataTrash.length} {i18n.t("dari")} {this.state.totalData} {i18n.t("data")}</div>
                                    <div className='col'>
                                        <ReactPaginate
                                            previousLabel={<img src={ArrowLeft} alt=''/>}
                                            nextLabel={<img src={ArrowRight} alt=''/>}
                                            pageCount={this.state.pageCount}
                                            forcePage={this.state.currentPage}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={this.handlePageTrash}
                                            breakClassName={'page-item'}
                                            breakLinkClassName={'page-link custom-page-link'}
                                            containerClassName={'pagination my-2'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link custom-page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link custom-prev-next-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link custom-prev-next-link'}
                                            activeClassName={'active custom-active'}
                                        />
                                    </div>
                                </>
                            }
                        </div>
                    </ModalFooter>
                </Modal>

                <Modal centered isOpen={this.state.restoreMapel} toggle={() => {
                    this.setState({
                        restoreMapel: false
                    })
                }}>
                    <ModalHeader className='pl-4 border-bottom-0' toggle={() => {
                        this.setState({
                            restoreMapel: false
                        })
                    }}>
                        <div className='d-flex'>
                            <div className='p-3 mr-4' style={{
                                backgroundColor: "#8C2BEE",
                                borderRadius: "8px"
                            }}>
                                <img alt='' src={Restore} />
                            </div>
                            <div>
                                <div style={{
                                    color: "#143B5E",
                                    fontWeight: 700,
                                    fontSize: "18px",
                                    lineHeight: "27px"
                                }}>{i18n.t('pulihkandatamapel')}</div>
                                <div style={{
                                    color: "#5D6F80",
                                    fontWeight: 400,
                                    fontSize: "14px",
                                    lineHeight: "24px"
                                }}>{this.state.checked.length} {i18n.t('mapelterpilih')}</div>
                            </div>
                        </div>
                    </ModalHeader>
                    <ModalFooter style={{ borderTop: 0 }}>
                        <div className='row mx-0' style={{ width: "100%" }}>
                            <div className='col-md-6 px-1'>
                                <button className='btn ipresens-btn-cancel' style={{ width: "100%" }} onClick={() => {
                                    this.setState({
                                        restoreMapel: false
                                    })
                                }}>
                                    {i18n.t('batal')}
                                </button>
                            </div>
                            <div className='col-md-6 px-1'>
                                <button className='btn ipresens-btn-color' style={{
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    lineHeight: "24px",
                                    width: "100%"
                                }} onClick={this.pemulihanMapel}>
                                    {i18n.t('ya')}
                                </button>
                            </div>
                        </div>
                    </ModalFooter>
                </Modal>

                <Modal size="lg" style={{maxWidth: "600px"}} centered isOpen={this.state.permanentDelete} toggle={() => {this.setState({permanentDelete: false})}}>
                    <ModalHeader className='pl-6 border-bottom-0' toggle={() => {
                        this.setState({
                            permanentDelete: false
                        })
                    }}>
                        <div className='d-flex'>
                            <div className='p-3 mr-4' style={{
                                backgroundColor: "#8C2BEE",
                                borderRadius: "8px"
                            }}>
                                <img alt='' src={Trash} />
                            </div>
                            <div>
                                <div style={{
                                    color: "#143B5E",
                                    fontWeight: 700,
                                    fontSize: "18px",
                                    lineHeight: "27px"
                                }}>{i18n.t('hapuspermanenmapel')}</div>
                                <div style={{
                                    color: "#5D6F80",
                                    fontWeight: 400,
                                    fontSize: "14px",
                                    lineHeight: "24px"
                                }}>{this.state.checked.length} {i18n.t('mapelterpilih')}</div>
                            </div>
                        </div>
                    </ModalHeader>
                    <ModalFooter style={{ borderTop: 0 }}>
                        <div className='row mx-0' style={{ width: "100%" }}>
                            <div className='col-md-6 px-1'>
                                <button className='btn ipresens-btn-cancel' style={{ width: "100%" }} onClick={() => {
                                    this.setState({
                                        permanentDelete: false
                                    })
                                }}>
                                    {i18n.t('batal')}
                                </button>
                            </div>
                            <div className='col-md-6 px-1'>
                                <button className='btn ipresens-btn-color' style={{
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    lineHeight: "24px",
                                    width: "100%"
                                }} onClick={this.trashMapel}>
                                    {i18n.t('ya')}
                                </button>
                            </div>
                        </div>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}
export default TrashMataPelajaran;