import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Empty, Modal, Spin, Tooltip, Checkbox } from 'antd';
import Swal from "sweetalert2";
import Paging from "../../components/paging";
import { Helmet } from "react-helmet";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";
import i18n from "i18next";
import { pageNumber } from "../../functions/functionGeneral";
import {APIKEY, BACKEND_API_HOST, DEFAULT_IMG} from "../../helper/host";
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { clear_auth, set_auth } from "../../reducers/Auth";
import { connect } from "react-redux";
import EmptyDataImage from "../../assets/images/data-kosong.png";
import {Col, Modal as ModalRs, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import moment from "moment/moment";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg";
import ReactPaginate from "react-paginate";
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import ArrowRight from "../../assets/icons/arrow-right.svg";
import SearchIcon from "../../assets/icons/search.svg";
import PlusIcon from "../../assets/icons/plus-white.svg";
import RefreshIcon from "../../assets/icons/refresh.svg";
import Edit from './edit'
import Add from "./add";
import MoreIcon from "../../assets/icons/more.svg";

export class List_outlet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            karyawan: [],
            data: [],
            dataHistory: [],
            historyLast: [],
            dataTrash: [],
            checked: [],
            idDel: '',
            filter_nama: "",
            radius: "",
            radius_outlet: "",
            pageCount: 0,
            currentPage: 0,
            totalData: 0,
            perPage: 0,
            paging: 20,
            pagination: 2,
            currentPageHistory: 0,
            openModal: false,
            guide: false,
            prosesSimpan: false,
            importFile: null,
            modalimport: false,
            modalHistory: false,
            loadingHistory: false,
            add_visible: false,
            visible: false,
            outlet_id: '',
            nama_outlet: '',
            email_outlet: '',
            no_hp_outlet: '',
            location: {
                lat: '',
                lng: ''
            },
            lokasi_karyawan: []
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.handlePageHistory = this.handlePageHistory.bind(this)
        this.wantDelete = this.wantDelete.bind(this)
        this.importExcel = this.importExcel.bind(this)
    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, data: [], loading: true }, () => {
            this.loadData();
        });
    }

    handleOk = e => {
        this.setState({
            modalexport: false,
            modalImport: false,
            modal: false
        });
    };

    handleCancel = e => {
        this.setState({
            modalexport: false,
            modalimport: false,
            modal: false

        });
    };

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    handleFilter(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,

            data: [],
            loading: true
        }, () => this.loadData())
    }

    handlePageHistory(dataHistory) {
        let selected = dataHistory.selected;
        this.setState({ currentPageHistory: selected, dataHistory: [], loadingHistory: true }, () => {
            this.loadHistory();
        });
    }

    handlePageTrashHistory = (dataHistory) => {
        let selected = dataHistory.selected; // zero based index
        this.setState({ currentPageTrash: selected, dataTrash: [], loadingTrash: true }, () => {
            this.loadTrash();
        });
    }

    resetFilter(event) {
        this.setState({
            filter_nama: '',
            data: [],
            loading: true
        }, () => this.loadData())
    }

    wantDelete() {
        Swal.fire({
            title: i18n.t("pemberitahuan"),
            text: i18n.t("andayakinmenghapuslokasikunjungan"),
            icon: 'warning',
            confirmButtonText: i18n.t("ya"),
            showCancelButton: true,
            cancelButtonText: i18n.t("batal"),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        id: this.state.idDel
                    }
                    this.setState({
                        loading: true,
                        data: [],
                    })
                    this.props.request("outlet/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: i18n.t("berhasil"),
                                    text: i18n.t(response.message),
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    loadData() {
        this.setState({loading: true})
        let form = {
            paginate: 10,
            page: this.state.currentPage + 1,
            filter_nama: this.state.filter_nama
        }
        this.props.request("outlet/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        radius_outlet: response.perusahaan_radius_outlet,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        loading: false
                    })
                }
            });
    }

    exportLokasiKunjungan = () => {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'outlet/export_template?token=' + AuthKey, "_blank")
    }

    importExcel() {
        this.setState({
            importLoading: true
        })

        var AuthKey = localStorage.getItem('Presensi-Auth-Key')

        let formData = new FormData()
        formData.append('file', this.state.importFile)
        formData.append('token', AuthKey)

        fetch(BACKEND_API_HOST + 'outlet/import', {
            method: 'POST',
            headers: {
                'Authorization': 'bearer ' + AuthKey
            },
            body: formData
        })
            .catch((error) => {
                console.error(error);
            })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t("berhasil"),
                        text: i18n.t(response.message),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                    window.location.reload();
                    this.setState({
                        modalimport: false,
                        loading: true,
                        importFile: null,
                        data: [],
                    }, () => {
                        this.loadData()
                    })
                }
                else {
                    Swal.fire({
                        title: i18n.t("gagal"),
                        text: i18n.t(response.message),
                        icon: 'warning',
                        showConfirmButton: false,
                        timer: 1200,
                    })
                    this.setState({
                        modalimport: false,
                    })
                }

                this.setState({ importLoading: false })
            })
    }

    loadHistory(id) {
        this.setState({
            dataHistory: [],
            loadingHistory: true
        }, () => {
            let form = {
                id: id ? id : this.state.outlet_id,
                paging: this.state.pagination,
                page: this.state.currentPageHistory + 1,
            };

            this.props.request("outlet/history", form, 'POST')
                .then(response => {
                    this.setState({
                        loadingHistory: false
                    })
                    if (response.succes) {
                        this.setState({
                            dataHistory: response.data.data,
                            historyLast: response.history,
                            pageCountHistory: response.data.last_page,
                            totalDataHistory: response.data.total,
                            totalPerpageHistory: response.data.to,
                            perPageHistory: response.data.per_page,
                        });
                    } else {
                        this.setState({
                            modalHistory: false,
                            outlet_id: ''
                        });
                    }
                });
        })
    }

    handleRevert = () => {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('yakinmengembalikandata'),
            icon: "warning",
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                    if (result.isConfirmed) {
                        let form = {
                            outlet_id: this.state.outlet_id,
                            history_id: this.state.history_id,
                        }
                        this.props.request("outlet/revert", form, 'POST')
                            .then(response => {
                                if (response.success) {
                                    Swal.fire({
                                        title: i18n.t('berhasil'),
                                        text: i18n.t(response.message),
                                        icon: 'success'
                                    })
                                    this.loadData()
                                    this.setState({
                                        modalHistory: false,
                                        checked: []
                                    })
                                } else {
                                    Swal.fire({
                                        title: i18n.t('gagal'),
                                        text: i18n.t(response.message),
                                        icon: 'warning',
                                        confirmButtonText: 'OK'
                                    })
                                }
                            })
                    }
                }
            )
    }

    loadTrash() {
        this.setState({
            loadingHistory: true,
        }, () => {
            let form = {
                page: this.state.currentPageTrash + 1,
                paginate: 5,
            };
            this.props.request('outlet/trash', form, 'POST')
                .then(response => {
                    if (response.success) {
                        this.setState({
                            dataTrash: response.data.data,
                            pageCountTrash: response.data.last_page,
                            totalDataTrash: response.data.total,
                            totalPerpageTrash: response.data.to,
                            perPageTrash: response.data.per_page,
                            loadingTrash: false
                        })
                    }
                })
        })
    }

    HandleRestore= () => {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: `${this.state.checked.length} ${i18n.t('dataakandipulihkan')}`,
            iconHtml: '<i style="color: #8c2bee;" class="fa fa-refresh"></i>',
            confirmButtonText: i18n.t('restore'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        outlet_id: this.state.checked,
                    }
                    this.props.request('outlet/restore', form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: i18n.t(response.message),
                                    icon: "success",
                                    showConfirmButton: false,
                                    timer: 900,
                                })
                                this.loadData()
                                this.setState({
                                    modaltrash: false,
                                    checked: [],
                                    checkAll: false,
                                })
                            }
                        })
                }
            })
    }

    HandleDeletePermanent = () => {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: `${this.state.checked.length} ${i18n.t('dataakandihapuspermanen')}`,
            iconHtml: '<i style="color: #eb4034;" class="fa fa-trash"></i>',
            confirmButtonText: i18n.t('hapus'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        outlet_id: this.state.checked,
                    }
                    this.props.request('outlet/delete-permanent', form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: i18n.t(response.message),
                                    icon: "success",
                                    showConfirmButton: false,
                                    timer: 900,
                                })
                                this.loadData()
                                this.setState({
                                    modaltrash: false,
                                    checked: [],
                                    checkAll: false,
                                })
                            }
                        })
                }
            })
    }

    handleCheckBoxChangeTrash = (id)=> {
        this.state.checked.includes(id) ?
            this.setState(prevState => ({
                checked: prevState.checked.filter(currId => currId !== id),
            }), () => {
                if (this.state.checked.length !== this.state.totalDataTrash) {
                    this.setState({
                        checkAll: false
                    })
                }
            })
            :
            this.setState(prevState => ({
                checked: [...prevState.checked, id],
            }), () => {
                if (this.state.checked.length === this.state.totalDataTrash) {
                    this.setState({
                        checkAll: true
                    })
                }
            })
    }

    handleCheckBoxAllChangeTrash = () => {
        this.setState(prevState => ({
            checkAll: !prevState.checkAll
        }), () => {
            this.state.checkAll
                ? this.setState({
                    checked: this.state.dataTrash.map(item => item.outlet_id)
                })
                : this.setState({
                    checked: []
                })
        })
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        const style = {
            td_style: {
                padding: "16px 12px",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "24px",
                color: "#252B2B"
            }
        }
        let no = 0;
        if (!this.props.user.user_level.level_page_limited || this.props.user.user_level.level_page_limited.search("Lokasi Kunjungan") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{i18n.t("daftarlokasikunjungan")} - iPresens</title>
                        <meta name="description" content="Daftar Lokasi Kunjungan" />
                    </Helmet>
                    <Breadcrumb title={i18n.t("daftarlokasikunjungan")} parent={i18n.t("lokasikunjungan")} setGuide={() => { this.setState({ guide: true }) }} />
                    <Tour isOpen={this.state.guide === true} onAfterOpen={this.disableBody} onBeforeClose={this.enableBody} onRequestClose={() => this.setState({ guide: false })} />
                    <Add
                        add_visible={this.state.add_visible}
                        request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                        changeState={(state, callback) => {
                            this.setState(state, () => {
                                if (typeof callback != 'undefined')
                                    callback()
                            })
                        }}
                        loadData={() => this.loadData()}
                    />
                    <Edit
                        data={this.state}
                        request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                        changeState={(state, callback) => {
                            this.setState(state, () => {
                                if (typeof callback != 'undefined')
                                    callback()
                            })
                        }}
                        loadData={() => this.loadData()}
                    />
                    <Modal
                        title={i18n.t("importlokasikunjungan")}
                        visible={this.state.modalimport}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        footer={[
                            this.state.importLoading ?
                                <button className={'btn ipresens-btn-color'} style={{textTransform: "none", width: "100%"}} disabled={true}><i className="fa fa-refresh fa-spin"></i> {i18n.t("sedangmenguploaddata")}</button>
                                :
                                <button className={this.state.importFile === null ? 'btn bg-secondary-custom' : 'btn ipresens-btn-color'} style={{textTransform: "none", width: "100%"}} disabled={this.state.importFile === null} onClick={this.importExcel}>Import Data</button>
                        ]}
                    >
                        <div>
                            <a style={{ color: "#5D6F80", cursor: "default" }}>{i18n.t("importcutitext")}</a><br />
                            <a onClick={this.exportLokasiKunjungan} style={{ color: "#057CE4" }}>{i18n.t("importcutitemplates")}</a>
                            <div style={{ position: 'relative', width: '100%', backgroundColor: '#F9F9F9', padding: 16, borderRadius: 6, marginTop: 8, border: "1px dashed #DDDDDD" }}>
                                <input type='file'
                                       accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                       onChange={(event) => {
                                           this.setState({
                                               importFile: event.target.files[0],
                                           })
                                       }} /><br />
                            </div>
                        </div>
                    </Modal>
                    <ModalRs size="lg" style={{ maxWidth: "1000px" }} isOpen={this.state.modalHistory} toggle={() => this.setState({ modalHistory: false })}>
                        <ModalHeader style={{ backgroundColor: "#FAFDFF" }} toggle={() => this.setState({ modalHistory: false })}>
                            <div className='modal-header-reactstrap'>{i18n.t("riwayatperubahan")}</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="list-group">
                                <div>
                                    {this.state.loadingHistory && (
                                        <div style={{ textAlign: 'center', margin: '10px auto' }}>
                                            <Spin
                                                size="large"
                                                tip="Loading..."
                                                style={{
                                                    width: '100%',
                                                    margin: 'auto',
                                                }}
                                            />
                                        </div>
                                    )}

                                    {this.state.dataHistory.length !== 0 && (
                                        <div>
                                            <Row>
                                                <Col xs={0} md={0} className="text-left" style={{ marginLeft: '20px' }}>
                                                    <h4>User</h4>
                                                </Col>
                                                <Col xs={0} md={0} className="text-left" style={{ marginRight: '20px' }}>
                                                    <h4>{i18n.t("aktivitas")}</h4>
                                                </Col>
                                            </Row>

                                            {this.state.dataHistory.map((item, index) => {
                                                return(
                                                    <div key={index} style={{ marginBottom: '20px' }}>
                                                        <Row>
                                                            <Col xs={12} md={6} className="text-left">
                                                                <div className="d-flex align-items-center" style={{padding: '16px 12px',}}>
                                                                    <div style={{marginRight: '16px'}}>
                                                                        <img src={DEFAULT_IMG} alt=""
                                                                            style={{
                                                                                width: '48x',
                                                                                marginBottom: '25px',
                                                                                height: '48px'
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div style={{flex: 1}}>
                                                                        <h6 className="mb-0 font-13"
                                                                            style={{
                                                                                fontWeight: 500,
                                                                                fontSize: '16px',
                                                                                lineHeight: '24px',
                                                                            }}
                                                                        >
                                                                            {item.p_user_name} | {item.level_name}
                                                                        </h6>
                                                                        <p className="m-0 font-12">
                                                                            <span className="col-green font-weight-bold">
                                                                                {moment(item.created_at).format('lll')}
                                                                            </span>
                                                                        </p>
                                                                        <div className="d-flex mt-3">
                                                                            {item.history_status === "created" ? (
                                                                                <div className="d-flex justify-content-between w-75">
                                                                                    <div className="col d-flex ipresens-text-color justify-content-center align-items-center mr-2 py-2"
                                                                                        style={{
                                                                                            border: '1px solid #000',
                                                                                            borderRadius: '25px',
                                                                                            borderColor: '#7805e4',
                                                                                        }}>
                                                                                        {i18n.t('dibuat')}
                                                                                    </div>
                                                                                    {this.state.historyLast.history_id === item.history_id ?
                                                                                        <div className="col"></div> :
                                                                                        <div className="col d-flex justify-content-center align-items-center ipresens-btn-revert py-2"
                                                                                            onClick={() => {
                                                                                                this.setState({
                                                                                                    outlet_id: item.outlet_id,
                                                                                                    history_id: item.history_id,
                                                                                                });
                                                                                                this.handleRevert();
                                                                                            }}
                                                                                        >
                                                                                            {i18n.t('revert')}
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            ) : item.history_status === "reverted" ? (
                                                                                <div className="w-75 text-center py-2"
                                                                                     style={{
                                                                                         padding: '6px 0',
                                                                                         borderRadius: '25px',
                                                                                         color: '#7805e4',
                                                                                         border: '1px solid #7805e4',
                                                                                     }}
                                                                                >
                                                                                    {i18n.t('dikembalikan')}
                                                                                </div>
                                                                            ) : (
                                                                                <div className="d-flex justify-content-between w-75">
                                                                                    <div className="col d-flex justify-content-center align-items-center border border-danger text-danger mr-2 py-2"
                                                                                        style={{
                                                                                            borderRadius: '25px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t('diubah')}
                                                                                    </div>
                                                                                    {this.state.historyLast.history_id === item.history_id ?
                                                                                        <div className="col"></div>
                                                                                        :
                                                                                        <div className="col d-flex justify-content-center align-items-center ipresens-btn-revert py-2"
                                                                                            style={{
                                                                                                border: '1px solid #000',
                                                                                                borderRadius: '25px',
                                                                                                color: '#7805e4',
                                                                                                borderColor: '#7805e4',
                                                                                            }}
                                                                                            onClick={() => {
                                                                                                this.setState({
                                                                                                    outlet_id: item.outlet_id,
                                                                                                    history_id: item.history_id,
                                                                                                });
                                                                                                this.handleRevert();
                                                                                            }}
                                                                                        >
                                                                                            {i18n.t('revert')}
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} md={6} className="text-left" style={{ paddingTop: '16px',paddingBottom:'16px', paddingRight:'12px', paddingLeft:'12px' }}>
                                                                {item.hitory_status !== 'created' && (
                                                                    <div>
                                                                        {item.parent_history ?
                                                                            item.parent_history.outlet_nama != item.outlet_nama && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t("namalokasikunjungandari")} {' '}
                                                                                        <b>{item.parent_history
                                                                                            ? item.parent_history.outlet_nama
                                                                                            : 'N/A'}</b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t("jadi")} : <b>{item.outlet_nama ? item.outlet_nama : ''}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.ouutlet_nama_pemilik != item.ouutlet_nama_pemilik && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t("pemiliklokasikunjungandari")} {' '}
                                                                                        <b>{item.parent_history
                                                                                            ? item.parent_history.ouutlet_nama_pemilik
                                                                                            : 'N/A'}</b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t("jadi")} : <b>{item.ouutlet_nama_pemilik ? item.ouutlet_nama_pemilik : ''}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.outlet_email != item.outlet_email && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t("emaillokasikunjungandari")} {' '}
                                                                                        <b>{item.parent_history
                                                                                            ? item.parent_history.outlet_email
                                                                                            : 'N/A'}</b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t("jadi")} : <b>{item.outlet_email ? item.outlet_email : ''}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.outlet_no_hp != item.outlet_no_hp && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t("nolokasikunjungandari")}{' '}
                                                                                        <b>{item.parent_history
                                                                                            ? item.parent_history.outlet_no_hp
                                                                                            : 'N/A'}</b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t("jadi")} : <b>{item.outlet_no_hp ? item.outlet_no_hp : ''}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.outlet_alamat != item.outlet_alamat && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t("alamatlokasikunjungandari")} {' '}
                                                                                        <b>{item.parent_history
                                                                                            ? item.parent_history.outlet_alamat
                                                                                            : 'N/A'}</b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t("jadi")} : <b>{item.outlet_alamat ? item.outlet_alamat : ''}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.outlet_radius != item.outlet_radius && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t("radiuslokasikunjungandari")} {' '}
                                                                                        <b>{item.parent_history
                                                                                            ? item.parent_history.outlet_radius
                                                                                            : 'N/A'}</b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t("jadi")} : <b>{item.outlet_radius ? item.outlet_radius : ''}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.outlet_latitude != item.outlet_latitude && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t("latitudedari")} {' '}
                                                                                        <b>{item.parent_history
                                                                                            ? item.parent_history.outlet_latitude
                                                                                            : 'N/A'}</b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t("jadi")} : <b>{item.outlet_latitude ? item.outlet_latitude : ''}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.outlet_longitude != item.outlet_longitude && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t("longitudedari")}{' '}
                                                                                        <b>{item.parent_history
                                                                                            ? item.parent_history.outlet_longitude
                                                                                            : 'N/A'}</b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t("jadi")} : <b>{item.outlet_longitude ? item.outlet_longitude : ''}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            (item.parent_history.parent_karyawan_id.length !== item.data_karyawan_id.length ||
                                                                                item.parent_history.parent_karyawan_id.some((id, index) => id !== item.data_karyawan_id[index])) && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t("karyawanlokasikunjungandari")} {' '}
                                                                                        <b>{item.parent_history
                                                                                            ? item.parent_history.parent_karyawan_nama.slice(0, 3).toString().replaceAll(",", ", ")
                                                                                            : 'N/A'}</b>
                                                                                        &nbsp;{item.parent_history.parent_karyawan_nama.length > 3 && (<i>,dll</i>)}{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        />
                                                                                        {i18n.t("jadi")} :&nbsp;
                                                                                        <b>{item.data_karyawan_nama
                                                                                            ? item.data_karyawan_nama.slice(0, 3).toString().replaceAll(",", ", ")
                                                                                            : 'N/A'}</b>
                                                                                        &nbsp;{item.data_karyawan_nama.length > 3 && (<i>,dll</i>)}
                                                                                    </h6>
                                                                                </div>
                                                                            ) : null
                                                                        }
                                                                    </div>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{backgroundColor: "#F5F5F5"}}>
                            <div className='row mx-0' style={{backgroundColor: "#F5F5F5", width: "100%"}}>
                                {this.state.dataHistory.length !== 0 &&
                                    <>
                                        <div className='col font-14' style={{
                                            padding: "auto",
                                            margin: "auto",
                                            fontWeight: 500,
                                            lineHeight: "24px"
                                        }}>{i18n.t("menampilkan")} {this.state.dataHistory.length === 0 || this.state.dataHistory.length === null ? "0" : this.state.dataHistory.length} {i18n.t("dari")} {this.state.totalDataHistory} data
                                        </div>
                                        <div className='col'>
                                            <ReactPaginate
                                                previousLabel={<img src={ArrowLeft} alt=''/>}
                                                nextLabel={<img src={ArrowRight} alt=''/>}
                                                pageCount={this.state.pageCountHistory}
                                                forcePage={this.state.currentPageHistory}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={this.handlePageHistory}
                                                breakClassName={'page-item'}
                                                breakLinkClassName={'page-link custom-page-link'}
                                                containerClassName={'pagination my-2'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link custom-page-link'}
                                                previousClassName={'page-item'}
                                                previousLinkClassName={'page-link custom-prev-next-link'}
                                                nextClassName={'page-item'}
                                                nextLinkClassName={'page-link custom-prev-next-link'}
                                                activeClassName={'active custom-active'}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </ModalFooter>
                    </ModalRs>
                    <ModalRs size="lg" style={{ maxWidth: "1000px" }} isOpen={this.state.modaltrash} toggle={() => this.setState({ modaltrash: false, checked: [], checkAll: false,})}>
                        <ModalHeader className='pengumuman-modal-header' toggle={() => this.setState({modaltrash: false, checked: [], checkAll: false,})}>
                            <div className='pengumuman-modal-header-font'>{i18n.t("trashoutlet")}</div>
                        </ModalHeader>
                        <ModalBody className="pengumuman-modal-body">
                            <div className="d-flex justify-content-end">
                                <button className="btn ipresens-btn-color mr-2 text-capitalize" disabled={this.state.checked.length === 0} onClick={this.HandleRestore}>
                                    {i18n.t('restore')}
                                </button>
                                <button className="btn ipresens-btn-color text-capitalize" disabled={this.state.checked.length === 0} onClick={this.HandleDeletePermanent}>
                                    {i18n.t('hapuspermanent')}
                                </button>
                            </div>
                            <div className="list-group">
                                <div>
                                    {!this.state.loadingTrash && this.state.dataTrash.length === 0 ?
                                        <Empty description="Data Kosong"
                                               image={EmptyDataImage} className="ipresens-image-empty my-5"/>
                                        :
                                        <>
                                            <Table className="table table-borderless table-striped mb-5">
                                                <Thead>
                                                    <Tr>
                                                        <Th width="30"><Checkbox checked={this.state.checkAll} onChange={this.handleCheckBoxAllChangeTrash}></Checkbox></Th>
                                                        <Th className="lembur-th-style">{i18n.t("lokasikunjungan")}</Th>
                                                        <Th className="lembur-th-style">{i18n.t("email")}</Th>
                                                        <Th className="lembur-th-style">{i18n.t("namapemilik")}</Th>
                                                        <Th className="lembur-th-style">{i18n.t("alamat")}</Th>
                                                        <Th className="lembur-th-style">{i18n.t("notelepon")}</Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {this.state.loadingTrash ?
                                                        <Tr>
                                                            <Td colSpan="9" style={{
                                                                textAlign: "center",
                                                                backgroundColor: "#FFFFFF"
                                                            }}>
                                                                <Spin size="large"
                                                                      tip={i18n.t("sedangmemuatdata") + ' ...'}
                                                                      style={{
                                                                          width: '100%',
                                                                          margin: 'auto',
                                                                          marginBottom: '10px',
                                                                          marginTop: '10px'
                                                                      }}/>
                                                            </Td>
                                                        </Tr>
                                                        :
                                                        this.state.dataTrash.map((item) => {
                                                            return (
                                                                <Tr>
                                                                    <Td><Checkbox checked={this.state.checked.includes(item.outlet_id)} onChange={() => this.handleCheckBoxChangeTrash(item.outlet_id)}></Checkbox></Td>
                                                                    <Td className="lembur-td-style fw-900" align='left'>{item.outlet_nama}</Td>
                                                                    <Td className="lembur-td-style" align='left'>{item.outlet_email}</Td>
                                                                    <Td className="lembur-td-style" align='left'>{item.outlet_nama_pemilik}</Td>
                                                                    <Td className="lembur-td-style" align='left'>{item.outlet_alamat}</Td>
                                                                    <Td className="lembur-td-style" align='left'>{item.outlet_no_hp}</Td>
                                                                </Tr>
                                                            );
                                                        })
                                                    }
                                                </Tbody>
                                            </Table>
                                        </>
                                    }
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{ backgroundColor: "#F5F5F5", borderBottomLeftRadius: "8px", borderBottomRightRadius: "8px" }}>
                            <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                                {this.state.dataTrash.length !== 0 &&
                                    <>
                                        <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>{i18n.t("menampilkan")} {this.state.dataTrash.length === 0 || this.state.dataTrash.length === null ? "0" : this.state.dataTrash.length} {i18n.t("dari")} {this.state.totalDataHistory} {i18n.t("data")}</div>
                                        <div className='col'>
                                            <Paging
                                                className="float-right"
                                                handlePageClick={this.handlePageTrashHistory}
                                                pageCount={this.state.pageCountTrash}
                                                currentPage={this.state.currentPageTrash}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </ModalFooter>
                    </ModalRs>
                    <div className="container-fluid">
                        <div className="card" style={{border: "1px solid #DDDDDD"}}>
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className='d-flex flex-row'>
                                            <div className='flex-fill pr-2'>
                                                <div className='input-group btn-srch-kbeban'>
                                                <span className='input-group-text izin-search-karyawan-img'>
                                                    <img alt='' src={SearchIcon}/>
                                                </span>
                                                    <input type='text' onChange={this.handleChange}
                                                           name='filter_nama' value={this.state.filter_nama}
                                                           className='form-control izin-search-karyawan-form'
                                                           placeholder={i18n.t("namalokasikunjungan") + "..."}
                                                           style={{
                                                               padding: "12px 10px",
                                                           }}
                                                           onKeyDown={(e) => {
                                                               if (e.key === "Enter") {
                                                                   this.loadData()
                                                               }
                                                           }}></input>
                                                </div>
                                            </div>
                                            <div>
                                                <button className='btn ipresens-btn-reset border px-2'>
                                                    <img alt="" src={RefreshIcon} onClick={(e) => {
                                                        this.setState({
                                                            filter_nama: ""
                                                        }, () => this.loadData())
                                                    }}/></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7 d-flex justify-content-end flex-wrap">
                                        <div className="flex-grow-1 mr-2">
                                            <button
                                                className="btn ipresens-btn-color text-capitalize width-filter pull-right"
                                                onClick={() => {
                                                    this.setState({add_visible: true});
                                                }}
                                            >
                                                <img src={PlusIcon} alt="Tambah Lokasi"/> {i18n.t('tambahlokasi')}
                                            </button>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <Tooltip zIndex={1} trigger={'click'} placement="bottomLeft" color={'#FFF'}
                                                     title={
                                                         <div className={'p-2'}>
                                                             <a onClick={() => {
                                                                 this.setState({modalimport: true})
                                                             }}
                                                                style={{marginRight: "50px"}}
                                                                className={'text-dark'}>Import Excel
                                                             </a><br/>
                                                             <a onClick={() => {
                                                                 this.setState({
                                                                     modaltrash: true,
                                                                     currentPageTrash: 0
                                                                 }, () => this.loadTrash())
                                                             }}
                                                                style={{marginRight: "50px"}}
                                                                className={'text-dark'}>{i18n.t('trashoutlet')}
                                                             </a><br/>
                                                         </div>
                                                     }>
                                                <a>
                                                    <i className='btn border border-dark border-1 px-2'>
                                                        <img alt="More" src={MoreIcon}/>
                                                    </i>
                                                </a>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="table-responsive">
                                            {!this.state.loading && this.state.data.length === 0 ?
                                                <Empty description="Data Kosong"
                                                       image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                                :
                                                <Table className="table table-striped table-borderless mb-5">
                                                    <Thead>
                                                        <Tr>
                                                            <Th className='lembur-th-style'>#</Th>
                                                            <Th className='lembur-th-style text-center'>{i18n.t("lokasikunjungan")}</Th>
                                                            <Th className='lembur-th-style text-center'>Email</Th>
                                                            <Th className='lembur-th-style text-center'>{i18n.t("namapemilik")}</Th>
                                                            <Th className='lembur-th-style text-center'>{i18n.t("alamat")}</Th>
                                                            <Th className='lembur-th-style text-center'>{i18n.t("notelepon")}</Th>
                                                            <Th className='lembur-th-style text-center'>{i18n.t("karyawan")}</Th>
                                                            <Th className='lembur-th-style text-center' width={140}>{i18n.t("aksi")}</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {this.state.loading ?
                                                            <Tr>
                                                                <Td colSpan={9} style={{textAlign: "center", backgroundColor: "#FFFFFF"}}>
                                                                    <Spin size="large" tip="Loading..." style={{
                                                                        width: '100%',
                                                                        margin: 'auto',
                                                                        marginBottom: '10px',
                                                                        marginTop: '10px'
                                                                    }}/>
                                                                </Td>
                                                            </Tr>
                                                            :
                                                            this.state.data.map((item, index) => {
                                                                no += 1
                                                                let status = 'badge'
                                                                return (
                                                                    <Tr>
                                                                        <Td style={style.td_style} scope="row">{pageNumber(no, this.state.perPage, this.state.currentPage)}</Td>
                                                                        <Td align="center"
                                                                            style={{...style.td_style,
                                                                                fontWeight: 700,
                                                                                fontSize: "14px",
                                                                                color: "#252B2B",
                                                                                lineHeight: "24px"}}>{item.outlet_nama}
                                                                        </Td>
                                                                        <Td style={style.td_style} align="center">{item.outlet_email !== null ? item.outlet_email : '-'}</Td>
                                                                        <Td style={style.td_style} align="center">{item.outlet_nama_pemilik !== null ? item.outlet_nama_pemilik : '-'}</Td>
                                                                        <Td style={style.td_style} align="center">{item.outlet_alamat !== null ? item.outlet_alamat : '-'}</Td>
                                                                        <Td style={style.td_style} align="center">{item.outlet_no_hp !== null ? item.outlet_no_hp : '-'}</Td>
                                                                        <Td style={style.td_style} align="center">{item.karyawan.length} Karyawan</Td>
                                                                        <Td style={style.td_style} align="center">
                                                                            <Tooltip trigger={'click'} placement="bottom" zIndex={1}
                                                                                     color={'#fff'} title={
                                                                                <div className={'p-2'}>
                                                                                    <a onClick={() => {
                                                                                        this.setState({
                                                                                            modalHistory: true,
                                                                                            outlet_id: item.outlet_id,
                                                                                            currentPageHistory: 0
                                                                                        }, () => {
                                                                                            this.loadHistory(item.outlet_id);
                                                                                        })
                                                                                    }}
                                                                                       className={'text-dark'}>{i18n.t("riwayat")}</a><br/>
                                                                                    <a onClick={() => {
                                                                                        this.setState({
                                                                                            modal: item.outlet_id
                                                                                        })
                                                                                    }}
                                                                                       className={'text-dark'}>{i18n.t("lihatlokasi")}</a><br/>
                                                                                    <a
                                                                                        className="text-dark"
                                                                                        onClick={() => {
                                                                                            this.setState(
                                                                                                {
                                                                                                    visible: true,
                                                                                                    outlet_id: item.outlet_id,
                                                                                                    nama_outlet: item.outlet_nama,
                                                                                                    nama_pemilik: item.outlet_nama_pemilik,
                                                                                                    email_outlet: item.outlet_email,
                                                                                                    no_hp_outlet: item.outlet_no_hp,
                                                                                                    alamat: item.outlet_alamat,
                                                                                                    radius: item.outlet_radius,
                                                                                                    location: {
                                                                                                        lat: parseFloat(item.outlet_latitude),
                                                                                                        lng: parseFloat(item.outlet_longitude),
                                                                                                    },
                                                                                                    karyawan: item.karyawan,
                                                                                                    lokasi_karyawan: item.karyawan
                                                                                                        ? item.karyawan.map((karyawanItem) => karyawanItem.karyawan_id)
                                                                                                        : [],
                                                                                                },
                                                                                                () => {
                                                                                                }
                                                                                            )
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t("edit")}
                                                                                    </a><br/>
                                                                                    <a onClick={() => {
                                                                                        this.setState({
                                                                                            idDel: item.outlet_id
                                                                                        })
                                                                                        this.wantDelete()
                                                                                    }}
                                                                                       className={'text-danger'}>{i18n.t("hapus")}</a>
                                                                                </div>
                                                                            }>
                                                                            <a><i className="fa fa-ellipsis-v"
                                                                                      style={{
                                                                                          width: 35,
                                                                                          fontSize: 18,
                                                                                          padding: 11,
                                                                                          color: 'rgb(51 51 51)'
                                                                                      }}>
                                                                                </i></a>
                                                                            </Tooltip>
                                                                            <Modal
                                                                                visible={this.state.modal == item.outlet_id}
                                                                                onOk={this.handleOk}
                                                                                onCancel={this.handleCancel}
                                                                            >
                                                                                <div style={{
                                                                                    position: 'relative',
                                                                                    width: '100%',
                                                                                    height: '250px'
                                                                                }}>
                                                                                    <Map
                                                                                        google={this.props.google}
                                                                                        initialCenter={{
                                                                                            lat: item.outlet_latitude,
                                                                                            lng: item.outlet_longitude
                                                                                        }}
                                                                                        zoom={15}>
                                                                                        <Marker/>
                                                                                    </Map>
                                                                                </div>
                                                                            </Modal>
                                                                        </Td>
                                                                    </Tr>
                                                                )
                                                            })
                                                        }
                                                    </Tbody>
                                                </Table>
                                            }
                                            <Paging
                                                className="float-right"
                                                handlePageClick={this.handlePageClick}
                                                pageCount={this.state.pageCount}
                                                currentPage={this.state.currentPage}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">{i18n.t("tidakadaakseshalaman")}</h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(GoogleApiWrapper({
    apiKey: APIKEY
})(List_outlet));
