import React, { Component, Fragment } from 'react';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Swal from "sweetalert2";
import Paging from "../../../components/paging";
import {Checkbox, Empty, Spin, Tooltip} from 'antd';
import i18n from "i18next";
import AddUserLevel from "./addlevel";
import EditLevel from "./editlevel";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { Helmet } from "react-helmet";
import { pageNumber } from "../../../functions/functionGeneral";
import { clear_auth, set_auth } from "../../../reducers/Auth";
import { connect } from "react-redux";
import EmptyDataImage from "../../../assets/images/data-kosong.png";
import ReactPaginate from "react-paginate";
import {Col, Modal as ModalRs, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {DEFAULT_IMG} from "../../../helper/host";
import moment from "moment";
import ArrowRight from "../../../assets/icons/arrow-right.svg";
import ArrowLeft from "../../../assets/icons/arrow-left.svg";
import MoreIcon from "../../../assets/icons/more.svg";
import Trash from "../../../assets/icons/trash.svg";
import Restore from "../../../assets/icons/update.svg";

const imgMyimageexample = require('../../../assets/images/bg3.png');
const divStyle = {
    width: '100%',
    height: '50%',
};

export class UserLevelPerusahaan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            divisi: [],
            dataPrimary: '',
            idDel: '',
            isLoading: true,
            pageCount: 0,
            currentPage: 0,
            totalData: 0,
            perPage: 0,
            paging: 20,
            p_user_name: '',
            p_user_id: '',
            p_user_email: '',
            filter_nama: '',
            add_visible: false,
            visable: false,
            guide: false,
            level_id: '',
            pagination: 2,
            currentPageHistory: 0,
            dataHistory: [],
            loadingHistory: false,
            modalHistory: false,
            id_LevelHistory: 0,
            id_history: 0,
            historyLast: [],
            trashHistory: [],
            modalTrash: false,
            restore: false,
            deletePermanent: false,
            checkedTrash: [],
            checkAllTrash: false,
        }
        this.handleChange = this.handleChange.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.wantDelete = this.wantDelete.bind(this)
        this.handlePageHistory = this.handlePageHistory.bind(this)
        this.handleCheckBoxAllChangeTrash = this.handleCheckBoxAllChangeTrash.bind(this)
        this.handleCheckBoxChangeTrash = this.handleCheckBoxChangeTrash.bind(this)
    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected }, () => {
            this.loadData();
        });
    }

    resetFilter(event) {
        this.setState({
            filter_nama: '',
            data: [],
            isLoading: true
        }, () => this.loadData())
    }


    wantDelete() {
        Swal.fire({
            title: i18n.t("pemberitahuan"),
            text: i18n.t("andayakinhapus"),
            icon: 'warning',
            confirmButtonText: i18n.t("ya"),
            showCancelButton: true,
            cancelButtonText: i18n.t("batal"),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        isLoading: true,
                        data: []
                    })
                    let form = {
                        id: this.state.idDel
                    }
                    this.props.request("userlevel/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: i18n.t("berhasil"),
                                    text: i18n.t(response.message),
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.setState({
                                    isLoading: true,
                                    data: []
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    loadDivisi() {
        let form = {
            filter_shift: "",
            filter_nama: "",
            paginate: 99,
            page: 1,
        }
        this.props.request("divisi/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        divisi: response.data.data,
                    })
                }
            });
    }

    loadData() {
        let form = {
            filter_nama: this.state.filter_nama,
            paginate: 10,
            page: this.state.currentPage + 1,
        }
        this.props.request("userlevel/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        dataPrimary: response.primary,
                        isLoading: false,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                    })
                }
            });
    }

    loadHistory(id){
        this.setState({
                dataHistory: [],
                loadingHistory: true
            },()=>{
                let form = {
                    id : id ? id  : this.state.level_id,
                    paging        : this.state.pagination,
                    page          : this.state.currentPageHistory + 1,
                };
                this.props.request("userlevel/history-user-level",form,'POST')
                    .then(response => {
                        this.setState({
                            loadingHistory: false
                        })
                        if (response.success){
                            this.setState({
                                dataHistory         : response.data.data,
                                historyLast         : response.history,
                                pageCountHistory    : response.data.last_page,
                                totalDataHistory    : response.data.total,
                                totalPerpageHIstory : response.data.to,
                                perPageHistory      : response.data.per_page
                            });
                        } else {
                            this.setState({
                                modalHistory: false,
                                level_id: ''
                            })
                        }
                    })
            }
        )
    }

    handleSubmitRevert = () => {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('yakinmengembalikanuserlevel'),
            icon: "warning",
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                    if (result.isConfirmed) {
                        let form = {
                            level_id: this.state.id_LevelHistory,
                            history_id: this.state.id_history,
                        }
                        this.props.request("userlevel/revert", form, 'POST')
                            .then(response => {
                                if (response.success) {
                                    Swal.fire({
                                        title: i18n.t('berhasil'),
                                        text: response.message,
                                        icon: 'success'
                                    })
                                    this.setState({
                                        modalHistory: false
                                    })
                                    this.props.history.push('/settings/user/level')
                                    this.loadData()
                                } else {
                                    Swal.fire({
                                        title: i18n.t('gagal'),
                                        text: response.message,
                                        icon: 'warning',
                                        confirmButtonText: 'OK'
                                    })
                                }
                            })
                    }
                }
            )
    }

    loadTrash(id) {
        this.setState({
            trashHistory: [],
            loading_history: true,
        }, () => {
            let form = {
                perusahaan_id: this.state.perusahaan_id,
                page: this.state.currentPageHistory + 1,
                paginate: 4,
            };
            this.props.request('userlevel/trash', form, 'POST')
                .then(response => {
                    if (response.success) {
                        this.setState({
                            trashHistory: response.data.data,
                            pageCountHistory: response.data.last_page,
                            totalDataHistory: response.data.total,
                            totalPerpageHistory: response.data.to,
                            perPageHistory: response.data.per_page,
                            loading_history: false
                        })
                    } else {
                        this.setState({
                            modal_history: false, level_id: 0, perusahaan_id: 0,
                            modalTrash: false,
                        })
                    }
                })
        })
    }

    handleCheckBoxAllChangeTrash() {
        this.setState(prevState => ({
            checkAllTrash: !prevState.checkAllTrash
        }), () => {
            this.state.checkAllTrash
                ? this.setState({
                    checkedTrash: this.state.trashHistory.map(item => item.level_id)
                })
                : this.setState({
                    checkedTrash: []
                })
        })
    }

    handleCheckBoxChangeTrash(id) {
        this.state.checkedTrash.includes(id) ?
            this.setState(prevState => ({
                checkedTrash: prevState.checkedTrash.filter(currId => currId !== id),
            }), () => {
                if (this.state.checkedTrash.length !== this.state.totalDataHistory) {
                    this.setState({
                        checkAllTrash: false
                    })
                }
            })
            :
            this.setState(prevState => ({
                checkedTrash: [...prevState.checkedTrash, id],
            }), () => {
                if (this.state.checkedTrash.length === this.state.totalDataHistory) {
                    this.setState({
                        checkAllTrash: true
                    })
                }
            })
    }

    restore = (id) => {
        let form = {
            level_id: this.state.checkedTrash,
        }
        this.props.request('userlevel/restore', form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('databerhasildikembalikan'),
                        icon: "success",
                        showConfirmButton: false,
                        timer: 900,
                    })
                    this.loadData()
                    this.setState({
                        modalTrash: false,
                        checkedTrash: [],
                        restore: false,
                        checkAllTrash: false,
                    })
                }
            })
    }

    deletePermanent = (id) => {
        let form = {
            level_id: this.state.checkedTrash,
        }
        this.props.request('userlevel/delete-permanent', form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('databerhasildihapus'),
                        icon: "success",
                        showConfirmButton: false,
                        timer: 900,
                    })
                    this.loadData()
                    this.setState({
                        modalTrash: false,
                        checkedTrash: [],
                        permanentDelete: false,
                        checkAllTrash: false,
                    })
                }
            })
    }

    handlePageHistory(dataHistory){
        let selected = dataHistory.selected;
        this.setState({ currentPageHistory: selected, dataHistory: [], loadingHistory: true},()=>{
            this.loadHistory()
        })
    }

    componentDidMount() {
        this.loadData()
        this.loadDivisi()
    }

    render() {
        let no = 0;

        if (!this.props.user.user_level.level_page_limited || this.props.user.user_level.level_page_limited.search("Pengaturan") >= 0) {
            return (
                <div>
                    {/*{!this.state.isLoading &&*/}
                        <Fragment>
                            <Helmet>
                                <meta charSet="utf-8" />
                                <title>Daftar User Level - Presensi</title>
                                <meta name="description" content="Detail User" />
                            </Helmet>

                            <EditLevel
                                data={this.state}
                                perusahaan_level={this.props.user.perusahaan}
                                request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                                changeState={(state, callback) => {
                                    this.setState(state, () => {
                                        if (typeof callback != 'undefined')
                                            callback()
                                    })
                                }}
                                loadData={() => this.loadData()}
                            />

                            <AddUserLevel
                                add_visible={this.state.add_visible}
                                divisi={this.state.divisi}
                                perusahaan_level={this.props.user.perusahaan}
                                request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                                changeState={(state, callback) => {
                                    this.setState(state, () => {
                                        if (typeof callback != 'undefined')
                                            callback()
                                    })
                                }}
                                loadData={() => this.loadData()}
                            />

                            <ModalRs size='lg' style={{maxWidth: '1000px'}} isOpen={this.state.modalHistory} toggle={() => this.setState({ modalHistory: false, currentPageHistory: 0 })}>
                                <ModalHeader toggle={() => this.setState({modalHistory: false, currentPageHistory: 0})}>
                                    <div className='modal-header-reactstrap'>{i18n.t("riwayatperubahan")} </div>
                                </ModalHeader>
                                <ModalBody>
                                    <div className='list-group'>
                                        <div>
                                            {this.state.loadingHistory && (
                                                <div style={{ textAlign: 'center', margin: '10px auto' }}>
                                                    <Spin
                                                        size="large"
                                                        tip="Loading..."
                                                        style={{
                                                            width: '100%',
                                                            margin: 'auto',
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            {this.state.dataHistory.length !== 0 && (
                                                <div>
                                                    <Row>
                                                        <Col xs={0} md={0} className="text-left" style={{ marginLeft: '20px' }}>
                                                            <h4>User</h4>
                                                        </Col>
                                                        <Col xs={0} md={0} className="text-left" style={{ marginRight: '20px' }}>
                                                            <h4>{i18n.t("aktivitas")} </h4>
                                                        </Col>
                                                    </Row>
                                                    {this.state.dataHistory.map((item, index) => {
                                                        return(
                                                            <div>
                                                                <div key={index} style={{marginBottom: '20px'}}>
                                                                    <Row>
                                                                        <Col xs={12} md={6} className='text-left'>
                                                                            <div style={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                paddingTop: '16px',
                                                                                paddingBottom: '16',
                                                                                paddingRight: '12px',
                                                                                paddingLeft: '12px'
                                                                            }}>
                                                                                <div style={{marginRight: '16px'}}>
                                                                                    <img
                                                                                        src={DEFAULT_IMG}
                                                                                        alt=""
                                                                                        style={{
                                                                                            width: '48x',
                                                                                            marginBottom: '25px',
                                                                                            height: '48px'
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-0 font-13"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {item.p_user_name} | {item.level_name}
                                                                                    </h6>
                                                                                    <p className="m-0 font-12">
                                                                                    <span className="col-green font-weight-bold">
                                                                                        {moment(item.created_at).format('lll')}
                                                                                    </span>
                                                                                    </p>
                                                                                    <div style={{marginTop: 12}}>
                                                                                        {item.history_status === "created" ? (
                                                                                            <div className="row px-3">
                                                                                                <div
                                                                                                    className="mr-2"
                                                                                                    style={{
                                                                                                        border: '1px solid #000',
                                                                                                        borderRadius: '25px',
                                                                                                        width: '50%',
                                                                                                        color: '#7805e4',
                                                                                                        borderColor: '#7805e4',
                                                                                                    }}
                                                                                                >
                                                                                                    <div
                                                                                                        className="d-flex justify-content-center"
                                                                                                        style={{
                                                                                                            paddingTop: '6px',
                                                                                                            paddingBottom: '6px'
                                                                                                        }}>
                                                                                                        {i18n.t('dibuat')}
                                                                                                    </div>
                                                                                                </div>
                                                                                                {this.state.historyLast.history_id === item.history_id ?
                                                                                                    "" :
                                                                                                    <button
                                                                                                        className='col-md-5 d-flex justify-content-center ipresens-btn-revert'
                                                                                                        onClick={() => {
                                                                                                            this.setState({
                                                                                                                id_LevelHistory: item.level_id,
                                                                                                                id_history: item.history_id,
                                                                                                            })
                                                                                                            this.handleSubmitRevert()
                                                                                                        }}>revert
                                                                                                    </button>
                                                                                                }
                                                                                            </div>
                                                                                        ) : item.history_status === "reverted" ?
                                                                                            <div
                                                                                                className="mr-2 px-5"
                                                                                                style={{
                                                                                                    border: '1px solid #000',
                                                                                                    borderRadius: '25px',
                                                                                                    width: '70%',
                                                                                                    color: '#7805e4',
                                                                                                    borderColor: '#7805e4',
                                                                                                }}
                                                                                            >
                                                                                                <div
                                                                                                    className="d-flex justify-content-center"
                                                                                                    style={{
                                                                                                        paddingTop: '6px',
                                                                                                        paddingBottom: '6px'
                                                                                                    }}>
                                                                                                    {i18n.t('dikembalikan')}
                                                                                                </div>
                                                                                            </div>
                                                                                            : (
                                                                                                <div className="row px-3">
                                                                                                    <div
                                                                                                        className="mr-2"
                                                                                                        style={{
                                                                                                            border: '1px solid #000',
                                                                                                            borderRadius: '25px',
                                                                                                            width: '50%',
                                                                                                            color: '#E6462E',
                                                                                                            borderColor: '#E6462E',
                                                                                                        }}
                                                                                                    >
                                                                                                        <div
                                                                                                            className="d-flex justify-content-center"
                                                                                                            style={{
                                                                                                                paddingTop: '6px',
                                                                                                                paddingBottom: '6px'
                                                                                                            }}>
                                                                                                            {i18n.t('diubah')}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {this.state.historyLast.history_id === item.history_id ?
                                                                                                        "" :
                                                                                                        <button
                                                                                                            className='col-md-5 d-flex justify-content-center ipresens-btn-revert'
                                                                                                            onClick={() => {
                                                                                                                this.setState({
                                                                                                                    id_LevelHistory: item.level_id,
                                                                                                                    id_history: item.history_id,
                                                                                                                })
                                                                                                                this.handleSubmitRevert()
                                                                                                            }}>{i18n.t('revert')}
                                                                                                        </button>
                                                                                                    }
                                                                                                </div>
                                                                                            )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs={12} md={6} className="text-left" style={{ paddingTop: '16px',paddingBottom:'16px', paddingRight:'12px', paddingLeft:'12px' }}>
                                                                            {item.history_status !== 'created' && (
                                                                                <div>
                                                                                    {item.parent_history ?
                                                                                        item.parent_history.level_name != item.level_name && (
                                                                                            <div>
                                                                                                <h6 className="mb-3 font-13 text-left"
                                                                                                    style={{
                                                                                                        fontWeight: 500,
                                                                                                        fontSize: '16px',
                                                                                                        lineHeight: '24px',
                                                                                                    }}>
                                                                                                    {i18n.t('namalevel')} : {i18n.t('dari')}{' '}
                                                                                                    {item.parent_history ? item.parent_history.level_name
                                                                                                        : 'N/A'}{' '}
                                                                                                    <img
                                                                                                        src={ArrowRight}
                                                                                                        alt=""
                                                                                                        style={{
                                                                                                            width: '20x',
                                                                                                            marginBottom: '3px',
                                                                                                            height: '20px'
                                                                                                        }}
                                                                                                    /> {i18n.t("jadi")} : {item.level_name ? item.level_name : ''}
                                                                                                </h6>
                                                                                            </div>
                                                                                        ):null
                                                                                    }
                                                                                    {item.parent_history ?
                                                                                        item.parent_history.level_page_limited != item.level_page_limited && (
                                                                                            <div>
                                                                                                <h6 className="mb-3 font-13 text-left"
                                                                                                    style={{
                                                                                                        fontWeight: 500,
                                                                                                        fontSize: '16px',
                                                                                                        lineHeight: '24px',
                                                                                                    }}>
                                                                                                    {i18n.t('akseshalaman')} : {i18n.t('dari')}{' '}
                                                                                                    {item.parent_history ? item.parent_history.level_page_limited
                                                                                                        : 'N/A'}{' '}
                                                                                                    <img
                                                                                                        src={ArrowRight}
                                                                                                        alt=""
                                                                                                        style={{
                                                                                                            width: '20x',
                                                                                                            marginBottom: '3px',
                                                                                                            height: '20px'
                                                                                                        }}
                                                                                                    /> {i18n.t("jadi")} : {item.level_page_limited ? item.level_page_limited : i18n.t('semuahalaman')}
                                                                                                </h6>
                                                                                            </div>
                                                                                        ):null
                                                                                    }
                                                                                    {item.parent_history ?
                                                                                        item.parent_history.level_divisi_limited != item.level_divisi_limited && (
                                                                                            <div>
                                                                                                <h6 className="mb-3 font-13 text-left"
                                                                                                    style={{
                                                                                                        fontWeight: 500,
                                                                                                        fontSize: '16px',
                                                                                                        lineHeight: '24px',
                                                                                                    }}>
                                                                                                    {i18n.t('aksesdivisi')} : {i18n.t('dari')}{' '}
                                                                                                    {item.parent_history ? item.parent_history.divisi.divisi_name
                                                                                                        : 'N/A'}{' '}
                                                                                                    <img
                                                                                                        src={ArrowRight}
                                                                                                        alt=""
                                                                                                        style={{
                                                                                                            width: '20x',
                                                                                                            marginBottom: '3px',
                                                                                                            height: '20px'
                                                                                                        }}
                                                                                                    /> {i18n.t("jadi")} : {item.divisi ? item.divisi.divisi_name : i18n.t('semuadivisi')}
                                                                                                </h6>
                                                                                            </div>
                                                                                        ):null
                                                                                    }
                                                                                </div>
                                                                            )}
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </ModalBody>
                                <ModalFooter  style={{ backgroundColor: "#F5F5F5" }}>
                                    <div className="row mx-0" style={{backgroundColor: "#F5F5F5", width: "100%"}}>
                                        {this.state.dataHistory.length !== 0 &&
                                            <>
                                                <div className="col font-14" style={{padding: "auto", margin:"auto", fontWeight: 500, lineHeight: "24px"}}>
                                                    {i18n.t("menampilkan")}  {this.state.dataHistory.length === 0 || this.state.dataHistory.length === null ? "0" : this.state.dataHistory.length} {i18n.t("dari")}  {this.state.totalDataHistory}
                                                </div>
                                                <div className="col">
                                                    <ReactPaginate
                                                        previousLabel={<img src={ArrowLeft} alt='' />}
                                                        nextLabel={<img src={ArrowRight} alt='' />}
                                                        pageCount={this.state.pageCountHistory}
                                                        forcePage={this.state.currentPageHistory}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={3}
                                                        onPageChange={this.handlePageHistory}
                                                        breakClassName={'page-item'}
                                                        breakLinkClassName={'page-link custom-page-link'}
                                                        containerClassName={'pagination my-2'}
                                                        pageClassName={'page-item'}
                                                        pageLinkClassName={'page-link custom-page-link'}
                                                        previousClassName={'page-item'}
                                                        previousLinkClassName={'page-link custom-prev-next-link'}
                                                        nextClassName={'page-item'}
                                                        nextLinkClassName={'page-link custom-prev-next-link'}
                                                        activeClassName={'active custom-active'}
                                                    />
                                                </div>
                                            </>
                                        }
                                    </div>
                                </ModalFooter>
                            </ModalRs>
                            <ModalRs size="lg" zIndex={999} isOpen={this.state.modalTrash} toggle={() => this.setState({
                                modalTrash: false,
                                checkedTrash: [],
                                trashHistory: [],
                                checkAllTrash: false,
                            })} style={{maxWidth: "1000px"}}>
                                <ModalHeader className='pengumuman-modal-header'>
                                    <div className='pengumuman-modal-header-font'>{i18n.t("trashleveluser")}</div>
                                </ModalHeader>
                                <ModalBody>
                                    <div className="row d-flex justify-content-end">
                                        <div className="col-md-6"></div>
                                        <div className="col-md-6 d-flex justify-content-end">
                                            <button className="btn ipresens-btn-color px-3 py-2 mx-1"
                                                    disabled={this.state.checkedTrash.length === 0}
                                                    onClick={() => {
                                                        this.setState({restore: true});
                                                    }}>
                                                Restore
                                            </button>
                                            <button className="btn ipresens-btn-color px-3 py-2 mx-1"
                                                    disabled={this.state.checkedTrash.length === 0} onClick={() => {
                                                this.setState({permanentDelete: true});
                                            }}>Delete Permanent
                                            </button>
                                        </div>
                                    </div>
                                    <div className="list-group">
                                        <div>
                                            {this.state.loading_history && (
                                                <div style={{textAlign: "center", margin: '10px auto'}}>
                                                    <Spin size="large" tip={i18n.t('loading')}
                                                          style={{width: "100%", margin: "auto"}}/>
                                                </div>
                                            )}
                                            {this.state.trashHistory.length !== 0 && (
                                                <Table className="table table-striped mb-5">
                                                    <Thead>
                                                        <Tr>
                                                            <Th width="50"><Checkbox checked={this.state.checkAllTrash}
                                                                          onChange={this.handleCheckBoxAllChangeTrash}></Checkbox></Th>
                                                            <Th style={{border: "none", fontSize: "13px"}} className="text-left modal-header-ipresens-table" width="200">{i18n.t("namalevel")}</Th>
                                                            <Th style={{border: "none", fontSize: "13px"}} className="text-left modal-header-ipresens-table" width="200">{i18n.t("akseshalaman")}</Th>
                                                            <Th style={{border: "none", fontSize: "13px"}} className="text-left modal-header-ipresens-table" width="200">{i18n.t("aksesdivisi")}</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {this.state.trashHistory.map((item, index) => {
                                                            let no = index + 1;
                                                            return (
                                                                <Tr>
                                                                    <Td><Checkbox checked={this.state.checkedTrash.includes(item.level_id)} onChange={() => this.handleCheckBoxChangeTrash(item.level_id)}></Checkbox></Td>
                                                                    <Td>
                                                                        {item.level_name}
                                                                    </Td>
                                                                    <Td>
                                                                        {item.level_page_limited ? item.level_page_limited : i18n.t('semuahalaman')}
                                                                    </Td>
                                                                    <Td>
                                                                        {item.level_divisi_limited ? item.level_divisi_limited : i18n.t('semuadivisi')}
                                                                    </Td>
                                                                </Tr>
                                                            );
                                                        })}
                                                    </Tbody>
                                                </Table>
                                            )
                                            }
                                        </div>
                                    </div>
                                </ModalBody>
                                <ModalFooter style={{ backgroundColor: "#F5F5F5", borderBottomLeftRadius: "8px", borderBottomRightRadius: "8px" }}>
                                    <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                                        {this.state.trashHistory.length !== 0 &&
                                            <>
                                                <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>{i18n.t("menampilkan")} {this.state.trashHistory.length === 0 || this.state.trashHistory.length === null ? "0" : this.state.trashHistory.length} {i18n.t("dari")} {this.state.totalDataHistory} {i18n.t("data")}</div>
                                                <div className='col'>
                                                    <ReactPaginate
                                                        previousLabel={<img src={ArrowLeft} alt='' />}
                                                        nextLabel={<img src={ArrowRight} alt='' />}
                                                        pageCount={this.state.pageCountHistory}
                                                        forcePage={this.state.currentPageHistory}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={3}
                                                        onPageChange={this.handlePageTrashHistory}
                                                        breakClassName={'page-item'}
                                                        breakLinkClassName={'page-link custom-page-link'}
                                                        containerClassName={'pagination my-2'}
                                                        pageClassName={'page-item'}
                                                        pageLinkClassName={'page-link custom-page-link'}
                                                        previousClassName={'page-item'}
                                                        previousLinkClassName={'page-link custom-prev-next-link'}
                                                        nextClassName={'page-item'}
                                                        nextLinkClassName={'page-link custom-prev-next-link'}
                                                        activeClassName={'active custom-active'}
                                                    />
                                                </div>
                                            </>
                                        }
                                    </div>
                                </ModalFooter>
                            </ModalRs>
                            <ModalRs centered isOpen={this.state.permanentDelete} toggle={() => {
                                this.setState({
                                    permanentDelete: false
                                })
                            }}>
                                <ModalHeader className='pl-4 border-bottom-0' toggle={() => {
                                    this.setState({
                                        permanentDelete: false
                                    })
                                }}>
                                    <div className='d-flex'>
                                        <div className='p-3 mr-4' style={{
                                            backgroundColor: "transparent",
                                            borderRadius: "8px"
                                        }}>
                                            <img alt='' src={Trash}/>
                                        </div>
                                        <div>
                                            <div style={{
                                                color: "#143B5E",
                                                fontWeight: 700,
                                                fontSize: "18px",
                                                lineHeight: "27px"
                                            }}>Hapus Permanen Data User Level?</div>
                                            <div style={{
                                                color: "#5D6F80",
                                                fontWeight: 400,
                                                fontSize: "14px",
                                                lineHeight: "24px"
                                            }}>{this.state.checkedTrash.length} User Level Terpilih</div>
                                        </div>
                                    </div>
                                </ModalHeader>
                                <ModalFooter style={{
                                    borderTop: 0
                                }}>
                                    <div className='row mx-0' style={{ width: "100%" }}>
                                        <div className='col-md-6 px-1'>
                                            <button className='btn ipresens-btn-cancel' style={{ width: "100%" }} onClick={() => {
                                                this.setState({
                                                    permanentDelete: false
                                                })
                                            }}>
                                                {i18n.t('batal')}
                                            </button>
                                        </div>
                                        <div className='col-md-6 px-1'>
                                            <button className='btn ipresens-btn-color' style={{
                                                fontSize: "14px",
                                                fontWeight: 500,
                                                lineHeight: "24px",
                                                width: "100%"
                                            }} onClick={this.deletePermanent}>
                                                {i18n.t('ya')}
                                            </button>
                                        </div>
                                    </div>
                                </ModalFooter>
                            </ModalRs>
                            <ModalRs centered isOpen={this.state.restore} toggle={() => {
                                this.setState({
                                    restore: false
                                })
                            }}>
                                <ModalHeader className='pl-4 border-bottom-0' toggle={() => {
                                    this.setState({
                                        restore: false
                                    })
                                }}>
                                    <div className='d-flex'>
                                        <div className='p-3 mr-4' style={{
                                            backgroundColor: "#8C2BEE",
                                            borderRadius: "8px"
                                        }}>
                                            <img alt='' src={Restore} />
                                        </div>
                                        <div>
                                            <div style={{
                                                color: "#143B5E",
                                                fontWeight: 700,
                                                fontSize: "18px",
                                                lineHeight: "27px"
                                            }}>Pulihkan Data User Level</div>
                                            <div style={{
                                                color: "#5D6F80",
                                                fontWeight: 400,
                                                fontSize: "14px",
                                                lineHeight: "24px"
                                            }}>{this.state.checkedTrash.length} User Level Terpilih</div>
                                        </div>
                                    </div>
                                </ModalHeader>
                                <ModalFooter style={{
                                    borderTop: 0
                                }}>
                                    <div className='row mx-0' style={{ width: "100%" }}>
                                        <div className='col-md-6 px-1'>
                                            <button className='btn ipresens-btn-cancel' style={{ width: "100%" }} onClick={() => {
                                                this.setState({
                                                    restore: false
                                                })
                                            }}>
                                                {i18n.t('batal')}
                                            </button>
                                        </div>
                                        <div className='col-md-6 px-1'>
                                            <button className='btn ipresens-btn-color' style={{
                                                fontSize: "14px",
                                                fontWeight: 500,
                                                lineHeight: "24px",
                                                width: "100%"
                                            }} onClick={this.restore}>
                                                {i18n.t('ya')}
                                            </button>
                                        </div>
                                    </div>
                                </ModalFooter>
                            </ModalRs>

                            {/*<Breadcrumb parent={i18n.t('manajemenuser')} title={i18n.t('daftaruserlevel')} setGuide={() => { this.setState({ guide: true }) }} />*/}
                            {/*<Tour isOpen={this.state.guide === true} onAfterOpen={this.disableBody} onBeforeClose={this.enableBody} onRequestClose={() => this.setState({ guide: false })} />*/}

                            <div className="container-fluid pt-4">
                                <div className="row">
                                    <div className="col-md-12" style={{
                                        fontWeight: 400, fontSize: '12px', lineHeight: "16px", color: "#5d6f80"
                                    }}>iPresens Admin Panel
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col-md-12" style={{
                                        fontWeight: 700,
                                        fontSize: "24px",
                                        lineHeight: "36px",
                                        color: "#143B5E"
                                    }}>{i18n.t('daftaruserlevel')}
                                    </div>
                                </div>

                                <div className="card">
                                    <div className="card-header border-0 p-4">
                                        <div className="row">
                                            <div className="col-md-6 pengumuman-col-filter">
                                                <div className="flex-row flex-nowrap">
                                                    <div className="input-group btn-srch-user-lvl-setting">
                                                        <label
                                                            style={{fontSize: '10px'}}>{i18n.t('filternamalevel')}:</label><br/>
                                                        <input name="filter_nama"
                                                               className="form-control"
                                                               placeholder={i18n.t('namalevel...')}
                                                               type="text"
                                                               value={this.state.filter_nama}
                                                               onChange={this.handleChange}
                                                               onKeyUp={(event) => {
                                                                   event.preventDefault()
                                                                   if (event.which == 13) {

                                                                       this.setState({
                                                                           page: 0,
                                                                           paging: this.state.paging,
                                                                           isLoading: true
                                                                       }, () => {
                                                                           this.loadData()
                                                                       });
                                                                   }
                                                               }}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6" style={{
                                                display: "flex",
                                                justifyItems: "center",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}>
                                                <div className="input-group btn-refresh-user-lvl-setting">
                                                    <label
                                                        style={{fontSize: '10px'}}> {i18n.t('aturilangfilter')}:</label><br/>
                                                    <button className="btn btn-danger"
                                                            onClick={this.resetFilter}>
                                                        <span className="fa fa-refresh"></span> {i18n.t('aturulang')}
                                                    </button>
                                                </div>
                                                <div className="input-group d-flex" style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "flex-end",
                                                    paddingTop: "20px "
                                                }}>
                                                    <a className="btn ipresens-btn-color tambah-user mx-2"
                                                       style={{float: "right", color: "white"}} onClick={() => {
                                                        this.setState({
                                                            add_visible: true
                                                        })
                                                    }}><i className={"fa fa-plus"}></i> {i18n.t('tambahuserlevel')}</a>
                                                </div>
                                                <div className="input-group d-flex" style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    paddingTop: "20px"
                                                }}>
                                                    <Tooltip rigger={'click'} placement="bottomLeft" color={'#FFF'}
                                                             title={
                                                                 <div className="p-2">
                                                                     <a
                                                                         className={'text-dark'}
                                                                         onClick={() => {
                                                                             this.setState({modalTrash: true});
                                                                             this.loadTrash();
                                                                         }}
                                                                     >{i18n.t("trashleveluser")}</a>
                                                                 </div>
                                                             }>
                                                        <a>
                                                            <i
                                                                style={{
                                                                    width: "15",
                                                                    fontSize: 18,
                                                                    color: 'rgb(51 51 51)',
                                                                    alignItems: "center",
                                                                    display: "flex",
                                                                }}
                                                                className='btn border border-dark border-1 px-2'
                                                            >
                                                                <img src={MoreIcon}/>
                                                            </i>
                                                        </a>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body daftar-shift pt-3">
                                        {!this.state.isLoading && this.state.data.length === 0 ?
                                            <Empty description={i18n.t('datakosong')}
                                                   image={EmptyDataImage}
                                                   className="ipresens-image-empty mb-5"/>
                                            :
                                            <div>
                                                <Table striped className="mb-5 table table-borderless table-striped">
                                                    <Thead>
                                                        <Tr>
                                                            <Th width="30">#</Th>
                                                            <Th>{i18n.t('namalevel')}</Th>
                                                            <Th>{i18n.t('akseshalaman')}</Th>
                                                            <Th>{i18n.t('aksesdivisi')}</Th>
                                                            <Th className={"text-center"}
                                                                width="150">{i18n.t('aksi')}</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {this.state.data.map((item, index) => {
                                                            no += 1
                                                            return (
                                                                <Tr>
                                                                    <Td scope="row">{pageNumber(no, this.state.perPage, this.state.currentPage)}</Td>
                                                                    <Td>
                                                                        {item.level_name}
                                                                    </Td>
                                                                    <Td>
                                                                        {item.level_page_limited ? item.level_page_limited : i18n.t('semuahalaman')}
                                                                    </Td>
                                                                    <Td>
                                                                        {item.level_divisi_limited ? item.level_divisi_limited : i18n.t('semuadivisi')}
                                                                    </Td>
                                                                    <Td align={"center"}>
                                                                        {item.level_default != "1" &&
                                                                        <Tooltip trigger={'click'} placement="bottomRight" color={'#fff'} zIndex={900} title={
                                                                            <div className="p-2">
                                                                                <a className="text-dark"
                                                                                   onClick={(event) => {
                                                                                       event.preventDefault();
                                                                                       if (item.level_default != "1") {
                                                                                           this.setState({
                                                                                               modalHistory: true,
                                                                                               level_id: item.level_id
                                                                                           }, () => {
                                                                                               this.loadHistory(item.level_id);
                                                                                           });
                                                                                       }
                                                                                   }}
                                                                                >{i18n.t('riwayat')}</a><br/>
                                                                                <a className="text-dark"
                                                                                   onClick={() => {
                                                                                       item.level_default != "1" &&
                                                                                       this.setState({
                                                                                           level_id: item.level_id,
                                                                                           level_name: item.level_name,
                                                                                           level_page_limited: item.level_page_limited ? item.level_page_limited.split(",") : null,
                                                                                           level_divisi_limited: item.level_divisi_id ? item.level_divisi_id.split(",").map(function (x) {
                                                                                               return parseInt(x);
                                                                                           }) : null,
                                                                                       }, () => {
                                                                                           this.setState({
                                                                                               visable: true
                                                                                           })
                                                                                       })
                                                                                   }}>{i18n.t('edit')}</a><br/>
                                                                                <a className="text-danger"
                                                                                   onClick={() => {
                                                                                       item.level_default != "1" &&
                                                                                       this.setState({
                                                                                           idDel: item.level_id
                                                                                       }, () => {
                                                                                           this.wantDelete()
                                                                                       })
                                                                                   }}>{i18n.t('hapus')}</a>
                                                                            </div>
                                                                        }>
                                                                            <a><i
                                                                                className="fa fa-ellipsis-v" style={{
                                                                                width: 35,
                                                                                fontSize: 18,
                                                                                color: 'rgb(51 51 51)'
                                                                            }}
                                                                            ></i></a>
                                                                        </Tooltip>}
                                                                    </Td>
                                                                </Tr>
                                                            )
                                                        })}
                                                        {this.state.isLoading &&
                                                            <Tr>
                                                                <Td colSpan="5">
                                                                    <Spin style={{width: '100%', paddingTop: "30px"}}
                                                                          size="large" tip={i18n.t('mohontunggu')}/>
                                                                </Td>
                                                            </Tr>
                                                        }
                                                    </Tbody>
                                                </Table>
                                                <Paging
                                                    className="float-right"
                                                    handlePageClick={this.handlePageClick}
                                                    pageCount={this.state.pageCount}
                                                    currentPage={this.state.currentPage}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Fragment>

                </div>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">Anda tidak punya akses ke halaman ini.</h5>
                </Fragment>
            )
        }

    }
}

const mapStateToProps = state => ({
    api_token: state.Auth.api_token,
    user: state.Auth.user
})

const mapDispatchToProps = dispatch => ({
    set_auth: (api_token, auth_key, data) => dispatch(set_auth(api_token, auth_key, data)),
    clear_auth: () => dispatch(clear_auth())
})

export default connect(mapStateToProps, mapDispatchToProps)(UserLevelPerusahaan);
