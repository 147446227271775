import React, {Component, Fragment} from "react";
import {Helmet} from "react-helmet";
import i18n from "i18next";
import refreshIcon from "../../assets/icons/refresh.svg";
import {clear_auth, set_auth} from "../../reducers/Auth";
import {connect} from "react-redux";
import {Empty, Spin, Tooltip} from "antd";
import EmptyDataImage from "../../assets/images/data-kosong.png";
import {pageNumber} from "../../functions/functionGeneral";
import Swal from "sweetalert2";
import { Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import Breadcrumb from "../common/breadcrumb";
import Tour from "reactour";
import "./style.css";
import ReactPaginate from 'react-paginate';
import IconPlusWhite from "../../assets/icons/IconPlusWhite.svg";
import ArrowLeftIcon from "../../assets/icons/arrow-left.svg";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg";
import HistoryKategoriNilai from "./nilai/history-kategory";
import TrashCategory from "./nilai/trash-category";
import MoreIcon from "../../assets/icons/more.svg";

export class KategoriNilai extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter_nama: '',
            page: 0,
            paging: 5,
            loading: false,
            currentPage: 0,
            data: [],
            totalData: 0,
            pageCount: 0,
            perPage: 0,
            id_delete: 0,
            modal_add: false,
            nl_term_name: "",
            data_detail: [],
            importFile: null,
            modalimport: false,
            history_visible: false,
            category_id: 0,
            trash_visible: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    resetFilter() {
        this.setState({
            filter_nama: "",
            data: [],
            loading: true,
        }, () => this.loadData())
    }

    handlePageClick(data) {
        let selected = data.selected;
        this.setState({ currentPage: selected, loading: true, data: [] }, () => {
            this.loadData()
        });
    }

    loadData() {
        this.setState({loading: true})
        let form = {
            filter_nama: this.state.filter_nama,
            paginate: 10,
            page: this.state.currentPage + 1,
        }
        this.props.request('nilai/list-nilai-term', form, "POST")
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                    })
                }
                this.setState({
                    loading: false
                })
            })
    }

    wantdelete(nl_term_id) {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('andayakininginmenghapuskategorinilai'),
            icon: 'warning',
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal')
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.setState({ data: [], loading: true })
                    let form = {
                        nl_term_id: nl_term_id ? nl_term_id : this.state.id_delete,
                    }
                    this.props.request("nilai/delete-nilai-term", form, "POST")
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: i18n.t('databerhasildihapus'),
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            }
                        })
                }
            })
    }

    handleSubmit = () => {
        this.setState({ loading: true })
        let form = {
            nl_term_name : this.state.nl_term_name,
        }
        this.props.request('nilai/add-nilai-term', form, "POST")
            .then(response => {
                if (response.success) {
                    this.setState({
                        nl_term_name: '',
                        data: [],
                        loading: false,
                        modal_add: false,
                    }, () => this.loadData())
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('databerhasilditambahkan'),
                        icon: "success"
                    })
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t(response.message),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({ loading: false })
            })
    }

    loadDetail () {
        let form = {
            nl_term_id: this.state.nl_term_id,
        }
        this.props.request('nilai/detail-nilai-term', form, "POST")
            .then(response => {
                if (response.success) {
                    this.setState({
                        data_detail: response.data,
                        nl_term_name: response.data.nl_term_name,
                    })
                }
                this.setState({
                    loading: false,
                })
            })
    }

    handleEdit = () => {
        this.setState({ loading: true })
        let form = {
            nl_term_name : this.state.nl_term_name,
            nl_term_id: this.state.nl_term_id,
        }
        this.props.request('nilai/edit-nilai-term', form, "POST")
            .then(response => {
                if (response.success) {
                    this.setState({
                        nl_term_name: '',
                        data: [],
                        loading: false,
                        modal_edit: false,
                    }, () => this.loadData())
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t(response.message),
                        icon: "success"
                    })
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t('gagalmengubahdata'),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({ loading: false })
            })
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        let no = 0;
        let borderBottom = "1px solid #EFEFEF";
        const steps = [
            {
                selector: '.kategori-nilai-siswa',
                content: i18n.t('caramembuatkategorinilai'),
            },
            {
                selector: '.daftar-kategori-nilai',
                content: i18n.t('daftarkategorinilaidisini'),
            },
        ];
        if (!this.props.user.user_level.level_page_limited || this.props.user.user_level.level_page_limited.search("Nilai") >= 0){
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8"/>
                        <title>{i18n.t('daftarkategori')} - Presensi</title>
                        <meta name="description" content="Detail Daftar Kategori Nilai"/>
                    </Helmet>

                    <Breadcrumb title={i18n.t("kategorinilailist")}  parent={i18n.t("nilai")}  setGuide={() => { this.setState({ guide: true }) }} />
                    <Tour steps={steps} isOpen={this.state.guide === true} onAfterOpen={this.disableBody} onBeforeClose={this.enableBody} onRequestClose={() => this.setState({ guide: false })} />
                    <div className="container-fluid">
                        <div className='row'>
                        </div>
                        <div className="col-md-12">
                            <div className='card' style={{border: "1px solid #DDDDDD"}}>
                                <div className="card-header">
                                    <div className="form-group mb-0">
                                        <div className="row filter-karyawan">
                                            <div className="col-md-3 pr-0 input-column-search">
                                                <div className="button-search-istilah">
                                                    <input name="filter_nama"
                                                           className={this.state.filter_nama === "" ? "form-control bg-image-input filter-name input-select-style" : "form-control filter-name input-select-style"}
                                                           placeholder={i18n.t('carinamakategori')}
                                                           type="text"
                                                           style={{
                                                               backgroundColor: "#F9F9F9",
                                                               fontSize: "12px",
                                                               fontWeight: 400,
                                                               paddingTop: "9px",
                                                               paddingBottom: "9px",
                                                               paddingLeft: "35px",
                                                               borderRadius: "8px",
                                                               marginBottom: "0px"
                                                           }}
                                                           required=""
                                                           value={this.state.filter_nama}
                                                           onChange={this.handleChange}
                                                           onKeyUp={(event) => {
                                                               event.preventDefault()
                                                               if (event.which == 13) {

                                                                   this.setState({
                                                                       page: 0,
                                                                       paging: this.state.paging,
                                                                       loading: true
                                                                   }, () => {
                                                                       this.loadData()
                                                                   });
                                                               }
                                                           }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 column-select column-select-istilah">
                                                <div className="button-select-istilah">
                                                    {/* Select Type */}
                                                    <button className="input-select-style button-reset"
                                                            onClick={this.resetFilter}><img src={refreshIcon} alt=""/>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col"></div>
                                            <div className="col-auto col-button column-add-term d-flex flex-row align-items-center" style={{ gap: 5 }}>
                                                {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("C") >= 0) && (
                                                    <a className="btn ipresens-btn-color tambah-term button-add-istilah mt-0 kategori-nilai-siswa"
                                                       style={{lineHeight: "24px"}}
                                                       onClick={() => {
                                                           this.setState({
                                                               modal_add: true,
                                                               nl_term_name: ""
                                                           })
                                                       }}>
                                                        <img src={IconPlusWhite} alt="" className="mr-2 mb-1"/> {i18n.t('tambah')}
                                                    </a>
                                                )}
                                                <Tooltip rigger={'click'} placement="bottomLeft" color={'#FFF'} title={
                                                    <div className={'p-2'}>
                                                        <a className="text-dark" onClick={() => this.setState({
                                                            trash_visible: true
                                                        })}>{i18n.t('sampahkategorinilai')}</a>
                                                    </div>
                                                }>
                                                    <i
                                                        style={{
                                                            width: "15",
                                                            fontSize: 18,
                                                            color: 'rgb(51 51 51)',
                                                            alignItems: "center",
                                                            height: "41px",
                                                            paddingRight: "30px"
                                                        }}
                                                        className='btn border border-dark border-1 px-2'
                                                    ><img src={MoreIcon}/></i>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.state.loading ?
                                    <div className="card-body">
                                        <Spin size="large" tip={i18n.t('sedangmemuatdata')} style={{
                                            width: '100%',
                                            margin: 'auto',
                                            marginBottom: '10px',
                                            marginTop: '10px'
                                        }}/>
                                    </div>
                                    :
                                    <div className="card-body">
                                        {!this.state.loading && this.state.data.length === 0 ?
                                            <Empty description={i18n.t("datakosong")}
                                                   image={EmptyDataImage} className="ipresens-image-empty "/>
                                            :
                                            <div className="row">
                                                <div className="col-md-12 daftar-kategori-nilai">
                                                    <div className="table-responsive">
                                                        <table className="table table-striped table-borderless mb-5">
                                                            <thead>
                                                            <tr>
                                                                <th width="30" className="nilai-th-style">#</th>
                                                                <th width="100" className="nilai-th-style">{i18n.t("nama")}</th>
                                                                <th width="100" className="nilai-th-style text-center">{i18n.t("aksi")}</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody className="">
                                                            {this.state.loading &&
                                                                <tr>
                                                                    <td colSpan={9}>
                                                                        <Spin size="large" tip="Loading..." style={{
                                                                            width: '100%',
                                                                            margin: 'auto',
                                                                            marginBottom: '10px',
                                                                            marginTop: '10px'
                                                                        }}/>
                                                                    </td>
                                                                </tr>
                                                            }
                                                            {this.state.data.map((item, index) => {
                                                                no += 1
                                                                return (
                                                                    <tr style={{ borderBottom: no === this.state.data.length ? "" : borderBottom }}>
                                                                        <td className="nilai-td-style" scope="row">{pageNumber(no, this.state.perPage, this.state.currentPage)}</td>
                                                                        <td className="nilai-td-style" style={{minWidth: "350px"}}>
                                                                            {item.nl_term_name}
                                                                        </td>
                                                                        <td align={"center"}>
                                                                            <Tooltip trigger={'click'} zIndex={1}
                                                                                     placement="bottom"
                                                                                     color={'#fff'} title={
                                                                                <div className={'text-dark'}>
                                                                                    <a onClick={() => {
                                                                                        this.setState({
                                                                                            history_visible: true,
                                                                                            category_id: item.nl_term_id
                                                                                        })
                                                                                    }}>{i18n.t("riwayat")}</a><br/>
                                                                                    <a className={'text-dark'}
                                                                                       onClick={() => {
                                                                                           this.setState({
                                                                                               nl_term_id: item.nl_term_id,
                                                                                               nl_term_name: item.nl_term_name,
                                                                                               modal_edit: true,
                                                                                           })
                                                                                       }}>{i18n.t("edit")}</a><br/>
                                                                                    <a className={'text-danger'}
                                                                                       onClick={() => {
                                                                                           this.setState({
                                                                                               id_delete: item.nl_term_id
                                                                                           })
                                                                                           this.wantdelete(item.nl_term_id)
                                                                                       }}>{i18n.t("hapus")}</a>
                                                                                </div>
                                                                            }>
                                                                                <a><i
                                                                                    className="fa fa-ellipsis-v"
                                                                                    style={{
                                                                                        width: 35,
                                                                                        fontSize: 18,
                                                                                        padding: 11,
                                                                                        color: 'rgb(51 51 51)'
                                                                                    }}
                                                                                ></i></a>
                                                                            </Tooltip>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                                <div className='card-footer p-0 border-0'>
                                    <div className='row mx-0' style={{backgroundColor: "#F5F5F5", width: "100%"}}>
                                        {this.state.data.length !== 0 &&
                                            <>
                                                <div className='col font-14' style={{
                                                    padding: "auto",
                                                    margin: "auto",
                                                    fontWeight: 500,
                                                    lineHeight: "24px"
                                                }}>{i18n.t("menampilkan")} {this.state.data.length === 0 || this.state.data.length === null ? "0" : this.state.data.length} {i18n.t("dari")} {this.state.totalData} data
                                                </div>
                                                <div className='col'>
                                                    <ReactPaginate
                                                        previousLabel={<img src={ArrowLeftIcon} alt=''/>}
                                                        nextLabel={<img src={ArrowRightIcon} alt=''/>}
                                                        pageCount={this.state.pageCount}
                                                        forcePage={this.state.currentPage}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={3}
                                                        onPageChange={this.handlePageClick}
                                                        breakClassName={'page-item'}
                                                        breakLinkClassName={'page-link custom-page-link'}
                                                        containerClassName={'pagination my-2'}
                                                        pageClassName={'page-item'}
                                                        pageLinkClassName={'page-link custom-page-link'}
                                                        previousClassName={'page-item'}
                                                        previousLinkClassName={'page-link lembur-prev-next-link'}
                                                        nextClassName={'page-item'}
                                                        nextLinkClassName={'page-link lembur-prev-next-link'}
                                                        activeClassName={'active lembur-active'}
                                                    />
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <HistoryKategoriNilai
                        history_visible={this.state.history_visible}
                        category_id={this.state.category_id}
                        request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                        changeState={(state, callback) => {
                            this.setState(state, () => {
                                if (typeof callback != 'undefined')
                                    callback()
                            })
                        }}
                        loadData={() => this.loadData()}

                    />

                    <TrashCategory
                        trash_visible={this.state.trash_visible}
                        request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                        changeState={(state, callback) => {
                            this.setState(state, () => {
                                if (typeof callback != 'undefined')
                                    callback()
                            })
                        }}
                        loadData={() => this.loadData()}
                    />

                    <Modal size="lg" style={{maxWidth: "600px"}} isOpen={this.state.modal_add}
                           toggle={() => this.setState({modal_add: false})}>
                        <ModalHeader>
                            <div className="">{i18n.t('tambahkategorinilai')}</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="">
                                <label htmlFor="">{i18n.t('namakategorinilai')}</label>
                                <input type="text" className="form-control" placeholder={i18n.t('nama')} name="nl_term_name"
                                       onChange={this.handleChange} value={this.state.nl_term_name}/>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <div
                                style={{
                                    textAlign: 'right',
                                }}
                            >
                                <button type="button" className="btn ipresens-btn-cancel" onClick={() => {
                                    this.setState({modal_add: false, nl_term_name: ""})
                                }}
                                        style={{marginRight: 8}}>
                                    {i18n.t('batal')}
                                </button>
                                <button type="button" className="btn ipresens-btn-color" onClick={this.handleSubmit}
                                        disabled={this.state.loading}>
                                    {this.state.loading ?
                                        <><i className={"fa fa-refresh fa-spin"}></i> {i18n.t('loading')}</>
                                        :
                                        i18n.t('simpan')
                                    }
                                </button>
                            </div>
                        </ModalFooter>
                    </Modal>

                    <Modal size="lg" style={{maxWidth: "600px"}} isOpen={this.state.modal_edit}
                           toggle={() => this.setState({modal_edit: false})}>
                        <ModalHeader>
                            <div className="">{i18n.t('editkategorinilai')}</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="">
                                <label htmlFor="">{i18n.t('namakategorinilai')}</label>
                                <input type="text" className="form-control" placeholder={i18n.t('nama')} name="nl_term_name"
                                       onChange={this.handleChange} value={this.state.nl_term_name}/>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <div
                                style={{
                                    textAlign: 'right',
                                }}
                            >
                                <button type="button" className="btn ipresens-btn-cancel" onClick={() => {
                                    this.setState({ modal_edit:false })
                                }}
                                        style={{marginRight: 8}}>
                                    {i18n.t('batal')}
                                </button>
                                <button type="button" className="btn ipresens-btn-color" onClick={this.handleEdit}
                                        disabled={this.state.loading}>
                                    {this.state.loading ?
                                        <><i className={"fa fa-refresh fa-spin"}></i> {i18n.t('loading')}</>
                                        :
                                        i18n.t('simpan')
                                    }
                                </button>
                            </div>
                        </ModalFooter>
                    </Modal>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">{i18n.t('tidakadaakseshalaman')}</h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(KategoriNilai);
