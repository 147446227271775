import React,{Component} from "react";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import i18n from "i18next";
import Select from "react-select";
import SaveIcon from "../../assets/icons/save.svg";
import Swal from "sweetalert2";

class EditMataPelajaran extends Component {
    constructor(props) {
        super(props);
        this.state = {
            guru: [],
            guru_selected: [],
            guru_loading: false,
            guru_id: 0,
            mapel_id: 0,
            mapel_nama: '',
            mapel_kkm: '',
            mapel_nilai_start: '',
            mapel_nilai_end: '',
            prosesSimpan: false,
        }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        const { edit_visible, mapel_id } = this.props;

        if (edit_visible && (!prevProps.edit_visible || mapel_id !== prevProps.mapel_id)) {
            this.setState({ loading_detail: true, mapel_id: mapel_id,  }, () => this.loadDetail(mapel_id));
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    searchGuru() {
        let form = {}
        this.props.request('guru/search', form, 'POST')
            .then((response) => {
                if (response.success) {
                    this.setState({
                        guru: response.data,
                        guru_loading: false
                    })
                }
            })
    }

    loadDetail(mapel) {
        let form = {
            mapel_id: mapel || this.state.mapel_id
        }
        this.props.request('mapel/load-detail', form, 'POST')
            .then((response) => {
                if (response.success) {
                    this.setState({
                        mapel_nama: response.data.mapel_nama,
                        mapel_kkm: response.data.mapel_kkm,
                        mapel_nilai_start: response.data.mapel_nilai_start,
                        mapel_nilai_end: response.data.mapel_nilai_end,
                        guru_selected: {
                            value: response.data?.guru?.guru_id,
                            label: response.data?.guru?.guru_nama,
                        }
                    })
                }
            })
    }

    handleEdit = () => {
        this.setState({ prosesSimpan: true})
        let form = {
            mapel_id   : this.state.mapel_id,
            mapel_nama  : this.state.mapel_nama,
            mapel_kkm  : this.state.mapel_kkm,
            mapel_nilai_start  : this.state.mapel_nilai_start,
            mapel_nilai_end : this.state.mapel_nilai_end,
            guru_id  : this.state.guru_id || this.state.guru_selected.value
        }
        this.props.request("mapel/edit", form, 'POST')
            .then((response) => {
                if (response.success) {
                    this.props.changeState({ edit_visible: false, data:[], loading: true }, () => this.props.loadData());
                    Swal.fire({
                        title: i18n.t("berhasil"),
                        text: i18n.t('berhasileditmapel'),
                        icon: "success",
                    })
                } else {
                    Swal.fire({
                        title: i18n.t("gagal"),
                        text: response.message,
                        icon: "error",
                    })
                }
                this.setState({ prosesSimpan: false, mapel_id: 0, mapel_nama: '', mapel_kkm: '', mapel_nilai_start: '', mapel_nilai_end: '', guru_id: 0, })
            })
    }

    render() {
        return (
            <Modal isOpen={this.props.edit_visible} toggle={() => this.props.changeState({ edit_visible: false })} style={{ maxWidth: "1000px"}} zIndex={999}>
                <ModalHeader className="modal-header-ipresens" toggle={() => this.props.changeState({ edit_visible: false })}>
                    <div className='modal-header-ipresens'>{i18n.t("editmapel")}</div>
                </ModalHeader>
                <ModalBody>
                    <div>
                        <div className="py-1">
                            <label className="mb-2 pengumuman-label-input" style={{ color: "#38115F" }}>{i18n.t("namamapel")}</label>
                            <input className="form-control pengumuman-input" type="text" name="mapel_nama"
                                   value={this.state.mapel_nama} placeholder={i18n.t('ketikkannamamapel')}
                                   onChange={this.handleChange}/>
                        </div>
                        <div className="py-1">
                            <label htmlFor="" className="mb-2 pengumuman-label-input" style={{ color: "#38115F" }}>{i18n.t('guru')}</label>
                            <Select
                                className="font-edit-value"
                                placeholder={i18n.t("namaguru")}
                                backspaceRemoves={true}
                                deleteRemoves={true}
                                components={{
                                    DropdownIndicator: () => null,
                                    IndicatorSeparator: () => null
                                }}
                                value={this.state.guru_selected}
                                isLoading={this.state.guru_loading}
                                onFocus={() => this.setState({guru_loading: true}, () => this.searchGuru())}
                                onBlur={() => this.setState({guru_loading: false})}
                                onInputChange={(val) => this.setState({guru_name: val}, () => this.setState({guru_loading: true}, () => this.searchGuru()))}
                                options={
                                    this.state.guru != null ? (
                                            this.state.guru.map((item, index) =>
                                                (
                                                    {
                                                        key: index,
                                                        value: item.guru_id,
                                                        label: item.guru_nama,
                                                    }
                                                )
                                            ))
                                        : ("")
                                }
                                onChange={
                                    (guru_selected) => {
                                        if (!guru_selected) {
                                            this.setState({
                                                guru_selected: '',
                                                guru_id: 0,
                                            })
                                        } else {
                                            this.setState({
                                                guru_selected,
                                                guru_id: guru_selected.value,
                                                guru: []
                                            })
                                        }
                                    }
                                }
                                isClearable={() => {
                                    this.setState({
                                        isClearable: true,
                                    })
                                }}
                            />
                        </div>
                        <div className="row" style={{ marginTop: 8 }}>
                            <div className="form-group col">
                                <label className="mb-2 pengumuman-label-input" style={{ color: "#38115F" }}>{i18n.t("mapelkkm")}</label>
                                <input className="form-control pengumuman-input" type="number"
                                       name="mapel_kkm"
                                       value={this.state.mapel_kkm} placeholder={i18n.t('masukkannilaikkm')}
                                       onChange={this.handleChange}/>
                            </div>
                            <div className="form-group col">
                                <label className="mb-2 pengumuman-label-input" style={{ color: "#38115F" }}>{i18n.t("nilaiawal")}</label>
                                <input className="form-control pengumuman-input" type="number"
                                       name="mapel_nilai_start"
                                       value={this.state.mapel_nilai_start}
                                       placeholder={i18n.t('masukkannilaiawal')}
                                       onChange={this.handleChange}/>
                            </div>
                            <div className="form-group col">
                                <label
                                    className="mb-2 pengumuman-label-input" style={{ color: "#38115F" }}>{i18n.t("nilaiakhir")}</label>
                                <input className="form-control pengumuman-input" type="number"
                                       name="mapel_nilai_end"
                                       value={this.state.mapel_nilai_end}
                                       placeholder={i18n.t('masukkannilaiakhir')}
                                       onChange={this.handleChange}/>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className="inventaris-modal-footer">
                    <button onClick={() => this.props.changeState({edit_visible: false})}
                            className='ipresens-cancel-button'>{i18n.t("batal")}</button>
                    <button className='ipresens-save-button' disabled={this.state.prosesSimpan}
                            onClick={this.handleEdit}><img alt=''
                                                          src={SaveIcon}/> {this.state.prosesSimpan ? i18n.t("menyimpan") : i18n.t("simpan")}
                    </button>
                </ModalFooter>
            </Modal>
        )
    }
}
export default EditMataPelajaran;