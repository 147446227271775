import React, { Component, Fragment } from 'react';
import i18n from "i18next";
import {Helmet} from "react-helmet";
import Breadcrumb from "../../common/breadcrumb";
import {clear_auth, set_auth} from "../../../reducers/Auth";
import {connect} from "react-redux";
import SearchIcon from "../../../assets/icons/search.svg";
import RefreshIcon from "../../../assets/icons/refresh.svg";
import {Empty, Spin, Tooltip} from "antd";
import EmptyDataImage from "../../../assets/images/data-kosong.png";
import {pageNumber} from "../../../functions/functionGeneral";
import Paging from "../../paging";
import PlusIcon from "../../../assets/icons/plus-white.svg";
import Add from "./add";
import Edit from "./edit";
import Swal from "sweetalert2";

class Ip_Whitelist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data : [],
            currentPage : 0,
        }
        this.handlePageClick = this.handlePageClick.bind(this)
    }

    loadData() {
        this.setState({loading: true})
        let form = {
            filter_ip   : this.state.filter_ip,
            paginate    : 10,
            page        : this.state.currentPage + 1,
        }
        this.props.request("whitelist/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        loading: false,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                    })
                }
            });
    }

    handleDelete() {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('andayakinhapus'),
            icon: 'warning',
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        whitelist_id    : this.state.whitelist_id
                    }
                    this.props.request("whitelist/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: i18n.t(response.message),
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    handlePageClick(data) {
        let selected = data.selected
        this.setState({ currentPage: selected }, () => {
            this.loadData()
        });
    }

    handleInputIP = (e) => {
        let name = e.target.name;
        let value = e.target.value.replace(/[^0-9.x]/g, "")
        let parts = value.split(".")

        parts = parts.map((part) => part.slice(0, 3)).slice(0, 4)
        value = parts.join(".")

        this.setState({[name] : value})
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        let no = 0;
        if (!this.props.user.user_level.level_page_limited || this.props.user.user_level.level_page_limited.search("Pengaturan") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{i18n.t("daftaripwhitelist")} - iPresens</title>
                        <meta name="description" content={i18n.t("daftaripwhitelist")} />
                    </Helmet>
                    <Breadcrumb parent="Settings" title={i18n.t("daftaripwhitelist")}/>
                    <Add
                        handleInputIP={this.handleInputIP}
                        modal_add={this.state.modal_add}
                        ip_start={this.state.ip_start}
                        ip_end={this.state.ip_end}
                        request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                        changeState={(state, callback) => {
                            this.setState(state, () => {
                                if (typeof callback != 'undefined')
                                    callback()
                            })
                        }}
                        loadData={() => this.loadData()}
                    />
                    <Edit
                        handleInputIP={this.handleInputIP}
                        modal_edit={this.state.modal_edit}
                        isRange={this.state.isRange}
                        data={this.state}
                        request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                        changeState={(state, callback) => {
                            this.setState(state, () => {
                                if (typeof callback != 'undefined')
                                    callback()
                            })
                        }}
                        loadData={() => this.loadData()}
                    />
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-5 d-flex">
                                        <div className='input-group w-100 mb-3 mr-2'>
                                                <span className='input-group-text izin-search-karyawan-img'>
                                                    <img alt='' src={SearchIcon}/>
                                                </span>
                                            <input type='text' onChange={this.handleInputIP}
                                                   name='filter_ip' value={this.state.filter_ip}
                                                   className='form-control izin-search-karyawan-form'
                                                   style={{padding: "12px 10px",}}
                                                   placeholder={i18n.t("filterip") + " (ex : 192.168.1.x)"}
                                                   onKeyDown={(e) => {
                                                       if (e.key === "Enter") {
                                                           this.loadData()
                                                       }
                                                   }}>
                                            </input>
                                        </div>
                                        <div className="">
                                            <button className='btn border px-2'>
                                                <img alt="" src={RefreshIcon} onClick={() => {
                                                    this.setState({
                                                        filter_ip: ""
                                                    }, () => this.loadData())
                                                }}/>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-md-7 justify-content-end">
                                        <button
                                            className="btn ipresens-btn-color text-capitalize width-filter pull-right"
                                            onClick={() => {
                                                this.setState({modal_add: true});
                                            }}
                                        >
                                            <img src={PlusIcon} alt=""/> {i18n.t('tambahip')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="table-responsive">
                                            {!this.state.loading && this.state.data.length === 0 ?
                                                <Empty description="Data Kosong"
                                                       image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                                :
                                                <div>
                                                    <table className="table mb-5 table-striped table-borderless">
                                                        <thead>
                                                        <tr>
                                                            <th className='lembur-th-style text-center' width="50">#</th>
                                                            <th className='lembur-th-style'>{i18n.t("ipaddress")}</th>
                                                            <th className='lembur-th-style'>{i18n.t("tanggaldibuat")}</th>
                                                            <th className='lembur-th-style'>{i18n.t("tanggalupdate")}</th>
                                                            <th className='lembur-th-style text-center'>{i18n.t("aksi")}</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {this.state.loading ?
                                                            <tr>
                                                                <td colSpan="9" style={{
                                                                    textAlign: "center",
                                                                    backgroundColor: "#FFFFFF"
                                                                }}>
                                                                    <Spin size="large"
                                                                          tip={i18n.t("sedangmemuatdata") + ' ...'}
                                                                          style={{
                                                                              width: '100%',
                                                                              margin: 'auto',
                                                                              marginBottom: '10px',
                                                                              marginTop: '10px'
                                                                              }}/>
                                                                    </td>
                                                                </tr>
                                                                :
                                                                this.state.data.map((item, index) => {
                                                                    no += 1
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td className="lembur-td-style text-center">{pageNumber(no, this.state.perPage, this.state.currentPage)}</td>
                                                                            <td className="lembur-td-style">{item.whitelist_allowed_ip}</td>
                                                                            <td className="lembur-td-style">{item.created_at}</td>
                                                                            <td className="lembur-td-style">{item.updated_at}</td>
                                                                            <td className="lembur-td-style text-center">
                                                                                <Tooltip trigger={'click'} zIndex={1} placement="bottom" color={'#fff'} title={
                                                                                    <div className={'text-dark'}>
                                                                                        <a onClick={() => {
                                                                                            const ip = item.whitelist_allowed_ip;
                                                                                            if (ip.includes(" - ")) {
                                                                                                const [start, end] = ip.split(" - ").map((ip) => ip.trim());
                                                                                                this.setState({ ip_start: start, ip_end: end, isRange: true });
                                                                                            } else {
                                                                                                this.setState({ ip_start: ip, ip_end: null, isRange: false });
                                                                                            }
                                                                                            this.setState({
                                                                                            whitelist_id: item.whitelist_id,
                                                                                            modal_edit: true
                                                                                        })}}>{i18n.t("edit")}</a><br/>
                                                                                        <a onClick={() => {
                                                                                            this.setState({
                                                                                                whitelist_id: item.whitelist_id
                                                                                            })
                                                                                            this.handleDelete()
                                                                                        }}
                                                                                           className={'text-danger'}>{i18n.t("hapus")}</a>
                                                                                    </div>
                                                                                }>
                                                                                    <a><i
                                                                                        className="fa fa-ellipsis-v"
                                                                                        style={{
                                                                                            width: 35,
                                                                                            fontSize: 18,
                                                                                            padding: 11,
                                                                                            color: 'rgb(51 51 51)'
                                                                                        }}
                                                                                    ></i></a>
                                                                                </Tooltip>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            }
                                        </div>
                                        <div className="row">
                                            <div className='col font-14 fw-500 m-auto'>
                                                {i18n.t("menampilkan")} {this.state.data.length === 0 || this.state.data.length === null ? "0" : this.state.data.length} {i18n.t("dari")} {this.state.totalData} {i18n.t("data")}.
                                            </div>
                                            <div className="col">
                                                <Paging
                                                    className="float-right"
                                                    handlePageClick={this.handlePageClick}
                                                    pageCount={this.state.pageCount}
                                                    currentPage={this.state.currentPage}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">{i18n.t("tidakadaakseshalaman")}</h5>
                </Fragment>
            )
        }

    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Ip_Whitelist);