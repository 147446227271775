import React, { Component, Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb';
import Swal from "sweetalert2";
import { Select, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import i18n from "i18next";
import { clear_auth, set_auth } from "../../reducers/Auth";
import UploadIcon from '../../assets/icons/import-icon-blue-light.svg'

export class Add_karyawan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prosesSimpan: false,
            isLoading: true,
            divisi: [],
            jabatan: [],
            lokasi: [],
            bpjs_data: [],
            fileList: [],
            fileKtp: [],
            fileSelfie: [],
            fileIjazah: [],
            perusahaan_karyawan: '',
            foto_karyawan: '',
            foto_ktp: '',
            foto_selfie: '',
            foto_ijazah: '',
            nama_karyawan: '',
            no_induk_karyawan: '',
            gender_karyawan: 'Laki-laki',
            divisi_karyawan: '',
            jabatan_karyawan: '',
            lokasi_karyawan: null,
            email_karyawan: '',
            ttl_karyawan: '',
            join_date_karyawan: '',
            no_hp_karyawan: '',
            alamat_karyawan: '',
            no_npwp: '',
            no_bpjs: '',
            karyawan_bpjs: '',
            no_hp_ortu: '',
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    loadJabatan() {
        let form = {}
        this.props.request("get_jabatan_perusahaan", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        isLoading: false,
                        jabatan: response.data
                    })
                }
            });
    }

    loadBPJSSetting() {
        let form = {}
        this.props.request("bpjs/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        isLoading: false,
                        bpjs_data: response.data
                    })
                }
            });
    }

    loadDivisi() {
        let form = {}
        this.props.request("get_divisi_perusahaan", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        isLoading: false,
                        divisi: response.data
                    })
                }
            });
    }

    loadLokasi() {
        let form = {}
        this.props.request("get_lokasi_perusahaan", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        lokasi: response.data,
                        lokasi_karyawan: null,
                    })
                }
            });
    }

    lokasiHandler = () => {
        if (this.state.lokasi_karyawan === null) {
            this.setState({
                lokasi_karyawan: ''
            })
        } else {
            this.setState({
                lokasi_karyawan: null
            })
        }
    }

    simpanKaryawan = () => {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            lokasi_karyawan: this.state.lokasi_karyawan,
            foto_karyawan: this.state.foto_karyawan,
            foto_selfie: this.state.foto_selfie,
            foto_ijazah: this.state.foto_ijazah,
            foto_ktp: this.state.foto_ktp,
            nama_karyawan: this.state.nama_karyawan,
            no_induk_karyawan: this.state.no_induk_karyawan,
            gender_karyawan: this.state.gender_karyawan,
            email_karyawan: this.state.email_karyawan,
            jabatan_karyawan: this.state.jabatan_karyawan,
            divisi_karyawan: this.state.divisi_karyawan,
            ttl_karyawan: this.state.ttl_karyawan,
            join_date_karyawan: this.state.join_date_karyawan,
            no_hp_karyawan: this.state.no_hp_karyawan,
            alamat_karyawan: this.state.alamat_karyawan,
            no_npwp: this.state.no_npwp,
            no_bpjs: this.state.no_bpjs,
            karyawan_bpjs: this.state.karyawan_bpjs,
            no_hp_ortu: this.state.no_hp_ortu,
            tipe_instansi: this.props.user.perusahaan.perusahaan_type,
        }

        this.props.request("karyawan/add_karyawan", form, 'POST')
            .then(response => {
                const isSekolah = this.props.user.perusahaan.perusahaan_type === "sekolah";
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: isSekolah ? i18n.t('berhasil-menambahkan-siswa') : i18n.t('berhasil-menambahkan-karyawan'),
                        icon: 'success'
                    })
                    this.props.history.push('/employee/employee-list/0')
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t(response.failed),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    prosesSimpan: false,
                })
            });
    }

    cekMasterData() {
        const { perusahaan } = this.props.user;
        const isSekolah = perusahaan.perusahaan_type === "sekolah";

        const hasMissingData = isSekolah
            ? perusahaan.divisi === null || perusahaan.lokasi.length < 1
            : perusahaan.divisi === null || perusahaan.jabatan.length < 1 || perusahaan.lokasi.length < 1;

        if (hasMissingData) {
            Swal.fire({
                title: 'Peringatan!',
                text: 'Anda harus menambahkan Seluruh Master Data terlebih dahulu',
                icon: 'warning',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.props.history.push('/employee/shift');
                }
            });
        }
    }

    componentDidMount() {
        this.cekMasterData()
        this.loadBPJSSetting()
    }

    render() {
        const { Option } = Select;
        const isSekolah = this.props.user.perusahaan.perusahaan_type === "sekolah";
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{isSekolah ? i18n.t("tambahsiswa") : i18n.t("tambahkaryawan")} - iPresens</title>
                    <meta name="description" content={i18n.t("tambahkaryawan")} />
                </Helmet>
                <Breadcrumb title={isSekolah ? i18n.t("tambahsiswa") : i18n.t("tambahkaryawan")} parent={isSekolah ? i18n.t("siswa") : i18n.t("karyawan")} />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <form className="needs-validation user-add" noValidate="">
                                        <div className='row mb-2'>
                                            <div className='col-md-6'>
                                                <h4>{isSekolah ? i18n.t("detailsiswa") : i18n.t("detailkaryawan")}</h4>
                                            </div>
                                            <div className='col-md-6 d-flex justify-content-end'>
                                                <a className='btn ipresens-btn-cancel-add mr-2 text-capitalize' href="/employee/employee-list/0">{i18n.t('batal')}</a>
                                                {this.state.prosesSimpan === false ?
                                                    <button type="button" className="btn ipresens-btn-color text-capitalize"
                                                            onClick={this.simpanKaryawan}><i className="fa fa-floppy-o"></i> {i18n.t('simpan')}</button>
                                                    :
                                                    <button type="button" className="btn ipresens-btn-color text-capitalize"><i className="fa fa-refresh fa-spin"></i> {i18n.t('loading')}</button>
                                                }
                                            </div>
                                        </div>
                                        <div className="form-group row col-md-12">
                                            <div className="col-lg-3 col-md-6">
                                                <ImgCrop
                                                    rotate
                                                    width={750}
                                                    height={1000}>
                                                    <Upload action={"https://www.mocky.io/v2/5cc8019d300000980a055e76"}
                                                        listType="picture-card"
                                                        fileList={this.state.fileList}
                                                        className="antd-upload-custom"
                                                        onRemove={() => {
                                                            this.setState({ foto_karyawan: null })
                                                        }}
                                                        onChange={(file) => {
                                                            this.setState({ fileList: file.fileList })

                                                            if (file.fileList.length > 0) {
                                                                let files = file.file.originFileObj
                                                                let reader = new FileReader()

                                                                reader.readAsDataURL(files)
                                                                reader.onloadend = () => {
                                                                    this.setState({
                                                                        foto_karyawan: {
                                                                            name: file.file.name,
                                                                            file: reader.result
                                                                        }
                                                                    })
                                                                }
                                                            }
                                                            else {
                                                                this.setState({ foto_karyawan: [] })
                                                            }
                                                        }}
                                                    >
                                                        {this.state.fileList.length >= 1 ? null :
                                                            <div className="p-2 d-flex align-items-center justify-content-center flex-column" style={{height: "167px"}}>
                                                                <span>
                                                                    <img src={UploadIcon} alt="" style={{height: "24px", width: "24px"}} />
                                                                </span>
                                                                <p className="ant-upload-text mb-3" style={{fontSize: "12px", fontWeight: 400, lineHeight: "16px", marginTop: "6px"}}>{isSekolah ? i18n.t("uploadfotosiswa") : i18n.t("uploadfotokaryawan")}</p>
                                                                <a className="btn btn-outline-custom tambah-karyawan">{i18n.t('carigambar')}</a>
                                                            </div>
                                                        }
                                                    </Upload>
                                                </ImgCrop>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <ImgCrop
                                                    rotate
                                                    width={550}
                                                    height={860}>
                                                    <Upload action={"https://www.mocky.io/v2/5cc8019d300000980a055e76"}
                                                        listType="picture-card"
                                                        fileList={this.state.fileSelfie}
                                                        className="antd-upload-custom"
                                                        onRemove={() => {
                                                            this.setState({ foto_selfie: null })
                                                        }}
                                                        onChange={(file) => {
                                                            this.setState({ fileSelfie: file.fileList })

                                                            if (file.fileList.length > 0) {
                                                                let files = file.file.originFileObj
                                                                let reader = new FileReader()

                                                                reader.readAsDataURL(files)
                                                                reader.onloadend = () => {
                                                                    this.setState({
                                                                        foto_selfie: {
                                                                            name: file.file.name,
                                                                            file: reader.result
                                                                        }
                                                                    })
                                                                }
                                                            }
                                                            else {
                                                                this.setState({ foto_selfie: [] })
                                                            }
                                                        }}
                                                    >
                                                        {this.state.fileSelfie.length >= 1 ? null :
                                                            <div className="p-2 d-flex align-items-center justify-content-center flex-column" style={{height: "167px"}}>
                                                                <span>
                                                                    <img src={UploadIcon} style={{height: "24px", width: "24px"}} />
                                                                </span>
                                                                <p className="ant-upload-text mb-3" style={{fontSize: "12px", fontWeight: 400, lineHeight: "16px", marginTop: "6px"}}>{isSekolah ? i18n.t("uploadfotoselfisiswa") : i18n.t("uploadfotoselfikaryawan")}</p>
                                                                <a className="btn btn-outline-custom tambah-karyawan">{i18n.t('carigambar')}</a>
                                                            </div>
                                                        }
                                                    </Upload>
                                                </ImgCrop>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <ImgCrop
                                                    rotate
                                                    width={960}
                                                    height={480}>
                                                    <Upload action={"https://www.mocky.io/v2/5cc8019d300000980a055e76"}
                                                        listType="picture-card"
                                                        fileList={this.state.fileKtp}
                                                        className="antd-upload-custom"
                                                        onRemove={() => {
                                                            this.setState({ foto_ktp: null })
                                                        }}
                                                        onChange={(file) => {
                                                            this.setState({ fileKtp: file.fileList })

                                                            if (file.fileList.length > 0) {
                                                                let files = file.file.originFileObj
                                                                let reader = new FileReader()

                                                                reader.readAsDataURL(files)
                                                                reader.onloadend = () => {
                                                                    this.setState({
                                                                        foto_ktp: {
                                                                            name: file.file.name,
                                                                            file: reader.result
                                                                        }
                                                                    })
                                                                }
                                                            }
                                                            else {
                                                                this.setState({ foto_ktp: [] })
                                                            }
                                                        }}>
                                                        {this.state.fileKtp.length >= 1 ? null :
                                                            <div className="p-2 d-flex align-items-center justify-content-center flex-column" style={{height: "167px"}}>
                                                                <span>
                                                                    <img src={UploadIcon} style={{height: "24px", width: "24px"}} />
                                                                </span>
                                                                <p className="ant-upload-text mb-3" style={{fontSize: "12px", fontWeight: 400, lineHeight: "16px", marginTop: "6px"}}>{isSekolah ? i18n.t("uploadfotoktpsiswa") : i18n.t("uploadfotoktpkaryawan")}</p>
                                                                <a className="btn btn-outline-custom tambah-karyawan">{i18n.t('carigambar')}</a>
                                                            </div>
                                                        }
                                                    </Upload>
                                                </ImgCrop>
                                            </div>
                                            {!isSekolah && (
                                                <div className="col-lg-3 col-md-6">
                                                    <ImgCrop
                                                        rotate
                                                        width={550}
                                                        height={860}>
                                                        <Upload
                                                            action={"https://www.mocky.io/v2/5cc8019d300000980a055e76"}
                                                            listType="picture-card"
                                                            fileList={this.state.fileIjazah}
                                                            className="antd-upload-custom"
                                                            onRemove={() => {
                                                                this.setState({foto_ijazah: null})
                                                            }}
                                                            onChange={(file) => {
                                                                this.setState({fileIjazah: file.fileList})

                                                                if (file.fileList.length > 0) {
                                                                    let files = file.file.originFileObj
                                                                    let reader = new FileReader()

                                                                    reader.readAsDataURL(files)
                                                                    reader.onloadend = () => {
                                                                        this.setState({
                                                                            foto_ijazah: {
                                                                                name: file.file.name,
                                                                                file: reader.result
                                                                            }
                                                                        })
                                                                    }
                                                                } else {
                                                                    this.setState({foto_ijazah: []})
                                                                }
                                                            }}
                                                        >
                                                            {this.state.fileIjazah.length >= 1 ? null :
                                                                <div className="p-2 d-flex align-items-center justify-content-center flex-column" style={{height: "167px"}}>
                                                                    <span>
                                                                        <img src={UploadIcon}
                                                                             style={{height: "24px", width: "24px"}}/>
                                                                        </span>
                                                                    <p className="ant-upload-text mb-3" style={{
                                                                        fontSize: "12px",
                                                                        fontWeight: 400,
                                                                        lineHeight: "16px",
                                                                        marginTop: "6px"
                                                                    }}>{i18n.t('uploadfotoisajasahkaryawan')}</p>
                                                                    <a className="btn btn-outline-custom tambah-karyawan">{i18n.t('carigambar')}</a>
                                                                </div>
                                                            }
                                                        </Upload>
                                                    </ImgCrop>
                                                </div>
                                            )}
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label style={{fontSize: "12px", color: "#38115F"}}
                                                           className="mb-2 font-weight-bold">{isSekolah ? i18n.t("namasiswa") : i18n.t("namakaryawan")}</label>
                                                    <div>
                                                        <input style={{fontSize: "12px"}} name="nama_karyawan"
                                                               className="form-control" placeholder={isSekolah ? i18n.t("namasiswa") : i18n.t("namakaryawan")}
                                                               type="text" required="" onChange={this.handleChange}/>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label style={{fontSize: "12px", color: "#38115F"}}
                                                           className="mb-2 font-weight-bold">{isSekolah ? i18n.t("nomorinduksiswa") : i18n.t("nomorindukkaryawan") + (' ') + '(' + i18n.t('opsional') + ')'}</label>
                                                    <div>
                                                        <input style={{fontSize: "12px"}} name="no_induk_karyawan"
                                                               className="form-control"
                                                               placeholder={isSekolah ? i18n.t("nomorinduksiswa") : i18n.t("nomorindukkaryawan")}
                                                               type="text" required="" onChange={this.handleChange}/>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label style={{fontSize: "12px", color: "#38115F"}}
                                                           className="mb-2 font-weight-bold">{i18n.t('email')}</label>
                                                    <div>
                                                        <input style={{fontSize: "12px"}} name="email_karyawan"
                                                               className="form-control" placeholder={i18n.t('email')}
                                                               type="email" required="" onChange={this.handleChange}/>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label style={{fontSize: "12px", color: "#38115F"}}
                                                           className="mb-2 font-weight-bold">{i18n.t('nomorhandphone')}</label>
                                                    <div>
                                                        <input style={{fontSize: "12px"}} name="no_hp_karyawan"
                                                               className="form-control"
                                                               type="number" required="" maxLength={15}
                                                               placeholder={i18n.t('nomorhandphone')}
                                                               onChange={this.handleChange}/>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label style={{fontSize: "12px", color: "#38115F"}}
                                                           className="mb-2 font-weight-bold">{i18n.t('alamat')}</label>
                                                    <div>
                                                        <textarea style={{fontSize: "12px"}} name="alamat_karyawan"
                                                                  type="text" className="form-control"
                                                                  placeholder={i18n.t('alamat')}
                                                                  onChange={this.handleChange}></textarea>
                                                    </div>
                                                </div>
                                                {!isSekolah && (
                                                    <>
                                                        <div className="form-group">
                                                            <label style={{fontSize: "12px", color: "#38115F"}}
                                                                   className="mb-2 font-weight-bold">{i18n.t('nomorpokokwajibpajak')} {'(' + i18n.t('opsional') + ')'}</label>
                                                            <div>
                                                                <input maxLength={16} style={{fontSize: "12px"}}
                                                                       name="no_npwp" type="text"
                                                                       className="form-control"
                                                                       placeholder={i18n.t('nomorpokokwajibpajak')}
                                                                       onChange={this.handleChange}/>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label style={{fontSize: "12px", color: "#38115F"}}
                                                                   className="mb-2 font-weight-bold">{i18n.t('nobpjs')} {'(' + i18n.t('opsional') + ')'}</label>
                                                            <div>
                                                                <input maxLength={16} style={{fontSize: "12px"}}
                                                                       name="no_bpjs" type="text"
                                                                       className="form-control"
                                                                       placeholder={i18n.t('nobpjs')}
                                                                       onChange={this.handleChange}/>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label style={{fontSize: "12px", color: "#38115F"}}
                                                                   className="mb-2 font-weight-bold">{i18n.t('pengaturanbpjs')} {'(' + i18n.t('opsional') + ')'}</label>
                                                            <div>
                                                                <select style={{fontSize: "12px"}} name="karyawan_bpjs"
                                                                        className="form-control"
                                                                        onChange={this.handleChange}>
                                                                    <option style={{fontSize: "12px"}} value="">{i18n.t('pilihpengaturanbpjs')}
                                                                    </option>
                                                                    {this.state.bpjs_data.map((item, index) => {
                                                                        return (
                                                                            <option style={{fontSize: "12px"}}
                                                                                    value={item.bpjs_setting_id}>{item.bpjs_setting_name}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label style={{fontSize: "12px", color: "#38115F"}}
                                                                   className="mb-2 font-weight-bold">{i18n.t('tanggallahir')}</label>
                                                            <div>
                                                                <input style={{fontSize: "12px"}} name="ttl_karyawan"
                                                                       className="form-control"
                                                                       onChange={this.handleChange}
                                                                       type="date" required=""/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label style={{fontSize: "12px", color: "#38115F"}}
                                                                   className="mb-2 font-weight-bold">{i18n.t('jeniskelamin')}</label>
                                                            <div>
                                                                <div class="form-check form-check-inline">
                                                                    <input class="form-check-input" type="radio"
                                                                           checked={this.state.gender_karyawan === "Laki-laki"}
                                                                           onClick={(e) => this.setState({gender_karyawan: e.target.value})}
                                                                           name="inlineRadioOptions" id="inlineRadio1"
                                                                           value="Laki-laki"/>
                                                                    <label class="form-check-label"
                                                                           style={{fontSize: "12px"}}
                                                                           for="inlineRadio1">{i18n.t('lakilaki')}</label>
                                                                </div>
                                                                <div class="form-check form-check-inline">
                                                                    <input class="form-check-input" type="radio"
                                                                           name="inlineRadioOptions" id="inlineRadio2"
                                                                           onClick={(e) => this.setState({gender_karyawan: e.target.value})}
                                                                           checked={this.state.gender_karyawan === "Perempuan"}
                                                                           value="Perempuan"/>
                                                                    <label class="form-check-label"
                                                                           style={{fontSize: "12px"}}
                                                                           for="inlineRadio2">{i18n.t('perempuan')}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label style={{fontSize: "12px", color: "#38115F"}}
                                                           className="mb-2 font-weight-bold">{i18n.t('tanggalbergabung')}</label>
                                                    <div>
                                                        <input style={{fontSize: "12px"}} name="join_date_karyawan"
                                                               className="form-control" onChange={this.handleChange}
                                                               type="date" required=""/>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label style={{fontSize: "12px", color: "#38115F"}}
                                                           className="mb-2 font-weight-bold">{isSekolah ? i18n.t('kelas') : i18n.t('divisi')}</label>
                                                    <div>
                                                        <select style={{fontSize: "12px"}} name="divisi_karyawan"
                                                                className="form-control" onChange={this.handleChange}>
                                                            <option
                                                                style={{fontSize: "12px"}}>{isSekolah ? i18n.t('pilihkelas') : i18n.t('pilihdivisi')}</option>
                                                            {this.props.divisi.map((item, index) => {
                                                                return (
                                                                    <option style={{fontSize: "12px"}}
                                                                            value={item.divisi_id}>{item.divisi_name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                {!isSekolah && (
                                                    <div className="form-group">
                                                        <label style={{fontSize: "12px", color: "#38115F"}}
                                                               className="mb-2 font-weight-bold">{i18n.t('jabatankaryawan')}</label>
                                                        <div>
                                                            <select style={{fontSize: "12px"}} name="jabatan_karyawan"
                                                                    className="form-control"
                                                                    onChange={this.handleChange}>
                                                                <option
                                                                    style={{fontSize: "12px"}}>{i18n.t('pilihjabatan')}
                                                                </option>
                                                                {this.props.jabatan.map((item, index) => {
                                                                    return (
                                                                        <option style={{fontSize: "12px"}}
                                                                                value={item.jabatan_id}>{item.jabatan_nama}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="form-group">
                                                    <label style={{fontSize: "12px", color: "#38115F"}}
                                                           className="mb-2 font-weight-bold">{i18n.t('nomorhandphoneorangtua')}</label>
                                                    <div>
                                                        <input style={{fontSize: "12px"}} name="no_hp_ortu"
                                                               className="form-control"
                                                               type="number" required="" maxLength={15}
                                                               placeholder={i18n.t('nomorhandphoneorangtua')}
                                                               onChange={this.handleChange}/>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label style={{fontSize: "12px", color: "#38115F"}}
                                                           className="mb-2 font-weight-bold">{i18n.t('lokasi')}</label>
                                                    <div>
                                                        <label style={{fontSize: "12px"}}>
                                                            <input style={{fontSize: "12px"}} type="checkbox"
                                                                   checked={this.state.lokasi_karyawan === null}
                                                                   onChange={() => this.lokasiHandler()}/>
                                                            &nbsp;{i18n.t('semualokasi')}
                                                        </label>
                                                        {this.state.lokasi_karyawan !== null &&
                                                            <Select name="lokasi_karyawan"
                                                                    style={{width: '100%'}}
                                                                    mode={"multiple"}
                                                                    placeholder={i18n.t('pilihlokasi')}
                                                                    filterOption={(input, option) =>
                                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                        || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                    }
                                                                    onChange={(event) => {
                                                                        this.setState({
                                                                            lokasi_karyawan: event
                                                                        })
                                                                    }}>
                                                                {this.props.lokasi.map((item) => {
                                                                    return (<Option
                                                                        key={item.lokasi_id}>{item.lokasi_nama}</Option>)
                                                                })}
                                                            </Select>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token,
    divisi: state.Filter._divisi,
    jabatan: state.Filter._jabatan,
    lokasi: state.Filter._lokasi
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Add_karyawan);
