import React, { Component, Fragment} from 'react';
import { Empty,Spin, Tooltip} from 'antd';
import { Td, Th } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Swal from "sweetalert2";
import Paging from "../../../components/paging";
import {IMG_HOST} from "../../../helper/host";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { pageNumber } from "../../../functions/functionGeneral";
import { clear_auth, set_auth } from "../../../reducers/Auth";
import RefreshIcon from '../../../assets/icons/refresh.svg';
import '../style.css'
import { isOdd } from "../../../helper/function";
import EmptyDataImage from "../../../assets/images/data-kosong.png";
import { Modal as ModalRs, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import moment from "moment/moment";
import i18n from "i18next";
import SaveIcon from "../../../assets/icons/save.svg";

export class List_cuti_karyawan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: [],
            idDel: '',
            filter_nama: "",
            filter_jabatan: "",
            pageCount: 0,
            totalData: 0,
            perPage: 0,
            pagination: 3,
            paging: 15,
            currentPage: 0,
            guide: false,
            icon_success: false,
            itemsPerPage: 10,
            shift_check: [],
            lokasi_check: [],
            divisi_check: [],
            karyawan_check: [],
            jam_kerja_check: [],
            confirm_modal_default: false,
            karyawan_id: 0,
            modalEdit: false,
            dataCuti: [],
            karyawan_max_cuti: '',
            max_cuti: ''
        }
        this.handleChange = this.handleChange.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected }, () => {
            this.loadData()
            this.props.history.push('/employee/employee-list/' + selected)
        });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    handleFilter(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            data: [],
            loading: true
        }, () => this.loadData())
    }

    resetFilter(event) {
        this.setState({
            filter_nama: '',
            filter_jabatan: '',
            data: [],
            loading: true
        }, () => this.loadData())
    }

    handleOk = e => {
        this.setState({
            modalimport: false,
            modalAction: false
        });
    };

    handleCancel = e => {
        this.setState({
            modalEdit: false,
            dataCuti: [],
            cuti: ''
        });
    };

    loadData() {
        let form = {
            paginate: this.state.paging,
            page: this.state.currentPage + 1,
            filter_nama: this.state.filter_nama,
            filter_jabatan: this.state.filter_jabatan,
            tipe_instansi : this.props.user.perusahaan.perusahaan_type
        }
        this.props.request("karyawan/get-cuti-karyawan", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        loading: false
                    })
                }
            });
    }

    loadEditDetail() {
        let form = {
            id : this.state.karyawan_id
        }
        this.props.request("karyawan/detail-cuti-karyawan", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        dataCuti: response.data,
                        karyawan_max_cuti: response.data.karyawan_max_cuti,
                        cuti: response.data.cuti,
                        modalEdit: true
                    })
                }
            });
    }

    updateCutiKaryawan = () => {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            id             : this.state.karyawan_id,
            max_cuti       : this.state.karyawan_max_cuti
        }

        this.props.request("karyawan/edit-cuti-karyawan", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title               : i18n.t('berhasil'),
                        text                : i18n.t('berhasilmengubahdata'),
                        icon                : 'success',
                        showConfirmButton   : false,
                        timer               : 800,
                    })
                }else{
                    Swal.fire({
                        title               : i18n.t('gagal'),
                        text                : i18n.t('gagalmengubahdata'),
                        icon                : 'warning',
                        showConfirmButton   : false,
                        timer               : 800,
                    })
                }
                this.setState({
                    prosesSimpan: false,
                    modalEdit: false,
                    dataCuti: [],
                    cuti: ''
                }, () => this.loadData())
            });
    }


    componentDidMount() {
        this.loadData()
    }

    render() {
        let no = 0;
        const stylefoto = {
            height: '50px',
            width: '50px',
            objectFit: 'cover'
        }
        const steps = [
            {
                selector: '.laporan-cuti-karyawan',
                content: 'Laporan Cuti Karyawan anda dapat dilihat disini',
            },
            {
                selector: '.filter-laporan-cuti',
                content: 'Anda dapat mem-Filter daftar laporan cuti karyawan anda lewat sini',
            },
        ];
        const levelPageLimited = this.props.user.user_level.level_page_limited;
        moment.locale(i18n.language);
        if (!levelPageLimited || levelPageLimited.search("Laporan Cuti") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{i18n.t("laporancuti")} - iPresens</title>
                        <meta name="description" content="Detail Presensi" />
                    </Helmet>
                    <Tour steps={steps} isOpen={this.state.guide === true} onAfterOpen={this.disableBody} onBeforeClose={this.enableBody} onRequestClose={() => this.setState({ guide: false })} />
                    <div className="container-fluid list-karyawan">
                        <div className='row pt-4'>
                            <div className='col-md-12'>
                                <div className='row'>
                                    <div className='col-md-12' style={{
                                        fontSize: "12px",
                                        fontWeight: 400,
                                        lineHeight: "16px",
                                        color: "#5D6F80"
                                    }}>iPresens Admin Panel</div>
                                </div>
                                <div className='row' style={{ marginBottom: "28px" }}>
                                    <div className='col-md-12' style={{
                                        fontSize: "24px",
                                        lineHeight: "36px",
                                        fontWeight: 700,
                                        color: "#143B5E"
                                    }}>{i18n.t("laporancuti")}</div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header pt-4 pb-0 px-3" style={{borderBottom: "0px"}}>
                                <div className="row mx-0">
                                    <div className="col-md-4 px-1 karyawan-filter mb-2">
                                        <input name="filter_nama"
                                               className={this.state.filter_nama !== "" ? "form-control h-100" : "form-control bg-image-input karyawan-filter-nama"}
                                               placeholder={i18n.t("carinama") + "..."}
                                               style={{paddingLeft: "40px"}}
                                               type="text"
                                               value={this.state.filter_nama}
                                               onChange={this.handleChange}
                                               onKeyUp={(event) => {
                                                   event.preventDefault()
                                                   if (event.which == 13) {

                                                       this.setState({
                                                           page: 0,
                                                           paging: this.state.paging,
                                                           loading: true
                                                       }, () => {
                                                           this.loadData()
                                                       });
                                                   }
                                               }}/>
                                    </div>
                                    <div className="col-md-3 px-1 d-flex flex-row flex-nowrap mb-2">
                                        <select name="filter_jabatan" value={this.state.filter_jabatan}
                                                className="form-control karyawan-filter-jabatan mr-2 btn-slc-krywn"
                                                onChange={this.handleFilter}>
                                            <option value="">{i18n.t('pilihjabatan')}</option>
                                            {this.props.jabatan.map((item, index) => {
                                                return (
                                                    <option value={item.jabatan_id}>{item.jabatan_nama}</option>
                                                )
                                            })}
                                        </select>
                                        <button className="btn ipresens-btn-reset border px-2"
                                                onClick={this.resetFilter}>
                                            <img src={RefreshIcon} alt=''/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body px-2 daftar-karyawan" style={{paddingTop: "40px"}}>
                                <div className="row">
                                    <div className="col-md-12 px-4">
                                        <div className="table-responsive">
                                            {!this.state.loading && this.state.data.length === 0 ?
                                                <Empty description={i18n.t("datakosong")}
                                                       image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                                :
                                                <table className="table table-striped mb-5">
                                                    <thead>
                                                        <tr>
                                                            <th style={{border: "none"}} width="30">#</th>
                                                            <th style={{border: "none"}}
                                                                className="text-center">{i18n.t('foto')}</th>
                                                            <th style={{border: "none"}}
                                                                className="text-left">{i18n.t('namakaryawan')}</th>
                                                            <th style={{border: "none"}}
                                                                className="text-left">{i18n.t('jabatandivisi')}</th>
                                                            <th style={{border: "none"}}
                                                                className="text-left">{i18n.t('maxcuti')}</th>
                                                            <th style={{border: "none"}}
                                                                className="text-left">{i18n.t('cutiterpakai')}</th>
                                                            <th style={{border: "none"}} className="text-left"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.loading &&
                                                            <tr>
                                                                <td colSpan={10}>
                                                                    <Spin size="large" tip="Loading..." style={{
                                                                        width: '100%',
                                                                        margin: 'auto',
                                                                        marginBottom: '10px',
                                                                        marginTop: '10px'
                                                                    }}/>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {this.state.data.map((item, index) => {
                                                            no += 1
                                                            return (
                                                                <tr style={item.status_karyawan !== 'active' ? (isOdd(index) ? {backgroundColor: '#FFDED7'} : {backgroundColor: '#F9B5A6'}) : {}}>
                                                                    <small>
                                                                        <Th style={{border: "none"}}
                                                                            scope="row">{pageNumber(no, this.state.perPage, this.state.currentPage)}</Th>
                                                                    </small>
                                                                    <Td style={{border: "none"}}
                                                                        align="center">{item.karyawan_foto !== null ? (
                                                                        <img style={stylefoto} alt=''
                                                                             src={IMG_HOST + 'mitra/frontapp/karyawan/' + item.karyawan_foto}/>
                                                                    ) : (
                                                                        <img style={stylefoto} alt=''
                                                                             src={IMG_HOST + "user-profile.png"}/>
                                                                    )}</Td>
                                                                    <Td style={{border: "none"}} align="left">
                                                                        {item.status_karyawan !== 'active' && (
                                                                            <div>
                                                                                <p className={'badges-danger'}>{i18n.t("karyawanblmaktif")}</p>
                                                                            </div>
                                                                        )}
                                                                        {item.karyawan_no_induk && (
                                                                            <div>
                                                                                <p className={'ipresens-badges-induk'}>NI: {item.karyawan_no_induk}</p>
                                                                            </div>
                                                                        )}
                                                                        <span style={{
                                                                            fontWeight: 500,
                                                                            color: "#248CE7",
                                                                            fontSize: "14px"
                                                                        }}><a
                                                                            href={"/karyawan/profil/detail/" + item.karyawan_slug}>{item.karyawan_nama}</a></span>
                                                                        <br/>
                                                                        <div>
                                                                            <p className={'text-muted fs-5'} style={{
                                                                                fontSize: "12px",
                                                                                lineHeight: "24px",
                                                                                fontWeight: 400
                                                                            }}>{item.karyawan_email}</p>
                                                                        </div>
                                                                        {item.karyawan_payroll_amount === 0 ?
                                                                            <a href={"/karyawan/profil/detail/" + item.karyawan_slug + '/4'}
                                                                               target="_blank">
                                                                                <div
                                                                                    className={"badge badge-warning"}>{i18n.t('lengkapipayroll')}</div>
                                                                            </a>
                                                                            : ""
                                                                        }
                                                                    </Td>
                                                                    <Td style={{border: "none"}} align="left">
                                                                        <span style={{
                                                                            fontWeight: 400,
                                                                            fontSize: "14px",
                                                                            lineHeight: "24px",
                                                                            color: "#143B5E"
                                                                        }}>{item.jabatan && item.jabatan.jabatan_nama ? item.jabatan.jabatan_nama : '-'}</span><br/>
                                                                        <div><p className={'text-muted fs-5'} style={{
                                                                            fontSize: "14px",
                                                                            fontWeight: 400,
                                                                            lineHeight: "24px"
                                                                        }}>{item.divisi && item.divisi.divisi_name ? item.divisi.divisi_name : '-'}</p>
                                                                        </div>
                                                                    </Td>
                                                                    <Td style={{border: "none"}} align="left">
                                                                        <div className="" style={{
                                                                            marginRight: '2px',
                                                                            fontWeight: 400,
                                                                            fontSize: "14px",
                                                                            lineHeight: "24px",
                                                                            color: "#143B5E"
                                                                        }}>
                                                                            {item.karyawan_max_cuti ? item.karyawan_max_cuti : '0'}
                                                                        </div>
                                                                    </Td>
                                                                    <Td style={{border: "none"}} align="left">
                                                                        <div className="" style={{
                                                                            marginRight: '2px',
                                                                            fontWeight: 400,
                                                                            fontSize: "14px",
                                                                            lineHeight: "24px",
                                                                            color: "#143B5E"
                                                                        }}>
                                                                            {item.cuti ? item.cuti.length : '0'}
                                                                        </div>
                                                                    </Td>
                                                                    <Td style={{border: "none"}} align="center">
                                                                        <Tooltip trigger={'click'} placement="bottom"
                                                                                 color={'#fff'}
                                                                                 title={
                                                                                     <div className={'p-2'}>
                                                                                         <a
                                                                                             onClick={() => {
                                                                                                 this.setState({
                                                                                                     modalEdit: true,
                                                                                                     karyawan_id: item.karyawan_id
                                                                                                 }, () => {
                                                                                                     this.loadEditDetail(item.karyawan_id);
                                                                                                 })
                                                                                             }}
                                                                                             className={'text-dark'}
                                                                                         >
                                                                                             {i18n.t('edit')}
                                                                                         </a>
                                                                                     </div>
                                                                                 }>
                                                                            <a><i
                                                                                className="fa fa-ellipsis-v" style={{
                                                                                width: 35,
                                                                                fontSize: 18,
                                                                                padding: 11,
                                                                                color: 'rgb(51 51 51)'
                                                                            }}
                                                                            ></i></a>
                                                                        </Tooltip>
                                                                    </Td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <Paging
                                    className="float-right"
                                    handlePageClick={this.handlePageClick}
                                    pageCount={this.state.pageCount}
                                    currentPage={this.state.currentPage}
                                />

                                <ModalRs size="lg" zIndex={999} isOpen={this.state.modalEdit} style={{maxWidth: "1000px"}}>
                                    <ModalHeader className='pengumuman-modal-header'
                                                 toggle={this.handleCancel}>
                                        <div className='pengumuman-modal-header-font' style={{color: "#38115F"}}>{i18n.t("editcutikaryawan")}</div>
                                    </ModalHeader>
                                    <ModalBody className='pengumuman-modal-body'>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <form className="needs-validation user-add" noValidate="">
                                                    <div className="row">
                                                        <div className="form-group col-md-5">
                                                            <label style={{fontSize: "12px", color: "#38115F"}}
                                                                   className="mb-2 font-weight-bold">
                                                                {i18n.t("namakaryawan")}
                                                            </label>
                                                            <div className="mt-2">
                                                                <b>{this.state.dataCuti ? this.state.dataCuti.karyawan_nama : ''}</b>
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-md-3">
                                                            <label style={{fontSize: "12px", color: "#38115F"}}
                                                                   className="mb-2 font-weight-bold">
                                                                {i18n.t("maxcuti")}
                                                            </label>
                                                            <div>
                                                                <input type="number" name="karyawan_max_cuti"
                                                                       className="form-control col-xl-5 col-md-4"
                                                                       placeholder={0}
                                                                       defaultValue={this.state.dataCuti && this.state.dataCuti.karyawan_max_cuti}
                                                                       onChange={this.handleChange}/>
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-md-3">
                                                            <label style={{fontSize: "12px", color: "#38115F"}}
                                                                   className="mb-2 font-weight-bold">
                                                                {i18n.t("cutiterpakai")}
                                                            </label>
                                                            <div>
                                                                <input type="number" name="karyawan_max_cuti"
                                                                       className="form-control col-xl-5 col-md-4"
                                                                       placeholder={0}
                                                                       disabled={true}
                                                                       defaultValue={this.state.cuti && this.state.cuti.length}
                                                                       onChange={this.handleChange}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </ModalBody>
                                    <ModalFooter className='pengumuman-modal-footer'>
                                        <button onClick={this.handleCancel} className='ipresens-cancel-button'>{i18n.t("batal")}</button>
                                        <button className='ipresens-save-button' onClick={this.updateCutiKaryawan}>
                                            <img alt='' src={SaveIcon}/> {i18n.t("simpan")}
                                        </button>
                                    </ModalFooter>
                                </ModalRs>
                                <div className='col font-14'
                                     style={{padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px"}}>
                                    {i18n.t("menampilkan")} {this.state.data.length === 0 || this.state.data.length === null ? "0" : this.state.data.length} {i18n.t("dari")} {this.state.totalData} {i18n.t("data")}.
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>

            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">{i18n.t("tidakadaakseshalaman")}</h5>
                </Fragment>
            )
        }

    }
}


const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token,
    jabatan: state.Filter._jabatan
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(List_cuti_karyawan);
