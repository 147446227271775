import React, { Component, Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb';
import {APIKEY, BACKEND_API_HOST, DEFAULT_IMG} from "../../helper/host";
import { GoogleApiWrapper } from 'google-maps-react';
import {Modal, Empty, Spin, Tooltip, Checkbox} from 'antd';
import { Helmet } from "react-helmet";
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Add from "./add";
import Edit from "./edit";
import { pageNumber } from "../../functions/functionGeneral";
import Swal from "sweetalert2";
import Paging from "../../components/paging";
import { clear_auth, set_auth } from "../../reducers/Auth";
import { connect } from "react-redux";
import "./style.css";
import deleteImages from "../../assets/images/delete-images.png"
import {Col, Modal as ModalRs, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import EmptyDataImage from "../../assets/images/data-kosong.png";
import ImportIcon from "../../assets/icons/import-icon.svg";
import moment from "moment";
import i18n from "i18next";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg";
import ReactPaginate from "react-paginate";
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import ArrowRight from "../../assets/icons/arrow-right.svg";
import SearchIcon from "../../assets/icons/search.svg";
import RefreshIcon from "../../assets/icons/refresh.svg";
import PlusIcon from "../../assets/icons/plus-white.svg";
import MoreIcon from "../../assets/icons/more.svg";
import Trash from "../../assets/icons/trash.svg";
import Restore from "../../assets/icons/update.svg";

export class List_istillah extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            dataHistory: [],
            loading: true,
            add_visible: false,
            visable: false,
            filter_nama: '',
            filter_type: '',
            pageCount: 0,
            currentPage: 0,
            totalData: 0,
            perPage: 0,
            paging: 20,
            pagination: 2,
            currentPageHistory: 0,
            modalEdit: false,
            modalDelete: false,
            id_del: null,
            importFile: null,
            modalimport: false,
            modalHistory: false,
            loadingHistory: false,
            id_p_termHistory: 0,
            id_history: 0,
            historyLast: [],
            trashHistory: [],
            modalTrash: false,
            restore: false,
            deletePermanent: false,
            checkedTrash: [],
            checkAllTrash: false,
        }
        this.handleChange = this.handleChange.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.handlePageHistory = this.handlePageHistory.bind(this)
        this.importExcel = this.importExcel.bind(this)
        this.wantDelete = this.wantDelete.bind(this)

    }
    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, loading: true, data: [] }, () => {
            this.loadData();
        });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }
    handleFilter(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            data: [],
            loading: true
        }, () => this.loadData())
    }

    handleOk = e => {
        this.setState({
            modalimport: false
        });
    };

    handleCancel = e => {
        this.setState({
            modalimport: false
        });
    };

    handlePageHistory(dataHistory) {
        let selected = dataHistory.selected; // zero based index
        this.setState({ currentPageHistory: selected, dataHistory: [], loadingHistory: true }, () => {
            this.loadHistory();
        });
    }

    resetFilter(event) {
        this.setState({
            filter_nama: '',
            filter_type: '',
            data: [],
            loading: true
        }, () => this.loadData())
    }

    wantDelete = () => {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('andayakinmenghapusistilah'),
            icon: 'warning',
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        id: this.state.id_del
                    }
                    this.props.request("payroll/delete_term", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: i18n.t(response.message),
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 4000,
                                })
                                this.setState({
                                    loading: true,
                                    modalDelete: false,
                                    data: []
                                })
                                this.loadData()
                            } else {
                                Swal.fire({
                                    title: i18n.t('gagal'),
                                    text: i18n.t(response.message),
                                    icon: 'error',
                                    showConfirmButton: false,
                                    timer: 5000,
                                })
                                this.setState({
                                    loading: true,
                                    data: []
                                })
                                this.loadData()
                            }
                        })
                }
            })
    }

    loadData() {
        this.setState({
            loading: true,
        })
        let form = {
            filter_nama: this.state.filter_nama,
            filter_type: this.state.filter_type,
            paginate: 10,
            page: this.state.currentPage + 1,
        }
        this.props.request("payroll/list_payroll_term", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                    })
                }
                this.setState({
                    loading: false,
                })
            });
    }

    exportIstilahGaji = () => {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'payroll/export_template?token=' + AuthKey, "_blank")
    }

    importExcel() {
        this.setState({
            importLoading: true
        })

        var AuthKey = localStorage.getItem('Presensi-Auth-Key')

        let formData = new FormData()
        formData.append('file', this.state.importFile)
        formData.append('token', AuthKey)

        fetch(BACKEND_API_HOST + 'payroll/import', {
            method: 'POST',
            headers: {
                'Authorization': 'bearer ' + AuthKey
            },
            body: formData
        })
            .catch((error) => {
                console.error(error);
            })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t(response.message),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                    window.location.reload();
                    this.setState({
                        modalimport: false,
                        loading: true,
                        importFile: null,
                        data: [],
                    }, () => {
                        this.loadData()
                    })
                }
                else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t(response.message),
                        icon: 'warning',
                        showConfirmButton: false,
                        timer: 1200,
                    })
                    this.setState({
                        modalimport: false,
                    })
                }

                this.setState({ importLoading: false })
            })
    }

    loadHistory(id) {
        this.setState({
            dataHistory: [],
            loadingHistory: true
        }, () => {
            let form = {
                id: id ? id : this.state.p_term_id,
                paging: this.state.pagination,
                page: this.state.currentPageHistory + 1,
            };

            this.props.request("payroll/history-term", form, 'POST')
                .then(response => {
                    this.setState({
                        loadingHistory: false
                    })
                    if (response.succes) {
                        this.setState({
                            dataHistory: response.data.data,
                            historyLast: response.history,
                            pageCountHistory: response.data.last_page,
                            totalDataHistory: response.data.total,
                            totalPerpageHistory: response.data.to,
                            perPageHistory: response.data.per_page,
                        });
                    } else {
                        this.setState({
                            modalHistory: false,
                            p_term_id: ''
                        });
                    }
                });
        })
    }

    handleSubmitRevert = () => {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('yakinmengembalikanistilah'),
            icon: "warning",
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                    if (result.isConfirmed) {
                        let form = {
                            p_term_id: this.state.id_p_termHistory,
                            history_id: this.state.id_history,
                        }
                        this.props.request("payroll/revert", form, 'POST')
                            .then(response => {
                                if (response.success) {
                                    Swal.fire({
                                        title: i18n.t('berhasil'),
                                        text: response.message,
                                        icon: 'success'
                                    })
                                    this.setState({
                                        modalHistory: false
                                    })
                                    this.props.history.push('/payroll-term-list')
                                    this.loadData()
                                } else {
                                    Swal.fire({
                                        title: i18n.t('gagal'),
                                        text: response.message,
                                        icon: 'warning',
                                        confirmButtonText: 'OK'
                                    })
                                }
                            })
                    }
                }
            )
    }

    loadTrash(id) {
        this.setState({
            trashHistory: [],
            loading_history: true,
        }, () => {
            let form = {
                perusahaan_id: this.state.perusahaan_id,
                page: this.state.currentPageHistory + 1,
                paginate: 4,
            };
            this.props.request('payroll/trash', form, 'POST')
                .then(response => {
                    if (response.success) {
                        this.setState({
                            trashHistory: response.data.data,
                            pageCountHistory: response.data.last_page,
                            totalDataHistory: response.data.total,
                            totalPerpageHistory: response.data.to,
                            perPageHistory: response.data.per_page,
                            loading_history: false
                        })
                    } else {
                        this.setState({
                            modal_history: false, p_term_id: 0, perusahaan_id: 0,
                            modalTrash: false,
                        })
                    }
                })
        })
    }

    handleCheckBoxAllChangeTrash() {
        this.setState(prevState => ({
            checkAllTrash: !prevState.checkAllTrash
        }), () => {
            this.state.checkAllTrash
                ? this.setState({
                    checkedTrash: this.state.trashHistory.map(item => item.p_term_id)
                })
                : this.setState({
                    checkedTrash: []
                })
        })
    }

    handleCheckBoxChangeTrash(id) {
        this.state.checkedTrash.includes(id) ?
            this.setState(prevState => ({
                checkedTrash: prevState.checkedTrash.filter(currId => currId !== id),
            }), () => {
                if (this.state.checkedTrash.length !== this.state.totalDataHistory) {
                    this.setState({
                        checkAllTrash: false
                    })
                }
            })
            :
            this.setState(prevState => ({
                checkedTrash: [...prevState.checkedTrash, id],
            }), () => {
                if (this.state.checkedTrash.length === this.state.totalDataHistory) {
                    this.setState({
                        checkAllTrash: true
                    })
                }
            })
    }

    restore = (id) => {
        let form = {
            p_term_id: this.state.checkedTrash,
        }
        this.props.request('payroll/restore', form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('databerhasildikembalikan'),
                        icon: "success",
                        showConfirmButton: false,
                        timer: 900,
                    })
                    this.loadData()
                    this.setState({
                        modalTrash: false,
                        checkedTrash: [],
                        restore: false,
                        checkAllTrash: false,
                    })
                }
            })
    }

    deletePermanent = (id) => {
        let form = {
            p_term_id: this.state.checkedTrash,
        }
        this.props.request('payroll/delete-permanent', form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('databerhasildihapus'),
                        icon: "success",
                        showConfirmButton: false,
                        timer: 900,
                    })
                    this.loadData()
                    this.setState({
                        modalTrash: false,
                        checkedTrash: [],
                        permanentDelete: false,
                        checkAllTrash: false,
                    })
                }
            })
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        let no = 0;
        const style = {
            filter_nama: {
                backgroundColor: "#F9F9F9",
                padding: "12px 10px",
                color: "#5D6F80",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "16px"
            },
            td_style: {
                padding: "16px 12px",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "24px",
                color: "#252B2B"
            }
        }
        if (!this.props.user.user_level.level_page_limited || this.props.user.user_level.level_page_limited.search("Daftar Istillah") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8"/>
                        <title>{i18n.t("daftaristilahgaji")} - iPresens</title>
                        <meta name="description" content="Daftar Istilah"/>
                    </Helmet>
                    <Breadcrumb parent={i18n.t("manajemenistilahgaji")} title={i18n.t("daftaristilahgaji")} setGuide={() => {
                        this.setState({guide: true})
                    }}/>
                    <Add
                        add_visible={this.state.add_visible}
                        request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                        changeState={(state, callback) => {
                            this.setState(state, () => {
                                if (typeof callback != 'undefined')
                                    callback()
                            })
                        }}
                        loadData={() => this.loadData()}
                    />
                    <Edit
                        data={this.state}
                        modalEdit={this.state.modalEdit}
                        request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                        setState={(val, callback) => this.setState(val, callback)}
                        changeState={(state, callback) => {
                            this.setState(state, () => {
                                if (typeof callback != 'undefined')
                                    callback()
                            })
                        }}
                        loadData={() => this.loadData()}
                    />
                    {this.state.modalDelete &&
                        <Modal isOpen={this.state.modalDelete} toggle={() => this.setState({modalDelete: false})}
                               style={{
                                   top: "20%"
                               }}>
                            <ModalBody align={"center"}>
                                <img src={deleteImages} alt=""/>
                                <h3 style={{
                                    "fontWeight": 700,
                                    "fontSize": "18px",
                                    "lineHeight": "27px",
                                    "color": "#143B5E"
                                }}
                                >{i18n.t("peringatan")}</h3>
                                <p>{i18n.t("peringatantext")}</p>
                                <ModalFooter style={{
                                    borderTop: 0,
                                    backgroundColor: "#FFFFFF",
                                    borderBottomLeftRadius: "8px",
                                    borderBottomRightRadius: "8px",
                                    justifyContent: "space-between"
                                }}>
                                    <button className="btn ipresens-btn-cancel"
                                            onClick={() => this.setState({modalDelete: false})}>
                                        {i18n.t("batal")}
                                    </button>
                                    <>
                                        {/*{this.state.data.map((item, index) => {*/}
                                        {/*    return (*/}
                                        <button className="btn ipresens-btn-color" onClick={() => this.setState({
                                            idDel: this.state.data.p_term_id
                                        }, this.wantDelete)}>
                                            {i18n.t("ya")}
                                        </button>
                                        {/*)})}*/}
                                    </>
                                </ModalFooter>
                            </ModalBody>
                        </Modal>
                    }
                    <Modal
                        title={i18n.t("importistilahgaji")}
                        visible={this.state.modalimport}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        footer={[
                            this.state.importLoading ?
                                <button className={'btn ipresens-btn-color'}
                                        style={{textTransform: "none", width: "100%"}} disabled={true}><i
                                    className="fa fa-refresh fa-spin"></i>{i18n.t("sedangmenguploaddata")}</button>
                                :
                                <button
                                    className={this.state.importFile === null ? 'btn bg-secondary-custom' : 'btn ipresens-btn-color'}
                                    style={{textTransform: "none", width: "100%"}}
                                    disabled={this.state.importFile === null}
                                    onClick={this.importExcel}>{i18n.t('importdata')}</button>
                        ]}
                    >
                        <div>
                            <a style={{color: "#5D6F80", cursor: "default"}}>{i18n.t("importistilahgajitext")}</a><br/>
                            <a onClick={this.exportIstilahGaji}
                               style={{color: "#057CE4"}}>{i18n.t("importistilahgajitemplates")}</a>
                            <div style={{
                                position: 'relative',
                                width: '100%',
                                backgroundColor: '#F9F9F9',
                                padding: 16,
                                borderRadius: 6,
                                marginTop: 8,
                                border: "1px dashed #DDDDDD"
                            }}>
                                <input type='file'
                                       accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                       onChange={(event) => {
                                           this.setState({
                                               importFile: event.target.files[0],
                                           })
                                       }}/><br/>
                            </div>
                        </div>
                    </Modal>
                    <ModalRs size="lg" style={{maxWidth: "1000px"}} isOpen={this.state.modalHistory}
                             toggle={() => this.setState({modalHistory: false})}>
                        <ModalHeader style={{backgroundColor: "#FAFDFF"}}
                                     toggle={() => this.setState({modalHistory: false})}>
                            <div className='modal-header-reactstrap'>{i18n.t("riwayatperubahan")}</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="list-group">
                                <div>
                                    {this.state.loadingHistory && (
                                        <div style={{textAlign: 'center', margin: '10px auto'}}>
                                            <Spin
                                                size="large"
                                                tip="Loading..."
                                                style={{
                                                    width: '100%',
                                                    margin: 'auto',
                                                }}
                                            />
                                        </div>
                                    )}

                                    {this.state.dataHistory.length !== 0 && (
                                        <div>
                                            <Row>
                                                <Col xs={0} md={0} className="text-left" style={{marginLeft: '20px'}}>
                                                    <h4>{i18n.t("user")}</h4>
                                                </Col>
                                                <Col xs={0} md={0} className="text-left" style={{marginRight: '20px'}}>
                                                    <h4>{i18n.t("aktivitas")}</h4>
                                                </Col>
                                            </Row>

                                            {this.state.dataHistory.map((item, index) => {
                                                return (
                                                    <div key={index} style={{marginBottom: '20px'}}>
                                                        <Row>
                                                            <Col xs={12} md={6} className="text-left">
                                                                <div style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    paddingTop: '16px',
                                                                    paddingBottom: '16',
                                                                    paddingRight: '12px',
                                                                    paddingLeft: '12px'
                                                                }}>
                                                                    <div style={{marginRight: '16px'}}>
                                                                        <img
                                                                            src={DEFAULT_IMG}
                                                                            alt=""
                                                                            style={{
                                                                                width: '48x',
                                                                                marginBottom: '25px',
                                                                                height: '48px'
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <h6
                                                                            className="mb-0 font-13"
                                                                            style={{
                                                                                fontWeight: 500,
                                                                                fontSize: '16px',
                                                                                lineHeight: '24px',
                                                                            }}
                                                                        >
                                                                            {item.p_user_name} | {item.level_name}
                                                                        </h6>
                                                                        <p className="m-0 font-12">
                                                                            <span
                                                                                className="col-green font-weight-bold">
                                                                                {moment(item.created_at).format('lll')}
                                                                            </span>
                                                                        </p>
                                                                        <div style={{marginTop: 12}}>
                                                                            {item.history_status === "created" ? (
                                                                                <div className="row px-3">
                                                                                    <div
                                                                                        className="mr-2"
                                                                                        style={{
                                                                                            border: '1px solid #000',
                                                                                            borderRadius: '25px',
                                                                                            width: '50%',
                                                                                            color: '#7805e4',
                                                                                            borderColor: '#7805e4',
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            className="d-flex justify-content-center"
                                                                                            style={{
                                                                                                paddingTop: '6px',
                                                                                                paddingBottom: '6px'
                                                                                            }}>
                                                                                            {i18n.t('dibuat')}
                                                                                        </div>
                                                                                    </div>
                                                                                    {this.state.historyLast.history_id === item.history_id ?
                                                                                        "" :
                                                                                        <button
                                                                                            className='col-md-5 d-flex justify-content-center ipresens-btn-revert'
                                                                                            onClick={() => {
                                                                                                this.setState({
                                                                                                    id_p_termHistory: item.p_term_id,
                                                                                                    id_history: item.history_id,
                                                                                                })
                                                                                                this.handleSubmitRevert()
                                                                                            }}>revert
                                                                                        </button>
                                                                                    }
                                                                                </div>
                                                                            ) : item.history_status === "reverted" ?
                                                                                <div
                                                                                    className="mr-2 px-5"
                                                                                    style={{
                                                                                        border: '1px solid #000',
                                                                                        borderRadius: '25px',
                                                                                        width: '70%',
                                                                                        color: '#7805e4',
                                                                                        borderColor: '#7805e4',
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        className="d-flex justify-content-center"
                                                                                        style={{
                                                                                            paddingTop: '6px',
                                                                                            paddingBottom: '6px'
                                                                                        }}>
                                                                                        {i18n.t('dikembalikan')}
                                                                                    </div>
                                                                                </div>
                                                                                : (
                                                                                    <div className="row px-3">
                                                                                        <div
                                                                                            className="mr-2"
                                                                                            style={{
                                                                                                border: '1px solid #000',
                                                                                                borderRadius: '25px',
                                                                                                width: '50%',
                                                                                                color: '#E6462E',
                                                                                                borderColor: '#E6462E',
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                className="d-flex justify-content-center"
                                                                                                style={{
                                                                                                    paddingTop: '6px',
                                                                                                    paddingBottom: '6px'
                                                                                                }}>
                                                                                                {i18n.t('diubah')}
                                                                                            </div>
                                                                                        </div>
                                                                                        {this.state.historyLast.history_id === item.history_id ?
                                                                                            "" :
                                                                                            <button
                                                                                                className='col-md-5 d-flex justify-content-center ipresens-btn-revert'
                                                                                                onClick={() => {
                                                                                                    this.setState({
                                                                                                        id_p_termHistory: item.p_term_id,
                                                                                                        id_history: item.history_id,
                                                                                                    })
                                                                                                    this.handleSubmitRevert()
                                                                                                }}>revert
                                                                                            </button>
                                                                                        }
                                                                                    </div>
                                                                                )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} md={6} className="text-left" style={{
                                                                paddingTop: '16px',
                                                                paddingBottom: '16px',
                                                                paddingRight: '12px',
                                                                paddingLeft: '12px'
                                                            }}>
                                                                {item.hitory_status !== 'created' && (
                                                                    <div>
                                                                        {item.parent_history ?
                                                                            item.parent_history.p_term_name != item.p_term_name && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t('namaistilah')}: {i18n.t('dari')}{' '}
                                                                                        <b>{item.parent_history
                                                                                            ? item.parent_history.p_term_name
                                                                                            : 'N/A'}</b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t('jadi')}: <b>{item.p_term_name ? item.p_term_name : ''}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ) : null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.p_term_type != item.p_term_type && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t('tipeistilah')}: {i18n.t('dari')}{' '}
                                                                                        <b>{item.parent_history
                                                                                            ? item.parent_history.p_term_type
                                                                                            : 'N/A'}</b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t('jadi')}: <b>{item.p_term_type ? item.p_term_type : ''}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ) : null
                                                                        }
                                                                    </div>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{backgroundColor: "#F5F5F5"}}>
                            <div className='row mx-0' style={{backgroundColor: "#F5F5F5", width: "100%"}}>
                                {this.state.dataHistory.length !== 0 &&
                                    <>
                                        <div className='col font-14' style={{
                                            padding: "auto",
                                            margin: "auto",
                                            fontWeight: 500,
                                            lineHeight: "24px"
                                        }}>{i18n.t('menampilkan')} {this.state.dataHistory.length === 0 || this.state.dataHistory.length === null ? "0" : this.state.dataHistory.length} {i18n.t('dari')} {this.state.totalDataHistory} {i18n.t('data')}</div>
                                        <div className='col'>
                                            <ReactPaginate
                                                previousLabel={<img src={ArrowLeft} alt=''/>}
                                                nextLabel={<img src={ArrowRight} alt=''/>}
                                                pageCount={this.state.pageCountHistory}
                                                forcePage={this.state.currentPageHistory}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={this.handlePageHistory}
                                                breakClassName={'page-item'}
                                                breakLinkClassName={'page-link custom-page-link'}
                                                containerClassName={'pagination my-2'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link custom-page-link'}
                                                previousClassName={'page-item'}
                                                previousLinkClassName={'page-link custom-prev-next-link'}
                                                nextClassName={'page-item'}
                                                nextLinkClassName={'page-link custom-prev-next-link'}
                                                activeClassName={'active custom-active'}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </ModalFooter>
                    </ModalRs>
                    <ModalRs size="lg" zIndex={999} isOpen={this.state.modalTrash} toggle={() => this.setState({
                        modalTrash: false,
                        checkedTrash: [],
                        trashHistory: [],
                        checkAllTrash: false,
                    })} style={{maxWidth: "1000px"}}>
                        <ModalHeader className='pengumuman-modal-header'>
                            <div className='pengumuman-modal-header-font'>{i18n.t("trashistilah")}</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="row d-flex justify-content-end">
                                <div className="col-md-6"></div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <button className="btn ipresens-btn-color px-3 py-2 mx-1"
                                            disabled={this.state.checkedTrash.length === 0}
                                            onClick={() => {
                                                this.setState({restore: true});
                                            }}>
                                        Restore
                                    </button>
                                    <button className="btn ipresens-btn-color px-3 py-2 mx-1"
                                            disabled={this.state.checkedTrash.length === 0} onClick={() => {
                                        this.setState({permanentDelete: true});
                                    }}>Delete Permanent
                                    </button>
                                </div>
                            </div>
                            <div className="list-group">
                                <div>
                                    {this.state.loading_history && (
                                        <div style={{textAlign: "center", margin: '10px auto'}}>
                                            <Spin size="large" tip={i18n.t('loading')}
                                                  style={{width: "100%", margin: "auto"}}/>
                                        </div>
                                    )}
                                    {this.state.trashHistory.length !== 0 && (
                                        <Table className="table table-striped mb-5">
                                            <Thead>
                                                <Tr>
                                                    <Th><Checkbox checked={this.state.checkAllTrash}
                                                                  onChange={this.handleCheckBoxAllChangeTrash}></Checkbox></Th>
                                                    <Th className="text-left lembur-th-style">{i18n.t("nama")}</Th>
                                                    <Th className="text-left lembur-th-style">{i18n.t("tipe")}</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {this.state.trashHistory.map((item, index) => {
                                                    let no = index + 1;
                                                    return (
                                                        <Tr>
                                                            <Td><Checkbox checked={this.state.checkedTrash.includes(item.p_term_id)} onChange={() => this.handleCheckBoxChangeTrash(item.p_term_id)}></Checkbox></Td>
                                                            <Td align="left" style={{...style.td_style,fontWeight: 700,
                                                                fontSize: "14px",
                                                                color: "#252B2B",
                                                                lineHeight: "24px"}}>
                                                                {item.p_term_name}
                                                            </Td>
                                                            <Td style={style.td_style} align="left">
                                                                {item.p_term_type === 'kompensasi' ?
                                                                    <div
                                                                        style={{color: "#6BAA61"}}>{i18n.t("kompensasigaji")}</div>
                                                                    :
                                                                    <div
                                                                        style={{color: "#E03616"}}>{i18n.t("potongangaji")}</div>
                                                                }
                                                            </Td>
                                                        </Tr>
                                                    );
                                                })}
                                            </Tbody>
                                        </Table>
                                    )
                                    }
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{ backgroundColor: "#F5F5F5", borderBottomLeftRadius: "8px", borderBottomRightRadius: "8px" }}>
                            <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                                {this.state.trashHistory.length !== 0 &&
                                    <>
                                        <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>{i18n.t("menampilkan")} {this.state.trashHistory.length === 0 || this.state.trashHistory.length === null ? "0" : this.state.trashHistory.length} {i18n.t("dari")} {this.state.totalDataHistory} {i18n.t("data")}</div>
                                        <div className='col'>
                                            <ReactPaginate
                                                previousLabel={<img src={ArrowLeft} alt='' />}
                                                nextLabel={<img src={ArrowRight} alt='' />}
                                                pageCount={this.state.pageCountHistory}
                                                forcePage={this.state.currentPageHistory}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={this.handlePageTrashHistory}
                                                breakClassName={'page-item'}
                                                breakLinkClassName={'page-link custom-page-link'}
                                                containerClassName={'pagination my-2'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link custom-page-link'}
                                                previousClassName={'page-item'}
                                                previousLinkClassName={'page-link custom-prev-next-link'}
                                                nextClassName={'page-item'}
                                                nextLinkClassName={'page-link custom-prev-next-link'}
                                                activeClassName={'active custom-active'}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </ModalFooter>
                    </ModalRs>
                    <ModalRs centered isOpen={this.state.permanentDelete} toggle={() => {
                        this.setState({
                            permanentDelete: false
                        })
                    }}>
                        <ModalHeader className='pl-4 border-bottom-0' toggle={() => {
                            this.setState({
                                permanentDelete: false
                            })
                        }}>
                            <div className='d-flex'>
                                <div className='p-3 mr-4' style={{
                                    backgroundColor: "transparent",
                                    borderRadius: "8px"
                                }}>
                                    <img alt='' src={Trash}/>
                                </div>
                                <div>
                                    <div style={{
                                        color: "#143B5E",
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        lineHeight: "27px"
                                    }}>Hapus Permanen Data Payroll Istilah?</div>
                                    <div style={{
                                        color: "#5D6F80",
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "24px"
                                    }}>{this.state.checkedTrash.length} Payroll Istilah Terpilih</div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalFooter style={{
                            borderTop: 0
                        }}>
                            <div className='row mx-0' style={{ width: "100%" }}>
                                <div className='col-md-6 px-1'>
                                    <button className='btn ipresens-btn-cancel' style={{ width: "100%" }} onClick={() => {
                                        this.setState({
                                            permanentDelete: false
                                        })
                                    }}>
                                        {i18n.t('batal')}
                                    </button>
                                </div>
                                <div className='col-md-6 px-1'>
                                    <button className='btn ipresens-btn-color' style={{
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        lineHeight: "24px",
                                        width: "100%"
                                    }} onClick={this.deletePermanent}>
                                        {i18n.t('ya')}
                                    </button>
                                </div>
                            </div>
                        </ModalFooter>
                    </ModalRs>
                    <ModalRs centered isOpen={this.state.restore} toggle={() => {
                        this.setState({
                            restore: false
                        })
                    }}>
                        <ModalHeader className='pl-4 border-bottom-0' toggle={() => {
                            this.setState({
                                restore: false
                            })
                        }}>
                            <div className='d-flex'>
                                <div className='p-3 mr-4' style={{
                                    backgroundColor: "#8C2BEE",
                                    borderRadius: "8px"
                                }}>
                                    <img alt='' src={Restore} />
                                </div>
                                <div>
                                    <div style={{
                                        color: "#143B5E",
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        lineHeight: "27px"
                                    }}>Pulihkan Data Payroll Istilah</div>
                                    <div style={{
                                        color: "#5D6F80",
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "24px"
                                    }}>{this.state.checkedTrash.length} Payroll Istilah Terpilih</div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalFooter style={{
                            borderTop: 0
                        }}>
                            <div className='row mx-0' style={{ width: "100%" }}>
                                <div className='col-md-6 px-1'>
                                    <button className='btn ipresens-btn-cancel' style={{ width: "100%" }} onClick={() => {
                                        this.setState({
                                            restore: false
                                        })
                                    }}>
                                        {i18n.t('batal')}
                                    </button>
                                </div>
                                <div className='col-md-6 px-1'>
                                    <button className='btn ipresens-btn-color' style={{
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        lineHeight: "24px",
                                        width: "100%"
                                    }} onClick={this.restore}>
                                        {i18n.t('ya')}
                                    </button>
                                </div>
                            </div>
                        </ModalFooter>
                    </ModalRs>
                    <div className="container-fluid">
                        <div className="card" style={{ border: "1px solid #DDDDDD" }}>
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-4 pr-lg-1 pr-md-1">
                                        <div className='input-group btn-srch-kbeban'>
                                        <span className='input-group-text izin-search-karyawan-img'>
                                            <img alt='' src={SearchIcon}/>
                                        </span>
                                            <input type='text' onChange={this.handleChange}
                                                   name='filter_nama' value={this.state.filter_nama}
                                                   className='form-control izin-search-karyawan-form'
                                                   placeholder={i18n.t("carinamaistilah") + ("...")}
                                                   style={style.filter_nama} onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    this.loadData()
                                                }}}>
                                            </input>
                                        </div>
                                    </div>
                                    <div className="col-md-3 px-lg-1 px-md-1 d-flex flex-row flex-nowrap mb-3">
                                        <select name="filter_type"
                                                className="form-control karyawan-filter-jabatan mr-2 btn-slc-krywn"
                                                style={{borderRadius: "4px"}}
                                                onChange={this.handleFilter}
                                        >
                                            <option value=""
                                                    selected={this.state.filter_type === ''}>{i18n.t("pilihtipe")}</option>
                                            <option value="kompensasi">{i18n.t("kompensasigaji")}</option>
                                            <option value="potongan">{i18n.t("potongangaji")}</option>
                                        </select>
                                        <div>
                                            <button className='btn ipresens-btn-reset border px-2'>
                                                <img alt="" src={RefreshIcon}
                                                     onClick={this.resetFilter}/>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="d-flex justify-content-end align-items-center mx-1">
                                            <button
                                                className="btn ipresens-btn-color px-3 text-capitalize width-filter tombol-export mr-2"
                                                onClick={() => {
                                                    this.setState({add_visible: true});
                                                }}
                                            >
                                                <img src={PlusIcon} alt="Tambah Istilah"/> {i18n.t('tambahistilah')}
                                            </button>
                                            <Tooltip rigger={'click'} placement="bottomLeft" color={'#FFF'}
                                                     title={
                                                         <div className="p-2">
                                                             <a
                                                                 onClick={() => {
                                                                     this.setState({modalimport: true})
                                                                 }}
                                                                 style={{marginRight: "100px"}}
                                                                 className={'text-dark'}
                                                             >Import</a><br/>
                                                             <a
                                                                 className={'text-dark'}
                                                                 onClick={() => {
                                                                     this.setState({modalTrash: true});
                                                                     this.loadTrash();
                                                                 }}
                                                             >{i18n.t("trashistilah")}</a>
                                                         </div>
                                                     }>
                                                <a>
                                                    <i
                                                        style={{
                                                            width: "15",
                                                            fontSize: 18,
                                                            color: 'rgb(51 51 51)',
                                                            alignItems: "center",
                                                            paddingRight: "30px"
                                                        }}
                                                        className='btn border border-dark border-1 px-2'
                                                    >
                                                        <img src={MoreIcon}/>
                                                    </i>
                                                </a>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="table-responsive">
                                            {!this.state.loading && this.state.data.length === 0 ?
                                                <Empty description={i18n.t("datakosong")}
                                                       image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                                :
                                                <Table className="table table-striped table-borderless mb-5">
                                                    <Thead>
                                                        <Tr>
                                                            <Th className="lembur-th-style" style={{maxWidth: "30px"}}>#</Th>
                                                            <Th className="text-left lembur-th-style">{i18n.t("nama")}</Th>
                                                            <Th className="text-left lembur-th-style">{i18n.t("tipe")}</Th>
                                                            <Th className="text-center lembur-th-style">{i18n.t("aksi")}</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {this.state.loading &&
                                                            <Tr>
                                                                <Td colSpan={9} style={{
                                                                    textAlign: "center",
                                                                    backgroundColor: "#FFFFFF"
                                                                }}>
                                                                    <Spin size="large" tip={i18n.t('memuat')} style={{
                                                                        width: '100%',
                                                                        margin: 'auto',
                                                                        marginBottom: '10px',
                                                                        marginTop: '10px'
                                                                    }}/>
                                                                </Td>
                                                            </Tr>
                                                        }
                                                        {this.state.data.map((item, index) => {
                                                            no += 1
                                                            return (
                                                                <Tr className="table-istilah">
                                                                    <Td style={style.td_style} scope="row" >{pageNumber(no, this.state.perPage, this.state.currentPage)}</Td>
                                                                    <Td align="left" style={{...style.td_style,fontWeight: 700,
                                                                        fontSize: "14px",
                                                                        color: "#252B2B",
                                                                        lineHeight: "24px"}}>
                                                                        {item.p_term_name}
                                                                    </Td>
                                                                    <Td style={style.td_style} align="left">
                                                                        {item.p_term_type === 'kompensasi' ?
                                                                            <div
                                                                                style={{color: "#6BAA61"}}>{i18n.t("kompensasigaji")}</div>
                                                                            :
                                                                            <div
                                                                                style={{color: "#E03616"}}>{i18n.t("potongangaji")}</div>
                                                                        }
                                                                    </Td>
                                                                    <Td style={style.td_style} align={"center"}>
                                                                        <Tooltip trigger={'click'} zIndex={1}
                                                                                 placement="bottom"
                                                                                 color={'#fff'} title={
                                                                            <div className={'text-dark'}>
                                                                                <a onClick={() => {
                                                                                    this.setState({
                                                                                        modalHistory: true,
                                                                                        p_term_id: item.p_term_id
                                                                                    }, () => {
                                                                                        this.loadHistory(item.p_term_id);
                                                                                    })
                                                                                }}>{i18n.t("riwayat")}</a><br/>
                                                                                <a className={'text-dark'}
                                                                                   onClick={() => {
                                                                                       this.setState({
                                                                                           p_term_id: item.p_term_id,
                                                                                           p_term_type: item.p_term_type,
                                                                                           p_term_name: item.p_term_name,
                                                                                           modalEdit: true,
                                                                                       })
                                                                                   }}>{i18n.t("edit")}</a><br/>
                                                                                <a className={'text-danger'}
                                                                                   onClick={() => {
                                                                                       this.setState({
                                                                                           id_del: item.p_term_id
                                                                                       })
                                                                                       this.wantDelete()
                                                                                   }}>{i18n.t("hapus")}</a>
                                                                            </div>
                                                                        }>
                                                                            <a><i
                                                                                className="fa fa-ellipsis-v"
                                                                                style={{
                                                                                    width: 35,
                                                                                    fontSize: 18,
                                                                                    padding: 11,
                                                                                    color: 'rgb(51 51 51)'
                                                                                }}
                                                                            ></i></a>
                                                                        </Tooltip>
                                                                    </Td>
                                                                </Tr>
                                                            )
                                                        })}
                                                    </Tbody>
                                                </Table>
                                            }
                                            <Paging
                                                className="float-right"
                                                handlePageClick={this.handlePageClick}
                                                pageCount={this.state.pageCount}
                                                currentPage={this.state.currentPage}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">{i18n.t("tidakadaakseshalaman")}</h5>
                </Fragment>
            )
        }
    }
}


const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(GoogleApiWrapper({
    apiKey: APIKEY
})(List_istillah));
