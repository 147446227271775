import React, {Component} from "react";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import i18n from "i18next";
import EmptyDataImage from "../../../assets/images/data-kosong.png";
import {Empty, Spin} from "antd";
import {Td, Tr} from "react-super-responsive-table";
import ReactPaginate from 'react-paginate';
import ArrowLeft from "../../../assets/icons/arrow-left.svg";
import ArrowRight from "../../../assets/icons/arrow-right.svg";
import Swal from "sweetalert2";

class TrashNilaiSiswa extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataTrash: [],
            checked: [],
            checked_all: false,
            loadingTrash: false,
            currentPage: 0,
            paginate: 5,
            pageCount: 0,
            totalData: 0,
            perPage: 0,
        };
        this.handlePageClick = this.handlePageClick.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.trash_visible !== this.props.trash_visible && this.props.trash_visible) {
            this.setState({ loadingTrash: true }, () => this.loadTrash())
        }
    }

    handlePageClick(dataTrash) {
        let selected = dataTrash.selected;
        this.setState({ currentPage: selected }, () => this.loadTrash())
    }

    loadTrash() {
        this.setState({ loadingTrash: true, dataTrash: [] })
        let form = {
            page: this.state.currentPage + 1,
            paginate: this.state.paginate
        }
        this.props.request('nilai/trash', form, 'POST')
            .then((response) => {
                if (response.success) {
                    this.setState({
                        dataTrash: response.data.data,
                        pageCount: response.data.last_page,
                        totalData: response.data.total,
                        perPage: response.data.per_page,
                        loadingTrash: false,
                    })
                }
            })
    }

    handleCheckAll(e) {
        const value = e.target.checked;
        if (value) {
            const allChecked = this.state.dataTrash.map(item => item.nilai_id);
            this.setState({
                checked: allChecked,
                checked_all: true
            });
        } else {
            this.setState({
                checked: [],
                checked_all: false
            });
        }
    }

    handleCheck(id) {
        this.setState(prevState => {
            const isChecked = prevState.checked.includes(id);
            const newChecked = isChecked
                ? prevState.checked.filter(currId => currId !== id)
                : [...prevState.checked, id];

            return {
                checked: newChecked,
                checked_all: newChecked.length === prevState.totalData
            };
        });
    }

    handleRestore() {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: `${this.state.checked.length} ${i18n.t('yakinpulihkannilaisiswa')}`,
            icon: "warning",
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        checked: this.state.checked
                    }
                    this.props.request('nilai/restore-nilai', form, 'POST')
                        .then((response) => {
                            if (response.success) {
                                this.props.changeState({ trash_visible: false, loading: true }, () => this.props.loadData())
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: i18n.t('nilaisiswaberhasildipulihkan'),
                                    icon: 'success'
                                })
                            } else {
                                Swal.fire({
                                    title: i18n.t('gagal'),
                                    text: response.message,
                                    icon: 'warning',
                                    confirmButtonText: 'OK'
                                })
                            }
                            this.setState({
                                checked: [],
                                checked_all: false
                            }, () => this.loadTrash())
                        })
                }
            })
    }

    handleDeletePermanent() {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: `${this.state.checked.length} ${i18n.t('yakinhapuspermanentnilaisiswa')}`,
            icon: "warning",
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        checked: this.state.checked,
                    }
                    this.props.request('nilai/delete-permanent-nilai', form, 'POST')
                        .then((response) => {
                            if (response.success) {
                                this.props.changeState({ trash_visible: false, loading: false }, () => this.props.loadData())
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: i18n.t('nilaisiswaberhasildihapuspermanent'),
                                    icon: 'success'
                                })
                            } else {
                                Swal.fire({
                                    title: i18n.t('gagal'),
                                    text: response.message,
                                    icon: 'warning',
                                    confirmButtonText: 'OK'
                                })
                            }
                            this.setState({ checked: [], checked_all: false }, () => this.loadTrash())
                        })
                }
            })
    }

    render() {
        return (
            <Modal isOpen={this.props.trash_visible} zIndex={999} style={{ maxWidth: "1000px"}} size="lg" toggle={() => this.props.changeState({
                trash_visible: false
            }, () => this.setState({ currentPage: 0 }))}>
                <ModalHeader toggle={() => this.props.changeState({ trash_visible: false }, () => this.setState({ checked: [], checked_all: false, currentPage: 0 }))} className="modal-header-ipresens">
                    <div className="modal-header-ipresens">{i18n.t('sampahnilaisiswa')}</div>
                </ModalHeader>
                <ModalBody>
                    <div>
                        <div className="d-flex align-items-center justify-content-end" style={{ gap: 5 }}>
                            <button className="btn ipresens-btn-color" disabled={this.state.checked.length === 0} onClick={() => this.handleRestore()}>{i18n.t('pulihkan')}</button>
                            <button className="btn ipresens-btn-color" disabled={this.state.checked.length === 0} onClick={() => this.handleDeletePermanent()}>{i18n.t('hapuspermanent')}</button>
                        </div>
                        <div className="mt-4">
                            {!this.state.loadingTrash && this.state.dataTrash.length === 0 ? (
                                <Empty description={i18n.t("datakosong")}
                                       image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                            ) : (
                                <table className="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th className="text-center" style={{ width: '20px' }}>
                                                <input
                                                    type="checkbox"
                                                    className="form-control"
                                                    checked={this.state.checked_all}
                                                    onChange={(e) => this.handleCheckAll(e)}
                                                />
                                            </th>
                                            <th>{i18n.t('siswa')}</th>
                                            <th className="text-center" style={{ maxWidth: "150px" }}>{i18n.t('semester')}</th>
                                            <th className="text-center" style={{ maxWidth: "220px" }}>{i18n.t('mapel')}</th>
                                            <th className="text-center" style={{ maxWidth: "220px" }}>{i18n.t('kategorinilai')}</th>
                                            <th className="text-center" style={{ maxWidth: "80px" }}>{i18n.t('nilai')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.loadingTrash && (
                                            <Tr>
                                                <Td colSpan={11}>
                                                    <Spin size="large" tip="Loading..." style={{
                                                        width: '100%',
                                                        margin: 'auto',
                                                        marginBottom: '10px',
                                                        marginTop: '10px'
                                                    }}/>
                                                </Td>
                                            </Tr>
                                        )}
                                        {this.state.dataTrash.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="text-center" style={{ width: '20px' }}>
                                                        <input type="checkbox"
                                                               name={`row-${item.nilai_id}`}
                                                               className="form-control"
                                                               checked={this.state.checked.includes(item.nilai_id)}
                                                               onChange={() => this.handleCheck(item.nilai_id)}/>
                                                    </td>
                                                    <td>{item.siswa?.karyawan_nama}</td>
                                                    <td className="text-capitalize text-center" style={{ width: "150px"}}>{item.semester?.semester_type}</td>
                                                    <td className="text-center" style={{ width: "220px" }}>{item.mapel?.mapel_nama}</td>
                                                    <td className="text-center" style={{ width: "220px" }}>{item.nilai_term?.nl_term_name}</td>
                                                    <td className="text-center" style={{ maxWidth: "80px" }}>{item.nilai_value}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    {this.state.dataTrash.length !== 0 &&
                        <>
                            <div className='col font-14' style={{
                                padding: "auto",
                                margin: "auto",
                                fontWeight: 500,
                                lineHeight: "24px"
                            }}>{i18n.t('menampilkan')} {this.state.dataTrash.length === 0 || this.state.dataTrash.length === null ? "0" : this.state.dataTrash.length} {i18n.t('dari')} {this.state.totalData} {i18n.t('data')}
                            </div>
                            <div className='col'>
                                <ReactPaginate
                                    previousLabel={<img src={ArrowLeft} alt=''/>}
                                    nextLabel={<img src={ArrowRight} alt=''/>}
                                    pageCount={this.state.pageCount}
                                    forcePage={this.state.currentPage}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={this.handlePageClick}
                                    breakClassName={'page-item'}
                                    breakLinkClassName={'page-link custom-page-link'}
                                    containerClassName={'pagination my-2'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link custom-page-link'}
                                    previousClassName={'page-item'}
                                    previousLinkClassName={'page-link custom-prev-next-link'}
                                    nextClassName={'page-item'}
                                    nextLinkClassName={'page-link custom-prev-next-link'}
                                    activeClassName={'active custom-active'}
                                />
                            </div>
                        </>
                    }
                </ModalFooter>
            </Modal>
        )
    }
}

export default TrashNilaiSiswa;