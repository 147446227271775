import React, {Component} from "react";
import {Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import i18n from "i18next";
import {Spin} from "antd";
import {DEFAULT_IMG} from "../../../helper/host";
import moment from "moment";
import ArrowRight from "../../../assets/icons/arrow-right.svg";
import ArrowLeft from "../../../assets/icons/arrow-left.svg";
import ReactPaginate from 'react-paginate';
import Swal from "sweetalert2";

class HistoryIstilahPembayaran extends Component {
    constructor(props) {
        super(props);
        this.state = {
            history: [],
            latest_history: [],
            pageCountHistory: 0,
            currentPageHistory: 0,
            term_id: 0,
            history_id: 0,
            loading_history: false,
        };
        this.handlePageHistory = this.handlePageHistory.bind(this);
    }

    componentDidUpdate(prevProps) {
        const { history_visible, term_id } = this.props;

        if (history_visible && (!prevProps.history_visible || term_id !== prevProps.term_id)) {
            this.setState({ loading_history: true, term_id: term_id, currentPageHistory: 0 }, () => this.loadHistory(term_id));
        }
    }

    handlePageHistory(history) {
        let selected = history.selected;
        this.setState({ currentPageHistory: selected, loading_history: true, history: [] }, () => {
            this.loadHistory();
        });
    }

    loadHistory(id) {
        this.setState({ loading_history: true, history: []})
        let form = {
            pb_term_id: id || this.state.term_id,
            paging: 2,
            page: this.state.currentPageHistory + 1,
        }
        this.props.request('pembayaran/history_term', form, 'POST')
            .then((response) => {
                if (response.success) {
                    this.setState({
                        history: response.data.data,
                        latest_history: response.latest_history,
                        loading_history: false,
                        totalDataHistory: response.data.total,
                        pageCountHistory: response.data.last_page,
                        perPageHistory: response.data.per_page,
                    })
                }
            })
    }

    handleRevert(history, term) {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('yakin-revert-term-nilai'),
            icon: "warning",
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        history_id: history || this.state.history_id,
                        term_id: term || this.state.term_id,
                    }
                    this.props.request('pembayaran/revert_term', form, 'POST')
                        .then((response) => {
                            if (response.success) {
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: i18n.t('berhasil-mengembalikan-term-siswa'),
                                    icon: "success",
                                });
                                this.props.changeState({ history_visible: false, loading: true }, () => this.props.loadData());
                            } else {
                                Swal.fire({
                                    title: i18n.t('gagal'),
                                    text: response.message,
                                    icon: "error",
                                });
                            }
                        })
                }
            })
    }

    render() {
        return (
            <Modal size="lg" style={{ maxWidth: "1000px" }} isOpen={this.props.history_visible} toggle={() => this.props.changeState({ history_visible: false })}>
                <ModalHeader className="modal-header-reactstrap" toggle={() => this.props.changeState({ history_visible: false })}>
                    <div className="modal-header-reactstrap">{i18n.t('riwayatpembayarantermsiswa')}</div>
                </ModalHeader>
                <ModalBody>
                    <div className="list-group">
                        <div className="">
                            {this.state.loading_history && (
                                <div style={{textAlign: "center", margin: '10px auto'}}>
                                    <Spin size="large" tip={i18n.t('loading')}
                                          style={{width: "100%", margin: "auto"}}/>
                                </div>
                            )}
                            {this.state.history.length !== 0 && (
                                <div>
                                    <Row>
                                        <Col xs={0} md={0} className="text-left" style={{marginLeft: '20px'}}>
                                            <h4>{i18n.t('user')}</h4>
                                        </Col>
                                        <Col xs={0} md={0} className="text-left" style={{marginRight: '20px'}}>
                                            <h4>{i18n.t('aktivitas')}</h4>
                                        </Col>
                                    </Row>

                                    {this.state.history.map((item, index) => {
                                        return (
                                            <div key={index} style={{marginBottom: "20px"}}>
                                                <Row>
                                                    <Col xs={12} md={6} className="text-left">
                                                        <div style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            padding: '16px 12px'
                                                        }}>
                                                            <div style={{marginRight: '16px'}}>
                                                                <img
                                                                    src={DEFAULT_IMG}
                                                                    alt=""
                                                                    style={{
                                                                        width: '48px',
                                                                        marginBottom: '25px',
                                                                        height: '48px'
                                                                    }}
                                                                />
                                                            </div>
                                                            <div>
                                                                <h6 className="mb-0 font-13" style={{
                                                                    fontWeight: 500,
                                                                    fontSize: '16px',
                                                                    lineHeight: '24px'
                                                                }}>{item.p_user_name} | {item.level_name}</h6>
                                                                <p className="m-0 font-12">
                                                                            <span
                                                                                className="col-green font-weight-bold">
                                                                              {moment(item.created_at).format('lll')}
                                                                            </span>
                                                                </p>
                                                                <div style={{marginTop: 8, gap: 5 }} className="d-flex align-items-center">
                                                                    {item.history_status === 'created' ? (
                                                                        <>
                                                                            <div
                                                                                className="m-0"
                                                                                style={{
                                                                                    border: '1px solid #000',
                                                                                    borderRadius: '25px',
                                                                                    width: '50%',
                                                                                    color: '#7805e4',
                                                                                    borderColor: '#7805e4',
                                                                                }}
                                                                            >
                                                                                <div className="d-flex justify-content-center" style={{
                                                                                    paddingTop: '6px',
                                                                                    paddingBottom: '6px'
                                                                                }}>
                                                                                    {i18n.t('dibuat')}
                                                                                </div>
                                                                            </div>
                                                                            {this.state.latest_history.history_id !== item.history_id && (
                                                                                <button
                                                                                    className="px-5 d-flex justify-content-center ipresens-btn-revert py-2"
                                                                                    style={{borderRadius: "25px"}}
                                                                                    onClick={() => this.setState({
                                                                                        history_id: item.history_id,
                                                                                        term_id: item.pb_term_id
                                                                                    }, () => this.handleRevert(item.history_id, item.pb_term_id))}>
                                                                                    {i18n.t('kembalikan')}
                                                                                </button>
                                                                            )}
                                                                        </>
                                                                    ) : item.history_status === 'reverted' ? (
                                                                        <div className="m-0" style={{
                                                                            border: '1px solid #000',
                                                                            borderRadius: '25px',
                                                                            width: '75%',
                                                                            color: '#7805e4',
                                                                            borderColor: '#7805e4',
                                                                            fontSize: "14px"
                                                                        }}>
                                                                            <div
                                                                                className="d-flex justify-content-center"
                                                                                style={{
                                                                                    paddingTop: '6px',
                                                                                    paddingBottom: '6px'
                                                                                }}>
                                                                                {i18n.t('dikembalikan')}
                                                                            </div>
                                                                        </div>
                                                                    ) :(
                                                                        <>
                                                                            <div
                                                                                className="m-0"
                                                                                style={{
                                                                                    border: '1px solid #000',
                                                                                    borderRadius: '25px',
                                                                                    width: '50%',
                                                                                    color: '#7805e4',
                                                                                    borderColor: '#7805e4',
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    className="d-flex justify-content-center"
                                                                                    style={{
                                                                                        paddingTop: '6px',
                                                                                        paddingBottom: '6px'
                                                                                    }}>
                                                                                    {i18n.t('diubah')}
                                                                                </div>
                                                                            </div>
                                                                            {this.state.latest_history.history_id !== item.history_id && (
                                                                                <button
                                                                                    className="px-5 d-flex justify-content-center ipresens-btn-revert py-2"
                                                                                    style={{borderRadius: "25px"}}
                                                                                    onClick={() => this.setState({
                                                                                        history_id: item.history_id,
                                                                                        term_id: item.pb_term_id
                                                                                    }, () => this.handleRevert(item.history_id, item.pb_term_id))}>
                                                                                    {i18n.t('kembalikan')}
                                                                                </button>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xs={12} md={6} className="text-left" style={{
                                                        padding: '28px 12px 16px',
                                                    }}>
                                                        {(item.history_status !== 'created' && item.history) && (
                                                            <div>
                                                                {item.history?.pb_term_name !== item.pb_term_name && (
                                                                    <div>
                                                                        <h6
                                                                            className="mb-3 font-13 text-left"
                                                                            style={{
                                                                                fontWeight: 100,
                                                                                fontSize: '16px',
                                                                                lineHeight: '24px',
                                                                            }}
                                                                        >
                                                                            {i18n.t('nama')} : {i18n.t('dari')}{' '}
                                                                            {item.history?.pb_term_name}
                                                                            <img
                                                                                src={ArrowRight}
                                                                                alt=""
                                                                                style={{
                                                                                    width: '20x',
                                                                                    marginBottom: '1px',
                                                                                    height: '18px'
                                                                                }}
                                                                            /> {i18n.t('jadi')}
                                                                            : {item?.pb_term_name}
                                                                        </h6>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </div>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    {this.state.history.length !== 0 &&
                        <>
                            <div className='col font-14' style={{
                                padding: "auto",
                                margin: "auto",
                                fontWeight: 500,
                                lineHeight: "24px"
                            }}>{i18n.t('menampilkan')} {this.state.history.length === 0 || this.state.history.length === null ? "0" : this.state.history.length} {i18n.t('dari')} {this.state.totalDataHistory} {i18n.t('data')}
                            </div>
                            <div className='col'>
                                <ReactPaginate
                                    previousLabel={<img src={ArrowLeft} alt=''/>}
                                    nextLabel={<img src={ArrowRight} alt=''/>}
                                    pageCount={this.state.pageCountHistory}
                                    forcePage={this.state.currentPageHistory}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={this.handlePageHistory}
                                    breakClassName={'page-item'}
                                    breakLinkClassName={'page-link custom-page-link'}
                                    containerClassName={'pagination my-2'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link custom-page-link'}
                                    previousClassName={'page-item'}
                                    previousLinkClassName={'page-link custom-prev-next-link'}
                                    nextClassName={'page-item'}
                                    nextLinkClassName={'page-link custom-prev-next-link'}
                                    activeClassName={'active custom-active'}
                                />
                            </div>
                        </>
                    }
                </ModalFooter>
            </Modal>
        )
    }
}
export  default HistoryIstilahPembayaran;